import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import { GetServiceHeader } from './Header';
import { GetServiceFilter } from './Filters';
import qs from 'qs';
import {
  GetServicePageFilters,
  GetServicePageClosedOptions,
  IServiceRequest,
  IAnswer,
  IListFilters,
} from 'interfaces/Marketplace';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import {
  likeService,
  marketplaceAcceptAnswer,
  marketplaceCompleteAnswer,
  marketplaceDeclineAnswer,
  marketplaceGetServicePageGetList,
} from 'store/features/marketplace/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { styled } from '@mui/system';
import {
  getServiceItemsList,
  hasMoreGetServiceItems,
  isGetServiceItemsListLoading,
} from 'store/features/marketplace';
import { AnswerStatuses } from 'interfaces/Marketplace';
import { Loader } from 'components/shared/loader';
import { ServiceRequestAnswerCard } from 'components/features/marketplace/components/cards/ServiceRequestAnswerCard';
import { NonNullableRecord } from 'utils/types';
import { isEqual } from 'lodash';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { useModalActions } from 'utils/hooks';
import { CreateReview } from 'components/features/marketplace/components/feedBack';
import { stringToBoolean } from 'utils/boolean';
import { getCurrent } from 'store/features/users';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { ReactionType } from 'interfaces/Post';
import { ListAdOne, ListAdTwo } from '../../../../elements/ads';

const EmptyText = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
  marginBottom: 15,
});

export const MarketplaceGetService = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const search = useQuery();
  const dispatch = useAppDispatch();

  const filters = useMemo(
    () =>
      ({
        text: search.text ?? '',
        closed: search.closed ?? GetServicePageClosedOptions.all,
        onlyCreatedByCurrentUser:
          stringToBoolean(search.onlyCreatedByCurrentUser as string) ?? true,
        fromDate: search.fromDate && search.fromDate,
      } as GetServicePageFilters),
    [
      search.closed,
      search.fromDate,
      search.onlyCreatedByCurrentUser,
      search.text,
    ],
  );

  const [listFilters, setListFilters] = useState<
    NonNullableRecord<IListFilters>
  >({
    limit: 10,
    offset: 0,
  });
  const user = useAppSelector(getCurrent);
  const items = useAppSelector(getServiceItemsList);
  const isLoading = useAppSelector(isGetServiceItemsListLoading);
  const hasMore = useAppSelector(hasMoreGetServiceItems);

  const isFetching = listFilters.offset === 0 && isLoading;
  const isRefetching = listFilters.offset !== 0 && isLoading;

  const prevFilters = useRef<GetServicePageFilters>({});

  useEffect(() => {
    const currentFilters = { ...filters, ...listFilters };

    if (!isEqual(prevFilters.current, currentFilters)) {
      dispatch(marketplaceGetServicePageGetList(currentFilters));
      prevFilters.current = currentFilters;
    }
  }, [dispatch, filters, listFilters]);

  const changeFilters = () => {
    setListFilters(({ offset, limit }) => ({
      limit,
      offset: offset + limit,
    }));
  };

  const handleFiltersChange = (value: GetServicePageFilters) => {
    setListFilters({ limit: 10, offset: 0 });
    navigate({
      search: qs.stringify({ ...filters, ...value }, { skipNulls: true }),
    });
  };

  const handleAcceptAnswer = useCallback(
    (item) => async () => {
      await dispatch(
        marketplaceAcceptAnswer({
          offerId: item.offer.id,
          answerId: item.id,
        }),
      );
      dispatch(marketplaceGetServicePageGetList(filters));
    },
    [dispatch, filters],
  );

  const handleDeclineAnswer = useCallback(
    (item) => async () => {
      await dispatch(
        marketplaceDeclineAnswer({
          offerId: item.offer.id,
          answerId: item.id,
        }),
      );
      dispatch(marketplaceGetServicePageGetList(filters));
    },
    [dispatch, filters],
  );

  const navigateToAnswer = (id: IServiceRequest['id']) => () => {
    navigate(`/marketplace/answer/${id}`);
  };

  const handleLike = (id: IAnswer['id']) => {
    dispatch(likeService({ id, type: ReactionType.LIKE }));
  };

  const handleDislike = (id: IAnswer['id']) => {
    dispatch(likeService({ id, type: ReactionType.DISLIKE }));
  };

  const { isOpen, onClickOpen, onClickClose } = useModalActions();
  const [selectedAnswer, setSelectedService] = useState<IAnswer | null>(null);
  const handleClickCreateComplaint = (answer: IAnswer) => {
    onClickOpen();
    setSelectedService(answer);
  };

  const handleClickCloseComplaint = () => {
    onClickClose();
    setSelectedService(null);
  };

  const [selectedOffer, setSelectedOffer] = useState<IAnswer | null>(null);
  const isCurrentUserOfferAnswer = user?.id === selectedOffer?.creator?.id;

  const handleClickCreateReview = (answer: IAnswer) => {
    onClickOpen();
    setSelectedOffer(answer);
  };

  const handleClickCloseReview = () => {
    onClickClose();
    setSelectedOffer(null);
  };

  const handleCompleteAnswer = (item: IAnswer) => async () => {
    await dispatch(
      marketplaceCompleteAnswer({
        offerId: item.offer.id,
        answerId: item.id,
      }),
    );
    dispatch(marketplaceGetServicePageGetList(filters));
    handleClickCreateReview(item);
  };

  return (
    <MarketplaceLayout contentSx={{ display: 'flex', flexDirection: 'column' }}>
      <Container>
        <GetServiceHeader
          onChange={handleFiltersChange}
          initial={filters}
          sx={{ borderBottom: '1px solid #e4e4e4' }}
        />
        <GetServiceFilter
          onChange={handleFiltersChange}
          initial={filters}
          sx={{ borderBottom: '1px solid #e4e4e4' }}
        />
      </Container>
      {isFetching && <Loader sx={{ m: 'auto' }} show />}
      {!isFetching && !items.length && (
        <EmptyText sx={{ m: 'auto' }}>
          {t('marketplace.emptyServices')}
        </EmptyText>
      )}
      {!isFetching && !!items.length ? (
        <InfiniteScroll
          dataLength={items.length}
          loader={<Loader show={isRefetching} global />}
          next={changeFilters}
          hasMore={hasMore}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {items.map((item, index) => {
            const isAcceptButton = item.status === AnswerStatuses.PUBLISHED;
            const isCompleteButton = item.status === AnswerStatuses.IN_WORK;
            const isCancelButton =
              item.status === AnswerStatuses.PUBLISHED ||
              item.status === AnswerStatuses.ACCEPTED ||
              item.status === AnswerStatuses.IN_WORK;
            return (
              <>
                {index % 4 === 0 && index % 8 !== 0 && index !== 0 && (
                  <ListAdOne />
                )}
                {index % 4 === 0 && index % 8 === 0 && index !== 0 && (
                  <ListAdTwo />
                )}
                <ServiceRequestAnswerCard
                  item={item}
                  onLike={handleLike}
                  onDislike={handleDislike}
                  onComment={navigateToAnswer(item.id)}
                  menu={[
                    {
                      label: t('actions.view'),
                      onClick: navigateToAnswer(item.id),
                    },
                    ...(isCompleteButton
                      ? [
                          {
                            label: t('actions.complete'),
                            onClick: handleCompleteAnswer(item),
                          },
                        ]
                      : []),
                    ...(isAcceptButton
                      ? [
                          {
                            label: t('actions.accept'),
                            onClick: handleAcceptAnswer(item),
                          },
                        ]
                      : []),
                    ...(isCancelButton
                      ? [
                          {
                            label: t('actions.cancel'),
                            onClick: handleDeclineAnswer(item),
                          },
                        ]
                      : []),
                    {
                      label: t('actions.complaint'),
                      onClick: () => {
                        handleClickCreateComplaint(item);
                      },
                    },
                  ]}
                  key={item.id}
                  sx={[{ mb: 2 }, index === items.length - 1 && { mb: 0 }]}
                />
              </>
            );
          })}
        </InfiniteScroll>
      ) : (
        <></>
      )}
      {selectedAnswer && (
        <ConfirmDialog
          onClickClose={handleClickCloseComplaint}
          open={isOpen}
          title={t('modalTexts.complaint.title', {
            name: getLocaleValue(selectedAnswer.title),
          })}
        >
          <CreateComplaint
            relationId={selectedAnswer.id}
            complaintAboutType={ComplaintAboutType.ANSWER}
            additionAction={handleClickCloseComplaint}
          />
        </ConfirmDialog>
      )}
      {selectedOffer?.id && (
        <ConfirmDialog
          onClickClose={handleClickCloseReview}
          open={isOpen}
          title={t('modalTexts.feedback.title')}
        >
          <CreateReview
            answer={selectedOffer}
            additionAction={handleClickCloseReview}
            isCurrentUserOfferAnswer={isCurrentUserOfferAnswer}
          />
        </ConfirmDialog>
      )}
    </MarketplaceLayout>
  );
};

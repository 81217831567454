import { Filters, ListFilters } from './Filters';
import { Locale } from './Locale';

export enum SearchMatchType {
  BUSINESS_CARD = 'BUSINESS_CARD',
  BUSINESS_SERVICE = 'BUSINESS_SERVICE',
  NEWS = 'NEWS',
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER',
  OFFER = 'OFFER',
  OFFER_ANSWER = 'OFFER_ANSWER',
  COMPLAINT = 'COMPLAINT',
}

export interface SearchMatchFragment {
  fieldName: string;
  locale: Locale;
  score: number;
  content: string;
}

export interface SearchMatch {
  docId: string;
  score: number;
  type: SearchMatchType;
  fragments: SearchMatchFragment[];
  pathToViewObject?: string;
}

export type SearchFilters = ListFilters &
  Filters<{
    text: string;
    types: SearchMatchType[];
    accountIds: string[];
    businessCardIds: string[];
    fromDate: number;
    toDate: number;
  }>;

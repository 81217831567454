import React, { useEffect } from 'react';
import { HomeLayout } from 'components/features/home/components/layout';
import { ViewField } from 'components/elements/viewFields/ViewField';
import {
  Container,
  ContentContainer,
  Divider,
  Title,
  TitleContainer,
} from 'components/features/home/components';
import { RouteNames, URLS } from 'defenitions/routes';
import { useAppDispatch, useAppSelector } from 'store';
import { getAccount } from 'store/features/users';
import { getAccountCurrent } from 'store/features/users/actions';
import { Loader } from 'components/shared/loader';
import { useTranslation } from 'react-i18next';

export const Account = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const account = useAppSelector(getAccount);
  useEffect(() => {
    dispatch(getAccountCurrent());
  }, [dispatch]);
  return (
    <HomeLayout>
      {!account && <Loader show global />}
      <Container item container>
        <TitleContainer>
          <Title>{RouteNames[URLS.account]}</Title>
        </TitleContainer>
        <Divider />
        <ContentContainer item container>
          <ViewField
            title={'Аккаунт'}
            fields={[
              { field: 'Наименование:', value: account?.name },
              { field: 'Страна:', value: t(`country.${account?.country}`) },
              {
                field: 'Электронная почта:',
                value: account?.email,
                isEmail: true,
              },
            ]}
          />
          {account?.owner && (
            <ViewField
              title={'Владелец'}
              fields={[
                { field: 'Имя:', value: account.owner.name },
                { field: 'Фамилия:', value: account.owner.lastName },
                {
                  field: 'Электронная почта:',
                  value: account.owner.id,
                  isEmail: true,
                },
              ]}
            />
          )}
        </ContentContainer>
      </Container>
    </HomeLayout>
  );
};

import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import { ExpandMore } from 'components/elements/collapse';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { NotificationSettingTitle } from 'components/features/settings/components';
import Collapse from '@mui/material/Collapse';

export const StatsCollapseLayout = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(!open);

  return (
    <>
      <Grid item container xs alignItems={'center'} p={2}>
        <ExpandMore
          style={{ padding: 0, width: 30, height: 30, margin: 0 }}
          onClick={handleClickOpen}
          expand={open}
          aria-expanded={open}
          aria-label="show more"
        >
          <ExpandMoreRoundedIcon style={{ fill: '#00618E' }} />
        </ExpandMore>
        <NotificationSettingTitle
          style={{ color: '#00618E', cursor: 'pointer' }}
          onClick={handleClickOpen}
        >
          {title}
        </NotificationSettingTitle>
      </Grid>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ width: '100%' }}
      >
        <Grid item container>
          {children}
        </Grid>
      </Collapse>
    </>
  );
};

import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';
import { IAttachment } from 'interfaces/Attachment';

const localeValidate = (maxLength: number) =>
  yup.object().shape(
    {
      ru: yup.string().when('en', {
        is: (en: string) => !en,
        then: yup
          .string()
          .max(maxLength, ErrorMessages.maxLength(maxLength))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
      en: yup.string().when('ru', {
        is: (ru: string) => !ru,
        then: yup
          .string()
          .max(maxLength, ErrorMessages.maxLength(maxLength))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
    },
    [
      ['ru', 'en'],
      ['en', 'ru'],
    ],
  );

export const commentValidationSchema = yup.object().shape(
  {
    content: yup.object().when(['attachments'], {
      is: (attachments: IAttachment[]) => !attachments.length,
      then: localeValidate(4000),
    }),
    attachments: yup.array().when(['content'], {
      is: (content: Record<'ru' | 'en', string>) =>
        !content?.ru && !content?.en,
      then: yup.array().min(1, ErrorMessages.required),
    }),
  },
  [
    ['content', 'attachments'],
    ['attachments', 'content'],
  ],
);

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { ReactComponent as NewsLogo } from 'assets/icons/business.svg';
import { ReactComponent as SubscriptionsLogo } from 'assets/icons/subscriptionsIcon.svg';
import { ReactComponent as ImportantLogo } from 'assets/icons/warningIcon.svg';
import { MenuItemLink } from 'components/elements/navigationMenu/menuItem';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { URLS } from 'defenitions/routes';
import { MENU_WIDTH } from 'services/theme';
import { useAppSelector } from 'store';
import { getAccount } from 'store/features/users';
import { useTranslation } from 'react-i18next';
import { MenuAd } from 'components/elements/ads';

const Container = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: '20px 13px',
  borderRight: '1px solid #dddddd',
  width: MENU_WIDTH,
  background: theme.palette.background.paper,
}));

const Divider = styled('div')({
  borderBottom: '1px solid #dddddd',
});

const Title = styled('div')({
  margin: 0,
  padding: '0 24px',
  fontWeight: '700',
  fontSize: 18,
  color: '#3d3d3d',
});

export const NavigationMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const account = useAppSelector(getAccount);

  return (
    <Container>
      <Title>{t('feedSoftware')}</Title>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <MenuItemLink
        link={URLS.newsImportant}
        logo={<ImportantLogo />}
        title={'Системные новости'}
      />
      <MenuItemLink
        link={URLS.news}
        logo={<NewsLogo />}
        title={'Новостная лента'}
      />
      {!account?.isSystemAccount && (
        <MenuItemLink
          link={URLS.newsSubscriptions}
          logo={<SubscriptionsLogo />}
          title={'Подписки'}
        />
      )}
      <CreateButton
        fullWidth
        withPlusIcon
        style={{ marginTop: 18 }}
        text={'Создать новость'}
        onClick={() => navigate(URLS.createNew)}
      />
      <Divider sx={{ mt: '18px' }} />
      <MenuAd />
    </Container>
  );
};

import React, { MouseEvent } from 'react';
import Snackbar from 'services/Snackbar';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import { getLocaleValue } from 'utils/form';
import { t } from 'i18next';
import { ISoftwareCardShort } from 'interfaces/Software';
import { styled, Typography } from '@mui/material';

const SoftwareCard = styled(Typography)({
  color: '#00618e',
  textDecoration: 'none',
}) as typeof Typography;

const showNotification = (event: MouseEvent<HTMLAnchorElement> | undefined) => {
  event?.preventDefault();
  Snackbar.show(t('hidden card'), 'warning');
};

export const softwareLinks = (softwareCards?: ISoftwareCardShort[]) =>
  softwareCards?.map((card, index) => (
    <>
      <SoftwareCard
        key={card.id}
        component={Link}
        to={generatePath(URLS.viewSoftwareCard, { id: card.id })}
        onClick={
          card?.hidden && !card?.cardBelongToCurrentAccount
            ? showNotification
            : undefined
        }
      >
        {getLocaleValue(card.name)}
      </SoftwareCard>
      {index === softwareCards.length - 1 ? '' : ', '}
    </>
  ));

import React from 'react';
import { ReactComponent as LabelBookIcon } from 'assets/logos/LabelCodingAvatar.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { Avatar, styled, SxProps, Theme } from '@mui/material';
import { IComplaint } from 'interfaces/Complaint';
import { getNameInitials } from 'utils/user';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';

const Root = styled('div')({
  padding: '8px 16px',
  cursor: 'pointer',
});

const Creator = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
});

const CreatorAvatar = styled(Avatar)({
  height: 40,
  width: 40,
});

const Container = styled('div')({
  flex: 1,
});

const Name = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '500',
  fontSize: 14,
  color: '#3d3d3d',
});

const WithIconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const Reason = styled('div')({
  fontWeight: '400',
  fontSize: 14,
  color: '#000000',
});

type Props = {
  sx?: SxProps<Theme>;
  complaint: IComplaint;
  onClick?: (id: string) => void;
};

export const SameComplaint = ({ sx = [], complaint, onClick }: Props) => {
  const { content, createdDateTime, creator } = complaint;

  const avatar = creator?.logo?.path;

  const firstName = creator?.name || '';
  const lastName = creator?.lastName || '';
  const name = creator ? `${firstName} ${lastName}`.trim() : 'LabelBook';
  const initials = getNameInitials(name);

  const datetime =
    differenceInDays(new Date(), createdDateTime) === 0
      ? formatDistanceToNowStrict(createdDateTime, { locale: ru })
      : format(createdDateTime, 'dd MMMM yyyy');

  const reason = content.ru || content.en || '';

  const handleClick = () => onClick?.(complaint.id);

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]} onClick={handleClick}>
      <Creator sx={{ mb: 2 }}>
        {creator ? (
          <CreatorAvatar src={avatar}>{initials}</CreatorAvatar>
        ) : (
          <LabelBookIcon width={55} height={55} />
        )}
        <Container>
          <Name>{name}</Name>
          <WithIconContainer>
            <TimeIcon />
            {datetime}
          </WithIconContainer>
        </Container>
      </Creator>
      <Reason>{reason}</Reason>
    </Root>
  );
};

import * as React from 'react';
import Box from '@mui/material/Box';
import {
  CreateQuestionSubscription,
  QuestionSubscription,
} from 'interfaces/Question';
import { getLocaleValue } from 'utils/form';
import Collapse from '@mui/material/Collapse';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { ExpandMore } from 'components/elements/collapse';
import { Checkbox } from 'components/shared/checkbox';
import { Title } from 'components/elements/tree/components';

type Props = {
  data: QuestionSubscription;
  onAddSubscriptions: (value: CreateQuestionSubscription) => void;
  onRemoveSubscription: (value: CreateQuestionSubscription) => void;
  subscriptionIds: CreateQuestionSubscription[];
};
export const SubscriptionTreeView = ({
  data,
  subscriptionIds,
  onAddSubscriptions,
  onRemoveSubscription,
}: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const getChildrenCount = (node: any, count = 0) => {
    if (!node.children) {
      return 1;
    }
    for (const child of node.children) {
      count += getChildrenCount(child);
    }
    return count;
  };
  const childLength = getChildrenCount(data);
  const selectedLength = subscriptionIds.filter(
    (el) => el.categoryId === data.categoryId,
  ).length;
  const isChildSelected = subscriptionIds.filter(
    (el) => el.categoryId === data.categoryId && el.subcategoryId.length === 1,
  ).length;
  const isChecked =
    childLength === selectedLength || data.children?.length === isChildSelected;
  const isIndeterminate = !!(selectedLength && selectedLength !== childLength);

  const Children = (child: QuestionSubscription[]) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {child.map((node) => {
          const isChildSelected = subscriptionIds.filter(
            (el) =>
              el.categoryId === node.categoryId &&
              el.subcategoryId.slice(0, 1) === node.subcategoryId,
          ).length;

          const isChecked =
            subscriptionIds.some(
              (el) =>
                el.subcategoryId === node.subcategoryId &&
                el.categoryId === node.categoryId,
            ) ||
            subscriptionIds.some(
              (el) =>
                el.subcategoryId === node.parent &&
                el.categoryId === node.categoryId,
            ) ||
            isChildSelected === node.children?.length;

          const isIndeterminate = subscriptionIds.some(
            (el) =>
              el.subcategoryId.slice(0, 1) === node.subcategoryId &&
              el.categoryId === node.categoryId,
          );

          const handleChange = (child: QuestionSubscription) => {
            if (child.children?.length) {
              if (isChecked || isIndeterminate) {
                onRemoveSubscription({
                  categoryId: child.categoryId,
                  subcategoryId: `${child.subcategoryId}`,
                });
                child.children?.forEach((el) => {
                  onRemoveSubscription({
                    categoryId: data.categoryId,
                    subcategoryId: `${el.subcategoryId}`,
                  });
                  el.children && handleChange(el);
                });
              } else {
                child.children?.forEach((el) => {
                  onAddSubscriptions({
                    categoryId: data.categoryId,
                    subcategoryId: `${el.subcategoryId}`,
                  });
                  el.children && handleChange(el);
                });
              }
            } else {
              isChecked || isIndeterminate
                ? onRemoveSubscription({
                    categoryId: data.categoryId,
                    subcategoryId: `${child.subcategoryId}`,
                  })
                : onAddSubscriptions({
                    categoryId: data.categoryId,
                    subcategoryId: `${child.subcategoryId}`,
                  });
            }
          };
          return (
            <>
              <Checkbox
                label={<Title>{getLocaleValue(node.value) || ''}</Title>}
                checked={isChecked}
                indeterminate={isChecked ? undefined : isIndeterminate}
                onChange={() => handleChange(node)}
              />
              {node.children && Children(node.children)}
            </>
          );
        })}
      </Box>
    );
  };

  const handleChange = (child: QuestionSubscription) => {
    if (isIndeterminate || isChecked) {
      child.children?.forEach((el) => {
        onRemoveSubscription({
          categoryId: child.categoryId,
          subcategoryId: `${el.subcategoryId}`,
        });
        if (el.children) handleChange(el);
      });
    } else {
      child.children?.forEach((el) => {
        onAddSubscriptions({
          categoryId: child.categoryId,
          subcategoryId: `${el.subcategoryId}`,
        });
        if (el.children) handleChange(el);
      });
    }
  };
  return (
    <div>
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Checkbox
          label={<Title>{getLocaleValue(data.value) || ''}</Title>}
          checked={isChecked}
          indeterminate={isChecked ? undefined : isIndeterminate}
          onChange={() => handleChange(data)}
        />
        <ExpandMore
          style={{ padding: 0, width: 30, height: 30 }}
          onClick={handleClick}
          expand={open}
          aria-expanded={open}
          aria-label="show more"
        >
          <ExpandMoreRoundedIcon />
        </ExpandMore>
      </div>
      {data.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {Children(data.children)}
        </Collapse>
      )}
    </div>
  );
};

import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';

export const signinValidationSchema = yup
  .object({
    id: yup
      .string()
      .required(ErrorMessages.required)
      .email(ErrorMessages.emailNotCorrect),
    password: yup.string().required(ErrorMessages.required),
    rememberMe: yup.boolean().required(ErrorMessages.required),
  })
  .required();

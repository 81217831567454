import React from 'react';
import Dialog from '@mui/material/Dialog';
import { default as MuiDialogTitle } from '@mui/material/DialogTitle';
import { default as MuiDialogActions } from '@mui/material/DialogActions';
import { styled } from '@mui/system';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { ActionButton } from 'components/shared/buttons/ActionButton';

const DialogTitle = styled(MuiDialogTitle)({
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: '#e4e4e4',
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 16,
  paddingRight: 16,
  textAlign: 'center',
  fontWeight: '500',
  fontSize: 16,
  color: '#000',
});

const DialogContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: 32,
  paddingBottom: 32,
  paddingLeft: 16,
  paddingRight: 16,
  fontWeight: '600',
  fontSize: 18,
  color: '#4b4b4b',
});

const DialogActions = styled(MuiDialogActions)({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 0,
  paddingBottom: 24,
  paddingLeft: 16,
  paddingRight: 16,
});

type Props = {
  open: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
};

export const CloseQuestionModal = ({ open, onConfirm, onClose }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Закрыть вопрос</DialogTitle>
      <DialogContent>Вы уверены, что хотите закрыть вопрос?</DialogContent>
      <DialogActions>
        <CreateButton fullWidth onClick={onClose} text={'Отменить'} />
        <ActionButton fullWidth onClick={onConfirm} text={'Подтвердить'} />
      </DialogActions>
    </Dialog>
  );
};

import {
  INotificationItem,
  INotificationsFilters,
} from 'interfaces/Notifications';

export enum ActionTypes {
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_UNREAD_COUNT = 'GET_NOTIFICATIONS_UNREAD_COUNT',
  GET_NOTIFICATION = 'GET_NOTIFICATION',
}

export interface INotificationsState {
  filters: INotificationsFilters;
  notifications: INotificationItem[];
  loading: boolean;
  unreadNotificationsCount: number;
  notification: INotificationItem | null;
  hasMore: boolean;
}

export const initialNotificationsState: INotificationsState = {
  filters: {
    limit: 10,
    offset: 0,
  },
  notifications: [],
  loading: false,
  unreadNotificationsCount: 0,
  notification: null,
  hasMore: false,
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import qs from 'qs';
import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getComplaintsAccountsHasMore,
  getComplaintsAccountsList,
  getComplaintsAccountsLoading,
} from 'store/features/complaints';
import { getComplaintsAccountsRatings } from 'store/features/complaints/actions';
import { ModerationLayout, RatingsFilters, RatingsList } from '../components';
import { ListFilters } from 'interfaces/Filters';
import { NonNullableRecord } from 'utils/types';
import { TRatingsFilters } from 'interfaces/Complaint';
import { useNavigate } from 'react-router-dom';
import { isEqual, pick } from 'lodash';
import { RatingsHeader } from '../components/RatingsHeader';
import { TabsValue } from 'components/features/moderation/containers/ModerationComplaints';

export const ModerationRatings = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const search = useQuery();

  const filters = useMemo(
    () => ({
      accountIds: search.accountIds,
      text: search.text,
    }),
    [search.accountIds, search.text],
  ) as TRatingsFilters;

  const [listFilters, setListFilters] = useState<
    NonNullableRecord<ListFilters>
  >({
    offset: 0,
    limit: 10,
  });

  const accounts = useAppSelector(getComplaintsAccountsList);
  const isLoading = useAppSelector(getComplaintsAccountsLoading);
  const hasMore = useAppSelector(getComplaintsAccountsHasMore);

  const isFetching = listFilters.offset === 0 && isLoading;
  const isRefetching = listFilters.offset !== 0 && isLoading;

  const handleListFiltersChange = () => {
    setListFilters((prev) => ({
      offset: prev.offset + prev.limit,
      limit: prev.limit,
    }));
  };

  const handleTextChange = (text: string) => {
    setListFilters({ offset: 0, limit: 10 });
    navigate({
      search: qs.stringify(
        {
          ...filters,
          text: text || null,
        },
        { skipNulls: true },
      ),
    });
  };

  const handleFiltersChange = ({
    accountIds,
  }: Pick<TRatingsFilters, 'accountIds'>) => {
    setListFilters({ offset: 0, limit: 10 });
    navigate({
      search: qs.stringify(
        {
          ...filters,
          accountIds,
        },
        { skipNulls: true },
      ),
    });
  };

  const handleAccountRatingClick = (accountId: string) => {
    navigate({
      pathname: '/moderation/complaints',
      search: qs.stringify({
        accountIds: [accountId],
        tab: TabsValue.Processed,
      }),
    });
  };

  const handleUserRatingClick = (userId: string) => {
    navigate({
      pathname: '/moderation/complaints',
      search: qs.stringify({ userIds: [userId], tab: TabsValue.Processed }),
    });
  };

  const prevRef = useRef<TRatingsFilters>({});

  useEffect(() => {
    const currentFilters: TRatingsFilters = {
      ...filters,
      ...listFilters,
      withUsers: true,
    };

    if (!isEqual(prevRef.current, currentFilters)) {
      dispatch(getComplaintsAccountsRatings(currentFilters));
      prevRef.current = currentFilters;
    }
  }, [dispatch, filters, listFilters]);

  return (
    <ModerationLayout>
      <RatingsHeader
        defaultValue={filters.text}
        onTextChange={handleTextChange}
      />
      <RatingsFilters
        sx={{ mb: 3 }}
        filters={pick(filters, ['accountIds'])}
        onChange={handleFiltersChange}
      />
      <RatingsList
        accounts={accounts}
        loading={isFetching}
        refetching={isRefetching}
        hasMore={hasMore}
        next={handleListFiltersChange}
        onAccountRatingClick={handleAccountRatingClick}
        onUserRatingClick={handleUserRatingClick}
      />
    </ModerationLayout>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReactionType } from 'interfaces/Post';
import {
  CreateAnswer,
  CreateQuestion,
  CreateQuestionSubscription,
  EditAnswerApi,
  Question,
} from 'interfaces/Question';
import { QuestionsFilters } from 'interfaces/QuestionsFilters';
import { actionHandler } from 'store/utils/actionHandler';
import {
  closeQuestionEndpoint,
  createAnswerEndpoint,
  createQuestionEndpoint,
  getAnswersEndpoint,
  getIncomingQuestionsEndpoint,
  getMyQuestionsEndpoint,
  getQuestionByIdEndpoint,
  getQuestionsEndpoint,
  reactToQuestionPostEndpoint,
  uploadAnswerAttachmentsEndpoint,
  uploadQuestionAttachmentsEndpoint,
  getQuestionSubscriptionsEndpoint,
  createQuestionSubscriptionsEndpoint,
  editAnswerEndpoint,
  acceptAnswerEndpoint,
} from './api';
import { ActionTypes } from './config';

export const getQuestionById = createAsyncThunk(
  ActionTypes.GET_QUESTION_BY_ID,
  (id: Question['id'], { rejectWithValue }) =>
    actionHandler(() => getQuestionByIdEndpoint(id), rejectWithValue),
);

export const getQuestions = createAsyncThunk(
  ActionTypes.GET_QUESTIONS,
  (filters: QuestionsFilters, { rejectWithValue }) =>
    actionHandler(() => getQuestionsEndpoint(filters), rejectWithValue),
);

export const getMyQuestions = createAsyncThunk(
  ActionTypes.GET_QUESTIONS,
  (filters: QuestionsFilters, { rejectWithValue }) =>
    actionHandler(() => getMyQuestionsEndpoint(filters), rejectWithValue),
);

export const getIncomingQuestions = createAsyncThunk(
  ActionTypes.GET_QUESTIONS,
  (filters: QuestionsFilters, { rejectWithValue }) =>
    actionHandler(() => getIncomingQuestionsEndpoint(filters), rejectWithValue),
);

export const createQuestion = createAsyncThunk(
  ActionTypes.CREATE_QUESTION,
  (question: CreateQuestion, { rejectWithValue }) =>
    actionHandler(
      () =>
        createQuestionEndpoint({
          ...question,
          receiverBusinessCardId: question.receiverBusinessCardId?.id,
          receiverSoftwareCardId: question.receiverSoftwareCardId?.code,
          categoryId: question.categoryId.code,
          businessCardId: question.businessCardId.code,
          subcategoryId: question.subcategoryId.code,
          softwareCardIds: question.softwareCardIds.map(({ code }) => code),
          applicationAreas: question.applicationAreas.map(({ code }) => code),
          programmingLanguages: question.programmingLanguages.map(
            ({ code }) => code,
          ),
        }),
      rejectWithValue,
    ),
);

export const reactToQuestionPost = createAsyncThunk(
  ActionTypes.REACT_TO_QUESTION_POST,
  ({ id, type }: { id: string; type: ReactionType }, { rejectWithValue }) =>
    actionHandler(() => reactToQuestionPostEndpoint(id, type), rejectWithValue),
);

export const closeQuestion = createAsyncThunk(
  ActionTypes.CLOSE_QUESTION,
  (id: Question['id'], { rejectWithValue }) =>
    actionHandler(() => closeQuestionEndpoint(id), rejectWithValue),
);

export const uploadQuestionAttachments = createAsyncThunk(
  ActionTypes.UPLOAD_QUESTION_ATTACHMENTS,
  (data: FormData, { rejectWithValue }) =>
    actionHandler(
      () => uploadQuestionAttachmentsEndpoint(data),
      rejectWithValue,
    ),
);

export const getAnswers = createAsyncThunk(
  ActionTypes.GET_ANSWERS,
  (questionId: Question['id'], { rejectWithValue }) =>
    actionHandler(() => getAnswersEndpoint(questionId), rejectWithValue),
);

export const createAnswer = createAsyncThunk(
  ActionTypes.CREATE_ANSWER,
  (answer: CreateAnswer, { rejectWithValue }) =>
    actionHandler(
      () =>
        createAnswerEndpoint({
          ...answer,
          businessCardId: answer.businessCardId.code,
          softwareCardIds: answer.softwareCardIds.map(({ code }) => code),
        }),
      rejectWithValue,
    ),
);

export const editAnswer = createAsyncThunk(
  ActionTypes.EDIT_ANSWER,
  (answer: EditAnswerApi, { rejectWithValue }) =>
    actionHandler(() => editAnswerEndpoint(answer), rejectWithValue),
);

export const uploadAnswerAttachments = createAsyncThunk(
  ActionTypes.UPLOAD_ANSWER_ATTACHMENTS,
  (data: FormData, { rejectWithValue }) =>
    actionHandler(() => uploadAnswerAttachmentsEndpoint(data), rejectWithValue),
);

export const getQuestionSubscriptions = createAsyncThunk(
  ActionTypes.GET_QUESTION_SUBSCRIPTIONS,
  (any, { rejectWithValue }) =>
    actionHandler(() => getQuestionSubscriptionsEndpoint(), rejectWithValue),
);

export const createQuestionSubscriptions = createAsyncThunk(
  ActionTypes.CREATE_QUESTION_SUBSCRIPTIONS,
  (subscriptions: CreateQuestionSubscription[], { rejectWithValue }) =>
    actionHandler(
      () => createQuestionSubscriptionsEndpoint(subscriptions),
      rejectWithValue,
    ),
);

export const acceptAnswer = createAsyncThunk(
  ActionTypes.ACCEPT_ANSWER,
  (
    { questionId, answerId }: { questionId: string; answerId: string },
    { rejectWithValue },
  ) =>
    actionHandler(
      () => acceptAnswerEndpoint(questionId, answerId),
      rejectWithValue,
    ),
);

import React from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as Logo } from 'assets/logos/auth.svg';
import Box from '@mui/material/Box';

type Props = {
  children: JSX.Element;
};

export const SignInLayout = ({ children }: Props) => {
  return (
    <Grid
      container
      style={{ background: '#F5F5F5', height: '100vh', overflow: 'hidden' }}
    >
      <Box
        component={Grid}
        item
        lg={7}
        display={{ xs: 'none', sm: 'none', lg: 'block' }}
        style={{ maxHeight: '100vh', zIndex: 1 }}
      >
        <Logo style={{ height: '100vh' }} />
      </Box>
      <Grid
        item
        lg={5}
        xs={12}
        style={{ background: '#F5F5F5', maxHeight: '100vh' }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

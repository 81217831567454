import React from 'react';
import { styled } from '@mui/system';
import { TextButton } from 'components/shared/buttons/TextButton';
import { ReactComponent as ForwardIcon } from 'assets/icons/forwardIcon.svg';
import { useModalActions } from 'utils/hooks';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'defenitions/routes';

const Container = styled('div')({
  paddingTop: 20,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '0px 70px',
});

const InfoText = styled('div')({
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  color: '#24262A',
  textAlign: 'center',
});

export const Info = () => {
  const { isOpen, onClickOpen, onClickClose } = useModalActions();
  const navigate = useNavigate();

  const onClickConnect = () => {
    navigate(URLS.signup);
  };
  return (
    <Container>
      <InfoText>
        Проект является аналогом Thecodeproject для разработчиков, отличается
        наличием маркетплейса.
      </InfoText>
      <TextButton
        style={{
          background: 'none',
          color: '#000000',
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '16px',
          textTransform: 'none',
        }}
        disableRipple
        IconComponent={ForwardIcon}
        fill={'black'}
        onClick={onClickOpen}
        text={'Подробнее о проекте'}
      />
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={isOpen}
        onClose={onClickClose}
        keepMounted={false}
        PaperProps={{
          sx: {
            boxShadow: '-24px 64px 80px rgba(21, 56, 150, 0.16)',
            borderRadius: '20px',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 32,
            lineHeight: '40px',
            color: '#000000',
            textAlign: 'center',
            paddingTop: 5,
          }}
        >
          Подробнее о проекте
          <IconButton
            aria-label="close"
            onClick={onClickClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[400],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '20px 80px 50px' }}>
          <Box>
            <p>
              Проект является аналогом Thecodeproject для разработчиков,
              отличается наличием маркетплейса. Проект решает проблему
              отсутствия единой точки, консолидирующей информацию, связанную с
              разработкой интеграционных решений и решений по автоматизации
              бизнес-процессов маркировки. Данный проект не является площадкой
              по продаже и продвижению готовых решений и систем. Для последнего
              мы разработали проект{' '}
              <a
                href={'https://labelbook.ru/'}
                style={{ textDecoration: 'none' }}
              >
                Labelbook
              </a>
              , социальную сеть для бизнеса, предоставляющую маркетплейс для
              решения подобных задач.
            </p>
            <p>
              В рамках{' '}
              <a
                href={'https://labelcoding.ru/'}
                style={{ textDecoration: 'none' }}
              >
                LabelCoding
              </a>{' '}
              участникам предоставляется возможность обсуждать вопросы,
              связанные с разработкой, обменом исходными кодами, примерами
              реализации тех или иных задач, а также с продвижением своих
              решений, представляющих собой компоненты, реализующие определенные
              функции, SDK- или OpenSource-продукты.
            </p>
            <p>
              Важнейшей задачей проекта является консолидация комьюнити
              разработчиков, вовлеченных в реализацию как интеграционных
              решений, так и отдельных продуктов, обеспечивающих внедрение
              маркировки для участников оборота продукции, подлежащей
              обязательной маркировке.
            </p>
          </Box>
          <Box>
            <TextButton
              style={{
                background: 'none',
                color: '#2F5BCF',
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '16px',
                textTransform: 'none',
                paddingLeft: 0,
                paddingTop: 28,
              }}
              disableRipple
              fill={'#2F5BCF'}
              IconComponent={ForwardIcon}
              onClick={onClickConnect}
              text={'Присоединяйтесь'}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

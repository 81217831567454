import React, { useCallback, useEffect, useState } from 'react';
import { HomeLayout } from 'components/features/home/components/layout';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editUserValidationSchema } from 'components/features/home/utils/validationSchema';
import { ICreateUser, Roles } from 'interfaces/Users';
import { Form, FormActions } from 'components/features/home/components';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { TextFieldInput } from 'components/shared/inputs';
import { SelectTextFields } from 'components/shared/selects';
import { RolesBook } from 'defenitions/main';
import Snackbar from 'services/Snackbar';
import { URLS } from 'defenitions/routes';
import { useAppDispatch, useAppSelector } from 'store';
import { createUser, updateUser } from 'store/features/users/actions';
import { getCurrent } from 'store/features/users';
import { LogoUploader } from 'components/elements/uploaders/LogoUploader';
import { IAttachment, IBusinessAttachment } from 'interfaces/Attachment';
import { uploadUserLogo } from 'store/features/users/actions';
import { AxiosResponse } from 'axios';

export const EditUser = () => {
  const { id } = useParams();
  const profile = useAppSelector(getCurrent);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [logo, setLogo] = useState<IAttachment | undefined>(profile?.logo);
  const handleUploadLogo = useCallback(
    async (formData: FormData) => {
      const { payload } = await dispatch(uploadUserLogo(formData));
      const { data } = payload as AxiosResponse<IBusinessAttachment>;
      setLogo(data);
    },
    [dispatch],
  );

  const handleDeleteLogo = () => setLogo(undefined);
  const isAdminCurrent = profile?.role === Roles.ADMINISTRATOR;
  const {
    control,
    handleSubmit,
    trigger,
    formState: { isValid, errors, isSubmitting },
  } = useForm<ICreateUser>({
    mode: 'onChange',
    resolver: yupResolver(editUserValidationSchema),
    defaultValues: id
      ? { ...profile }
      : { role: isAdminCurrent ? Roles.OPERATOR : undefined },
  });

  const onSuccess = () => {
    Snackbar.show(
      id ? t('notification.user updated') : t('notification.user created'),
      'success',
    );
    navigate(id ? URLS.profile : URLS.users);
  };

  const handleSaveUser: SubmitHandler<ICreateUser> = async (data) => {
    const result = id
      ? await dispatch(updateUser({ ...data, logoId: logo?.id })).unwrap()
      : await dispatch(createUser(data)).unwrap();

    if (result?.data) onSuccess();
  };

  const onClickCancel = () => navigate(-1);
  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <HomeLayout>
      <Form>
        <Grid container spacing={4}>
          {id && (
            <Grid item xs={12}>
              <LogoUploader
                logoUrl={logo?.path}
                onClickUploadLogo={handleUploadLogo}
                onClickDeleteLogo={handleDeleteLogo}
                text={t('logoTexts.profile.text')}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <TextFieldInput
                  {...rest}
                  inputRef={ref}
                  placeholder={t('formFieldsPlaceholder.name')}
                  label={t('formFields.name')}
                  errorMessage={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <TextFieldInput
                  {...rest}
                  inputRef={ref}
                  placeholder={t('formFieldsPlaceholder.lastName')}
                  label={t('formFields.lastName')}
                  errorMessage={errors.lastName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="role"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <SelectTextFields
                  {...rest}
                  disabled={isAdminCurrent || !!id}
                  options={RolesBook(!!id)}
                  inputRef={ref}
                  placeholder={t('formFieldsPlaceholder.role')}
                  label={t('formFields.role')}
                  errorMessage={errors.role?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue={id ? profile?.id : ''}
              render={({ field: { ref, ...rest } }) => (
                <TextFieldInput
                  {...rest}
                  disabled={!!id}
                  inputRef={ref}
                  placeholder={t('formFieldsPlaceholder.email')}
                  label={t('formFields.email')}
                  errorMessage={errors.email?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormActions>
          <CreateButton
            fullWidth
            type="button"
            text={t('cancel')}
            onClick={onClickCancel}
          />
          <ActionButton
            fullWidth
            disabled={!isValid || isSubmitting}
            text={t('save')}
            onClick={handleSubmit(handleSaveUser)}
          />
        </FormActions>
      </Form>
    </HomeLayout>
  );
};

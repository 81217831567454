import React from 'react';
import {
  Container,
  Divider,
  SubTitle,
} from 'components/features/business/shared/item/components';
import Grid from '@mui/material/Grid';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { useTranslation } from 'react-i18next';
import { ICodeExamples } from 'interfaces/Software';
import { CodeCard } from 'components/features/business/shared/item/view/codeExample/components/CodeCard';

type Props = {
  codeExamples: ICodeExamples[];
  onClickNext: () => void;
  onClickBack: () => void;
};

export const CodeExamples = ({
  onClickNext,
  onClickBack,
  codeExamples,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Container container>
      {codeExamples.length === 0 && (
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignContent={'center'}
          style={{ height: 'calc(100vh - 394px)' }}
        >
          <SubTitle>Примеры кода отсутсвуют</SubTitle>
        </Grid>
      )}
      {codeExamples.map((el, index) => (
        <CodeCard key={index} codeExample={el} />
      ))}
      <Divider />
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            text={t('actions.back')}
            onClick={onClickBack}
          />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            onClick={onClickNext}
            text={t('actions.next')}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

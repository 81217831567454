import { useCallback, useEffect, useState } from 'react';
import { IAttachment } from 'interfaces/Attachment';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'store';
import { AsyncThunkAction } from '@reduxjs/toolkit';

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return (): void => {
      clearTimeout(handler);
    };
  }, [delay, value]);
  return debounceValue;
};

export const useInterval = (
  intervalIsOn: boolean,
  callback: () => void,
  delay: number,
) => {
  useEffect(() => {
    if (delay !== null && callback !== null && intervalIsOn) {
      const interval = setInterval(callback, delay);
      return () => {
        clearInterval(interval);
      };
    }
  }, [delay, callback, intervalIsOn]);
};

export const useModalActions = (
  submitAction?: () => void,
  defaultOpen = false,
) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const onClickOpen = () => setIsOpen(true);
  const onClickClose = () => setIsOpen(false);
  const onClickSubmit = () => {
    submitAction && submitAction();
    onClickClose();
  };

  return {
    isOpen,
    onClickClose,
    onClickOpen,
    onClickSubmit,
  };
};

export const useAttachments = (
  uploadAction: (
    formData: FormData,
  ) => AsyncThunkAction<
    void | AxiosResponse<any, any>,
    FormData,
    Record<string, never>
  >,
) => {
  const dispatch = useAppDispatch();
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const onUploadAttachments = useCallback(
    async (formData: FormData) => {
      const { payload } = await dispatch(uploadAction(formData));
      const { data } = payload as AxiosResponse<IAttachment[]>;
      data && setAttachments((prevState) => [...prevState, ...data]);
    },
    [dispatch, uploadAction],
  );
  const onRemoveAttachments = (id: string) =>
    setAttachments((prevState) => prevState.filter((el) => el.id !== id));

  return {
    attachments,
    onUploadAttachments,
    onRemoveAttachments,
  };
};

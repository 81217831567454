import React from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

type MenuItemProps = {
  active?: boolean;
  subtitle?: boolean;
};

const MenuItem = styled(Link)<MenuItemProps>(({ active = false }) => ({
  marginBottom: 18,
  paddingLeft: 24,
  height: 40,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
  color: '#000000',
  background: active ? '#F5F5F5' : 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  borderRadius: 5,

  '&:hover': {
    background: '#F5F5F5',
  },

  '&:last-of-type': {
    marginBottom: 0,
  },
}));

const MenuItemIcon = styled('div')({
  width: 30,
  height: 30,
  background: '#F5F5F5',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.1)',
});

type Props = {
  link: string;
  title: string;
  logo?: React.ReactNode;
  onClick?: () => void;
};

export const MenuItemLink = ({ link, title, logo, onClick }: Props) => {
  const location = useLocation();
  const splitedLocation = location.pathname.split('/');
  const section = `/${splitedLocation[1]}/${splitedLocation[2]}`;
  const active =
    location.search === link || location.pathname === link || section === link;

  return (
    <MenuItem to={link} active={active} onClick={onClick}>
      {logo && <MenuItemIcon sx={{ mr: '21px' }}>{logo}</MenuItemIcon>}
      {title}
    </MenuItem>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { AxiosResponse } from 'axios';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getAnswerRequestById,
  getServiceRequestById,
  likeComment,
  likeService,
  likeServiceRequest,
  marketplaceAcceptAnswer,
  marketplaceCloseAnswer,
  marketplaceCompleteAnswer,
  marketplaceDeclineAnswer,
  marketplaceDeleteAnswer,
  marketplaceGetReviewsAnswer,
  marketplacePublishAnswer,
  marketplaceShareContacts,
  marketplaceWorkAnswer,
} from 'store/features/marketplace/actions';
import {
  getRequestAnswer,
  getReviews,
  getServiceRequest,
  isRequestAnswerLoading,
} from 'store/features/marketplace';
import {
  AnswerStatuses,
  IServiceRequest,
  IAnswer,
} from 'interfaces/Marketplace';
import { RouteNames, URLS } from 'defenitions/routes';
import { Loader } from 'components/shared/loader';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import { ServiceHeader } from 'components/features/marketplace/components/ServiceHeader';
import { ServiceRequestDetailedCard } from 'components/features/marketplace/components/cards/ServiceRequestDetailedCard';
import { ServiceRequestAnswerDetailedCard } from 'components/features/marketplace/components/cards/ServiceRequestAnswerDetailedCard';
import Snackbar from 'services/Snackbar';
import { getCurrent } from 'store/features/users';
import { IComment } from 'interfaces/Comment';
import { Comments } from 'components/features/marketplace/components/comments/Comments';
import { useTranslation } from 'react-i18next';
import { useModalActions } from 'utils/hooks';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { CreateReview } from 'components/features/marketplace/components/feedBack';
import isEmpty from 'lodash/isEmpty';
import { ReviewsView } from 'components/features/marketplace/components/feedBack/Reviews';
import { useQuery } from 'hooks/useQuery';
import { ReactionType } from 'interfaces/Post';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
});

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '40px 32px',
  gap: 16,
});

const Contacts = styled('div')({
  fontSize: '18px',
  cursor: 'pointer',
  display: 'flex',
  padding: '15px 33px',
});

const Link = styled('div')({
  color: '#00618E',
  marginRight: '5px',
});

type Params = { id: string };

export const MarketplaceOfferAnswer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
  const { leaveReview } = useQuery();
  const { isOpen, onClickOpen, onClickClose } = useModalActions();
  const {
    isOpen: isOpenComplaint,
    onClickOpen: onClickOpenComplaint,
    onClickClose: onClickCloseComplaint,
  } = useModalActions();
  const {
    isOpen: isOpenReviews,
    onClickOpen: onClickOpenReview,
    onClickClose: onClickCloseReview,
  } = useModalActions();

  const serviceRequest = useAppSelector(getServiceRequest);
  const answer = useAppSelector(getRequestAnswer);
  const reviews = useAppSelector(getReviews);

  const isLoading = useAppSelector(isRequestAnswerLoading);
  const user = useAppSelector(getCurrent);

  const { id = '' } = useParams<Params>();
  const isCurrentUserOfferAnswer = user?.id === answer?.creator?.id;

  useEffect(() => {
    getAnswer();
  }, [id]);

  useEffect(() => {
    if (answer?.offer?.id) {
      dispatch(getServiceRequestById(answer?.offer?.id));
    }
  }, [answer?.offer?.id]);

  const handleBack = () => navigate(-1);

  const getAnswer = useCallback(() => {
    dispatch(getAnswerRequestById(id));
  }, [id]);

  const handleAcceptAnswer = useCallback(async () => {
    await dispatch(
      marketplaceAcceptAnswer({
        offerId: answer!.offer.id,
        answerId: answer!.id,
      }),
    );
    getAnswer();
  }, [dispatch, getAnswer, answer]);

  const handleDeclineAnswer = useCallback(async () => {
    await dispatch(
      marketplaceDeclineAnswer({
        offerId: answer!.offer.id,
        answerId: answer!.id,
      }),
    );
    getAnswer();
  }, [dispatch, getAnswer, answer]);

  const handleCompleteAnswer = useCallback(async () => {
    await dispatch(
      marketplaceCompleteAnswer({
        offerId: answer!.offer.id,
        answerId: answer!.id,
      }),
    );
    getAnswer();
    answer && handleClickCreateReview();
  }, [dispatch, getAnswer, answer]);

  const handleDeleteAnswer = useCallback(async () => {
    await dispatch(marketplaceDeleteAnswer(answer!.id));
    handleBack();
  }, [dispatch, answer]);

  const handlePublishAnswer = useCallback(async () => {
    await dispatch(marketplacePublishAnswer(answer!.id));
    getAnswer();
  }, [dispatch, answer, getAnswer]);

  const handleCloseAnswer = useCallback(async () => {
    await dispatch(marketplaceCloseAnswer(answer!.id));
    getAnswer();
  }, [dispatch, answer, getAnswer]);

  const handleWorkAnswer = useCallback(async () => {
    await dispatch(marketplaceWorkAnswer(answer!.id));
    getAnswer();
  }, [dispatch, answer, getAnswer]);

  const handleShareContacts = useCallback(async () => {
    const res = await dispatch(
      marketplaceShareContacts({
        offerId: answer!.offer.id,
        answerId: answer!.id,
      }),
    );
    const data = res.payload as AxiosResponse;
    if (data?.status === 200) {
      Snackbar.show(t('notification.contacts shared'), 'success');
      getAnswer();
    } else {
      Snackbar.show(t('notification.error'), 'error');
    }
  }, [dispatch, getAnswer, answer]);

  const handleLikeServiceRequest = (id: IServiceRequest['id']) => {
    dispatch(likeServiceRequest({ id, type: ReactionType.LIKE }));
  };

  const handleDislikeServiceRequest = (id: IServiceRequest['id']) => {
    dispatch(likeServiceRequest({ id, type: ReactionType.DISLIKE }));
  };

  const handleLikeAnswer = (id: IServiceRequest['id']) => {
    dispatch(likeService({ id, type: ReactionType.LIKE }));
  };

  const handleDislikeAnswer = (id: IServiceRequest['id']) => {
    dispatch(likeService({ id, type: ReactionType.DISLIKE }));
  };

  const handleLikeComment = (id: IComment['id']) => {
    dispatch(likeComment({ id, type: ReactionType.LIKE }));
  };

  const handleDislikeComment = (id: IComment['id']) => {
    dispatch(likeComment({ id, type: ReactionType.DISLIKE }));
  };

  useEffect(() => {
    answer?.status === AnswerStatuses.COMPLETED &&
      dispatch(
        marketplaceGetReviewsAnswer({
          offerId: answer!.offer.id,
          answerId: answer!.id,
        }),
      );
  }, [answer]);

  const [selectedOffer, setSelectedOffer] = useState<IAnswer | null>(null);
  const handleClickCreateReview = () => {
    onClickOpen();
    setSelectedOffer(answer);
  };
  const handleClickCloseReview = () => {
    onClickClose();
    setSelectedOffer(null);
  };

  const disableComment =
    answer?.status === AnswerStatuses.DRAFT ||
    answer?.status === AnswerStatuses.COMPLETED ||
    answer?.status === AnswerStatuses.CANCELLED ||
    answer?.status === AnswerStatuses.DECLINED;
  const isAccessShareContact =
    answer?.status === AnswerStatuses.PUBLISHED ||
    answer?.status === AnswerStatuses.ACCEPTED ||
    answer?.status === AnswerStatuses.IN_WORK;

  useEffect(() => {
    !answer?.hasCurrentAccountReview &&
      answer?.status === AnswerStatuses.COMPLETED &&
      leaveReview &&
      answer &&
      handleClickCreateReview();
  }, [leaveReview, answer]);

  return (
    <MarketplaceLayout
      contentSx={{ bgcolor: (theme) => theme.palette.background.paper }}
    >
      <Container>
        <div style={{ position: 'relative' }}>
          <ServiceHeader
            sx={{ borderBottom: '1px solid #e4e4e4' }}
            onBack={handleBack}
            onMouseLeave={() => setAnchorEl(null)}
          >
            {RouteNames[URLS.marketplaceOfferAnswer]}
          </ServiceHeader>
          {!isCurrentUserOfferAnswer && (
            <MoreIcon
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
              }}
            />
          )}
        </div>
        {isLoading && <Loader sx={{ m: 'auto' }} show />}
        {serviceRequest && (
          <ServiceRequestDetailedCard
            sx={{ borderBottom: '1px solid #e4e4e4' }}
            type={t('marketplace.request')}
            request={serviceRequest}
            onLike={handleLikeServiceRequest}
            onDislike={handleDislikeServiceRequest}
            showStatus
          />
        )}
        {answer && (
          <ServiceRequestAnswerDetailedCard
            sx={{ borderBottom: '1px solid #e4e4e4' }}
            type={t('marketplace.respond')}
            answer={answer}
            onLike={handleLikeAnswer}
            onDislike={handleDislikeAnswer}
          />
        )}
        {answer && serviceRequest && (
          <Comments
            sx={{ p: '8px 32px' }}
            cardId={
              serviceRequest.canProcessByCurrentUser
                ? serviceRequest?.businessCard?.id
                : answer?.businessCard?.id
            }
            relationId={answer.id}
            comments={answer.comments}
            onLike={handleLikeComment}
            onDislike={handleDislikeComment}
            currentUser={user}
            disabled={disableComment}
          />
        )}
        {isAccessShareContact && (
          <Contacts>
            <Link onClick={handleShareContacts}>
              {t('marketplace.shareContacts.link')}
            </Link>{' '}
            {t('marketplace.shareContacts.text')}
          </Contacts>
        )}
        {!answer?.hasCurrentAccountReview &&
          answer?.status === AnswerStatuses.COMPLETED && (
            <Contacts>
              <Link onClick={handleClickCreateReview}>
                {t('modalTexts.feedback.actionTitle')}
              </Link>
            </Contacts>
          )}
        {!isEmpty(reviews) && answer?.status === AnswerStatuses.COMPLETED && (
          <Contacts>
            <Link onClick={onClickOpenReview}>{t('review.view')}</Link>
          </Contacts>
        )}
      </Container>
      {answer?.status === AnswerStatuses.PUBLISHED &&
        !isCurrentUserOfferAnswer && (
          <Actions>
            <ActionButton
              fullWidth
              text={t('actions.cancel')}
              onClick={handleDeclineAnswer}
              sx={{ width: '136px' }}
            />
            <ActionButton
              fullWidth
              text={t('actions.accept')}
              onClick={handleAcceptAnswer}
              sx={{ width: '136px' }}
            />
          </Actions>
        )}
      {answer?.status === AnswerStatuses.ACCEPTED && !isCurrentUserOfferAnswer && (
        <Actions>
          <ActionButton
            fullWidth
            text={t('actions.cancel')}
            onClick={handleDeclineAnswer}
            sx={{ width: '136px' }}
          />
        </Actions>
      )}
      {answer?.status === AnswerStatuses.ACCEPTED && isCurrentUserOfferAnswer && (
        <Actions>
          <ActionButton
            fullWidth
            text={t('actions.revoke')}
            onClick={handleCloseAnswer}
            sx={{ width: '136px' }}
          />
          <ActionButton
            fullWidth
            text={t('actions.submit')}
            onClick={handleWorkAnswer}
            sx={{ width: '136px' }}
          />
        </Actions>
      )}
      {answer?.status === AnswerStatuses.DRAFT && isCurrentUserOfferAnswer && (
        <Actions>
          <ActionButton
            fullWidth
            text={t('actions.delete')}
            onClick={handleDeleteAnswer}
            sx={{ width: '136px' }}
          />
          <ActionButton
            fullWidth
            text={t('actions.publish')}
            onClick={handlePublishAnswer}
            sx={{ width: '150px' }}
          />
        </Actions>
      )}
      {answer?.status === AnswerStatuses.PUBLISHED && isCurrentUserOfferAnswer && (
        <Actions>
          <ActionButton
            fullWidth
            text={t('actions.revoke')}
            onClick={handleCloseAnswer}
            sx={{ width: '136px' }}
          />
        </Actions>
      )}
      {answer?.status === AnswerStatuses.IN_WORK && !isCurrentUserOfferAnswer && (
        <Actions>
          <ActionButton
            fullWidth
            text={t('actions.complete')}
            onClick={handleCompleteAnswer}
            sx={{ width: '136px' }}
          />
          <ActionButton
            fullWidth
            text={t('actions.cancel')}
            onClick={handleDeclineAnswer}
            sx={{ width: '136px' }}
          />
        </Actions>
      )}
      {anchorEl && (
        <MoreMenu anchorEl={anchorEl} onClickComplaint={onClickOpenComplaint} />
      )}
      {answer && (
        <ConfirmDialog
          onClickClose={onClickCloseComplaint}
          open={isOpenComplaint}
          title={t('modalTexts.complaint.title', {
            name: getLocaleValue(answer.title),
          })}
        >
          <CreateComplaint
            relationId={answer.id}
            complaintAboutType={ComplaintAboutType.OFFER_ANSWER}
            additionAction={onClickCloseComplaint}
          />
        </ConfirmDialog>
      )}
      {selectedOffer?.id && (
        <ConfirmDialog
          onClickClose={handleClickCloseReview}
          open={isOpen}
          title={t('modalTexts.feedback.title')}
        >
          <CreateReview
            answer={selectedOffer}
            additionAction={handleClickCloseReview}
            isCurrentUserOfferAnswer={isCurrentUserOfferAnswer}
          />
        </ConfirmDialog>
      )}
      {isOpenReviews && (
        <ConfirmDialog
          onClickClose={onClickCloseReview}
          open={isOpenReviews}
          title={t('modalTexts.feedback.title')}
        >
          <ReviewsView reviews={reviews} />
        </ConfirmDialog>
      )}
    </MarketplaceLayout>
  );
};

import { AxiosError } from 'axios';
import Snackbar from 'services/Snackbar';
import { IAPIResponseError } from 'interfaces/APIResponseError';

export const validateError = (err: AxiosError) => {
  if (!err.response) {
    throw err;
  }
  const errorResponse: IAPIResponseError = err.response.data;
  errorResponse.globalErrors.map((el) => Snackbar.show(el, 'error'));
  errorResponse.fieldErrors.map(
    (el) =>
      el.fieldError &&
      Snackbar.show(
        `${el.fieldName === 'id' ? '' : `Поле ${el.fieldName}`} ${
          el.fieldError
        }`,
        'error',
      ),
  );
  return errorResponse;
};

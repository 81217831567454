const isUrl = (string: string) => {
  try {
    return Boolean(new URL(string));
  } catch (e) {
    return false;
  }
};

export const replaceURL = (website: string) => {
  return isUrl(website) ? website : `http://${website}`;
};

import React, { useMemo } from 'react';
import { Box, styled, SxProps, Theme } from '@mui/material';
import { AccountsFilter } from 'components/elements/filters/AccountsFilter';
import { DateTimeRangeFilter } from 'components/elements/filters/DateTimeRangeFilter';
import { SelectFilters } from 'components/elements/filters/SelectFilters';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getAllBusinessCardsList } from 'store/features/business';
import { getLocaleValue } from 'utils/form';
import { getAllBusinessCards } from 'store/features/business/actions';
import { NewsFilters } from 'interfaces/NewsFilters';
import { ListFilters } from 'interfaces/Filters';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(5),
}));

type Filters = Omit<NewsFilters, keyof ListFilters>;

type Props = {
  sx?: SxProps<Theme>;
  filters: Filters;
  onChange?: (filters: Filters) => void;
};

export const Filters = ({ sx = [], filters, onChange }: Props) => {
  const dispatch = useAppDispatch();

  const businessCards = useAppSelector(getAllBusinessCardsList);

  const businessOptions = useMemo(
    () =>
      businessCards.map(({ id, name }) => ({ id, name: getLocaleValue(name) })),
    [businessCards],
  );

  const { t } = useTranslation();

  const handleAccountsChange = (value: string[]) => {
    onChange?.({ ...filters, accountIds: value });
  };

  const handleBusinessChange = (value: string[]) => {
    onChange?.({ ...filters, businessCardIds: value });
  };

  const handleDateTimesChange = (start: Date | null, end: Date | null) => {
    onChange?.({
      ...filters,
      fromDate: start?.getTime(),
      toDate: end?.getTime(),
    });
  };

  const handleBusinessFiltersLoad = () => dispatch(getAllBusinessCards());

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <AccountsFilter
        value={filters.accountIds}
        onChange={handleAccountsChange}
      />
      <SelectFilters
        title={t('filters.stats.businessTitle')}
        placeholder={t('filters.stats.businessPlaceholder')}
        options={businessOptions}
        value={filters.businessCardIds}
        onChange={handleBusinessChange}
        onLoad={handleBusinessFiltersLoad}
      />
      <DateTimeRangeFilter
        startDate={filters.fromDate ? new Date(filters.fromDate) : null}
        endDate={filters.toDate ? new Date(filters.toDate) : null}
        onDateTimesChange={handleDateTimesChange}
      />
    </Root>
  );
};

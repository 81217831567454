import React, { ComponentProps, Fragment, MouseEvent } from 'react';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import {
  Avatar,
  Box,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Typography,
} from '@mui/material';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { ReactComponent as AnswerIcon } from 'assets/icons/answer.svg';
import { ReactComponent as ViewIcon } from 'assets/icons/viewIcon.svg';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import { styled } from '@mui/system';
import { getNameInitials } from 'utils/user';
import { useTranslation } from 'react-i18next';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { Question, QuestionStatus } from 'interfaces/Question';
import { attachUrl } from 'services/ApiClient';
import { ReactionType } from 'interfaces/Post';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import Snackbar from '../../../../services/Snackbar';

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  gap: 16,
  padding: '8px 16px',
});

const UserAvatar = styled(Avatar)({
  height: 55,
  width: 55,
});

const Inner = styled('div')({
  flex: 1,
});

const Creator = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginBottom: 4,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const Created = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginBottom: 16,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const Topic = styled('div')({
  marginBottom: 16,
  fontWeight: '500',
  fontSize: 14,
  color: '#000',
});

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

const More = styled((props: ComponentProps<'button'>) => (
  <button {...props}>
    <MoreIcon />
  </button>
))({
  position: 'absolute',
  top: 8,
  right: 16,
  padding: 0,
  border: 'none',
  fontFamily: 'inherit',
  fontWeight: '400',
  fontSize: 12,
  color: '#4b4b4b',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
});

const Menu = styled(MuiMenu)({
  '& .MuiMenu-list': {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const MenuItem = styled(MuiMenuItem)({
  display: 'flex',
  justifyContent: 'center',
  minWidth: 200,
  padding: '8px 16px',
  fontFamily: 'inherit',
  fontWeight: '600',
  fontSize: 14,
  color: '#004b7c',
});

type Props = {
  question: Question;
  mine?: boolean;
  onAnswer?: (id: string) => void;
  onLike?: (id: string) => void;
  onDislike?: (id: string) => void;
  onMore?: (id: string) => void;
  onClose?: (id: string) => void;
};

export const QuestionCard = ({
  question,
  mine = false,
  onAnswer,
  onLike,
  onDislike,
  onMore,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const { isOpen, onClickOpen, onClickClose } = useModalActions();

  const {
    answersCount,
    businessCard,
    createdDateTime,
    creator,
    hasUserAnswer,
    id,
    reactions,
    softwareCards,
    status,
    title,
    viewsCounter,
  } = question;

  const avatar = businessCard?.logo && `${attachUrl}${businessCard.logo.path}`;
  const company = businessCard?.name?.ru || businessCard?.name?.en || '';
  const createdDate = new Date(createdDateTime);
  const creatorName = `${creator.name} ${creator.lastName}, #${creator.reputation}`;
  const isClosed = status === QuestionStatus.CLOSED;
  const topic = title.ru || title.en;

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const created =
    differenceInDays(new Date(), createdDate) === 0
      ? formatDistanceToNowStrict(createdDate, { locale: ru })
      : format(createdDate, 'dd MMMM yyyy');

  const handleCloseMore = () => setAnchorEl(null);

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnswer = () => onAnswer?.(id);

  const handleLike = () => onLike?.(id);

  const handleDislike = () => onDislike?.(id);

  const handleMore = () => onMore?.(id);

  const handleClose = () => onClose?.(id);

  const handleClickMenu = (action?: () => void) => () => {
    action?.();
    handleCloseMore();
  };
  const showNotification = (
    event: MouseEvent<HTMLAnchorElement> | undefined,
  ) => {
    event?.preventDefault();
    Snackbar.show(t('hidden card'), 'warning');
  };

  return (
    <Container>
      <UserAvatar src={avatar}>{getNameInitials(company)}</UserAvatar>
      <Inner>
        <Typography
          sx={{
            marginBottom: 4,
            fontFamily: 'Montserrat',
            fontWeight: '500',
            fontSize: 14,
            color: '#000',
          }}
          component={businessCard ? Link : 'span'}
          to={generatePath(URLS.viewBusinessCard, {
            id: businessCard?.id ?? 'deleted',
          })}
          target="_blank"
        >
          {company}
        </Typography>
        <Creator>
          <UserIcon />
          {creatorName}
        </Creator>
        <Created>
          <TimeIcon />
          {created}
        </Created>
        {!!softwareCards?.length && (
          <Typography sx={{ mb: 1, fontWeight: 500, fontSize: 14 }}>
            {'ПО: '}
            {softwareCards.map((card, index) => (
              <>
                <Typography
                  component={Link}
                  key={card.id}
                  sx={{ color: '#00618e', textDecoration: 'none' }}
                  to={generatePath(URLS.viewSoftwareCard, { id: card.id })}
                  variant="inherit"
                  onClick={
                    card?.hidden && !card?.cardBelongToCurrentAccount
                      ? showNotification
                      : undefined
                  }
                >
                  {card.name.ru || card.name.en}
                </Typography>
                {index === softwareCards.length - 1 ? '' : ', '}
              </>
            ))}
          </Typography>
        )}
        <Topic>{topic}</Topic>
        <Actions>
          <Box sx={{ flexBasis: '200px' }}>
            <InteractiveButton
              variant="stroke"
              IconComponent={ViewIcon}
              count={viewsCounter.uniqueUsers}
              label={t('views')}
              active={viewsCounter.viewedByCurrentUser}
            />
          </Box>
          <Box sx={{ flexBasis: '100px' }}>
            <InteractiveButton
              IconComponent={LikeIcon}
              count={likes}
              active={hasUserLikes}
              onClick={handleLike}
            />
          </Box>
          <Box sx={{ flexBasis: '100px' }}>
            <InteractiveButton
              IconComponent={DislikeIcon}
              count={dislikes}
              active={hasUserDislikes}
              onClick={handleDislike}
            />
          </Box>
          <Box sx={{ flexBasis: '200px' }}>
            <InteractiveButton
              IconComponent={AnswerIcon}
              count={answersCount}
              label={t('answers')}
              active={hasUserAnswer}
              onClick={handleAnswer}
            />
          </Box>
        </Actions>
      </Inner>
      <More onClick={handleOpenMore} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseMore}
      >
        <MenuItem divider onClick={handleClickMenu(handleMore)}>
          Подробнее
        </MenuItem>

        {!isClosed && mine && (
          <MenuItem onClick={handleClickMenu(handleClose)}>Закрыть</MenuItem>
        )}
        {!mine && (
          <MenuItem onClick={handleClickMenu(onClickOpen)}>
            {t('actions.complaint')}
          </MenuItem>
        )}
      </Menu>
      <ConfirmDialog
        onClickClose={onClickClose}
        open={isOpen}
        title={t('modalTexts.complaint.title', {
          name: topic,
        })}
      >
        <CreateComplaint
          relationId={id}
          complaintAboutType={ComplaintAboutType.QUESTION}
          additionAction={onClickClose}
        />
      </ConfirmDialog>
    </Container>
  );
};

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import {
  initialNotificationsState,
  INotificationsState,
} from 'store/features/notifications/config';
import { RootState } from 'store/index';
import { AxiosResponse } from 'axios';
import {
  getNotifications,
  getUnreadCountNotifications,
  getNotification,
} from 'store/features/notifications/actions';
import {
  INotificationItem,
  INotificationsFilters,
} from 'interfaces/Notifications';
import { PAGE_SIZE } from 'interfaces/BusinessFilters';

const notificationsSlice = createSlice({
  name: Slices.notifications,
  initialState: initialNotificationsState,
  reducers: {
    changeFilters: (
      state: INotificationsState,
      { payload }: PayloadAction<INotificationsFilters>,
    ) => {
      state.filters = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotification.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<INotificationItem>;
      state.loading = false;
      state.notification = data;
    });
    builder.addCase(getNotification.rejected, (state) => {
      state.loading = false;
      state.notification = null;
    });
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, { payload, meta }) => {
      const { data } = payload as AxiosResponse<INotificationItem[]>;
      const hasMore = data.length % PAGE_SIZE === 0;
      const { offset = 0 } = meta.arg ?? {};
      state.loading = false;
      state.notifications =
        offset === 0 ? data : [...state.notifications, ...data];
      state.hasMore = hasMore;
    });
    builder.addCase(getNotifications.rejected, (state) => {
      state.loading = false;
      state.notifications = [];
    });
    builder.addCase(
      getUnreadCountNotifications.fulfilled,
      (state, { payload }) => {
        const { data } = payload as AxiosResponse<number>;
        state.unreadNotificationsCount = data;
      },
    );
  },
});

export { notificationsSlice };
export const { changeFilters } = notificationsSlice.actions;
const self = (state: RootState) => state.notifications;
export const selectNotifications = createSelector(
  self,
  (state) => state.notifications,
);
export const selectNotificationsLoading = createSelector(
  self,
  (state) => state.loading,
);
export const selectNotificationsHasMore = createSelector(
  self,
  (state) => state.hasMore,
);
export const selectNotificationsFilters = createSelector(
  self,
  (state) => state.filters,
);

export const selectUnreadCountNotifications = createSelector(
  self,
  (state) => state.unreadNotificationsCount,
);

export const selectNotification = createSelector(
  self,
  (state) => state.notification,
);

import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { IPopulars } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCell = (): HeadCell[] => {
  return [
    { id: 'name', label: i18n.t('tables.name'), numeric: false },
    {
      id: 'responses',
      label: i18n.t('tables.responses'),
      numeric: true,
    },
    {
      id: 'reactions',
      label: i18n.t('tables.reactions'),
      numeric: true,
    },
    {
      id: 'views',
      label: i18n.t('tables.views'),
      numeric: true,
    },
  ];
};

export const createData = (data: IPopulars[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.objectId,
      name: getLocaleValue(el.name),
    };
  });

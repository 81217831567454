import React, { ReactNode } from 'react';
import { styled } from '@mui/system';
import { Filters, NavigationMenu } from './Navigation';
import { FooterAd } from 'components/elements/ads';

const Container = styled('div')({
  minHeight: '100%',
  width: '100%',
  display: 'flex',
});

const InnerContainer = styled('div')({
  width: '100%',
  padding: '20px 37px',
  display: 'flex',
  flexDirection: 'column',
});

type Props = {
  filters?: Filters;
  children: ReactNode;
  useFilters?: boolean;
  onFiltersChange?: (filters: Filters) => void;
};

export const Layout = ({
  filters,
  children,
  useFilters,
  onFiltersChange,
}: Props) => {
  return (
    <Container>
      <NavigationMenu
        filters={filters}
        onChange={onFiltersChange}
        useFilters={useFilters}
      />
      <InnerContainer>
        {children}
        <FooterAd />
      </InnerContainer>
    </Container>
  );
};

import React from 'react';
import { Answer, Question, QuestionStatus } from 'interfaces/Question';
import { AnswerCard } from './AnswerCard';
import { styled, SxProps, Theme } from '@mui/system';
import { Loader } from 'components/shared/loader';

const Empty = styled('span')({
  margin: 'auto',
});

const List = styled('ul')({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: 0,
  border: 0,
  listStyle: 'none',

  '& li': {
    borderBottom: '1px solid #e4e4e4',
  },
});

type Props = {
  question: Question;
  answers: Answer[];
  currentUserId?: string;
  loading?: boolean;
  ownerId?: string;
  sx?: SxProps<Theme>;
  onAccept?: (id: Answer['id']) => void;
  onLike?: (id: Answer['id']) => void;
  onDislike?: (id: Answer['id']) => void;
};

export const AnswersList = ({
  question,
  answers,
  currentUserId,
  loading = false,
  ownerId,
  sx = [],
  onAccept,
  onLike,
  onDislike,
}: Props) => {
  return (
    <List sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {loading && (
        <Empty>
          <Loader show />
        </Empty>
      )}

      {!loading && !answers.length && <Empty>Нет ответов</Empty>}

      {!loading &&
        Boolean(answers.length) &&
        answers.map((item) => {
          const closed = question.status === QuestionStatus.CLOSED;
          const isQuestionOwner = currentUserId === ownerId && !closed;
          const mine = currentUserId === item.creator.id;

          const editable =
            mine && !closed && !item.accepted && item.canProcessByCurrentUser;

          const showAcceptCheckbox = isQuestionOwner && !closed;

          return (
            <li key={item.id}>
              <AnswerCard
                answer={item}
                showAcceptCheckbox={showAcceptCheckbox}
                editable={editable}
                mine={mine}
                onAccept={onAccept}
                onLike={onLike}
                onDislike={onDislike}
              />
            </li>
          );
        })}
    </List>
  );
};

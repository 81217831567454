import React, { ChangeEvent, ComponentProps, useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextFieldInput } from 'components/shared/inputs';
import { Locale } from 'interfaces/Locale';
import { Editor } from 'components/shared/editor';

type Props = {
  fieldName: any;
  label: string;
  control: any;
  errors?: any;
  rules?: ComponentProps<typeof Controller>['rules'];
  multiline?: boolean;
  minRows?: number;
  placeholder?: string;
  isEditor?: boolean;
  disabled?: boolean;
  maxLength?: number;
};

export const FormMultiLocaleField = ({
  control,
  errors,
  fieldName,
  label,
  rules,
  multiline,
  minRows,
  placeholder,
  isEditor,
  disabled = false,
  maxLength,
}: Props) => {
  const [locale, setLocale] = useState(Locale.ru);
  const handleChangeLocale = () =>
    setLocale((prevState) => (prevState === Locale.ru ? Locale.en : Locale.ru));

  const handleChange =
    (onChange: (value: string) => void) =>
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(event.target.value);
    };

  return (
    <>
      {locale === Locale.ru && (
        <Controller
          name={`${fieldName}.ru`}
          control={control}
          rules={rules}
          defaultValue=""
          render={({ field: { ref, onChange, ...rest } }) => {
            return isEditor ? (
              <Editor
                label={label}
                placeholder={placeholder}
                value={rest.value}
                readOnly={disabled}
                onChange={onChange}
                errorMessage={
                  errors?.[`${fieldName}`]?.ru?.message ||
                  errors?.[`${fieldName}`]?.en?.message
                }
              />
            ) : (
              <TextFieldInput
                inputRef={ref}
                label={label}
                placeholder={placeholder}
                locale={Locale.ru}
                multiline={multiline}
                disabled={disabled}
                minRows={minRows ? minRows : multiline ? 2 : undefined}
                onClickChangeLocale={handleChangeLocale}
                onChange={handleChange(onChange)}
                errorMessage={
                  errors?.[`${fieldName}`]?.ru?.message ||
                  errors?.[`${fieldName}`]?.en?.message
                }
                maxLength={maxLength}
                {...rest}
              />
            );
          }}
        />
      )}
      {locale === Locale.en && (
        <Controller
          name={`${fieldName}.en`}
          control={control}
          defaultValue=""
          render={({ field: { ref, onChange, ...rest } }) =>
            isEditor ? (
              <Editor
                label={label}
                placeholder={placeholder}
                value={rest.value}
                readOnly={disabled}
                onChange={onChange}
                errorMessage={
                  errors?.[`${fieldName}`]?.en?.message ||
                  errors?.[`${fieldName}`]?.ru?.message
                }
              />
            ) : (
              <TextFieldInput
                inputRef={ref}
                label={label}
                placeholder={placeholder}
                locale={Locale.en}
                multiline={multiline}
                disabled={disabled}
                minRows={minRows ? minRows : multiline ? 2 : undefined}
                onClickChangeLocale={handleChangeLocale}
                onChange={handleChange(onChange)}
                errorMessage={
                  errors?.[`${fieldName}`]?.ru?.message ||
                  errors?.[`${fieldName}`]?.en?.message
                }
                maxLength={maxLength}
                {...rest}
              />
            )
          }
        />
      )}
    </>
  );
};

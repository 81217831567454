import React from 'react';
import { Header } from 'components/elements/header';
import { styled } from '@mui/system';
import { HEADER_HEIGHT, SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { CopyRight } from 'components/shared/footer';

type Props = {
  children: JSX.Element;
};

const Container = styled('div')({
  minHeight: `100vh`,
  overflow: 'hidden',
});

const MainHeader = styled(Header)({
  overflowX: 'hidden',
  overflowY: 'auto',
  scrollbarGutter: 'stable',
});

const ContentContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  overflowY: 'auto',
  scrollbarGutter: 'stable',
}));

export const MainLayout = ({ children }: Props) => {
  return (
    <Container>
      <MainHeader isAuth />
      <ContentContainer id={SCROLLABLE_REFERENCE_ID}>
        {children}
        <CopyRight />
      </ContentContainer>
    </Container>
  );
};

import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, styled, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { ISoftware } from 'interfaces/Software';
import { useAppDispatch, useAppSelector } from 'store';
import { selectSoftware, selectSoftwareHasMore } from 'store/features/software';
import { getSoftware } from 'store/features/software/actions';
import { CircularProgress } from '@mui/material';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { AxiosResponse } from 'axios';
import { SoftwareCard } from 'components/features/marketplace/components/cards/SoftwareCard';
import { ICompilationPageFilters } from 'interfaces/Marketplace';
import { useTranslation } from 'react-i18next';
import { ListAdOne, ListAdTwo } from '../../../../elements/ads';

const EmptyText = styled(Typography)({
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
});

const LoaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 40,
});

const SOFTWARE_PER_PAGE = 10;

type Props = {
  filters?: ICompilationPageFilters;
  onFiltersChange?: (filters: ICompilationPageFilters) => void;
  onFirstLoaded?: (length: number) => void;
};

export const SoftwareList = ({
  filters,
  onFiltersChange,
  onFirstLoaded,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const hasMore = useAppSelector(selectSoftwareHasMore);
  const software = useAppSelector(selectSoftware);

  const offset = filters?.offset ?? 0;

  const handleNextPage = () => {
    onFiltersChange?.({
      ...filters,
      offset: offset + SOFTWARE_PER_PAGE,
      limit: SOFTWARE_PER_PAGE,
    });
  };

  const isFirstLoaded = useRef(true);

  const prevFilters = useRef<ICompilationPageFilters | undefined>({});

  useEffect(() => {
    const currentFilters = filters ?? {};

    if (
      isFirstLoaded.current ||
      !isEqual(prevFilters.current, currentFilters)
    ) {
      dispatch(getSoftware(currentFilters))
        .unwrap()
        .then((result) => {
          const { data } = result as AxiosResponse<ISoftware[]>;
          isFirstLoaded.current && onFirstLoaded?.(data?.length ?? 0);
          isFirstLoaded.current = false;
        });
    }

    prevFilters.current = currentFilters;
  }, [dispatch, filters, onFirstLoaded]);

  const loader = (
    <LoaderBox sx={{ mt: 2 }}>
      <CircularProgress size={32} />
    </LoaderBox>
  );

  return (
    <>
      {!software.length && (
        <EmptyText sx={{ m: 'auto' }}>{t('software empty data')}</EmptyText>
      )}
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={software.length}
        hasMore={hasMore}
        loader={loader}
        next={handleNextPage}
        scrollableTarget={SCROLLABLE_REFERENCE_ID}
      >
        {software.map((item, index) => (
          <>
            {index % 4 === 0 && index % 8 !== 0 && index !== 0 && <ListAdOne />}
            {index % 4 === 0 && index % 8 === 0 && index !== 0 && <ListAdTwo />}
            <SoftwareCard
              sx={{ mb: index === software.length - 1 ? 0 : 2 }}
              key={item.id}
              software={item}
            />
          </>
        ))}
      </InfiniteScroll>
    </>
  );
};

import React, { useEffect } from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { IMultiLanguage } from 'interfaces/Locale';
import { useForm } from 'react-hook-form';
import { TAddInvalidContent } from 'interfaces/Complaint';
import { yupResolver } from '@hookform/resolvers/yup';
import { contentValidationSchema } from './validationScheme';
import { useTranslation } from 'react-i18next';

const Root = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  padding: '16px 24px',
  backgroundColor: theme.palette.background.paper,
}));

type Form = {
  text: IMultiLanguage;
};

type Props = {
  sx?: SxProps<Theme>;
  onSubmit: (data: TAddInvalidContent) => void;
};

export const AddContentForm = ({ sx = [], onSubmit }: Props) => {
  const { t } = useTranslation();

  const {
    formState: { isValid, errors },
    control,
    handleSubmit,
    reset,
    watch,
    trigger,
  } = useForm<Form>({
    defaultValues: { text: { en: '', ru: '' } },
    mode: 'onChange',
    resolver: yupResolver(contentValidationSchema),
  });

  const submit = (data: TAddInvalidContent) => {
    onSubmit(data);
    reset();
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'text.en' && type === 'change') {
        trigger('text.ru');
      }
      if (name === 'text.ru' && type === 'change') {
        trigger('text.en');
      }
    });
    return () => subscription.unsubscribe();
  }, [trigger, watch]);

  useEffect(() => {
    trigger();
  }, [trigger]);
  return (
    <Root
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      onSubmit={handleSubmit(submit)}
    >
      <FormMultiLocaleField
        label={t('moderation.form.contentLabel')}
        placeholder={t('moderation.form.contentPlaceholder')}
        fieldName="text"
        control={control}
        errors={errors}
      />
      <ActionButton
        sx={{ width: 135, height: '100%' }}
        text={t('actions.add')}
        type="submit"
        autoFocus
        disabled={!isValid}
      />
    </Root>
  );
};

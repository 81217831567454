import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';
import { PASSWORD_REGEX } from 'defenitions/regex';

export const signupValidationSchema = yup
  .object({
    name: yup.string().when('type', {
      is: 'ORGANIZATION',
      then: yup.string().required(ErrorMessages.required),
    }),
    type: yup.string().required(ErrorMessages.required),
    country: yup.string().required(ErrorMessages.required),
    accept: yup
      .boolean()
      .required()
      .oneOf([true], ErrorMessages.notAcceptedTerms),
    userProfile: yup.object({
      email: yup
        .string()
        .required(ErrorMessages.required)
        .email(ErrorMessages.emailNotCorrect),
      password: yup
        .string()
        .required(ErrorMessages.required)
        .matches(PASSWORD_REGEX, ErrorMessages.passwordRegex),
      confirmPassword: yup
        .string()
        .required(ErrorMessages.required)
        .oneOf([yup.ref('password'), null], ErrorMessages.confirmPassword),
      name: yup.string().required(ErrorMessages.required),
      lastName: yup.string().required(ErrorMessages.required),
    }),
  })
  .required();

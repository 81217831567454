import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import {
  IComplaintCreate,
  IComplaint,
  TRatingsFilters,
  TComplaintsFilters,
  IAccountRating,
  IAccount,
  IInvalidContent,
  TAddInvalidContent,
  TContentsFilters,
} from 'interfaces/Complaint';
import { AttachmentType } from 'interfaces/Attachment';
import qs from 'qs';

export const createComplaintEndpoint = (
  data: IComplaintCreate,
): Promise<AxiosResponse> => {
  return apiClient.post(`/posts/complaints`, data);
};

export const uploadComplaintAttachmentEndpoint = (
  formData: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments/files?type=${AttachmentType.Complaint}`,
    formData,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  );
};

export const getComplaintsEndpoint = (
  filters: TComplaintsFilters,
): Promise<AxiosResponse<IComplaint[]>> => {
  return apiClient.get(`/posts/complaints`, {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

export const resolveComplaintEndpoint = (
  id: string,
): Promise<AxiosResponse> => {
  return apiClient.patch(`/posts/complaints/${id}/resolve`);
};

export const declineComplaintEndpoint = (
  id: string,
): Promise<AxiosResponse> => {
  return apiClient.patch(`/posts/complaints/${id}/decline`);
};

export const getComplaintsAccountsRatingsEndpoint = (
  filters: TRatingsFilters,
): Promise<AxiosResponse<IAccountRating[]>> => {
  return apiClient.get(`/posts/complaints/accounts`, {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

export const getComplaintsAccountsFilterEndpoint = (): Promise<
  AxiosResponse<IAccount[]>
> => {
  return apiClient.get(`/account`);
};

export const getInvalidContentsEndpoint = (
  filters: TContentsFilters,
): Promise<AxiosResponse<IInvalidContent[]>> => {
  return apiClient.get(`/dictionaries/invalidContent/select2`, {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

export const addInvalidContentEndpoint = (
  data: TAddInvalidContent,
): Promise<AxiosResponse<IInvalidContent>> => {
  return apiClient.post(
    `/dictionaries/invalidContent/values/simple`,
    data.text,
  );
};

export const removeInvalidContentEndpoint = (
  id: string,
): Promise<AxiosResponse<void>> => {
  return apiClient.delete(`/dictionaries/invalidContent/values/${id}`);
};

export const editInvalidContentEndpoint = (
  data: IInvalidContent,
): Promise<AxiosResponse<IInvalidContent[]>> => {
  const body = { code: data.id, value: data.text, selectable: !data.disabled };
  return apiClient.put(`/dictionaries/invalidContent/values`, body);
};

export const getComplaintByIdEndpoint = (
  id: string,
): Promise<AxiosResponse<IComplaint[]>> => {
  return apiClient.get(`/posts/complaints/${id}`);
};

export const getComplaintsByRelationIdEndpoint = (
  relationId: string,
): Promise<AxiosResponse<IComplaint[]>> => {
  return apiClient.get(`/posts/complaints/to/${relationId}`);
};

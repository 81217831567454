import { createAsyncThunk } from '@reduxjs/toolkit';
import { SearchFilters } from 'interfaces/Search';
import { actionHandler } from 'store/utils/actionHandler';
import { searchEndpoint } from './api';
import { ActionTypes } from './config';

export const search = createAsyncThunk(
  ActionTypes.SEARCH,
  async (filters: SearchFilters | undefined, { rejectWithValue }) =>
    actionHandler(() => searchEndpoint(filters), rejectWithValue),
);

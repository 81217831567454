import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { BusinessCard } from 'components/features/business/shared/list/card/BusinessCard';
import { IBusiness } from 'interfaces/Business';
import { Loader } from 'components/shared/loader';
import { useAppDispatch, useAppSelector } from 'store';
import { changeFilters, getBusinessHasMore } from 'store/features/business';
import { IBusinessFilters, PAGE_SIZE } from 'interfaces/BusinessFilters';
import { BusinessFilters } from 'components/features/business/shared/list/filters';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box/Box';
//import { TextButton } from 'components/shared/buttons/TextButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrent } from 'store/features/users';
import { Roles } from 'interfaces/Users';
import { getBusiness, getMyBusiness } from 'store/features/business/actions';
import { ListAdOne, ListAdTwo } from 'components/elements/ads';

type Props = {
  businessCards: IBusiness[];
  isBusinessCardsLoading: boolean;
  filters: IBusinessFilters;
  isAll?: boolean;
};

const Container = styled('div')({
  display: 'flex',
  paddingTop: '20px',
  alignItems: 'flex-start',
  flexDirection: 'column',
  alignSelf: 'flex-start',
  width: '100%',
});

export const BusinessList = ({
  businessCards,
  isBusinessCardsLoading,
  filters,
  isAll,
}: Props) => {
  const dispatch = useAppDispatch();
  const hasMore = useAppSelector(getBusinessHasMore);
  const user = useAppSelector(getCurrent);
  const isOwner = user?.role === Roles.OWNER;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  /*  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };*/

  const open = Boolean(anchorEl);
  const handleChangeFilters = (newFilters: IBusinessFilters) => {
    dispatch(changeFilters(newFilters));
    setAnchorEl(null);
  };

  const loadNext = () => {
    dispatch(changeFilters({ ...filters, offset: filters.offset + PAGE_SIZE }));
  };

  useEffect(() => {
    isAll ? dispatch(getBusiness(filters)) : dispatch(getMyBusiness(filters));
  }, [filters, isAll]);

  return (
    <Container>
      {isBusinessCardsLoading && <Loader show global />}
      {/*      <TextButton
        text={'Сортировка'}
        onClick={handleClick}
        style={{ marginBottom: 8 }}
      />*/}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: 'background.paper',
            boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
          }}
        >
          <BusinessFilters
            filters={filters}
            onChangeFilters={handleChangeFilters}
          />
        </Box>
      </Popper>
      <div style={{ height: '100%', width: '100%' }}>
        <InfiniteScroll
          dataLength={businessCards.length}
          next={loadNext}
          hasMore={hasMore}
          loader={<Loader show={isBusinessCardsLoading} global />}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {businessCards.map((el, index) => (
            <>
              {index % 4 === 0 && index % 8 !== 0 && index !== 0 && (
                <ListAdOne />
              )}
              {index % 4 === 0 && index % 8 === 0 && index !== 0 && (
                <ListAdTwo />
              )}
              <BusinessCard
                key={el.id}
                business={el}
                isOwner={isOwner}
                isAll={isAll}
              />
            </>
          ))}
        </InfiniteScroll>
      </div>
    </Container>
  );
};

import {
  BusinessStatus,
  IBusiness,
  IBusinessCreate,
  IBusinessService,
} from 'interfaces/Business';
import { IBusinessAttachment } from 'interfaces/Attachment';

export const transformBusinessDataForm = (
  data: IBusiness,
  logo: IBusinessAttachment | undefined,
  attachments: IBusinessAttachment[],
  businessServices: IBusinessService[],
  status: BusinessStatus,
  cardType: string | null,
): IBusinessCreate => {
  return {
    ...data,
    logo: logo?.id,
    cardType: data?.publishedDateTime > 0 ? null : cardType,
    attachments: attachments.map((el) => el.id),
    status: status,
    services: businessServices.map((el) => {
      return {
        ...el,
        serviceType: el.serviceType?.code || '',
        attachments: el.attachments?.map((el) => el.id),
      };
    }),
  };
};

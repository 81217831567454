import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionHandler } from 'store/utils/actionHandler';
import { ActionTypes } from 'store/features/marketplace/config';
import {
  closeServiceRequestEndpoint,
  createServiceRequestEndpoint,
  deleteServiceRequestEndpoint,
  getServiceRequestByIdEndpoint,
  publishServiceRequestEndpoint,
  uploadMarketplaceAttachmentEndpoint,
  saveMarketplaceItemsOfferAnswerEndpoint,
  marketplaceAcceptAnswerEndpoint,
  marketplaceDeclineAnswerEndpoint,
  marketplaceDeleteAnswerEndpoint,
  marketplaceCloseAnswerEndpoint,
  marketplaceGetServicePageGetListEndpoint,
  getMyServiceRequestsEndpoint,
  getPublishedServiceRequestsEndpoint,
  getAnswerRequestByIdEndpoint,
  marketplaceShareContactsEndpoint,
  marketplacePublishAnswerEndpoint,
  getAllAnswersForServiceRequestEndpoint,
  getServicesEndpoint,
  getServiceByIdEndpoint,
  getIncomingServiceRequestsEndpoint,
  getMyOffersEndpoint,
  likeMarketplacePostEndpoint,
  createMarketplaceCommentEndpoint,
  marketplaceDeclineServiceRequestEndpoint,
  marketplaceCompleteAnswerEndpoint,
  marketplaceReviewEndpoint,
  uploadReviewAttachmentEndpoint,
  marketplaceWorkAnswerEndpoint,
  marketplaceGetReviewEndpoint,
  marketplaceGetOfferCountEndpoint,
  hideServiceRequestEndpoint,
  showServiceRequestEndpoint,
  editMarketplaceCommentEndpoint,
} from 'store/features/marketplace/api';
import {
  CreateReview,
  GetServicePageFilters,
  IAcceptAnswer,
  ICompilationPageFilters,
  ICreateServiceRequest,
  IDeclineAnswer,
  IDeclineServiceRequest,
  IProvideServicesPageFilters,
  IRequestsPageFilters,
  IService,
  IServiceRequest,
  IShareContacts,
} from 'interfaces/Marketplace';
import { AttachmentType } from 'interfaces/Attachment';
import { IAnswer, ICreateOfferAnswer } from 'interfaces/Marketplace';
import { ICreateComment, IEditComment } from 'interfaces/Comment';
import { ReactionType } from 'interfaces/Post';

export const getServiceRequestById = createAsyncThunk(
  ActionTypes.GET_SERVICE_REQUEST_BY_ID,
  async (id: IServiceRequest['id'], { rejectWithValue }) =>
    actionHandler(() => getServiceRequestByIdEndpoint(id), rejectWithValue),
);

export const getServiceById = createAsyncThunk(
  ActionTypes.GET_SERVICE_BY_ID,
  async (id: IService['businessService']['id'], { rejectWithValue }) =>
    actionHandler(() => getServiceByIdEndpoint(id), rejectWithValue),
);

export const getAnswerRequestById = createAsyncThunk(
  ActionTypes.MARKETPLACE_GET_ANSWER_BY_ID,
  async (id: IAnswer['id'], { rejectWithValue }) =>
    actionHandler(() => getAnswerRequestByIdEndpoint(id), rejectWithValue),
);

export const getPublishedServiceRequests = createAsyncThunk(
  ActionTypes.GET_PUBLISHED_SERVICE_REQUESTS,
  async (filters: ICompilationPageFilters | undefined, { rejectWithValue }) =>
    actionHandler(
      () => getPublishedServiceRequestsEndpoint(filters),
      rejectWithValue,
    ),
);

export const getIncomingServiceRequests = createAsyncThunk(
  ActionTypes.GET_INCOMING_SERVICE_REQUESTS,
  async (
    filters: IProvideServicesPageFilters | undefined,
    { rejectWithValue },
  ) =>
    actionHandler(
      () => getIncomingServiceRequestsEndpoint(filters),
      rejectWithValue,
    ),
);

export const getMyOffers = createAsyncThunk(
  ActionTypes.GET_MY_OFFERS,
  async (
    filters: IProvideServicesPageFilters | undefined,
    { rejectWithValue },
  ) => actionHandler(() => getMyOffersEndpoint(filters), rejectWithValue),
);

export const getServices = createAsyncThunk(
  ActionTypes.GET_SERVICES,
  async (filters: ICompilationPageFilters | undefined, { rejectWithValue }) =>
    actionHandler(() => getServicesEndpoint(filters), rejectWithValue),
);

export const getMyServiceRequests = createAsyncThunk(
  ActionTypes.GET_MY_SERVICE_REQUESTS,
  async (filters: IRequestsPageFilters | undefined, { rejectWithValue }) =>
    actionHandler(() => getMyServiceRequestsEndpoint(filters), rejectWithValue),
);

export const createServiceRequest = createAsyncThunk(
  ActionTypes.CREATE_SERVICE_REQUEST,
  async (request: ICreateServiceRequest, { rejectWithValue }) =>
    actionHandler(() => createServiceRequestEndpoint(request), rejectWithValue),
);

export const publishServiceRequest = createAsyncThunk(
  ActionTypes.PUBLISH_SERVICE_REQUEST,
  async (id: IServiceRequest['id'], { rejectWithValue }) =>
    actionHandler(() => publishServiceRequestEndpoint(id), rejectWithValue),
);

export const closeServiceRequest = createAsyncThunk(
  ActionTypes.CLOSE_SERVICE_REQUEST,
  async (id: IServiceRequest['id'], { rejectWithValue }) =>
    actionHandler(() => closeServiceRequestEndpoint(id), rejectWithValue),
);

export const deleteServiceRequest = createAsyncThunk(
  ActionTypes.DELETE_SERVICE_REQUEST,
  async (id: IServiceRequest['id'], { rejectWithValue }) =>
    actionHandler(() => deleteServiceRequestEndpoint(id), rejectWithValue),
);

export const getAllAnswersForServiceRequest = createAsyncThunk(
  ActionTypes.GET_ALL_ANSWERS_FOR_SERVICE_REQUEST,
  async (id: IServiceRequest['id'], { rejectWithValue }) =>
    actionHandler(
      () => getAllAnswersForServiceRequestEndpoint(id),
      rejectWithValue,
    ),
);

export const uploadMarketplaceAttachment = createAsyncThunk(
  ActionTypes.UPLOAD_MARKETPLACE_ATTACHMENT,
  async (
    attachment: { data: FormData; type: AttachmentType },
    { rejectWithValue },
  ) =>
    actionHandler(
      () => uploadMarketplaceAttachmentEndpoint(attachment),
      rejectWithValue,
    ),
);

export const saveMarketplaceOfferAnswer = createAsyncThunk(
  ActionTypes.SAVE_MARKET_PLACE_OFFER_ANSWER,
  async (data: ICreateOfferAnswer, { rejectWithValue }) =>
    actionHandler(
      () => saveMarketplaceItemsOfferAnswerEndpoint(data),
      rejectWithValue,
    ),
);

export const marketplaceAcceptAnswer = createAsyncThunk(
  ActionTypes.MARKETPLACE_ACCEPT_ANSWER,
  async (data: IAcceptAnswer, { rejectWithValue }) =>
    actionHandler(() => marketplaceAcceptAnswerEndpoint(data), rejectWithValue),
);

export const marketplaceDeclineAnswer = createAsyncThunk(
  ActionTypes.MARKETPLACE_DECLINE_ANSWER,
  async (data: IDeclineAnswer, { rejectWithValue }) =>
    actionHandler(
      () => marketplaceDeclineAnswerEndpoint(data),
      rejectWithValue,
    ),
);
export const marketplaceCompleteAnswer = createAsyncThunk(
  ActionTypes.MARKETPLACE_COMPLETE_ANSWER,
  async (data: IAcceptAnswer, { rejectWithValue }) =>
    actionHandler(
      () => marketplaceCompleteAnswerEndpoint(data),
      rejectWithValue,
    ),
);
export const marketplaceReviewAnswer = createAsyncThunk(
  ActionTypes.CREATE_MARKETPLACE_REVIEW,
  async (data: CreateReview, { rejectWithValue }) =>
    actionHandler(() => marketplaceReviewEndpoint(data), rejectWithValue),
);

export const marketplaceGetReviewsAnswer = createAsyncThunk(
  ActionTypes.LOAD_MARKETPLACE_REVIEW,
  async (data: IAcceptAnswer, { rejectWithValue }) =>
    actionHandler(() => marketplaceGetReviewEndpoint(data), rejectWithValue),
);

export const marketplaceDeclineServiceRequest = createAsyncThunk(
  ActionTypes.DECLINE_SERVICE_REQUEST,
  async (data: IDeclineServiceRequest, { rejectWithValue }) =>
    actionHandler(
      () => marketplaceDeclineServiceRequestEndpoint(data),
      rejectWithValue,
    ),
);
export const marketplaceShareContacts = createAsyncThunk(
  ActionTypes.MARKETPLACE_SHARE_CONTACTS,
  async (data: IShareContacts, { rejectWithValue }) =>
    actionHandler(
      () => marketplaceShareContactsEndpoint(data),
      rejectWithValue,
    ),
);

export const marketplaceDeleteAnswer = createAsyncThunk(
  ActionTypes.MARKETPLACE_DELETE_ANSWER,
  async (id: IAnswer['id'], { rejectWithValue }) =>
    actionHandler(() => marketplaceDeleteAnswerEndpoint(id), rejectWithValue),
);

export const marketplacePublishAnswer = createAsyncThunk(
  ActionTypes.MARKETPLACE_PUBLISH_ANSWER,
  async (id: IAnswer['id'], { rejectWithValue }) =>
    actionHandler(() => marketplacePublishAnswerEndpoint(id), rejectWithValue),
);

export const marketplaceCloseAnswer = createAsyncThunk(
  ActionTypes.MARKETPLACE_CLOSE_ANSWER,
  async (id: IAnswer['id'], { rejectWithValue }) =>
    actionHandler(() => marketplaceCloseAnswerEndpoint(id), rejectWithValue),
);

export const marketplaceWorkAnswer = createAsyncThunk(
  ActionTypes.MARKETPLACE_WORK_ANSWER,
  async (id: IAnswer['id'], { rejectWithValue }) =>
    actionHandler(() => marketplaceWorkAnswerEndpoint(id), rejectWithValue),
);

export const marketplaceGetServicePageGetList = createAsyncThunk(
  ActionTypes.MARKETPLACE_GET_SERVICE_PAGE_LIST,
  async (filter: GetServicePageFilters, { rejectWithValue }) =>
    actionHandler(
      () => marketplaceGetServicePageGetListEndpoint(filter),
      rejectWithValue,
    ),
);

export const likeComment = createAsyncThunk(
  ActionTypes.LIKE_MARKETPLACE_COMMENT,
  async (
    { id, type }: { id: string; type: ReactionType },
    { rejectWithValue },
  ) =>
    actionHandler(() => likeMarketplacePostEndpoint(id, type), rejectWithValue),
);

export const likeService = createAsyncThunk(
  ActionTypes.LIKE_SERVICE,
  async (
    { id, type }: { id: string; type: ReactionType },
    { rejectWithValue },
  ) =>
    actionHandler(() => likeMarketplacePostEndpoint(id, type), rejectWithValue),
);

export const likeServiceRequest = createAsyncThunk(
  ActionTypes.LIKE_SERVICE_REQUEST,
  async (
    { id, type }: { id: string; type: ReactionType },
    { rejectWithValue },
  ) =>
    actionHandler(() => likeMarketplacePostEndpoint(id, type), rejectWithValue),
);

export const likeServiceOffer = createAsyncThunk(
  ActionTypes.LIKE_SERVICE_OFFER,
  async (
    { id, type }: { id: string; type: ReactionType },
    { rejectWithValue },
  ) =>
    actionHandler(() => likeMarketplacePostEndpoint(id, type), rejectWithValue),
);

export const createOfferComment = createAsyncThunk(
  ActionTypes.CREATE_MARKETPLACE_COMMENT,
  async (data: ICreateComment, { rejectWithValue }) =>
    actionHandler(
      () => createMarketplaceCommentEndpoint(data),
      rejectWithValue,
    ),
);

export const editOfferComment = createAsyncThunk(
  ActionTypes.EDIT_MARKETPLACE_COMMENT,
  async (data: IEditComment, { rejectWithValue }) =>
    actionHandler(() => editMarketplaceCommentEndpoint(data), rejectWithValue),
);

export const uploadReviewAttachments = createAsyncThunk(
  ActionTypes.UPLOAD_REVIEW_ATTACHMENTS,
  async (data: FormData, { rejectWithValue }) =>
    actionHandler(() => uploadReviewAttachmentEndpoint(data), rejectWithValue),
);

export const getOfferCount = createAsyncThunk(
  ActionTypes.GET_MY_OFFER_COUNT,
  async (id: IServiceRequest['id'], { rejectWithValue }) =>
    actionHandler(() => marketplaceGetOfferCountEndpoint(id), rejectWithValue),
);

export const showServiceRequest = createAsyncThunk(
  ActionTypes.SHOW_SERVICE_REQUEST,
  async (id: IServiceRequest['id'], { rejectWithValue }) =>
    actionHandler(() => showServiceRequestEndpoint(id), rejectWithValue),
);

export const hideServiceRequest = createAsyncThunk(
  ActionTypes.HIDE_SERVICE_REQUEST,
  async (id: IServiceRequest['id'], { rejectWithValue }) =>
    actionHandler(() => hideServiceRequestEndpoint(id), rejectWithValue),
);

import React, { useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'store';
import { QuestionsFilters as QuestionsFiltersType } from 'interfaces/QuestionsFilters';
import { Question } from 'interfaces/Question';
import {
  closeQuestion,
  getIncomingQuestions,
  reactToQuestionPost,
} from 'store/features/questions/actions';
import {
  getQuestionsList,
  hasMoreQuestionsList,
  isQuestionsListLoading,
} from 'store/features/questions';
import { Layout } from './layout/Layout';
import { QuestionsHeader } from './components/QuestionsHeader';
import { QuestionList } from './components/QuestionsList';
import { CloseQuestionModal } from './components/CloseQuestionModal';
import { QuestionStatus } from 'interfaces/Question';
import { pickBy } from 'lodash';
import { useQuery } from 'hooks/useQuery';
import { NonNullableRecord } from 'utils/types';
import { ReactionType } from 'interfaces/Post';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
  backgroundColor: '#fff',
});

const initialFilters: QuestionsFiltersType = {
  status: QuestionStatus.ACTIVE,
  businessCardIds: [],
  myQuestions: false,
};

export const IncomingQuestions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const search = useQuery();

  const questions = useAppSelector(getQuestionsList);
  const isLoading = useAppSelector(isQuestionsListLoading);
  const hasMore = useAppSelector(hasMoreQuestionsList);

  const [question, setQuestion] = useState<Question['id'] | null>(null);

  const filters = useMemo(
    () =>
      ({
        ...search,
        fromDate: search.fromDate && search.fromDate,
        myQuestions: search.myQuestions === 'true',
      } as QuestionsFiltersType),
    [search],
  );

  const [listFilters, setListFilters] = useState<
    NonNullableRecord<Pick<QuestionsFiltersType, 'limit' | 'offset'>>
  >({
    limit: 10,
    offset: 0,
  });

  const isFetching = listFilters.offset === 0 && isLoading;
  const isRefetching = listFilters.offset !== 0 && isLoading;

  useEffect(() => {
    dispatch(
      getIncomingQuestions({
        ...initialFilters,
        ...filters,
        ...listFilters,
      }),
    );
  }, [dispatch, filters, listFilters]);

  const handleChangeFilters = (value: QuestionsFiltersType) => {
    setListFilters({ limit: 10, offset: 0 });
    const f = pickBy({ ...filters, ...value }, Boolean);
    navigate({ search: qs.stringify(f) });
  };

  const handleQuestionLike = (id: Question['id']) => {
    dispatch(reactToQuestionPost({ id, type: ReactionType.LIKE }));
  };

  const handleQuestionDislike = (id: Question['id']) => {
    dispatch(reactToQuestionPost({ id, type: ReactionType.DISLIKE }));
  };

  const handleQuestionClose = (id: Question['id']) => {
    setQuestion(id);
  };

  const handleQuestionCloseReject = () => {
    setQuestion(null);
  };

  const handleQuestionCloseConfirm = async () => {
    if (!question) return;
    await dispatch(closeQuestion(question));
    handleQuestionCloseReject();
  };

  const handleGoToAnswers = (id: Question['id']) =>
    navigate(`/questions/${id}/answers`);

  const changeListFilters = () => {
    setListFilters(({ offset, limit }) => ({
      limit,
      offset: offset + limit,
    }));
  };

  return (
    <Layout filters={filters} useFilters onFiltersChange={handleChangeFilters}>
      <Container>
        <QuestionsHeader
          initial={filters}
          sx={{ borderBottom: '1px solid #e4e4e4' }}
          onChange={handleChangeFilters}
        />
        <QuestionList
          sx={{ flex: 1 }}
          questions={questions}
          loading={isFetching}
          refetching={isRefetching}
          hasMore={hasMore}
          next={changeListFilters}
          onAnswer={handleGoToAnswers}
          onLike={handleQuestionLike}
          onDislike={handleQuestionDislike}
          onMore={handleGoToAnswers}
          onClose={handleQuestionClose}
        />
        <CloseQuestionModal
          open={Boolean(question)}
          onConfirm={handleQuestionCloseConfirm}
          onClose={handleQuestionCloseReject}
        />
      </Container>
    </Layout>
  );
};

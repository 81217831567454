import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled, SxProps, Theme } from '@mui/material/styles';

const StyledButton = styled(Button)({
  minHeight: 48,
  borderRadius: 14,
  padding: '5px 15px',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '16px',
  textTransform: 'none',
  boxShadow: 'none',
  backgroundColor: '#00618e',
  color: '#fff',

  '&:hover': {
    backgroundColor: '#00618e',
    color: '#fff',
  },

  '&:active': {
    backgroundColor: '#e9f8ff',
    color: '#fff',
  },

  '&.Mui-disabled': {
    backgroundColor: '#a9a9a9',
    color: '#fff',
  },
});

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  text: React.ReactNode;
} & ButtonProps;

export const ActionButton = ({ className, sx = [], text, ...other }: Props) => {
  return (
    <StyledButton
      className={className}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      variant="outlined"
      disableRipple
      {...other}
    >
      {text}
    </StyledButton>
  );
};

import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { default as MuiCheckbox, CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import checkIcon from 'assets/icons/checkIcon.svg';
import indeterminateIcon from 'assets/icons/indeterminateIcon.svg';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 1,
  width: 15,
  height: 15,
  outline: '1px solid #A9A9A9',
  backgroundColor: theme.palette.background.paper,
  'input:hover ~ &': {
    outlineColor: '#2F80ED',
  },
  'input:disabled ~ &': {
    background: '#EDEDED',
    outline: 'none',
  },
}));

const IndeterminateIcon = styled(BpIcon)({
  backgroundColor: '#FFFFFF',
  outline: '1px solid #A9A9A9',
  '&:before': {
    display: 'block',
    width: 15,
    height: 15,
    backgroundImage: `url(${indeterminateIcon})`,
    content: '""',
    marginTop: 7,
    marginLeft: 2,
    backgroundRepeat: 'no-repeat',
  },
});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#FFFFFF',
  outline: '1px solid #A9A9A9',
  '&:before': {
    display: 'block',
    width: 15,
    height: 15,
    backgroundImage: `url(${checkIcon})`,
    content: '""',
  },
});

const StyledFormControlLabel = styled(FormControlLabel)<{ checked: boolean }>(
  ({ checked }) => ({
    color: '#4B4B4B',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
    margin: 0,
    '& 	.MuiFormControlLabel-label': {
      fontSize: 12,
      lineHeight: '15px',
      color: checked ? '#4B4B4B' : '#A9A9A9',
    },
  }),
);

type Props = {
  label: string | ReactElement;
  error?: string;
};

export const Checkbox = ({ label, error, ...props }: Props & CheckboxProps) => {
  return (
    <FormControl
      error={!!error}
      sx={{
        '& .MuiFormHelperText-root': {
          marginTop: 0,
        },
      }}
    >
      <FormGroup>
        <StyledFormControlLabel
          checked={!!props.checked}
          label={label}
          control={
            <MuiCheckbox
              sx={{
                '&:hover': { bgcolor: 'transparent' },
              }}
              color={error ? 'error' : 'default'}
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              indeterminateIcon={<IndeterminateIcon />}
              inputProps={{ 'aria-label': 'Checkbox' }}
              {...props}
            />
          }
        />
      </FormGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

import { createTheme, Theme } from '@mui/material/styles';

export const HEADER_HEIGHT = 75;
export const MENU_WIDTH = 261;
export const SCROLLABLE_REFERENCE_ID = 'scrollable-reference';

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#00618E',
    },
    text: {
      secondary: '#B8B8B8',
    },
    error: {
      main: '#E04311',
    },
    background: {
      default: '#F5F5F5',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

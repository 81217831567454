import {
  SnackbarKey,
  useSnackbar,
  VariantType,
  WithSnackbarProps,
} from 'notistack';

let snackbarRef: WithSnackbarProps;

export const SnackbarConfig = () => {
  snackbarRef = useSnackbar();
  return null;
};
export const CloseSnackbar = (key: SnackbarKey) => {
  snackbarRef.closeSnackbar(key);
};

export default {
  show(msg: string, variant: VariantType = 'default'): void {
    snackbarRef.enqueueSnackbar(msg, { variant });
  },
};

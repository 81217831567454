import React, { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { IAuthCredentials, signinFormFields } from 'interfaces/Auth';
import { TextFieldInput } from 'components/shared/inputs/TextFieldInput';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/system';
import { URLS } from 'defenitions/routes';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { Checkbox } from 'components/shared/checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from 'store';
import { signin } from 'store/features/auth/actions';
import { signinValidationSchema } from 'components/features/auth/containers/signin/form/signinValidationSchema';
import { getFieldErrors } from 'store/features/auth';
import { setFromError } from 'utils/form';
import {
  ButtonContainer,
  CreateContainer,
  HelpSinginText,
  RememberContainer,
  SecondaryText,
  TextFieldContainer,
} from 'components/features/auth/shared';
import axios, { AxiosResponse } from 'axios';
import { authService } from 'services/AuthService';
import {
  getAccountCurrent,
  getUserCurrent,
} from 'store/features/users/actions';
import { useQuery } from 'hooks/useQuery';
import { baseURL } from 'services/ApiClient';
import { ReactComponent as EmailIcon } from 'assets/icons/emailIcon.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/passwordIcon.svg';

const FromContainer = styled('div')({
  padding: '0px 70px',
  display: 'flex',
  flexDirection: 'column',
});

export const SignInForm = () => {
  const dispatch = useAppDispatch();
  const fieldErrors = useAppSelector(getFieldErrors);
  const navigate = useNavigate();
  const { accountId } = authService;
  const { mustAcceptAgreement } = useQuery();

  const isNeedRenderAgreement = fieldErrors.filter(
    (el) => el.fieldName === 'agreementSigned',
  ).length;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
    setError,
  } = useForm<IAuthCredentials>({
    resolver: yupResolver(signinValidationSchema),
    mode: 'onChange',
    defaultValues: {
      id: '',
      password: '',
      rememberMe: false,
    },
  });

  const onSubmit: SubmitHandler<IAuthCredentials> = async (data) => {
    await dispatch(signin(data)).then(async (res) => {
      const response = res.payload as AxiosResponse;
      if (response.data) {
        const res = await dispatch(getUserCurrent());
        dispatch(getAccountCurrent());
        const user = res.payload as AxiosResponse;
        authService.signIn(response.data[0]?.id || user.data.id);
        navigate(URLS.business);
      }
    });
  };

  useEffect(() => {
    setFromError<IAuthCredentials>(
      Object.values(signinFormFields),
      fieldErrors,
      setError,
    );
  }, [fieldErrors, setError]);

  useEffect(() => {
    accountId && navigate(URLS.business);
  }, [accountId]);
  useEffect(() => {
    (async () => {
      const res = await axios.get(baseURL + '/users/current', {
        withCredentials: true,
      });
      res.data.id && authService.signIn(res.data.id);
    })();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FromContainer>
        <TextFieldContainer>
          <Controller
            name="id"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextFieldInput
                {...rest}
                InputProps={{
                  startAdornment: <EmailIcon />,
                }}
                inputRef={ref}
                label={'Почта'}
                placeholder={'email@yandex.ru'}
                errorMessage={errors.id?.message}
              />
            )}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextFieldInput
                {...rest}
                InputProps={{
                  startAdornment: <PasswordIcon />,
                }}
                inputRef={ref}
                type={'password'}
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
                label={'Пароль'}
                errorMessage={errors.password?.message}
              />
            )}
          />
        </TextFieldContainer>
        {(mustAcceptAgreement || !!isNeedRenderAgreement) && (
          <RememberContainer>
            <Controller
              name="agreementSigned"
              control={control}
              defaultValue={false}
              render={({ field: { ref, ...rest } }) => (
                <Checkbox
                  {...rest}
                  error={errors.agreementSigned?.message}
                  inputRef={ref}
                  label={
                    <>
                      Я подтверждаю, что принимаю{' '}
                      <HelpSinginText
                        href={URLS.termsOfService}
                        target={'_blank'}
                      >
                        пользовательское соглашение
                      </HelpSinginText>
                    </>
                  }
                />
              )}
            />
          </RememberContainer>
        )}
        <RememberContainer>
          <Controller
            name="rememberMe"
            control={control}
            defaultValue={false}
            render={({ field: { ref, ...rest } }) => (
              <Checkbox {...rest} inputRef={ref} label={'Запомнить меня'} />
            )}
          />
          <HelpSinginText href={URLS.restore}>Забыли пароль?</HelpSinginText>
        </RememberContainer>
        <ButtonContainer>
          <ActionButton
            sx={{ fontSize: 20, lineHeight: '32px', minHeight: 64 }}
            fullWidth
            disabled={!isDirty || !isValid || isSubmitting}
            text={'Войти'}
            type={'submit'}
          />
        </ButtonContainer>
        <CreateContainer>
          <SecondaryText>Еще нет аккаунта?</SecondaryText>
          <HelpSinginText href={URLS.signup}>Зарегистрироваться</HelpSinginText>
        </CreateContainer>
      </FromContainer>
    </form>
  );
};

import React from 'react';
import Grid from '@mui/material/Grid';
import { BusinessCardHeader } from 'components/features/marketplace/components/BusinessCardHeader';
import { getLocaleValue } from 'utils/form';
import Rating from '@mui/material/Rating';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { IReview } from 'interfaces/Review';

type Props = {
  review: IReview;
};

export const ReviewsBusiness = ({ review }: Props) => {
  return (
    <Grid item xs={12} ml={3} mb={2} sx={{ borderBottom: '1px solid #C9D1D7' }}>
      <BusinessCardHeader
        src={review?.businessCard?.logo?.path}
        name={getLocaleValue(review?.businessCard.name) || ''}
        user={`${review?.creator.name} ${review?.creator.lastName}, #${review?.creator.reputation}`}
        created={review.createdDateTime}
        id={review?.businessCard?.id}
      />
      <Rating value={review.grade} readOnly />
      <div>{getLocaleValue(review.content)}</div>
      {review.attachments && <Thumbs attachments={review.attachments} />}
    </Grid>
  );
};

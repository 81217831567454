import React from 'react';
import { FieldType, INotificationSettingsItem } from 'interfaces/Notifications';
import Grid from '@mui/material/Grid';
import { getLocaleValue } from 'utils/form';
import { ExpandMore } from 'components/elements/collapse';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Collapse from '@mui/material/Collapse';
import { NotificationSettingTitle } from 'components/features/settings/components/index';
import { Checkbox } from 'components/shared/checkbox';

type Props = {
  item: INotificationSettingsItem;
  onChangeItem: (index: number, field: FieldType) => void;
};

export const NotificationSettingItem = ({ item, onChangeItem }: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <Grid
      item
      container
      style={{ background: '#FFFFFF' }}
      mt={2}
      mb={2}
      pt={1}
      pb={1}
    >
      <Grid item container>
        <Grid
          item
          container
          xs={2}
          alignItems={'center'}
          justifyContent={'center'}
        />
        <Grid
          item
          container
          xs={2}
          alignItems={'center'}
          justifyContent={'center'}
        />
        <Grid item container xs alignItems={'center'}>
          <ExpandMore
            style={{ padding: 0, width: 30, height: 30, margin: 0 }}
            onClick={handleClickOpen}
            expand={open}
            aria-expanded={open}
            aria-label="show more"
          >
            <ExpandMoreRoundedIcon style={{ fill: '#00618E' }} />
          </ExpandMore>
          <NotificationSettingTitle
            style={{ color: '#00618E', cursor: 'pointer' }}
            onClick={handleClickOpen}
          >
            {getLocaleValue(item.value)}
          </NotificationSettingTitle>
        </Grid>
      </Grid>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ width: '100%' }}
      >
        {item.children?.map((el, index) => (
          <Grid item container key={el.code} pb={1} pt={1}>
            <Grid
              item
              container
              xs={2}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Checkbox
                label={''}
                checked={!!el.setting.LK}
                onChange={() => onChangeItem(index, FieldType.LK)}
              />
            </Grid>
            <Grid
              item
              container
              xs={2}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Checkbox
                label={''}
                checked={el.setting.EMAIL}
                onChange={() => onChangeItem(index, FieldType.EMAIL)}
              />
            </Grid>
            <Grid item container xs alignItems={'center'}>
              <NotificationSettingTitle
                style={{ paddingLeft: 30, textAlign: 'initial' }}
              >
                {getLocaleValue(el.value)}
              </NotificationSettingTitle>
            </Grid>
          </Grid>
        ))}
      </Collapse>
    </Grid>
  );
};

import {
  IBusiness,
  IPublishedBusinessCard,
  IBusinessPublished,
} from 'interfaces/Business';
import {
  IBusinessFilters,
  IBusinessReviewsFilters,
  PAGE_SIZE,
  SortName,
  SortType,
} from 'interfaces/BusinessFilters';
import { Locale } from 'interfaces/Locale';
import { IReview } from 'interfaces/Review';
export enum ActionTypes {
  GET_BUSINESS = 'GET_BUSINESS',
  GET_PUBLISHED_BUSINESS_CARDS = 'GET_PUBLISHED_BUSINESS_CARDS',
  GET_BUSINESS_CARD = 'GET_BUSINESS_CARD',
  GET_MY_BUSINESS_CARD = 'GET_MY_BUSINESS_CARD',
  CREATE_BUSINESS_CARD = 'CREATE_BUSINESS_CARD',
  UPDATE_BUSINESS_CARD = 'UPDATE_BUSINESS_CARD',
  DELETE_BUSINESS_CARD = 'DELETE_BUSINESS_CARD',
  UNPUBLISH_BUSINESS_CARD = 'UNPUBLISH_BUSINESS_CARD',
  UPLOAD_BUSINESS_CARD_LOGO = 'UPLOAD_BUSINESS_CARD_LOGO',
  UPLOAD_BUSINESS_CARD_ATTACHMENTS = 'UPLOAD_BUSINESS_CARD_ATTACHMENTS',
  GET_BUSINESS_PUBLISHED = 'GET_BUSINESS_PUBLISHED',
  UPLOAD_SERVICE_CARD_ATTACHMENTS = 'UPLOAD_SERVICE_CARD_ATTACHMENTS',
  SUBSCRIBE_SERVICE_CARD = 'SUBSCRIBE_SERVICE_CARD',
  UNSUBSCRIBE_SERVICE_CARD = 'UNSUBSCRIBE_SERVICE_CARD',
  GET_COUNT_BUSINESS = 'GET_COUNT_BUSINESS',
  GET_ALL_BUSINESS_CARDS = 'GET_ALL_BUSINESS_CARDS',
  GET_BUSINESS_CARD_REVIEWS = 'GET_BUSINESS_CARD_REVIEWS',
  GET_BUSINESS_CARD_BY_ACCOUNT = 'GET_BUSINESS_CARD_BY_ACCOUNT',
}

export const initialBusinessFilters: IBusinessFilters = {
  cardTypes: [],
  services: [],
  offset: 0,
  limit: PAGE_SIZE,
  order: SortType.DESC,
  sort: SortName.publishedDateTime,
  locale: Locale.ru,
  text: null,
};

export const initialBusinessReviewsFilters: IBusinessReviewsFilters = {
  businessCardId: '',
  offset: 0,
  limit: PAGE_SIZE,
};

export interface IBusinessState {
  list: {
    business: IBusiness[];
    filters: IBusinessFilters;
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
  item: {
    businessCard: IBusiness | null;
    reviewsFilters: IBusinessReviewsFilters;
    businessCardByAccount: IPublishedBusinessCard | null;
    reviews: IReview[];
    loading: boolean;
    loaded: boolean;
  };
  publishedBusiness: IBusinessPublished[];
  publishedBusinessCards: IPublishedBusinessCard[];
  all: IPublishedBusinessCard[];
}

export const initialBusinessState: IBusinessState = {
  list: {
    business: [],
    filters: initialBusinessFilters,
    loading: false,
    loaded: false,
    hasMore: false,
  },
  item: {
    businessCard: null,
    reviewsFilters: initialBusinessReviewsFilters,
    businessCardByAccount: null,
    reviews: [],
    loaded: false,
    loading: false,
  },
  publishedBusiness: [],
  publishedBusinessCards: [],
  all: [],
};

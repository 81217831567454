import React from 'react';
import Link from '@mui/material/Link';
import { URLS } from 'defenitions/routes';

export const NotFound = () => {
  return (
    <div>
      <div>Page not found</div>
      <Link href={URLS.home}>home</Link>
    </div>
  );
};

import React from 'react';
import { Avatar, styled, SxProps, Theme } from '@mui/material';
import { IUserRating } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { getNameInitials } from 'utils/user';

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  backgroundColor: theme.palette.background.paper,
}));

const UserAvatar = styled(Avatar)({
  height: 45,
  width: 45,
});

const Container = styled('div')({
  flex: 1,
});

const Name = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '500',
  fontSize: 14,
  color: '#000000',
});

const Info = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

type Props = {
  sx?: SxProps<Theme>;
  item: IUserRating;
  onRatingClick?: (id: string) => void;
};

export const UserCard = ({ sx = [], item, onRatingClick }: Props) => {
  const { t } = useTranslation();
  const { count, grade, user } = item;

  const avatar = user?.logo?.path;
  const name = `${user?.name || ''} ${user?.lastName || ''}`.trim();

  const handleRatingClick = () => {
    onRatingClick?.(user.id);
  };

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <UserAvatar src={avatar}>{getNameInitials(name)}</UserAvatar>
      <Container sx={{ ml: 2 }}>
        <Name>{name}</Name>
        <Info>
          {t('moderation.complaints.count')}: {count}
        </Info>
        <Info
          sx={{ cursor: onRatingClick ? 'pointer' : 'default' }}
          onClick={handleRatingClick}
        >
          {t('moderation.complaints.rating')}: {grade}
        </Info>
      </Container>
    </Root>
  );
};

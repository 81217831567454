import React from 'react';
import { SignupLayout } from 'components/features/auth/layout/signupLayout';
import { RouteNames, URLS } from 'defenitions/routes';

export const TermsOfService = () => {
  return (
    <SignupLayout title={RouteNames[URLS.termsOfService]}>
      <div>Text terms of conditions here</div>
    </SignupLayout>
  );
};

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import { initialStatsState } from 'store/features/stats/config';
import { RootState } from 'store/index';
import { AxiosResponse } from 'axios';
import {
  getBusinessStats,
  getUsersStats,
  getUserStats,
  getBusinessDetailedStats,
  getAllBusinessCards,
  getAllUsers,
} from 'store/features/stats/actions';
import {
  IBusinessCardsFiltersItem,
  IBusinessDetailedStats,
  IBusinessStats,
  IStatsFilters,
  IUsersFiltersItem,
  IUsersStats,
  IUserStats,
} from 'interfaces/Stats';

const statsSlice = createSlice({
  name: Slices.stats,
  initialState: initialStatsState,
  reducers: {
    changeBusinessFilters: (state, action: PayloadAction<IStatsFilters>) => {
      const { payload } = action;
      state.business.filters = payload;
    },
    changeUsersFilters: (state, action: PayloadAction<IStatsFilters>) => {
      const { payload } = action;
      state.users.filters = payload;
    },
    resetBusinessList: (state) => {
      state.business.business = [];
      state.business.filters = {
        userIds: [],
        businessCardIds: [],
        fromDate: null,
        toDate: null,
        extended: true,
      };
    },
    resetUsersList: (state) => {
      state.users.users = [];
      state.users.filters = {
        userIds: [],
        businessCardIds: [],
        fromDate: null,
        toDate: null,
        extended: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessStats.pending, (state) => {
      state.business.loading = true;
    });
    builder.addCase(getBusinessStats.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<IBusinessStats[]>;
      state.business.business = data;
      state.business.loading = false;
    });
    builder.addCase(getBusinessStats.rejected, (state) => {
      state.business.business = [];
      state.business.loading = false;
    });

    //users
    builder.addCase(getUsersStats.pending, (state) => {
      state.users.loading = true;
    });
    builder.addCase(getUsersStats.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<IUsersStats[]>;
      state.users.users = data;
      state.users.loading = false;
    });
    builder.addCase(getUsersStats.rejected, (state) => {
      state.users.users = [];
      state.users.loading = false;
    });

    //user
    builder.addCase(getUserStats.pending, (state) => {
      state.user.loading = true;
    });
    builder.addCase(getUserStats.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<IUserStats>;
      state.user.stats = data;
      state.user.loading = false;
    });
    builder.addCase(getUserStats.rejected, (state) => {
      state.user.stats = null;
      state.user.loading = false;
    });

    //businessDetailed
    builder.addCase(getBusinessDetailedStats.pending, (state) => {
      state.businessDetailed.loading = true;
    });
    builder.addCase(
      getBusinessDetailedStats.fulfilled,
      (state, { payload }) => {
        const { data } = payload as AxiosResponse<IBusinessDetailedStats>;
        state.businessDetailed.businessDetailed = data;
        state.businessDetailed.loading = false;
      },
    );
    builder.addCase(getBusinessDetailedStats.rejected, (state) => {
      state.businessDetailed.businessDetailed = null;
      state.businessDetailed.loading = false;
    });

    builder.addCase(getAllBusinessCards.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<IBusinessCardsFiltersItem[]>;
      state.filters.businessCards = data;
    });
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<IUsersFiltersItem[]>;
      state.filters.users = data;
    });
  },
});

export { statsSlice };
export const {
  changeBusinessFilters,
  changeUsersFilters,
  resetBusinessList,
  resetUsersList,
} = statsSlice.actions;
export const selectBusinessStats = (state: RootState) =>
  state.stats.business.business;
export const selectBusinessStatsLoading = (state: RootState) =>
  state.stats.business.loading;
export const selectUsersStats = (state: RootState) => state.stats.users.users;
export const selectUsersStatsLoading = (state: RootState) =>
  state.stats.users.loading;
export const selectStatsFilters = (state: RootState) => state.stats.filters;

export const selectUserStats = (state: RootState) => state.stats.user.stats;
export const selectUserStatsLoading = (state: RootState) =>
  state.stats.user.loading;

export const selectBusinessDetailedStats = (state: RootState) =>
  state.stats.businessDetailed.businessDetailed;
export const selectBusinessDetailedStatsLoading = (state: RootState) =>
  state.stats.businessDetailed.loading;

const self = (state: RootState) => state.stats;

export const selectBusinessFilters = createSelector(
  self,
  (state) => state.business.filters,
);

export const selectUsersFilters = createSelector(
  self,
  (state) => state.users.filters,
);

export const selectAllBusinessCards = createSelector(
  self,
  (state) => state.filters.businessCards,
);

export const selectAllUsers = createSelector(
  self,
  (state) => state.filters.users,
);

import React, { CSSProperties } from 'react';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

type Props = {
  expandDefault?: boolean;
  title: string;
  children: React.ReactNode;
  style?: CSSProperties;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  return <IconButton {...props} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const TitleContainer = styled('div')({
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'row',
  alignItems: 'center',
});

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
  color: '#000000',
});

export const CollapseLayout = ({
  title,
  style,
  children,
  expandDefault = false,
}: Props) => {
  const [open, setOpen] = React.useState(expandDefault);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div style={style}>
      <TitleContainer onClick={handleClick}>
        <Title>{title}</Title>
        <ExpandMore expand={open} aria-expanded={open} aria-label="show more">
          <ExpandMoreRoundedIcon />
        </ExpandMore>
      </TitleContainer>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

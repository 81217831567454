import React, { CSSProperties } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
const Title = styled('div')({
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '29px',
  color: '#00618E',
});

export const EmptyList = ({ style }: { style: CSSProperties }) => {
  const { t } = useTranslation();
  return <Title style={style}>{t('empty list')}</Title>;
};

import {
  IBusinessCardsFiltersItem,
  IBusinessDetailedStats,
  IBusinessStats,
  IStatsFilters,
  IUsersFiltersItem,
  IUsersStats,
  IUserStats,
} from 'interfaces/Stats';

export enum ActionTypes {
  GET_BUSINESS_STATS = 'GET_BUSINESS_STATS',
  GET_USERS_STATS = 'GET_USERS_STATS',
  GET_USER_STATS = 'GET_USER_STATS',
  GET_BUSINESS_DETAILED_STATS = 'GET_BUSINESS_DETAILED_STATS',
  GET_ALL_BUSINESS_CARDS = 'GET_ALL_BUSINESS_CARDS',
  GET_ALL_USERS = 'GET_ALL_USERS',
}

export interface IStatsState {
  business: {
    business: IBusinessStats[];
    loading: boolean;
    filters: IStatsFilters;
  };
  users: {
    users: IUsersStats[];
    loading: boolean;
    filters: IStatsFilters;
  };
  user: {
    stats: IUserStats | null;
    loading: boolean;
  };
  businessDetailed: {
    businessDetailed: IBusinessDetailedStats | null;
    loading: boolean;
  };
  filters: {
    businessCards: IBusinessCardsFiltersItem[];
    users: IUsersFiltersItem[];
  };
}

export const initialFilters: IStatsFilters = {
  userIds: [],
  businessCardIds: [],
  fromDate: null,
  toDate: null,
  extended: true,
};

export const initialStatsState: IStatsState = {
  business: {
    business: [],
    loading: false,
    filters: initialFilters,
  },
  users: {
    users: [],
    loading: false,
    filters: initialFilters,
  },
  user: {
    stats: null,
    loading: false,
  },
  businessDetailed: {
    businessDetailed: null,
    loading: false,
  },
  filters: {
    businessCards: [],
    users: [],
  },
};

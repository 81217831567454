import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled, SxProps, Theme } from '@mui/material';
import { Loader } from 'components/shared/loader';
import { IAccountRating } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { RatingsCard } from './RatingsCard';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

const Item = styled('div')({
  marginBottom: 8,

  '&:last-of-type': {
    marginBottom: 0,
  },
});

const EmptyListContainer = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

type Props = {
  sx?: SxProps<Theme>;
  accounts: IAccountRating[];
  loading?: boolean;
  refetching?: boolean;
  hasMore?: boolean;
  next?: () => void;
  onAccountRatingClick?: (accountId: string) => void;
  onUserRatingClick?: (userId: string) => void;
};

export const RatingsList = ({
  sx = [],
  accounts,
  loading = false,
  refetching = false,
  hasMore = false,
  next,
  onAccountRatingClick,
  onUserRatingClick,
}: Props) => {
  const { t } = useTranslation();

  const handleNext = () => next?.();

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {loading && (
        <EmptyListContainer>
          <Loader show />
        </EmptyListContainer>
      )}

      {!loading && !accounts.length && (
        <EmptyListContainer>{t('moderation.ratings.empty')}</EmptyListContainer>
      )}

      {!loading && !!accounts.length && (
        <InfiniteScroll
          dataLength={accounts.length}
          loader={<Loader show={refetching} global />}
          hasMore={hasMore}
          next={handleNext}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {accounts.map((item) => (
            <Item key={item.account.id}>
              <RatingsCard
                item={item}
                onAccountRatingClick={onAccountRatingClick}
                onUserRatingClick={onUserRatingClick}
              />
            </Item>
          ))}
        </InfiniteScroll>
      )}
    </Root>
  );
};

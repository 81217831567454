import React, { useRef, useState } from 'react';
import qs from 'qs';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import { TabLayout } from 'components/elements/tabs';
import { useQuery } from 'hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { findKey } from 'lodash';
import { IncomingServiceRequestsList } from './IncomingServiceRequestsList';
import { MyOffersList } from './MyOffersList';
import { IncomingServiceRequestsFilters } from 'components/features/marketplace/containers/provideService/IncomingServiceRequestsFilters';
import {
  IListFilters,
  IProvideServicesPageFilters,
} from 'interfaces/Marketplace';
import { styled } from '@mui/material';
import {
  SearchRef,
  SearchTextFieldInput,
} from 'components/shared/inputs/SearchInput';
import { MyOffersFilters } from 'components/features/marketplace/containers/provideService/MyOffersFilters';
import { useTranslation } from 'react-i18next';
import { stringToBoolean } from 'utils/boolean';
import { GuideIcon } from 'components/elements/guideIcon';

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const SearchContainer = styled('div')({
  position: 'absolute',
  right: 20,
  top: 15,
  width: 300,
  transform: 'translateY(50%)',
  display: 'flex',
});

enum TabsValue {
  Incoming = 'incoming',
  Offers = 'offers',
}

const TabsMap = {
  [TabsValue.Incoming]: 0,
  [TabsValue.Offers]: 1,
} as const;

type Filters = { tab: TabsValue } & IProvideServicesPageFilters;

export const MarketplaceProvideService = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const search = useQuery();

  const searchRef = useRef<SearchRef>(null);

  const filters = {
    tab: search.tab ?? TabsValue.Incoming,
    text: search.text,
    fromDate: search.fromDate ? parseInt(search.fromDate as string) : null,
    withClosed: stringToBoolean(search.withClosed as string) ?? false,
    businessCardIds: search.businessCardIds,
    onlyCreatedByCurrentUser:
      stringToBoolean(search.onlyCreatedByCurrentUser as string) ?? false,
    serviceTypes: search.serviceTypes ?? [],
  } as Filters;

  const [listFilters, setListFilters] = useState<IListFilters>({
    limit: 10,
    offset: 0,
  });

  const handleChangeTabs = (_: unknown, value: number) => {
    searchRef.current?.clear();
    setListFilters({ limit: 10, offset: 0 });
    const name = findKey(TabsMap, (item) => item === value);
    navigate({ search: qs.stringify({ tab: name }, { skipNulls: true }) });
  };

  const handleFiltersChange = (value: IProvideServicesPageFilters) => {
    const withClosed = value.withClosed || null;
    const onlyCreatedByCurrentUser = value.onlyCreatedByCurrentUser || null;

    setListFilters({ limit: 10, offset: 0 });
    navigate({
      search: qs.stringify(
        {
          ...filters,
          ...value,
          withClosed,
          onlyCreatedByCurrentUser,
        },
        { skipNulls: true },
      ),
    });
  };

  const handleSearchChange = (value: string) => {
    setListFilters({ limit: 10, offset: 0 });
    navigate({
      search: qs.stringify(
        { ...filters, text: value || null },
        { skipNulls: true },
      ),
    });
  };

  const handleListFiltersChange = (value: IProvideServicesPageFilters) => {
    setListFilters({ limit: value.limit, offset: value.offset });
  };

  return (
    <MarketplaceLayout
      contentSx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container>
        <TabLayout
          tab={TabsMap[filters.tab]}
          tabs={[
            {
              label: t('marketplace.navigation.incomingRequests'),
              component: (
                <>
                  <IncomingServiceRequestsFilters
                    sx={{ mb: 3, borderBottom: '1px solid #ddd' }}
                    filters={filters}
                    onChange={handleFiltersChange}
                  />
                  <IncomingServiceRequestsList
                    filters={{ ...filters, ...listFilters }}
                    onFiltersChange={handleListFiltersChange}
                  />
                </>
              ),
            },
            {
              label: t('marketplace.navigation.myOffers'),
              component: (
                <>
                  <MyOffersFilters
                    sx={{ mb: 3, borderBottom: '1px solid #ddd' }}
                    filters={filters}
                    onChange={handleFiltersChange}
                  />
                  <MyOffersList
                    filters={{ ...filters, ...listFilters }}
                    onFiltersChange={handleListFiltersChange}
                  />
                </>
              ),
            },
          ]}
          onChangeTabs={handleChangeTabs}
        />

        <SearchContainer>
          <SearchTextFieldInput
            ref={searchRef}
            defaultValue={filters.text ?? ''}
            onChangeSearch={handleSearchChange}
          />
          <GuideIcon withContainer />
        </SearchContainer>
      </Container>
    </MarketplaceLayout>
  );
};

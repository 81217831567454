import { styled } from '@mui/system';
import Link from '@mui/material/Link';

export const TextFieldContainer = styled('div')({
  padding: '12px 0px',
});

export const ButtonContainer = styled('div')({
  padding: '24px 0px',
});

export const RememberContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const CreateContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const SecondaryText = styled('div')({
  color: '#969696',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 15,
  lineHeight: '24px',
  paddingRight: 8,
});

export const HelpSinginText = styled(Link)({
  color: '#004B7C',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '24px',
  textDecoration: 'none',
});
export const HelpText = styled('div')({
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '24px',
  color: '#666666',
});

import qs from 'qs';
import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { IStatsFilters, IStatsItemFilters } from 'interfaces/Stats';

export const getBusinessStatsEndpoint = (
  filters: IStatsFilters,
): Promise<AxiosResponse> => {
  return apiClient.get('/statistics/business-cards', {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });
};

export const getUsersStatsEndpoint = (
  filters: IStatsFilters,
): Promise<AxiosResponse> => {
  return apiClient.get('/statistics/users', {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });
};

export const getUserStatsEndpoint = (
  id: string,
  filters?: IStatsItemFilters,
): Promise<AxiosResponse> => {
  return apiClient.get(`/statistics/users/${id}`, {
    params: { ...filters, extended: true },
  });
};

export const getBusinessDetailedStatsEndpoint = (
  id: string,
  filters?: IStatsItemFilters,
): Promise<AxiosResponse> => {
  return apiClient.get(`/statistics/business-cards/${id}`, {
    params: filters,
  });
};

export const getAllBusinessCardsEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get('/business-cards/account/all');
};

export const getAllUsersEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get('/users/list');
};

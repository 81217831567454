import React from 'react';

import { Layout } from './layout/Layout';
import { SubscriptionsHeader } from 'components/features/questions/components/SubscriptionsHeader';
import { SubscriptionsForm } from 'components/features/questions/components/SubscriptionsForm';

export const QuestionsSubscriptions = () => {
  return (
    <Layout>
      <SubscriptionsHeader />
      <SubscriptionsForm />
    </Layout>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { styled } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAppDispatch, useAppSelector } from 'store';
import { getBusinessPublishedList } from 'store/features/business';
import { getBusinessPublished } from 'store/features/business/actions';
import {
  getServiceRequestById,
  saveMarketplaceOfferAnswer,
  uploadMarketplaceAttachment,
} from 'store/features/marketplace/actions';
import { AnswerStatuses } from 'interfaces/Marketplace';

import { IAttachment, AttachmentType } from 'interfaces/Attachment';
import { IMultiLanguage } from 'interfaces/Locale';
import { offerAnswerFileTypes } from 'defenitions/fileUploader';

import { ActionButton } from 'components/shared/buttons/ActionButton';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { SelectTextFields } from 'components/shared/selects';

import { serviceDataValidationSchema } from './validationSchema';
import {
  getServiceRequest,
  isMarketplaceItemLoading,
} from 'store/features/marketplace';
import { ServiceRequestDetailedCard } from 'components/features/marketplace/components/cards/ServiceRequestDetailedCard';
import { Loader } from 'components/shared/loader';
import { URLS } from 'defenitions/routes';
import { useQuery } from 'hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { BackButton } from 'components/shared/buttons/BackButton';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { selectSoftwareCardAccount } from 'store/features/software';
import { getSoftwareCardAccount } from 'store/features/software/actions';

const Wrap = styled('div')({
  border: '1px solid #E4E4E4',
  borderRadius: '5px',
  width: '100%',
});

const Header = styled('div')({
  borderBottom: '1px solid #DDDDDD',
  width: '100%',
  display: 'flex',
  padding: '13px 34px 18px',
  alignItems: 'center',
});

const Title = styled('div')({
  fontSize: 22,
  fontWeight: 'bold',
  marginLeft: '204px',
  display: 'flex',
  alignItems: 'center',
});

const OfferAnswerHeader = styled('div')({
  padding: '15px 34px',
  borderBottom: '1px solid #DDDDDD',
  borderTop: '1px solid #DDDDDD',
});

const InputWrap = styled('div')({
  marginBottom: '27px',
});

interface CreateOfferAnswerForm {
  businessCardId: string;
  title: IMultiLanguage;
  content: IMultiLanguage;
  softwareCardIds?: IDictionariesContent[];
}

const ButtonsWrap = styled('div')({
  display: 'flex',
  padding: '45px 85px',
  borderBottom: '1px solid #DDDDDD',
  borderTop: '1px solid #DDDDDD',
  '& button': {
    width: 'fit-content',
    ':nth-child(2)': {
      marginLeft: 'auto',
      marginRight: '41px',
    },
  },
});

const Form = styled('div')({
  padding: '45px 85px',
});

type Params = { id: string };

export const MarketplaceCreateOfferAnswer = () => {
  const { t } = useTranslation();

  const language = 'ru';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id = '' } = useParams<Params>();
  const businessCards = useAppSelector(getBusinessPublishedList);
  const request = useAppSelector(getServiceRequest);
  const isLoading = useAppSelector(isMarketplaceItemLoading);
  const softwareCards = useAppSelector(selectSoftwareCardAccount);
  const software = softwareCards.map((s) => ({
    code: s.id,
    display: s.name,
    selectable: true,
  }));
  const search = useQuery();
  const defaultBusinessCard = search?.businessCard as string;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid, isSubmitting },
    trigger,
    setValue,
  } = useForm<CreateOfferAnswerForm>({
    mode: 'onChange',
    resolver: yupResolver(serviceDataValidationSchema),
    defaultValues: {},
  });
  const [attachments, setAttachments] = useState<IAttachment[]>([]);

  const formData = watch();

  const canSendForm = useMemo(
    () =>
      attachments.length > 0 ||
      formData.content?.ru?.length > 0 ||
      formData.content?.en?.length > 0,
    [formData, attachments],
  );

  const businessOptions = useMemo(
    () =>
      businessCards.map((item) => ({
        id: item?.id,
        name: item?.name?.[language],
      })),
    [businessCards],
  );

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const navigateToMyRequests = useCallback(() => {
    navigate(URLS.marketplaceProvideService);
  }, [navigate]);

  const onSubmit = useCallback(
    (status: AnswerStatuses) => (data: CreateOfferAnswerForm) => {
      dispatch(
        saveMarketplaceOfferAnswer({
          ...data,
          attachments: attachments.map((item) => item.id),
          status,
          offerId: id!,
          softwareCardIds: data.softwareCardIds?.map((el) => el.code),
        }),
      );
      navigateToMyRequests();
    },
    [id, attachments],
  );

  const handleSoftwareCardsOptions = useCallback(() => {
    dispatch(getSoftwareCardAccount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getServiceRequestById(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getBusinessPublished());
  }, [dispatch]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (businessOptions.length === 1) {
      setValue('businessCardId', businessOptions[0].id);
      trigger();
    }
    if (defaultBusinessCard) {
      setValue('businessCardId', defaultBusinessCard);
      trigger();
    }
  }, [businessOptions, trigger, defaultBusinessCard]);

  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      if (formData.get('files') === null) return;
      const { payload } = await dispatch(
        uploadMarketplaceAttachment({
          data: formData,
          type: AttachmentType.OfferAnswer,
        }),
      );
      const { data } = payload as AxiosResponse<IAttachment[]>;
      data && setAttachments([...attachments, ...data]);
    },
    [dispatch, attachments],
  );

  const handleRemoveAttachment = useCallback(
    (id) => {
      setAttachments(attachments.filter((attachment) => attachment.id !== id));
    },
    [attachments],
  );

  useEffect(() => {
    businessOptions.length === 1 &&
      setValue('businessCardId', businessOptions[0].id);
    trigger('businessCardId');
  }, [businessOptions, setValue, trigger]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <MarketplaceLayout
      contentSx={{ bgcolor: (theme) => theme.palette.background.paper }}
    >
      <Wrap>
        <Header>
          <BackButton onClick={handleGoBack} />
          <Title>{t('marketplace.createOffer')}</Title>
        </Header>
        {isLoading && <Loader sx={{ m: 'auto' }} show />}
        {!isLoading && request && (
          <ServiceRequestDetailedCard
            sx={{ borderBottom: '1px solid #e4e4e4' }}
            type={t('marketplace.request')}
            request={request}
            showStatus
          />
        )}
        <OfferAnswerHeader>
          <b>{t('marketplace.yourAnswer')}</b>
        </OfferAnswerHeader>
        <Form onSubmit={handleSubmit(onSubmit(AnswerStatuses.DRAFT))}>
          <InputWrap>
            <FormMultiLocaleField
              fieldName={'title'}
              label={t('marketplace.offerHeader')}
              errors={errors}
              control={control}
            />
          </InputWrap>
          <InputWrap>
            <Controller
              name="businessCardId"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <SelectTextFields
                  {...rest}
                  options={businessOptions}
                  inputRef={ref}
                  label={t('formFields.business')}
                  errorMessage={errors.businessCardId?.message}
                  disabled={!!defaultBusinessCard}
                  createLink={URLS.createBusinessCard}
                />
              )}
            />
          </InputWrap>
          <InputWrap>
            <Controller
              control={control}
              defaultValue={[]}
              name="softwareCardIds"
              render={({ field: { ref, value, ...rest } }) => (
                <MultiSelectAutocomplete
                  {...rest}
                  value={value}
                  noHierarchy
                  multiple
                  inputRef={ref}
                  label="ПО"
                  data={software}
                  onLoadOptions={handleSoftwareCardsOptions}
                />
              )}
            />
          </InputWrap>
          <InputWrap>
            <FormMultiLocaleField
              fieldName={'content'}
              label={t('marketplace.offerDescription')}
              errors={errors}
              control={control}
              minRows={6}
              multiline
              isEditor
            />
          </InputWrap>
          <FilesUploader
            onClickRemoveAttachments={handleRemoveAttachment}
            onUploadAttachments={handleUploadAttachments}
            attachments={attachments}
            hideTitle
            accept={offerAnswerFileTypes}
            maxFiles={10}
            maxSize={26214400}
          />
        </Form>
        <ButtonsWrap>
          <CreateButton fullWidth text={t('cancel')} onClick={handleGoBack} />
          <ActionButton
            fullWidth
            disabled={!isValid || isSubmitting || !canSendForm}
            text={t('save')}
            type={'submit'}
            onClick={handleSubmit(onSubmit(AnswerStatuses.DRAFT))}
          />
          <ActionButton
            fullWidth
            disabled={!isValid || isSubmitting || !canSendForm}
            text={t('actions.saveAndPublish')}
            type={'submit'}
            onClick={handleSubmit(onSubmit(AnswerStatuses.PUBLISHED))}
          />
        </ButtonsWrap>
      </Wrap>
    </MarketplaceLayout>
  );
};

import React from 'react';
import { Layout } from 'components/features/auth/layout/layout';
import {
  CreateContainer,
  HelpSinginText,
  HelpText,
  SecondaryText,
} from 'components/features/auth/shared';
import { URLS } from 'defenitions/routes';
import Grid from '@mui/material/Grid';

export const SuccessSignUp = () => {
  return (
    <Layout title={'Учётная запись участника успешно создана'}>
      <Grid item xs={12}>
        <HelpText style={{ padding: 30, textAlign: 'center' }}>
          На вашу почту отправлена ссылка , перейдите по ней для для активации
          учётной записи
        </HelpText>
      </Grid>
      <Grid item xs={12}>
        <CreateContainer>
          <SecondaryText>Есть аккаунт?</SecondaryText>
          <HelpSinginText href={URLS.signin}>Войти</HelpSinginText>
        </CreateContainer>
      </Grid>
    </Layout>
  );
};

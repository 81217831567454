import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { IBusinessService } from 'interfaces/Business';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { Editor } from 'components/shared/editor';

const Container = styled(Grid)({
  padding: '27px 32px',
  background: '#FFFFFF',
  border: '1px solid #DDDDDD',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  marginBottom: 42,
});

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 15,
  lineHeight: '34px',
  color: '#3D3D3D',
});

const Text = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 13,
  lineHeight: '24px',
  color: '#000000',
});

type Props = {
  businessService: IBusinessService;
  onClickRemoveBusinessService?: () => void;
  onClickEdit?: () => void;
  isCreatedByCurrentAccount: boolean;
};

export const ViewBusinessService = ({
  businessService,
  onClickRemoveBusinessService,
  onClickEdit,
  isCreatedByCurrentAccount,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
  const { isOpen, onClickOpen, onClickClose } = useModalActions();

  return (
    <Container container onMouseLeave={() => setAnchorEl(null)}>
      <Grid item xs={2}>
        <Title>Тип услуги:</Title>
        <Text>{businessService.serviceType?.display.ru}</Text>
      </Grid>
      <Grid item xs={4}>
        <Title>Наименование услуги:</Title>
        <Text>{businessService.name.ru}</Text>
      </Grid>
      <Grid item xs={6}>
        <Title>Описание:</Title>
        <Text>
          <Editor
            value={getLocaleValue(businessService.description)}
            readOnly
          />
        </Text>
      </Grid>
      <Grid item xs={12} marginTop={3}>
        {businessService.attachments && (
          <Thumbs attachments={businessService.attachments} />
        )}
      </Grid>
      {!isCreatedByCurrentAccount && (
        <MoreIcon
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        />
      )}
      {anchorEl && (
        <MoreMenu
          anchorEl={anchorEl}
          onClickEdit={onClickEdit}
          onClickDelete={onClickRemoveBusinessService}
          onClickComplaint={onClickOpen}
        />
      )}
      <ConfirmDialog
        onClickClose={onClickClose}
        open={isOpen}
        title={t('modalTexts.complaint.title', {
          name: getLocaleValue(businessService.name),
        })}
      >
        <CreateComplaint
          relationId={businessService.id}
          complaintAboutType={ComplaintAboutType.BUSINESS_SERVICE}
          additionAction={onClickClose}
        />
      </ConfirmDialog>
    </Container>
  );
};

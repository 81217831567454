import React from 'react';
import { SearchTextFieldInput } from 'components/shared/inputs/SearchInput';
import { styled, SxProps, Theme } from '@mui/system';
import {
  IRequestsPageFilters,
  ServiceRequestStatus,
} from 'interfaces/Marketplace';
import { GuideIcon } from 'components/elements/guideIcon';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 8px',
  backgroundColor: theme.palette.background.paper,
}));

const FilterButton = styled('button')({
  marginRight: 40,
  padding: 8,
  border: 'none',
  fontFamily: 'inherit',
  fontWeight: '400',
  fontSize: 14,
  color: '#4b4b4b',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',

  '&:disabled': {
    borderRadius: 4,
    backgroundColor: '#f5f5f5',
  },
});

const SearchContainer = styled('div')({
  marginLeft: 'auto',
  width: 300,
  display: 'flex',
});

type Filters = Pick<IRequestsPageFilters, 'statuses' | 'text'>;

type Props = {
  filters?: Filters;
  sx?: SxProps<Theme>;
  onChange?: (filters: Filters) => void;
};

export const Header = ({ filters = {}, sx = [], onChange }: Props) => {
  const text = filters.text ?? '';
  const statuses = filters.statuses ?? [];

  const isStatusChecked = (values: ServiceRequestStatus[]) => {
    return values.every(
      (value) => !!statuses.find((status) => status === value),
    );
  };

  const handleStatusChange = (values: ServiceRequestStatus[] | null) => () => {
    onChange?.({ statuses: values?.length ? values : [], text });
  };

  const handleTextChange = (value: string) => {
    onChange?.({ statuses, text: value });
  };

  return (
    <Container sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <FilterButton
        disabled={!statuses.length}
        onClick={handleStatusChange(null)}
      >
        Все
      </FilterButton>
      <FilterButton
        disabled={isStatusChecked([ServiceRequestStatus.DRAFT])}
        onClick={handleStatusChange([ServiceRequestStatus.DRAFT])}
      >
        Черновики
      </FilterButton>
      <FilterButton
        disabled={isStatusChecked([ServiceRequestStatus.PUBLISHED])}
        onClick={handleStatusChange([ServiceRequestStatus.PUBLISHED])}
      >
        Опубликованные
      </FilterButton>
      <FilterButton
        disabled={isStatusChecked([
          ServiceRequestStatus.CANCELLED,
          ServiceRequestStatus.COMPLETED,
          ServiceRequestStatus.DECLINED,
        ])}
        onClick={handleStatusChange([
          ServiceRequestStatus.CANCELLED,
          ServiceRequestStatus.COMPLETED,
          ServiceRequestStatus.DECLINED,
        ])}
      >
        Закрытые
      </FilterButton>
      <SearchContainer>
        <SearchTextFieldInput
          defaultValue={text ?? ''}
          onChangeSearch={handleTextChange}
        />
        <GuideIcon withContainer />
      </SearchContainer>
    </Container>
  );
};

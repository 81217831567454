import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { HEADER_HEIGHT } from 'services/theme';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '19px 0px',
  background: '#ffffff',
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
});

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 28,
  lineHeight: '36px',
});

type Props = {
  title: string;
  children: React.ReactNode;
};

export const Layout = ({ title, children }: Props) => {
  return (
    <Container>
      <Grid container item alignContent={'center'}>
        <Grid item container justifyContent={'center'} xs={12}>
          <Title>{title}</Title>
        </Grid>
        <Grid item container justifyContent={'center'} xs={12}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

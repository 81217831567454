import React, { useRef, useState } from 'react';
import qs from 'qs';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import { TabLayout } from 'components/elements/tabs';
import { ServicesList } from './ServicesList';
import { useQuery } from 'hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { findKey } from 'lodash';
import { Filters } from 'components/features/marketplace/containers/marketplace/Filters';
import { ICompilationPageFilters, IListFilters } from 'interfaces/Marketplace';
import {
  SearchRef,
  SearchTextFieldInput,
} from 'components/shared/inputs/SearchInput';
import { styled } from '@mui/material';
import { ServiceRequestsList } from 'components/features/marketplace/containers/marketplace/ServiceRequestsList';
import { useTranslation } from 'react-i18next';
import { stringToBoolean } from 'utils/boolean';
import { SoftwareList } from 'components/features/marketplace/containers/marketplace/SoftwareList';
import { SoftwareFilters } from 'components/features/marketplace/containers/marketplace/SoftwareFilters';
import { GuideIcon } from 'components/elements/guideIcon';

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const SearchContainer = styled('div')({
  position: 'absolute',
  right: 20,
  top: 15,
  width: 300,
  transform: 'translateY(50%)',
  display: 'flex',
});

enum TabsValue {
  Requests = 'requests',
  Services = 'services',
  Software = 'software',
}

const TabsMap = {
  [TabsValue.Requests]: 0,
  [TabsValue.Services]: 1,
  [TabsValue.Software]: 2,
} as const;

type Filters = { tab: TabsValue } & ICompilationPageFilters;

export const Marketplace = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const search = useQuery();

  const searchRef = useRef<SearchRef>(null);

  const filters = {
    tab: search.tab ?? TabsValue.Requests,
    fromDate: search.fromDate ? parseInt(search.fromDate as string) : null,
    recommended: stringToBoolean(search.recommended as string) ?? true,
    text: search.text,
    serviceTypes: search.serviceTypes ?? [],
    softwareTypes: search.softwareTypes ?? [],
    applicationAreas: search.applicationAreas ?? [],
  } as Filters;

  const [listFilters, setListFilters] = useState<IListFilters>({
    limit: 10,
    offset: 0,
  });

  const handleTabsChange = (_: unknown, value: number) => {
    searchRef.current?.clear();
    setListFilters({ limit: 10, offset: 0 });
    const name = findKey(TabsMap, (item) => item === value);
    navigate({ search: qs.stringify({ tab: name }, { skipNulls: true }) });
  };

  const handleFiltersChange = (value: ICompilationPageFilters) => {
    setListFilters({ limit: 10, offset: 0 });
    navigate({
      search: qs.stringify({ ...filters, ...value }, { skipNulls: true }),
    });
  };

  const handleSearchChange = (value: string) => {
    setListFilters({ limit: 10, offset: 0 });
    navigate({
      search: qs.stringify(
        { ...filters, text: value || null },
        { skipNulls: true },
      ),
    });
  };

  const handleListFiltersChange = (value: ICompilationPageFilters) => {
    setListFilters({ limit: value.limit, offset: value.offset });
  };

  const handleFirstLoaded = (length: number) => {
    if (!length && filters.recommended) {
      navigate({
        search: qs.stringify(
          { ...filters, recommended: false },
          { skipNulls: true },
        ),
      });
    }
  };

  return (
    <MarketplaceLayout
      contentSx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container>
        <TabLayout
          sx={{ pr: '340px' }}
          tab={TabsMap[filters.tab]}
          tabs={[
            {
              label: t('marketplace.navigation.requestCompilation'),
              component: (
                <>
                  <Filters
                    sx={{ mb: 3, borderBottom: '1px solid #ddd' }}
                    filters={filters}
                    onChange={handleFiltersChange}
                  />
                  <ServiceRequestsList
                    filters={{ ...filters, ...listFilters }}
                    onFiltersChange={handleListFiltersChange}
                    onFirstLoaded={handleFirstLoaded}
                  />
                </>
              ),
            },
            {
              label: t('marketplace.navigation.serviceCompilation'),
              component: (
                <>
                  <Filters
                    sx={{ mb: 3, borderBottom: '1px solid #ddd' }}
                    filters={filters}
                    onChange={handleFiltersChange}
                  />
                  <ServicesList
                    filters={{ ...filters, ...listFilters }}
                    onFiltersChange={handleListFiltersChange}
                    onFirstLoaded={handleFirstLoaded}
                  />
                </>
              ),
            },
            {
              label: t('marketplace.navigation.softwareCompilation'),
              component: (
                <>
                  <SoftwareFilters
                    sx={{ mb: 3, borderBottom: '1px solid #ddd' }}
                    filters={filters}
                    onChange={handleFiltersChange}
                  />
                  <SoftwareList
                    filters={{ ...filters, ...listFilters }}
                    onFiltersChange={handleListFiltersChange}
                    onFirstLoaded={handleFirstLoaded}
                  />
                </>
              ),
            },
          ]}
          onChangeTabs={handleTabsChange}
        />
        <SearchContainer>
          <SearchTextFieldInput
            ref={searchRef}
            defaultValue={filters.text ?? ''}
            onChangeSearch={handleSearchChange}
          />
          <GuideIcon withContainer />
        </SearchContainer>
      </Container>
    </MarketplaceLayout>
  );
};

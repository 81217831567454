import React from 'react';
import { Reviews } from 'interfaces/Marketplace';
import Grid from '@mui/material/Grid';
import { BusinessCardHeader } from 'components/features/marketplace/components/BusinessCardHeader';
import { getLocaleValue } from 'utils/form';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Rating from '@mui/material/Rating';
import { Thumbs } from 'components/elements/uploaders/thumbs';

const Title = styled('h2')({
  fontWeight: '600',
  fontSize: 20,
  color: '#565a65',
});

type Props = {
  reviews: Reviews;
};
export const ReviewsView = ({ reviews }: Props) => {
  const { t } = useTranslation();
  const { offerReview, answerReview } = reviews;
  return (
    <Grid item container>
      {offerReview && (
        <Grid item container>
          <Grid item xs={12} mb={2}>
            <Title>{t('review.offerOwner')}</Title>
          </Grid>
          <Grid item xs={12} ml={3}>
            <BusinessCardHeader
              src={offerReview?.businessCard?.logo?.path}
              name={getLocaleValue(offerReview?.businessCard.name) || ''}
              user={`${offerReview?.creator.name} ${offerReview?.creator.lastName}, #${offerReview?.creator.reputation}`}
              created={offerReview.createdDateTime}
              id={offerReview?.businessCard.id}
            />
            <Rating value={offerReview.grade} readOnly />
            <div>{getLocaleValue(offerReview.content)}</div>
            {offerReview.attachments && (
              <Thumbs attachments={offerReview.attachments} />
            )}
          </Grid>
        </Grid>
      )}
      {answerReview && (
        <Grid item container>
          <Grid item xs={12} mb={2}>
            <Title>{t('review.answerOwner')}</Title>
          </Grid>
          <Grid item xs={12} ml={3}>
            <BusinessCardHeader
              src={answerReview?.businessCard?.logo?.path}
              name={getLocaleValue(answerReview?.businessCard.name) || ''}
              user={`${answerReview?.creator.name} ${answerReview?.creator.lastName}, #${answerReview?.creator.reputation}`}
              created={answerReview.createdDateTime}
              id={answerReview?.businessCard?.id}
            />
            <Rating value={answerReview.grade} readOnly />
            <div>{getLocaleValue(answerReview.content)}</div>
            {answerReview.attachments && (
              <Thumbs attachments={answerReview.attachments} />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

import { styled, SxProps, Theme } from '@mui/material';
import React, { ReactNode } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 4),
  borderRadius: 4,
  border: '1px solid #e4e4e4',
  backgroundColor: theme.palette.background.paper,
}));

const Title = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '600',
  fontSize: 18,
  color: '#3d3d3d',
});

const Match = styled('span')({
  fontWeight: '600',
  fontSize: 18,
  color: '#00618e',
});

type Props = {
  sx?: SxProps<Theme>;
  children?: ReactNode;
  match?: string;
  empty?: boolean;
};

export const Header = ({ sx = [], children, match, empty = false }: Props) => {
  const { t } = useTranslation();

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {empty ? (
        <Title
          dangerouslySetInnerHTML={{
            __html: t('search.empty', {
              text: ReactDOMServer.renderToStaticMarkup(<Match>{match}</Match>),
            }),
          }}
        />
      ) : (
        <Title
          dangerouslySetInnerHTML={{
            __html: t('search.match', {
              text: ReactDOMServer.renderToStaticMarkup(<Match>{match}</Match>),
            }),
          }}
        />
      )}
      {children}
    </Root>
  );
};

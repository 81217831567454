import React, { useEffect, useState } from 'react';
import { TextFieldInput } from 'components/shared/inputs';
import Autocomplete from '@mui/material/Autocomplete';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { RefCallBack } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { getLocaleValue } from 'utils/form';

const LinkValue = styled('div')({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '24px',
  color: '#004B7C',
  overflowWrap: 'break-word',
  cursor: 'pointer',
});

type Props = {
  onLoadOptions?: () => void;
  data: IDictionariesContent[];
  inputRef?: RefCallBack;
  label: string;
  errorMessage?: string;
  onChange?: (
    value: IDictionariesContent | IDictionariesContent[] | null,
  ) => void;
  disabled?: boolean;
  noHierarchy?: boolean;
  placeholder?: string;
  multiple?: boolean;
  value?: IDictionariesContent | IDictionariesContent[] | null;
  createLink?: string;
};

export const MultiSelectAutocomplete = ({
  value,
  data,
  inputRef,
  label,
  disabled = false,
  errorMessage,
  onChange,
  onLoadOptions,
  noHierarchy = false,
  placeholder,
  createLink,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [options, setOptions] = useState<IDictionariesContent[]>([]);
  useEffect(() => {
    const _options = data.flatMap((object) => {
      if (object.children) {
        const newOptions = object.children.map((el) => {
          return { ...el, parent: object.display.ru };
        });
        return [...newOptions];
      }
      return [{ ...object, parent: noHierarchy ? '' : object.display.ru }];
    });
    setOptions(_options);
  }, [data]);

  useEffect(() => {
    onLoadOptions && onLoadOptions();
  }, [onLoadOptions]);
  return (
    <Autocomplete
      {...props}
      ref={inputRef}
      onChange={(_, data) => onChange?.(data)}
      options={options}
      loadingText={'Загрузка'}
      groupBy={(option) => option?.parent || ''}
      getOptionLabel={(option) => getLocaleValue(option.display)}
      value={value}
      isOptionEqualToValue={(
        option: IDictionariesContent,
        value: IDictionariesContent,
      ) => option.code === value.code}
      disabled={disabled}
      noOptionsText={
        createLink ? (
          <LinkValue onClick={() => navigate(createLink)}>
            {t('create link')}
          </LinkValue>
        ) : (
          t('no data')
        )
      }
      renderInput={(params) => {
        return (
          <TextFieldInput
            {...params}
            size={'small'}
            inputRef={inputRef}
            label={label}
            placeholder={placeholder}
            errorMessage={errorMessage}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        );
      }}
    />
  );
};

import * as yup from 'yup';
import {
  ErrorMessages,
  MAX_LENGTH_DESCRIPTION,
} from 'defenitions/errorMessages';

const localeValidate = (maxLength: number) =>
  yup.object().shape(
    {
      ru: yup.string().when('en', {
        is: (en: string) => !en,
        then: yup
          .string()
          .max(maxLength, ErrorMessages.maxLength(maxLength))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
      en: yup.string().when('ru', {
        is: (ru: string) => !ru,
        then: yup
          .string()
          .max(maxLength, ErrorMessages.maxLength(maxLength))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
    },
    [
      ['ru', 'en'],
      ['en', 'ru'],
    ],
  );

export const contentValidationSchema = yup.object({
  text: localeValidate(MAX_LENGTH_DESCRIPTION),
});

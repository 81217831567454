import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { IQuestionsStats } from 'interfaces/Stats';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';
import { StatsTable } from 'components/elements/table';
import {
  createHeadCell,
  createData,
} from 'components/features/stats/item/business/tables/questions/table/utils';

export const QuestionsStats = ({
  questions,
}: {
  questions: IQuestionsStats;
}) => {
  const { t } = useTranslation();

  return (
    <StatsCollapseLayout title={t('tables.tableQuestions')}>
      <Grid item container>
        <StatsTable
          title={t('tables.tableQuestionsOutgoing')}
          data={createData(questions.outgoing)}
          columns={createHeadCell()}
        />
      </Grid>
      <Grid item container>
        <StatsTable
          title={t('tables.tableQuestionsIncoming')}
          data={createData(questions.incoming)}
          columns={createHeadCell()}
        />
      </Grid>
    </StatsCollapseLayout>
  );
};

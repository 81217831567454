import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from 'store';
import { getServiceTypeDictionaries } from 'store/features/dictionaries';
import { getDictionariesServiceType } from 'store/features/dictionaries/actions';
import { Checkbox } from 'components/shared/checkbox';
import { styled } from '@mui/material/styles';
import { IDictionaries } from 'interfaces/Dictionaries';
import { map, uniq, without } from 'lodash';

const Title = styled('div')({
  color: '#4B4B4B',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
  display: 'flex',
  alignItems: 'center',
});

type Props = {
  services: Array<IDictionaries['id']>;
  onChange?: (checked: Array<IDictionaries['id']>) => void;
};

export const ServiceCategories = ({ services, onChange }: Props) => {
  const dispatch = useAppDispatch();
  const serviceTypes = useAppSelector(getServiceTypeDictionaries);

  useEffect(() => {
    dispatch(getDictionariesServiceType());
  }, [dispatch]);

  const handleChangeCategory =
    (id: string) => (_: unknown, checked: boolean) => {
      const category = serviceTypes.find((s) => s.id === id);

      if (!category) return;

      const ids = category.children
        ? map(category.children, 'id')
        : [category.id];

      const value = checked
        ? uniq([...services, ...ids])
        : without(services, ...ids);

      onChange?.(value);
    };

  const handleChangeSubcategory =
    (id: string) => (_: unknown, checked: boolean) => {
      const value = checked
        ? [...services, id]
        : services.filter((s) => s !== id);

      onChange?.(value);
    };

  const isCheckedCategory = (id: string) => {
    const subcategory = serviceTypes.find((s) => s.id === id);

    if (!subcategory) return;

    const ids = subcategory.children
      ? map(subcategory.children, 'id')
      : [subcategory.id];

    return ids.every((id) => services.includes(id));
  };

  const isCheckedSubcategory = (id: string) => {
    return services.includes(id);
  };

  return (
    <div>
      {serviceTypes.map((item) => (
        <Grid container key={item.id} direction={'column'}>
          <Title>
            <Checkbox
              label={item.text.ru}
              checked={isCheckedCategory(item.id)}
              onChange={handleChangeCategory(item.id)}
            />
          </Title>
          {item.children?.map((subEl) => (
            <Grid item xs={12} key={subEl.id} sx={{ pl: 3 }}>
              <Checkbox
                label={subEl.text.ru}
                checked={isCheckedSubcategory(subEl.id)}
                onChange={handleChangeSubcategory(subEl.id)}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </div>
  );
};

import React, { ReactNode } from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { MENU_WIDTH } from 'services/theme';
import { useTranslation } from 'react-i18next';
import { Search } from 'components/shared/search';

const Container = styled('aside')(({ theme }) => ({
  padding: '24px 12px',
  borderRight: '1px solid #dddddd',
  width: MENU_WIDTH,
  background: theme.palette.background.paper,
}));

const Header = styled('h3')({
  margin: 0,
  padding: 0,
  fontWeight: '700',
  fontSize: 24,
  color: '#4b4b4b',
});

type Props = {
  sx?: SxProps<Theme>;
  bottomSideMenu?: ReactNode;
  defaultSearchValue?: string;
  autoFocusSearch?: boolean;
  onSearch?: (text: string) => void;
};

export const SideMenu = ({
  sx = [],
  bottomSideMenu,
  defaultSearchValue = '',
  autoFocusSearch,
  onSearch,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Header sx={{ mb: 2 }}>{t('search.title')}</Header>
      <Search
        defaultValue={defaultSearchValue}
        autoFocus={autoFocusSearch}
        withClearButton
        onTextChange={onSearch}
      />
      {bottomSideMenu}
    </Container>
  );
};

import React, { useEffect } from 'react';
import {
  Divider,
  ServiceDataTitle,
} from 'components/features/business/shared/item/components';
import Grid from '@mui/material/Grid';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormTrigger,
} from 'react-hook-form';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { IBusinessAttachment } from 'interfaces/Attachment';
import { IBusinessService } from 'interfaces/Business';

type Props = {
  trigger: UseFormTrigger<IBusinessService>;
  serviceTypes: IDictionariesContent[];
  onLoadServiceTypes: () => void;
  controlService: Control<IBusinessService>;
  errors: FieldErrors<IBusinessService>;
  attachments: IBusinessAttachment[];
  handleUploadAttachments: (forData: FormData) => void;
  onRemoveAttachments: (id: string) => void;
};

export const ServiceDataAddForm = ({
  serviceTypes,
  onLoadServiceTypes,
  trigger,
  controlService,
  errors,
  attachments,
  handleUploadAttachments,
  onRemoveAttachments,
}: Props) => {
  useEffect(() => {
    trigger();
  }, []);

  return (
    <form>
      <Grid item container xs={12} spacing={2} padding={'0px 48px'}>
        <Grid item xs={12}>
          <ServiceDataTitle>Общие данные</ServiceDataTitle>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="serviceType"
            control={controlService}
            render={({ field: { ref, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                data={serviceTypes}
                onLoadOptions={onLoadServiceTypes}
                inputRef={ref}
                label={'Тип услуги'}
                noHierarchy
                errorMessage={errors.serviceType?.toString()}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'name'}
            control={controlService}
            errors={errors}
            label={'Наименование услуги'}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'description'}
            control={controlService}
            errors={errors}
            label={'Краткое описание услуги'}
            multiline
            minRows={3}
            isEditor
          />
        </Grid>
        <Grid item container xs={12}>
          <FilesUploader
            maxFiles={10}
            onUploadAttachments={handleUploadAttachments}
            attachments={attachments}
            onClickRemoveAttachments={onRemoveAttachments}
          />
        </Grid>
      </Grid>
      <Divider />
    </form>
  );
};

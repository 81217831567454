import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getCurrentAnswers,
  getCurrentQuestion,
  isCurrentQuestionLoading,
} from 'store/features/questions';
import {
  acceptAnswer,
  getAnswers,
  getQuestionById,
  reactToQuestionPost,
} from 'store/features/questions/actions';
import { Layout } from './layout/Layout';
import { DetailedQuestionCard } from './components/DetailedQuestionCard';
import { AnswerForm } from './components/AnswerForm';
import { AnswersList } from './components/AnswersList';
import { Answer, Question, QuestionStatus } from 'interfaces/Question';
import { Loader } from 'components/shared/loader';
import { getCurrent } from 'store/features/users';
import { ReactionType } from 'interfaces/Post';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
  backgroundColor: '#fff',
});

const Inner = styled('div')({
  margin: 'auto',
});

const Subtitle = styled('h3')({
  margin: 0,
  padding: '8px 16px',
  borderTop: 1,
  borderBottom: 1,
  borderLeft: 0,
  borderRight: 0,
  borderStyle: 'solid',
  borderColor: '#e4e4e4',
  fontWeight: '500',
  fontSize: 14,
  color: '#000',
});

export const Answers = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const question = useAppSelector(getCurrentQuestion);
  const currentUser = useAppSelector(getCurrent);
  const answers = useAppSelector(getCurrentAnswers);
  const isLoading = useAppSelector(isCurrentQuestionLoading);

  const mine = question?.creator.id === currentUser?.id;

  useEffect(() => {
    if (!id) return;
    Promise.all([dispatch(getQuestionById(id)), dispatch(getAnswers(id))]);
  }, [dispatch, id]);

  const handleQuestionLike = (id: Question['id']) => {
    dispatch(reactToQuestionPost({ id, type: ReactionType.LIKE }));
  };

  const handleQuestionDislike = (id: Question['id']) => {
    dispatch(reactToQuestionPost({ id, type: ReactionType.DISLIKE }));
  };

  const handleAnswerAccept = (id: Answer['id']) => {
    if (!question?.id) return;
    dispatch(acceptAnswer({ questionId: question.id, answerId: id }));
  };

  const handleAnswerLike = (id: Answer['id']) => {
    dispatch(reactToQuestionPost({ id, type: ReactionType.LIKE }));
  };

  const handleAnswerDislike = (id: Answer['id']) => {
    dispatch(reactToQuestionPost({ id, type: ReactionType.DISLIKE }));
  };

  return (
    <Layout>
      <Container>
        {isLoading && (
          <Inner>
            <Loader show />
          </Inner>
        )}
        {!isLoading && question && (
          <>
            <DetailedQuestionCard
              question={question}
              mine={mine}
              onLike={handleQuestionLike}
              onDislike={handleQuestionDislike}
            />

            <div>
              {question.status === QuestionStatus.ACTIVE && (
                <>
                  <Subtitle>Ваш ответ</Subtitle>
                  <AnswerForm
                    sx={{ backgroundColor: '#e4e4e4' }}
                    questionId={question.id}
                  />
                </>
              )}
              <Subtitle>{question.answersCount} ответа</Subtitle>
              <AnswersList
                question={question}
                answers={answers}
                ownerId={question?.creator?.id}
                currentUserId={currentUser?.id}
                onAccept={handleAnswerAccept}
                onLike={handleAnswerLike}
                onDislike={handleAnswerDislike}
              />
            </div>
          </>
        )}
      </Container>
    </Layout>
  );
};

import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { IViewsStats } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCell = (): HeadCell[] => {
  return [
    {
      id: 'name',
      label: i18n.t('tables.name'),
      numeric: false,
      withOpen: true,
    },
    {
      id: 'view',
      label: i18n.t('tables.views'),
      numeric: true,
    },
  ];
};

export const createData = (data: IViewsStats): DataItem[] => {
  return [
    {
      id: 'view',
      name: i18n.t('tables.viewCard'),
      view: data.views,
    },
    {
      id: 'viewList',
      name: i18n.t('tables.viewCardList'),
      view: data.viewsInList,
    },
    {
      id: 'viewsServices',
      name: i18n.t('tables.viewsServices'),
      view: data.viewsServices,
      child: data.services.map((el) => {
        return {
          id: el.objectId,
          name: getLocaleValue(el.name),
          view: el.views,
        };
      }),
    },
    {
      id: 'viewsQuestions',
      name: i18n.t('tables.viewsQuestions'),
      view: data.viewsQuestions,
      child: data.questions.map((el) => {
        return {
          id: el.objectId,
          name: getLocaleValue(el.name),
          view: el.views,
        };
      }),
    },
    {
      id: 'viewsOffers',
      name: i18n.t('tables.viewsOffers'),
      view: data.viewsOffers,
      child: data.offers.map((el) => {
        return {
          id: el.objectId,
          name: getLocaleValue(el.name),
          view: el.views,
        };
      }),
    },
  ];
};

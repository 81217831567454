import React, { useState } from 'react';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import {
  ComplaintAboutTypeName,
  ComplaintStatus,
  ComplaintStatusName,
  IComplaint,
} from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { getNameInitials } from 'utils/user';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { ReactComponent as LabelBookIcon } from 'assets/logos/LabelCodingAvatar.svg';

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  padding: '8px 12px',
  border: '1px solid #e4e4e4',
  backgroundColor: theme.palette.background.paper,
}));

const CreatorAvatar = styled(Avatar)({
  height: 55,
  width: 55,
});

const Container = styled('div')({
  flex: 1,
});

const Name = styled('p')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
  margin: 0,
  padding: 0,
  paddingTop: 4,
  paddingRight: 40,
  fontWeight: '500',
  fontSize: 14,
  color: '#000000',
});

const Status = styled('span')({
  fontWeight: '700',
  fontSize: 18,
  color: '#8e8e8e',
});

const Type = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const WithIconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const About = styled('p')({
  margin: 0,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

const AboutTitle = styled('span')({
  fontWeight: '400',
  fontSize: 12,
  color: '#666666',
});

const AboutObject = styled('span')({
  fontWeight: '500',
  fontSize: 14,
  color: '#666666',
});

const Closed = styled('p')({
  margin: 0,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

const ClosedTitle = styled('span')({
  fontWeight: '400',
  fontSize: 12,
  color: '#666666',
});

const ClosedDate = styled('span')({
  fontWeight: '400',
  fontSize: 12,
  color: '#666666',
});

const Reason = styled('div')({
  fontWeight: '500',
  fontSize: 14,
  color: '#666666',
});

const CardMenu = styled(Menu)({
  '& .MuiMenu-list': {
    padding: 0,
  },
});

const CardMenuItem = styled(MenuItem)({
  minWidth: 200,
  display: 'flex',
  justifyContent: 'center',
  padding: '8px 16px',
  fontFamily: 'inherit',
  fontWeight: '600',
  fontSize: 14,
  color: '#004b7c',
});

const StatusColors: Record<ComplaintStatus, string> = {
  [ComplaintStatus.ACTIVE]: '#165209',
  [ComplaintStatus.RESOLVED]: '#8e8e8e',
  [ComplaintStatus.DECLINED]: '#e84a4a',
};

type MenuOptions = Array<{ label: string; onClick?: () => void }>;

type Props = {
  sx?: SxProps<Theme>;
  complaint: IComplaint;
  menu?: MenuOptions;
  onFollowLink?: (url: string) => void;
};

export const ComplaintsCard = ({
  sx = [],
  complaint,
  menu = [],
  onFollowLink,
}: Props) => {
  const { t } = useTranslation();

  const {
    closedDateTime,
    complaintAboutType,
    content,
    createdDateTime,
    creator,
    pathToViewObject,
  } = complaint;

  const avatar = creator?.logo?.path;
  const name = creator
    ? `${creator?.name || ''} ${creator?.lastName || ''}`.trim()
    : 'LabelBook';
  const type = creator
    ? t('moderation.complaints.types.user')
    : t('moderation.complaints.types.system');
  const aboutType = t(
    `moderation.complaints.aboutType.${ComplaintAboutTypeName[complaintAboutType]}`,
  );
  const reason = content.ru || content.en || '';
  const datetime =
    differenceInDays(new Date(), createdDateTime) === 0
      ? formatDistanceToNowStrict(createdDateTime, { locale: ru })
      : format(createdDateTime, 'dd MMMM yyyy');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMoreOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => setAnchorEl(null);

  const handleFollowLink = () => {
    pathToViewObject && onFollowLink?.(pathToViewObject);
  };

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {creator ? (
        <CreatorAvatar src={avatar}>{getNameInitials(name)}</CreatorAvatar>
      ) : (
        <LabelBookIcon width={55} height={55} />
      )}
      <Container sx={{ ml: 3 }}>
        <Name>
          {name}
          <Status sx={{ color: StatusColors[complaint.status] }}>
            {t(
              `moderation.complaint.status.${
                ComplaintStatusName[complaint.status]
              }`,
            )}
          </Status>
        </Name>
        <Type>{type}</Type>
        <WithIconContainer>
          <TimeIcon />
          {datetime}
        </WithIconContainer>
        <About sx={{ mt: 2 }}>
          <AboutTitle>{t('moderation.complaints.about')}:</AboutTitle>
          <AboutObject
            sx={
              pathToViewObject
                ? {
                    color: '#004b7c',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }
                : {}
            }
            onClick={handleFollowLink}
          >
            {aboutType}
          </AboutObject>
        </About>
        {!!closedDateTime && (
          <Closed>
            <ClosedTitle>{t('moderation.complaints.closed')}:</ClosedTitle>
            <ClosedDate>
              {format(new Date(closedDateTime), 'dd.LL.yyyy HH:mm')}
            </ClosedDate>
          </Closed>
        )}
        <Reason sx={{ mt: 0.5 }}>{reason}</Reason>
      </Container>
      {!!menu.length && (
        <>
          <IconButton
            sx={{ position: 'absolute', top: 4, right: 4 }}
            onClick={handleMoreOpen}
          >
            <MoreIcon sx={{ color: '#707070' }} />
          </IconButton>
          <CardMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleMoreClose}
          >
            {menu.map(({ label, onClick }, index) => (
              <CardMenuItem key={index} divider onClick={onClick}>
                {label}
              </CardMenuItem>
            ))}
          </CardMenu>
        </>
      )}
    </Root>
  );
};

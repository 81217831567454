import React, { useEffect, useRef, useState } from 'react';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@mui/system';
import {
  getServiceRequestsList,
  hasMoreMarketplaceListItems,
  isMarketplaceListLoading,
} from 'store/features/marketplace';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getPublishedServiceRequests,
  likeServiceRequest,
} from 'store/features/marketplace/actions';
import {
  ICompilationPageFilters,
  IServiceRequest,
  ServiceRequestFromPages,
} from 'interfaces/Marketplace';
import { Loader } from 'components/shared/loader';
import { ServiceRequestCard } from 'components/features/marketplace/components/cards/ServiceRequestCard';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useModalActions } from 'utils/hooks';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { AxiosResponse } from 'axios';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { ReactionType } from 'interfaces/Post';
import { ListAdOne, ListAdTwo } from '../../../../elements/ads';

const EmptyText = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
});

type Props = {
  filters?: ICompilationPageFilters;
  onFiltersChange?: (filters: ICompilationPageFilters) => void;
  onFirstLoaded?: (length: number) => void;
};

export const ServiceRequestsList = ({
  filters = {},
  onFiltersChange,
  onFirstLoaded,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const limit = filters.limit ?? 10;
  const offset = filters.offset ?? 0;

  const isLoading = useAppSelector(isMarketplaceListLoading);
  const hasMore = useAppSelector(hasMoreMarketplaceListItems);
  const requests = useAppSelector(getServiceRequestsList);

  const isFetching = offset === 0 && isLoading;
  const isRefetching = offset !== 0 && isLoading;

  const prevFilters = useRef<ICompilationPageFilters>({});

  const { isOpen, onClickOpen, onClickClose } = useModalActions();

  const [selectedRequest, setSelectedRequest] =
    useState<IServiceRequest | null>(null);

  const handleClickCreateComplaint = (request: IServiceRequest) => {
    onClickOpen();
    setSelectedRequest(request);
  };

  const handleClickCloseComplaint = () => {
    onClickClose();
    setSelectedRequest(null);
  };

  const isFirstLoaded = useRef(true);

  useEffect(() => {
    const currentFilters = { ...filters, limit, offset };

    if (!isEqual(prevFilters.current, currentFilters)) {
      dispatch(getPublishedServiceRequests(currentFilters))
        .unwrap()
        .then((result) => {
          const request = result as AxiosResponse<IServiceRequest[]>;

          if (isFirstLoaded.current) {
            onFirstLoaded?.(request?.data?.length || 0);
            isFirstLoaded.current = false;
          }
        });
      prevFilters.current = currentFilters;
    }
  }, [dispatch, filters, limit, offset, onFirstLoaded]);

  const changeFilters = () => {
    onFiltersChange?.({ limit, offset: offset + limit });
  };

  const navigateToService = (id: IServiceRequest['id']) => () => {
    navigate({
      pathname: `/marketplace/request/${id}`,
      search: qs.stringify({
        from: ServiceRequestFromPages.RequestsCompilation,
      }),
    });
  };

  const navigateToCreateOfferAnswer = (id: IServiceRequest['id']) => () => {
    navigate(`/marketplace/offer/${id}/answer`);
  };

  const handleLike = (id: IServiceRequest['id']) => {
    dispatch(likeServiceRequest({ id, type: ReactionType.LIKE }));
  };

  const handleDislike = (id: IServiceRequest['id']) => {
    dispatch(likeServiceRequest({ id, type: ReactionType.DISLIKE }));
  };

  return (
    <>
      {isFetching && <Loader sx={{ m: 'auto' }} show />}
      {!isFetching && !requests.length && (
        <EmptyText sx={{ m: 'auto' }}>
          {t('marketplace.emptyRequests')}
        </EmptyText>
      )}
      {!isFetching && !!requests.length && (
        <InfiniteScroll
          dataLength={requests.length}
          loader={<Loader show={isRefetching} global />}
          next={changeFilters}
          hasMore={hasMore}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {requests.map((request, index) => (
            <>
              {index % 4 === 0 && index % 8 !== 0 && index !== 0 && (
                <ListAdOne />
              )}
              {index % 4 === 0 && index % 8 === 0 && index !== 0 && (
                <ListAdTwo />
              )}
              <ServiceRequestCard
                sx={[{ mb: 2 }, index === requests.length - 1 && { mb: 0 }]}
                key={request.id}
                request={request}
                onLike={handleLike}
                onDislike={handleDislike}
                menu={[
                  {
                    label: t('actions.view'),
                    onClick: navigateToService(request.id),
                  },
                  {
                    label: t('actions.respond'),
                    onClick: navigateToCreateOfferAnswer(request.id),
                  },
                  {
                    label: t('actions.complaint'),
                    onClick: () => {
                      handleClickCreateComplaint(request);
                    },
                  },
                ]}
              />
            </>
          ))}
        </InfiniteScroll>
      )}
      {selectedRequest && (
        <ConfirmDialog
          onClickClose={handleClickCloseComplaint}
          open={isOpen}
          title={t('modalTexts.complaint.title', {
            name: getLocaleValue(selectedRequest.title),
          })}
        >
          <CreateComplaint
            relationId={selectedRequest.id}
            complaintAboutType={ComplaintAboutType.OFFER}
            additionAction={handleClickCloseComplaint}
          />
        </ConfirmDialog>
      )}
    </>
  );
};

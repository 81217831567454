import React from 'react';
import Tab from '@mui/material/Tab';
import { SxProps, Theme } from '@mui/system';
import { CustomTabs } from 'components/elements/tabs/components';
import { TabsProps } from '@mui/material/Tabs';

type Tab = {
  label: string;
  component: JSX.Element;
};
type Props = {
  sx?: SxProps<Theme>;
  tab: number;
  onChangeTabs: (event: React.SyntheticEvent, newValue: number) => void;
  tabs: Tab[];
  disabled?: boolean;
};

export const TabLayout = ({
  sx = [],
  tab,
  onChangeTabs,
  tabs,
  disabled = false,
  ...props
}: Props & TabsProps) => {
  const TabContent = tabs[tab].component;

  return (
    <>
      <CustomTabs
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        value={tab}
        onChange={onChangeTabs}
        textColor="primary"
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        {...props}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            label={tab.label}
            disabled={index === 1 && disabled}
          />
        ))}
      </CustomTabs>
      {TabContent}
    </>
  );
};

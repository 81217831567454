import { IDictionaries, IDictionariesContent } from 'interfaces/Dictionaries';

export enum ActionTypes {
  GET_CARD_TYPE_DICTIONARIES = 'GET_CARD_TYPE_DICTIONARIES',
  GET_SERVICE_TYPE_DICTIONARIES = 'GET_SERVICE_TYPE_DICTIONARIES',
  GET_CARD_TYPE_DICTIONARIES_CONTENT = 'GET_CARD_TYPE_DICTIONARIES_CONTENT',
  GET_SERVICE_TYPE_DICTIONARIES_CONTENT = 'GET_SERVICE_TYPE_DICTIONARIES_CONTENT',
  GET_QUESTION_CATEGORIES_DICTIONARIES = 'GET_QUESTION_CATEGORIES_DICTIONARIES',
  GET_QUESTION_SUBCATEGORIES_DICTIONARIES = 'GET_QUESTION_SUBCATEGORIES_DICTIONARIES',
  GET_SOFTWARE_TYPES_DICTIONARIES = 'GET_SOFTWARE_TYPES_DICTIONARIES',
  GET_APPLICATION_AREAS_DICTIONARIES = 'GET_APPLICATION_AREAS_DICTIONARIES',
  GET_PROGRAMMING_LANGUAGES_DICTIONARIES = 'GET_PROGRAMMING_LANGUAGES_DICTIONARIES',
  GET_TECHNOLOGY_STACK_DICTIONARIES = 'GET_TECHNOLOGY_STACK_DICTIONARIES',
}

export interface IDictionariesState {
  cardTypes: IDictionaries[];
  serviceTypes: IDictionaries[];
  cardTypesContent: IDictionariesContent[];
  serviceTypesContent: IDictionariesContent[];
  questionCategories: IDictionaries[];
  questionSubcategories: IDictionaries[];
  softwareTypes: IDictionariesContent[];
  applicationAreas: IDictionariesContent[];
  programmingLanguages: IDictionariesContent[];
  technologyStack: IDictionariesContent[];
}

export const initialDictionariesState: IDictionariesState = {
  cardTypes: [],
  serviceTypes: [],
  cardTypesContent: [],
  serviceTypesContent: [],
  questionCategories: [],
  questionSubcategories: [],
  softwareTypes: [],
  applicationAreas: [],
  programmingLanguages: [],
  technologyStack: [],
};

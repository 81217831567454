import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { IServicesStats } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCellIncoming = (): HeadCell[] => {
  return [
    {
      id: 'name',
      label: i18n.t('tables.name'),
      numeric: false,
      withOpen: true,
    },
    {
      id: 'all',
      label: i18n.t('tables.allAnswers'),
      numeric: true,
    },
    {
      id: 'canceled',
      label: i18n.t('tables.canceledAnswers'),
      numeric: true,
    },
    {
      id: 'completed',
      label: i18n.t('tables.completedAnswers'),
      numeric: true,
    },
    {
      id: 'declined',
      label: i18n.t('tables.declinedAnswers'),
      numeric: true,
    },
    {
      id: 'published',
      label: i18n.t('tables.publishedAnswers'),
      numeric: true,
    },
  ];
};

export const createDataIncoming = (data: IServicesStats[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.serviceCode,
      name: getLocaleValue(el.serviceType),
      child: el.children?.map((child) => {
        return {
          ...child,
          id: child.serviceCode,
          name: getLocaleValue(child.serviceType),
        };
      }),
    };
  });

export const createHeadCellOutgoing = (): HeadCell[] => {
  return [
    {
      id: 'name',
      label: i18n.t('tables.name'),
      numeric: false,
      withOpen: true,
    },
    {
      id: 'all',
      label: i18n.t('tables.allAnswers'),
      numeric: true,
    },
    {
      id: 'draft',
      label: i18n.t('tables.draftAnswers'),
      numeric: true,
    },
    {
      id: 'canceled',
      label: i18n.t('tables.canceledAnswers'),
      numeric: true,
    },
    {
      id: 'completed',
      label: i18n.t('tables.completedAnswers'),
      numeric: true,
    },
    {
      id: 'declined',
      label: i18n.t('tables.declinedAnswers'),
      numeric: true,
    },
    {
      id: 'published',
      label: i18n.t('tables.publishedAnswers'),
      numeric: true,
    },
  ];
};

export const createDataOutgoing = (data: IServicesStats[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.serviceCode,
      name: getLocaleValue(el.serviceType),
      child: el.children?.map((child) => {
        return {
          ...child,
          id: child.serviceCode,
          name: getLocaleValue(child.serviceType),
        };
      }),
    };
  });

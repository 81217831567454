import { IMultiLanguage } from 'interfaces/Locale';
import { IAttachment } from './Attachment';
import { ICreator } from './Creator';
import { ListFilters, Filters } from './Filters';

export enum ComplaintAboutType {
  BUSINESS_CARD = 'BUSINESS_CARD',
  BUSINESS_SERVICE = 'BUSINESS_SERVICE',
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER',
  NEWS = 'NEWS',
  COMMENT = 'COMMENT',
  OFFER = 'OFFER',
  OFFER_ANSWER = 'OFFER_ANSWER',
  SOFTWARE_CARD = 'SOFTWARE_CARD',
}

export enum ComplaintStatus {
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
  DECLINED = 'DECLINED',
}

export const ComplaintAboutTypeName: Record<ComplaintAboutType, string> = {
  [ComplaintAboutType.BUSINESS_CARD]: 'businessCard',
  [ComplaintAboutType.BUSINESS_SERVICE]: 'businessService',
  [ComplaintAboutType.QUESTION]: 'question',
  [ComplaintAboutType.ANSWER]: 'answer',
  [ComplaintAboutType.NEWS]: 'news',
  [ComplaintAboutType.COMMENT]: 'comment',
  [ComplaintAboutType.OFFER]: 'offer',
  [ComplaintAboutType.OFFER_ANSWER]: 'offerAnswer',
  [ComplaintAboutType.SOFTWARE_CARD]: 'softwareCard',
};

export const ComplaintStatusName: Record<ComplaintStatus, string> = {
  [ComplaintStatus.ACTIVE]: 'active',
  [ComplaintStatus.RESOLVED]: 'resolved',
  [ComplaintStatus.DECLINED]: 'declined',
};

export interface IComplaintCreate {
  businessCardId?: string;
  relationId: string;
  content: IMultiLanguage;
  attachments: string[];
  complaintAboutType: ComplaintAboutType;
}

export interface IUser {
  id: string;
  lastName: string;
  name: string;
  logo: IAttachment;
}

export interface IAccount {
  id: string;
  name: string;
}

export interface IComplaint {
  id: string;
  attachments: IAttachment[];
  closedDateTime?: number;
  complaintAboutAccount?: IAccount;
  complaintAboutUser?: IUser;
  complaintAboutType: ComplaintAboutType;
  content: IMultiLanguage;
  createdDateTime: number;
  creator?: ICreator;
  pathToViewObject?: string;
  relationId: string;
  status: ComplaintStatus;
  title: IMultiLanguage;
}

export interface IUserRating {
  user: IUser;
  count: number;
  grade: number;
}

export interface IAccountRating {
  account: IAccount;
  count: number;
  grade: number;
  users?: IUserRating[];
}

export interface IInvalidContent {
  id: string;
  text: IMultiLanguage;
  disabled: boolean;
}

export type TAddInvalidContent = Pick<IInvalidContent, 'text'>;

export type TComplaintsFilters = ListFilters &
  Filters<{
    accountIds: string[];
    fromDate: number;
    processed: boolean;
    text: string;
  }>;

export type TRatingsFilters = ListFilters &
  Filters<{
    accountIds: string[];
    usersIds: string[];
    text: string;
    withUsers: boolean;
  }>;

export type TContentsFilters = Filters<{
  text: string;
}>;

import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import {
  StyledDialog,
  StyledTitle,
} from 'components/elements/modals/components';

type Props = {
  open: boolean;
  onClickClose: () => void;
  onClickAccept?: () => void;
  actionTitle?: string;
  title: string;
  text?: string;
  children?: React.ReactNode;
};
export const ConfirmDialog = ({
  open,
  onClickClose,
  onClickAccept,
  actionTitle,
  title,
  text,
  children,
}: Props) => {
  return (
    <StyledDialog open={open} onClose={onClickClose}>
      <StyledTitle onClose={onClickClose}>{title}</StyledTitle>
      <DialogContent dividers>
        {text && <DialogContentText>{text}</DialogContentText>}
        {children}
      </DialogContent>
      {onClickAccept && actionTitle && (
        <DialogActions>
          <ActionButton
            fullWidth
            onClick={onClickAccept}
            autoFocus
            text={actionTitle}
          />
        </DialogActions>
      )}
    </StyledDialog>
  );
};

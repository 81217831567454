import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';

export const restoreValidationSchema = yup
  .object({
    email: yup
      .string()
      .required(ErrorMessages.required)
      .email(ErrorMessages.emailNotCorrect),
  })
  .required();

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import { RootState } from 'store/index';
import { INewsState, initialNewsState } from 'store/features/news/config';
import {
  deleteNews,
  deleteNewsComment,
  getComments,
  getImportantNews,
  getNews,
  getSubscriptions,
  updateImportantNewsList,
  updateNewsList,
  createNew,
  changeReaction,
  createComment,
  editComment,
} from 'store/features/news/actions';
import { AxiosResponse } from 'axios';
import {
  NewsFilters,
  ISubscriptionFilters,
  PAGE_SIZE,
} from 'interfaces/NewsFilters';
import { IComment, INews } from 'interfaces/News';
import { ReactionType } from 'interfaces/Post';

const newsSlice = createSlice({
  name: Slices.news,
  initialState: initialNewsState,
  reducers: {
    changeFilters: (
      state: INewsState,
      { payload }: PayloadAction<NewsFilters>,
    ) => {
      return {
        ...state,
        list: {
          ...state.list,
          filters: payload,
        },
      };
    },
    changeSubscriptionFilters: (
      state: INewsState,
      { payload }: PayloadAction<ISubscriptionFilters>,
    ) => {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          filters: payload,
        },
      };
    },
    resetList: (state: INewsState) => {
      state.list.news = [];
      state.list.filters = { offset: 0, limit: PAGE_SIZE };
      state.list.loading = false;
      state.list.loaded = false;
      state.list.hasMore = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptions.pending, (state: INewsState) => {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          loading: true,
        },
      };
    });
    builder.addCase(getSubscriptions.rejected, (state: INewsState) => {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          loading: false,
          loaded: false,
        },
      };
    });
    builder.addCase(
      getSubscriptions.fulfilled,
      (state: INewsState, { payload }) => {
        const { data } = payload as AxiosResponse;

        return {
          ...state,
          subscriptions: {
            ...state.subscriptions,
            list: data,
          },
        };
      },
    );
    builder.addCase(
      deleteNewsComment.fulfilled,
      (state: INewsState, { meta }) => {
        const { newsId, commentId } = meta.arg;
        const comments = state.comments[newsId];

        if (comments?.list) {
          comments.list = comments.list.filter(
            (comment) => comment.id !== commentId,
          );
        }
      },
    );
    builder.addCase(createNew.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<INews>;
      const newData = [data, ...state.list.news];
      state.list.news = newData;
      state.list.loading = false;
      state.list.loaded = true;
      state.list.hasMore = newData.length !== 0;
    });

    builder.addCase(deleteNews.fulfilled, (state, { meta }) => {
      const id = meta.arg;
      state.list.news = state.list.news.filter((item) => item.id !== id);
    });

    builder
      .addCase(getNews.pending, (state) => {
        state.list.loading = true;
        state.list.loaded = false;
      })
      .addCase(getNews.rejected, (state) => {
        state.list.loading = false;
        state.list.loaded = false;
        state.list.hasMore = false;
      })
      .addCase(getNews.fulfilled, (state, { payload, meta }) => {
        const { offset } = meta.arg.filters ?? {};
        const { data } = payload as AxiosResponse<INews[]>;
        state.list.news = !offset ? data : [...state.list.news, ...data];
        state.list.loading = false;
        state.list.loaded = true;
        state.list.hasMore = !!data.length;
      });

    builder
      .addCase(getImportantNews.pending, (state) => {
        state.list.loading = true;
        state.list.loaded = false;
      })
      .addCase(getImportantNews.rejected, (state) => {
        state.list.loading = false;
        state.list.loaded = false;
        state.list.hasMore = false;
      })
      .addCase(getImportantNews.fulfilled, (state, { payload, meta }) => {
        const { offset } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<INews[]>;
        state.list.news = !offset ? data : [...state.list.news, ...data];
        state.list.loading = false;
        state.list.loaded = true;
        state.list.hasMore = !!data.length;
      });

    builder
      .addCase(updateNewsList.pending, (state) => {
        state.list.loading = true;
        state.list.loaded = false;
      })
      .addCase(updateNewsList.rejected, (state) => {
        state.list.loading = false;
        state.list.loaded = false;
      })
      .addCase(updateNewsList.fulfilled, (state, { payload }) => {
        const { data } = payload as AxiosResponse<INews[]>;
        state.list.loading = false;
        state.list.news = data;
        state.list.loaded = true;
      });

    builder
      .addCase(updateImportantNewsList.pending, (state) => {
        state.list.loading = true;
        state.list.loaded = false;
      })
      .addCase(updateImportantNewsList.rejected, (state) => {
        state.list.loading = false;
        state.list.loaded = false;
      })
      .addCase(updateImportantNewsList.fulfilled, (state, { payload }) => {
        const { data } = payload as AxiosResponse<INews[]>;
        state.list.loading = false;
        state.list.news = data;
        state.list.loaded = true;
      });

    builder.addCase(createComment.fulfilled, (state, { payload, meta }) => {
      const { data } = payload as AxiosResponse<IComment>;

      const key = meta.arg.relationId;

      state.comments[key] = {
        loading: false,
        list: [data, ...(state.comments[key]?.list ?? [])],
      };
    });

    builder.addCase(editComment.fulfilled, (state, { payload, meta }) => {
      const { id } = meta.arg;
      const { data } = payload as AxiosResponse<IComment>;

      const key = data.relationId;

      const comments = state.comments[key]?.list;

      if (!comments) return;

      const index = comments.findIndex((comment) => comment.id === id);

      if (index !== -1) {
        comments[index] = data;
      }
    });

    builder
      .addCase(getComments.pending, (state, { meta }) => {
        const key = meta.arg;
        state.comments[key] = { loading: true, list: [] };
      })
      .addCase(getComments.rejected, (state, { meta }) => {
        const key = meta.arg;
        state.comments[key] = { loading: false, list: [] };
      })
      .addCase(getComments.fulfilled, (state, { payload, meta }) => {
        const { data } = payload as AxiosResponse<IComment[]>;
        const key = meta.arg;
        state.comments[key] = { loading: false, list: data };
      });

    builder
      .addCase(changeReaction.pending, (state, { meta }) => {
        const { id, type } = meta.arg;

        const comments = Object.values(state.comments).reduce(
          (acc, { list }) => (list ? [...acc, ...list] : acc),
          [] as IComment[],
        );

        const posts = [...state.list.news, ...comments];

        const post = posts.find((p) => p.id === id);

        if (!post) return;

        post.reactions = post.reactions ?? {};

        post.reactions[ReactionType.LIKE] = post.reactions[
          ReactionType.LIKE
        ] ?? {
          count: 0,
          hasByUser: false,
        };

        post.reactions[ReactionType.DISLIKE] = post.reactions[
          ReactionType.DISLIKE
        ] ?? {
          count: 0,
          hasByUser: false,
        };

        if (type === ReactionType.LIKE) {
          const dislikes = post.reactions[ReactionType.DISLIKE]!;
          const likes = post.reactions[ReactionType.LIKE]!;

          likes.count += likes.hasByUser ? -1 : 1;
          likes.hasByUser = !likes.hasByUser;

          dislikes.count -= dislikes.hasByUser ? 1 : 0;
          dislikes.hasByUser = false;
        }

        if (type === ReactionType.DISLIKE) {
          const dislikes = post.reactions[ReactionType.DISLIKE]!;
          const likes = post.reactions[ReactionType.LIKE]!;

          dislikes.count += dislikes.hasByUser ? -1 : 1;
          dislikes.hasByUser = !dislikes.hasByUser;

          likes.count -= likes.hasByUser ? 1 : 0;
          likes.hasByUser = false;
        }
      })
      .addCase(changeReaction.rejected, (state, { meta }) => {
        const { id, type } = meta.arg;

        const comments = Object.values(state.comments).reduce(
          (acc, { list }) => (list ? [...acc, ...list] : acc),
          [] as IComment[],
        );

        const posts = [...state.list.news, ...comments];

        const post = posts.find((p) => p.id === id);

        if (!post) return;

        post.reactions = post.reactions ?? {};

        post.reactions[ReactionType.LIKE] = post.reactions[
          ReactionType.LIKE
        ] ?? {
          count: 0,
          hasByUser: false,
        };

        post.reactions[ReactionType.DISLIKE] = post.reactions[
          ReactionType.DISLIKE
        ] ?? {
          count: 0,
          hasByUser: false,
        };

        if (type === ReactionType.LIKE) {
          const dislikes = post.reactions[ReactionType.DISLIKE]!;
          const likes = post.reactions[ReactionType.LIKE]!;

          likes.count += likes.hasByUser ? -1 : 1;
          likes.hasByUser = !likes.hasByUser;

          dislikes.count -= dislikes.hasByUser ? 1 : 0;
          dislikes.hasByUser = false;
        }

        if (type === ReactionType.DISLIKE) {
          const dislikes = post.reactions[ReactionType.DISLIKE]!;
          const likes = post.reactions[ReactionType.LIKE]!;

          dislikes.count += dislikes.hasByUser ? -1 : 1;
          dislikes.hasByUser = !dislikes.hasByUser;

          likes.count -= likes.hasByUser ? 1 : 0;
          likes.hasByUser = false;
        }
      });
  },
});

export { newsSlice };

export const { changeFilters, resetList, changeSubscriptionFilters } =
  newsSlice.actions;

const selectSelf = (state: RootState) => state.news;

export const getNewsList = (state: RootState) => state.news.list.news;

export const getNewsLoading = (state: RootState) => state.news.list.loading;

export const getNewsLoaded = (state: RootState) => state.news.list.loaded;

export const getNewsFilters = (state: RootState) => state.news.list.filters;

export const getNewsHasMore = (state: RootState) => state.news.list.hasMore;

export const getSubscriptionsList = (state: RootState) =>
  state.news.subscriptions.list;

export const getSubscriptionsFilters = (state: RootState) =>
  state.news.subscriptions.filters;

export const getNewsComments = (key: string) =>
  createSelector(selectSelf, (state) => state.comments[key] ?? {});

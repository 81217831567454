import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';

const localeValidate = (maxLength: number) =>
  yup.object().shape(
    {
      ru: yup.string().when('en', {
        is: (en: string) => !en,
        then: yup
          .string()
          .max(maxLength, ErrorMessages.maxLength(maxLength))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
      en: yup.string().when('ru', {
        is: (ru: string) => !ru,
        then: yup
          .string()
          .max(maxLength, ErrorMessages.maxLength(maxLength))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
    },
    [
      ['ru', 'en'],
      ['en', 'ru'],
    ],
  );

const MAX_LENGTH_TITLE = 250;

export const serviceDataValidationSchema = yup.object({
  title: localeValidate(MAX_LENGTH_TITLE),
});

import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

export const Image = styled('img')({
  width: '100%',
  borderRadius: 5,
  objectFit: 'cover',
});

export const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '34px',
  color: '#969696',
  paddingRight: '16px',
  whiteSpace: 'nowrap',
});

export const Value = styled('div')({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '24px',
  color: '#000000',
  overflowWrap: 'break-word',
});

export const LinkValue = styled(Link)({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '24px',
  color: '#004B7C',
  textDecoration: 'none',
  overflowWrap: 'break-word',
});

export const Field = styled('div')({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '34px',
  color: '#3D3D3D',
  padding: '4px 0px',
});

export const StatusValue = styled('div')<{ isPublish: boolean }>(
  ({ isPublish = false }) => ({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
    color: isPublish ? '#0A8B41' : '#000000',
  }),
);

import React from 'react';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { IBusinessAttachment, iconType } from 'interfaces/Attachment';
import { attachUrl } from 'services/ApiClient';
import { ReactComponent as DocIcon } from 'assets/icons/docIcon.svg';
import { ReactComponent as ExcelIcon } from 'assets/icons/excelIcon.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdfIcon.svg';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useTranslation } from 'react-i18next';

const Close = styled(CloseIcon)({
  cursor: 'pointer',
  position: 'absolute',
  right: 0,
  top: 0,
});

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
});

const Thumb = styled('a')({
  display: 'flex',
  padding: 4,
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  textDecoration: 'none',
  textAlign: 'center',
  alignItems: 'center',
});

const ThumbInner = styled('div')({
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  height: 30,
  width: 30,
});

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const ImageText = styled('div')({
  maxWidth: 120,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 12,
  lineHeight: '24px',
  color: '#666666',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const Divider = styled('div')({
  height: 1,
  background: '#DDDDDD',
  width: '100%',
});

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 15,
  lineHeight: '34px',
  color: '#3D3D3D',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
});

type Props = {
  sx?: SxProps<Theme>;
  attachments: IBusinessAttachment[];
  onClickRemoveAttachments?: (id: string) => void;
};

export const Thumbs = ({
  sx = [],
  attachments,
  onClickRemoveAttachments,
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      {!!attachments.length && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <Title>
            {t('attachments')}
            {!!attachments.length && ` (${attachments.length})`}
            {!!attachments.length && (open ? <ExpandLess /> : <ExpandMore />)}
          </Title>
          <Divider />
        </div>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Container sx={[...(Array.isArray(sx) ? sx : [sx])]}>
          {attachments.map((file: IBusinessAttachment) => (
            <Thumb
              key={file.name}
              href={`${attachUrl}${file.path}`}
              title={file.name}
            >
              {onClickRemoveAttachments && (
                <Close
                  onClick={(e) => {
                    e.preventDefault();
                    onClickRemoveAttachments(file.id);
                  }}
                />
              )}
              <ThumbInner>
                {file.iconType === iconType.image && (
                  <img
                    src={`${attachUrl}${file.path}`}
                    style={img}
                    loading={'lazy'}
                  />
                )}
                {file.iconType === iconType.pdf && <PdfIcon />}
                {file.iconType === iconType.word && <DocIcon />}
                {file.iconType === iconType.excel && <ExcelIcon />}
              </ThumbInner>
              <ImageText>{file.name}</ImageText>
              <ImageText>{file.contentSize} Кб</ImageText>
            </Thumb>
          ))}
        </Container>
      </Collapse>
    </>
  );
};

import React, { useState } from 'react';
import {
  Container,
  Divider,
} from 'components/features/business/shared/item/components';
import Grid from '@mui/material/Grid';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import {
  Control,
  FormState,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormHandleSubmit,
  useWatch,
} from 'react-hook-form';
import { ICodeExamples, ISoftware } from 'interfaces/Software';
import { CodeCard } from 'components/features/business/shared/item/view/codeExample/components/CodeCard';
import { Modal } from 'components/shared/modal';
import { EditCodeExamples } from 'components/features/business/shared/item/edit/codeExamples/components/EditCodeExample';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';

const validationSchema = yup
  .object({
    code: yup.string().required(ErrorMessages.required),
  })
  .required();

type Props = {
  onClickBack: () => void;
  onClickSave: SubmitHandler<ISoftware>;
  handleSubmit: UseFormHandleSubmit<ISoftware>;
  formState: FormState<ISoftware>;
  control: Control<ISoftware>;
  programmingLanguages: IDictionariesContent[];
  onLoadProgrammingLanguages: () => void;
};

export const SoftwareCodeExamples = ({
  onClickBack,
  onClickSave,
  handleSubmit,
  formState,
  control,
  programmingLanguages,
  onLoadProgrammingLanguages,
}: Props) => {
  const { t } = useTranslation();
  const { isValid } = formState;
  const {
    isOpen: isOpenConfirmModal,
    onClickOpen: onClickOpenConfirmModal,
    onClickClose: onClickCloseConfirmModal,
  } = useModalActions();

  const {
    control: controlCreate,
    reset,
    formState: { errors },
    trigger,
  } = useForm<ICodeExamples>({
    mode: 'onChange',
    defaultValues: { code: '' },
    resolver: yupResolver(validationSchema),
  });

  const data = useWatch({ control: controlCreate });

  const { fields, remove, update, append } = useFieldArray({
    control,
    name: 'codeExamples',
  });
  const [editIndex, setEditIndex] = useState<number>(fields.length);

  const [open, setOpen] = useState(false);
  const handleCloseEdit = () => {
    setOpen(false);
    reset({});
  };
  const handleOpenAdd = () => {
    reset({});
    setOpen(true);
    setEditIndex(fields.length);
  };
  const handleOpenEdit = (index: number) => () => {
    setEditIndex(index);
    reset(fields[index]);
    setOpen(true);
  };
  const handleOpenRemoveModal = (index: number) => () => {
    setEditIndex(index);
    onClickOpenConfirmModal();
  };
  const handleRemove = () => {
    remove(editIndex);
    onClickCloseConfirmModal();
  };

  const isDisabled = !data?.code;

  const onClickHandleSubmit = (index: number) => () => {
    fields.length === index
      ? append(data as ICodeExamples)
      : update(index, data as ICodeExamples);
    setOpen(false);
    reset();
  };

  return (
    <Container container>
      <Grid item container xs={12} justifyContent={'flex-end'} marginBottom={2}>
        <CreateButton
          text={'Добавить код'}
          withPlusIcon
          onClick={handleOpenAdd}
          style={{ width: 222 }}
        />
      </Grid>
      {fields.map((el, index) => (
        <Grid item container xs={12} spacing={3} key={el.id}>
          <Grid item xs={12}>
            <CodeCard
              codeExample={el}
              onClickEdit={handleOpenEdit(index)}
              onClickRemove={handleOpenRemoveModal(index)}
            />
          </Grid>
        </Grid>
      ))}
      <Divider />
      <Modal
        title={'Новый пример кода'}
        onClickAccept={onClickHandleSubmit(editIndex)}
        open={open}
        handleClose={handleCloseEdit}
        disabled={isDisabled}
      >
        <EditCodeExamples
          control={controlCreate}
          programmingLanguages={programmingLanguages}
          onLoadProgrammingLanguages={onLoadProgrammingLanguages}
          errors={errors}
          trigger={trigger}
        />
      </Modal>
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        <Grid item style={{ width: 136 }}>
          <CreateButton fullWidth text={'Назад'} onClick={onClickBack} />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            disabled={!isValid}
            text={'Сохранить'}
            onClick={handleSubmit(onClickSave)}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        onClickAccept={handleRemove}
        onClickClose={onClickCloseConfirmModal}
        open={isOpenConfirmModal}
        actionTitle={t('modalTexts.software.delete')}
        text={t('modalTexts.software.text')}
        title={t('modalTexts.software.remove title')}
      />
    </Container>
  );
};

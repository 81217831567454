import { Locale, TimeZoneBook } from 'interfaces/Locale';
import { INotificationSettingsItem } from 'interfaces/Notifications';

export enum ActionTypes {
  GET_TIMEZONES = 'GET_TIMEZONES',
  GET_SETTINGS = 'GET_SETTINGS',
  CHANGE_LOCALE = 'CHANGE_LOCALE',
  CHANGE_TIMEZONE = 'CHANGE_TIMEZONE',
  GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS',
  CHANGE_NOTIFICATION_SETTINGS = 'CHANGE_NOTIFICATION_SETTINGS',
}

export interface ISettingsState {
  timeZone?: string;
  locale?: Locale;
  timeZones: TimeZoneBook[];
  notifications: INotificationSettingsItem[];
}

export const initialSettingsState: ISettingsState = {
  locale: Locale.ru,
  timeZone: '',
  timeZones: [],
  notifications: [],
};

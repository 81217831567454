export enum Locale {
  en = 'en',
  ru = 'ru',
}
export interface IMultiLanguage {
  ru: string;
  en: string;
}

export interface LocaleBook {
  id: Locale;
  title: IMultiLanguage;
}
export interface TimeZoneBook {
  id: string;
  title: IMultiLanguage;
}

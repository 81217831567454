import React, { MouseEvent, useState } from 'react';
import qs from 'qs';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { styled, Avatar, Box, SxProps, Theme, Typography } from '@mui/material';
import { ISoftware } from 'interfaces/Software';
import { getNameInitials } from 'utils/user';
import { getLocaleValue } from 'utils/form';
import { attachUrl } from 'services/ApiClient';
import { Editor } from 'components/shared/editor';
import { enumeration } from 'utils/strings';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { useModalActions } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { URLS } from 'defenitions/routes';

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2, 1.5, 2, 4),
  backgroundColor: theme.palette.background.paper,
}));

const AvatarBox = styled(Box)(({ theme }) => ({
  flex: '0 0 60px',
  marginRight: theme.spacing(1.5),
}));

const SoftwareAvatar = styled(Avatar)({
  width: 60,
  height: 60,
});

const HeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
});

const DetailsBox = styled(Box)({
  flex: 1,
  minHeight: 60,
});

const Header = styled(Typography)({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '22px',
  color: '#00618e',
}) as typeof Typography;

const Subheader = styled(Typography)({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '16px',
  color: '#a9a9a9',
});

const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,
});

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  software: ISoftware;
};

export const SoftwareDetailedCard = ({
  sx = [],
  className,
  software,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const complaintDialog = useModalActions();

  const {
    attachments,
    id,
    applicationAreas,
    logo,
    programmingLanguages,
    softwareTypes,
    updateDateTime,
  } = software;

  const avatar = logo?.path && `${attachUrl}${logo.path}`;
  const name = getLocaleValue(software.name);

  const from =
    differenceInDays(new Date(), new Date(updateDateTime)) === 0
      ? formatDistanceToNowStrict(new Date(updateDateTime), { locale: ru })
      : format(new Date(updateDateTime), 'dd MMMM yyyy', { locale: ru });

  const types = enumeration(
    (softwareTypes ?? []).map((type) => getLocaleValue(type.display)),
    'Тип ПО',
  );

  const areas = enumeration(
    (applicationAreas ?? []).map((area) => getLocaleValue(area.display)),
    'Область применения',
  );

  const languages = enumeration(
    (programmingLanguages ?? []).map((language) =>
      getLocaleValue(language.display),
    ),
    'Языки программирования',
  );

  const description = getLocaleValue(software.description);

  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleComplaint = () => {
    complaintDialog.onClickOpen();
    setAnchorEl(null);
  };

  const handleCreateRequest = () => {
    const query = qs.stringify({ software: id }, { addQueryPrefix: true });
    navigate(`${URLS.marketplaceCreateRequest}${query}`);
  };

  const complaintDialogTitle = t('modalTexts.complaint.title', { name });

  return (
    <>
      <Root className={className} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
        <MoreIcon onClick={handleMenuOpen} />
        <HeaderBox sx={{ mb: 3 }}>
          <AvatarBox>
            <SoftwareAvatar src={avatar}>
              {getNameInitials(name)}
            </SoftwareAvatar>
          </AvatarBox>
          <DetailsBox>
            <Header
              sx={{ mb: 0.5 }}
              component={Link}
              to={generatePath(URLS.viewSoftwareCard, { id })}
            >
              {name}
            </Header>
            <Subheader sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
              <TimeIcon />
              <Subheader sx={{ ml: 0.5 }}>{from}</Subheader>
            </Subheader>
            {types && <Subheader sx={{ mb: 0.5 }}>{types}</Subheader>}
            {areas && <Subheader sx={{ mb: 0.5 }}>{areas}</Subheader>}
            {languages && <Subheader>{languages}</Subheader>}
          </DetailsBox>
        </HeaderBox>
        <Editor value={description} readOnly />
        {!!attachments?.length && (
          <Box sx={{ mt: 3 }}>
            <Thumbs attachments={attachments} />
          </Box>
        )}
        <Actions sx={{ mt: 5 }}>
          <ActionButton
            text={t('actions.request')}
            onClick={handleCreateRequest}
          />
        </Actions>
      </Root>
      <MoreMenu
        anchorEl={anchorEl}
        onClickAway={handleMenuClose}
        onClickComplaint={handleComplaint}
      />
      <ConfirmDialog
        open={complaintDialog.isOpen}
        title={complaintDialogTitle}
        onClickClose={complaintDialog.onClickClose}
      >
        <CreateComplaint
          relationId={id}
          complaintAboutType={ComplaintAboutType.SOFTWARE_CARD}
          additionAction={complaintDialog.onClickClose}
        />
      </ConfirmDialog>
    </>
  );
};

import { TimeZoneBook } from 'interfaces/Locale';
import { IDictionariesContent } from 'interfaces/Dictionaries';

export const normalizeTimeZonesBook = (
  timeZones: TimeZoneBook[],
): IDictionariesContent[] => {
  return timeZones.map((el) => {
    return {
      code: el.id,
      display: el.title,
      selectable: false,
    };
  });
};

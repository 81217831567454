import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import { ReactComponent as InfoIcon } from 'assets/icons/informationCircle.svg';
import Paper from '@mui/material/Paper';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl/FormControl';
import FormLabel from '@mui/material/FormLabel';

type Props = {
  errorMessage?: string;
};

const ErrorMessageContainer = styled(Paper)({
  boxShadow: ' 0px 0px 20px rgba(6, 31, 65, 0.1)',
  borderRadius: 8,
  padding: 16,
  fontSize: 14,
  lineHeight: '20px',
  color: '#9499A6',
});

const StyledLabel = styled(FormLabel)({
  paddingBottom: 6,
  color: '#59606A',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '18px',
});

const CustomTextFieldInput = styled(
  ({ errorMessage, ...props }: TextFieldProps & Props) => {
    const [anchorEl, setAnchorEl] = React.useState<SVGElement | null>(null);
    const [showPassword, setShowPassword] = React.useState<boolean>(
      props.type !== 'password',
    );

    const handleClickShowPassword = () => setShowPassword(true);
    const handleMouseDownPassword = () => setShowPassword(false);
    const MessageText = () => {
      return (
        <Popper
          open={!!anchorEl}
          anchorEl={anchorEl}
          placement={'top-start'}
          style={{ zIndex: 1 }}
        >
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        </Popper>
      );
    };

    const endAdornment = (): React.ReactNode => {
      return (
        <InputAdornment position="end">
          {props.error && (
            <InfoIcon
              onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
              onMouseLeave={() => setAnchorEl(null)}
              fill={props.error ? '#E04311' : '#9499A6'}
              style={{ cursor: 'pointer' }}
            />
          )}
          <MessageText />
          {props.type === 'password' && (
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          )}
        </InputAdornment>
      );
    };
    const startAdornment = (): React.ReactNode => {
      return (
        <>
          {props.InputProps?.startAdornment && (
            <InputAdornment position="start">
              {props.InputProps.startAdornment}
            </InputAdornment>
          )}
        </>
      );
    };

    return (
      <FormControl fullWidth>
        <StyledLabel id="input-label">{props.label}</StyledLabel>
        <TextField
          {...props}
          fullWidth
          hiddenLabel
          aria-labelledby="input-label"
          InputProps={{
            ...props.InputProps,
            disableUnderline: true,
            startAdornment:
              props.InputProps?.startAdornment && startAdornment(),
            endAdornment: endAdornment(),
          }}
          label={''}
          placeholder={props.placeholder}
          type={showPassword ? 'text' : 'password'}
        />
      </FormControl>
    );
  },
)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#F8F8F8',
    border: '1px solid #C0C8D1',
    borderRadius: 14,
    overflow: 'hidden',
    width: '100%',
    transition: theme.transitions.create([
      'border-width',
      'border-color',
      'background-color',
    ]),
    '&:hover': {
      backgroundColor: '#eaeaea',
    },
    '&.Mui-focused': {
      backgroundColor: '#F8F8F8',
      border: '1px solid #CBCBCB',
    },
  },
  '& .Mui-error': {
    border: '1px solid #E04311',
  },
  '& .MuiInputLabel-root': {
    color: '#969696',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    '&.Mui-focused': {
      color: '#969696',
    },
  },
  '& .MuiInputBase-input': {
    height: 36,
    padding: '10px 12px',
    borderRadius: 14,
  },
}));

export const TextFieldInput = ({
  label,
  errorMessage,
  ...props
}: Props & TextFieldProps) => {
  return (
    <CustomTextFieldInput
      label={label}
      error={!!errorMessage}
      errorMessage={errorMessage}
      variant="filled"
      {...props}
    />
  );
};

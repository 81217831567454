import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SnackbarConfig, CloseSnackbar } from 'services/Snackbar';
import { ThemeProvider } from '@mui/material/styles';
import { store } from 'store';
import { URLS } from 'defenitions/routes';
import { SignIn } from 'components/features/auth/containers/signin';
import { PrivateRoute } from 'components/elements/privateRoute';
import { theme } from 'services/theme';
import { AuthLayout } from 'root/layouts/authLayout';
import { CssBaseline } from '@mui/material';
import { MainLayout } from 'root/layouts/mainLayoyt';
import { Business } from 'components/features/business/containers/list/Business';
import { Software } from 'components/features/business/containers/list/Software';
import { SignUp } from 'components/features/auth/containers/signup';
import { SnackbarProvider } from 'notistack';
import { SuccessSignUp } from 'components/features/auth/containers/signup/success';
import { UpdateBusinessCard } from 'components/features/business/containers/item/update/UpdateBusinessCard';
import { News } from 'components/features/news/containers/news';
import { ImportantNews } from 'components/features/news/containers/importantNews';
import { NewsSubscriptions } from 'components/features/news/containers/subscriptions';
import { CreateNew } from 'components/features/news/containers/createNew';
import { ViewBusinessCard } from 'components/features/business/containers/item/view/Business';
import { Restore } from 'components/features/auth/containers/restore';
import { ChangePassword } from 'components/features/auth/containers/changePassword';
import { MarketplaceCreateRequest } from 'components/features/marketplace/containers/createRequest';
import { MarketplaceGetService } from 'components/features/marketplace/containers/getService';
import { Marketplace } from 'components/features/marketplace/containers/marketplace';
import { MarketplaceProvideService } from 'components/features/marketplace/containers/provideService';
import { MarketplaceRequests } from 'components/features/marketplace/containers/requests';
import { MarketplaceCreateOfferAnswer } from 'components/features/marketplace/containers/createOfferAnswer';
import { MarketplaceOfferAnswer } from 'components/features/marketplace/containers/offerAnswer';
import {
  Answers,
  CreateQuestion,
  IncomingQuestions,
  MyQuestions,
  Questions,
  QuestionsSubscriptions,
} from 'components/features/questions';
import { TermsOfService } from 'components/features/auth/containers/termsOfService';
import { MarketplaceServiceRequest } from 'components/features/marketplace/containers/serviceRequest';
import { MarketplaceService } from 'components/features/marketplace/containers/service';
import { Account } from 'components/features/home/account';
import { Profile } from 'components/features/home/profile';
import { Users } from 'components/features/home/users';
import { EditUser } from 'components/features/home/users/editUser';
import { NotFound } from './components';
import { Stats } from 'components/features/stats';
import { MainSettings } from 'components/features/settings/mainSetttings';
import {
  ModerationContents,
  ModerationRatings,
  ModerationComplaints,
  ModerationComplaint,
} from 'components/features/moderation';
import { NotificationSettings } from 'components/features/settings/notificationSettings';
import { Notifications } from 'components/features/notification/containers/list';
import { ViewNotification } from 'components/features/notification/containers/view';
import { GlobalSearch } from 'components/features/search';
import { ViewUserStats } from 'components/features/stats/item/user';
import { ViewBusinessStats } from 'components/features/stats/item/business';
import { ViewSoftwareCard } from 'components/features/business/containers/item/view/Software';
import { UpdateSoftwareCard } from 'components/features/business/containers/item/update/UpdateSoftwareCard';
import { MarketplaceSoftware } from 'components/features/marketplace/containers/software';
import { ViewUser } from 'components/features/home/users/viewUser';
import { SignInLayout } from 'root/layouts/authLayout/singin';

export const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        action={(key) => (
          <div
            onClick={() => CloseSnackbar(key)}
            style={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
              cursor: 'pointer',
            }}
          />
        )}
      >
        <>
          <SnackbarConfig />
          <BrowserRouter>
            <Routes>
              <Route
                path={'/'}
                element={
                  <SignInLayout>
                    <SignIn />
                  </SignInLayout>
                }
              />
              <Route
                path={URLS.signin}
                element={
                  <SignInLayout>
                    <SignIn />
                  </SignInLayout>
                }
              />
              <Route
                path={URLS.signup}
                element={
                  <AuthLayout>
                    <SignUp />
                  </AuthLayout>
                }
              />
              <Route
                path={URLS.successSingup}
                element={
                  <AuthLayout>
                    <SuccessSignUp />
                  </AuthLayout>
                }
              />
              <Route
                path={URLS.restore}
                element={
                  <AuthLayout>
                    <Restore />
                  </AuthLayout>
                }
              />
              <Route
                path={URLS.changePassword}
                element={
                  <AuthLayout>
                    <ChangePassword />
                  </AuthLayout>
                }
              />
              <Route
                path={URLS.termsOfService}
                element={
                  <AuthLayout>
                    <TermsOfService />
                  </AuthLayout>
                }
              />
              <Route
                path={URLS.business}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Business />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.software}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Software />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.viewSoftwareCard}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ViewSoftwareCard />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.createBusinessCard}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <UpdateBusinessCard />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.createSoftware}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <UpdateSoftwareCard />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.editBusinessCard}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <UpdateBusinessCard />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.editSoftwareCard}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <UpdateSoftwareCard />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.viewBusinessCard}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ViewBusinessCard />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplaceOfferAnswer}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MarketplaceOfferAnswer />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplaceCreateOfferAnswer}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MarketplaceCreateOfferAnswer />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplace}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Marketplace />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplaceGetService}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MarketplaceGetService />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplaceProvideService}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MarketplaceProvideService />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplaceCreateRequest}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MarketplaceCreateRequest />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplaceRequests}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MarketplaceRequests />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplaceServiceRequest}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MarketplaceServiceRequest />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplaceService}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MarketplaceService />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.news}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <News />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.newsSubscriptions}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <NewsSubscriptions />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.createNew}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <CreateNew />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.newsImportant}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ImportantNews />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.questions}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Questions />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.incomingQuestions}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <IncomingQuestions />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.myQuestions}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MyQuestions />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.createQuestion}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <CreateQuestion />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.createQuestionForBusinessCard}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <CreateQuestion />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.questionSubscriptions}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <QuestionsSubscriptions />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.answers}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Answers />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.account}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Account />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.home}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Account />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.profile}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Profile />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.users}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Users />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.createUser}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <EditUser />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.editUser}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <EditUser />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.notifications}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Notifications />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.notificationView}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ViewNotification />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.stats}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <Stats />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.settings}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MainSettings />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.moderationComplaints}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ModerationComplaints />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.moderationRatings}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ModerationRatings />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.moderationContents}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ModerationContents />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.moderationComplaint}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ModerationComplaint />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.notificationSettings}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <NotificationSettings />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.globalSearch}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <GlobalSearch />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.userStats}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ViewUserStats />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.businessStats}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ViewBusinessStats />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.marketplaceSoftware}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <MarketplaceSoftware />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path={URLS.viewUser}
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <ViewUser />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>
);

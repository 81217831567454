import React from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { FromDateFilter } from './FromDateFilter';
import { TComplaintsFilters } from 'interfaces/Complaint';
import { AccountsFilter } from './AccountsFilter';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 16,
  padding: '8px 40px',
  backgroundColor: theme.palette.background.paper,
}));

type Filters = Pick<TComplaintsFilters, 'fromDate' | 'accountIds'>;

type Props = {
  sx?: SxProps<Theme>;
  filters?: Filters;
  onChange?: (value: Filters) => void;
};

export const ComplaintsFilters = ({
  sx = [],
  filters = {},
  onChange,
}: Props) => {
  const handleFromDateChange = (value: number | null) =>
    onChange?.({ ...filters, fromDate: value });

  const handleAccountsChange = (value: string[]) =>
    onChange?.({ ...filters, accountIds: value });

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <AccountsFilter
        value={filters.accountIds}
        onChange={handleAccountsChange}
      />
      <FromDateFilter
        value={filters.fromDate}
        onChange={handleFromDateChange}
      />
    </Root>
  );
};

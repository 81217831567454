import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { ReactComponent as UploadIcon } from 'assets/icons/plusIcon.svg';
import { Loader } from 'components/shared/loader';
import { IBusinessAttachment } from 'interfaces/Attachment';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import Snackbar from 'services/Snackbar';
import { businessFileTypes } from 'defenitions/fileUploader';

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  background: '#F5F5F5',
  borderRadius: 5,
  minHeight: 130,
};

const focusedStyle = {
  border: '1px solid #00618E',
};

const acceptStyle = {
  border: '1px solid #00618E',
};

const rejectStyle = {
  border: '1px solid #E04311',
};

const FileUploadContainer = styled('div')({
  width: '100%',
});

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 16,
  lineHeight: '20px',
  color: '#4B4B4B',
  paddingBottom: 16,
});
const SubText = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 12,
  lineHeight: '15px',
  color: '#A9A9A9',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 4,
});

const UploadText = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 12,
  lineHeight: '15px',
  color: '#00618E',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

const UploadContainer = styled('div')<{ variant: 'large' | 'small' }>(
  ({ variant }) => ({
    background: '#F5F5F5',
    borderRadius: 5,
    minHeight: variant === 'large' ? 130 : 75,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  }),
);

const ThumbsContainer = styled('aside')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  background: '#F0F2F5',
});

type Props = {
  attachments: IBusinessAttachment[];
  onUploadAttachments: (forData: FormData) => void;
  onClickRemoveAttachments: (id: string) => void;
  hideTitle?: boolean;
  accept?: string;
  maxFiles: number;
  maxSize?: number;
  variant?: 'large' | 'small';
};

export interface IFile extends File {
  preview: string;
}

export const FilesUploader = ({
  onUploadAttachments,
  attachments,
  onClickRemoveAttachments,
  hideTitle,
  maxFiles,
  maxSize,
  accept = businessFileTypes,
  variant = 'large',
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      onDrop: async (acceptedFiles: File[], fileRejections) => {
        if (acceptedFiles.length) {
          setIsLoading(true);
          const data = new FormData();
          acceptedFiles.forEach((item: File) => {
            data.append(`files`, item);
          });
          await onUploadAttachments(data);
          setIsLoading(false);
        }
        if (fileRejections.length)
          Snackbar.show('Загруженные файлы не соответсуют формату', 'error');
      },
      maxSize,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <FileUploadContainer>
      {!hideTitle && <Title>Вложенные файлы</Title>}
      <UploadContainer variant={variant}>
        {maxFiles && attachments.length < maxFiles && (
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <UploadText>
                <UploadIcon
                  stroke={'#00618E'}
                  style={{ marginRight: 5, width: 12, height: 12 }}
                />
                Добавьте файл
              </UploadText>
              <SubText>или перетащите сюда</SubText>
            </div>
          </div>
        )}
        {isLoading && <Loader show />}
        <ThumbsContainer>
          <Thumbs
            attachments={attachments}
            onClickRemoveAttachments={onClickRemoveAttachments}
          />
        </ThumbsContainer>
      </UploadContainer>
    </FileUploadContainer>
  );
};

import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { IExchangeContactsStats } from 'interfaces/Stats';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';
import { StatsTable } from 'components/elements/table';
import {
  createHeadCell,
  createData,
} from 'components/features/stats/item/business/tables/exchangeContacts/table/utils';

export const ExchangeContactsStats = ({
  exchangeContacts,
}: {
  exchangeContacts: IExchangeContactsStats;
}) => {
  const { t } = useTranslation();

  return (
    <StatsCollapseLayout title={t('tables.tableExchangeContacts')}>
      <Grid item container>
        <StatsTable
          data={createData(exchangeContacts)}
          columns={createHeadCell()}
        />
      </Grid>
    </StatsCollapseLayout>
  );
};

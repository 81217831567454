import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuItemLink } from 'components/elements/navigationMenu/menuItem';
import {
  getAnswersItemsList,
  getServiceRequest,
} from 'store/features/marketplace';
import { useAppDispatch, useAppSelector } from 'store';
import { getAllAnswersForServiceRequest } from 'store/features/marketplace/actions';
import { IServiceRequest } from 'interfaces/Marketplace';
import { attachUrl } from 'services/ApiClient';
import { Grid } from '@mui/material';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { getFormattedDate } from 'utils/dates';
import { truncate } from 'lodash';
import { changeFilters } from 'store/features/business';
import { initialBusinessFilters } from 'store/features/business/config';
import { URLS } from 'defenitions/routes';
import {
  Title,
  Container,
  Divider,
  Link,
  SubTitle,
  Content,
  AnswerItem,
  Name,
  Header,
  InfoHeader,
  BusinessAvatar,
} from './components';
import { useTranslation } from 'react-i18next';
import { Editor } from 'components/shared/editor';

type Params = { id: string };

export const AnswersNavigationMenu = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const answersList = useAppSelector(getAnswersItemsList);
  const serviceRequest = useAppSelector(getServiceRequest);

  const { id = '' } = useParams<Params>();

  useEffect(() => {
    dispatch(getAllAnswersForServiceRequest(id));
  }, [id, dispatch]);

  const navigateToAnswer = (id: IServiceRequest['id']) => () => {
    navigate(`/marketplace/answer/${id}`);
  };

  const onClickBusinessCards = () => {
    dispatch(
      changeFilters({
        ...initialBusinessFilters,
        services: serviceRequest?.serviceTypes.map((el) => el.code) || [],
      }),
    );
    navigate(URLS.business);
  };

  return (
    <Container>
      <Title>{t('catalogSoftware')}</Title>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <Link>
        <MenuItemLink
          link={`/marketplace/request/${serviceRequest?.id}`}
          title={t('marketplace.serviceRequest')}
        />
      </Link>
      <SubTitle
        onClick={
          serviceRequest?.countPotentialBusinessCards
            ? onClickBusinessCards
            : undefined
        }
        style={{ cursor: 'pointer' }}
      >
        {t('found')}
        <span>{serviceRequest?.countPotentialBusinessCards}</span>
      </SubTitle>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <SubTitle>{t('marketplace.responds')}</SubTitle>
      {answersList.map((item) => (
        <>
          <AnswerItem key={item.id} onClick={navigateToAnswer(item.id)}>
            <Header>
              <BusinessAvatar
                src={`${attachUrl}${item?.businessCard?.logo?.path}`}
              />
              <Grid sx={{ flex: 1 }} spacing={0.5} direction="column" container>
                <Grid sx={{ display: 'flex', alignItems: 'center' }} item>
                  <Name>
                    {item?.businessCard?.name?.ru ||
                      item?.businessCard?.name?.en}
                  </Name>
                </Grid>{' '}
                <Grid sx={{ display: 'flex', alignItems: 'center' }} item>
                  <TimeIcon />
                  <InfoHeader>
                    {getFormattedDate(item.createdDateTime)}
                  </InfoHeader>
                </Grid>
              </Grid>
            </Header>

            <Content>
              <Editor
                sx={{
                  '& .ql-editor > *': {
                    cursor: 'pointer',
                  },
                }}
                readOnly
                value={truncate(item.content.ru || item.content.en, {
                  length: 100,
                  separator: ' ',
                })}
              />
            </Content>
          </AnswerItem>
          <Divider sx={{ mt: '18px', mb: '18px' }} />
        </>
      ))}
    </Container>
  );
};

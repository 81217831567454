import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';
import { localeValidate } from 'components/elements/fieldBuilder/validator';

const MAX_LENGTH_CONTENT = 4000;
const MAX_LENGTH_TITLE = 250;

export const serviceDataValidationSchema = yup.object({
  title: yup
    .object({
      ru: yup
        .string()
        .max(MAX_LENGTH_TITLE, ErrorMessages.maxLength(MAX_LENGTH_TITLE)),
      en: yup
        .string()
        .max(MAX_LENGTH_TITLE, ErrorMessages.maxLength(MAX_LENGTH_TITLE)),
    })
    .required(ErrorMessages.required),
  content: localeValidate(MAX_LENGTH_CONTENT),
  businessCardId: yup.string().required(ErrorMessages.required),
});

import React from 'react';
import { styled } from '@mui/system';
import { NavigationMenu } from 'components/features/home/components/navigationMenu/navigationMenu';
import { MENU_WIDTH } from 'services/theme';

const Container = styled('div')({
  minHeight: '100%',
  display: 'flex',
});

const ContainerContent = styled('div')({
  padding: '20px 37px',
  flex: 1,
  width: `calc(100vw - ${MENU_WIDTH}px)`,
});

export const HomeLayout = ({
  children,
  showFilters,
}: {
  children: React.ReactNode;
  showFilters?: boolean;
}) => {
  return (
    <Container>
      <NavigationMenu showFilters={showFilters} />
      <ContainerContent>{children}</ContainerContent>
    </Container>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@mui/system';
import {
  getServicesList,
  hasMoreMarketplaceListItems,
  isMarketplaceListLoading,
} from 'store/features/marketplace';
import { useAppDispatch, useAppSelector } from 'store';
import { getServices } from 'store/features/marketplace/actions';
import { ICompilationPageFilters, IService } from 'interfaces/Marketplace';
import { Loader } from 'components/shared/loader';
import { ServiceCard } from 'components/features/marketplace/components/cards/ServiceCard';
import { URLS } from 'defenitions/routes';
import { isEqual } from 'lodash';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { ListAdOne, ListAdTwo } from '../../../../elements/ads';

const EmptyText = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
});

type Props = {
  filters?: ICompilationPageFilters;
  onFiltersChange?: (filters: ICompilationPageFilters) => void;
  onFirstLoaded?: (length: number) => void;
};

export const ServicesList = ({
  filters = {},
  onFiltersChange,
  onFirstLoaded,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const limit = filters.limit ?? 10;
  const offset = filters.offset ?? 0;

  const isLoading = useAppSelector(isMarketplaceListLoading);
  const hasMore = useAppSelector(hasMoreMarketplaceListItems);
  const services = useAppSelector(getServicesList);

  const isFetching = offset === 0 && isLoading;
  const isRefetching = offset !== 0 && isLoading;

  const prevFilters = useRef<ICompilationPageFilters>({});
  const isFirstLoaded = useRef(true);

  useEffect(() => {
    const currentFilters = { ...filters, limit, offset };

    if (!isEqual(prevFilters.current, currentFilters)) {
      dispatch(getServices(currentFilters))
        .unwrap()
        .then((result) => {
          const request = result as AxiosResponse<IService[]>;

          if (isFirstLoaded.current) {
            onFirstLoaded?.(request?.data?.length || 0);
            isFirstLoaded.current = false;
          }
        });

      prevFilters.current = currentFilters;
    }
  }, [dispatch, filters, limit, offset, onFirstLoaded]);

  const changeFilters = () => {
    onFiltersChange?.({ limit, offset: offset + limit });
  };

  const navigateToService = (id: IService['businessService']['id']) => () => {
    navigate(`/marketplace/service/${id}`);
  };

  const navigateToCreateRequest =
    (id: IService['businessService']['id']) => () => {
      navigate(
        `${URLS.marketplaceCreateRequest}?${qs.stringify({
          service: id,
        })}`,
      );
    };

  const { isOpen, onClickOpen, onClickClose } = useModalActions();
  const [selectedService, setSelectedService] = useState<IService | null>(null);

  const handleClickCreateComplaint = (service: IService) => {
    onClickOpen();
    setSelectedService(service);
  };

  const handleClickCloseComplaint = () => {
    onClickClose();
    setSelectedService(null);
  };

  return (
    <>
      {isFetching && <Loader sx={{ m: 'auto' }} show />}
      {!isFetching && !services.length && (
        <EmptyText sx={{ m: 'auto' }}>
          {t('marketplace.emptyServices')}
        </EmptyText>
      )}
      {!isFetching && !!services.length && (
        <InfiniteScroll
          dataLength={services.length}
          loader={<Loader show={isRefetching} global />}
          next={changeFilters}
          hasMore={hasMore}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {services.map((service, index) => (
            <>
              {index % 4 === 0 && index % 8 !== 0 && index !== 0 && (
                <ListAdOne />
              )}
              {index % 4 === 0 && index % 8 === 0 && index !== 0 && (
                <ListAdTwo />
              )}
              <ServiceCard
                sx={[{ mb: 2 }, index === services.length - 1 && { mb: 0 }]}
                key={service.businessService.id}
                service={service}
                menu={[
                  {
                    label: t('actions.view'),
                    onClick: navigateToService(service.businessService.id),
                  },
                  {
                    label: t('actions.request'),
                    onClick: navigateToCreateRequest(
                      service.businessService.id,
                    ),
                  },
                  {
                    label: t('actions.complaint'),
                    onClick: () => {
                      handleClickCreateComplaint(service);
                    },
                  },
                ]}
              />
            </>
          ))}
        </InfiniteScroll>
      )}
      {selectedService && (
        <ConfirmDialog
          onClickClose={handleClickCloseComplaint}
          open={isOpen}
          title={t('modalTexts.complaint.title', {
            name: getLocaleValue(selectedService.businessService.name),
          })}
        >
          <CreateComplaint
            relationId={selectedService.businessService.id}
            complaintAboutType={ComplaintAboutType.BUSINESS_SERVICE}
            additionAction={handleClickCloseComplaint}
          />
        </ConfirmDialog>
      )}
    </>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from 'store/features/auth/config';
import {
  IAuthCredentials,
  IChangePasswordForm,
  ISignUpForm,
} from 'interfaces/Auth';
import { actionHandler } from 'store/utils/actionHandler';
import {
  signinEndpoint,
  signupEndpoint,
  restoreEndpoint,
  changePasswordEndpoint,
  logoutEndpoint,
} from 'store/features/auth/api';

export const signin = createAsyncThunk(
  ActionTypes.SIGNIN,
  async (credentials: IAuthCredentials, { rejectWithValue }) =>
    actionHandler(() => signinEndpoint(credentials), rejectWithValue),
);

export const signup = createAsyncThunk(
  ActionTypes.SIGNUP,
  async (data: ISignUpForm, { rejectWithValue }) =>
    actionHandler(() => signupEndpoint(data), rejectWithValue),
);

export const restore = createAsyncThunk(
  ActionTypes.RESTORE,
  async (email: string, { rejectWithValue }) =>
    actionHandler(() => restoreEndpoint(email), rejectWithValue),
);

export const changePassword = createAsyncThunk(
  ActionTypes.CHANGE_PASSWORD,
  async (data: IChangePasswordForm, { rejectWithValue }) =>
    actionHandler(() => changePasswordEndpoint(data), rejectWithValue),
);

export const logout = createAsyncThunk(
  ActionTypes.LOGOUT,
  async (any, { rejectWithValue }) =>
    actionHandler(() => logoutEndpoint(), rejectWithValue),
);

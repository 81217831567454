import { Locale } from 'interfaces/Locale';

export const PAGE_SIZE = 10;

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortName {
  name = 'name',
  publishedDateTime = 'publishedDateTime',
}

export interface IBusinessFilters {
  cardTypes: string[];
  services: string[];
  offset: number;
  limit: number;
  order: SortType;
  sort: SortName;
  locale: Locale;
  text: string | null;
}

export interface IBusinessReviewsFilters {
  businessCardId: string;
  offset: number;
  limit: number;
}

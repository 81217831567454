import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { Container, Title, Value } from './components';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import { attachUrl } from 'services/ApiClient';
import { useAppDispatch } from 'store';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { useModalActions } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { getLocaleValue } from 'utils/form';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { ReactComponent as ViewIcon } from 'assets/icons/viewIcon.svg';
import { ISoftware } from 'interfaces/Software';
import { showSoftware, hideSoftware } from 'store/features/software/actions';

type Props = {
  software: ISoftware;
  isAll?: boolean;
};

export const SoftwareCard = ({ software, isAll }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const { id, name, logo, viewsCounter, rate, softwareTypes, hidden } =
    software;
  const { isOpen, onClickOpen, onClickClose } = useModalActions();

  const handleClickEdit = useCallback(() => {
    navigate(`${URLS.software}/${id}/edit`);
  }, [id, navigate]);

  const handleClickView = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      navigate(`${URLS.software}/${id}/view`);
    },
    [id, navigate],
  );

  const hideSoftwareCard = useCallback(() => {
    dispatch(hideSoftware(id));
    setAnchorEl(null);
  }, [id, dispatch]);

  const showSoftwareCard = useCallback(() => {
    dispatch(showSoftware(id));
    setAnchorEl(null);
  }, [id, dispatch]);

  const onClickCreateReview = () => {
    navigate(`${URLS.software}/${id}/view?addReview=true`);
  };

  const type =
    softwareTypes &&
    softwareTypes.map(({ display }) => getLocaleValue(display)).join(', ');

  return (
    <Container container item xs={12} onMouseLeave={() => setAnchorEl(null)}>
      <Grid item container xs={2} alignItems={'center'}>
        <Avatar
          alt="Avatar"
          src={logo?.path ? `${attachUrl}${logo?.path}` : ''}
          sx={{ width: 80, height: 80 }}
        />
      </Grid>
      <Grid item container xs={7}>
        <Grid item xs={4}>
          <Title>Наименование:</Title>
        </Grid>
        <Grid item xs={8}>
          <Value>{getLocaleValue(name)}</Value>
        </Grid>
        <Grid item xs={4}>
          <Title>Тип ПО:</Title>
        </Grid>
        <Grid item xs={8}>
          <Value>{type}</Value>
        </Grid>
        <Grid item xs={4}>
          <Title>{`${t('rating')}:`}</Title>
        </Grid>
        <Grid item xs={8}>
          <Value>{rate}</Value>
        </Grid>
        <>
          <Grid item xs={4}>
            <Title>{`${t('card hide')}:`}</Title>
          </Grid>
          <Grid item xs={8}>
            <Value>{`${
              hidden ? t(`boolean.${hidden}`) : t(`boolean.false`)
            }`}</Value>
          </Grid>
        </>
      </Grid>
      <Grid item container xs={12} mt={2}>
        <InteractiveButton
          variant="stroke"
          IconComponent={ViewIcon}
          count={viewsCounter.uniqueUsers}
          label={t('views')}
          active={viewsCounter.viewedByCurrentUser}
        />
      </Grid>
      <MoreIcon
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      />
      {anchorEl && (
        <MoreMenu
          anchorEl={anchorEl}
          onClickView={handleClickView}
          onClickEdit={!isAll ? handleClickEdit : undefined}
          onClickComplaint={isAll ? onClickOpen : undefined}
          onClickShow={!isAll && hidden ? showSoftwareCard : undefined}
          onClickHide={!isAll && !hidden ? hideSoftwareCard : undefined}
          onClickReview={isAll ? onClickCreateReview : undefined}
        />
      )}
      <ConfirmDialog
        onClickClose={onClickClose}
        open={isOpen}
        title={t('modalTexts.complaint.title', {
          name: getLocaleValue(name),
        })}
      >
        <CreateComplaint
          relationId={id}
          complaintAboutType={ComplaintAboutType.SOFTWARE_CARD}
          additionAction={onClickClose}
        />
      </ConfirmDialog>
    </Container>
  );
};

export enum ReactionType {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
}

export interface Reaction {
  count: number;
  hasByUser: boolean;
}

export type ReactionDictionary = Partial<Record<ReactionType, Reaction>>;

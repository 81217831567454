import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { IComplaintsStats } from 'interfaces/Stats';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';
import { StatsTable } from 'components/elements/table';
import {
  createHeadCell,
  createData,
} from 'components/features/stats/item/business/tables/complaints/table/utils';

export const ComplaintsStats = ({
  complaints,
}: {
  complaints: IComplaintsStats;
}) => {
  const { t } = useTranslation();

  return (
    <StatsCollapseLayout title={t('tables.tableComplaints')}>
      <Grid item container>
        <StatsTable
          data={createData(complaints.complaints)}
          columns={createHeadCell()}
        />
      </Grid>
    </StatsCollapseLayout>
  );
};

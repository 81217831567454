import React, { useEffect } from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { IMultiLanguage } from 'interfaces/Locale';
import { useForm } from 'react-hook-form';
import { IInvalidContent } from 'interfaces/Complaint';
import { yupResolver } from '@hookform/resolvers/yup';
import { contentValidationSchema } from './validationScheme';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { useTranslation } from 'react-i18next';

const Root = styled('form')(({ theme }) => ({
  padding: '16px 24px',
  border: '1px solid #e4e4e4',
  backgroundColor: theme.palette.background.paper,
}));

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

type Form = {
  text: IMultiLanguage;
};

type Props = {
  sx?: SxProps<Theme>;
  initial: IInvalidContent;
  onSubmit: (data: IInvalidContent) => void;
  onClose: () => void;
};

export const EditContentForm = ({
  sx = [],
  initial,
  onSubmit,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const {
    formState: { isValid, errors },
    control,
    handleSubmit,
    reset,
    watch,
    trigger,
  } = useForm<Form>({
    defaultValues: { text: { en: initial.text.en, ru: initial.text.ru } },
    mode: 'onChange',
    resolver: yupResolver(contentValidationSchema),
  });

  const submit = (data: Form) => {
    onSubmit({ ...initial, ...data });
    reset();
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'text.en' && type === 'change') {
        trigger('text.ru');
      }
      if (name === 'text.ru' && type === 'change') {
        trigger('text.en');
      }
    });
    return () => subscription.unsubscribe();
  }, [trigger, watch]);

  useEffect(() => {
    trigger();
  }, [trigger]);
  return (
    <Root
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      onSubmit={handleSubmit(submit)}
    >
      <FormMultiLocaleField
        label={t('moderation.form.contentLabel')}
        placeholder={t('moderation.form.contentPlaceholder')}
        fieldName="text"
        control={control}
        errors={errors}
      />
      <Actions sx={{ mt: 2 }}>
        <CreateButton
          sx={{ width: 135 }}
          text={t('actions.edit')}
          type="submit"
          autoFocus
          disabled={!isValid}
        />
        <ActionButton
          sx={{ width: 135 }}
          text={t('cancel')}
          type="button"
          autoFocus
          onClick={onClose}
        />
      </Actions>
    </Root>
  );
};

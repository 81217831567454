import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import { useAppDispatch, useAppSelector } from 'store';
import { getSoftwareCard } from 'store/features/software/actions';
import { selectSoftwareCard } from 'store/features/software';
import { SoftwareDetailedCard } from 'components/features/marketplace/components/cards/SoftwareDetailedCard';
import { ServiceHeader } from 'components/features/marketplace/components/ServiceHeader';
import { RouteNames, URLS } from 'defenitions/routes';

const Container = styled('div')({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
});

type Params = { id: string };

export const MarketplaceSoftware = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id = '' } = useParams<Params>();

  const software = useAppSelector(selectSoftwareCard);

  const handleBack = () => navigate(-1);

  useEffect(() => {
    dispatch(getSoftwareCard(id));
  }, [dispatch, id]);

  return (
    <MarketplaceLayout
      contentSx={{ bgcolor: (theme) => theme.palette.background.paper }}
    >
      <Container>
        <ServiceHeader
          sx={{ borderBottom: '1px solid #e4e4e4' }}
          onBack={handleBack}
        >
          {RouteNames[URLS.marketplaceSoftware]}
        </ServiceHeader>
        {software && <SoftwareDetailedCard software={software} />}
      </Container>
    </MarketplaceLayout>
  );
};

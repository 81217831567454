import React, { MouseEvent, useCallback, useState } from 'react';
import { styled } from '@mui/system';
import { ReactComponent as ClockIcon } from 'assets/icons/clockIcon.svg';
import { attachUrl } from 'services/ApiClient';
import { getFormattedDate } from 'utils/dates';
import { IComment } from 'interfaces/News';
import {
  changeReaction,
  deleteNewsComment,
  editComment,
  uploadAttachments,
} from 'store/features/news/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { AttachmentType, IAttachment } from 'interfaces/Attachment';
import { AxiosResponse } from 'axios';
import {
  commentValidationSchema,
  EditCommentForm,
} from 'components/elements/comment';
import { IComment as IEditComment } from 'interfaces/Comment';
import { EditCommentFormData } from 'components/elements/comment/EditCommentForm';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getAccount } from 'store/features/users';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { getLocaleValue } from 'utils/form';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import Snackbar from 'services/Snackbar';
import { Editor } from 'components/shared/editor';
import { ReactionType } from 'interfaces/Post';
import { Box } from '@mui/material';

const CommentHeader = styled('div')({
  display: 'flex',
  position: 'relative',
});

const CommentHeaderPhoto = styled('div')({
  marginRight: 5,
  img: {
    borderRadius: '50%',
    overflow: 'hidden',
    width: 53,
    height: 47,
  },
});

const CommentHeaderInfo = styled('div')({});

const CommentHeaderInfoTitle = styled(Link)({
  fontSize: '14px',
  fontWeight: 500,
  color: '#000000',
});

const CommentItemHeaderInfoSubTitle = styled('div')({
  fontSize: '12px',
  color: '#A9A9A9',
});

const CommentItemHeaderInfoTime = styled('div')({
  display: 'flex',
  fontSize: '12px',
  color: '#A9A9A9',
});

const CommentItemHeaderInfoTimeIcon = styled('div')({
  marginRight: 5,
});

const CommentContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginTop: 25,
  marginBottom: 25,
});

const CommentAttachments = styled('div')({
  display: 'flex',
  width: '100%',
});

const Attachment = styled('div')({
  marginRight: '10px',
  position: 'relative',
  width: '100%',
});

const CommentItem = styled('div')({
  width: '100%',
  border: '1px solid #E4E4E4',
  padding: '18px 33px',
});

const AttachmentVideo = styled('div')({
  video: {
    height: 'auto',
    width: '100%',
    objectFit: 'fill',
    position: 'absolute',
  },
});

const NameWordsLogo = styled('div')({
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#00618E',
  color: 'white',
  borderRadius: '50%',
  width: 47,
  height: 47,
});

const ActionsWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const TextButton = styled('button')({
  padding: 0,
  border: 0,
  fontFamily: 'inherit',
  fontWeight: '400',
  fontSize: 14,
  color: '#00618e',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
});

const getAttachmentWidth = (count?: number) => {
  switch (count) {
    case 1:
    default:
      return '100%';
    case 2:
      return '45%';
    case 3:
      return '30%';
  }
};

export const CommentCard = ({
  newsId,
  comment,
  currentUserId,
  forSystemCard = false,
}: {
  newsId: string;
  comment: IComment;
  currentUserId?: string;
  forSystemCard?: boolean;
}) => {
  const { t } = useTranslation();
  const language = 'ru';
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
  const { isOpen, onClickOpen, onClickClose } = useModalActions();

  const account = useAppSelector(getAccount);

  const { id, createdDateTime, updateDateTime, reactions } = comment;
  const edited = createdDateTime !== updateDateTime;

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const mine =
    comment.canProcessByCurrentUser && currentUserId === comment.creator?.id;

  const [editing, setEditing] = useState(false);

  const handleUploadAttachments = async (formData: FormData) => {
    const { payload } = await dispatch(
      uploadAttachments({
        formData,
        type: AttachmentType.Comment,
      }),
    );

    return (payload as AxiosResponse<IAttachment[]>).data;
  };

  const handleStartEditing = () => setEditing(true);

  const handleStopEditing = () => setEditing(false);

  const handleLike = useCallback(async () => {
    await dispatch(
      changeReaction({
        id: comment.id,
        type: ReactionType.LIKE,
      }),
    );
  }, [comment.id, dispatch]);

  const handleDislike = useCallback(async () => {
    await dispatch(
      changeReaction({
        id: comment.id,
        type: ReactionType.DISLIKE,
      }),
    );
  }, [comment.id, dispatch]);

  const handleEditComment = async (data: EditCommentFormData) => {
    const { attachments } = data;
    const attachmentIds = attachments.map((attachment) => attachment.id);
    await dispatch(
      editComment({
        id,
        businessCardId: data.businessCardId,
        content: data.content,
        attachments: attachmentIds,
        softwareCards: data.softwareCards?.map((el) => el.code),
        softwareCardIds: data.softwareCards?.map((el) => el.code),
      }),
    );
    handleStopEditing();
  };

  const handleDelete = () => {
    dispatch(deleteNewsComment({ newsId, commentId: comment.id }));
  };

  const showNotification = (
    event: MouseEvent<HTMLAnchorElement> | undefined,
  ) => {
    event?.preventDefault();
    Snackbar.show(t('hidden card'), 'warning');
  };

  const software = comment?.softwareCards?.map((el, index) => (
    <Link
      key={el.id}
      to={generatePath(URLS.viewSoftwareCard, { id: el.id })}
      onClick={
        el?.hidden && !el?.cardBelongToCurrentAccount
          ? showNotification
          : undefined
      }
    >
      <span>{`${getLocaleValue(el.name)}${
        index === (comment?.softwareCards?.length || 0) - 1 ? '' : ', '
      }`}</span>
    </Link>
  ));

  if (editing) {
    return (
      <EditCommentForm
        comment={comment as IEditComment}
        label={'Написать ответ'}
        validationSchema={commentValidationSchema}
        uploadAttachments={handleUploadAttachments}
        onSubmit={handleEditComment}
        onCancel={handleStopEditing}
      />
    );
  }

  return (
    <CommentItem>
      <CommentHeader onMouseLeave={() => setAnchorEl(null)}>
        <CommentHeaderPhoto>
          {comment.businessCard?.logo?.path ? (
            <img src={`${attachUrl}${comment.businessCard?.logo?.path}`} />
          ) : (
            <NameWordsLogo>
              {comment.creator.name[0]} {comment.creator.lastName[0]}
            </NameWordsLogo>
          )}
        </CommentHeaderPhoto>
        <CommentHeaderInfo>
          <CommentHeaderInfoTitle
            to={`${URLS.business}/${comment.businessCard?.id}/view`}
            target={'_blank'}
          >
            {comment.businessCard?.name?.[language]}
          </CommentHeaderInfoTitle>
          <CommentItemHeaderInfoSubTitle>
            {`${comment.creator.name} ${comment.creator.lastName}, #${comment.creator.reputation}`}
          </CommentItemHeaderInfoSubTitle>
          <CommentItemHeaderInfoTime>
            <CommentItemHeaderInfoTimeIcon>
              <ClockIcon />
            </CommentItemHeaderInfoTimeIcon>
            {getFormattedDate(comment.createdDateTime)}
          </CommentItemHeaderInfoTime>
        </CommentHeaderInfo>
        {(currentUserId !== comment.creator.id ||
          (account?.isSystemAccount && !forSystemCard)) && (
          <MoreIcon
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          />
        )}
        {anchorEl && (
          <MoreMenu
            anchorEl={anchorEl}
            onClickComplaint={
              currentUserId !== comment.creator.id &&
              !(account?.isSystemAccount && !forSystemCard)
                ? onClickOpen
                : undefined
            }
            onClickDelete={
              account?.isSystemAccount && !forSystemCard
                ? handleDelete
                : undefined
            }
          />
        )}
        <ConfirmDialog
          onClickClose={onClickClose}
          open={isOpen}
          title={t('modalTexts.complaint.title', {
            name: comment.creator.name,
          })}
        >
          <CreateComplaint
            relationId={comment.id}
            complaintAboutType={ComplaintAboutType.COMMENT}
            additionAction={onClickClose}
          />
        </ConfirmDialog>
      </CommentHeader>
      {software && <div>{software}</div>}
      <CommentContent>
        <Editor value={getLocaleValue(comment?.content)} readOnly />
        {edited && <EditOutlinedIcon color="inherit" fontSize="inherit" />}
      </CommentContent>
      <CommentAttachments>
        {comment.attachments?.map((item) => (
          <Attachment
            key={item.id}
            style={{
              width: getAttachmentWidth(comment?.attachments?.length),
            }}
          >
            {item.contentType.includes('image') ? (
              <img
                src={`${attachUrl}${item.path}`}
                loading={'lazy'}
                style={{
                  width: '100%',
                }}
              />
            ) : (
              <AttachmentVideo>
                <video controls>
                  <source src={`${attachUrl}${item.path}`} type="video/mp4" />
                </video>
              </AttachmentVideo>
            )}
          </Attachment>
        ))}
      </CommentAttachments>
      <ActionsWrap>
        <Box sx={{ flexBasis: '100px' }}>
          <InteractiveButton
            IconComponent={LikeIcon}
            count={likes}
            active={hasUserLikes}
            onClick={handleLike}
          />
        </Box>
        <Box sx={{ flexBasis: '100px' }}>
          <InteractiveButton
            IconComponent={DislikeIcon}
            count={dislikes}
            active={hasUserDislikes}
            onClick={handleDislike}
          />
        </Box>
        {mine && (
          <TextButton sx={{ ml: 4 }} onClick={handleStartEditing}>
            {'Изменить'}
          </TextButton>
        )}
      </ActionsWrap>
    </CommentItem>
  );
};

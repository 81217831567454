export const Slices: Record<string, string> = {
  signin: 'auth',
  business: 'business',
  users: 'users',
  dictionaries: 'dictionaries',
  news: 'news',
  marketplace: 'marketplace',
  complaints: 'complaints',
  settings: 'settings',
  notifications: 'notifications',
  stats: 'stats',
  search: 'search',
  software: 'software',
};

import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { styled, SxProps, Theme } from '@mui/system';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { Controller, useForm } from 'react-hook-form';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getApplicationAreasDictionaries,
  getProgrammingLanguagesDictionaries,
  getQuestionCategoriesDictionaries,
  getQuestionSubcategoriesDictionaries,
} from 'store/features/dictionaries';
import {
  getBusinessCardItem,
  getPublishedBusinessCardsList,
} from 'store/features/business';
import { CreateQuestion } from 'interfaces/Question';
import { IBusinessAttachment } from 'interfaces/Attachment';
import {
  getDictionariesContentApplicationAreas,
  getDictionariesContentProgrammingLanguages,
  getDictionariesQuestionCategories,
  getDictionariesQuestionSubcategories,
} from 'store/features/dictionaries/actions';
import {
  getBusinessCard,
  getPublishedBusinessCards,
} from 'store/features/business/actions';
import {
  createQuestion,
  uploadQuestionAttachments,
} from 'store/features/questions/actions';
import { AxiosResponse } from 'axios';
import Snackbar from 'services/Snackbar';
import { TextFieldInput } from 'components/shared/inputs';
import { createQuestionValidationSchema } from './validationSchemes';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { IDictionaries, IDictionariesContent } from 'interfaces/Dictionaries';
import {
  selectOthersSoftwareCards,
  selectOthersSoftwareCardsByBusinessId,
} from 'store/features/software';
import {
  getOthersAccountSoftwareCardByBusinessId,
  getOthersAccountSoftwareCards,
} from 'store/features/software/actions';

const Title = styled('h2')({
  marginBottom: 30,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '30px',
  color: '#4b4b4b',
});

const Form = styled('form')({
  width: 732,
  padding: '16px 24px',
});

const FormContent = styled(Grid)({
  marginBottom: 24,
});

const FormActions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 24,
  width: '100%',
});

const dictionariesToContent = (
  dictionary: IDictionaries[],
): IDictionariesContent[] => {
  return dictionary.map((d) => ({
    code: d.id,
    display: d.text,
    selectable: !d.disabled,
    ...(d.children ? { children: dictionariesToContent(d.children) } : {}),
  })) as IDictionariesContent[];
};

type Props = {
  receiverBusinessCardId?: string;
  sx?: SxProps<Theme>;
  onCancel?: () => void;
  onSuccess?: () => void;
};

export const CreateQuestionForm = ({
  receiverBusinessCardId,
  sx = [],
  onCancel,
  onSuccess,
}: Props) => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(getQuestionCategoriesDictionaries);
  const subcategories = useAppSelector(getQuestionSubcategoriesDictionaries);
  const business = useAppSelector(getPublishedBusinessCardsList);
  const receiver = useAppSelector(getBusinessCardItem);
  const software = useAppSelector(selectOthersSoftwareCards);
  const languages = useAppSelector(getProgrammingLanguagesDictionaries);
  const applicationAreas = useAppSelector(getApplicationAreasDictionaries);

  const receiverSoftware = useAppSelector(
    selectOthersSoftwareCardsByBusinessId,
  );

  const {
    formState: { isValid, errors },
    control,
    watch,
    reset,
    resetField,
    trigger,
    handleSubmit,
  } = useForm<Omit<CreateQuestion, 'attachments'>>({
    mode: 'onChange',
    resolver: yupResolver(createQuestionValidationSchema),
  });

  const isEmptyCategory = !watch('categoryId');

  const [attachments, setAttachments] = useState<IBusinessAttachment[]>([]);

  const categoriesDictionary = dictionariesToContent(categories);

  const subcategoriesDictionary = dictionariesToContent(subcategories);

  const businessDictionary = business.map((b) => ({
    code: b.id,
    display: b.name,
    selectable: false,
  }));

  const softwareDictionary = software.map((s) => ({
    code: s.id,
    display: s.name,
    selectable: false,
  }));

  const receiverSoftwareDictionary = receiverSoftware.map((s) => ({
    code: s.id,
    display: s.name,
    selectable: false,
  }));

  const handleCategoriesLoadOptions = useCallback(() => {
    dispatch(getDictionariesQuestionCategories());
  }, [dispatch]);

  const handleSubcategoriesLoadOptions = useCallback(
    (categoryId?: string | undefined) => {
      const category = categories.find((c) => c.id === categoryId);
      const directory = category?.childDirectory;
      if (!directory) return;
      dispatch(getDictionariesQuestionSubcategories({ directory }));
    },
    [categories, dispatch],
  );

  const handleSoftwareLoadOptions = useCallback(() => {
    dispatch(getOthersAccountSoftwareCards());
  }, [dispatch]);

  const handleLoadProgrammingLanguages = useCallback(() => {
    dispatch(getDictionariesContentProgrammingLanguages());
  }, [dispatch]);

  const handleLoadApplicationAreas = useCallback(() => {
    dispatch(getDictionariesContentApplicationAreas());
  }, [dispatch]);

  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      const { payload } = await dispatch(uploadQuestionAttachments(formData));
      const { data } = payload as AxiosResponse<IBusinessAttachment[]>;
      data && setAttachments((prev) => [...prev, ...data]);
    },
    [dispatch],
  );

  const handleRemoveAttachments = useCallback((id: string) => {
    setAttachments((prevState) => prevState.filter((el) => el.id !== id));
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    const question = {
      ...data,
      receiverBusinessCardId: receiverBusinessCardId
        ? receiver ?? undefined
        : undefined,
      receiverSoftwareCardId: receiverBusinessCardId
        ? data.receiverSoftwareCardId
        : undefined,
      businessCardId: businessDictionary[0],
      attachments,
    };

    const result = await dispatch(createQuestion(question)).unwrap();

    if (result) {
      setAttachments([]);
      reset();
      onSuccess?.();
      Snackbar.show('Вопрос успешно опубликован', 'success');
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'topic.en' && type === 'change') {
        trigger('topic.ru');
      }
      if (name === 'topic.ru' && type === 'change') {
        trigger('topic.en');
      }
      if (name === 'content.en' && type === 'change') {
        trigger('content.ru');
      }
      if (name === 'content.ru' && type === 'change') {
        trigger('content.en');
      }
      if (name === 'categoryId' && type === 'change') {
        resetField('subcategoryId');
        handleSubcategoriesLoadOptions(value.categoryId?.code);
      }
    });

    return () => subscription.unsubscribe();
  }, [handleSubcategoriesLoadOptions, resetField, trigger, watch]);

  useEffect(() => {
    if (!receiverBusinessCardId) return;
    dispatch(getBusinessCard(receiverBusinessCardId));
    dispatch(
      getOthersAccountSoftwareCardByBusinessId({
        businessCardId: receiverBusinessCardId,
      }),
    );
  }, [dispatch, receiverBusinessCardId]);

  useEffect(() => {
    dispatch(getPublishedBusinessCards());
  }, [dispatch]);

  useEffect(() => {
    trigger();
  }, [trigger]);
  return (
    <Form sx={[...(Array.isArray(sx) ? sx : [sx])]} onSubmit={onSubmit}>
      <Title>Задать вопрос</Title>
      <FormContent container spacing={4}>
        {receiverBusinessCardId && receiver && (
          <Grid item xs={12}>
            <TextFieldInput
              label="Получатель вопроса"
              value={receiver?.name.ru ?? receiver?.name.en}
              disabled
            />
          </Grid>
        )}
        {receiverBusinessCardId && !!receiverSoftware?.length && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="receiverSoftwareCardId"
              render={({ field: { ref, value, ...rest } }) => (
                <MultiSelectAutocomplete
                  {...rest}
                  value={value ?? null}
                  noHierarchy
                  inputRef={ref}
                  label="Получатель ПО"
                  data={receiverSoftwareDictionary}
                  errorMessage={errors.receiverSoftwareCardId?.toString()}
                  onLoadOptions={handleSoftwareLoadOptions}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Controller
            control={control}
            name="categoryId"
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value ?? null}
                noHierarchy
                inputRef={ref}
                label="Выберите категорию вопроса"
                data={categoriesDictionary}
                errorMessage={errors.categoryId?.toString()}
                onLoadOptions={handleCategoriesLoadOptions}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="subcategoryId"
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value ?? null}
                noHierarchy
                inputRef={ref}
                label="Выберите подкатегорию вопроса"
                data={subcategoriesDictionary}
                errorMessage={errors.subcategoryId?.toString()}
                disabled={isEmptyCategory}
              />
            )}
          />
        </Grid>
        {!!businessDictionary?.length && (
          <Grid item xs={12}>
            <MultiSelectAutocomplete
              value={businessDictionary[0]}
              noHierarchy
              disabled
              label="Бизнес"
              data={businessDictionary}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="softwareCardIds"
            defaultValue={[]}
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value}
                noHierarchy
                multiple
                inputRef={ref}
                label="ПО"
                data={softwareDictionary}
                errorMessage={errors.softwareCardIds?.toString()}
                onLoadOptions={handleSoftwareLoadOptions}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="programmingLanguages"
            control={control}
            defaultValue={[]}
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value}
                noHierarchy
                multiple
                inputRef={ref}
                label="Язык программирования"
                data={languages}
                errorMessage={errors.programmingLanguages?.toString()}
                onLoadOptions={handleLoadProgrammingLanguages}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="applicationAreas"
            control={control}
            defaultValue={[]}
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value}
                noHierarchy
                multiple
                inputRef={ref}
                data={applicationAreas}
                label={'Область применения'}
                errorMessage={errors.applicationAreas?.toString()}
                onLoadOptions={handleLoadApplicationAreas}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName="topic"
            label="Тема вопроса"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            control={control}
            errors={errors}
            fieldName="content"
            label="Текст вопроса"
            isEditor
          />
        </Grid>
        <Grid item xs={12}>
          <FilesUploader
            maxFiles={3}
            onUploadAttachments={handleUploadAttachments}
            onClickRemoveAttachments={handleRemoveAttachments}
            attachments={attachments}
          />
        </Grid>
      </FormContent>
      <FormActions>
        <CreateButton
          fullWidth
          type="button"
          text="Отменить"
          onClick={onCancel}
        />
        <ActionButton
          fullWidth
          type="submit"
          text="Опубликовать вопрос"
          disabled={!isValid}
        />
      </FormActions>
    </Form>
  );
};

export const MAX_LENGTH = 250;
export const MAX_LENGTH_SMALL = 128;
export const MAX_LENGTH_DESCRIPTION = 2000;
export const MAX_LENGTH_QUESTION = 4000;

export const ErrorMessages = {
  required: 'Поле, обязательное для заполнения',
  emailNotCorrect: 'Некорректно указан адрес электронной почты',
  notAcceptedTerms: 'Необходимо принимять пользовательское соглашение',
  confirmPassword: 'Пароли не совпадают',
  passwordRegex: 'Введенный пароль не соответствует требованиям',
  maxLength: (count: number) => `Допустимое количество символов ${count}`,
  url: 'Введите корретный адрес сайта',
};

import React, { useCallback, useEffect, useState } from 'react';
import { RouteNames, URLS } from 'defenitions/routes';
import { TabLayout } from 'components/elements/tabs';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  Container,
  Title,
} from 'components/features/business/containers/item/update/components';
import { IAttachment } from 'interfaces/Attachment';
import { AxiosResponse } from 'axios';
import { Loader } from 'components/shared/loader';
import Snackbar from 'services/Snackbar';
import { IAPIResponseError } from 'interfaces/APIResponseError';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { useTranslation } from 'react-i18next';
import {
  selectSoftwareCard,
  selectSoftwareCardLoading,
} from 'store/features/software';
import {
  getSoftwareCard,
  uploadSoftwareCardAttachments,
  uploadSoftwareCardLogo,
} from 'store/features/software/actions';
import { ISoftware } from 'interfaces/Software';
import { commonDataValidationSchema } from 'components/features/business/shared/item/edit/SoftwareCommonData/validationSchema';
import { SoftwareCommonDataForm } from 'components/features/business/shared/item/edit/SoftwareCommonData';
import {
  createSoftwareCard,
  updateSoftwareCard,
} from 'store/features/software/actions';
import { transformSoftwareDataForm } from 'utils/software';
import {
  getSoftwareTypesDictionaries,
  getProgrammingLanguagesDictionaries,
  getApplicationAreasDictionaries,
  getTechnologyStackDictionaries,
} from 'store/features/dictionaries';
import {
  getDictionariesContentSoftwareTypes,
  getDictionariesContentProgrammingLanguages,
  getDictionariesContentApplicationAreas,
  getDictionariesContentTechnologyStack,
} from 'store/features/dictionaries/actions';
import { SoftwareCodeExamples } from 'components/features/business/shared/item/edit/codeExamples';

export const UpdateSoftwareCard = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const isNew = id === 'new';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const softwareCard = useAppSelector(selectSoftwareCard);
  const isSoftwareCardLoading = useAppSelector(selectSoftwareCardLoading);
  const softwareTypes = useAppSelector(getSoftwareTypesDictionaries);
  const programmingLanguages = useAppSelector(
    getProgrammingLanguagesDictionaries,
  );
  const applicationAreas = useAppSelector(getApplicationAreasDictionaries);
  const technologyStack = useAppSelector(getTechnologyStackDictionaries);
  const [logo, setLogo] = useState<IAttachment>();
  const [attachments, setAttachments] = useState<IAttachment[]>(
    softwareCard?.attachments || [],
  );

  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const onClickOpenConfirm = () => setIsOpenConfirm(true);
  const onClickCloseConfirm = () => setIsOpenConfirm(false);
  const onClickCancel = () => navigate(URLS.business);
  const onClickNext = () => setTab(1);
  const onClickBack = () => setTab(0);
  const { control, handleSubmit, formState, trigger, reset } =
    useForm<ISoftware>({
      mode: 'onChange',
      resolver: yupResolver(commonDataValidationSchema),
    });

  const handleSave: SubmitHandler<ISoftware> = async (data) => {
    const normalizeData = transformSoftwareDataForm(data, logo, attachments);
    const response = id
      ? await dispatch(updateSoftwareCard(normalizeData))
      : await dispatch(createSoftwareCard(normalizeData));
    const payload = response.payload as IAPIResponseError;
    if (payload.success !== false) {
      navigate(URLS.software);
      Snackbar.show('Данные сохранены', 'success');
    }
  };

  const handleUploadLogo = useCallback(
    async (formData: FormData) => {
      const { payload } = await dispatch(uploadSoftwareCardLogo(formData));
      const { data } = payload as AxiosResponse<IAttachment>;
      setLogo(data);
    },
    [dispatch],
  );

  const handleDeleteLogo = () => setLogo(undefined);

  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      const { payload } = await dispatch(
        uploadSoftwareCardAttachments(formData),
      );
      const { data } = payload as AxiosResponse<IAttachment[]>;
      data && setAttachments((prevState) => [...prevState, ...data]);
    },
    [dispatch],
  );

  const handleLoadSoftwareTypes = useCallback(() => {
    dispatch(getDictionariesContentSoftwareTypes());
  }, [dispatch]);

  const handleLoadProgrammingLanguages = useCallback(() => {
    dispatch(getDictionariesContentProgrammingLanguages());
  }, [dispatch]);

  const handleLoadApplicationAreas = useCallback(() => {
    dispatch(getDictionariesContentApplicationAreas());
  }, [dispatch]);

  const handleLoadTechnologyStack = useCallback(() => {
    dispatch(getDictionariesContentTechnologyStack());
  }, [dispatch]);

  const handleRemoveAttachments = (id: string) =>
    setAttachments((prevState) => prevState.filter((el) => el.id !== id));

  const name = useWatch({ name: 'name', control });
  useEffect(() => {
    trigger();
  }, [trigger, name]);

  useEffect(() => {
    //load selects
    if (!isNew && id) {
      dispatch(getSoftwareCard(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const defaultValues = id ? { ...softwareCard } : {};
    reset(defaultValues);
    setAttachments(id ? softwareCard?.attachments || [] : []);
    setLogo(id ? softwareCard?.logo : undefined);
  }, [softwareCard, reset, id]);

  return (
    <Container>
      {isSoftwareCardLoading && <Loader show global />}
      <Title>
        {id ? softwareCard?.name.ru : RouteNames[URLS.createSoftware]}
      </Title>
      <form>
        <TabLayout
          tab={tab}
          disabled={!formState.isValid}
          onChangeTabs={handleChange}
          tabs={[
            {
              label: 'Общая информация',
              component: (
                <SoftwareCommonDataForm
                  control={control}
                  formState={formState}
                  onClickNext={onClickNext}
                  onClickCancel={onClickOpenConfirm}
                  onUploadLogo={handleUploadLogo}
                  onDeleteLogo={handleDeleteLogo}
                  onRemoveAttachments={handleRemoveAttachments}
                  logo={logo}
                  attachments={attachments}
                  onUploadAttachments={handleUploadAttachments}
                  softwareTypes={softwareTypes}
                  onLoadSoftwareTypes={handleLoadSoftwareTypes}
                  programmingLanguages={programmingLanguages}
                  onLoadProgrammingLanguages={handleLoadProgrammingLanguages}
                  applicationAreas={applicationAreas}
                  onLoadApplicationAreas={handleLoadApplicationAreas}
                  technologyStack={technologyStack}
                  onLoadTechnologyStack={handleLoadTechnologyStack}
                />
              ),
            },
            {
              label: 'Примеры кода',
              component: (
                <SoftwareCodeExamples
                  onClickBack={onClickBack}
                  onClickSave={handleSave}
                  handleSubmit={handleSubmit}
                  formState={formState}
                  control={control}
                  programmingLanguages={programmingLanguages}
                  onLoadProgrammingLanguages={handleLoadProgrammingLanguages}
                />
              ),
            },
          ]}
        />
      </form>
      <ConfirmDialog
        onClickAccept={onClickCancel}
        onClickClose={onClickCloseConfirm}
        open={isOpenConfirm}
        actionTitle={t('modalTexts.business.actionTitle')}
        text={t('modalTexts.business.text')}
        title={t('modalTexts.business.title')}
      />
    </Container>
  );
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Answer, Question, QuestionSubscription } from 'interfaces/Question';
import { RootState } from 'store';
import {
  closeQuestion,
  createAnswer,
  getAnswers,
  getQuestionById,
  getQuestions,
  reactToQuestionPost,
  getQuestionSubscriptions,
  createQuestionSubscriptions,
  editAnswer,
  acceptAnswer,
} from './actions';
import { initialState } from './config';
import { ReactionType } from 'interfaces/Post';

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuestionById.pending, (state) => {
      state.current.loading = true;
      state.current.loaded = false;
    });
    builder.addCase(getQuestionById.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<Question>;
      state.current.question = data;
      state.current.loading = false;
      state.current.loaded = true;
    });
    builder.addCase(getQuestionById.rejected, (state) => {
      state.current.loading = false;
      state.current.loaded = false;
    });
    builder.addCase(getQuestions.pending, (state, { meta }) => {
      const { offset = 0 } = meta.arg ?? {};
      state.list.loading = true;
      state.list.loaded = false;
      state.list.hasMore = offset === 0 || state.list.hasMore;
    });
    builder.addCase(getQuestions.fulfilled, (state, { payload, meta }) => {
      const { offset = 0 } = meta.arg ?? {};
      const { data } = payload as AxiosResponse<Question[]>;
      state.list.questions =
        offset === 0 ? data : [...state.list.questions, ...data];
      state.list.loading = false;
      state.list.loaded = true;
      state.list.hasMore = data.length !== 0;
    });
    builder.addCase(getQuestions.rejected, (state) => {
      state.list.loading = false;
      state.list.loaded = false;
      state.list.hasMore = false;
    });
    builder.addCase(reactToQuestionPost.pending, (state, { meta }) => {
      const { id, type } = meta.arg;

      const posts = [
        ...state.list.questions,
        state.current.question,
        ...state.current.answers,
      ];

      for (const post of posts) {
        if (post?.id === id) {
          post.reactions = post.reactions ?? {};

          post.reactions[ReactionType.LIKE] = post.reactions[
            ReactionType.LIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          post.reactions[ReactionType.DISLIKE] = post.reactions[
            ReactionType.DISLIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          if (type === ReactionType.LIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            likes.count += likes.hasByUser ? -1 : 1;
            likes.hasByUser = !likes.hasByUser;

            dislikes.count -= dislikes.hasByUser ? 1 : 0;
            dislikes.hasByUser = false;
          }

          if (type === ReactionType.DISLIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            dislikes.count += dislikes.hasByUser ? -1 : 1;
            dislikes.hasByUser = !dislikes.hasByUser;

            likes.count -= likes.hasByUser ? 1 : 0;
            likes.hasByUser = false;
          }
        }
      }
    });
    builder.addCase(reactToQuestionPost.rejected, (state, { meta }) => {
      const { id, type } = meta.arg;

      const posts = [
        ...state.list.questions,
        state.current.question,
        ...state.current.answers,
      ];

      for (const post of posts) {
        if (post?.id === id) {
          post.reactions = post.reactions ?? {};

          post.reactions[ReactionType.LIKE] = post.reactions[
            ReactionType.LIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          post.reactions[ReactionType.DISLIKE] = post.reactions[
            ReactionType.DISLIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          if (type === ReactionType.LIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            likes.count += likes.hasByUser ? -1 : 1;
            likes.hasByUser = !likes.hasByUser;

            dislikes.count -= dislikes.hasByUser ? 1 : 0;
            dislikes.hasByUser = false;
          }

          if (type === ReactionType.DISLIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            dislikes.count += dislikes.hasByUser ? -1 : 1;
            dislikes.hasByUser = !dislikes.hasByUser;

            likes.count -= dislikes.hasByUser ? 1 : 0;
            likes.hasByUser = false;
          }
        }
      }
    });
    builder.addCase(closeQuestion.fulfilled, (state, { meta }) => {
      const questionId = meta.arg;

      state.list.questions = state.list.questions.filter(
        (item) => item.id !== questionId,
      );
    });
    builder.addCase(getAnswers.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<Answer[]>;
      state.current.answers = data;
    });
    builder.addCase(createAnswer.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<Answer>;

      const answer: Answer = {
        ...data,
        hasCurrentUserReaction: false,
        reactionCount: 0,
      };

      if (state.current.question) {
        state.current.question.answersCount += 1;
      }

      state.current.answers = [answer, ...state.current.answers];
    });
    builder.addCase(editAnswer.fulfilled, (state, { payload, meta }) => {
      const { id } = meta.arg;
      const { data } = payload as AxiosResponse<Answer>;

      if (state.current.answers) {
        const index = state.current.answers.findIndex(
          (answer) => answer.id === id,
        );

        if (index !== -1) {
          state.current.answers = [
            ...state.current.answers.slice(0, index),
            data,
            ...state.current.answers.slice(index + 1),
          ];
        }
      }
    });
    builder.addCase(getQuestionSubscriptions.pending, (state) => {
      state.subscriptions.loading = true;
      state.subscriptions.loaded = false;
    });
    builder.addCase(
      getQuestionSubscriptions.fulfilled,
      (state, { payload }) => {
        const { data } = payload as AxiosResponse<QuestionSubscription[]>;
        state.subscriptions.subscriptions = data;
        state.subscriptions.loading = false;
        state.subscriptions.loaded = true;
      },
    );
    builder.addCase(getQuestionSubscriptions.rejected, (state) => {
      state.subscriptions.loading = false;
      state.subscriptions.loaded = false;
    });
    builder.addCase(createQuestionSubscriptions.pending, (state) => {
      state.subscriptions.loading = true;
      state.subscriptions.loaded = false;
    });
    builder.addCase(
      createQuestionSubscriptions.fulfilled,
      (state, { payload }) => {
        const { data } = payload as AxiosResponse<QuestionSubscription[]>;
        state.subscriptions.subscriptions = data;
        state.subscriptions.loading = false;
        state.subscriptions.loaded = true;
      },
    );
    builder.addCase(createQuestionSubscriptions.rejected, (state) => {
      state.subscriptions.loading = false;
      state.subscriptions.loaded = false;
    });
    builder.addCase(acceptAnswer.pending, (state, action) => {
      const { questionId, answerId } = action.meta.arg;

      if (state.current.question?.id !== questionId) return;

      const target = state.current.answers.find(
        (answer) => answer.id === answerId,
      );

      if (target != null) {
        target.accepted = !target.accepted;
      }
    });
    builder.addCase(acceptAnswer.rejected, (state, action) => {
      const { questionId, answerId } = action.meta.arg;

      if (state.current.question?.id !== questionId) return;

      const target = state.current.answers.find(
        (answer) => answer.id === answerId,
      );

      if (target != null) {
        target.accepted = !target.accepted;
      }
    });
  },
});

const self = (state: RootState) => state.questions;

export const getQuestionsList = createSelector(
  self,
  (state) => state.list.questions,
);

export const isQuestionsListLoading = createSelector(
  self,
  (state) => state.list.loading,
);

export const hasMoreQuestionsList = createSelector(
  self,
  (state) => state.list.hasMore,
);

export const getCurrentQuestion = createSelector(
  self,
  (state) => state.current.question,
);

export const isCurrentQuestionLoading = createSelector(
  self,
  (state) => state.current.loading,
);

export const getCurrentAnswers = createSelector(
  self,
  (state) => state.current.answers,
);

export const getQuestionSubscriptionsList = createSelector(
  self,
  (state) => state.subscriptions.subscriptions,
);

export const isQuestionSubscriptionsLoading = createSelector(
  self,
  (state) => state.subscriptions.loading,
);

import React, { ReactNode } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import get from 'lodash/get';
import { HeadCell, StatsTableHead } from 'components/elements/table/TableHead';
import { getNodeText } from 'components/elements/table/utils';
import { styled } from '@mui/material';
import { StatsTableRow } from 'components/elements/table/TableRow';

const Title = styled('div')({
  color: ' #A9A9A9',
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '34px',
});

type Order = 'asc' | 'desc';

export interface DataItem {
  id: string;
  [key: string]: string | number | ReactNode;
  child?: DataItem[];
}

const descendingComparator = (a: DataItem, b: DataItem, orderBy: string) => {
  const getA = get(a, orderBy);
  const getB = get(b, orderBy);
  const valueA = typeof getA === 'object' ? getNodeText(getA) : getA;
  const valueB = typeof getB === 'object' ? getNodeText(getB) : getB;
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
};

const getComparator = (
  order: Order,
  orderBy: string,
): ((a: DataItem, b: DataItem) => number) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

type Props = {
  data: DataItem[];
  columns: HeadCell[];
  url?: string;
  query?: string;
  title?: string;
};
export const StatsTable = ({ data, columns, url, title, query }: Props) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer>
        {title && <Title>{title}</Title>}

        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <StatsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={columns}
          />
          <TableBody>
            {data
              .slice()
              .sort(getComparator(order, orderBy))
              .map((row) => {
                return (
                  <StatsTableRow
                    key={row.id}
                    row={row}
                    columns={columns}
                    url={url}
                    query={query}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

import React from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { ComplaintAboutTypeName, IComplaint } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';

const Root = styled('div')({
  padding: '8px 32px',
});

const Title = styled('h3')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
  margin: 0,
  padding: 0,
  fontWeight: '700',
  fontSize: 14,
  color: '#3d3d3d',
});

const Type = styled('p')({
  fontWeight: '500',
  fontSize: 16,
  color: '#666666',
});

const Author = styled('div')({});

const AuthorTitle = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 14,
  color: '#a9a9a9',
});

const AuthorValue = styled('p')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 14,
  color: '#000000',
});

const AuthorLabel = styled('span')({
  fontWeight: '400',
  fontSize: 14,
  color: '#666666',
});

type Props = {
  sx?: SxProps<Theme>;
  complaint: IComplaint;
  onFollowLink?: (url: string) => void;
};

export const ComplaintObject = ({
  sx = [],
  complaint,
  onFollowLink,
}: Props) => {
  const { t } = useTranslation();

  const {
    complaintAboutType,
    complaintAboutUser,
    complaintAboutAccount,
    pathToViewObject,
  } = complaint;

  const firstName = complaintAboutUser?.name || '';
  const lastName = complaintAboutUser?.lastName || '';
  const userName = `${firstName} ${lastName}`.trim();

  const account = complaintAboutAccount?.name || '';

  const type = t(
    `moderation.complaints.aboutType.${ComplaintAboutTypeName[complaintAboutType]}`,
  );

  const handleFollowLink = () => {
    pathToViewObject && onFollowLink?.(pathToViewObject);
  };

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Title sx={{ mb: 0.5 }}>
        {t('moderation.complaint.subheader.complaintObject')}
      </Title>
      <Type
        sx={
          pathToViewObject
            ? {
                mb: 2,
                color: '#004b7c',
                textDecoration: 'underline',
                cursor: 'pointer',
              }
            : { mb: 2 }
        }
        onClick={handleFollowLink}
      >
        {type}
      </Type>
      <Author>
        <AuthorTitle>{t('moderation.complaint.author')}:</AuthorTitle>
        <AuthorValue>
          <AuthorLabel>{t('user')}:</AuthorLabel>
          {userName}
        </AuthorValue>
        <AuthorValue>
          <AuthorLabel>{t('account')}:</AuthorLabel>
          {account}
        </AuthorValue>
      </Author>
    </Root>
  );
};

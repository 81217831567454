import { IComment, INews, ISubscription } from 'interfaces/News';
import { NewsFilters, ISubscriptionFilters } from 'interfaces/NewsFilters';
import { PAGE_SIZE } from 'interfaces/NewsFilters';

export enum ActionTypes {
  GET_NEWS = 'GET_NEWS',
  UPDATE_NEWS_LIST = 'UPDATE_NEWS_LIST',
  GET_IMPORTANT_NEWS = 'GET_IMPORTANT_NEWS',
  UPDATE_IMPORTANT_NEWS_LIST = 'UPDATE_IMPORTANT_NEWS_LIST',
  UPLOAD_ATTACHMENTS_NEWS = 'UPLOAD_ATTACHMENTS_NEWS',
  CREATE_NEW = 'CREATE_NEW',
  CREATE_COMMENT = 'CREATE_COMMENT',
  EDIT_COMMENT = 'EDIT_COMMENT',
  CHANGE_REACTION = 'CHANGE_REACTION',
  GET_COMMENTS = 'GET_COMMENTS',
  GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  DELETE_NEWS = 'DELETE_NEWS',
  DELETE_NEWS_COMMENT = 'DELETE_NEWS_COMMENT',
}

export interface INewsState {
  list: {
    news: INews[];
    filters: NewsFilters;
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
  comments: Record<
    string,
    {
      loading?: boolean;
      list?: IComment[];
    }
  >;
  // todo: возможно нужно вынести все подписки из новостей отдельно
  subscriptions: {
    list: ISubscription[];
    filters: ISubscriptionFilters;
    loading: boolean;
    loaded: boolean;
  };
}

export const initialNewsState: INewsState = {
  list: {
    news: [],
    filters: {
      offset: 0,
      limit: PAGE_SIZE,
    },
    loading: false,
    loaded: false,
    hasMore: false,
  },
  comments: {},
  subscriptions: {
    list: [],
    filters: {
      text: '',
    },
    loading: false,
    loaded: false,
  },
};

import { styled } from '@mui/system';
import { MENU_WIDTH } from 'services/theme';
import { Avatar } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: '20px 13px',
  borderRight: '1px solid #dddddd',
  width: MENU_WIDTH,
  background: theme.palette.background.paper,
}));

export const Divider = styled('div')({
  borderBottom: '1px solid #dddddd',
});

export const Title = styled('div')({
  margin: 0,
  padding: '0 24px',
  fontWeight: '700',
  fontSize: 18,
  color: '#3d3d3d',
});

export const Link = styled('div')({
  a: {
    padding: '0px 10px',
  },
});

export const SubTitle = styled('div')({
  padding: '0px 10px',
  height: '58px',
  lineHeight: '29px',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const AnswerItem = styled('div')({
  cursor: 'pointer',
  lineHeight: '29px',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
});

export const BusinessAvatar = styled(Avatar)({
  height: 40,
  width: 40,
  marginRight: 20,
});

export const Name = styled('h3')({
  display: 'flex',
  alignItems: 'flex-start',
  margin: 0,
  padding: 0,
  fontWeight: '700',
  color: '#00618e',
});

export const InfoHeader = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

export const Header = styled('div')({
  display: 'flex',
});

export const Content = styled('div')({
  lineHeight: 1,
});

import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { IPopularsStats } from 'interfaces/Stats';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';
import { StatsTable } from 'components/elements/table';
import {
  createData,
  createHeadCell,
} from 'components/features/stats/item/business/tables/populars/table/utils';

export const PopularsStats = ({ populars }: { populars: IPopularsStats }) => {
  const { t } = useTranslation();

  return (
    <StatsCollapseLayout title={t('tables.tablePopulars')}>
      <Grid item container>
        <StatsTable
          title={t('tables.offers')}
          data={createData(populars.offers)}
          columns={createHeadCell()}
        />
        <StatsTable
          title={t('tables.questions')}
          data={createData(populars.questions)}
          columns={createHeadCell()}
        />
      </Grid>
    </StatsCollapseLayout>
  );
};

import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { ReactComponent as BusinessLogo } from 'assets/icons/business.svg';
import { ReactComponent as QuestionsIcon } from 'assets/icons/questions.svg';
import { MenuItemLink } from 'components/elements/navigationMenu/menuItem';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { URLS } from 'defenitions/routes';
import { MENU_WIDTH } from 'services/theme';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getApplicationAreasDictionaries,
  getProgrammingLanguagesDictionaries,
  getQuestionCategoriesDictionaries,
  getQuestionSubcategoriesDictionaries,
} from 'store/features/dictionaries';
import {
  getDictionariesContentApplicationAreas,
  getDictionariesContentProgrammingLanguages,
  getDictionariesQuestionCategories,
  getDictionariesQuestionSubcategories,
} from 'store/features/dictionaries/actions';
import { QuestionsFilters } from 'interfaces/QuestionsFilters';
import { CollapseLayout } from 'components/elements/collapse';
import { Checkbox } from 'components/shared/checkbox';
import { map, uniq, without } from 'lodash';
import { useTranslation } from 'react-i18next';
import { MenuAd } from 'components/elements/ads';

const Container = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: '20px 13px',
  borderRight: '1px solid #dddddd',
  width: MENU_WIDTH,
  background: theme.palette.background.paper,
}));

const Divider = styled('div')({
  borderBottom: '1px solid #dddddd',
});

const Header = styled('h3')({
  margin: 0,
  padding: '0 24px',
  fontWeight: '700',
  fontSize: 18,
  color: '#3d3d3d',
});

const List = styled('ul')({
  width: '100%',
  margin: 0,
  padding: 0,
  listStyleType: 'none',
});

type ListItemProps = {
  selected?: boolean;
};

const ListItem = styled('li')<ListItemProps>(({ selected = false }) => ({
  width: '100%',
  margin: 0,
  borderRadius: '4px',
  padding: '8px 16px',
  fontWeight: '600',
  fontSize: '12px',
  color: selected ? '#000' : '#4b4b4b',
  backgroundColor: selected ? '#f5f5f5' : 'transparent',
  cursor: 'pointer',
}));

export type Filters = Pick<
  QuestionsFilters,
  'categoryId' | 'subcategoryIds' | 'programmingLanguages' | 'applicationAreas'
>;

type Props = {
  filters?: Filters;
  onChange?: (filters: Filters) => void;
  useFilters?: boolean;
};

export const NavigationMenu = ({
  filters = {},
  useFilters = false,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const categories = useAppSelector(getQuestionCategoriesDictionaries);
  const subcategories = useAppSelector(getQuestionSubcategoriesDictionaries);
  const applicationAreasCards = useAppSelector(getApplicationAreasDictionaries);

  const programmingLanguagesCards = useAppSelector(
    getProgrammingLanguagesDictionaries,
  );

  const categoryId = filters.categoryId ?? null;
  const subcategoryIds = filters.subcategoryIds ?? [];
  const programmingLanguages = filters.programmingLanguages ?? [];
  const applicationAreas = filters.applicationAreas ?? [];

  const handleChangeCategory = (id: string) => () => {
    onChange?.({
      categoryId: id,
      subcategoryIds: [],
      programmingLanguages,
      applicationAreas,
    });
  };

  const isCheckedSubcategory = (id: string) => {
    return subcategoryIds.includes(id);
  };

  const isCheckedSubcategoryCategory = (id: string) => {
    const subcategory = subcategories.find((s) => s.id === id);

    if (!subcategory) return;

    const ids = subcategory.children
      ? map(subcategory.children, 'id')
      : [subcategory.id];

    return ids.every((id) => subcategoryIds.includes(id));
  };

  const handleChangeSubcategory =
    (id: string) => (_: unknown, checked: boolean) => {
      const value = checked
        ? [...subcategoryIds, id]
        : subcategoryIds.filter((s) => s !== id);

      onChange?.({
        subcategoryIds: value,
        ...(categoryId ? { categoryId } : {}),
        programmingLanguages,
        applicationAreas,
      });
    };

  const handleChangeSubcategoryCategory =
    (id: string) => (_: unknown, checked: boolean) => {
      const subcategory = subcategories.find((s) => s.id === id);

      if (!subcategory) return;

      const ids = subcategory.children
        ? map(subcategory.children, 'id')
        : [subcategory.id];

      const value = checked
        ? uniq([...subcategoryIds, ...ids])
        : without(subcategoryIds, ...ids);

      onChange?.({
        subcategoryIds: value,
        ...(categoryId ? { categoryId } : {}),
        programmingLanguages,
        applicationAreas,
      });
    };

  const isCheckedProgrammingLanguage = (id: string) => {
    return programmingLanguages.includes(id);
  };

  const handleProgrammingLanguages =
    (id: string) => (_: unknown, checked: boolean) => {
      const value = checked
        ? [...programmingLanguages, id]
        : programmingLanguages.filter((s) => s !== id);

      onChange?.({
        programmingLanguages: value,
        ...(categoryId ? { categoryId } : {}),
        subcategoryIds,
        applicationAreas,
      });
    };

  const isCheckedApplicationArea = (id: string) => {
    return applicationAreas.includes(id);
  };

  const handleApplicationAreas =
    (id: string) => (_: unknown, checked: boolean) => {
      const value = checked
        ? [...applicationAreas, id]
        : applicationAreas.filter((s) => s !== id);

      onChange?.({
        applicationAreas: value,
        ...(categoryId ? { categoryId } : {}),
        subcategoryIds,
        programmingLanguages,
      });
    };

  useEffect(() => {
    dispatch(getDictionariesQuestionCategories());
    dispatch(getDictionariesContentProgrammingLanguages());
    dispatch(getDictionariesContentApplicationAreas());
  }, [dispatch]);

  useEffect(() => {
    const category = categories.find((c) => c.id === categoryId);
    const directory = category?.childDirectory;
    if (!directory) return;
    dispatch(getDictionariesQuestionSubcategories({ directory }));
  }, [categories, categoryId, dispatch]);

  return (
    <Container>
      <Header>{t('questionsSoftware')}</Header>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <MenuItemLink
        link={URLS.myQuestions}
        logo={<QuestionsIcon />}
        title={'Исходящие вопросы'}
      />
      <MenuItemLink
        link={URLS.incomingQuestions}
        logo={<BusinessLogo />}
        title={'Входящие вопросы'}
      />
      <MenuItemLink
        link={URLS.questions}
        logo={<BusinessLogo />}
        title={'Актуальные вопросы'}
      />
      <MenuItemLink
        link={URLS.questionSubscriptions}
        logo={<BusinessLogo />}
        title={t('questions.subscriptions')}
      />
      <CreateButton
        fullWidth
        withPlusIcon
        style={{ marginTop: 18 }}
        text={'Создать вопрос'}
        onClick={() => navigate(URLS.createQuestion)}
      />
      <Divider sx={{ mt: '18px', mb: useFilters ? '18px' : 0 }} />

      {useFilters && (
        <>
          <CollapseLayout
            style={{ paddingLeft: 8 }}
            title={'Категории вопросов'}
          >
            <List>
              {categories.map((item) => (
                <ListItem
                  key={item.id}
                  selected={item.id === categoryId}
                  onClick={handleChangeCategory(item.id)}
                >
                  {item.text.ru ?? item.text.en}
                </ListItem>
              ))}
            </List>
          </CollapseLayout>

          {categoryId && !!subcategories.length && (
            <CollapseLayout
              style={{
                marginTop: '24px',
                paddingLeft: 8,
              }}
              title={'Подкатегории вопросов'}
            >
              <List>
                {subcategories.map((item) => (
                  <>
                    <ListItem key={item.id} sx={{ p: 0 }}>
                      <Checkbox
                        checked={isCheckedSubcategoryCategory(item.id)}
                        label={item.text.ru ?? item.text.en}
                        onChange={handleChangeSubcategoryCategory(item.id)}
                      />
                    </ListItem>

                    {!!item.children?.length && (
                      <List sx={{ pl: 2 }}>
                        {item.children.map((item) => (
                          <ListItem key={item.id} sx={{ padding: 0 }}>
                            <Checkbox
                              checked={isCheckedSubcategory(item.id)}
                              label={item.text.ru ?? item.text.en}
                              onChange={handleChangeSubcategory(item.id)}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </>
                ))}
              </List>
            </CollapseLayout>
          )}

          {!!programmingLanguagesCards.length && (
            <CollapseLayout
              style={{
                marginTop: '24px',
                paddingLeft: 8,
              }}
              title={'Языки программирования'}
            >
              <List>
                {programmingLanguagesCards.map((item) => (
                  <ListItem key={item.code} sx={{ p: 0 }}>
                    <Checkbox
                      checked={isCheckedProgrammingLanguage(item.code)}
                      label={item.display.ru ?? item.display.en}
                      onChange={handleProgrammingLanguages(item.code)}
                    />
                  </ListItem>
                ))}
              </List>
            </CollapseLayout>
          )}

          {!!applicationAreasCards.length && (
            <CollapseLayout
              style={{
                marginTop: '24px',
                paddingLeft: 8,
              }}
              title={'Области применения '}
            >
              <List>
                {applicationAreasCards.map((item) => (
                  <ListItem key={item.code} sx={{ p: 0 }}>
                    <Checkbox
                      checked={isCheckedApplicationArea(item.code)}
                      label={item.display.ru ?? item.display.en}
                      onChange={handleApplicationAreas(item.code)}
                    />
                  </ListItem>
                ))}
              </List>
            </CollapseLayout>
          )}
        </>
      )}
      <MenuAd />
    </Container>
  );
};

import React, { CSSProperties } from 'react';
import { styled } from '@mui/material/styles';
import { attachUrl } from 'services/ApiClient';
import Avatar from '@mui/material/Avatar';

const ImageContainer = styled('div')({
  border: '1px solid #A9A9A9',
  borderRadius: 5,
  padding: 5,
});

type Props = {
  url?: string;
  style?: CSSProperties;
  emptyText?: string;
};

export const ViewImage = ({ url, style, emptyText }: Props) => {
  const avatarUrl = url && attachUrl + url;

  return (
    <ImageContainer style={style}>
      <Avatar
        sx={{ width: '100%', height: '100%' }}
        src={avatarUrl}
        variant="square"
      >
        {!url && emptyText}
      </Avatar>
    </ImageContainer>
  );
};

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import {
  initialSettingsState,
  ISettingsState,
} from 'store/features/settings/config';
import { RootState } from 'store/index';
import {
  changeLocale,
  changeTimeZone,
  getSettings,
  getTimeZones,
  getNotificationSettings,
  changeNotificationSettings,
} from 'store/features/settings/actions';
import { AxiosResponse } from 'axios';
import { LocaleBook, TimeZoneBook } from 'interfaces/Locale';
import { INotificationSettingsItem } from 'interfaces/Notifications';
import { normalizeNotificationSettings } from 'utils/tree';

const settingsSlice = createSlice({
  name: Slices.settings,
  initialState: initialSettingsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSettings.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<ISettingsState>;
      state.locale = data?.locale;
      state.timeZone = data?.timeZone;
    });
    builder.addCase(changeLocale.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<LocaleBook>;
      state.locale = data.id;
    });
    builder.addCase(changeTimeZone.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<TimeZoneBook>;
      state.timeZone = data.id;
    });
    builder.addCase(getTimeZones.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<TimeZoneBook[]>;
      state.timeZones = data;
    });
    builder.addCase(getNotificationSettings.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<INotificationSettingsItem[]>;
      state.notifications = normalizeNotificationSettings(data);
    });
    builder.addCase(
      changeNotificationSettings.fulfilled,
      (state, { payload }) => {
        const { data } = payload as AxiosResponse<INotificationSettingsItem[]>;
        state.notifications = data;
      },
    );
  },
});

export { settingsSlice };
const self = (state: RootState) => state.settings;
export const getLocale = createSelector(self, (state) => state.locale);
export const getTimeZone = createSelector(self, (state) => state.timeZone);
export const getTimeZonesAvailable = createSelector(
  self,
  (state) => state.timeZones,
);
export const getNotifications = createSelector(
  self,
  (state) => state.notifications,
);

import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import { IUser, Roles, Status } from 'interfaces/Users';
import Avatar from '@mui/material/Avatar';
import { attachUrl } from 'services/ApiClient';
import { getNameInitials } from 'utils/user';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { LastLoginText } from 'components/features/home/components';
import { format } from 'date-fns';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { ViewRole } from 'components/elements/viewFields/Role';
import { URLS } from 'defenitions/routes';
import { useNavigate } from 'react-router-dom';

const Container = styled(Grid)({
  borderTop: '1px solid #E4E4E4',
  padding: '7px 40px',
  position: 'relative',
});

const Name = styled('div')({
  fontWeight: '500',
  fontSize: 14,
  lineHeight: '17px',
});

const ValueTitle = styled('div')({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
  color: '#666666',
});

const LinkValue = styled(Link)({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
  color: '#004B7C',
  textDecoration: 'none',
});

const Value = styled('div')({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
});

const StatusValue = styled('div')<{ isBlocked: boolean }>(
  ({ isBlocked = false }) => ({
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: isBlocked ? '#E84A4A' : '#4B4B4B',
  }),
);

type Props = {
  user: IUser;
  viewByOwner?: boolean;
  viewByAdmin?: boolean;
  isCurrentUser?: boolean;
  onClickEdit?: () => void;
  onClickLock: () => void;
  onClickUnlock: () => void;
  onClickDelete?: () => void;
  onClickInvite?: () => void;
  onClickStats?: () => void;
  viewBySystem?: boolean;
};

export const UserItem = ({
  user,
  onClickEdit,
  viewByOwner,
  viewByAdmin,
  isCurrentUser,
  onClickLock,
  onClickUnlock,
  onClickDelete,
  onClickInvite,
  onClickStats,
  viewBySystem = false,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const avatarUrl = user.logo?.path && attachUrl + user.logo.path;
  const emptyText = getNameInitials(`${user.name} ${user.lastName}`);
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const onClickOpenConfirm = () => setIsOpenConfirm(true);
  const onClickCloseConfirm = () => setIsOpenConfirm(false);
  const handleClickDelete = () => {
    onClickDelete && onClickDelete();
    onClickCloseConfirm();
  };

  /*const showMoreIcon =
    ((isCurrentUser ||
      ((viewByAdmin || viewByOwner) &&
        !isCurrentUser &&
        user.status === Status.ACTIVE &&
        user.role !== Roles.OWNER) ||
      ((viewByAdmin || viewByOwner) &&
        !isCurrentUser &&
        user.status === Status.BLOCKED &&
        user.role !== Roles.OWNER) ||
      ((viewByAdmin || viewByOwner) &&
        user.status === Status.CONFIRMING &&
        !isCurrentUser) ||
      (viewByOwner && !isCurrentUser)) &&
      user.status !== Status.DELETED) ||
    (viewBySystem &&
      (user.status === Status.BLOCKED || user.status === Status.ACTIVE)) ||
    (user.status !== Status.CONFIRMING && viewByOwner);*/

  const handleClickView = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      navigate(`${URLS.users}/${user.id}`);
    },
    [user.id, navigate],
  );

  return (
    <Container container onMouseLeave={() => setAnchorEl(null)}>
      <Grid item>
        <Avatar src={avatarUrl} sx={{ width: 55, height: 55 }}>
          {!user.logo && emptyText}
        </Avatar>
      </Grid>
      <Grid item container direction={'column'} xs paddingLeft={3}>
        <Grid item xs>
          <Name>{`${user.name} ${user.lastName}`}</Name>
        </Grid>
        <Grid item xs container>
          <ViewRole role={user.role} />
        </Grid>
        {(viewBySystem || user?.account) && (
          <Grid item xs container paddingTop={1}>
            <Grid item sx={{ minWidth: 160, alignSelf: 'center' }}>
              <ValueTitle>{t('account')}:</ValueTitle>
            </Grid>
            <Grid item xs>
              <Value>{user.account?.name}</Value>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs
          container
          paddingTop={viewBySystem || user?.account ? 0 : 1}
        >
          <Grid item sx={{ minWidth: 160, alignSelf: 'center' }}>
            <ValueTitle>{t('email')}</ValueTitle>
          </Grid>
          <Grid item xs>
            <LinkValue href={`mailto:${user.id}`}>{user.id}</LinkValue>
          </Grid>
        </Grid>
        <Grid item xs container>
          <Grid item sx={{ minWidth: 160, alignSelf: 'center' }}>
            <ValueTitle>{t('status')}</ValueTitle>
          </Grid>
          <Grid item xs>
            <StatusValue isBlocked>{t(`statuses.${user.status}`)}</StatusValue>
          </Grid>
        </Grid>
        <Grid item xs container paddingBottom={1}>
          <Grid item sx={{ minWidth: 160, alignSelf: 'center' }}>
            <ValueTitle>{t('reputation')}:</ValueTitle>
          </Grid>
          <Grid item xs>
            <Value>{user?.reputation}</Value>
          </Grid>
        </Grid>
        {!!user?.lastLoggedIn && (
          <LastLoginText>
            {`${t(`lastLoggedIn`)} ${format(user?.lastLoggedIn, 'dd.MM.yyyy')}`}
          </LastLoginText>
        )}
      </Grid>

      <MoreIcon
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      />

      {anchorEl && (
        <MoreMenu
          anchorEl={anchorEl}
          onClickEdit={isCurrentUser ? onClickEdit : undefined}
          onClickView={handleClickView}
          onClickLock={
            ((viewByAdmin || viewByOwner) &&
              !isCurrentUser &&
              user.status === Status.ACTIVE &&
              user.role !== Roles.OWNER) ||
            (user.status === Status.ACTIVE && viewBySystem)
              ? () => {
                  onClickLock();
                  setAnchorEl(null);
                }
              : undefined
          }
          onClickUnlock={
            ((viewByAdmin || viewByOwner) &&
              !isCurrentUser &&
              user.status === Status.BLOCKED &&
              user.role !== Roles.OWNER) ||
            (user.status === Status.BLOCKED && viewBySystem)
              ? () => {
                  onClickUnlock();
                  setAnchorEl(null);
                }
              : undefined
          }
          onClickInvite={
            (viewByAdmin || viewByOwner) &&
            user.status === Status.CONFIRMING &&
            !isCurrentUser
              ? () => {
                  onClickInvite && onClickInvite();
                  setAnchorEl(null);
                }
              : undefined
          }
          onClickDelete={
            viewByOwner && !isCurrentUser && user.status === Status.BLOCKED
              ? () => {
                  onClickOpenConfirm();
                  setAnchorEl(null);
                }
              : undefined
          }
          onClickStats={
            viewByOwner && user.status !== Status.CONFIRMING
              ? () => {
                  onClickStats && onClickStats();
                  setAnchorEl(null);
                }
              : undefined
          }
        />
      )}
      <ConfirmDialog
        onClickAccept={handleClickDelete}
        onClickClose={onClickCloseConfirm}
        open={isOpenConfirm}
        actionTitle={t('modalTexts.users.actionTitle')}
        text={t('modalTexts.users.text', {
          name: `${user.name} ${user.lastName}`,
        })}
        title={t('modalTexts.users.title')}
      />
    </Container>
  );
};

import React, { useEffect, useState } from 'react';
import { getDictionariesCardType } from 'store/features/dictionaries/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { getCardTypeDictionaries } from 'store/features/dictionaries';
import { Loader } from 'components/shared/loader';
import { Checkbox } from 'components/shared/checkbox';
import { changeFilters, getBusinessFilters } from 'store/features/business';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { ReactComponent as MemberIcon } from 'assets/icons/memberIcon.svg';
import { ReactComponent as ProviderIcon } from 'assets/icons/providerIcon.svg';
import { IDictionaries } from 'interfaces/Dictionaries';

const Title = styled('div')({
  color: '#4B4B4B',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
  display: 'flex',
  alignItems: 'center',
});

export const BusinessCardCategories = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(getBusinessFilters);
  const cardTypes = useAppSelector(getCardTypeDictionaries);
  const [cardTypesFilter, setCardTypesFilter] = useState<string[]>(
    filters.cardTypes,
  );
  const isLoading = !cardTypes.length;
  useEffect(() => {
    isLoading && dispatch(getDictionariesCardType());
  }, [isLoading, dispatch]);

  const handleChangeFilter = (id: string) => {
    const newCardTypeFilter = cardTypesFilter.includes(id)
      ? cardTypesFilter.filter((el) => el !== id)
      : [...cardTypesFilter, id];
    setCardTypesFilter(newCardTypeFilter);
    dispatch(
      changeFilters({ ...filters, cardTypes: newCardTypeFilter, offset: 0 }),
    );
  };

  const getAllChecked = (array: IDictionaries[] | undefined) => {
    let checked = 0;
    array?.forEach((el) => {
      if (cardTypesFilter.includes(el.id)) checked += 1;
    });
    return array?.length === checked;
  };

  useEffect(() => {
    setCardTypesFilter(filters.cardTypes);
  }, [filters.cardTypes]);

  return (
    <div style={{ paddingLeft: 5 }}>
      {isLoading && <Loader show />}
      {cardTypes.map((el) => (
        <Grid container key={el.id} direction={'column'}>
          <Title>
            {el.id === '1' ? (
              <MemberIcon style={{ marginRight: 10 }} />
            ) : (
              <ProviderIcon style={{ marginRight: 10 }} />
            )}
            <Checkbox
              label={el.text.ru}
              checked={
                cardTypesFilter.includes(el.id) || getAllChecked(el.children)
              }
              onChange={() => handleChangeFilter(el.id)}
            />
          </Title>
          {el.children?.map((subEl) => (
            <Grid item xs={12} key={subEl.id} style={{ paddingLeft: 55 }}>
              <Checkbox
                label={subEl.text.ru}
                checked={
                  cardTypesFilter.includes(subEl.id) ||
                  cardTypesFilter.includes(subEl.id.slice(0, 1))
                }
                onChange={() => handleChangeFilter(subEl.id)}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </div>
  );
};

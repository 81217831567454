import React, { useEffect, useMemo, useRef, useState } from 'react';
import qs from 'qs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, useTheme } from '@mui/material';
import { useQuery } from 'hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { search } from 'store/features/search/actions';
import { SearchFilters, SearchMatchType } from 'interfaces/Search';
import { Loader } from 'components/shared/loader';
import { isEqual, pick } from 'lodash';
import {
  clear,
  getSearchMatchesHasMore,
  getSearchMatchesList,
  getSearchMatchesLoading,
} from 'store/features/search';
import {
  Filters,
  Header,
  MatchCard,
  SearchLayout,
  SectionsFilters,
} from './components';
import { CollapseLayout } from 'components/elements/collapse';
import { NonNullableRecord } from 'utils/types';
import { ListFilters } from 'interfaces/Filters';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { SearchAd } from 'components/elements/ads';

const MIN_SEARCH_LENGTH = 3;

const useList = () => {
  const [filters, setFilters] = useState<{ offset: number; limit: number }>({
    offset: 0,
    limit: 10,
  });

  const next = () =>
    setFilters((prev) => ({
      offset: prev.offset + prev.limit,
      limit: prev.limit,
    }));

  const reset = () => setFilters({ offset: 0, limit: 10 });

  return { offset: filters.offset, limit: filters.limit, next, reset };
};

type Filters = Omit<
  NonNullableRecord<SearchFilters>,
  'fromDate' | 'toDate' | keyof ListFilters
> &
  Pick<SearchFilters, 'fromDate' | 'toDate'>;

export const GlobalSearch = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const list = useList();
  const query = useQuery();

  const filters = useMemo(
    () =>
      ({
        accountIds: query.accountIds ?? [],
        businessCardIds: query.businessCardIds ?? [],
        text: query.text ?? '',
        types: query.types ?? [],
        fromDate: parseInt(query.fromDate as string) || null,
        toDate: parseInt(query.toDate as string) || null,
      } as Filters),
    [
      query.accountIds,
      query.businessCardIds,
      query.fromDate,
      query.text,
      query.toDate,
      query.types,
    ],
  );

  const dispatch = useAppDispatch();
  const matches = useAppSelector(getSearchMatchesList);
  const loading = useAppSelector(getSearchMatchesLoading);
  const hasMore = useAppSelector(getSearchMatchesHasMore);

  const handleFollowObject = (url: string) => {
    navigate(url);
  };

  const handleSearch = (text: string) => {
    list.reset();
    navigate({
      search: qs.stringify(
        { ...filters, text: text || null },
        { skipNulls: true },
      ),
    });
  };

  const handleTypesChange = (types: SearchMatchType[]) => {
    list.reset();
    navigate({
      search: qs.stringify(
        { ...filters, text: filters.text || null, types },
        { skipNulls: true },
      ),
    });
  };

  const handleFiltersChange = (
    value: Pick<
      Filters,
      'accountIds' | 'businessCardIds' | 'fromDate' | 'toDate'
    >,
  ) => {
    list.reset();
    navigate({
      search: qs.stringify(
        { ...filters, text: filters.text || null, ...value },
        { skipNulls: true },
      ),
    });
  };

  const prevFilters = useRef<SearchFilters>({});

  useEffect(() => {
    const current = {
      offset: list.offset,
      limit: list.limit,
      text: filters.text,
      types: filters.types,
    };

    if (isEqual(prevFilters, current)) {
      return;
    }

    if (filters.text.length === 0) {
      dispatch(clear());
    }

    if (filters.text.length >= MIN_SEARCH_LENGTH) {
      dispatch(
        search({
          offset: list.offset,
          limit: list.limit,
          accountIds: filters.accountIds,
          businessCardIds: filters.businessCardIds,
          text: filters.text,
          types: filters.types,
          fromDate: filters.fromDate,
          toDate: filters.toDate,
        }),
      );
    }
  }, [dispatch, filters, list.limit, list.offset]);

  return (
    <SearchLayout
      defaultSearchValue={filters.text}
      autoFocusSearch
      onSearch={handleSearch}
      bottomSideMenu={
        <CollapseLayout
          style={{
            marginTop: theme.spacing(3),
            borderTop: '1px solid #ddd',
            paddingTop: theme.spacing(3),
          }}
          title={'Разделы'}
          expandDefault
        >
          <SectionsFilters
            sx={{ p: 0 }}
            types={filters.types}
            onChange={handleTypesChange}
          />
        </CollapseLayout>
      }
    >
      <Header
        sx={{ mb: 3 }}
        match={filters.text}
        empty={!loading && !matches.length}
      >
        <Filters
          sx={{ mt: 3 }}
          filters={pick(filters, [
            'accountIds',
            'businessCardIds',
            'fromDate',
            'toDate',
          ])}
          onChange={handleFiltersChange}
        />
      </Header>
      <InfiniteScroll
        style={{ overflow: 'hidden' }}
        dataLength={matches.length}
        hasMore={hasMore}
        next={list.next}
        loader={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader show />
          </Box>
        }
        scrollableTarget={SCROLLABLE_REFERENCE_ID}
      >
        {matches.map((match, index) => (
          <MatchCard
            sx={{ mt: index === 0 ? 0 : 2 }}
            key={index}
            match={match}
            onFollowObject={handleFollowObject}
          />
        ))}
      </InfiniteScroll>
      <SearchAd />
    </SearchLayout>
  );
};

import React, { useState } from 'react';
import { HomeLayout } from 'components/features/home/components/layout';
import { ViewField } from 'components/elements/viewFields/ViewField';
import {
  Container,
  Divider,
  Title,
  TitleContainer,
  LastLoginText,
  LastLoginTextContainer,
  ContentContainer,
} from 'components/features/home/components';
import { RouteNames, URLS } from 'defenitions/routes';
import { getCurrent } from 'store/features/users';
import { useAppSelector } from 'store';
import { useTranslation } from 'react-i18next';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { ViewImage } from 'components/elements/viewFields/avatar';
import { format } from 'date-fns';
import { getNameInitials } from 'utils/user';
import { useNavigate } from 'react-router-dom';
import { Roles } from 'interfaces/Users';

export const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useAppSelector(getCurrent);
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const onClickEdit = () => {
    navigate(`${URLS.users}/${profile?.id}/edit`);
  };

  const emptyText = getNameInitials(`${profile?.name} ${profile?.lastName}`);

  const onClickStats = () => {
    navigate(`${URLS.stats}/user/${profile?.id}`);
  };

  return (
    <HomeLayout>
      <Container item container>
        <TitleContainer onMouseLeave={() => setAnchorEl(null)}>
          <Title>{RouteNames[URLS.profile]}</Title>
          <MoreIcon
            style={{ top: 'auto', right: 'auto', position: 'relative' }}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          />
          {anchorEl && (
            <MoreMenu
              anchorEl={anchorEl}
              onClickEdit={onClickEdit}
              onClickStats={
                profile?.role === Roles.OWNER ? onClickStats : undefined
              }
            />
          )}
        </TitleContainer>
        <Divider />
        <ContentContainer item container>
          <ViewField
            sx={{ width: '70%', paddingRight: 3 }}
            title={'Профиль'}
            fields={[
              { field: 'Имя:', value: profile?.name },
              { field: 'Фамилия:', value: profile?.lastName },
              { field: 'Роль:', value: t(`roles.${profile?.role}`) },
              {
                field: 'Электронная почта:',
                value: profile?.id,
                isEmail: true,
              },
              {
                field: 'Репутация:',
                value: profile?.reputation?.toString(),
              },
            ]}
          />
          <ViewImage
            style={{ display: 'flex', width: '30%', marginTop: 20 }}
            url={profile?.logo?.path}
            emptyText={emptyText}
          />
          {profile?.lastLoggedIn && (
            <LastLoginTextContainer>
              <LastLoginText>
                {`${t(`lastLoggedIn`)} ${format(
                  profile?.lastLoggedIn,
                  'dd.MM.yyyy',
                )}`}
              </LastLoginText>
            </LastLoginTextContainer>
          )}
        </ContentContainer>
      </Container>
    </HomeLayout>
  );
};

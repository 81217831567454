import React from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { TRatingsFilters } from 'interfaces/Complaint';
import { AccountsFilter } from './AccountsFilter';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 16,
  padding: '8px 40px',
  backgroundColor: theme.palette.background.paper,
}));

type Filters = Pick<TRatingsFilters, 'accountIds'>;

type Props = {
  sx?: SxProps<Theme>;
  filters?: Filters;
  onChange?: (value: Filters) => void;
};

export const RatingsFilters = ({ sx = [], filters = {}, onChange }: Props) => {
  const handleAccountsChange = (value: string[]) =>
    onChange?.({ ...filters, accountIds: value });

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <AccountsFilter
        value={filters.accountIds}
        onChange={handleAccountsChange}
      />
    </Root>
  );
};

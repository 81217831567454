import React, { ReactNode } from 'react';
import { Container } from 'components/features/home/components';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'store';
import {
  selectUsersStats,
  selectUsersStatsLoading,
} from 'store/features/stats';
import { Loader } from 'components/shared/loader';
import { useTranslation } from 'react-i18next';
import {
  createData,
  createHeadCell,
} from 'components/features/stats/list/users/table/utils';
import { StatsTable } from 'components/elements/table';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';

type Props = {
  filters?: ReactNode;
  query?: string;
};

export const UsersStats = ({ filters, query }: Props) => {
  const { t } = useTranslation();
  const usersStats = useAppSelector(selectUsersStats);
  const isUsersStatsLoading = useAppSelector(selectUsersStatsLoading);

  return (
    <Container item container mt={3}>
      <StatsCollapseLayout title={t('tables.tableUsersName')}>
        <Grid item container>
          {filters && (
            <Grid item xs={12}>
              {filters}
            </Grid>
          )}
          {isUsersStatsLoading && <Loader show global />}
          <StatsTable
            data={createData(usersStats)}
            columns={createHeadCell()}
            url={'user'}
            query={query}
          />
        </Grid>
      </StatsCollapseLayout>
    </Container>
  );
};

import React, { useState, MouseEvent } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { HeadCell } from 'components/elements/table/TableHead';
import { URLS } from 'defenitions/routes';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DataItem } from 'components/elements/table/index';
import Collapse from '@mui/material/Collapse';
import { getNodeText } from 'components/elements/table/utils';

type Props = {
  row: DataItem;
  columns: HeadCell[];
  url?: string;
  childCount?: number;
  childOpen?: boolean;
  query?: string;
};
export const StatsTableRow = ({
  row,
  url,
  columns,
  childCount = 1,
  childOpen,
  query,
}: Props) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(!open);
  };
  const navigate = useNavigate();

  const handleClickView = (id: string) =>
    url && navigate(`${URLS.stats}/${url}/${id}${query ? `?${query}` : ''}`);

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={row.id}
        sx={{
          cursor: 'pointer',
          background: childCount !== 1 ? '#F0F2F5' : 'none',
        }}
        onClick={() => handleClickView(row.id)}
      >
        {columns.map((item, index) => (
          <TableCell
            key={index}
            align={item.numeric ? 'right' : 'left'}
            style={{
              paddingLeft:
                item.withOpen && !!row.child?.length
                  ? childCount * 16
                  : childCount * 16 + 30,
              paddingTop: childCount === 1 ? 16 : childOpen ? 16 : 0,
              paddingBottom: childCount === 1 ? 16 : childOpen ? 16 : 0,
              borderBottom:
                childCount === 1
                  ? '1px solid #DDDDDD'
                  : childOpen
                  ? '1px solid #DDDDDD'
                  : 'none',
              transition: 'padding .3s linear',
            }}
          >
            <Collapse in={childCount === 1 ? true : childOpen} unmountOnExit>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: item.numeric ? 'end' : 'start',
                }}
              >
                {item.withOpen && !!row.child?.length && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={handleClickOpen}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                )}
                <div
                  style={{
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    '-webkit-line-clamp': '3',
                    overflow: 'hidden',
                  }}
                  title={getNodeText(row[item.id])}
                >
                  {row[item.id]}
                </div>
              </div>
            </Collapse>
          </TableCell>
        ))}
      </TableRow>
      {row.child?.map((el) => {
        const localCount = 1;
        return (
          <>
            <StatsTableRow
              key={el.id}
              row={el}
              columns={columns}
              childCount={localCount + childCount}
              childOpen={open}
            />
          </>
        );
      })}
      {/*{row.child?.map((el) => (
        <TableRow key={el.id} style={{ background: '#F0F2F5' }}>
          {columns.map((item, index) => (
            <TableCell
              key={index}
              align={item.numeric ? 'right' : 'left'}
              style={{
                paddingTop: open ? 16 : 0,
                paddingBottom: open ? 16 : 0,
                paddingLeft: index === 0 ? 60 : 16,
                borderBottom: open ? '1px solid #DDDDDD' : 'none',
              }}
            >
              <Collapse in={open} unmountOnExit>
                <div
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: 200,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {el[item.id]}
                </div>
              </Collapse>
            </TableCell>
          ))}
        </TableRow>
      ))}*/}
    </>
  );
};

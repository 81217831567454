import { SearchMatch } from 'interfaces/Search';

export enum ActionTypes {
  SEARCH = 'SEARCH',
  CLEAR = 'CLEAR',
}

export interface ISearchState {
  matches: {
    list: SearchMatch[];
    loading: boolean;
    hasMore: boolean;
  };
}

export const initialState: ISearchState = {
  matches: {
    list: [],
    loading: false,
    hasMore: false,
  },
};

import React, {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  InputBase,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { useDebounce } from 'utils/hooks';

const Root = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: 40,
  border: '1px solid #e4e4e4',
  borderRadius: 4,
  padding: '2px 4px',
  backgroundColor: '#f5f5f5',
});

const defaultValidate = (value: string) => {
  return value.length > 2;
};

export type SearchRef = {
  focus: () => void;
  blur: () => void;
  clear: () => void;
};

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  delay?: number;
  defaultValue?: string | null;
  placeholder?: string;
  autoFocus?: boolean;
  withClearButton?: boolean;
  onTextChange?: (value: string) => void;
  onEnter?: (value: string) => void;
  validate?: (value: string) => boolean;
};

export const Search = forwardRef<SearchRef, Props>(
  (
    {
      className,
      sx = [],
      delay = 500,
      defaultValue,
      placeholder,
      autoFocus = false,
      withClearButton = false,
      onTextChange,
      onEnter,
      validate,
    },
    ref,
  ) => {
    const [search, setSearch] = useState(defaultValue || '');

    const debounced = useDebounce(search, delay);

    const input = useRef<HTMLInputElement>(null);

    const prev = useRef(debounced);

    const safeValidate = validate ?? defaultValidate;

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearch(value);
    };

    const handleClear = () => setSearch('');

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onEnter?.(search);
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () => input.current?.focus(),
      blur: () => input.current?.blur(),
      clear: () => handleClear(),
    }));

    useEffect(() => {
      const isEmpty = debounced.length === 0;
      const isValid = safeValidate(debounced);

      if (prev.current === debounced) return;

      if (isEmpty || isValid) {
        prev.current = debounced;
        onTextChange?.(debounced);
      }
    }, [debounced, onTextChange, safeValidate]);

    return (
      <Root className={className} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
        <SearchIcon sx={{ color: '#a9a9a9' }} />
        <InputBase
          sx={{ ml: 0.5, flex: 1 }}
          autoFocus={autoFocus}
          placeholder={placeholder}
          value={search}
          inputRef={input}
          inputProps={{
            style: { height: 30, padding: 0 },
            'aria-label': 'search',
          }}
          onChange={handleTextChange}
          onKeyPress={handleKeyPress}
        />
        {search && withClearButton && (
          <IconButton size="small" onClick={handleClear}>
            <ClearIcon sx={{ color: '#a9a9a9' }} />
          </IconButton>
        )}
      </Root>
    );
  },
);

Search.displayName = 'Search';

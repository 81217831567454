import { styled } from '@mui/material';

export const CreateButton = styled('button')(({ theme }) => ({
  border: 0,
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
  fontWeight: 500,
  fontSize: 10,
  lineHeight: '12px',
  color: '#00618e',
  textDecoration: 'underline',
}));

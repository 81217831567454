import React from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/system';
import Link from '@mui/material/Link';
import { ReactComponent as moreIcon } from 'assets/icons/menuIcon.svg';
import { useTranslation } from 'react-i18next';

export const MoreIcon = styled(moreIcon)({
  position: 'absolute',
  right: 16,
  top: 12,
  cursor: 'pointer',
});

export const MoreText = styled(Link)({
  color: '#00618E',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '17px',
  cursor: 'pointer',
  textDecoration: 'none',
  padding: '8px 0px',
});

type MoreMenuProps = {
  anchorEl: SVGElement | null;
  onClickAway?: () => void;
  onClickView?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickViewQuestions?: () => void;
  onClickCreateQuestion?: () => void;
  onClickSubscribe?: () => void;
  onClickUnsubscribe?: () => void;
  onClickLock?: () => void;
  onClickUnlock?: () => void;
  onClickInvite?: () => void;
  onClickComplaint?: () => void;
  onClickStats?: () => void;
  onClickShow?: () => void;
  onClickHide?: () => void;
  onClickReview?: () => void;
  onClickCreateRequest?: () => void;
};
export const MoreMenu = ({
  anchorEl,
  onClickAway,
  onClickView,
  onClickEdit,
  onClickDelete,
  onClickViewQuestions,
  onClickCreateQuestion,
  onClickSubscribe,
  onClickUnsubscribe,
  onClickLock,
  onClickUnlock,
  onClickInvite,
  onClickComplaint,
  onClickStats,
  onClickHide,
  onClickShow,
  onClickReview,
  onClickCreateRequest,
}: MoreMenuProps) => {
  const { t } = useTranslation();

  const handleClickAway = () => onClickAway?.();

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement={'bottom-end'}
        style={{
          background: '#FFFFFF',
          border: '1px solid #DDDDDD',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          padding: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {onClickView && (
          <MoreText onClick={(e) => onClickView(e)}>
            {t('actions.view')}
          </MoreText>
        )}
        {onClickEdit && (
          <MoreText onClick={onClickEdit}>{t('actions.edit')}</MoreText>
        )}
        {onClickViewQuestions && (
          <MoreText onClick={onClickViewQuestions}>Вопросы</MoreText>
        )}
        {onClickCreateQuestion && (
          <MoreText onClick={onClickCreateQuestion}>Задать вопрос</MoreText>
        )}
        {onClickSubscribe && (
          <MoreText onClick={onClickSubscribe}>Подписаться</MoreText>
        )}
        {onClickUnsubscribe && (
          <MoreText onClick={onClickUnsubscribe}>Отписаться</MoreText>
        )}
        {onClickLock && (
          <MoreText onClick={onClickLock}>{t('actions.lock')}</MoreText>
        )}
        {onClickUnlock && (
          <MoreText onClick={onClickUnlock}>{t('actions.unlock')}</MoreText>
        )}
        {onClickInvite && (
          <MoreText onClick={onClickInvite}>{t('actions.invite')}</MoreText>
        )}
        {onClickDelete && (
          <MoreText onClick={onClickDelete}>{t('actions.delete')}</MoreText>
        )}
        {onClickComplaint && (
          <MoreText onClick={onClickComplaint}>
            {t('actions.complaint')}
          </MoreText>
        )}
        {onClickStats && (
          <MoreText onClick={onClickStats}>{t('actions.stats')}</MoreText>
        )}
        {onClickShow && (
          <MoreText onClick={onClickShow}>{t('actions.showSoftware')}</MoreText>
        )}
        {onClickHide && (
          <MoreText onClick={onClickHide}>{t('actions.hideSoftware')}</MoreText>
        )}
        {onClickReview && (
          <MoreText onClick={onClickReview}>{t('actions.add review')}</MoreText>
        )}
        {onClickCreateRequest && (
          <MoreText onClick={onClickCreateRequest}>
            {t('actions.request')}
          </MoreText>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

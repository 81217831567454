import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { ICodeExamples } from 'interfaces/Software';
import { getLocaleValue } from 'utils/form';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { Editor } from 'components/shared/editor';

const Container = styled(Grid)({
  padding: '27px 32px',
  background: '#FFFFFF',
  border: '1px solid #DDDDDD',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  marginBottom: 42,
});

const SubTitle = styled('div')({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '24px',
  color: '#969696',
});

const Text = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 16,
  lineHeight: '24px',
  color: '#000000',
});

type Props = {
  codeExample: ICodeExamples;
  onClickEdit?: () => void;
  onClickRemove?: () => void;
};

export const CodeCard = ({
  codeExample,
  onClickEdit,
  onClickRemove,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
  const { description, programmingLanguage, code } = codeExample;

  const handleClickEdit = () => {
    onClickEdit && onClickEdit();
    setAnchorEl(null);
  };

  const handleClickRemove = () => {
    onClickRemove && onClickRemove();
    setAnchorEl(null);
  };

  return (
    <Container container sx={{ display: 'relative' }} spacing={1}>
      <Grid item xs={3}>
        <SubTitle>Язык программирования</SubTitle>
        <Text>{getLocaleValue(programmingLanguage?.display)}</Text>
      </Grid>
      <Grid item xs={9}>
        <SubTitle>Примечание</SubTitle>
        <Text>{getLocaleValue(description)}</Text>
      </Grid>
      <Grid item xs={12}>
        <SubTitle>Пример кода</SubTitle>
        <Editor value={code} readOnly />
      </Grid>
      {(onClickEdit || onClickRemove) && (
        <MoreIcon
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        />
      )}
      {anchorEl && (
        <MoreMenu
          anchorEl={anchorEl}
          onClickEdit={handleClickEdit}
          onClickDelete={handleClickRemove}
        />
      )}
    </Container>
  );
};

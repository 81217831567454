import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover,
  RadioGroup,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { RadioButton } from 'components/shared/radio';
import { startOfToday, subMonths, subWeeks } from 'date-fns';

const FilterButton = styled(Button)({
  borderRadius: 4,
  padding: 4,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
  textTransform: 'none',
  boxShadow: 'none',
});

const Label = styled(FormLabel)({
  marginBottom: 8,
  fontWeight: '600',
  fontSize: 12,
  color: '#4b4b4b',
});

const RadioControlLabel = styled(FormControlLabel)({
  color: '#4b4b4b',
  '& span': {
    fontWeight: '400',
    fontSize: 12,
    color: '#63666a',
  },
});

type Props = {
  sx?: SxProps<Theme>;
  value?: number | null | undefined;
  onChange?: (value: number | null) => void;
};

export const FromDateFilter = (props: Props) => {
  const { sx = [], onChange } = props;
  const value = props.value || null;

  const { t } = useTranslation();

  const today = startOfToday();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (_: unknown, value: string) => {
    onChange?.(value ? parseInt(value) : null);
  };

  return (
    <>
      <FilterButton
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        onClick={handlePopoverOpen}
      >
        <FilterIcon style={{ marginRight: 4 }} />
        {t('filters.period.title')}
      </FilterButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <FormControl sx={{ padding: '16px' }}>
          <Label>{t('filters.period.title')}</Label>
          <FormGroup>
            <RadioGroup value={value ? `${value}` : ''} onChange={handleChange}>
              <RadioControlLabel
                labelPlacement="end"
                value={''}
                control={<RadioButton />}
                label={t('filters.period.all')}
              />
              <RadioControlLabel
                labelPlacement="end"
                value={`${today.getTime()}`}
                control={<RadioButton />}
                label={t('filters.period.today')}
              />
              <RadioControlLabel
                labelPlacement="end"
                value={`${subWeeks(today, 1).getTime()}`}
                control={<RadioButton />}
                label={t('filters.period.week')}
              />
              <RadioControlLabel
                labelPlacement="end"
                value={`${subMonths(today, 1).getTime()}`}
                control={<RadioButton />}
                label={t('filters.period.month')}
              />
            </RadioGroup>
          </FormGroup>
        </FormControl>
      </Popover>
    </>
  );
};

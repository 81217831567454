import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { INotificationItem } from 'interfaces/Notifications';
import { attachUrl } from 'services/ApiClient';
import { ReactComponent as HomeIcon } from 'assets/icons/mainIcon.svg';
import { ReactComponent as NavigationBusinessIcon } from 'assets/icons/businessIcon.svg';
import { ReactComponent as MarketplaceIcon } from 'assets/icons/marketplaceIcon.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/newsIcon.svg';
import { ReactComponent as QAIcon } from 'assets/icons/qaIcon.svg';
import { truncate } from 'lodash';
import { getLocaleValue } from 'utils/form';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import {
  NotificationAvatar,
  Title,
  Info,
  UnreadIcon,
  Container,
  NotificationAvatarContainer,
} from 'components/features/notification/components';
import getDateFnsLocale from 'services/dateFns';
import DOMPurify from 'dompurify';
import { Sections } from 'defenitions/main';

type Props = {
  item: INotificationItem;
};
export const NotificationListItem = ({ item }: Props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const from =
    differenceInDays(new Date(), new Date(item.createdDateTime)) === 0
      ? formatDistanceToNowStrict(new Date(item.createdDateTime), {
          locale: getDateFnsLocale(),
        })
      : format(new Date(item.createdDateTime), 'dd MMMM yyyy', {
          locale: getDateFnsLocale(),
        });
  const avatarUrl = item.creator?.logo?.path
    ? attachUrl + item.creator.logo.path
    : '';

  const renderEmptyAvatar = () => {
    return (
      <NotificationAvatarContainer>
        {item.category === Sections.HOME && <HomeIcon stroke={'white'} />}
        {item.category === Sections.MARKETPLACE && (
          <MarketplaceIcon stroke={'white'} />
        )}
        {item.category === Sections.BUSINESS && (
          <NavigationBusinessIcon stroke={'white'} />
        )}
        {item.category === Sections.QUESTION && <QAIcon stroke={'white'} />}
        {item.category === Sections.NEWS && <NewsIcon stroke={'white'} />}
      </NotificationAvatarContainer>
    );
  };

  const handleClickView = () => navigate(URLS.notifications + '/' + item.id);
  return (
    <Container
      item
      container
      flexWrap={'nowrap'}
      onMouseLeave={() => setAnchorEl(null)}
      sx={{ background: !item.read ? '#F6FCFF' : '' }}
    >
      <Grid item mr={2}>
        {avatarUrl ? (
          <NotificationAvatar src={avatarUrl} />
        ) : (
          renderEmptyAvatar()
        )}
      </Grid>
      <Grid item container direction={'column'} spacing={2}>
        <Title item>
          {truncate(getLocaleValue(item.title), {
            length: 100,
            separator: ' ',
          })}
        </Title>
        <Grid
          item
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              truncate(getLocaleValue(item.content), {
                length: 200,
                separator: ' ',
              }),
            ),
          }}
        />
        <Grid sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} item>
          <TimeIcon />
          <Info>{from}</Info>
        </Grid>
      </Grid>
      <MoreIcon
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      />
      {anchorEl && (
        <MoreMenu anchorEl={anchorEl} onClickView={handleClickView} />
      )}
      {!item.read && <UnreadIcon />}
    </Container>
  );
};

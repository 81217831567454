import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { NewsFilters, ISubscriptionFilters } from 'interfaces/NewsFilters';
import { AttachmentType } from 'interfaces/Attachment';
import {
  ICreateComment,
  ICreateNew,
  INews,
  IEditComment,
  ISubscription,
} from 'interfaces/News';
import { ReactionType } from 'interfaces/Post';

export const getNewsEndpoint = (
  filters: NewsFilters,
  isSystemAccount = false,
): Promise<AxiosResponse> =>
  apiClient.get(isSystemAccount ? '/news-posts' : '/news-posts/feed', {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

export const getImportantNewsEndpoint = (
  filters: NewsFilters,
): Promise<AxiosResponse> =>
  apiClient.get('/news-posts/system', {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

export const uploadAttachmentsEndpoint = ({
  formData,
  type,
}: {
  formData: FormData;
  type: AttachmentType;
}): Promise<AxiosResponse> => {
  return apiClient.post(`/attachments/files?type=${type}`, formData, {
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const createNewEndpoint = (
  formData: ICreateNew,
): Promise<AxiosResponse> => {
  return apiClient.post(`/news-posts`, formData, {
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const createCommentEndpoint = (
  formData: ICreateComment,
): Promise<AxiosResponse> => {
  return apiClient.post(`/posts/comments`, formData, {
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const editCommentEndpoint = (
  formData: IEditComment,
): Promise<AxiosResponse> => {
  return apiClient.put(`/posts/comments/${formData.id}`, formData, {
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const changeReactionEndpoint = (
  id: string,
  type: ReactionType,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/posts/reactions/${id}`,
    {},
    {
      params: { type },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  );
};

export const getCommentsEndpoint = (
  newId: INews['id'],
): Promise<AxiosResponse> => {
  return apiClient.get(`/posts/comments/to/${newId}`, {
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const getSubscriptionsEndpoint = (
  filters: ISubscriptionFilters,
): Promise<AxiosResponse> => {
  return apiClient.get(`/subscriptions/business`, {
    params: {
      ...filters,
      type: 'NEWS',
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const unsubscribeEndpoint = (
  id: ISubscription['id'],
): Promise<AxiosResponse> => {
  return apiClient.put(`/subscriptions/${id}/unsubscribe`);
};

export const deleteNewsEndpoint = (
  newsId: INews['id'],
): Promise<AxiosResponse> => {
  return apiClient.patch(`/news-posts/${newsId}/block`);
};

export const deleteNewsCommentEndpoint = (
  commentId: string,
): Promise<AxiosResponse> => {
  return apiClient.patch(`/posts/comments/${commentId}/block`);
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { changeFilters } from 'store/features/business';
import { initialBusinessFilters } from 'store/features/business/config';
import { URLS } from 'defenitions/routes';
import { Title, Container, Divider, SubTitle } from './components';
import { getBusinessCount } from 'store/features/business/actions';
import { AxiosResponse } from 'axios';

type Props = {
  progress: number;
  services: string[];
};

export const CreationNavigationMenu = ({ progress, services }: Props) => {
  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickBusinessCards = () => {
    dispatch(
      changeFilters({
        ...initialBusinessFilters,
        services,
      }),
    );
    navigate(URLS.business);
  };

  useEffect(() => {
    services.length
      ? (async () => {
          const res = await dispatch(
            getBusinessCount({ cardTypes: [], services }),
          ).unwrap();
          const { data } = res as AxiosResponse<number>;
          setCount(data);
        })()
      : setCount(0);
  }, [dispatch, services]);

  return (
    <Container>
      <Title>Marketplace</Title>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <SubTitle>
        Запрос
        <span style={{ color: '#969696' }}>Заполнено на {progress}%</span>
      </SubTitle>
      <SubTitle
        onClick={count ? onClickBusinessCards : undefined}
        style={{ cursor: count ? 'pointer' : 'initial' }}
      >
        Найдено
        <span style={{ color: '#969696' }}>{count}</span>
      </SubTitle>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <SubTitle style={{ display: 'flex', flexDirection: 'column' }}>
        <div>Отклики</div>
        <div style={{ color: '#969696' }}>
          Отклики будут появляться здесь, когда будет опубликован запрос
        </div>
      </SubTitle>
    </Container>
  );
};

import { IMultiLanguage } from 'interfaces/Locale';
import { IUser } from 'interfaces/Users';

export enum FieldType {
  LK = 'LK',
  EMAIL = 'EMAIL',
}

export interface INotificationSettingsItemSetting {
  EMAIL?: boolean;
  LK?: boolean;
}
export interface INotificationSettingsItem {
  code: string;
  value: IMultiLanguage;
  children?: INotificationSettingsItem[];
  selectable: boolean;
  setting: INotificationSettingsItemSetting;
  parent?: string;
}
export interface INotificationSettingCreateItem {
  code: string;
  setting: INotificationSettingsItemSetting;
}

export interface INotificationItem {
  id: string;
  title: IMultiLanguage;
  content: IMultiLanguage;
  account: {
    id: string;
    name: string;
  };
  creator?: IUser;
  category: string;
  type: string;
  method: FieldType;
  read: boolean;
  sent: boolean;
  createdDateTime: number;
  updateDateTime: number;
}

export interface INotificationsFilters {
  fromDate?: number;
  category?: string;
  onlyUnread?: boolean;
  offset: number;
  limit: number;
}

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import { initialComplaintsState } from 'store/features/complaints/config';
import {
  createComplaint,
  declineComplaint,
  getComplaints,
  getComplaintsAccountsRatings,
  getComplaintsAccountsFilter,
  resolveComplaint,
  getInvalidContents,
  addInvalidContent,
  removeInvalidContent,
  editInvalidContent,
  getComplaintById,
  getComplaintsByRelationId,
} from 'store/features/complaints/actions';
import Snackbar from 'services/Snackbar';
import i18next from 'services/i18n';
import { AxiosResponse } from 'axios';
import {
  IAccount,
  IAccountRating,
  IComplaint,
  IInvalidContent,
} from 'interfaces/Complaint';
import { RootState } from 'store';

const complaintsSlice = createSlice({
  name: Slices.complaints,
  initialState: initialComplaintsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getComplaints.pending, (state, { meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        state.complaints.loading = true;
        state.complaints.loaded = false;
        state.complaints.hasMore = offset === 0 || state.complaints.hasMore;
      })
      .addCase(getComplaints.fulfilled, (state, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<IComplaint[]>;

        state.complaints.list =
          offset === 0 ? data : [...state.complaints.list, ...data];

        state.complaints.loading = false;
        state.complaints.loaded = true;
        state.complaints.hasMore = data.length !== 0;
      })
      .addCase(getComplaints.rejected, (state) => {
        state.complaints.loading = false;
        state.complaints.loaded = false;
        state.complaints.hasMore = false;
      });

    builder
      .addCase(resolveComplaint.fulfilled, (state, { meta, payload }) => {
        const id = meta.arg;
        const { data } = payload as AxiosResponse<IComplaint>;

        state.complaints.list = state.complaints.list.filter(
          (item) => item.id !== id,
        );

        if (state.complaint.item?.id === id) {
          state.complaint.item = data;
        }
      })
      .addCase(declineComplaint.fulfilled, (state, { meta, payload }) => {
        const id = meta.arg;
        const { data } = payload as AxiosResponse<IComplaint>;

        state.complaints.list = state.complaints.list.filter(
          (item) => item.id !== id,
        );

        if (state.complaint.item?.id === id) {
          state.complaint.item = data;
        }
      });

    builder
      .addCase(getComplaintsAccountsRatings.pending, (state, { meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        state.accounts.loading = true;
        state.accounts.loaded = false;
        state.accounts.hasMore = offset === 0 || state.accounts.hasMore;
      })
      .addCase(
        getComplaintsAccountsRatings.fulfilled,
        (state, { payload, meta }) => {
          const { offset = 0 } = meta.arg ?? {};
          const { data } = payload as AxiosResponse<IAccountRating[]>;

          state.accounts.list =
            offset === 0 ? data : [...state.accounts.list, ...data];

          state.accounts.loading = false;
          state.accounts.loaded = true;
          state.accounts.hasMore = data.length !== 0;
        },
      )
      .addCase(getComplaintsAccountsRatings.rejected, (state) => {
        state.accounts.loading = false;
        state.accounts.loaded = false;
        state.accounts.hasMore = false;
      });

    builder
      .addCase(getComplaintsAccountsFilter.pending, (state) => {
        state.accountsFilter.loading = true;
        state.accountsFilter.loaded = false;
        state.accountsFilter.hasMore = false;
      })
      .addCase(getComplaintsAccountsFilter.fulfilled, (state, { payload }) => {
        const { data } = payload as AxiosResponse<IAccount[]>;
        state.accountsFilter.list = data;
        state.accountsFilter.loading = false;
        state.accountsFilter.loaded = true;
        state.accountsFilter.hasMore = false;
      })
      .addCase(getComplaintsAccountsFilter.rejected, (state) => {
        state.accountsFilter.loading = false;
        state.accountsFilter.loaded = false;
        state.accountsFilter.hasMore = false;
      });

    builder
      .addCase(getInvalidContents.pending, (state) => {
        state.invalidContents.loading = true;
        state.invalidContents.loaded = false;
        state.invalidContents.hasMore = false;
      })
      .addCase(getInvalidContents.fulfilled, (state, { payload }) => {
        const { data } = payload as AxiosResponse<IInvalidContent[]>;
        state.invalidContents.list = data;
        state.invalidContents.loading = false;
        state.invalidContents.loaded = true;
        state.invalidContents.hasMore = false;
      })
      .addCase(getInvalidContents.rejected, (state) => {
        state.invalidContents.loading = false;
        state.invalidContents.loaded = false;
        state.invalidContents.hasMore = false;
      });

    builder
      .addCase(addInvalidContent.fulfilled, (state, { payload }) => {
        const { data } = payload as AxiosResponse<Record<string, unknown>>;

        const content = {
          disabled: !data.selectable,
          id: data.code,
          text: data.value,
        } as IInvalidContent;

        state.invalidContents.list = [...state.invalidContents.list, content];
      })
      .addCase(removeInvalidContent.fulfilled, (state, { meta }) => {
        const id = meta.arg;

        state.invalidContents.list = state.invalidContents.list.filter(
          (item) => item.id !== id,
        );
      })
      .addCase(editInvalidContent.fulfilled, (state, { meta }) => {
        const content = meta.arg;

        const index = state.invalidContents.list.findIndex(
          (item) => item.id === content.id,
        );

        if (index !== -1) {
          state.invalidContents.list[index] = content;
        }
      });

    builder
      .addCase(getComplaintById.pending, (state) => {
        state.complaint.loading = true;
        state.complaint.loaded = false;
      })
      .addCase(getComplaintById.fulfilled, (state, { payload }) => {
        const { data } = payload as AxiosResponse<IComplaint>;
        state.complaint.item = data;
        state.complaint.loading = false;
        state.complaint.loaded = true;
      })
      .addCase(getComplaintById.rejected, (state) => {
        state.complaint.loading = false;
        state.complaint.loaded = false;
      });

    builder
      .addCase(getComplaintsByRelationId.pending, (state) => {
        state.sameComplaints.loading = true;
        state.sameComplaints.loaded = false;
        state.sameComplaints.hasMore = false;
      })
      .addCase(getComplaintsByRelationId.fulfilled, (state, { payload }) => {
        const { data } = payload as AxiosResponse<IComplaint[]>;
        state.sameComplaints.list = data;
        state.sameComplaints.loading = false;
        state.sameComplaints.loaded = true;
        state.sameComplaints.hasMore = false;
      })
      .addCase(getComplaintsByRelationId.rejected, (state) => {
        state.complaints.loading = false;
        state.complaints.loaded = false;
        state.complaints.hasMore = false;
      });

    builder.addCase(createComplaint.fulfilled, () => {
      Snackbar.show(i18next.t('notification.complaint created'), 'success');
    });
  },
});

const self = (state: RootState) => state.complaints;

export const getComplaintsList = createSelector(
  self,
  (state) => state.complaints.list,
);

export const getComplaintsLoading = createSelector(
  self,
  (state) => state.complaints.loading,
);

export const getComplaintsLoaded = createSelector(
  self,
  (state) => state.complaints.loaded,
);

export const getComplaintsHasMore = createSelector(
  self,
  (state) => state.complaints.hasMore,
);

export const getComplaintsAccountsList = createSelector(
  self,
  (state) => state.accounts.list,
);

export const getComplaintsAccountsLoading = createSelector(
  self,
  (state) => state.accounts.loading,
);

export const getComplaintsAccountsLoaded = createSelector(
  self,
  (state) => state.accounts.loaded,
);

export const getComplaintsAccountsHasMore = createSelector(
  self,
  (state) => state.accounts.hasMore,
);

export const getComplaintsAccountsFilterList = createSelector(
  self,
  (state) => state.accountsFilter.list,
);

export const getComplaintsAccountsFilterLoading = createSelector(
  self,
  (state) => state.accountsFilter.loading,
);

export const getComplaintsAccountsFilterLoaded = createSelector(
  self,
  (state) => state.accountsFilter.loaded,
);

export const getComplaintsAccountsFilterHasMore = createSelector(
  self,
  (state) => state.accountsFilter.hasMore,
);

export const getInvalidContentsList = createSelector(
  self,
  (state) => state.invalidContents.list,
);

export const getInvalidContentsLoading = createSelector(
  self,
  (state) => state.invalidContents.loading,
);

export const getInvalidContentsLoaded = createSelector(
  self,
  (state) => state.invalidContents.loaded,
);

export const getInvalidContentsHasMore = createSelector(
  self,
  (state) => state.invalidContents.hasMore,
);

export const getComplaint = createSelector(
  self,
  (state) => state.complaint.item,
);

export const getComplaintLoading = createSelector(
  self,
  (state) => state.complaint.loading,
);

export const getComplaintLoaded = createSelector(
  self,
  (state) => state.complaint.loaded,
);

export const getSameComplaintsList = createSelector(
  self,
  (state) => state.sameComplaints.list,
);

export const getSameComplaintsLoading = createSelector(
  self,
  (state) => state.sameComplaints.loading,
);

export const getSameComplaintsLoaded = createSelector(
  self,
  (state) => state.sameComplaints.loaded,
);

export const getSameComplaintsHasMore = createSelector(
  self,
  (state) => state.sameComplaints.hasMore,
);

export { complaintsSlice };

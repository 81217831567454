import React, { useState } from 'react';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import {
  IconButton,
  Menu,
  MenuItem,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { IInvalidContent } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { EditContentForm } from './EditContentForm';

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  padding: '16px 24px',
  border: '1px solid #e4e4e4',
  backgroundColor: theme.palette.background.paper,
  fontWeight: '500',
  fontSize: 14,
  color: '#4b4b4b',
}));

const CardMenu = styled(Menu)({
  '& .MuiMenu-list': {
    padding: 0,
  },
});

const CardMenuItem = styled(MenuItem)({
  minWidth: 200,
  display: 'flex',
  justifyContent: 'center',
  padding: '8px 16px',
  fontFamily: 'inherit',
  fontWeight: '600',
  fontSize: 14,
  color: '#004b7c',
});

type Props = {
  sx?: SxProps<Theme>;
  content: IInvalidContent;
  onEdit?: (data: IInvalidContent) => void;
  onDelete?: (id: string) => void;
};

export const ContentCard = ({ sx = [], content, onEdit, onDelete }: Props) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);

  const text = content.text.ru || content.text.en;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMoreOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => setAnchorEl(null);

  const openEditForm = () => {
    handleMoreClose();
    setEditing(true);
  };

  const closeEditForm = () => setEditing(false);

  const handleEdit = (data: IInvalidContent) => {
    onEdit?.(data);
    closeEditForm();
  };

  const handleDelete = () => {
    handleMoreClose();
    onDelete?.(content.id);
  };

  return editing ? (
    <EditContentForm
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      initial={content}
      onSubmit={handleEdit}
      onClose={closeEditForm}
    />
  ) : (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {text}
      <IconButton
        sx={{ position: 'absolute', top: 4, right: 4 }}
        onClick={handleMoreOpen}
      >
        <MoreIcon sx={{ color: '#707070' }} />
      </IconButton>
      <CardMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleMoreClose}
      >
        <CardMenuItem divider onClick={openEditForm}>
          {t('actions.edit')}
        </CardMenuItem>
        <CardMenuItem divider onClick={handleDelete}>
          {t('actions.delete')}
        </CardMenuItem>
      </CardMenu>
    </Root>
  );
};

import i18n from 'i18next';
import { HeadCell } from 'components/elements/table/TableHead';
import { DataItem } from 'components/elements/table';
import { IBusinessStats } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCell = (): HeadCell[] => {
  return [
    { id: 'businessCard', label: i18n.t('business card'), numeric: false },
    {
      id: 'status',
      label: i18n.t('tables.status'),
      numeric: false,
    },
    /*    {
      id: 'blocked',
      label: i18n.t('tables.blocked'),
      numeric: false,
    },*/
    {
      id: 'services',
      label: i18n.t('tables.services'),
      numeric: true,
    },
    {
      id: 'rate',
      label: i18n.t('tables.rate'),
      numeric: true,
    },
    {
      id: 'complaints',
      label: i18n.t('tables.complaints'),
      numeric: true,
    },
    /*   {
      id: 'blocks',
      label: i18n.t('tables.blocks'),
      numeric: true,
    },*/
    {
      id: 'offers',
      label: i18n.t('tables.offers'),
      numeric: true,
    },
    {
      id: 'offerAnswers',
      label: i18n.t('tables.offerAnswers'),
      numeric: true,
    },
    {
      id: 'questions',
      label: i18n.t('tables.questions'),
      numeric: true,
    },
    {
      id: 'answers',
      label: i18n.t('tables.answers'),
      numeric: true,
    },
    {
      id: 'news',
      label: i18n.t('tables.news'),
      numeric: true,
    },
    /*   {
        id: 'comments',
        label: i18n.t('tables.comments'),
        numeric: true,
      },
      {
        id: 'reviews',
        label: i18n.t('tables.reviews'),
        numeric: true,
      },
     {
        id: 'exchangeContacts',
        label: i18n.t('tables.exchangeContacts'),
        numeric: true,
      },
      {
        id: 'exchangeContactsWithMe',
        label: i18n.t('tables.exchangeContactsWithMe'),
        numeric: true,
      },
      {
        id: 'views',
        label: i18n.t('tables.views'),
        numeric: true,
      },
      {
        id: 'viewsInList',
        label: i18n.t('tables.viewsInList'),
        numeric: true,
      },*/
  ];
};

export const createData = (data: IBusinessStats[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.businessCard.id,
      businessCard: getLocaleValue(el.businessCard.name),
      blocked: i18n.t(`boolean.${el.blocked}`),
      status: i18n.t(`businessCardStatuses.${el.status}`),
    };
  });

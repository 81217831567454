import { Quill } from 'react-quill';

export const SUPPORTED_FONT_SIZES = [
  '26px',
  '24px',
  '22px',
  '20px',
  '18px',
  '16px',
  '14px',
  '12px',
  '10px',
];

const SizeClass = Quill.import('attributors/class/size');

SizeClass.whitelist = SUPPORTED_FONT_SIZES;

const SizeStyle = Quill.import('attributors/style/size');

SizeStyle.whitelist = SUPPORTED_FONT_SIZES;

export { SizeClass, SizeStyle };

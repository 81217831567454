import React, {
  ChangeEvent,
  FormEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputBase, styled, SxProps, Theme } from '@mui/material';
import { useDebounce } from 'utils/hooks';
import { useTranslation } from 'react-i18next';

const Root = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
  padding: '2px 4px',
  backgroundColor: '#f5f5f5',
});

const defaultValidate = (value: string) => {
  return value.length > 2;
};

export type SearchRef = {
  focus: () => void;
  blur: () => void;
  clear: () => void;
};

type Props = {
  sx?: SxProps<Theme>;
  delay?: number;
  defaultValue?: string | null;
  onTextChange?: (value: string) => void;
  validate?: (value: string) => boolean;
};

export const Search = forwardRef<SearchRef, Props>((props, ref) => {
  const { t } = useTranslation();
  const { sx = [], delay = 500, defaultValue, onTextChange } = props;

  const [search, setSearch] = useState(defaultValue || '');

  const debounced = useDebounce(search, delay);

  const input = useRef<HTMLInputElement>(null);

  const prev = useRef(debounced);

  const validate = props.validate ?? defaultValidate;

  const handleSubmit = (event: FormEvent) => event.preventDefault();

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
  };

  useImperativeHandle(ref, () => ({
    focus: () => input.current?.focus(),
    blur: () => input.current?.blur(),
    clear: () => setSearch(''),
  }));

  useEffect(() => {
    const isEmpty = debounced.length === 0;
    const isValid = validate(debounced);

    if (prev.current === debounced) return;

    if (isEmpty || isValid) {
      prev.current = debounced;
      onTextChange?.(debounced);
    }
  }, [debounced, onTextChange, validate]);

  return (
    <Root
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      component="form"
      onSubmit={handleSubmit}
    >
      <SearchIcon sx={{ color: '#a9a9a9' }} />
      <InputBase
        sx={{ ml: 0.5, flex: 1 }}
        placeholder={t('filters.search')}
        value={search}
        inputRef={input}
        inputProps={{
          style: { height: 30, padding: 0 },
          'aria-label': 'search',
        }}
        onChange={handleTextChange}
      />
    </Root>
  );
});

Search.displayName = 'Search';

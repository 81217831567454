import React from 'react';
import {
  styled,
  SxProps,
  Theme,
  Avatar,
  Grid,
  Typography,
} from '@mui/material';
import { attachUrl } from 'services/ApiClient';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import { getNameInitials } from 'utils/user';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from '../../../../defenitions/routes';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 12,
});

const BusinessAvatar = styled(Avatar)({
  height: 55,
  width: 55,
});

const Name = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  margin: 0,
  padding: 0,
  fontWeight: '700',
  fontSize: 18,
  color: '#00618e',
  justifyContent: 'space-between',
});

const Header = styled(Typography)({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '22px',
  color: '#00618e',
}) as typeof Typography;

const Info = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

type Props = {
  sx?: SxProps<Theme>;
  avatarSx?: SxProps<Theme>;
  nameSx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
  receiverSx?: SxProps<Theme>;
  src?: string;
  name?: string;
  user?: string;
  created: Date | number | string;
  receiver?: string;
  status?: React.ReactNode;
  rating?: number;
  id?: string;
};

export const BusinessCardHeader = ({
  sx = [],
  avatarSx = [],
  nameSx = [],
  textSx = [],
  receiverSx = [],
  src,
  name,
  user,
  created,
  receiver,
  status,
  rating,
  id,
}: Props) => {
  const { t } = useTranslation();

  const from =
    differenceInDays(new Date(), new Date(created)) === 0
      ? formatDistanceToNowStrict(new Date(created), { locale: ru })
      : format(new Date(created), 'dd MMMM yyyy', { locale: ru });

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <BusinessAvatar
        sx={[...(Array.isArray(avatarSx) ? avatarSx : [avatarSx])]}
        src={src && `${attachUrl}${src}`}
      >
        {getNameInitials(name)}
      </BusinessAvatar>
      <Grid sx={{ flex: 1 }} spacing={0.5} direction="column" container>
        <Grid item>
          <Name sx={[...(Array.isArray(nameSx) ? nameSx : [nameSx])]}>
            <Header
              component={id ? Link : 'div'}
              to={generatePath(URLS.viewBusinessCard, { id: id ?? '' })}
            >
              {name || 'Deleted'}
            </Header>
            {status && status}
          </Name>
        </Grid>
        {user && (
          <Grid sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} item>
            <UserIcon />
            <Info sx={[...(Array.isArray(textSx) ? textSx : [textSx])]}>
              {user}
            </Info>
          </Grid>
        )}
        <Grid sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} item>
          <TimeIcon />
          <Info sx={[...(Array.isArray(textSx) ? textSx : [textSx])]}>
            {from}
          </Info>
        </Grid>
        {rating && (
          <Grid sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} item>
            <Info sx={[...(Array.isArray(textSx) ? textSx : [textSx])]}>
              {`${t('rating')}: ${rating}`}
            </Info>
          </Grid>
        )}
        {receiver && (
          <Grid sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} item>
            <Info
              sx={[...(Array.isArray(receiverSx) ? receiverSx : [receiverSx])]}
            >
              {`${t('marketplace.requestReceiver')}: ${receiver}`}
            </Info>
          </Grid>
        )}
      </Grid>
    </Root>
  );
};

import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { IBusinessCreate } from 'interfaces/Business';
import {
  IBusinessFilters,
  IBusinessReviewsFilters,
} from 'interfaces/BusinessFilters';
import qs from 'qs';
import { AttachmentType } from 'interfaces/Attachment';

export const getBusinessEndpoint = (
  filters: IBusinessFilters,
): Promise<AxiosResponse> => {
  return apiClient.get(`/business-cards`, {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });
};

export const getBusinessPublishedEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get(`/business-cards/account/published`, {
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const getMyBusinessEndpoint = (
  filters: IBusinessFilters,
): Promise<AxiosResponse> => {
  return apiClient.get(`/business-cards/account`, {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });
};

export const getBusinessCardEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`/business-cards/${id}`);
};

export const createBusinessCardEndpoint = (
  businessCard: IBusinessCreate,
): Promise<AxiosResponse> => {
  return apiClient.post(`/business-cards`, businessCard);
};

export const updateBusinessCardEndpoint = (
  id: string,
  businessCard: IBusinessCreate,
): Promise<AxiosResponse> => {
  return apiClient.put(`/business-cards/${id}`, businessCard);
};

export const deleteBusinessCardEndpoint = (
  id: string,
): Promise<AxiosResponse> => {
  return apiClient.delete(`/business-cards/${id}`);
};

export const unpublishBusinessCardEndpoint = (
  id: string,
  archive = false,
): Promise<AxiosResponse> => {
  return apiClient.patch(`/business-cards/${id}/unpublish`, null, {
    params: archive,
  });
};

export const uploadBusinessCardLogoEndpoint = (
  formData: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments?type=${AttachmentType.BusinessCardLogo}`,
    formData,
  );
};

export const uploadBusinessCardAttachmentEndpoint = (
  formData: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments/files?type=${AttachmentType.BusinessCard}`,
    formData,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  );
};

export const uploadServiceCardAttachmentEndpoint = (
  formData: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments/files?type=${AttachmentType.ServiceCard}`,
    formData,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  );
};

export const getPublishedBusinessCardsEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get(`/business-cards/account/published`);
};

export const subscribeBusinessCardsEndpoint = (
  id: string,
): Promise<AxiosResponse> => {
  return apiClient.post(`/subscriptions/business`, {
    businessCardId: id,
    postType: 'NEWS',
  });
};

export const unsubscribeBusinessCardsEndpoint = (
  id: string,
): Promise<AxiosResponse> => {
  return apiClient.put(`/subscriptions/${id}/unsubscribe`);
};

export const getCountBusinessEndpoint = (filters: {
  cardTypes: string[];
  services: string[];
}): Promise<AxiosResponse> => {
  return apiClient.get(`/business-cards/count`, {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });
};

export const getAllBusinessCardsEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get('/business-cards/all');
};

export const getBusinessCardReviewsEndpoint = (
  filters: IBusinessReviewsFilters,
): Promise<AxiosResponse> => {
  return apiClient.get(`/reviews/business-cards/${filters.businessCardId}`, {
    params: {
      ...filters,
    },
  });
};

export const getBusinessCardByAccountEndpoint = (
  accountId: string,
): Promise<AxiosResponse> => {
  return apiClient.get(`/business-cards/account/${accountId}/published`);
};

import React from 'react';
import { SignUpForm } from 'components/features/auth/containers/signup/form';
import { SignupLayout } from 'components/features/auth/layout/signupLayout';

export const SignUp = () => {
  return (
    <SignupLayout title={'Создать учётную запись участника'}>
      <SignUpForm />
    </SignupLayout>
  );
};

import React from 'react';
import { Header } from 'components/elements/header';
import { styled } from '@mui/system';
import { HEADER_HEIGHT } from 'services/theme';

type Props = {
  children: JSX.Element;
};

const Container = styled('div')({
  minHeight: `100vh`,
});

const ContentContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: `calc(100vh - ${HEADER_HEIGHT + 30}px)`,
}));

const Footer = styled('div')({
  textAlign: 'right',
  height: 30,
  color: '#666666',
  paddingRight: 60,
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '15px',
});

export const AuthLayout = ({ children }: Props) => {
  return (
    <Container>
      <Header />
      <ContentContainer>{children}</ContentContainer>
      <Footer>
        <div>© Авторские права 2022, EQUIRON LLC.</div>
        <div>
          LabelCoding® является зарегистрированным товарным знаком EQUIRON LLC.
        </div>
      </Footer>
    </Container>
  );
};

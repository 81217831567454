import React, { useEffect, useState } from 'react';

export const MenuAd = () => {
  const [height, setHeight] = useState<number>();
  useEffect(() => {
    let myIdContent = 0;
    const interval = setInterval(() => {
      myIdContent =
        document.getElementById('menuAd')?.contentDocument.body.scrollHeight;
      setHeight(myIdContent);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <iframe
        id={'menuAd'}
        style={{
          display: 'inline-block',
          textDecoration: 'none',
          width: '100%',
          height: height,
          maxHeight: 850,
          border: 'none',
        }}
        srcDoc={
          '<!DOCTYPE html>\n' +
          '<html lang="en">\n' +
          '<head>\n' +
          '</head>\n' +
          '<body style="margin: 0">\n' +
          '<script async src="https://ad.mail.ru/static/ads-async.js"></script>\n' +
          '<ins class="mrg-tag" style="display: inline-block; text-decoration: none; width: 100%; height: auto; max-height: 850px" data-ad-client="ad-1163438" data-ad-slot="1163438"></ins>\n' +
          '<script>(MRGtag = window.MRGtag || []).push({})</script>\n' +
          '</body>\n' +
          '</html>\n'
        }
      ></iframe>
    </>
  );
};

export const FooterAd = () => {
  return (
    <>
      <iframe
        style={{
          display: 'inline-block',
          textDecoration: 'none',
          width: '100%',
          maxHeight: 250,
          border: 'none',
        }}
        srcDoc={
          '<!DOCTYPE html>\n' +
          '<html lang="en">\n' +
          '<head>\n' +
          '</head>\n' +
          '<body>\n' +
          '<script async src="https://ad.mail.ru/static/ads-async.js"></script>\n' +
          '<ins class="mrg-tag" style="display:inline-block;width:100%;max-height:250px" data-ad-client="ad-1163404" data-ad-slot="1163404"></ins>\n' +
          '<script>(MRGtag = window.MRGtag || []).push({})</script>\n' +
          '</body>\n' +
          '</html>\n'
        }
      ></iframe>
    </>
  );
};

export const ListAdOne = () => {
  return (
    <div
      style={{
        padding: 10,
      }}
    >
      <div
        className="js-relap-anchor"
        data-relap-id="BwoKJAHAM8gycHBw"
        style={{
          width: '100%',
          maxHeight: 90,
          marginBottom: 16,
        }}
      ></div>
      <iframe
        className="relap-runtime-iframe"
        style={{
          position: 'absolute',
          top: '-9999px',
          left: '-9999px',
          visibility: 'hidden',
        }}
        srcDoc="<script src='https://relap.io/v7/relap.js' data-relap-token='6uCZ5h-bNMQ5AwYD'></script>"
      ></iframe>
    </div>
  );
};

export const ListAdTwo = () => {
  return (
    <div
      style={{
        padding: 10,
      }}
    >
      <div
        className="js-relap-anchor"
        data-relap-id="_eTkUXS1eIN5ysrK"
        style={{
          width: '100%',
          maxHeight: 90,
          marginBottom: 16,
        }}
      ></div>
      <iframe
        className="relap-runtime-iframe"
        style={{
          position: 'absolute',
          top: '-9999px',
          left: '-9999px',
          visibility: 'hidden',
        }}
        srcDoc="<script src='https://relap.io/v7/relap.js' data-relap-token='6uCZ5h-bNMQ5AwYD'></script>"
      ></iframe>
    </div>
  );
};

export const SearchAd = () => {
  const [height, setHeight] = useState<number>();
  useEffect(() => {
    let myIdContent = 0;
    const interval = setInterval(() => {
      myIdContent =
        document.getElementById('searchAd')?.contentDocument.body.scrollHeight;
      setHeight(myIdContent);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <iframe
        id={'searchAd'}
        style={{
          display: 'inline-block',
          textDecoration: 'none',
          width: '100%',
          maxHeight: 650,
          border: 'none',
          height: height,
        }}
        srcDoc={
          '<!DOCTYPE html>\n' +
          '<html lang="en">\n' +
          '<head>\n' +
          '</head>\n' +
          '<body>\n' +
          '<script async src="https://ad.mail.ru/static/ads-async.js"></script>\n' +
          '<ins class="mrg-tag" style="display:inline-block;width:100%;max-height:650px" data-ad-client="ad-1163448" data-ad-slot="1163448"></ins>\n' +
          '<script>(MRGtag = window.MRGtag || []).push({})</script>\n' +
          '</body>\n' +
          '</html>\n'
        }
      ></iframe>
    </>
  );
};

export enum signinFormFields {
  id = 'id',
  password = 'password',
  rememberMe = 'rememberMe',
}

export interface IAuthCredentials {
  id: string;
  password: string;
  rememberMe: boolean;
  agreementSigned?: boolean;
}

export interface IChangePasswordForm {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export enum signupFormFields {
  name = 'name',
  country = 'country',
  email = 'email',
  userProfileEmail = 'userProfile.email',
  userProfilePassword = 'userProfile.password',
  userProfileConfirmPassword = 'userProfile.confirmPassword',
  userProfileName = 'userProfile.name',
  userProfileLastName = 'userProfile.lastName',
}
export interface ISignUpForm {
  name: string;
  country: string;
  email: string;
  userProfile: {
    email: string;
    password: string;
    confirmPassword: string;
    name: string;
    lastName: string;
  };
  accept: boolean;
  type: string;
}

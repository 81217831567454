import React, { createContext, useState } from 'react';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import { CreateServiceRequestForm } from './CreateServiceRequestForm';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { CreationNavigationMenu } from 'components/features/marketplace/components/answersNavigationMenu/CreationNavigationMenu';
export type CreationContext = {
  setProgress: (p: number) => void;
  setServices: (s: string[]) => void;
};
export const CreationContext = createContext<CreationContext>({
  setProgress: () => undefined,
  setServices: () => undefined,
});

export const MarketplaceCreateRequest = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState<number>(0);
  const [services, setServices] = useState<string[]>([]);

  const { service, software } = useQuery() as {
    service: string;
    software: string;
  };

  const goToBack = () => navigate(-1);

  return (
    <CreationContext.Provider value={{ setProgress, setServices }}>
      <MarketplaceLayout
        contentSx={{ bgcolor: (theme) => theme.palette.background.paper }}
        menuComponent={
          <CreationNavigationMenu progress={progress} services={services} />
        }
      >
        <CreateServiceRequestForm
          receiverServiceId={service}
          receiverSoftwareId={software}
          onSuccess={goToBack}
          onCancel={goToBack}
        />
      </MarketplaceLayout>
    </CreationContext.Provider>
  );
};

import React, { useEffect, useMemo } from 'react';
import qs from 'qs';
import { HomeLayout } from 'components/features/home/components/layout';
import {
  Container,
  Title,
  TitleContainer,
} from 'components/features/home/components';
import { RouteNames, URLS } from 'defenitions/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import {
  TitleDivider,
  ViewTitle,
} from 'components/features/notification/components';
import { Loader } from 'components/shared/loader';
import { useAppDispatch, useAppSelector } from 'store';
import { selectUserStats, selectUserStatsLoading } from 'store/features/stats';
import { attachUrl } from 'services/ApiClient';
import Avatar from '@mui/material/Avatar';
import { getNameInitials } from 'utils/user';
import { getUserStats } from 'store/features/stats/actions';
import { UserComplaintsStats } from 'components/features/stats/item/user/tables/complaints';
import { ActivityStats } from 'components/features/stats/item/user/tables/activity';
import { DateTimeRangeFilter } from 'components/elements/filters/DateTimeRangeFilter';
import { format } from 'date-fns';
import { useQuery } from 'hooks/useQuery';
import { BackButton } from 'components/shared/buttons/BackButton';

const FilterText = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: 500,
  fontSize: 14,
  color: '#000',
});

export const ViewUserStats = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const search = useQuery();
  const stats = useAppSelector(selectUserStats);
  const isLoading = useAppSelector(selectUserStatsLoading);

  const filters = useMemo(
    () => ({
      fromDate: parseInt(search.fromDate as string) || null,
      toDate: parseInt(search.toDate as string) || null,
    }),
    [search.fromDate, search.toDate],
  );

  const startDate = filters.fromDate ? new Date(filters.fromDate) : null;
  const endDate = filters.toDate ? new Date(filters.toDate) : null;

  const onClickBack = () => navigate(-1);
  const avatarUrl = stats?.user.logo?.path
    ? attachUrl + stats?.user.logo?.path
    : '';
  const emptyText = getNameInitials(
    `${stats?.user.name} ${stats?.user.lastName}`,
  );

  const handleDateTimesChange = (start: Date | null, end: Date | null) => {
    const startTime = start ? start.getTime() : null;
    const endTime = end ? end.getTime() : null;

    navigate({
      search: qs.stringify(
        {
          ...filters,
          fromDate: startTime,
          toDate: endTime,
        },
        { skipNulls: true },
      ),
    });
  };

  useEffect(() => {
    id && dispatch(getUserStats({ id, filters }));
  }, [dispatch, filters, id]);

  return (
    <HomeLayout>
      <Container item container>
        <TitleContainer container>
          <Grid item xs={2}>
            <BackButton onClick={onClickBack} />
          </Grid>
          <Grid item container xs={8} justifyContent={'center'}>
            <Title>{t(`${RouteNames[URLS.userStats]}`)}</Title>
          </Grid>
          <Grid item xs={2} />
        </TitleContainer>
      </Container>
      {isLoading && <Loader show global />}
      {stats && (
        <Container sx={{ borderTop: 'none', borderRadius: 0 }}>
          <TitleContainer container flexWrap={'nowrap'}>
            <Grid item mr={2}>
              <Avatar src={avatarUrl} sx={{ width: 55, height: 55 }}>
                {!stats?.user.logo && emptyText}
              </Avatar>
            </Grid>
            <Grid item container direction={'column'} spacing={1}>
              <Title
                item
              >{`${stats?.user.name} ${stats?.user.lastName}`}</Title>
            </Grid>
          </TitleContainer>
          {startDate && endDate && (
            <FilterText sx={{ pl: 3, pr: 3, mt: 2 }}>
              {t('statisticsPeriod', {
                start: format(startDate, 'dd.LL.yyyy'),
                end: format(endDate, 'dd.LL.yyyy'),
              })}
            </FilterText>
          )}
          <Grid
            sx={{ pt: 1, pb: 1, pl: 3, pr: 3 }}
            container
            alignItems="center"
          >
            <DateTimeRangeFilter
              startDate={startDate}
              endDate={endDate}
              onDateTimesChange={handleDateTimesChange}
            />
          </Grid>
          <TitleContainer container sx={{ paddingRight: 0 }}>
            <div
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <ViewTitle>{t('statistics')}</ViewTitle>
              <TitleDivider />
            </div>
            <Grid item sx={{ width: '100%' }}>
              <ActivityStats activity={stats.activity} />
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <UserComplaintsStats complaints={stats.complaints} />
            </Grid>
          </TitleContainer>
        </Container>
      )}
    </HomeLayout>
  );
};

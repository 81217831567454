import * as React from 'react';
import Radio, { RadioProps } from '@mui/material/Radio';

export const RadioButton = ({ ...props }: RadioProps) => {
  return (
    <Radio
      sx={{
        color: '#A9A9A9',
        padding: '4px',
        '&.Mui-checked': {
          color: '#4B4B4B',
        },
      }}
      {...props}
    />
  );
};

import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { IExchangeContactsStats } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCell = (): HeadCell[] => {
  return [
    {
      id: 'name',
      label: i18n.t('tables.name'),
      numeric: false,
      withOpen: true,
    },
    {
      id: 'account',
      label: i18n.t('tables.account'),
      numeric: true,
    },
    {
      id: 'card',
      label: i18n.t('tables.card'),
      numeric: true,
    },
  ];
};

export const createData = (data: IExchangeContactsStats): DataItem[] => {
  return [
    {
      id: 'grantedAccess',
      name: i18n.t('tables.grantedAccess'),
      account: data.countGrantedAccess,
      card: data.countGrantedAccess,
      child: data.grantedAccess.map((el, index) => {
        return {
          id: index.toString(),
          name: '',
          account: el.account.name,
          card: getLocaleValue(el.businessCard.name),
        };
      }),
    },
    {
      id: 'gotAccess',
      name: i18n.t('tables.gotAccess'),
      account: data.countGotAccess,
      card: data.countGotAccess,
      child: data.gotAccess.map((el, index) => {
        return {
          id: index.toString(),
          name: '',
          account: el.account.name,
          card: getLocaleValue(el.businessCard.name),
        };
      }),
    },
  ];
};

import React, { useEffect } from 'react';
import qs from 'qs';
import { HomeLayout } from 'components/features/home/components/layout';
import {
  Container,
  Title,
  TitleContainer,
} from 'components/features/home/components';
import { RouteNames, URLS } from 'defenitions/routes';
import { BusinessStats } from 'components/features/stats/list/business';
import { UsersStats } from 'components/features/stats/list/users';
import { BusinessFilters, UsersFilters } from './components/filters';
import { useAppDispatch, useAppSelector } from 'store';
import {
  resetBusinessList,
  resetUsersList,
  selectBusinessFilters,
  selectUsersFilters,
} from 'store/features/stats';
import { pick } from 'lodash';

export const Stats = () => {
  const dispatch = useAppDispatch();

  const businessFilters = useAppSelector(selectBusinessFilters);
  const usersFilters = useAppSelector(selectUsersFilters);

  useEffect(
    () => () => {
      dispatch(resetBusinessList());
      dispatch(resetUsersList());
    },
    [dispatch],
  );

  return (
    <HomeLayout>
      <Container item container>
        <TitleContainer container>
          <Title>{RouteNames[URLS.stats]}</Title>
        </TitleContainer>
      </Container>
      <BusinessStats
        filters={<BusinessFilters sx={{ p: 2, borderTop: '1px solid #ddd' }} />}
        query={qs.stringify(pick(businessFilters, ['fromDate', 'toDate']), {
          skipNulls: true,
        })}
      />
      <UsersStats
        filters={<UsersFilters sx={{ p: 2, borderTop: '1px solid #ddd' }} />}
        query={qs.stringify(pick(usersFilters, ['fromDate', 'toDate']), {
          skipNulls: true,
        })}
      />
    </HomeLayout>
  );
};

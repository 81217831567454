import React, { useEffect, useCallback, ComponentProps } from 'react';
import { styled } from '@mui/system';
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreHoriz';

import { attachUrl } from 'services/ApiClient';
import { useAppDispatch, useAppSelector } from 'store';
import {
  changeSubscriptionFilters,
  getSubscriptionsList,
  getSubscriptionsFilters,
} from 'store/features/news';
import { getSubscriptions, unsubscribe } from 'store/features/news/actions';
import { ISubscription } from 'interfaces/News';

import { NewsLayout } from 'components/features/news/components/layout';
import { SearchTextFieldInput } from 'components/shared/inputs/SearchInput';
import { getLocaleValue } from 'utils/form';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import { ListAdOne, ListAdTwo } from 'components/elements/ads';
import { GuideIcon } from '../../../../elements/guideIcon';

const SubscriptionHeader = styled('div')({
  display: 'flex',
  border: '1px solid #E4E4E4',
  borderRadius: '5px',
  height: '70px',
  width: '100%',
  background: '#fff',
  alignItems: 'center',
  padding: '19px 45px',
  justifyContent: 'space-between',
});

const SubscriptionHeaderTitle = styled('div')({
  fontSize: '24px',
});

const SubscriptionWrap = styled('div')({
  fontWeight: 600,
});

const SearchWrap = styled('div')({
  width: '310px',
  display: 'flex',
});

const HeaderList = styled('div')({
  border: '1px solid #E4E4E4',
  height: '33px',
  width: '100%',
  background: '#fff',
  marginTop: '15px',
});

const SubscriptionCard = styled('div')({
  border: '1px solid #E4E4E4',
  height: '81px',
  width: '100%',
  padding: '13px 20px 13px 31px',
  background: '#fff',
  display: 'flex',
});

const SubscriptionCardLogo = styled('img')({
  borderRadius: '50%',
  overflow: 'hidden',
  width: 55,
  height: 55,
});

const SubscriptionCardName = styled(Link)({
  fontSize: '16px',
  marginLeft: 19,
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: '#000000DE',
  cursor: 'pointer',
});

const Menu = styled(MuiMenu)({
  '& .MuiMenu-list': {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const More = styled((props: ComponentProps<'button'>) => (
  <button {...props}>
    <MoreIcon />
  </button>
))({
  marginLeft: 'auto',
  marginBottom: 'auto',
  padding: 0,
  border: 'none',
  fontFamily: 'inherit',
  fontWeight: '400',
  fontSize: 12,
  color: '#4b4b4b',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
});

const MenuItem = styled(MuiMenuItem)({
  display: 'flex',
  justifyContent: 'center',
  minWidth: 200,
  padding: '8px 16px',
  fontFamily: 'inherit',
  fontWeight: '600',
  fontSize: 14,
  color: '#004b7c',
});

export const NewsSubscriptions = () => {
  const dispatch = useAppDispatch();
  const subscriptionsList = useAppSelector(getSubscriptionsList);
  const filters = useAppSelector(getSubscriptionsFilters);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => setAnchorEl(null);

  useEffect(() => {
    dispatch(getSubscriptions(filters));
  }, [filters, dispatch]);

  const handleChangeSearch = useCallback(
    (value) => {
      if (value.trim().length < 3 && value?.length !== 0) return;
      dispatch(changeSubscriptionFilters({ text: value }));
    },
    [dispatch, filters],
  );

  const handleUnsubscribe = useCallback(
    async (id) => {
      handleCloseMore();
      await dispatch(unsubscribe(id)).then(() => {
        dispatch(getSubscriptions(filters));
      });
    },
    [filters, dispatch],
  );

  return (
    <NewsLayout>
      <SubscriptionWrap>
        <SubscriptionHeader>
          <SubscriptionHeaderTitle>Подписки</SubscriptionHeaderTitle>
          <SearchWrap>
            <SearchTextFieldInput onChangeSearch={handleChangeSearch} />
            <GuideIcon withContainer />
          </SearchWrap>
        </SubscriptionHeader>
        {subscriptionsList.length ? <HeaderList /> : <></>}
        {subscriptionsList.map((subscription: ISubscription, index) => (
          <>
            {index % 4 === 0 && index % 8 !== 0 && index !== 0 && <ListAdOne />}
            {index % 4 === 0 && index % 8 === 0 && index !== 0 && <ListAdTwo />}
            <SubscriptionCard key={subscription.id}>
              {subscription?.businessCard?.logo?.path ? (
                <SubscriptionCardLogo
                  src={`${attachUrl}${subscription.businessCard?.logo?.path}`}
                />
              ) : (
                <></>
              )}
              <SubscriptionCardName
                to={generatePath(URLS.viewBusinessCard, {
                  id: subscription?.businessCard?.id,
                })}
                target="_blank"
              >
                {getLocaleValue(subscription?.businessCard?.name)}
              </SubscriptionCardName>
              <More onClick={handleOpenMore} />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleCloseMore}
              >
                <MenuItem onClick={() => handleUnsubscribe(subscription.id)}>
                  Отписаться
                </MenuItem>
              </Menu>
            </SubscriptionCard>
          </>
        ))}
      </SubscriptionWrap>
    </NewsLayout>
  );
};

import React, { useCallback, useContext, useEffect, useState } from 'react';
import Snackbar from 'services/Snackbar';
import { Controller, useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { styled, SxProps, Theme } from '@mui/system';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import { IAttachment, AttachmentType } from 'interfaces/Attachment';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { IMultiLanguage } from 'interfaces/Locale';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import {
  getApplicationAreasDictionaries,
  getProgrammingLanguagesDictionaries,
  getServiceTypeDictionariesContent,
} from 'store/features/dictionaries';
import {
  getDictionariesContentApplicationAreas,
  getDictionariesContentProgrammingLanguages,
  getDictionariesContentServiceType,
} from 'store/features/dictionaries/actions';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import {
  ICreateServiceRequest,
  IService,
  IServiceRequest,
  ServiceRequestStatus,
} from 'interfaces/Marketplace';
import { map, isEmpty, values } from 'lodash';
import {
  createServiceRequest,
  getServiceById,
  publishServiceRequest,
  uploadMarketplaceAttachment,
} from 'store/features/marketplace/actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { createServiceRequestValidationSchema } from './validationScheme';
import { CreationContext } from 'components/features/marketplace/containers/createRequest/index';
import { useTranslation } from 'react-i18next';
import { selectOthersSoftwareCards } from 'store/features/software';
import {
  getOthersAccountSoftwareCards,
  getSoftwareCard,
} from 'store/features/software/actions';
import { IPublishedBusinessCard } from 'interfaces/Business';
import { TextFieldInput } from 'components/shared/inputs';
import { getLocaleValue } from 'utils/form';
import { ISoftware } from 'interfaces/Software';
import {
  getBusinessCardByAccount,
  getPublishedBusinessCards,
} from 'store/features/business/actions';

const Form = styled('form')({
  maxWidth: 762,
  borderRadius: 4,
  border: '1px solid #e4e4e4',
  padding: '24px 32px',
});

const Title = styled('h2')({
  margin: 0,
  padding: 0,
  fontWeight: '600',
  fontSize: 24,
  color: '#4b4b4b',
});

type IFormData = {
  receiverBusinessCard: IPublishedBusinessCard;
  receiverSoftwareCard: ISoftware;
  authorOfRequest: IPublishedBusinessCard;
  serviceTypes: IDictionariesContent[];
  title: IMultiLanguage;
  content: IMultiLanguage;
  softwareCards: IDictionariesContent[];
  programmingLanguages: IDictionariesContent[];
  applicationAreas: IDictionariesContent[];
};

type Props = {
  sx?: SxProps<Theme>;
  receiverServiceId?: string;
  receiverSoftwareId?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
};

export const CreateServiceRequestForm = ({
  receiverServiceId,
  receiverSoftwareId,
  sx = [],
  onSuccess,
  onCancel,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    formState: { isValid, errors },
    control,
    reset,
    trigger,
    watch,
    handleSubmit,
    setValue,
    resetField,
  } = useForm<IFormData>({
    mode: 'onChange',
    resolver: yupResolver(createServiceRequestValidationSchema),
  });

  const { setProgress, setServices } =
    useContext<CreationContext>(CreationContext);

  const requiredField = {
    title: values(watch('title')).every(isEmpty),
    content: values(watch('content')).every(isEmpty),
    serviceTypes: isEmpty(watch('serviceTypes')),
  };

  const progress = values(requiredField).length;
  const filledProgress = values(requiredField).filter((el) => !el).length;
  const services = watch('serviceTypes');

  const serviceTypes = useAppSelector(getServiceTypeDictionariesContent);
  const software = useAppSelector(selectOthersSoftwareCards);
  const languages = useAppSelector(getProgrammingLanguagesDictionaries);
  const applicationAreas = useAppSelector(getApplicationAreasDictionaries);

  const softwareDictionary = software.map((s) => ({
    code: s.id,
    display: s.name,
    selectable: false,
  }));

  const handleServiceTypeLoadOptions = useCallback(() => {
    dispatch(getDictionariesContentServiceType());
  }, [dispatch]);

  const handleSoftwareLoadOptions = useCallback(() => {
    dispatch(getOthersAccountSoftwareCards());
  }, [dispatch]);

  const handleLoadProgrammingLanguages = useCallback(() => {
    dispatch(getDictionariesContentProgrammingLanguages());
  }, [dispatch]);

  const handleLoadApplicationAreas = useCallback(() => {
    dispatch(getDictionariesContentApplicationAreas());
  }, [dispatch]);

  const [attachments, setAttachments] = useState<Array<IAttachment>>([]);

  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      const attachment = { data: formData, type: AttachmentType.Offer };

      const { payload } = await dispatch(
        uploadMarketplaceAttachment(attachment),
      );

      const { data } = payload as AxiosResponse<IAttachment[]>;
      data && setAttachments((prev) => [...prev, ...data]);
    },
    [dispatch],
  );

  const handleRemoveAttachments = useCallback((id: IAttachment['id']) => {
    setAttachments((prev) => prev.filter((item) => item.id !== id));
  }, []);

  const resetAttachments = useCallback(() => setAttachments([]), []);

  const submit = async (
    data: IFormData,
    { withPublish = false }: { withPublish?: boolean } = {},
  ) => {
    const request: ICreateServiceRequest = {
      receiverBusinessCardId: data.receiverBusinessCard?.id,
      receiverSoftwareCardId: data.receiverSoftwareCard?.id,
      serviceTypes: map(data.serviceTypes, 'code'),
      title: data.title,
      content: data.content,
      attachments: map(attachments, 'id'),
      status: ServiceRequestStatus.DRAFT,
      softwareCardIds: map(data.softwareCards, 'code'),
      programmingLanguages: map(data.programmingLanguages, 'code'),
      applicationAreas: map(data.applicationAreas, 'code'),
    };

    const result = await dispatch(createServiceRequest(request)).unwrap();

    if (result && withPublish) {
      const id = (result.data as IServiceRequest).id;
      await dispatch(publishServiceRequest(id)).unwrap();
    }

    const notification = withPublish
      ? t('notification.service request published')
      : t('notification.service request saved');

    resetAttachments();
    reset();
    onSuccess?.();
    Snackbar.show(notification, 'success');
  };

  const save = (data: IFormData) => submit(data, { withPublish: false });

  const publish = (data: IFormData) => submit(data, { withPublish: true });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'title.en' && type === 'change') {
        trigger('title.ru');
      }
      if (name === 'title.ru' && type === 'change') {
        trigger('title.en');
      }
      if (name === 'content.en' && type === 'change') {
        trigger('content.ru');
      }
      if (name === 'content.ru' && type === 'change') {
        trigger('content.en');
      }
    });
    return () => subscription.unsubscribe();
  }, [trigger, watch]);

  useEffect(() => {
    setProgress(Math.round((filledProgress / progress) * 100));
  }, [filledProgress, progress, setProgress]);

  useEffect(() => {
    setServices(services?.map((el) => el.code) || []);
  }, [services, setServices]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    (async () => {
      if (receiverServiceId && !receiverSoftwareId) {
        const service = await dispatch(
          getServiceById(receiverServiceId),
        ).unwrap();

        const { data } = service as AxiosResponse<IService>;

        const { businessCard, businessService } = data;
        const { serviceType } = businessService;

        resetField('receiverBusinessCard');
        resetField('serviceTypes');

        setValue('receiverBusinessCard', businessCard);
        setValue('serviceTypes', [serviceType]);
      }
    })();
  }, [dispatch, receiverServiceId, receiverSoftwareId, resetField, setValue]);

  useEffect(() => {
    (async () => {
      if (receiverSoftwareId && !receiverServiceId) {
        const software = await dispatch(
          getSoftwareCard(receiverSoftwareId),
        ).unwrap();

        const { data: softwareData } = software as AxiosResponse<ISoftware>;

        const business = await dispatch(
          getBusinessCardByAccount(softwareData.accountId),
        ).unwrap();

        const { data: businessData } =
          business as AxiosResponse<IPublishedBusinessCard>;

        resetField('receiverBusinessCard');
        resetField('receiverSoftwareCard');

        setValue('receiverBusinessCard', businessData);
        setValue('receiverSoftwareCard', softwareData);
      }
    })();
  }, [dispatch, receiverServiceId, receiverSoftwareId, resetField, setValue]);

  useEffect(() => {
    (async () => {
      const result = await dispatch(getPublishedBusinessCards()).unwrap();
      const { data } = result as AxiosResponse<IPublishedBusinessCard[]>;

      const authorOfRequest = data[0];

      resetField('receiverSoftwareCard');
      setValue('authorOfRequest', authorOfRequest);
    })();
  }, [dispatch, resetField, setValue]);

  const receiverBusinessCard = watch('receiverBusinessCard');
  const receiverSoftwareCard = watch('receiverSoftwareCard');
  const authorOfRequest = watch('authorOfRequest');

  return (
    <Form sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Title>{t('marketplace.createRequest')}</Title>
        </Grid>
        {authorOfRequest && (
          <Grid item xs={12}>
            <TextFieldInput
              label={t('marketplace.authorOfRequest')}
              value={getLocaleValue(authorOfRequest?.name)}
              disabled
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            defaultValue={[]}
            control={control}
            name="serviceTypes"
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value}
                multiple
                noHierarchy
                disabled={!!receiverServiceId && !receiverSoftwareId}
                inputRef={ref}
                label={t('formFieldsPlaceholder.serviceTypes')}
                placeholder={
                  value.length ? '' : t('formFieldsPlaceholder.serviceTypes')
                }
                data={serviceTypes}
                errorMessage={errors.serviceTypes?.toString()}
                onLoadOptions={handleServiceTypeLoadOptions}
              />
            )}
          />
        </Grid>
        {receiverBusinessCard && (
          <Grid item xs={12}>
            <TextFieldInput
              label={t('marketplace.requestReceiver')}
              value={getLocaleValue(receiverBusinessCard?.name)}
              disabled
            />
          </Grid>
        )}

        {receiverSoftwareCard && (
          <Grid item xs={12}>
            <TextFieldInput
              label={t('marketplace.requestSoftwareReceiver')}
              value={getLocaleValue(receiverSoftwareCard?.name)}
              disabled
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName="title"
            label={t('marketplace.createServiceRequest.title.label')}
            placeholder={t('marketplace.createServiceRequest.title.label')}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="softwareCards"
            defaultValue={[]}
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value}
                noHierarchy
                multiple
                inputRef={ref}
                label="ПО"
                data={softwareDictionary}
                errorMessage={errors.softwareCards?.toString()}
                onLoadOptions={handleSoftwareLoadOptions}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="programmingLanguages"
            control={control}
            defaultValue={[]}
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value}
                noHierarchy
                multiple
                inputRef={ref}
                label="Язык программирования"
                data={languages}
                errorMessage={errors.programmingLanguages?.toString()}
                onLoadOptions={handleLoadProgrammingLanguages}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="applicationAreas"
            control={control}
            defaultValue={[]}
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value}
                noHierarchy
                multiple
                inputRef={ref}
                data={applicationAreas}
                label={'Область применения'}
                errorMessage={errors.applicationAreas?.toString()}
                onLoadOptions={handleLoadApplicationAreas}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName="content"
            isEditor
            label={t('marketplace.createServiceRequest.content.label')}
            placeholder={t(
              'marketplace.createServiceRequest.content.placeholder',
            )}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <FilesUploader
            attachments={attachments}
            maxFiles={10}
            onUploadAttachments={handleUploadAttachments}
            onClickRemoveAttachments={handleRemoveAttachments}
          />
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px',
          }}
          item
          xs={12}
        >
          <CreateButton
            fullWidth
            type="button"
            text={t('cancel')}
            onClick={onCancel}
          />
          <CreateButton
            fullWidth
            disabled={!isValid}
            type="button"
            text={t('save')}
            onClick={handleSubmit(save)}
          />
          <ActionButton
            fullWidth
            disabled={!isValid}
            text={t('actions.saveAndPublish')}
            onClick={handleSubmit(publish)}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

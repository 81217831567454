import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import { ServiceHeader } from 'components/features/marketplace/components/ServiceHeader';
import { useAppDispatch, useAppSelector } from 'store';
import {
  closeServiceRequest,
  deleteServiceRequest,
  getOfferCount,
  getServiceRequestById,
  likeServiceRequest,
  marketplaceDeclineServiceRequest,
  publishServiceRequest,
} from 'store/features/marketplace/actions';
import { ServiceRequestDetailedCard } from 'components/features/marketplace/components/cards/ServiceRequestDetailedCard';
import {
  getServiceRequest,
  isMarketplaceItemLoading,
} from 'store/features/marketplace';
import { Loader } from 'components/shared/loader';
import { RouteNames, URLS } from 'defenitions/routes';
import { useQuery } from 'hooks/useQuery';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import {
  OfferCount,
  RequiredOfferName,
  ServiceRequestFromPages,
  ServiceRequestStatus,
} from 'interfaces/Marketplace';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/shared/modal';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { useForm } from 'react-hook-form';
import { IMultiLanguage } from 'interfaces/Locale';
import { serviceDataValidationSchema } from 'components/features/marketplace/containers/provideService/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { ReactionType } from 'interfaces/Post';

const Container = styled('div')({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
});

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '40px 32px',
  gap: 16,
});

const ModalBody = styled('div')({
  padding: 20,
});

type Params = { id: string };

export const MarketplaceServiceRequest = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id = '' } = useParams<Params>();
  const { from } = useQuery() as { from: ServiceRequestFromPages };
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
  const {
    isOpen: isOpenComplaint,
    onClickOpen: onClickOpenComplaint,
    onClickClose: onClickCloseComplaint,
  } = useModalActions();

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const openRejectModal = () => setIsRejectModalOpen(true);

  const closeRejectModal = () => setIsRejectModalOpen(false);

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
    reset,
    watch,
    trigger,
  } = useForm<{ title: IMultiLanguage }>({
    mode: 'onChange',
    resolver: yupResolver(serviceDataValidationSchema),
  });

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name === 'title.en' && type === 'change') {
        trigger('title.ru');
      }
      if (name === 'title.ru' && type === 'change') {
        trigger('title.en');
      }
    });
    return () => subscription.unsubscribe();
  }, [trigger, watch]);

  const request = useAppSelector(getServiceRequest);
  const isLoading = useAppSelector(isMarketplaceItemLoading);

  useEffect(() => {
    dispatch(getServiceRequestById(id));
  }, [dispatch, id]);

  const handleBack = () => navigate(-1);

  const handlePublish = () => {
    dispatch(publishServiceRequest(id));
  };

  const handleClose = () => {
    dispatch(closeServiceRequest(id));
  };
  const [offerCount, setOfferCount] = useState<OfferCount>({});
  const { isOpen, onClickSubmit, onClickClose, onClickOpen } =
    useModalActions(handleClose);
  const handleCreateCloseData = (data: OfferCount) => {
    setOfferCount(data);
    onClickOpen();
  };

  const handleCheckClose = async () => {
    const res = await dispatch(getOfferCount(id)).unwrap();
    const { data } = res as AxiosResponse<OfferCount>;
    const RequiredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key]) =>
          key.includes(RequiredOfferName.PUBLISHED) ||
          key.includes(RequiredOfferName.ACCEPTED) ||
          key.includes(RequiredOfferName.IN_WORK),
      ),
    );

    isEmpty(RequiredData) ? handleClose() : handleCreateCloseData(RequiredData);
  };

  const handleDelete = async () => {
    await dispatch(deleteServiceRequest(id)).unwrap();
    handleBack();
  };

  const handleLike = () => {
    dispatch(likeServiceRequest({ id, type: ReactionType.LIKE }));
  };

  const handleDislike = () => {
    dispatch(likeServiceRequest({ id, type: ReactionType.DISLIKE }));
  };

  const handleReject = async (data: { title: IMultiLanguage }) => {
    if (request) {
      await dispatch(
        marketplaceDeclineServiceRequest({
          ...data,
          offerId: request.id,
        }),
      );

      dispatch(getServiceRequestById(id));
      closeRejectModal();
      reset();
    }
  };

  const navigateToCreateOfferAnswer = () => {
    navigate(`/marketplace/offer/${id}/answer`);
  };

  const navigateToCreateAnswer = () => {
    if (request) {
      navigate({
        pathname: `/marketplace/offer/${request.id}/answer`,
        search: qs.stringify({
          businessCard: request.receiverBusinessCard?.id,
        }),
      });
    }
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <MarketplaceLayout
      contentSx={{ bgcolor: (theme) => theme.palette.background.paper }}
      answersLayout={from === ServiceRequestFromPages.MyRequests}
    >
      <Container>
        <div style={{ position: 'relative' }}>
          <ServiceHeader
            sx={{ borderBottom: '1px solid #e4e4e4' }}
            onBack={handleBack}
            onMouseLeave={() => setAnchorEl(null)}
          >
            {RouteNames[URLS.marketplaceServiceRequest]}
          </ServiceHeader>
          {from === ServiceRequestFromPages.RequestsCompilation && (
            <MoreIcon
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
              }}
            />
          )}
        </div>

        {isLoading && <Loader sx={{ m: 'auto' }} show />}
        {!isLoading && request && (
          <>
            <ServiceRequestDetailedCard
              sx={{ borderBottom: '1px solid #e4e4e4' }}
              type={t('marketplace.request')}
              request={request}
              onLike={handleLike}
              onDislike={handleDislike}
              showStatus
            />
            <Actions>
              {from === ServiceRequestFromPages.RequestsCompilation &&
                (request.status === ServiceRequestStatus.PUBLISHED ||
                  request.status === ServiceRequestStatus.ACCEPTED ||
                  request.status === ServiceRequestStatus.IN_WORK) && (
                  <ActionButton
                    text={t('marketplace.respond')}
                    onClick={navigateToCreateOfferAnswer}
                  />
                )}

              {from === ServiceRequestFromPages.MyRequests && (
                <>
                  {request.status === ServiceRequestStatus.DRAFT &&
                    request.canProcessByCurrentUser && (
                      <>
                        <CreateButton
                          text={t('actions.delete')}
                          onClick={handleDelete}
                        />
                        <ActionButton
                          text={t('actions.publish')}
                          onClick={handlePublish}
                        />
                      </>
                    )}

                  {request.status === ServiceRequestStatus.PUBLISHED &&
                    request.canProcessByCurrentUser && (
                      <CreateButton
                        text={t('actions.revoke')}
                        onClick={handleCheckClose}
                      />
                    )}
                </>
              )}

              {from === ServiceRequestFromPages.ReceivedRequests &&
                (request.status === ServiceRequestStatus.PUBLISHED ||
                  request.status === ServiceRequestStatus.ACCEPTED ||
                  request.status === ServiceRequestStatus.IN_WORK) && (
                  <>
                    <CreateButton
                      text={t('actions.reject')}
                      onClick={openRejectModal}
                    />
                    <ActionButton
                      text={t('actions.answer')}
                      onClick={navigateToCreateAnswer}
                    />
                  </>
                )}
            </Actions>
          </>
        )}
        {anchorEl && (
          <MoreMenu
            anchorEl={anchorEl}
            onClickComplaint={
              from === ServiceRequestFromPages.RequestsCompilation
                ? onClickOpenComplaint
                : undefined
            }
          />
        )}
        <Modal
          title={t('modalTexts.revokeServiceRequest.title')}
          saveButtonTitle={t('modalTexts.revokeServiceRequest.actionTitle')}
          open={isRejectModalOpen}
          disabled={!isValid}
          handleClose={closeRejectModal}
          onClickAccept={handleSubmit(handleReject)}
        >
          <ModalBody>
            <FormMultiLocaleField
              fieldName={'title'}
              label={t('modalTexts.revokeServiceRequest.reasonLabel')}
              placeholder={t(
                'modalTexts.revokeServiceRequest.reasonPlaceholder',
              )}
              errors={errors}
              control={control}
            />
          </ModalBody>
        </Modal>
        {request && (
          <ConfirmDialog
            onClickClose={onClickCloseComplaint}
            open={isOpenComplaint}
            title={t('modalTexts.complaint.title', {
              name: getLocaleValue(request.title),
            })}
          >
            <CreateComplaint
              relationId={request.id}
              complaintAboutType={ComplaintAboutType.OFFER}
              additionAction={onClickCloseComplaint}
            />
          </ConfirmDialog>
        )}
      </Container>
      <ConfirmDialog
        onClickAccept={onClickSubmit}
        onClickClose={onClickClose}
        open={isOpen}
        actionTitle={t('modalTexts.marketplace.actionTitle')}
        title={t('modalTexts.marketplace.title')}
      >
        <div style={{ textAlign: 'center' }}>
          {t('modalTexts.marketplace.subText')}
        </div>
        {Object.entries(offerCount).map(([status, count]) => (
          <div style={{ marginTop: 8 }} key={status}>
            {t('modalTexts.marketplace.statusCount', {
              status: t(`marketplace.statuses.${status}`),
              count,
            })}
          </div>
        ))}
      </ConfirmDialog>
    </MarketplaceLayout>
  );
};

import React, { useEffect } from 'react';
import { getUser } from 'store/features/users/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { getUserLoadingSelector, getUserSelector } from 'store/features/users';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/shared/loader';
import { HomeLayout } from 'components/features/home/components/layout';
import {
  Container,
  ContentContainer,
  Divider,
  LastLoginText,
  LastLoginTextContainer,
  Title,
  TitleContainer,
} from 'components/features/home/components';
import { ViewField } from 'components/elements/viewFields/ViewField';
import { ViewImage } from 'components/elements/viewFields/avatar';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getNameInitials } from 'utils/user';
import Grid from '@mui/material/Grid';

export const ViewUser = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { user, statistics } = useAppSelector(getUserSelector) || {};
  const {
    name,
    lastName,
    role,
    reputation,
    logo,
    lastLoggedIn,
    account,
    status,
  } = user || {};
  const { questions, answers, offers, offerAnswers } = statistics || {};
  const loading = useAppSelector(getUserLoadingSelector);
  const emptyText = getNameInitials(`${name} ${lastName}`);

  useEffect(() => {
    id && dispatch(getUser(id));
  }, [dispatch, id]);

  return (
    <HomeLayout>
      {loading && <Loader show global />}
      <Container item container>
        <TitleContainer>
          <Title>{`${lastName} ${name}`}</Title>
        </TitleContainer>
        <Divider />
        <ContentContainer item container>
          <Grid item xs={9}>
            <ViewField
              title={'Профиль'}
              fields={[
                { field: 'Имя:', value: name },
                { field: 'Фамилия:', value: lastName },
                { field: 'Роль:', value: t(`roles.${role}`) },
                {
                  field: 'Аккаунт:',
                  value: account?.name,
                },
                {
                  field: 'Электронная почта:',
                  value: id,
                  isEmail: true,
                },
                {
                  field: 'Статус:',
                  value: t(`statuses.${status}`),
                },
                {
                  field: 'Репутация:',
                  value: reputation?.toString(),
                },
              ]}
            />
          </Grid>
          <Grid item xs={3}>
            <ViewImage
              style={{
                display: 'flex',
                marginTop: 20,
                height: logo?.path ? undefined : '50%',
              }}
              url={logo?.path}
              emptyText={emptyText}
            />
          </Grid>
          {lastLoggedIn && (
            <LastLoginTextContainer>
              <LastLoginText>
                {`${t(`lastLoggedIn`)} ${format(lastLoggedIn, 'dd.MM.yyyy')}`}
              </LastLoginText>
            </LastLoginTextContainer>
          )}
          <ViewField
            title={t('statistics')}
            fieldTitleXs={9}
            fields={[
              {
                field: 'Количество вопросов за весь период:',
                value: questions?.toString(),
              },
              {
                field: 'Количество ответов за весь период:',
                value: answers?.toString(),
              },
              {
                field: 'Количество запросов за весь период:',
                value: offers?.toString(),
              },
              {
                field: 'Количество предложений за весь период:',
                value: offerAnswers?.toString(),
              },
            ]}
          />
        </ContentContainer>
      </Container>
    </HomeLayout>
  );
};

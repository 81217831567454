export type Route =
  | 'signin'
  | 'business'
  | 'restore'
  | 'signup'
  | 'successSingup'
  | 'createBusinessCard'
  | 'editBusinessCard'
  | 'viewBusinessCard'
  | 'questions'
  | 'myQuestions'
  | 'incomingQuestions'
  | 'createQuestion'
  | 'createQuestionForBusinessCard'
  | 'questionSubscriptions'
  | 'answers'
  | 'changePassword'
  | 'news'
  | 'newsImportant'
  | 'newsSubscriptions'
  | 'createNew'
  | 'marketplace'
  | 'marketplaceGetService'
  | 'marketplaceProvideService'
  | 'marketplaceRequests'
  | 'marketplaceCreateRequest'
  | 'marketplaceServiceRequest'
  | 'marketplaceService'
  | 'marketplaceCreateOfferAnswer'
  | 'marketplaceOfferAnswer'
  | 'termsOfService'
  | 'account'
  | 'profile'
  | 'home'
  | 'users'
  | 'allUsers'
  | 'notifications'
  | 'stats'
  | 'userStats'
  | 'businessStats'
  | 'createUser'
  | 'editUser'
  | 'settings'
  | 'moderationComplaints'
  | 'moderationRatings'
  | 'moderationContents'
  | 'moderationComplaint'
  | 'notificationSettings'
  | 'notificationView'
  | 'globalSearch'
  | 'software'
  | 'createSoftware'
  | 'viewSoftwareCard'
  | 'editSoftwareCard'
  | 'marketplaceSoftware'
  | 'viewUser';

export const URLS: Record<Route, string> = {
  signin: '/signin',
  restore: '/restore',
  signup: '/signup',
  changePassword: '/changePassword',
  successSingup: '/successSingup',
  business: '/business',
  createBusinessCard: '/business/new',
  editBusinessCard: '/business/:id/edit',
  viewBusinessCard: '/business/:id/view',
  questions: '/questions',
  myQuestions: '/questions/my',
  incomingQuestions: '/questions/incoming',
  createQuestion: '/questions/create',
  createQuestionForBusinessCard: '/questions/create/:id',
  answers: '/questions/:id/answers',
  questionSubscriptions: '/questions/subscriptions',
  news: '/news',
  newsImportant: '/news/important',
  newsSubscriptions: '/news/subscriptions',
  createNew: '/news/createNew',
  marketplace: '/marketplace',
  marketplaceGetService: '/marketplace/getService',
  marketplaceProvideService: '/marketplace/provideService',
  marketplaceRequests: '/marketplace/requests',
  marketplaceCreateRequest: '/marketplace/create',
  marketplaceServiceRequest: 'marketplace/request/:id',
  marketplaceService: 'marketplace/service/:id',
  marketplaceCreateOfferAnswer: '/marketplace/offer/:id/answer',
  marketplaceOfferAnswer: '/marketplace/answer/:id',
  termsOfService: '/termsOfService',
  account: '/home/account',
  profile: '/home/profile',
  home: '/home',
  users: '/home/users',
  createUser: '/home/users/new',
  editUser: '/home/users/:id/edit',
  notifications: '/home/notifications',
  notificationView: '/home/notifications/:id',
  stats: '/home/stats',
  settings: '/settings',
  moderationComplaints: '/moderation/complaints',
  moderationRatings: '/moderation/ratings',
  moderationContents: '/moderation/contents',
  moderationComplaint: '/moderation/complaints/:id',
  notificationSettings: '/settings/notifications',
  allUsers: '/home/users/all',
  globalSearch: '/search',
  userStats: '/home/stats/user/:id',
  businessStats: '/home/stats/business/:id',
  software: '/software',
  createSoftware: '/software/new',
  viewSoftwareCard: '/software/:id/view',
  editSoftwareCard: '/software/:id/edit',
  marketplaceSoftware: '/marketplace/software/:id',
  viewUser: '/home/users/:id',
};

export const RouteNames: { [key: string]: string } = {
  [URLS.marketplace]: 'Подборка',
  [URLS.marketplaceRequests]: 'Мои запросы',
  [URLS.marketplaceGetService]: 'Получаю услуги',
  [URLS.marketplaceProvideService]: 'Оказываю услуги',
  [URLS.marketplaceCreateRequest]: 'Создать запрос на услугу',
  [URLS.marketplaceCreateOfferAnswer]: 'Создать предложение',
  [URLS.marketplaceServiceRequest]: 'Просмотр запроса',
  [URLS.marketplaceService]: 'Просмотр услуги',
  [URLS.business]: 'Карточки бизнеса',
  [URLS.restore]: 'Восстановление пароля',
  [URLS.signup]: 'Регистрация',
  [URLS.createBusinessCard]: 'Создание карточки бизнеса',
  [URLS.editBusinessCard]: 'Редактирование карточки бизнеса',
  [URLS.news]: 'Новости',
  [URLS.newsImportant]: 'Самые важные новости',
  [URLS.newsSubscriptions]: 'Подписки',
  [URLS.createNew]: 'Создать новость',
  [URLS.termsOfService]: 'Пользовательское соглашение',
  [URLS.marketplaceOfferAnswer]: 'Просмотр предложения',
  [URLS.account]: 'Аккаунт',
  [URLS.profile]: 'Профиль',
  [URLS.users]: 'My users',
  [URLS.notifications]: 'Notifications',
  [URLS.stats]: 'Статистика',
  [URLS.home]: 'Главная',
  [URLS.questionSubscriptions]: 'Подписки на категории вопросов',
  [URLS.settings]: 'Настройки',
  [URLS.notificationSettings]: 'Настройки по уведомлениям',
  [URLS.notificationView]: 'View notification',
  [URLS.allUsers]: 'All users',
  [URLS.userStats]: 'View stats',
  [URLS.businessStats]: 'View stats',
  [URLS.createSoftware]: 'Создание карточки ПО',
  [URLS.marketplaceSoftware]: 'Просмотр ПО',
};

import * as yup from 'yup';
import {
  ErrorMessages,
  MAX_LENGTH,
  MAX_LENGTH_QUESTION,
} from 'defenitions/errorMessages';

const localeValidate = (maxLength: number) =>
  yup.object().shape(
    {
      ru: yup.string().when('en', {
        is: (en: string) => {
          return !en;
        },
        then: yup
          .string()
          .max(maxLength, ErrorMessages.maxLength(maxLength))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
      en: yup.string().when('ru', {
        is: (ru: string) => {
          return !ru;
        },
        then: yup
          .string()
          .max(maxLength, ErrorMessages.maxLength(maxLength))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
    },
    [
      ['ru', 'en'],
      ['en', 'ru'],
    ],
  );

export const createQuestionValidationSchema = yup.object({
  categoryId: yup.object().required(ErrorMessages.required),
  subcategoryId: yup.object().required(ErrorMessages.required),
  topic: localeValidate(MAX_LENGTH),
  content: localeValidate(MAX_LENGTH_QUESTION),
});

export const answerValidationSchema = yup.object({
  content: localeValidate(MAX_LENGTH_QUESTION),
});

export const editAnswerValidationSchema = yup.object({
  content: localeValidate(MAX_LENGTH_QUESTION),
});

import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { ICategoryStats } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCell = (): HeadCell[] => {
  return [
    {
      id: 'name',
      label: i18n.t('tables.name'),
      numeric: false,
      withOpen: true,
    },
    {
      id: 'all',
      label: i18n.t('tables.all'),
      numeric: true,
    },
    {
      id: 'publicQuestions',
      label: i18n.t('tables.publicQuestions'),
      numeric: true,
    },
    {
      id: 'privateQuestions',
      label: i18n.t('tables.privateQuestions'),
      numeric: true,
    },
  ];
};

export const createData = (data: ICategoryStats[]): DataItem[] =>
  data.map((el) => {
    return {
      id: el.id,
      name: getLocaleValue(el.name),
      all: el.all,
      publicQuestions: el.publicQuestions,
      privateQuestions: el.privateQuestions,
      child: el.children && createData(el.children),
    };
  });

import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@mui/system';
import {
  getAnswersItemsList,
  hasMoreAnswersItemsList,
  isLoadingAnswersItemsList,
} from 'store/features/marketplace';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getMyOffers,
  likeServiceOffer,
  marketplaceCloseAnswer,
  marketplaceDeleteAnswer,
  marketplacePublishAnswer,
  marketplaceWorkAnswer,
} from 'store/features/marketplace/actions';
import {
  AnswerStatuses,
  IAnswer,
  IProvideServicesPageFilters,
} from 'interfaces/Marketplace';
import { Loader } from 'components/shared/loader';
import { ServiceRequestAnswerCard } from 'components/features/marketplace/components/cards/ServiceRequestAnswerCard';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { ReactionType } from 'interfaces/Post';
import { ListAdOne, ListAdTwo } from '../../../../elements/ads';

const EmptyText = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
});

type Props = {
  filters?: IProvideServicesPageFilters;
  onFiltersChange?: (filters: IProvideServicesPageFilters) => void;
};

export const MyOffersList = ({ filters = {}, onFiltersChange }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const limit = filters.limit ?? 10;
  const offset = filters.offset ?? 0;

  const isLoading = useAppSelector(isLoadingAnswersItemsList);
  const hasMore = useAppSelector(hasMoreAnswersItemsList);

  const offers = useAppSelector(getAnswersItemsList);

  const isFetching = offset === 0 && isLoading;
  const isRefetching = offset !== 0 && isLoading;

  const prevFilters = useRef<IProvideServicesPageFilters>({});

  useEffect(() => {
    const currentFilters = { ...filters, limit, offset };

    if (!isEqual(prevFilters.current, currentFilters)) {
      dispatch(getMyOffers(currentFilters));
      prevFilters.current = currentFilters;
    }
  }, [dispatch, filters, limit, offset]);

  const changeFilters = () => {
    onFiltersChange?.({ limit, offset: offset + limit });
  };

  const publish = (id: IAnswer['id']) => async () => {
    await dispatch(marketplacePublishAnswer(id));
    if (filters.offset) {
      onFiltersChange?.({ ...filters, offset: 0 });
    } else {
      dispatch(getMyOffers(filters));
    }
  };

  const close = (id: IAnswer['id']) => async () => {
    await dispatch(marketplaceCloseAnswer(id));
    if (filters.offset) {
      onFiltersChange?.({ ...filters, offset: 0 });
    } else {
      dispatch(getMyOffers(filters));
    }
  };

  const deleteAnswer = (id: IAnswer['id']) => async () => {
    await dispatch(marketplaceDeleteAnswer(id));
    if (filters.offset) {
      onFiltersChange?.({ ...filters, offset: 0 });
    } else {
      dispatch(getMyOffers(filters));
    }
  };

  const work = (id: IAnswer['id']) => async () => {
    await dispatch(marketplaceWorkAnswer(id));
    if (filters.offset) {
      onFiltersChange?.({ ...filters, offset: 0 });
    } else {
      dispatch(getMyOffers(filters));
    }
  };

  const navigateToAnswer = (id: IAnswer['id']) => () => {
    navigate(`/marketplace/answer/${id}`);
  };

  const handleLike = (id: IAnswer['id']) => {
    dispatch(likeServiceOffer({ id, type: ReactionType.LIKE }));
  };

  const handleDislike = (id: IAnswer['id']) => {
    dispatch(likeServiceOffer({ id, type: ReactionType.DISLIKE }));
  };

  return (
    <>
      {isFetching && <Loader sx={{ m: 'auto' }} show />}
      {!isFetching && !offers.length && (
        <EmptyText sx={{ m: 'auto' }}>{t('marketplace.emptyOffers')}</EmptyText>
      )}
      {!isFetching && !!offers.length && (
        <InfiniteScroll
          dataLength={offers.length}
          loader={<Loader show={isRefetching} global />}
          hasMore={hasMore}
          next={changeFilters}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {offers.map((offer, index) => {
            const draftActions = [
              {
                label: t('actions.publish'),
                onClick: publish(offer.id),
              },
              {
                label: t('actions.delete'),
                onClick: deleteAnswer(offer.id),
              },
            ];
            const publishedActions = [
              {
                label: t('actions.revoke'),
                onClick: close(offer.id),
              },
            ];
            const acceptedActions = [
              {
                label: t('actions.submit'),
                onClick: work(offer.id),
              },
              {
                label: t('actions.revoke'),
                onClick: close(offer.id),
              },
            ];
            return (
              <>
                {index % 4 === 0 && index % 8 !== 0 && index !== 0 && (
                  <ListAdOne />
                )}
                {index % 4 === 0 && index % 8 === 0 && index !== 0 && (
                  <ListAdTwo />
                )}
                <ServiceRequestAnswerCard
                  sx={[{ mb: 2 }, index === offers.length - 1 && { mb: 0 }]}
                  key={offer.id}
                  item={offer}
                  onLike={handleLike}
                  onDislike={handleDislike}
                  onComment={navigateToAnswer(offer.id)}
                  menu={[
                    {
                      label: t('actions.view'),
                      onClick: navigateToAnswer(offer.id),
                    },
                    ...(offer.status === AnswerStatuses.DRAFT
                      ? draftActions
                      : []),
                    ...(offer.status === AnswerStatuses.PUBLISHED
                      ? publishedActions
                      : []),
                    ...(offer.status === AnswerStatuses.ACCEPTED
                      ? acceptedActions
                      : []),
                  ]}
                />
              </>
            );
          })}
        </InfiniteScroll>
      )}
    </>
  );
};

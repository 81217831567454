import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getNewsList,
  getNewsHasMore,
  getNewsFilters,
  changeFilters,
  resetList,
  getNewsLoaded,
  getNewsLoading,
} from 'store/features/news';
import {
  changeReaction,
  deleteNews,
  getNews,
  updateNewsList,
} from 'store/features/news/actions';
import { NewsLayout } from 'components/features/news/components/layout';
import { getAccount, getCurrent } from 'store/features/users';
import { ReactionType } from 'interfaces/Post';
import { NewsList } from 'components/features/news/components/NewsList';
import { NewsFilters, PAGE_SIZE } from 'interfaces/NewsFilters';
import { Filters } from 'components/features/news/containers/news/Filters';
import { useInterval } from 'utils/hooks';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Title = styled('div')({
  fontSize: '14px',
  fontWeight: 500,
  color: '#000000',
});

export const News = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const hasMore = useAppSelector(getNewsHasMore);
  const loading = useAppSelector(getNewsLoading);
  const loaded = useAppSelector(getNewsLoaded);
  const news = useAppSelector(getNewsList);
  const currentUser = useAppSelector(getCurrent);
  const account = useAppSelector(getAccount);
  const filters = useAppSelector(getNewsFilters);

  const isSystemAccount = account?.isSystemAccount;

  const handleChangeFilters = (value: NewsFilters) => {
    dispatch(
      changeFilters({
        ...filters,
        ...value,
        offset: 0,
        limit: PAGE_SIZE,
      }),
    );
  };

  const reloadPage = async () => {
    await dispatch(updateNewsList({ isSystemAccount }));
  };

  const handleNextPage = () => {
    dispatch(
      changeFilters({
        ...filters,
        offset: (filters.offset ?? 0) + PAGE_SIZE,
        limit: PAGE_SIZE,
      }),
    );
  };

  const handleDelete = async (id: string) => {
    await dispatch(deleteNews(id));
    await reloadPage();
  };

  const handleLike = (id: string) => {
    dispatch(changeReaction({ id, type: ReactionType.LIKE }));
  };

  const handleDislike = (id: string) => {
    dispatch(changeReaction({ id, type: ReactionType.DISLIKE }));
  };

  useEffect(() => {
    if (isSystemAccount == null) return;
    dispatch(getNews({ filters, isSystemAccount }));
  }, [dispatch, filters, isSystemAccount]);

  useEffect(() => {
    return () => void dispatch(resetList());
  }, [dispatch]);

  useInterval(loaded, reloadPage, 5000);

  return (
    <NewsLayout>
      {isSystemAccount && (
        <Filters
          sx={{
            mb: 3,
            pl: 4,
            pr: 4,
            pt: 2,
            pb: 2,
            borderRadius: (theme) => theme.spacing(1),
            bgcolor: 'background.paper',
            boxShadow: '1px 2px 3px rgb(0 0 0 / 10%)',
          }}
          filters={filters}
          onChange={handleChangeFilters}
        />
      )}
      {!news.length && !loading && loaded && (
        <Title>{t('news empty data')}</Title>
      )}
      <NewsList
        currentUserId={currentUser?.id}
        isSystemAccount={isSystemAccount}
        hasMore={hasMore}
        items={news}
        next={handleNextPage}
        onDelete={handleDelete}
        onLike={handleLike}
        onDislike={handleDislike}
      />
    </NewsLayout>
  );
};

import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrent } from 'store/features/users';
import { Loader } from 'components/shared/loader';
import { Question } from 'interfaces/Question';
import { QuestionCard } from './QuestionCard';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { styled, SxProps, Theme } from '@mui/system';
import { useAppSelector } from 'store';
import { ListAdOne, ListAdTwo } from '../../../elements/ads';

const Empty = styled('span')({
  margin: 'auto',
});

const List = styled('ul')({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: 0,
  border: 0,
  listStyle: 'none',

  '& li': {
    borderBottom: '1px solid #e4e4e4',
  },
});

type Props = {
  sx?: SxProps<Theme>;
  questions: Question[];
  loading?: boolean;
  refetching?: boolean;
  hasMore?: boolean;
  isMyQuestions?: boolean;
  next: () => void;
  onAnswer?: (id: string) => void;
  onLike?: (id: string) => void;
  onDislike?: (id: string) => void;
  onMore?: (id: string) => void;
  onClose?: (id: string) => void;
};

export const QuestionList = ({
  sx = [],
  questions,
  loading = false,
  refetching = false,
  hasMore = false,
  isMyQuestions = false,
  next,
  onAnswer,
  onLike,
  onDislike,
  onMore,
  onClose,
}: Props) => {
  const currentUser = useAppSelector(getCurrent);
  return (
    <List sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {loading && (
        <Empty>
          <Loader show />
        </Empty>
      )}

      {!loading && !questions.length && <Empty>Нет вопросов</Empty>}

      {!loading && !!questions.length && (
        <InfiniteScroll
          dataLength={questions.length}
          hasMore={hasMore}
          loader={<Loader show={refetching} global />}
          next={next}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {questions.map((question, index) => {
            const isSameCreator = question.creator.id === currentUser?.id;
            const mine = isMyQuestions || isSameCreator;

            return (
              <>
                {index % 4 === 0 && index % 8 !== 0 && index !== 0 && (
                  <ListAdOne />
                )}
                {index % 4 === 0 && index % 8 === 0 && index !== 0 && (
                  <ListAdTwo />
                )}
                <li key={question.id}>
                  <QuestionCard
                    question={question}
                    mine={mine}
                    onAnswer={onAnswer}
                    onLike={onLike}
                    onDislike={onDislike}
                    onMore={onMore}
                    onClose={onClose}
                  />
                </li>
              </>
            );
          })}
        </InfiniteScroll>
      )}
    </List>
  );
};

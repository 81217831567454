import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover,
  RadioGroup,
} from '@mui/material';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { RadioButton } from 'components/shared/radio';
import { startOfToday, subMonths, subWeeks } from 'date-fns';
import { Checkbox } from 'components/shared/checkbox';
import { useTranslation } from 'react-i18next';
import { INotificationsFilters } from 'interfaces/Notifications';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 24px',
});

const StyledButton = styled(Button)({
  borderRadius: 4,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: 12,
  color: '#000',
  textTransform: 'none',
  boxShadow: 'none',
});

const Title = styled(FormLabel)({
  marginBottom: 8,
  fontWeight: '600',
  fontSize: 12,
  color: '#4b4b4b',
});

const Label = styled(FormControlLabel)({
  color: '#4B4B4B',
  '& span': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
  },
});

const Radio = styled(RadioButton)({
  marginRight: 12,
  padding: 0,
});

type Props = {
  filters: INotificationsFilters;
  onChange: (filters: INotificationsFilters) => void;
};

export const NotificationsFilters = ({ filters, onChange }: Props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFromDateChange = (_: unknown, value: string) => {
    const current = value ? parseInt(value) : undefined;
    onChange({ ...filters, offset: 0, fromDate: current });
  };

  const handleChangeOnlyUnread = (_: unknown, checked: boolean) => {
    onChange({ ...filters, offset: 0, onlyUnread: checked });
  };

  const today = startOfToday();

  return (
    <Container>
      <StyledButton onClick={handleOpen}>
        <FilterIcon style={{ marginRight: 4 }} />
        {t('filters.title')}
      </StyledButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <FormControl sx={{ padding: '16px' }}>
          <Title>{t('filters.period.title')}</Title>
          <FormGroup>
            <RadioGroup
              value={filters.fromDate ? `${filters.fromDate}` : ''}
              onChange={handleFromDateChange}
            >
              <Label
                labelPlacement="end"
                value={''}
                control={<Radio />}
                label={t('filters.period.all')}
              />
              <Label
                labelPlacement="end"
                value={`${today.getTime()}`}
                control={<Radio />}
                label={t('filters.period.today')}
              />
              <Label
                labelPlacement="end"
                value={`${subWeeks(today, 1).getTime()}`}
                control={<Radio />}
                label={t('filters.period.week')}
              />
              <Label
                labelPlacement="end"
                value={`${subMonths(today, 1).getTime()}`}
                control={<Radio />}
                label={t('filters.period.month')}
              />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <FormControl sx={{ padding: '16px' }}>
          <Title>{t('filters.notifications.title')}</Title>
          <FormGroup>
            <Checkbox
              label={t('filters.notifications.onlyUnread')}
              checked={filters.onlyUnread}
              onChange={handleChangeOnlyUnread}
            />
          </FormGroup>
        </FormControl>
      </Popover>
    </Container>
  );
};

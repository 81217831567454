import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import {
  IAuthCredentials,
  IChangePasswordForm,
  ISignUpForm,
} from 'interfaces/Auth';

export const signinEndpoint = (
  credentials: IAuthCredentials,
): Promise<AxiosResponse> => {
  return apiClient.post('/signin', credentials);
};

export const signupEndpoint = (data: ISignUpForm): Promise<AxiosResponse> => {
  return apiClient.post('/signup', data);
};

export const restoreEndpoint = (email: string): Promise<AxiosResponse> => {
  return apiClient.post('/restore', { email });
};

export const changePasswordEndpoint = (
  data: IChangePasswordForm,
): Promise<AxiosResponse> => {
  return apiClient.post('/change_password', data);
};

export const logoutEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get('/logout');
};

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateQuestionForm } from './components/CreateQuestionForm';
import { Layout } from './layout/Layout';

export const CreateQuestion = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Layout>
      <CreateQuestionForm
        sx={{
          border: '1px solid #e4e4e4',
          borderRadius: 0.5,
          backgroundColor: '#fff',
        }}
        receiverBusinessCardId={id}
        onCancel={goBack}
        onSuccess={goBack}
      />
    </Layout>
  );
};

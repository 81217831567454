import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store';
import { AxiosResponse } from 'axios';

import { IComment, INews } from 'interfaces/News';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { SelectTextFields } from 'components/shared/selects';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';

import { IMultiLanguage } from 'interfaces/Locale';
import { IAttachment, AttachmentType } from 'interfaces/Attachment';
import { getBusinessPublishedList } from 'store/features/business';
import { getBusinessPublished } from 'store/features/business/actions';
import {
  createComment,
  editComment,
  uploadAttachments,
} from 'store/features/news/actions';
import { newsCommentFileTypes } from 'defenitions/fileUploader';

import { serviceDataValidationSchema } from './validationSchema';
import { URLS } from 'defenitions/routes';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { selectSoftwareCardAccount } from 'store/features/software';
import { getSoftwareCardAccount } from 'store/features/software/actions';

const FormWrap = styled('div')({
  background: '#F0F1F3',
  border: '1px solid #E4E4E4',
  padding: '12px 24px',
  marginTop: '5px',
});

const CommentButtonWrap = styled('div')({
  width: '203px',
  marginTop: '7px',
});

const InputWrap = styled('div')({
  marginBottom: '5px',
});

interface CreateCommentForm {
  content: IMultiLanguage;
  businessCardId: string;
  softwareCardIds?: IDictionariesContent[];
}

export const CommentForm = ({
  newId,
  onSaveComment,
  comment,
}: {
  newId: INews['id'];
  onSaveComment?: () => void;
  comment?: IComment;
}) => {
  const language = 'ru';

  const dispatch = useAppDispatch();
  const businessCards = useAppSelector(getBusinessPublishedList);
  const softwareCards = useAppSelector(selectSoftwareCardAccount);
  const [attachments, setAttachments] = useState<IAttachment[]>(
    comment?.attachments || [],
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreateCommentForm>({
    mode: 'onChange',
    resolver: attachments.length
      ? undefined
      : yupResolver(serviceDataValidationSchema),
    defaultValues: {
      content: comment?.content,
      businessCardId: comment?.businessCard?.id,
    },
  });
  const formData = watch();

  const canSendForm = useMemo(
    () =>
      attachments.length > 0 ||
      formData.content?.ru?.length > 0 ||
      formData.content?.en?.length > 0,
    [formData, attachments],
  );

  const businessOptions = useMemo(
    () =>
      businessCards.map((item) => ({
        id: item?.id,
        name: item?.name?.[language],
      })),
    [businessCards],
  );

  const softwareOptions: IDictionariesContent[] = useMemo(
    () =>
      softwareCards.map((item) => ({
        code: item.id,
        display: item.name,
        selectable: true,
      })),
    [softwareCards],
  );

  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      if (formData.get('files') === null) return;
      const { payload } = await dispatch(
        uploadAttachments({ formData, type: AttachmentType.Comment }),
      );
      const { data } = payload as AxiosResponse<IAttachment[]>;
      data && setAttachments([...attachments, ...data]);
    },
    [dispatch, attachments],
  );

  useEffect(() => {
    dispatch(getBusinessPublished());
    dispatch(getSoftwareCardAccount());
  }, [dispatch]);

  const handleRemoveAttachment = useCallback(
    (id) => {
      setAttachments(attachments.filter((attachment) => attachment.id !== id));
    },
    [attachments],
  );

  const onSubmit = useCallback(
    async (data: CreateCommentForm) => {
      const commentData = {
        ...data,
        softwareCardIds: data.softwareCardIds?.map((el) => el.code),
        attachments: attachments.map((item) => item.id),
      };
      if (comment) {
        await dispatch(editComment({ ...commentData, id: comment.id })).then(
          () => {
            onSaveComment?.();
          },
        );
      } else {
        await dispatch(
          createComment({ ...commentData, relationId: newId }),
        ).then(() => {
          onSaveComment?.();
        });
      }

      reset({ businessCardId: watch('businessCardId') });
    },
    [attachments, comment, reset, watch, dispatch, onSaveComment, newId],
  );

  const handleSoftwareCardsOptions = useCallback(() => {
    dispatch(getSoftwareCardAccount());
  }, [dispatch]);

  useEffect(() => {
    businessOptions.length === 1 &&
      setValue('businessCardId', businessOptions[0].id);
    trigger('businessCardId');
  }, [businessOptions, setValue, trigger]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    trigger('content');
  }, [attachments]);
  return (
    <FormWrap>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrap>
          <Controller
            name="businessCardId"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <SelectTextFields
                {...rest}
                options={businessOptions}
                inputRef={ref}
                label={'Бизнес'}
                errorMessage={errors.businessCardId?.message}
                createLink={URLS.createBusinessCard}
                disabled={!!businessOptions.length}
              />
            )}
          />
        </InputWrap>
        <InputWrap>
          <Controller
            control={control}
            name="softwareCardIds"
            defaultValue={[]}
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value ?? null}
                noHierarchy
                inputRef={ref}
                label="ПО"
                multiple
                data={softwareOptions}
                onLoadOptions={handleSoftwareCardsOptions}
              />
            )}
          />
        </InputWrap>
        <InputWrap>
          <FormMultiLocaleField
            fieldName={'content'}
            label={'Написать комментарий'}
            errors={errors}
            control={control}
            minRows={2}
            multiline
            isEditor
          />
        </InputWrap>
      </form>
      <FilesUploader
        onClickRemoveAttachments={handleRemoveAttachment}
        onUploadAttachments={handleUploadAttachments}
        attachments={attachments}
        hideTitle
        maxFiles={3}
        accept={newsCommentFileTypes}
      />
      <CommentButtonWrap>
        <ActionButton
          fullWidth
          disabled={isSubmitting || !isValid || !canSendForm}
          text={comment ? 'Изменить' : 'Комментировать'}
          type={'submit'}
          onClick={handleSubmit(onSubmit)}
        />
      </CommentButtonWrap>
    </FormWrap>
  );
};

import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { IOffersName, IOffersStatus } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCellOffers = (
  isOutgoing?: boolean,
  hideDraft?: boolean,
): HeadCell[] => {
  const draftField = !hideDraft
    ? [
        {
          id: 'draftAnswers',
          label: i18n.t('tables.draftAnswers'),
          numeric: true,
        },
      ]
    : [];
  return [
    { id: 'offerStatus', label: i18n.t('tables.offerStatus'), numeric: false },
    {
      id: isOutgoing ? 'offers' : 'answers',
      label: i18n.t('tables.allAnswers'),
      numeric: true,
    },
    {
      id: isOutgoing ? 'answers' : 'offers',
      label: i18n.t('tables.allOffers'),
      numeric: true,
    },
    ...draftField,
    {
      id: 'publishedAnswers',
      label: i18n.t('tables.publishedAnswers'),
      numeric: true,
    },
    {
      id: 'acceptedAnswers',
      label: i18n.t('tables.acceptedAnswers'),
      numeric: true,
    },
    {
      id: 'inWorkAnswers',
      label: i18n.t('tables.inWorkAnswers'),
      numeric: true,
    },
    {
      id: 'completedAnswers',
      label: i18n.t('tables.completedAnswers'),
      numeric: true,
    },
    {
      id: 'canceledAnswers',
      label: i18n.t('tables.canceledAnswers'),
      numeric: true,
    },
    {
      id: 'declinedAnswers',
      label: i18n.t('tables.declinedAnswers'),
      numeric: true,
    },
  ];
};

export const createDataOffers = (data: IOffersStatus[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.offerStatus,
      offerStatus: i18n.t(`marketplace.statuses.${el.offerStatus}`),
    };
  });

export const createHeadCellAnswers = (
  isOutgoing?: boolean,
  hideDraft?: boolean,
): HeadCell[] => {
  const draftField = !hideDraft
    ? [
        {
          id: 'draftAnswers',
          label: i18n.t('tables.draftAnswers'),
          numeric: true,
        },
      ]
    : [];
  return [
    { id: 'name', label: i18n.t('tables.name'), numeric: false },
    {
      id: 'type',
      label: i18n.t('tables.offerType'),
      numeric: false,
    },
    {
      id: 'answers',
      label: i18n.t('tables.allOffers'),
      numeric: true,
    },
    ...draftField,
    {
      id: 'publishedAnswers',
      label: i18n.t('tables.publishedAnswers'),
      numeric: true,
    },
    {
      id: 'acceptedAnswers',
      label: i18n.t('tables.acceptedAnswers'),
      numeric: true,
    },
    {
      id: 'inWorkAnswers',
      label: i18n.t('tables.inWorkAnswers'),
      numeric: true,
    },
    {
      id: 'completedAnswers',
      label: i18n.t('tables.completedAnswers'),
      numeric: true,
    },
    {
      id: 'canceledAnswers',
      label: i18n.t('tables.canceledAnswers'),
      numeric: true,
    },
    {
      id: 'declinedAnswers',
      label: i18n.t('tables.declinedAnswers'),
      numeric: true,
    },
  ];
};

export const createDataAnswers = (data: IOffersName[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.offerId,
      name: getLocaleValue(el.name),
      type: i18n.t(`AnswerType.${el.type}`),
    };
  });

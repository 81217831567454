import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import {
  IMarketplaceState,
  initialMarketplaceState,
} from 'store/features/marketplace/config';
import {
  closeServiceRequest,
  createOfferComment,
  getAllAnswersForServiceRequest,
  getAnswerRequestById,
  getIncomingServiceRequests,
  getMyOffers,
  getMyServiceRequests,
  getPublishedServiceRequests,
  getServiceById,
  getServiceRequestById,
  getServices,
  likeComment,
  likeService,
  likeServiceOffer,
  likeServiceRequest,
  marketplaceGetServicePageGetList,
  publishServiceRequest,
  marketplaceReviewAnswer,
  marketplaceGetReviewsAnswer,
  editOfferComment,
} from 'store/features/marketplace/actions';
import { AxiosResponse } from 'axios';
import {
  IAnswer,
  IService,
  IServiceRequest,
  Reviews,
} from 'interfaces/Marketplace';
import { RootState } from 'store';
import { PAGE_SIZE } from 'interfaces/NewsFilters';
import { IComment } from 'interfaces/Comment';
import Snackbar from 'services/Snackbar';
import i18next from 'services/i18n';
import { ReactionType } from 'interfaces/Post';

const marketplaceSlice = createSlice({
  name: Slices.marketplace,
  initialState: initialMarketplaceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getPublishedServiceRequests.pending,
      (state: IMarketplaceState, { meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        state.list.loading = true;
        state.list.loaded = false;
        state.list.hasMore = offset === 0 || state.list.hasMore;
      },
    );
    builder.addCase(
      getPublishedServiceRequests.fulfilled,
      (state: IMarketplaceState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<Array<IServiceRequest>>;
        state.list.requests =
          offset === 0 ? data : [...state.list.requests, ...data];
        state.list.loading = false;
        state.list.loaded = true;
        state.list.hasMore = data.length !== 0;
      },
    );
    builder.addCase(
      getPublishedServiceRequests.rejected,
      (state: IMarketplaceState) => {
        state.list.loading = false;
        state.list.loaded = false;
        state.list.hasMore = false;
      },
    );
    builder.addCase(
      getServices.pending,
      (state: IMarketplaceState, { meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        state.list.loading = true;
        state.list.loaded = false;
        state.list.hasMore = offset === 0 || state.list.hasMore;
      },
    );
    builder.addCase(
      getServices.fulfilled,
      (state: IMarketplaceState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<Array<IService>>;
        state.list.services =
          offset === 0 ? data : [...state.list.services, ...data];
        state.list.loading = false;
        state.list.loaded = true;
        state.list.hasMore = data.length !== 0;
      },
    );
    builder.addCase(getServices.rejected, (state: IMarketplaceState) => {
      state.list.loading = false;
      state.list.loaded = false;
      state.list.hasMore = false;
    });
    builder.addCase(
      getMyServiceRequests.pending,
      (state: IMarketplaceState, { meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        state.list.loading = true;
        state.list.loaded = false;
        state.list.hasMore = offset === 0 || state.list.hasMore;
      },
    );
    builder.addCase(
      getMyServiceRequests.fulfilled,
      (state: IMarketplaceState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<Array<IServiceRequest>>;
        state.list.requests =
          offset === 0 ? data : [...state.list.requests, ...data];
        state.list.loading = false;
        state.list.loaded = true;
        state.list.hasMore = data.length !== 0;
      },
    );
    builder.addCase(
      getMyServiceRequests.rejected,
      (state: IMarketplaceState) => {
        state.list.loading = false;
        state.list.loaded = false;
        state.list.hasMore = false;
      },
    );
    builder.addCase(
      getIncomingServiceRequests.pending,
      (state: IMarketplaceState, { meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        state.list.loading = true;
        state.list.loaded = false;
        state.list.hasMore = offset === 0 || state.list.hasMore;
      },
    );
    builder.addCase(
      getIncomingServiceRequests.fulfilled,
      (state: IMarketplaceState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<Array<IServiceRequest>>;
        state.list.requests =
          offset === 0 ? data : [...state.list.requests, ...data];
        state.list.loading = false;
        state.list.loaded = true;
        state.list.hasMore = data.length !== 0;
      },
    );
    builder.addCase(
      getIncomingServiceRequests.rejected,
      (state: IMarketplaceState) => {
        state.list.loading = false;
        state.list.loaded = false;
        state.list.hasMore = false;
      },
    );

    builder.addCase(
      getMyOffers.pending,
      (state: IMarketplaceState, { meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        state.answersList.loading = true;
        state.answersList.loaded = false;
        state.answersList.hasMore = offset === 0 || state.answersList.hasMore;
      },
    );
    builder.addCase(
      getMyOffers.fulfilled,
      (state: IMarketplaceState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<Array<IAnswer>>;
        state.answersList.items =
          offset === 0 ? data : [...state.answersList.items, ...data];
        state.answersList.loading = false;
        state.answersList.loaded = true;
        state.answersList.hasMore = data.length !== 0;
      },
    );
    builder.addCase(getMyOffers.rejected, (state: IMarketplaceState) => {
      state.answersList.loading = false;
      state.answersList.loaded = false;
      state.answersList.hasMore = false;
    });

    builder.addCase(
      getServiceRequestById.pending,
      (state: IMarketplaceState) => {
        state.item.loading = true;
      },
    );
    builder.addCase(
      getServiceRequestById.fulfilled,
      (state: IMarketplaceState, { payload }) => {
        const { data } = payload as AxiosResponse<IServiceRequest>;
        state.item.loading = false;
        state.item.loaded = true;
        state.item.request = data;
      },
    );
    builder.addCase(
      getServiceRequestById.rejected,
      (state: IMarketplaceState) => {
        state.item.loading = false;
      },
    );
    builder.addCase(getServiceById.pending, (state: IMarketplaceState) => {
      state.item.loading = true;
    });
    builder.addCase(
      getServiceById.fulfilled,
      (state: IMarketplaceState, { payload }) => {
        const { data } = payload as AxiosResponse<IService>;
        state.item.loading = false;
        state.item.loaded = true;
        state.item.service = data;
      },
    );
    builder.addCase(getServiceById.rejected, (state: IMarketplaceState) => {
      state.item.loading = false;
    });
    builder.addCase(
      getAnswerRequestById.pending,
      (state: IMarketplaceState) => {
        state.answerItem.loading = true;
      },
    );
    builder.addCase(
      getAnswerRequestById.fulfilled,
      (state: IMarketplaceState, { payload }) => {
        const { data } = payload as AxiosResponse<IAnswer>;
        state.answerItem.loading = false;
        state.answerItem.loaded = true;
        state.answerItem.answer = data;
      },
    );
    builder.addCase(
      getAnswerRequestById.rejected,
      (state: IMarketplaceState) => {
        state.answerItem.loading = false;
      },
    );
    builder.addCase(
      marketplaceGetServicePageGetList.pending,
      (state: IMarketplaceState, { meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        state.getServiceItems.loading = true;
        state.getServiceItems.loaded = false;
        state.getServiceItems.hasMore =
          offset === 0 || state.getServiceItems.hasMore;
      },
    );
    builder.addCase(
      marketplaceGetServicePageGetList.fulfilled,
      (state: IMarketplaceState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<Array<IAnswer>>;
        state.getServiceItems.items =
          offset === 0 ? data : [...state.getServiceItems.items, ...data];
        state.getServiceItems.loading = false;
        state.getServiceItems.loaded = true;
        state.getServiceItems.hasMore = data.length !== 0;
      },
    );
    builder.addCase(
      marketplaceGetServicePageGetList.rejected,
      (state: IMarketplaceState) => {
        state.getServiceItems.loading = false;
        state.getServiceItems.loaded = false;
        state.getServiceItems.hasMore = false;
      },
    );
    builder.addCase(
      getAllAnswersForServiceRequest.pending,
      (state: IMarketplaceState) => {
        state.answersList.loading = true;
      },
    );
    builder.addCase(
      getAllAnswersForServiceRequest.rejected,
      (state: IMarketplaceState) => {
        state.answersList.loading = false;
      },
    );
    builder.addCase(
      publishServiceRequest.fulfilled,
      (state: IMarketplaceState, { payload }) => {
        const { data } = payload as AxiosResponse<IServiceRequest>;

        if (!state.item.request) return;

        state.item.request = data;
      },
    );
    builder.addCase(
      closeServiceRequest.fulfilled,
      (state: IMarketplaceState, { payload }) => {
        const { data } = payload as AxiosResponse<IServiceRequest>;

        if (!state.item.request) return;

        state.item.request = data;
      },
    );
    builder.addCase(
      getAllAnswersForServiceRequest.fulfilled,
      (state: IMarketplaceState, { payload }) => {
        const { data } = payload as AxiosResponse;
        const hasMore = data.length && data.length % PAGE_SIZE === 0;

        return {
          ...state,
          answersList: {
            ...state.answersList,
            items: data,
            hasMore,
            loading: false,
          },
        };
      },
    );
    builder.addCase(likeComment.pending, (state, { meta }) => {
      const { id, type } = meta.arg;

      const comments = state.answerItem.answer?.comments ?? [];
      const post = comments.find((comment) => comment.id === id);

      if (post?.id === id) {
        post.reactions = post.reactions ?? {};

        post.reactions[ReactionType.LIKE] = post.reactions[
          ReactionType.LIKE
        ] ?? {
          count: 0,
          hasByUser: false,
        };

        post.reactions[ReactionType.DISLIKE] = post.reactions[
          ReactionType.DISLIKE
        ] ?? {
          count: 0,
          hasByUser: false,
        };

        if (type === ReactionType.LIKE) {
          const dislikes = post.reactions[ReactionType.DISLIKE]!;
          const likes = post.reactions[ReactionType.LIKE]!;

          likes.count += likes.hasByUser ? -1 : 1;
          likes.hasByUser = !likes.hasByUser;

          dislikes.count -= dislikes.hasByUser ? 1 : 0;
          dislikes.hasByUser = false;
        }

        if (type === ReactionType.DISLIKE) {
          const dislikes = post.reactions[ReactionType.DISLIKE]!;
          const likes = post.reactions[ReactionType.LIKE]!;

          dislikes.count += dislikes.hasByUser ? -1 : 1;
          dislikes.hasByUser = !dislikes.hasByUser;

          likes.count -= likes.hasByUser ? 1 : 0;
          likes.hasByUser = false;
        }
      }
    });
    builder.addCase(likeComment.rejected, (state, { meta }) => {
      const { id, type } = meta.arg;

      const comments = state.answerItem.answer?.comments ?? [];
      const post = comments.find((comment) => comment.id === id);

      if (post?.id === id) {
        post.reactions = post.reactions ?? {};

        post.reactions[ReactionType.LIKE] = post.reactions[
          ReactionType.LIKE
        ] ?? {
          count: 0,
          hasByUser: false,
        };

        post.reactions[ReactionType.DISLIKE] = post.reactions[
          ReactionType.DISLIKE
        ] ?? {
          count: 0,
          hasByUser: false,
        };

        if (type === ReactionType.LIKE) {
          const dislikes = post.reactions[ReactionType.DISLIKE]!;
          const likes = post.reactions[ReactionType.LIKE]!;

          likes.count += likes.hasByUser ? -1 : 1;
          likes.hasByUser = !likes.hasByUser;

          dislikes.count -= dislikes.hasByUser ? 1 : 0;
          dislikes.hasByUser = false;
        }

        if (type === ReactionType.DISLIKE) {
          const dislikes = post.reactions[ReactionType.DISLIKE]!;
          const likes = post.reactions[ReactionType.LIKE]!;

          dislikes.count += dislikes.hasByUser ? -1 : 1;
          dislikes.hasByUser = !dislikes.hasByUser;

          likes.count -= likes.hasByUser ? 1 : 0;
          likes.hasByUser = false;
        }
      }
    });
    builder.addCase(createOfferComment.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<IComment>;

      if (state.answerItem.answer) {
        state.answerItem.answer.comments = [
          { ...data },
          ...(state.answerItem.answer.comments ?? []),
        ];
      }
    });
    builder.addCase(editOfferComment.fulfilled, (state, { payload, meta }) => {
      const { id } = meta.arg;
      const { data } = payload as AxiosResponse<IComment>;

      if (state.answerItem.answer?.comments) {
        const index = state.answerItem.answer.comments.findIndex(
          (comment) => comment.id === id,
        );

        if (index !== -1) {
          state.answerItem.answer.comments = [
            ...state.answerItem.answer.comments.slice(0, index),
            data,
            ...state.answerItem.answer.comments.slice(index + 1),
          ];
        }
      }
    });
    builder.addCase(likeService.pending, (state, { meta }) => {
      const { id, type } = meta.arg;

      const posts = [
        state.getServiceItems.items.find((r) => r.id === id),
        ...(state.answerItem.answer?.id === id
          ? [state.answerItem.answer]
          : []),
      ];

      for (const post of posts) {
        if (post?.id === id) {
          post.reactions = post.reactions ?? {};

          post.reactions[ReactionType.LIKE] = post.reactions[
            ReactionType.LIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          post.reactions[ReactionType.DISLIKE] = post.reactions[
            ReactionType.DISLIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          if (type === ReactionType.LIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            likes.count += likes.hasByUser ? -1 : 1;
            likes.hasByUser = !likes.hasByUser;

            dislikes.count -= dislikes.hasByUser ? 1 : 0;
            dislikes.hasByUser = false;
          }

          if (type === ReactionType.DISLIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            dislikes.count += dislikes.hasByUser ? -1 : 1;
            dislikes.hasByUser = !dislikes.hasByUser;

            likes.count -= likes.hasByUser ? 1 : 0;
            likes.hasByUser = false;
          }
        }
      }
    });
    builder.addCase(likeService.rejected, (state, { meta }) => {
      const { id, type } = meta.arg;

      const posts = [
        state.getServiceItems.items.find((r) => r.id === id),
        ...(state.answerItem.answer?.id === id
          ? [state.answerItem.answer]
          : []),
      ];

      for (const post of posts) {
        if (post?.id === id) {
          post.reactions = post.reactions ?? {};

          post.reactions[ReactionType.LIKE] = post.reactions[
            ReactionType.LIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          post.reactions[ReactionType.DISLIKE] = post.reactions[
            ReactionType.DISLIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          if (type === ReactionType.LIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            likes.count += likes.hasByUser ? -1 : 1;
            likes.hasByUser = !likes.hasByUser;

            dislikes.count -= dislikes.hasByUser ? 1 : 0;
            dislikes.hasByUser = false;
          }

          if (type === ReactionType.DISLIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            dislikes.count += dislikes.hasByUser ? -1 : 1;
            dislikes.hasByUser = !dislikes.hasByUser;

            likes.count -= likes.hasByUser ? 1 : 0;
            likes.hasByUser = false;
          }
        }
      }
    });
    builder.addCase(likeServiceRequest.pending, (state, { meta }) => {
      const { id, type } = meta.arg;

      const posts = [
        state.list.requests.find((r) => r.id === id),
        ...(state.item.request?.id === id ? [state.item.request] : []),
      ];

      for (const post of posts) {
        if (post?.id === id) {
          post.reactions = post.reactions ?? {};

          post.reactions[ReactionType.LIKE] = post.reactions[
            ReactionType.LIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          post.reactions[ReactionType.DISLIKE] = post.reactions[
            ReactionType.DISLIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          if (type === ReactionType.LIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            likes.count += likes.hasByUser ? -1 : 1;
            likes.hasByUser = !likes.hasByUser;

            dislikes.count -= dislikes.hasByUser ? 1 : 0;
            dislikes.hasByUser = false;
          }

          if (type === ReactionType.DISLIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            dislikes.count += dislikes.hasByUser ? -1 : 1;
            dislikes.hasByUser = !dislikes.hasByUser;

            likes.count -= likes.hasByUser ? 1 : 0;
            likes.hasByUser = false;
          }
        }
      }
    });
    builder.addCase(likeServiceRequest.rejected, (state, { meta }) => {
      const { id, type } = meta.arg;

      const posts = [
        state.list.requests.find((r) => r.id === id),
        ...(state.item.request?.id === id ? [state.item.request] : []),
      ];

      for (const post of posts) {
        if (post?.id === id) {
          post.reactions = post.reactions ?? {};

          post.reactions[ReactionType.LIKE] = post.reactions[
            ReactionType.LIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          post.reactions[ReactionType.DISLIKE] = post.reactions[
            ReactionType.DISLIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          if (type === ReactionType.LIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            likes.count += likes.hasByUser ? -1 : 1;
            likes.hasByUser = !likes.hasByUser;

            dislikes.count -= dislikes.hasByUser ? 1 : 0;
            dislikes.hasByUser = false;
          }

          if (type === ReactionType.DISLIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            dislikes.count += dislikes.hasByUser ? -1 : 1;
            dislikes.hasByUser = !dislikes.hasByUser;

            likes.count -= likes.hasByUser ? 1 : 0;
            likes.hasByUser = false;
          }
        }
      }
    });
    builder.addCase(likeServiceOffer.pending, (state, { meta }) => {
      const { id, type } = meta.arg;

      const posts = [
        state.answersList.items.find((r) => r.id === id),
        ...(state.answerItem.answer?.id === id ? [state.item.request] : []),
      ];

      for (const post of posts) {
        if (post?.id === id) {
          post.reactions = post.reactions ?? {};

          post.reactions[ReactionType.LIKE] = post.reactions[
            ReactionType.LIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          post.reactions[ReactionType.DISLIKE] = post.reactions[
            ReactionType.DISLIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          if (type === ReactionType.LIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            likes.count += likes.hasByUser ? -1 : 1;
            likes.hasByUser = !likes.hasByUser;

            dislikes.count -= dislikes.hasByUser ? 1 : 0;
            dislikes.hasByUser = false;
          }

          if (type === ReactionType.DISLIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            dislikes.count += dislikes.hasByUser ? -1 : 1;
            dislikes.hasByUser = !dislikes.hasByUser;

            likes.count -= likes.hasByUser ? 1 : 0;
            likes.hasByUser = false;
          }
        }
      }
    });
    builder.addCase(likeServiceOffer.rejected, (state, { meta }) => {
      const { id, type } = meta.arg;

      const posts = [
        state.answersList.items.find((r) => r.id === id),
        ...(state.answerItem.answer?.id === id ? [state.item.request] : []),
      ];

      for (const post of posts) {
        if (post?.id === id) {
          post.reactions = post.reactions ?? {};

          post.reactions[ReactionType.LIKE] = post.reactions[
            ReactionType.LIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          post.reactions[ReactionType.DISLIKE] = post.reactions[
            ReactionType.DISLIKE
          ] ?? {
            count: 0,
            hasByUser: false,
          };

          if (type === ReactionType.LIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            likes.count += likes.hasByUser ? -1 : 1;
            likes.hasByUser = !likes.hasByUser;

            dislikes.count -= dislikes.hasByUser ? 1 : 0;
            dislikes.hasByUser = false;
          }

          if (type === ReactionType.DISLIKE) {
            const dislikes = post.reactions[ReactionType.DISLIKE]!;
            const likes = post.reactions[ReactionType.LIKE]!;

            dislikes.count += dislikes.hasByUser ? -1 : 1;
            dislikes.hasByUser = !dislikes.hasByUser;

            likes.count -= likes.hasByUser ? 1 : 0;
            likes.hasByUser = false;
          }
        }
      }
    });
    builder.addCase(marketplaceReviewAnswer.fulfilled, () => {
      Snackbar.show(i18next.t('notification.review created'), 'success');
    });
    builder.addCase(
      marketplaceGetReviewsAnswer.fulfilled,
      (state: IMarketplaceState, { payload }) => {
        const { data } = payload as AxiosResponse<Reviews>;
        state.reviews = data;
      },
    );
  },
});

const self = (state: RootState) => state.marketplace;

export const getServiceRequest = createSelector(
  self,
  (state) => state.item.request,
);

export const getService = createSelector(self, (state) => state.item.service);

export const isMarketplaceItemLoading = createSelector(
  self,
  (state) => state.item.loading,
);

export const getRequestAnswer = createSelector(
  self,
  (state) => state.answerItem.answer,
);

export const isRequestAnswerLoading = createSelector(
  self,
  (state) => state.answerItem.loading,
);

export const getServiceRequestsList = createSelector(
  self,
  (state) => state.list.requests,
);

export const getServicesList = createSelector(
  self,
  (state) => state.list.services,
);

export const isMarketplaceListLoading = createSelector(
  self,
  (state) => state.list.loading,
);

export const hasMoreMarketplaceListItems = createSelector(
  self,
  (state) => state.list.hasMore,
);

export const isGetServiceItemsListLoading = createSelector(
  self,
  (state) => state.getServiceItems.loading,
);

export const getServiceItemsList = createSelector(
  self,
  (state) => state.getServiceItems.items,
);

export const getAnswersItemsList = createSelector(
  self,
  (state) => state.answersList.items,
);

export const isLoadingAnswersItemsList = createSelector(
  self,
  (state) => state.answersList.loading,
);

export const hasMoreAnswersItemsList = createSelector(
  self,
  (state) => state.answersList.hasMore,
);

export const hasMoreGetServiceItems = createSelector(
  self,
  (state) => state.getServiceItems.hasMore,
);

export const getReviews = createSelector(self, (state) => state.reviews);

export { marketplaceSlice };

import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import { getSoftwareTypesDictionaries } from 'store/features/dictionaries';
import { getDictionariesContentSoftwareTypes } from 'store/features/dictionaries/actions';
import { Checkbox } from 'components/shared/checkbox';
import { styled } from '@mui/material/styles';
import { IDictionaries } from 'interfaces/Dictionaries';
import { uniq, without } from 'lodash';
import { getLocaleValue } from 'utils/form';

const Title = styled('div')({
  color: '#4B4B4B',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
  display: 'flex',
  alignItems: 'center',
});

type Props = {
  software: Array<IDictionaries['id']>;
  onChange?: (checked: Array<IDictionaries['id']>) => void;
};

export const SoftwareTypes = ({ software, onChange }: Props) => {
  const dispatch = useAppDispatch();
  const softwareTypes = useAppSelector(getSoftwareTypesDictionaries);

  useEffect(() => {
    dispatch(getDictionariesContentSoftwareTypes());
  }, [dispatch]);

  const handleChangeCategory =
    (id: string) => (_: unknown, checked: boolean) => {
      const category = softwareTypes.find((s) => s.code === id);

      if (!category) return;

      const value = checked
        ? uniq([...software, category.code])
        : without(software, category.code);

      onChange?.(value);
    };

  const isCheckedCategory = (id: string) => {
    return software.includes(id);
  };

  return (
    <Box>
      {softwareTypes.map((item) => (
        <Grid container key={item.code} direction={'column'}>
          <Title>
            <Checkbox
              label={getLocaleValue(item.display)}
              checked={isCheckedCategory(item.code)}
              onChange={handleChangeCategory(item.code)}
            />
          </Title>
        </Grid>
      ))}
    </Box>
  );
};

import React from 'react';
import Grid from '@mui/material/Grid';
import {
  Container,
  Divider,
} from 'components/features/business/shared/item/components';
import { attachUrl } from 'services/ApiClient';
import { Field, Image, Value } from 'components/elements/viewFields';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { ViewField } from 'components/elements/viewFields/ViewField';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { format } from 'date-fns';
import { getLocaleValue } from 'utils/form';
import { useTranslation } from 'react-i18next';
import { ISoftware } from 'interfaces/Software';

type Props = {
  softwareCard: ISoftware | null;
  onClickCancel: () => void;
  onClickNext: () => void;
};

export const CommonDataView = ({
  softwareCard,
  onClickCancel,
  onClickNext,
}: Props) => {
  const {
    hidden,
    createdDateTime,
    logo,
    name,
    rate,
    softwareTypes,
    applicationAreas,
    description,
    contactPerson,
    contactPhone,
    attachments,
    programmingLanguages,
    technologyStack,
  } = softwareCard || {};
  const { t } = useTranslation();
  const type =
    softwareTypes &&
    softwareTypes.map(({ display }) => getLocaleValue(display)).join(', ');

  const areas =
    applicationAreas &&
    applicationAreas.map(({ display }) => getLocaleValue(display)).join(', ');

  const languages =
    programmingLanguages &&
    programmingLanguages
      .map(({ display }) => getLocaleValue(display))
      .join(', ');

  const stack =
    technologyStack &&
    technologyStack.map(({ display }) => getLocaleValue(display)).join(', ');
  return (
    <Container container spacing={4}>
      <Grid item container xs={4}>
        <Grid item xs={12}>
          {logo?.path && (
            <Image src={`${attachUrl}${softwareCard?.logo?.path}`} />
          )}
          <Grid container>
            <Grid item xs={6} alignSelf={'center'}>
              <Field>{`${t('card hide')}:`}</Field>
            </Grid>
            <Grid item xs={6} alignSelf={'center'}>
              <Value>{`${
                hidden ? t(`boolean.${hidden}`) : t(`boolean.false`)
              }`}</Value>
            </Grid>
            {createdDateTime && (
              <>
                <Grid item xs={6} alignSelf={'center'}>
                  <Field>Дата регистрация:</Field>
                </Grid>
                <Grid item xs={6} alignSelf={'center'}>
                  <Value>
                    {format(new Date(createdDateTime), 'dd.MM.yyyy')}
                  </Value>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={8} spacing={3}>
        <ViewField
          title={'О ПО'}
          fields={[
            {
              field: 'Наименование ПО:',
              value: getLocaleValue(name),
            },
            {
              field: 'Рейтинг:',
              value: rate?.toString(),
            },
            {
              field: 'Тип ПО:',
              value: type,
            },
            {
              field: 'Области применения:',
              value: areas,
            },
            {
              field: 'Описание:',
              value: getLocaleValue(description),
              isEditor: true,
            },
          ]}
        />
        {!hidden && (
          <ViewField
            title={'О технологиях:'}
            fields={[
              {
                field: 'Язык программирования:',
                value: languages,
              },
              {
                field: 'Технологии:',
                value: stack,
              },
            ]}
          />
        )}
        {(contactPhone || contactPerson) && (
          <ViewField
            title={'Контактное лицо'}
            fields={[
              {
                field: 'Контактное лицо:',
                value: getLocaleValue(contactPerson),
              },
              {
                field: 'Контактный телефон:',
                value: contactPhone,
              },
            ]}
          />
        )}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          {attachments && <Thumbs attachments={attachments} />}
        </Grid>
      </Grid>
      <Divider />
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            text={t('actions.back')}
            onClick={onClickCancel}
          />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            onClick={onClickNext}
            text={t('actions.next')}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { StyledDialog } from 'components/elements/modals/components';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { DialogTitle } from '@mui/material';

type Props = {
  open: boolean;
  onClickClose: () => void;
  onClickAccept: () => void;
};
export const AcceptDialog = ({ open, onClickClose, onClickAccept }: Props) => {
  return (
    <StyledDialog open={open} onClose={onClickClose}>
      <DialogTitle>Принять ответ</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Вы точно хотите принять ответ? Данное действие будет невозможно
          отменить!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CreateButton
          text={'Отменить'}
          fullWidth
          autoFocus
          onClick={onClickClose}
        />
        <ActionButton
          text={'Принять'}
          fullWidth
          autoFocus
          onClick={onClickAccept}
        />
      </DialogActions>
    </StyledDialog>
  );
};

import React, { useState } from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Collapse, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IAccountRating } from 'interfaces/Complaint';
import { UserCard } from './UserCard';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: '12px 32px 8px',
});

const Name = styled('p')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  margin: 0,
  padding: 0,
  fontWeight: '500',
  fontSize: 14,
  color: '#00618e',
  cursor: 'pointer',
});

const Info = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const Collapsed = styled('div')({
  padding: '8px 32px',
  borderTop: '1px solid #dddddd',
});

type Props = {
  item: IAccountRating;
  onAccountRatingClick?: (accountId: string) => void;
  onUserRatingClick?: (userId: string) => void;
};

export const RatingsCard = ({
  item,
  onAccountRatingClick,
  onUserRatingClick,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { account, count, grade, users = [] } = item;
  const { name } = account;

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const handleAccountRatingClick = () => {
    onAccountRatingClick?.(account.id);
  };

  return (
    <Root>
      <Container>
        <Name onClick={toggle}>
          {name}
          <ExpandMoreRoundedIcon
            sx={{
              color: '#00618e',
              transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
            }}
          />
        </Name>
        <Info>
          {t('moderation.complaints.count')}: {count}
        </Info>
        <Info
          sx={{ cursor: onAccountRatingClick ? 'pointer' : 'default' }}
          onClick={handleAccountRatingClick}
        >
          {t('moderation.complaints.rating')}: {grade}
        </Info>
      </Container>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Collapsed>
          {users.map((item) => (
            <UserCard
              key={item.user.id}
              sx={{
                borderBottom: '1px solid #dddddd',
                '&:last-of-type': { borderBottom: 0 },
              }}
              item={item}
              onRatingClick={onUserRatingClick}
            />
          ))}
        </Collapsed>
      </Collapse>
    </Root>
  );
};

import React, { useEffect } from 'react';
import { HomeLayout } from 'components/features/home/components/layout';
import {
  Container,
  Title,
  TitleContainer,
} from 'components/features/home/components';
import { RouteNames, URLS } from 'defenitions/routes';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  changeFilters,
  selectNotifications,
  selectNotificationsFilters,
  selectNotificationsHasMore,
  selectNotificationsLoading,
} from 'store/features/notifications';
import { getNotifications } from 'store/features/notifications/actions';
import { Loader } from 'components/shared/loader';
import { ReactComponent as SettingIcon } from 'assets/icons/settingIcon.svg';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { NotificationsFilters } from 'components/features/notification/filters';
import { INotificationsFilters } from 'interfaces/Notifications';
import { NotificationListItem } from 'components/features/notification/components/listItem';
import { EmptyList } from 'components/elements/emptyList';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PAGE_SIZE } from 'interfaces/BusinessFilters';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';

export const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notifications = useAppSelector(selectNotifications);
  const isNotificationsLoading = useAppSelector(selectNotificationsLoading);
  const notificationsFilters = useAppSelector(selectNotificationsFilters);
  const hasMore = useAppSelector(selectNotificationsHasMore);

  const handleChangeFilters = (filters: INotificationsFilters) => {
    dispatch(changeFilters(filters));
  };
  const handleClickSettingsMenu = () => navigate(URLS.notificationSettings);

  const loadNext = () => {
    dispatch(
      changeFilters({
        ...notificationsFilters,
        offset: notificationsFilters.offset + PAGE_SIZE,
      }),
    );
  };
  useEffect(() => {
    dispatch(getNotifications(notificationsFilters));
  }, [dispatch, notificationsFilters]);

  return (
    <HomeLayout showFilters>
      <Container item container mb={3}>
        <TitleContainer style={{ paddingBottom: 0 }}>
          <Title>{t(`${RouteNames[URLS.notifications]}`)}</Title>
          <IconButton onClick={handleClickSettingsMenu}>
            <SettingIcon />
          </IconButton>
        </TitleContainer>
        <NotificationsFilters
          filters={notificationsFilters}
          onChange={handleChangeFilters}
        />
      </Container>
      <Container item container pb={3}>
        <div
          style={{
            height: '100%',
            overflowY: 'scroll',
            width: '100%',
          }}
        >
          <InfiniteScroll
            dataLength={notifications.length}
            next={loadNext}
            hasMore={hasMore}
            loader={<Loader show={isNotificationsLoading} global />}
            scrollableTarget={SCROLLABLE_REFERENCE_ID}
          >
            {notifications.map((el) => (
              <NotificationListItem key={el.id} item={el} />
            ))}
          </InfiniteScroll>
        </div>
        {!notifications.length && (
          <EmptyList style={{ padding: '24px 0px 0px 24px' }} />
        )}
      </Container>
    </HomeLayout>
  );
};

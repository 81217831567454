import React from 'react';
import { CardLayout } from 'components/features/auth/layout';
import { RestoreForm } from 'components/features/auth/containers/restore/form';

export const Restore = () => {
  return (
    <CardLayout title={'Восстановление доступа'}>
      <RestoreForm />
    </CardLayout>
  );
};

import { IBook } from 'interfaces/Books';
import { Locale } from 'interfaces/Locale';
import { Types } from 'interfaces/Users';
import i18next from 'i18next';

export const Countries: IBook[] = [
  {
    id: '051',
    name: 'АРМЕНИЯ',
  },
  {
    id: '112',
    name: 'БЕЛОРУСЬ',
  },
  {
    id: '398',
    name: 'КАЗАХСТАН',
  },
  {
    id: '417',
    name: 'КИРГИЗИЯ',
  },
  {
    id: '643',
    name: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ',
  },
  {
    id: '860',
    name: 'УЗБЕКИСТАН',
  },
];

export const RolesBook = (isOwnerView: boolean): IBook[] => {
  const owner = isOwnerView
    ? [
        {
          id: 'OWNER',
          name: 'Владелец',
        },
      ]
    : [];
  return [
    {
      id: 'OPERATOR',
      name: 'Оператор',
    },
    {
      id: 'ADMINISTRATOR',
      name: 'Администратор',
    },
    ...owner,
  ];
};

export const Languages: IBook[] = [
  {
    id: Locale.ru,
    name: '🇷🇺 Русский',
  },
  {
    id: Locale.en,
    name: '🇬🇧 English',
  },
];

export const Sections = {
  HOME: 'HOME',
  NEWS: 'NEWS',
  MARKETPLACE: 'MARKETPLACE',
  BUSINESS: 'BUSINESS',
  QUESTION: 'QUESTION',
};

export const UserTypes = (): IBook[] => [
  {
    id: Types.PERSON,
    name: i18next.t('userTypes.PERSON'),
  },
  {
    id: Types.ORGANIZATION,
    name: i18next.t('userTypes.ORGANIZATION'),
  },
];

import qs from 'qs';
import { AxiosResponse } from 'axios';
import { AttachmentType } from 'interfaces/Attachment';
import {
  Answer,
  CreateAnswerApi,
  CreateQuestionApi,
  CreateQuestionSubscription,
  EditAnswerApi,
  Question,
} from 'interfaces/Question';
import { QuestionsFilters } from 'interfaces/QuestionsFilters';
import { apiClient } from 'services/ApiClient';
import { ReactionType } from 'interfaces/Post';

export const getQuestionByIdEndpoint = (
  id: Question['id'],
): Promise<AxiosResponse<Question[]>> => {
  return apiClient.get(`/questions/${id}`);
};

export const getQuestionsEndpoint = (
  filters: QuestionsFilters,
): Promise<AxiosResponse<Question[]>> => {
  return apiClient.get(`/questions`, {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

export const getMyQuestionsEndpoint = (
  filters: QuestionsFilters,
): Promise<AxiosResponse<Question[]>> => {
  return apiClient.get(`/questions/account`, {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

export const getIncomingQuestionsEndpoint = (
  filters: QuestionsFilters,
): Promise<AxiosResponse<Question[]>> => {
  return apiClient.get(`/questions/incoming`, {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

export const createQuestionEndpoint = (
  question: CreateQuestionApi,
): Promise<AxiosResponse> => {
  const attachments = question.attachments.map((attachment) => attachment.id);
  return apiClient.post(`/questions`, { ...question, attachments });
};

export const reactToQuestionPostEndpoint = (
  id: Question['id'] | Answer['id'],
  type: ReactionType,
): Promise<AxiosResponse> => {
  return apiClient.post(`/posts/reactions/${id}`, {}, { params: { type } });
};

export const closeQuestionEndpoint = (
  id: Question['id'],
): Promise<AxiosResponse> => {
  return apiClient.post(`/questions/${id}/close`);
};

export const uploadQuestionAttachmentsEndpoint = (
  data: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments/files?type=${AttachmentType.Question}`,
    data,
  );
};

export const getAnswersEndpoint = (
  questionId: Question['id'],
): Promise<AxiosResponse> => {
  return apiClient.get(`/questions/${questionId}/answers`);
};

export const createAnswerEndpoint = (
  answer: CreateAnswerApi,
): Promise<AxiosResponse> => {
  const attachments = answer.attachments.map((attachment) => attachment.id);
  return apiClient.post(`/questions/answers`, { ...answer, attachments });
};

export const editAnswerEndpoint = ({
  id,
  ...data
}: EditAnswerApi): Promise<AxiosResponse> => {
  const attachments = data.attachments.map((attachment) => attachment.id);
  return apiClient.put(`/questions/answers/${id}`, { ...data, attachments });
};

export const uploadAnswerAttachmentsEndpoint = (
  data: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments/files?type=${AttachmentType.Answer}`,
    data,
  );
};

export const getQuestionSubscriptionsEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get(`/subscriptions/question`);
};

export const createQuestionSubscriptionsEndpoint = (
  subscriptions: CreateQuestionSubscription[],
): Promise<AxiosResponse> => {
  return apiClient.post(`/subscriptions/question`, subscriptions);
};

export const acceptAnswerEndpoint = (
  questionId: string,
  answerId: string,
): Promise<AxiosResponse> => {
  return apiClient.patch(`/questions/${questionId}/answers/${answerId}/accept`);
};

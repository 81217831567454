import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { IUserActivityStats } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCell = (): HeadCell[] => {
  return [
    { id: 'businessCard', label: i18n.t('business card'), numeric: false },
    {
      id: 'questions',
      label: i18n.t('tables.questions'),
      numeric: true,
    },
    {
      id: 'answers',
      label: i18n.t('tables.answers'),
      numeric: true,
    },
    {
      id: 'offers',
      label: i18n.t('tables.offers'),
      numeric: true,
    },
    {
      id: 'offerAnswers',
      label: i18n.t('tables.offerAnswers'),
      numeric: true,
    },
    {
      id: 'news',
      label: i18n.t('tables.news'),
      numeric: true,
    },
    {
      id: 'comments',
      label: i18n.t('tables.comments'),
      numeric: true,
    },
    /*    {
      id: 'reactions',
      label: i18n.t('tables.reactions'),
      numeric: true,
    },*/
    {
      id: 'reviews',
      label: i18n.t('tables.reviews'),
      numeric: true,
    },
  ];
};

export const createData = (data: IUserActivityStats[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.businessCard.id,
      businessCard: getLocaleValue(el.businessCard.name),
    };
  });

import React, { ReactNode } from 'react';
import { Roles } from 'interfaces/Users';
import { styled } from '@mui/material/styles';
import { ReactComponent as OwnerIcon } from 'assets/icons/ownerIcon.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { useTranslation } from 'react-i18next';

const Role = styled('div')({
  fontWeight: '400',
  fontSize: 12,
  lineHeight: '15px',
  color: '#A9A9A9',
  paddingLeft: 10,
});

export const ViewRole = ({
  role,
  children,
}: {
  role: Roles;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex' }}>
      {role === Roles.OWNER ? <OwnerIcon /> : <UserIcon />}
      <Role>{children || t(`roles.${role}`)}</Role>
    </div>
  );
};

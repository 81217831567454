import React from 'react';
import { IAnswer } from 'interfaces/Marketplace';
import { styled, SxProps, Theme } from '@mui/system';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { AnswerStatuses, AnswerStatusMap } from 'interfaces/Marketplace';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { BusinessCardHeader } from 'components/features/marketplace/components/BusinessCardHeader';
import { Box, Grid } from '@mui/material';
import { ReactionType } from 'interfaces/Post';
import { Editor } from 'components/shared/editor';
import { softwareLinks } from '../../../../elements/links';
import { useTranslation } from 'react-i18next';

const Root = styled('div')({
  padding: '24px 32px',
});

const Type = styled('span')({
  fontWeight: '700',
  fontSize: 14,
  color: '#3d3d3d',
});

const Title = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '700',
  fontSize: 16,
  color: '#00618e',
});

const StatusHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 32,
});

const Status = styled('span')<{ published?: boolean }>(
  ({ published = false }) => ({
    fontWeight: '700',
    fontSize: 18,
    color: published ? '#165209' : '#707070',
  }),
);

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  '&:empty': {
    display: 'none',
  },
}));

const Label = styled('span')({
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
  whiteSpace: 'nowrap',
});

const Info = styled('span')({
  fontWeight: '400',
  fontSize: 16,
  color: '#000',
});

type Props = {
  sx?: SxProps<Theme>;
  answer: IAnswer;
  type: string;
  showStatus?: boolean;
  onLike?: (id: IAnswer['id']) => void;
  onDislike?: (id: IAnswer['id']) => void;
};

export const ServiceRequestAnswerDetailedCard = ({
  sx = [],
  answer,
  type,
  showStatus = true,
  onLike,
  onDislike,
}: Props) => {
  const { t } = useTranslation();
  const {
    attachments,
    content,
    id,
    status,
    title,
    businessCard,
    creator,
    createdDateTime,
    reactions,
    softwareCards,
  } = answer;

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const handleLike = () => {
    onLike?.(id);
  };

  const handleDislike = () => {
    onDislike?.(id);
  };
  const software = softwareLinks(softwareCards);

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <StatusHeader sx={{ mb: 2 }}>
        <Type>{type}</Type>
        {showStatus && (
          <Status published={status === AnswerStatuses.PUBLISHED}>
            {AnswerStatusMap[status]}
          </Status>
        )}
      </StatusHeader>
      <BusinessCardHeader
        sx={{ mb: 3 }}
        src={businessCard?.logo?.path}
        name={businessCard?.name?.ru || businessCard?.name?.en || ''}
        user={`${creator.name} ${creator.lastName}, #${creator.reputation}`}
        created={createdDateTime}
        rating={businessCard?.rate}
        id={businessCard?.id}
      />
      {!!software?.length && (
        <Grid sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }} item>
          <Label>{t('marketplace.navigation.softwareCompilation')}:</Label>
          <Info>{software}</Info>
        </Grid>
      )}
      <Title sx={{ mb: 1.5 }}>{title.ru || title.en}</Title>
      <Editor value={content.ru || content.en} readOnly />
      {Boolean(attachments?.length) && <Thumbs attachments={attachments} />}
      <Actions sx={{ mt: 3 }}>
        {!!onLike && (
          <Box sx={{ flexBasis: '100px' }}>
            <InteractiveButton
              IconComponent={LikeIcon}
              count={likes}
              active={hasUserLikes}
              onClick={handleLike}
            />
          </Box>
        )}
        {!!onDislike && (
          <Box sx={{ flexBasis: '100px' }}>
            <InteractiveButton
              IconComponent={DislikeIcon}
              count={dislikes}
              active={hasUserDislikes}
              onClick={handleDislike}
            />
          </Box>
        )}
      </Actions>
    </Root>
  );
};

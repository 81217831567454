import React from 'react';
import { IServiceRequest } from 'interfaces/Marketplace';
import { styled, SxProps, Theme } from '@mui/system';
import { BusinessCardHeader } from '../BusinessCardHeader';
import { Box, Grid } from '@mui/material';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { Status } from 'components/features/marketplace/components/status';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { useTranslation } from 'react-i18next';
import { ReactionType } from 'interfaces/Post';
import { getLocaleValue } from 'utils/form';
import { softwareLinks } from 'components/elements/links';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import { Editor } from 'components/shared/editor';
import { enumeration } from 'utils/strings';

const Root = styled('div')({
  padding: '24px 32px',
});

const StatusHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 32,
});

const Type = styled('span')({
  fontWeight: '700',
  fontSize: 14,
  color: '#3d3d3d',
});

const Title = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '700',
  fontSize: 16,
  color: '#00618e',
});

const Label = styled('span')({
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
  whiteSpace: 'nowrap',
});

const Info = styled('span')({
  fontWeight: '400',
  fontSize: 16,
  color: '#000',
});

const InfoLink = styled(Link)({
  fontWeight: '400',
  fontSize: 16,
  color: '#000',
});

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  '&:empty': {
    display: 'none',
  },
}));

type Props = {
  sx?: SxProps<Theme>;
  request: IServiceRequest;
  showStatus?: boolean;
  type: string;
  onLike?: (id: IServiceRequest['id']) => void;
  onDislike?: (id: IServiceRequest['id']) => void;
};

export const ServiceRequestDetailedCard = ({
  sx = [],
  request,
  showStatus = true,
  type,
  onLike,
  onDislike,
}: Props) => {
  const { t } = useTranslation();

  const {
    attachments,
    businessCard,
    content,
    creator,
    createdDateTime,
    id,
    receiverBusinessCard,
    serviceTypes,
    status,
    title,
    reactions,
    softwareCards,
    applicationAreas,
    programmingLanguages,
  } = request;

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const software = softwareLinks(softwareCards);

  const types = enumeration(
    serviceTypes.map(({ display }) => getLocaleValue(display)),
  );

  const areas = enumeration(
    (applicationAreas ?? []).map(({ display }) => getLocaleValue(display)),
  );

  const languages = enumeration(
    (programmingLanguages ?? []).map(({ display }) => getLocaleValue(display)),
  );

  const handleLike = () => {
    onLike?.(id);
  };

  const handleDislike = () => {
    onDislike?.(id);
  };

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <StatusHeader sx={{ mb: 2 }}>
        <Type>{type}</Type>
        {showStatus && <Status type={'Request'} status={status} />}
      </StatusHeader>
      <BusinessCardHeader
        sx={{ mb: 3 }}
        src={businessCard?.logo?.path}
        name={businessCard?.name?.ru || businessCard?.name?.en || ''}
        user={`${creator.name} ${creator.lastName}, #${creator.reputation}`}
        created={createdDateTime}
        id={businessCard?.id}
      />
      <Grid sx={{ mb: 1.5 }} flexDirection="column" spacing={0.5} container>
        {receiverBusinessCard && (
          <Grid sx={{ display: 'flex', gap: 4 }} item>
            <Label>{t('marketplace.requestReceiver')}:</Label>
            <InfoLink
              to={generatePath(URLS.viewSoftwareCard, {
                id: receiverBusinessCard.id,
              })}
            >{`${
              receiverBusinessCard.name?.ru ||
              receiverBusinessCard.name?.en ||
              ''
            }`}</InfoLink>
          </Grid>
        )}
        <Grid sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }} item>
          <Label>{t('marketplace.serviceType')}:</Label>
          <Info>{types}</Info>
        </Grid>
        {!!software?.length && (
          <Grid sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }} item>
            <Label>{t('marketplace.navigation.softwareCompilation')}:</Label>
            <Info>{software}</Info>
          </Grid>
        )}
        {!!areas?.length && (
          <Grid sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }} item>
            <Label>{t('applicationArea')}:</Label>
            <Info>{areas}</Info>
          </Grid>
        )}
        {!!languages?.length && (
          <Grid sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }} item>
            <Label>{t('programmingLanguages')}:</Label>
            <Info>{languages}</Info>
          </Grid>
        )}
      </Grid>
      <Title sx={{ mb: 1.5 }}>{title.ru || title.en}</Title>
      <Editor value={content.ru || content.en} readOnly />
      {!!attachments?.length && <Thumbs attachments={attachments} />}
      <Actions sx={{ mt: 3 }}>
        {!!onLike && (
          <Box sx={{ flexBasis: '100px' }}>
            <InteractiveButton
              IconComponent={LikeIcon}
              count={likes}
              active={hasUserLikes}
              onClick={handleLike}
            />
          </Box>
        )}
        {!!onDislike && (
          <Box sx={{ flexBasis: '100px' }}>
            <InteractiveButton
              IconComponent={DislikeIcon}
              count={dislikes}
              active={hasUserDislikes}
              onClick={handleDislike}
            />
          </Box>
        )}
      </Actions>
    </Root>
  );
};

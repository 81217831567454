import { IAttachment, IBusinessAttachment } from './Attachment';
import { IPublishedBusinessCard } from './Business';
import { IDictionariesContent } from './Dictionaries';
import { IMultiLanguage } from './Locale';
import { IComment } from 'interfaces/Comment';
import { ReactionDictionary } from './Post';
import { ISoftwareCardShort } from './Software';

export enum AnswerType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}
export enum ServiceRequestStatus {
  DRAFT = 'DRAFT',
  CANCELLED = 'CANCELLED',
  PUBLISHED = 'PUBLISHED',
  ACCEPTED = 'ACCEPTED',
  IN_WORK = 'IN_WORK',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
}

export enum ServiceRequestFromPages {
  RequestsCompilation = 'compilation',
  MyRequests = 'myrequests',
  ReceivedRequests = 'received',
}

export const ServiceRequestStatusMap = {
  [ServiceRequestStatus.ACCEPTED]: 'Предложение принято',
  [ServiceRequestStatus.CANCELLED]: 'Закрыт',
  [ServiceRequestStatus.DECLINED]: 'Отклонен',
  [ServiceRequestStatus.DRAFT]: 'Черновик',
  [ServiceRequestStatus.PUBLISHED]: 'Опубликован',
  [ServiceRequestStatus.IN_WORK]: 'В работе',
  [ServiceRequestStatus.COMPLETED]: 'Завершен',
};

export enum AnswerStatuses {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  IN_WORK = 'IN_WORK',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export const AnswerStatusMap = {
  [AnswerStatuses.DRAFT]: 'Черновик',
  [AnswerStatuses.PUBLISHED]: 'Опубликовано',
  [AnswerStatuses.ACCEPTED]: 'Принято',
  [AnswerStatuses.DECLINED]: 'Отклонено',
  [AnswerStatuses.IN_WORK]: 'В работе',
  [AnswerStatuses.COMPLETED]: 'Завершено',
  [AnswerStatuses.CANCELLED]: 'Отозвано',
};

export interface IMarketPlaceItem {
  id: string;
}

export interface ICreator {
  id: string;
  lastName: string;
  name: string;
  logo: IArguments;
  reputation: number;
}

export interface IServiceRequest {
  id: string;
  businessCard: IPublishedBusinessCard;
  title: IMultiLanguage;
  content: IMultiLanguage;
  attachments?: IAttachment[];
  status: ServiceRequestStatus;
  serviceTypes: IDictionariesContent[];
  createdDateTime: number;
  canProcessByCurrentUser: boolean;
  commentsCount: number;
  answersCount?: number;
  countPotentialBusinessCards: number;
  hasCurrentUserComment: boolean;
  hasUserAnswer?: boolean;
  creator: ICreator;
  reactions?: ReactionDictionary;
  receiverBusinessCard?: {
    id: string;
    name?: IMultiLanguage;
    logo: IArguments;
  };
  viewsCounter: { uniqueUsers: number; viewedByCurrentUser: boolean };
  hidden?: boolean;
  softwareCards?: ISoftwareCardShort[];
  applicationAreas?: IDictionariesContent[];
  programmingLanguages?: IDictionariesContent[];
}

export interface IService {
  businessCard: IPublishedBusinessCard;
  businessService: {
    id: string;
    name: IMultiLanguage;
    serviceType: IDictionariesContent;
    description: IMultiLanguage;
    attachments?: IAttachment[];
    createdDateTime: number;
    updateDateTime: number;
  };
}

export interface ICreateServiceRequest
  extends Pick<IServiceRequest, 'title' | 'content'> {
  receiverBusinessCardId?: IPublishedBusinessCard['id'];
  receiverSoftwareCardId?: string;
  attachments: Array<IAttachment['id']>;
  status: ServiceRequestStatus.DRAFT;
  serviceTypes: Array<IDictionariesContent['code']>;
  softwareCardIds: string[];
  programmingLanguages: string[];
  applicationAreas: string[];
}

export interface IListFilters {
  limit?: number | undefined | null;
  offset?: number | undefined | null;
}

export interface ICreateOfferAnswer {
  offerId: string;
  status: AnswerStatuses;
  businessCardId: string;
  content: IMultiLanguage;
  title: IMultiLanguage;
  attachments: Array<string>;
  softwareCardIds?: string[];
}

export interface IDeclineAnswer {
  offerId: string;
  answerId: string;
}

export interface IDeclineServiceRequest {
  offerId: string;
  title: IMultiLanguage;
}

export interface IAcceptAnswer {
  offerId: string;
  answerId: string;
}

export interface IShareContacts {
  offerId: string;
  answerId: string;
}

export interface IAnswer {
  attachments: Array<IBusinessAttachment>;
  accountId: string;
  businessCard: IPublishedBusinessCard;
  canProcessByCurrentUser: boolean;
  commentsCount: number;
  content: IMultiLanguage;
  createdDateTime: number;
  creator: ICreator;
  hasCurrentAccountReview: boolean;
  hasCurrentUserComment: boolean;
  isCommentAllowed: boolean;
  id: string;
  offer: IServiceRequest;
  relationId: string;
  status: AnswerStatuses;
  title: IMultiLanguage;
  updateDateTime: number;
  comments?: IComment[];
  viewsCounter: {
    uniqueUsers: number;
    viewedByCurrentUser: boolean;
  };
  reactions?: ReactionDictionary;
  softwareCards?: ISoftwareCardShort[];
}

export const GetServicePageClosedOptions = {
  all: undefined,
  open: 'false',
  closed: 'true',
};

export interface GetServicePageFilters extends IListFilters {
  closed?: string | undefined | null;
  onlyCreatedByCurrentUser?: boolean | undefined | null;
  fromDate?: number | undefined | null;
  text?: string | undefined | null;
}

export interface ICompilationPageFilters extends IListFilters {
  fromDate?: number | undefined | null;
  recommended?: boolean | undefined | null;
  serviceTypes?: string[] | undefined | null;
  softwareTypes?: string[] | undefined | null;
  applicationAreas?: string[] | undefined | null;
  text?: string | undefined | null;
}

export interface IProvideServicesPageFilters extends IListFilters {
  fromDate?: number | undefined | null;
  serviceTypes?: string[] | undefined | null;
  text?: string | undefined | null;
  withClosed?: boolean | undefined | null;
  onlyCreatedByCurrentUser?: boolean | undefined | null;
}

export interface IRequestsPageFilters extends IListFilters {
  statuses?: Array<ServiceRequestStatus> | undefined | null;
  fromDate?: number | undefined | null;
  serviceTypes?: string[] | undefined | null;
  text?: string | undefined | null;
  onlyCreatedByCurrentUser?: boolean | undefined | null;
}

export interface Review {
  content: IMultiLanguage;
  attachments: string[];
  grade: number;
}

export interface CreateReview extends IAcceptAnswer {
  data: Review;
}

export interface ViewReview {
  id: string;
  content: IMultiLanguage;
  businessCard: IPublishedBusinessCard;
  accountId: string;
  creator: ICreator;
  relationId: string;
  createdDateTime: number;
  updateDateTime: number;
  isCommentAllowed: boolean;
  canProcessByCurrentUser: boolean;
  businessCardReview: IPublishedBusinessCard;
  grade: number;
  attachments?: IAttachment[];
}

export interface Reviews {
  offerReview?: ViewReview;
  answerReview?: ViewReview;
}

export enum RequiredOfferName {
  PUBLISHED = 'PUBLISHED',
  ACCEPTED = 'ACCEPTED',
  IN_WORK = 'IN_WORK',
}
export interface OfferCount {
  DRAFT?: number;
  CANCELLED?: number;
  PUBLISHED?: number;
  ACCEPTED?: number;
  IN_WORK?: number;
  COMPLETED?: number;
  DECLINED?: number;
}

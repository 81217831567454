import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { RouteNames, URLS } from 'defenitions/routes';

const Container = styled(Grid)({
  padding: '18px 18px 18px 40px',
  background: '#FFFFFF',
  border: '1px solid #E4E4E4',
  marginBottom: 16,
});

const Title = styled('div')({
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '29px',
  color: '#4B4B4B',
});

export const SubscriptionsHeader = () => {
  return (
    <Container container>
      <Grid item xs={6}>
        <Title>{RouteNames[URLS.questionSubscriptions]}</Title>
      </Grid>
    </Container>
  );
};

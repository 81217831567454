import React, { useState } from 'react';
import { styled, SxProps, Theme } from '@mui/system';
import { BusinessCardHeader } from 'components/features/marketplace/components/BusinessCardHeader';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useTranslation } from 'react-i18next';
import {
  commentValidationSchema,
  EditCommentForm,
} from 'components/elements/comment';
import { IComment } from 'interfaces/Comment';
import { Locale } from 'interfaces/Locale';
import { useAppDispatch } from 'store';
import { AttachmentType, IAttachment } from 'interfaces/Attachment';
import {
  editOfferComment,
  uploadMarketplaceAttachment,
} from 'store/features/marketplace/actions';
import { AxiosResponse } from 'axios';
import { EditCommentFormData } from 'components/elements/comment/EditCommentForm';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ReactionType } from 'interfaces/Post';
import { Box } from '@mui/material';
import { Editor } from 'components/shared/editor';
import { softwareLinks } from 'components/elements/links';

const Root = styled('div')({
  padding: '16px 24px',
});

const Content = styled('p')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  margin: 0,
  padding: 0,
  fontWeight: '500',
  fontSize: 14,
  color: '#000',
});

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  '&:empty': {
    display: 'none',
  },
}));

const TextButton = styled('button')({
  padding: 0,
  border: 0,
  fontFamily: 'inherit',
  fontWeight: '500',
  fontSize: 14,
  color: '#00618e',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
});

type Props = {
  sx?: SxProps<Theme>;
  comment: IComment;
  mine?: boolean;
  onLike?: (id: string) => void;
  onDislike?: (id: string) => void;
  onCreateComplaint?: () => void;
};

export const Comment = ({
  sx = [],
  comment,
  mine = false,
  onLike,
  onDislike,
  onCreateComplaint,
}: Props) => {
  const { i18n } = useTranslation();
  const lang = i18n.language as Locale;

  const dispatch = useAppDispatch();

  const { id, attachments = [], updateDateTime, reactions } = comment;

  const avatar = comment.businessCard?.logo?.path;
  const content = comment.content[lang];
  const creator = comment.businessCard.name[lang];
  const businessCardId = comment.businessCard?.id;
  const createdDate = comment.createdDateTime;
  const softwareCards = comment.softwareCards;
  const user =
    `${comment.creator?.name} ${comment.creator?.lastName}, #${comment.creator.reputation}`.trim();
  const edited = createdDate !== updateDateTime;

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const [editing, setEditing] = useState(false);

  const handleLike = () => onLike?.(id);

  const handleDislike = () => onDislike?.(id);

  const handleUploadAttachments = async (data: FormData) => {
    const options = { data, type: AttachmentType.Comment };
    const { payload } = await dispatch(uploadMarketplaceAttachment(options));
    return (payload as AxiosResponse<IAttachment[]>).data;
  };

  const handleStartEditing = () => setEditing(true);

  const handleStopEditing = () => setEditing(false);

  const handleEditComment = (data: EditCommentFormData) => {
    const { attachments } = data;
    const attachmentsIds = attachments.map((attachment) => attachment.id);
    dispatch(
      editOfferComment({
        ...data,
        id,
        attachments: attachmentsIds,
        softwareCardIds: data.softwareCards?.map((el) => el.code),
      }),
    );
    handleStopEditing();
  };

  const software = softwareLinks(softwareCards);

  if (editing) {
    return (
      <EditCommentForm
        comment={comment}
        label={'Написать ответ'}
        validationSchema={commentValidationSchema}
        uploadAttachments={handleUploadAttachments}
        onSubmit={handleEditComment}
        onCancel={handleStopEditing}
      />
    );
  }

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <div style={{ position: 'relative' }}>
        <BusinessCardHeader
          src={avatar}
          name={creator}
          user={user}
          created={createdDate}
          id={businessCardId}
        />
        {onCreateComplaint && (
          <MoreIcon
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          />
        )}
      </div>

      <Content sx={{ mt: 3, mb: 3 }}>
        <div>{software}</div>
        <div style={{ display: 'flex' }}>
          <Editor readOnly value={content} />
          {edited && <EditOutlinedIcon color="inherit" fontSize="inherit" />}
        </div>
      </Content>
      {!!attachments?.length && <Thumbs attachments={attachments} />}
      <Actions>
        {!!onLike && (
          <Box sx={{ flexBasis: '100px' }}>
            <InteractiveButton
              IconComponent={LikeIcon}
              count={likes}
              active={hasUserLikes}
              onClick={handleLike}
            />
          </Box>
        )}
        {!!onDislike && (
          <Box sx={{ flexBasis: '100px' }}>
            <InteractiveButton
              IconComponent={DislikeIcon}
              count={dislikes}
              active={hasUserDislikes}
              onClick={handleDislike}
            />
          </Box>
        )}
        {mine && (
          <TextButton onClick={handleStartEditing}>{'Изменить'}</TextButton>
        )}
      </Actions>
      {anchorEl && onCreateComplaint && (
        <MoreMenu anchorEl={anchorEl} onClickComplaint={onCreateComplaint} />
      )}
    </Root>
  );
};

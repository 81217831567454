import * as yup from 'yup';
import {
  ErrorMessages,
  MAX_LENGTH,
  MAX_LENGTH_DESCRIPTION,
} from 'defenitions/errorMessages';

const localeValidate = yup.object().shape(
  {
    ru: yup.string().when('en', {
      is: (en: string) => !en,
      then: yup
        .string()
        .max(MAX_LENGTH, ErrorMessages.maxLength(MAX_LENGTH))
        .notOneOf(['', undefined, null], ErrorMessages.required),
    }),
    en: yup.string().when('ru', {
      is: (ru: string) => !ru,
      then: yup
        .string()
        .max(MAX_LENGTH, ErrorMessages.maxLength(MAX_LENGTH))
        .notOneOf(['', undefined, null], ErrorMessages.required),
    }),
  },
  [
    ['en', 'ru'],
    ['ru', 'en'],
  ],
);

export const commonDataValidationSchema = yup.object({
  name: localeValidate,
  softwareTypes: yup.array().min(1, ErrorMessages.required),
  programmingLanguages: yup.array().min(1, ErrorMessages.required),
  applicationAreas: yup.array().min(1, ErrorMessages.required),
  description: yup
    .object({
      ru: yup
        .string()
        .max(
          MAX_LENGTH_DESCRIPTION,
          ErrorMessages.maxLength(MAX_LENGTH_DESCRIPTION),
        ),
      en: yup
        .string()
        .max(
          MAX_LENGTH_DESCRIPTION,
          ErrorMessages.maxLength(MAX_LENGTH_DESCRIPTION),
        ),
    })
    .required(ErrorMessages.required),
});

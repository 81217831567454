import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

type Props = {
  link: string | string[];
  title: string;
  logo?: React.ReactNode;
};

type MenuItemProps = { active?: boolean };

const MenuItem = styled(Link)<MenuItemProps>(({ active = false }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '13px',
  color: active ? '#000000' : '#3d3d3d',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textDecoration: 'none',
  position: 'relative',
  whiteSpace: 'nowrap',

  '&:hover': {
    color: '#000000',
  },
}));

type MenuItemIconProps = { active?: boolean };

const MenuItemIcon = styled('div')<MenuItemIconProps>(({ active = false }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& svg': {
    height: 24,
    width: 24,
    fill: active ? '#000000' : '#3d3d3d',
    fontSize: 24,
  },
}));

const Selected = styled('div')({
  marginTop: 4,
  height: 2,
  width: '100%',
  background: '#3d3d3d',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  position: 'absolute',
  bottom: '-4px',
});

export const MenuItemLink = ({ link, title, logo }: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const location = useLocation();
  const mainLink = Array.isArray(link) ? link[0] : link;
  const active = Array.isArray(link)
    ? link.some((el) => location.pathname.split('/')[1] === el.split('/')[1])
    : location.pathname.split('/')[1] === link.split('/')[1];
  return (
    <MenuItem
      to={mainLink}
      active={active}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
    >
      {logo && <MenuItemIcon active={isHover || active}>{logo}</MenuItemIcon>}
      {title}
      {active && <Selected />}
    </MenuItem>
  );
};

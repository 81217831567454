import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';

export const localeValidate = (maxLength: number) =>
  yup.object().shape(
    {
      ru: yup.string().when('en', {
        is: (en: string) => !en,
        then: yup
          .string()
          .max(maxLength, ErrorMessages.maxLength(maxLength))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
      en: yup.string().when('ru', {
        is: (ru: string) => !ru,
        then: yup.string().max(maxLength, ErrorMessages.maxLength(maxLength)),
      }),
    },
    [
      ['ru', 'en'],
      ['en', 'ru'],
    ],
  );

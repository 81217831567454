import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import { ServiceHeader } from 'components/features/marketplace/components/ServiceHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { getServiceById } from 'store/features/marketplace/actions';
import { ServiceDetailedCard } from 'components/features/marketplace/components/cards/ServiceDetailedCard';
import {
  getService,
  isMarketplaceItemLoading,
} from 'store/features/marketplace';
import { Loader } from 'components/shared/loader';
import { RouteNames, URLS } from 'defenitions/routes';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useTranslation } from 'react-i18next';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';

const Container = styled('div')({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
});

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '40px 32px',
  gap: 16,
});

type Params = { id: string };

export const MarketplaceService = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const service = useAppSelector(getService);
  const isLoading = useAppSelector(isMarketplaceItemLoading);
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
  const { isOpen, onClickOpen, onClickClose } = useModalActions();

  const { id = '' } = useParams<Params>();

  useEffect(() => {
    dispatch(getServiceById(id));
  }, [dispatch, id]);

  const handleBack = () => navigate(-1);

  const handleCreateRequest = () => {
    if (service) {
      navigate(
        `${URLS.marketplaceCreateRequest}?${qs.stringify({
          service: service.businessService.id,
        })}`,
      );
    }
  };

  return (
    <MarketplaceLayout
      contentSx={{ bgcolor: (theme) => theme.palette.background.paper }}
    >
      <Container>
        <div style={{ position: 'relative' }}>
          <ServiceHeader
            sx={{ borderBottom: '1px solid #e4e4e4' }}
            onBack={handleBack}
            onMouseLeave={() => setAnchorEl(null)}
          >
            {RouteNames[URLS.marketplaceService]}
          </ServiceHeader>
          <MoreIcon
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          />
        </div>
        {isLoading && <Loader sx={{ m: 'auto' }} show />}
        {!isLoading && service && (
          <>
            <ServiceDetailedCard
              type={t('marketplace.service')}
              service={service}
            />
            <Actions>
              <ActionButton
                text={t('actions.request')}
                onClick={handleCreateRequest}
              />
            </Actions>
          </>
        )}
        {anchorEl && (
          <MoreMenu anchorEl={anchorEl} onClickComplaint={onClickOpen} />
        )}
        {service && (
          <ConfirmDialog
            onClickClose={onClickClose}
            open={isOpen}
            title={t('modalTexts.complaint.title', {
              name: getLocaleValue(service.businessService.name),
            })}
          >
            <CreateComplaint
              relationId={service.businessService.id}
              complaintAboutType={ComplaintAboutType.BUSINESS_SERVICE}
              additionAction={onClickClose}
            />
          </ConfirmDialog>
        )}
      </Container>
    </MarketplaceLayout>
  );
};

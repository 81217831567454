import React, { useEffect, useMemo, useRef, useState } from 'react';
import qs from 'qs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@mui/system';
import { MarketplaceLayout } from 'components/features/marketplace/components/layout';
import {
  getServiceRequestsList,
  hasMoreMarketplaceListItems,
  isMarketplaceListLoading,
} from 'store/features/marketplace';
import { useAppDispatch, useAppSelector } from 'store';
import { ServiceRequestCard } from 'components/features/marketplace/components/cards/ServiceRequestCard';
import {
  closeServiceRequest,
  deleteServiceRequest,
  getMyServiceRequests,
  getOfferCount,
  hideServiceRequest,
  likeServiceRequest,
  publishServiceRequest,
  showServiceRequest,
} from 'store/features/marketplace/actions';
import { useNavigate } from 'react-router-dom';
import {
  IListFilters,
  IRequestsPageFilters,
  IServiceRequest,
  OfferCount,
  RequiredOfferName,
  ServiceRequestFromPages,
  ServiceRequestStatus,
} from 'interfaces/Marketplace';
import { Loader } from 'components/shared/loader';
import { useQuery } from 'hooks/useQuery';
import { isEqual } from 'lodash';
import { Header } from 'components/features/marketplace/containers/requests/Header';
import { Filters } from 'components/features/marketplace/containers/requests/Filters';
import { NonNullableRecord } from 'utils/types';
import { useTranslation } from 'react-i18next';
import { stringToBoolean } from 'utils/boolean';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { useModalActions } from 'utils/hooks';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { ReactionType } from 'interfaces/Post';
import { ListAdOne, ListAdTwo } from '../../../../elements/ads';

const EmptyText = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
});

type Filters = IRequestsPageFilters;

export const MarketplaceRequests = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const search = useQuery();

  const filters = useMemo(
    () =>
      ({
        text: search.text ?? '',
        fromDate: search.fromDate ? parseInt(search.fromDate as string) : null,
        onlyCreatedByCurrentUser:
          stringToBoolean(search.onlyCreatedByCurrentUser as string) ?? false,
        statuses: search.statuses ?? [],
        serviceTypes: search.serviceTypes ?? [],
      } as Filters),
    [
      search.fromDate,
      search.onlyCreatedByCurrentUser,
      search.serviceTypes,
      search.statuses,
      search.text,
    ],
  );

  const [listFilters, setListFilters] = useState<
    NonNullableRecord<IListFilters>
  >({
    limit: 10,
    offset: 0,
  });

  const isLoading = useAppSelector(isMarketplaceListLoading);
  const hasMore = useAppSelector(hasMoreMarketplaceListItems);
  const requests = useAppSelector(getServiceRequestsList);

  const isFetching = listFilters.offset === 0 && isLoading;
  const isRefetching = listFilters.offset !== 0 && isLoading;

  const prevFilters = useRef<IRequestsPageFilters>({});

  useEffect(() => {
    const currentFilters = { ...filters, ...listFilters };

    if (!isEqual(prevFilters.current, currentFilters)) {
      dispatch(getMyServiceRequests(currentFilters));
      prevFilters.current = currentFilters;
    }
  }, [dispatch, filters, listFilters]);

  const changeFilters = () => {
    setListFilters(({ offset, limit }) => ({
      limit,
      offset: offset + limit,
    }));
  };

  const navigateToServiceRequest = (id: IServiceRequest['id']) => () => {
    navigate({
      pathname: `/marketplace/request/${id}`,
      search: qs.stringify({
        from: ServiceRequestFromPages.MyRequests,
      }),
    });
  };

  const handlePublish = (id: IServiceRequest['id']) => async () => {
    await dispatch(publishServiceRequest(id));
    dispatch(getMyServiceRequests(filters));
  };

  const handleShow = (id: IServiceRequest['id']) => async () => {
    await dispatch(showServiceRequest(id));
    dispatch(getMyServiceRequests(filters));
  };

  const handleHide = (id: IServiceRequest['id']) => async () => {
    await dispatch(hideServiceRequest(id));
    dispatch(getMyServiceRequests(filters));
  };

  const handleDelete = (id: IServiceRequest['id']) => async () => {
    await dispatch(deleteServiceRequest(id)).unwrap();
    dispatch(getMyServiceRequests(filters));
  };

  const closeOffer = async (id: IServiceRequest['id']) => {
    await dispatch(closeServiceRequest(id)).unwrap();
    dispatch(getMyServiceRequests(filters));
  };

  const [offerCount, setOfferCount] = useState<OfferCount>({});
  const [selectedOfferId, setSelectedOfferId] = useState<string>('');
  const { isOpen, onClickSubmit, onClickClose, onClickOpen } = useModalActions(
    () => closeOffer(selectedOfferId),
  );

  const handleCreateCloseData = (
    id: IServiceRequest['id'],
    data: OfferCount,
  ) => {
    setSelectedOfferId(id);
    setOfferCount(data);
    onClickOpen();
  };

  const handleClose = (id: IServiceRequest['id']) => async () => {
    const res = await dispatch(getOfferCount(id)).unwrap();
    const { data } = res as AxiosResponse<OfferCount>;
    const RequiredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key]) =>
          key.includes(RequiredOfferName.PUBLISHED) ||
          key.includes(RequiredOfferName.ACCEPTED) ||
          key.includes(RequiredOfferName.IN_WORK),
      ),
    );

    isEmpty(RequiredData)
      ? closeOffer(id)
      : handleCreateCloseData(id, RequiredData);
  };

  const handleLike = (id: IServiceRequest['id']) => {
    dispatch(likeServiceRequest({ id, type: ReactionType.LIKE }));
  };

  const handleDislike = (id: IServiceRequest['id']) => {
    dispatch(likeServiceRequest({ id, type: ReactionType.DISLIKE }));
  };

  const handleFiltersChange = (value: IRequestsPageFilters) => {
    const text = value.text || null;

    setListFilters({ limit: 10, offset: 0 });
    navigate({
      search: qs.stringify({ ...filters, ...value, text }, { skipNulls: true }),
    });
  };

  return (
    <MarketplaceLayout contentSx={{ display: 'flex', flexDirection: 'column' }}>
      <Header
        sx={{
          border: '1px solid #ddd',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
        filters={filters}
        onChange={handleFiltersChange}
      />
      <Filters
        sx={{
          mb: 3,
          border: '1px solid #ddd',
          borderTop: 'none',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
        filters={filters}
        onChange={handleFiltersChange}
      />
      {isFetching && <Loader sx={{ m: 'auto' }} show />}
      {!isFetching && !requests.length && (
        <EmptyText sx={{ m: 'auto' }}>
          {t('marketplace.emptyRequests')}
        </EmptyText>
      )}
      {!isFetching && !!requests.length && (
        <InfiniteScroll
          dataLength={requests.length}
          loader={<Loader show={isRefetching} global />}
          next={changeFilters}
          hasMore={hasMore}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {requests.map((request, index) => (
            <>
              {index % 4 === 0 && index % 8 !== 0 && index !== 0 && (
                <ListAdOne />
              )}
              {index % 4 === 0 && index % 8 === 0 && index !== 0 && (
                <ListAdTwo />
              )}
              <ServiceRequestCard
                sx={[{ mb: 2 }, index === requests.length - 1 && { mb: 0 }]}
                key={request.id}
                request={request}
                onLike={handleLike}
                onDislike={handleDislike}
                onAnswer={navigateToServiceRequest(request.id)}
                showAnswers
                menu={[
                  {
                    label: t('actions.view'),
                    onClick: navigateToServiceRequest(request.id),
                  },
                  ...(request.status === ServiceRequestStatus.DRAFT
                    ? [
                        {
                          label: t('actions.publish'),
                          onClick: handlePublish(request.id),
                        },
                        {
                          label: t('actions.delete'),
                          onClick: handleDelete(request.id),
                        },
                      ]
                    : []),
                  ...(request.status === ServiceRequestStatus.PUBLISHED
                    ? [
                        {
                          label: t('actions.close'),
                          onClick: handleClose(request.id),
                        },
                        request.hidden
                          ? {
                              label: t('actions.show'),
                              onClick: handleShow(request.id),
                            }
                          : {
                              label: t('actions.hide'),
                              onClick: handleHide(request.id),
                            },
                      ]
                    : []),
                ]}
              />
            </>
          ))}
        </InfiniteScroll>
      )}
      <ConfirmDialog
        onClickAccept={onClickSubmit}
        onClickClose={onClickClose}
        open={isOpen}
        actionTitle={t('modalTexts.marketplace.actionTitle')}
        title={t('modalTexts.marketplace.title')}
      >
        <div style={{ textAlign: 'center' }}>
          {t('modalTexts.marketplace.subText')}
        </div>
        {Object.entries(offerCount).map(([status, count]) => (
          <div style={{ marginTop: 8 }} key={status}>
            {t('modalTexts.marketplace.statusCount', {
              status: t(`marketplace.statuses.${status}`),
              count,
            })}
          </div>
        ))}
      </ConfirmDialog>
    </MarketplaceLayout>
  );
};

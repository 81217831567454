import React, { ReactNode } from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { SideMenu } from './SideMenu';

const Root = styled('div')({
  display: 'flex',
  minHeight: '100%',
});

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 37px',
});

type Props = {
  sx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  bottomSideMenu?: ReactNode;
  children?: ReactNode;
};

export const ModerationLayout = ({
  sx = [],
  containerSx = [],
  bottomSideMenu,
  children,
}: Props) => {
  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <SideMenu bottomSideMenu={bottomSideMenu} />
      <Container
        sx={[...(Array.isArray(containerSx) ? containerSx : [containerSx])]}
      >
        {children}
      </Container>
    </Root>
  );
};

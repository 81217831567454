import { Filters, ListFilters } from './Filters';

export const PAGE_SIZE = 10;

export type NewsFilters = ListFilters &
  Filters<{
    accountIds: string[];
    businessCardIds: string[];
    fromDate: number;
    toDate: number;
  }>;

export interface ISubscriptionFilters {
  text: string;
}

import React, { useState } from 'react';
import { styled, SxProps, Theme } from '@mui/material/styles';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel as MuiFormLabel,
  Popover,
  RadioGroup,
} from '@mui/material';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { startOfToday, subMonths, subWeeks } from 'date-fns';
import { RadioButton } from 'components/shared/radio';
import { IProvideServicesPageFilters } from 'interfaces/Marketplace';
import { Checkbox } from 'components/shared/checkbox';
import { useTranslation } from 'react-i18next';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 56,
  padding: '16px 40px',
  backgroundColor: '#fff',
});

const StyledButton = styled(Button)({
  borderRadius: 4,
  padding: 0,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: 12,
  color: '#000',
  textTransform: 'none',
  boxShadow: 'none',
});

const FormLabel = styled(MuiFormLabel)({
  marginBottom: 8,
  fontWeight: '600',
  fontSize: 12,
  color: '#4b4b4b',
});

const RadioControlLabel = styled(FormControlLabel)({
  color: '#4B4B4B',
  '& span': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
  },
});

const Radio = styled(RadioButton)({
  marginRight: 12,
  padding: 0,
});

type Filters = Pick<
  IProvideServicesPageFilters,
  'fromDate' | 'withClosed' | 'onlyCreatedByCurrentUser'
>;

type Props = {
  filters?: Partial<Filters>;
  sx?: SxProps<Theme>;
  onChange?: (filters: Filters) => void;
};

export const MyOffersFilters = ({ sx = [], filters = {}, onChange }: Props) => {
  const { t } = useTranslation();

  const fromDate = filters.fromDate ?? null;
  const withClosed = filters.withClosed ?? false;
  const onlyCreatedByCurrentUser = filters.onlyCreatedByCurrentUser ?? false;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleFromDateChange = (_: unknown, value: string) => {
    onChange?.({
      fromDate: value ? parseInt(value) : null,
      withClosed,
      onlyCreatedByCurrentUser,
    });
  };

  const handleWithClosedChange = (_: unknown, checked: boolean) => {
    onChange?.({
      fromDate,
      withClosed: checked,
      onlyCreatedByCurrentUser,
    });
  };

  const handleOnlyCreatedByCurrentUserChange = (
    _: unknown,
    checked: boolean,
  ) => {
    onChange?.({
      fromDate,
      withClosed,
      onlyCreatedByCurrentUser: checked,
    });
  };

  const today = startOfToday();

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <StyledButton onClick={handlePopoverOpen}>
        <FilterIcon style={{ marginRight: 4 }} />
        {t('filters.title')}
      </StyledButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <FormControl sx={{ padding: '16px' }}>
          <FormLabel>{t('filters.period.title')}</FormLabel>
          <FormGroup>
            <RadioGroup
              value={fromDate ? `${fromDate}` : ''}
              onChange={handleFromDateChange}
            >
              <RadioControlLabel
                labelPlacement="end"
                value={''}
                control={<Radio />}
                label={t('filters.period.all')}
              />
              <RadioControlLabel
                labelPlacement="end"
                value={`${today.getTime()}`}
                control={<Radio />}
                label={t('filters.period.today')}
              />
              <RadioControlLabel
                labelPlacement="end"
                value={`${subWeeks(today, 1).getTime()}`}
                control={<Radio />}
                label={t('filters.period.week')}
              />
              <RadioControlLabel
                labelPlacement="end"
                value={`${subMonths(today, 1).getTime()}`}
                control={<Radio />}
                label={t('filters.period.month')}
              />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <FormControl sx={{ padding: '16px' }}>
          <FormLabel>{t('filters.viewAll.title')}</FormLabel>
          <FormGroup>
            <Checkbox
              label={t('filters.viewAll.viewClosedOffers')}
              checked={withClosed}
              onChange={handleWithClosedChange}
            />
          </FormGroup>
        </FormControl>
        <FormControl sx={{ padding: '16px' }}>
          <FormLabel>{t('filters.author.title')}</FormLabel>
          <FormGroup>
            <Checkbox
              label={t('filters.author.createdByMe')}
              checked={onlyCreatedByCurrentUser}
              onChange={handleOnlyCreatedByCurrentUserChange}
            />
          </FormGroup>
        </FormControl>
      </Popover>
    </Root>
  );
};

import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';
import { PASSWORD_REGEX } from 'defenitions/regex';

export const changePasswordValidationSchema = yup
  .object({
    oldPassword: yup.string().required(ErrorMessages.required),
    password: yup
      .string()
      .required(ErrorMessages.required)
      .matches(PASSWORD_REGEX, ErrorMessages.passwordRegex),
    confirmPassword: yup
      .string()
      .required(ErrorMessages.required)
      .oneOf([yup.ref('password'), null], ErrorMessages.confirmPassword),
  })
  .required();

import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {
  Container,
  Divider,
  SubTitle,
} from 'components/features/business/shared/item/components';
import { IBusiness } from 'interfaces/Business';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getBusinessCardReviews } from 'store/features/business/actions';
import {
  changeReviewsFilters,
  getBusinessCardLoading,
  selectBusinessCardReviewFilters,
  selectBusinessCardReviews,
} from 'store/features/business';
import { Loader } from 'components/shared/loader';
import { PAGE_SIZE } from 'interfaces/BusinessFilters';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReviewsBusiness } from 'components/features/business/shared/item/view/reviewsData/components/ReviewsBusiness';
import { Field } from 'components/elements/viewFields';
import { initialBusinessReviewsFilters } from 'store/features/business/config';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';

type Props = {
  businessCard: IBusiness | null;
  onClickCancel: () => void;
  onClickBack: () => void;
};

export const BusinessReviewsData = ({
  businessCard,
  onClickCancel,
  onClickBack,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getBusinessCardLoading);
  const filters = useAppSelector(selectBusinessCardReviewFilters);
  const reviews = useAppSelector(selectBusinessCardReviews);
  const hasMore = reviews.length % PAGE_SIZE === 0;

  const loadNext = () => {
    dispatch(
      changeReviewsFilters({ ...filters, offset: filters.offset + PAGE_SIZE }),
    );
  };
  useEffect(() => {
    !!businessCard?.id &&
      dispatch(
        getBusinessCardReviews({ ...filters, businessCardId: businessCard.id }),
      );
  }, [businessCard?.id, dispatch, filters]);

  useEffect(() => {
    return () => {
      dispatch(changeReviewsFilters(initialBusinessReviewsFilters));
    };
  }, []);
  return (
    <Container container spacing={4}>
      <Divider />
      {!!businessCard?.reviewCount && (
        <>
          <Grid item container xs style={{ paddingTop: 0 }}>
            <Field>{`${t('rating')}: ${businessCard.rate || ''} (${
              businessCard.reviewCount
            })`}</Field>
          </Grid>
          <Divider />
        </>
      )}
      {!businessCard?.reviewCount && (
        <Grid
          item
          container
          xs
          style={{ paddingTop: 0 }}
          alignContent={'center'}
        >
          <SubTitle> {t('no reviews')}</SubTitle>
        </Grid>
      )}
      <Grid item container xs style={{ paddingTop: 0 }}>
        <div style={{ height: '100%', overflowY: 'scroll', width: '100%' }}>
          <InfiniteScroll
            dataLength={reviews.length}
            next={loadNext}
            hasMore={hasMore}
            loader={<Loader show={isLoading} global />}
            scrollableTarget={SCROLLABLE_REFERENCE_ID}
          >
            {reviews.map((el) => (
              <ReviewsBusiness key={el.id} review={el} />
            ))}
          </InfiniteScroll>
        </div>
      </Grid>
      <Divider />
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            text={t('actions.back')}
            onClick={onClickBack}
          />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            onClick={onClickCancel}
            text={t('actions.close')}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

import React, { useEffect, useMemo, useRef } from 'react';
import qs from 'qs';
import {
  IInvalidContent,
  TAddInvalidContent,
  TContentsFilters,
} from 'interfaces/Complaint';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getInvalidContentsList,
  getInvalidContentsLoading,
} from 'store/features/complaints';
import {
  addInvalidContent,
  editInvalidContent,
  getInvalidContents,
  removeInvalidContent,
} from 'store/features/complaints/actions';
import {
  AddContentForm,
  ContentsHeader,
  ContentsList,
  ModerationLayout,
} from '../components';
import { useQuery } from 'hooks/useQuery';
import { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';

export const ModerationContents = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const contents = useAppSelector(getInvalidContentsList);
  const isLoading = useAppSelector(getInvalidContentsLoading);

  const search = useQuery();

  const filters = useMemo(
    () => ({ text: search.text }),
    [search.text],
  ) as TContentsFilters;

  const prevRef = useRef<TContentsFilters>({});

  useEffect(() => {
    if (!isEqual(prevRef.current, filters)) {
      dispatch(getInvalidContents(filters));
      prevRef.current = filters;
    }
  }, [dispatch, filters]);

  const handleAddContent = (data: TAddInvalidContent) => {
    dispatch(addInvalidContent(data));
  };

  const handleRemoveContent = (id: string) => {
    dispatch(removeInvalidContent(id));
  };

  const handleEditContent = (data: IInvalidContent) => {
    dispatch(editInvalidContent(data));
  };

  const handleTextChange = (text: string) => {
    navigate({
      search: qs.stringify(
        {
          ...filters,
          text: text || null,
        },
        { skipNulls: true },
      ),
    });
  };

  return (
    <ModerationLayout>
      <ContentsHeader
        defaultValue={filters.text}
        onTextChange={handleTextChange}
      />
      <AddContentForm sx={{ mb: 3 }} onSubmit={handleAddContent} />
      <ContentsList
        contents={contents}
        loading={isLoading}
        onEdit={handleEditContent}
        onRemove={handleRemoveContent}
      />
    </ModerationLayout>
  );
};

import React from 'react';
import Grid from '@mui/material/Grid';
import {
  Container,
  Divider,
} from 'components/features/business/shared/item/components';
import { Control, Controller, FormState } from 'react-hook-form';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { IAttachment } from 'interfaces/Attachment';
import { LogoUploader } from 'components/elements/uploaders/LogoUploader';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { ISoftware } from 'interfaces/Software';

type Props = {
  control: Control<ISoftware>;
  formState: FormState<ISoftware>;
  onClickNext: () => void;
  onClickCancel: () => void;
  softwareTypes: IDictionariesContent[];
  onLoadSoftwareTypes: () => void;
  programmingLanguages: IDictionariesContent[];
  onLoadProgrammingLanguages: () => void;
  applicationAreas: IDictionariesContent[];
  onLoadApplicationAreas: () => void;
  technologyStack: IDictionariesContent[];
  onLoadTechnologyStack: () => void;
  onUploadLogo: (formData: FormData) => void;
  logo?: IAttachment;
  attachments: IAttachment[];
  onUploadAttachments: (formData: FormData) => void;
  onDeleteLogo: () => void;
  onRemoveAttachments: (id: string) => void;
};
export const SoftwareCommonDataForm = ({
  control,
  formState,
  onClickNext,
  onClickCancel,
  softwareTypes,
  onLoadSoftwareTypes,
  onUploadLogo,
  logo,
  onUploadAttachments,
  attachments,
  onDeleteLogo,
  onRemoveAttachments,
  applicationAreas,
  onLoadApplicationAreas,
  onLoadProgrammingLanguages,
  programmingLanguages,
  onLoadTechnologyStack,
  technologyStack,
}: Props) => {
  const { errors, isValid } = formState;

  return (
    <Container container spacing={4}>
      <Grid item container xs={8} spacing={3}>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'name'}
            label={'Наименование ПО'}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="softwareTypes"
              control={control}
              defaultValue={[]}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <MultiSelectAutocomplete
                    {...rest}
                    data={softwareTypes}
                    onLoadOptions={onLoadSoftwareTypes}
                    inputRef={ref}
                    label={'Тип ПО'}
                    errorMessage={errors.softwareTypes?.toString()}
                    multiple
                    noHierarchy
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="programmingLanguages"
              control={control}
              defaultValue={[]}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <MultiSelectAutocomplete
                    {...rest}
                    data={programmingLanguages}
                    onLoadOptions={onLoadProgrammingLanguages}
                    inputRef={ref}
                    label={'Язык программирования'}
                    errorMessage={errors.programmingLanguages?.toString()}
                    multiple
                    noHierarchy
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="technologyStack"
              control={control}
              defaultValue={[]}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <MultiSelectAutocomplete
                    {...rest}
                    data={technologyStack}
                    onLoadOptions={onLoadTechnologyStack}
                    inputRef={ref}
                    label={'Технологии'}
                    errorMessage={errors.technologyStack?.toString()}
                    multiple
                    noHierarchy
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="applicationAreas"
              control={control}
              defaultValue={[]}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <MultiSelectAutocomplete
                    {...rest}
                    data={applicationAreas}
                    onLoadOptions={onLoadApplicationAreas}
                    inputRef={ref}
                    label={'Область применения'}
                    errorMessage={errors.applicationAreas?.toString()}
                    multiple
                    noHierarchy
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'description'}
            label={'Краткое описание'}
            errors={errors}
            control={control}
            multiline
            isEditor
          />
        </Grid>
      </Grid>
      <Grid item container xs={4}>
        <LogoUploader
          logoUrl={logo?.path}
          onClickUploadLogo={onUploadLogo}
          onClickDeleteLogo={onDeleteLogo}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <FilesUploader
            maxFiles={10}
            onUploadAttachments={onUploadAttachments}
            attachments={attachments}
            onClickRemoveAttachments={onRemoveAttachments}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        <Grid item style={{ width: 136 }}>
          <CreateButton fullWidth text={'Назад'} onClick={onClickCancel} />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            disabled={!isValid}
            onClick={onClickNext}
            text={'Далее'}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

import React from 'react';
import { CardLayout } from 'components/features/auth/layout';
import { ChangePasswordForm } from 'components/features/auth/containers/changePassword/form';

export const ChangePassword = () => {
  return (
    <CardLayout title={'Восстановление доступа'}>
      <ChangePasswordForm />
    </CardLayout>
  );
};

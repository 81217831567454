import { styled } from '@mui/system';

export const Container = styled('div')({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

export const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '29px',
  color: ' #3D3D3D',
  padding: '30px 27px',
});

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { attachUrl } from 'services/ApiClient';
import { Loader } from 'components/shared/loader';
import LogoEmptyIcon from 'assets/icons/logoEmptyIcon.svg';
import CloseIcon from '@mui/icons-material/Close';

const Close = styled(CloseIcon)({
  cursor: 'pointer',
  position: 'absolute',
  right: 0,
});

const Image = styled('img')({
  width: '100%',
  maxWidth: 97,
  height: 97,
  borderRadius: 5,
  objectFit: 'cover',
});

const FilledImageContainer = styled('div')({
  width: '100%',
  maxWidth: 97,
  height: 97,
  position: 'relative',
});

const EmptyImage = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 97,
  height: 97,
  cursor: 'pointer',
  color: '#969696',
  border: '1px solid #A9A9A9',
  borderRadius: 5,
  background: `url('${LogoEmptyIcon}') center no-repeat`,
});

const ImageContainer = styled('div')({
  display: 'flex',
  height: 'min-content',
  position: 'relative',
});

const TextContainerTitle = styled('div')({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '20px',
  color: '#4B4B4B',
});

const TextContainer = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '19px',
  color: '#A9A9A9',
  paddingRight: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

type Props = {
  logoUrl?: string;
  onClickUploadLogo: (formData: FormData) => void;
  onClickDeleteLogo: () => void;
  text?: string;
};
export const LogoUploader = ({
  onClickUploadLogo,
  logoUrl,
  onClickDeleteLogo,
  text,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    hiddenFileInput.current && hiddenFileInput.current?.click();
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const fileUploaded = event.target.files?.[0];
    if (fileUploaded && fileUploaded.size < 1048576) {
      const formData = new FormData();
      fileUploaded && formData.append('file', fileUploaded);
      await onClickUploadLogo(formData);
    }
    setIsLoading(false);
    event.target.value = '';
  };

  return (
    <ImageContainer>
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <TextContainer>
        <TextContainerTitle>Логотип</TextContainerTitle>
        <div>
          {text ||
            'Добавьте логотип Вашей компании, логотип будет отображаться при просмотре карточки бизнеса'}
        </div>
      </TextContainer>
      {logoUrl ? (
        <FilledImageContainer>
          <Image src={`${attachUrl}${logoUrl}`} alt={'logo'} loading="lazy" />
          <Close onClick={onClickDeleteLogo} />
        </FilledImageContainer>
      ) : (
        <EmptyImage onClick={handleClick}>
          {isLoading ? <Loader show /> : ''}
        </EmptyImage>
      )}
    </ImageContainer>
  );
};

import { IFieldError } from 'interfaces/APIResponseError';
import { FieldPath, UseFormSetError } from 'react-hook-form';
import { IMultiLanguage, Locale } from 'interfaces/Locale';
import i18n from 'services/i18n';

export const setFromError = <T>(
  fields: FieldPath<T>[],
  fieldErrors: IFieldError[],
  setError: UseFormSetError<T>,
) => {
  const getFieldMessage = (fieldName: string) =>
    fieldErrors.find((el) => el.fieldName === fieldName)?.fieldError;
  fields.forEach((field) => {
    setError(field, {
      message: getFieldMessage(field),
    });
  });
};

export const getLocaleValue = (value: IMultiLanguage | undefined): string => {
  const primaryLang = `${i18n.languages[0]}` as Locale;
  const secondaryLang = `${i18n.languages[1]}` as Locale;
  return value?.[`${primaryLang}`] || value?.[`${secondaryLang}`] || '';
};

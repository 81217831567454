import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

export const Container = styled(Grid)({
  display: 'flex',
  position: 'relative',
  background: '#FFFFFF',
  borderRadius: 5,
  padding: '35px 15px 35px 30px',
  marginBottom: 30,
  minHeight: 150,
  boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.2)',
  },
});

export const Title = styled('div')({
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '17px',
  color: '#A9A9A9',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});
export const Value = styled('div')({
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  color: '#000000',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});
export const WebSiteLink = styled(Link)({
  color: '#00618E',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
  textDecoration: 'none',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

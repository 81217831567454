import React, { useEffect, useState } from 'react';
import { styled, SxProps, Theme } from '@mui/system';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover,
  RadioGroup,
} from '@mui/material';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { QuestionsFilters as QuestionsFiltersType } from 'interfaces/QuestionsFilters';
import { RadioButton } from 'components/shared/radio';
import { startOfToday, subMonths, subWeeks } from 'date-fns';
import { Checkbox } from 'components/shared/checkbox';
import { useAppDispatch, useAppSelector } from 'store';
import { getPublishedBusinessCardsList } from 'store/features/business';
import { getPublishedBusinessCards } from 'store/features/business/actions';
import { useTranslation } from 'react-i18next';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 8px',
});

const StyledButton = styled(Button)({
  borderRadius: 4,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: 12,
  color: '#000',
  textTransform: 'none',
  boxShadow: 'none',
});

const Title = styled(FormLabel)({
  marginBottom: 8,
  fontWeight: '600',
  fontSize: 12,
  color: '#4b4b4b',
});

const Label = styled(FormControlLabel)({
  color: '#4B4B4B',
  '& span': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
  },
});

const Radio = styled(RadioButton)({
  marginRight: 12,
  padding: 0,
});

type Filters = Pick<
  QuestionsFiltersType,
  'fromDate' | 'businessCardIds' | 'myQuestions'
>;

type Props = {
  initial?: Filters;
  sx?: SxProps<Theme>;
  usePeriodFilters?: boolean;
  useBusinessFilters?: boolean;
  useAuthorFilters?: boolean;
  onChange?: (filters: Filters) => void;
};

export const QuestionsFilters = ({
  initial = {},
  sx = [],
  usePeriodFilters = false,
  useBusinessFilters = false,
  useAuthorFilters = false,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const business = useAppSelector(getPublishedBusinessCardsList);

  const [fromDate, setFromDate] = useState<number | null>(
    initial.fromDate ?? null,
  );

  const [businessCards, setBusinessCards] = useState<string[]>(
    initial.businessCardIds ?? [],
  );

  const [myQuestions, setMyQuestions] = useState(initial.myQuestions ?? false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFromDateChange = (_: unknown, value: string) => {
    const current = value ? parseInt(value) : null;
    setFromDate(current);
    onChange?.({
      businessCardIds: businessCards,
      fromDate: current || undefined,
      myQuestions,
    });
  };

  const isBusinessCardSelected = (id: string) => {
    return Boolean(businessCards.find((b) => b === id));
  };

  const handleChangeBusinessCards =
    (id: string) => (_: unknown, checked: boolean) => {
      const newBusinessCards = checked
        ? [...businessCards, id]
        : businessCards.filter((b) => b !== id);

      setBusinessCards(newBusinessCards);
      onChange?.({
        ...(fromDate ? { fromDate } : {}),
        businessCardIds: newBusinessCards,
        myQuestions,
      });
    };

  const handleChangeMyQuestions = (_: unknown, checked: boolean) => {
    setMyQuestions(checked);
    onChange?.({
      ...(fromDate ? { fromDate } : {}),
      businessCardIds: businessCards,
      myQuestions: checked,
    });
  };

  useEffect(() => {
    dispatch(getPublishedBusinessCards());
  }, [dispatch]);

  const today = startOfToday();

  return (
    <Container sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <StyledButton onClick={handleOpen}>
        <FilterIcon style={{ marginRight: 4 }} />
        Фильтр
      </StyledButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        {usePeriodFilters && (
          <FormControl sx={{ padding: '16px' }}>
            <Title>{t('filters.period.title')}</Title>
            <FormGroup>
              <RadioGroup
                value={fromDate ? `${fromDate}` : ''}
                onChange={handleFromDateChange}
              >
                <Label
                  labelPlacement="end"
                  value={''}
                  control={<Radio />}
                  label={t('filters.period.all')}
                />
                <Label
                  labelPlacement="end"
                  value={`${today.getTime()}`}
                  control={<Radio />}
                  label={t('filters.period.today')}
                />
                <Label
                  labelPlacement="end"
                  value={`${subWeeks(today, 1).getTime()}`}
                  control={<Radio />}
                  label={t('filters.period.week')}
                />
                <Label
                  labelPlacement="end"
                  value={`${subMonths(today, 1).getTime()}`}
                  control={<Radio />}
                  label={t('filters.period.month')}
                />
              </RadioGroup>
            </FormGroup>
          </FormControl>
        )}
        {useBusinessFilters && (
          <FormControl sx={{ padding: '16px' }}>
            <Title>Карточки бизнеса</Title>
            <FormGroup>
              {business.map((item) => (
                <Checkbox
                  key={item.id}
                  label={item.name.ru ?? item.name.en}
                  checked={isBusinessCardSelected(item.id)}
                  onChange={handleChangeBusinessCards(item.id)}
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
        {useAuthorFilters && (
          <FormControl sx={{ padding: '16px' }}>
            <Title>Автор</Title>
            <FormGroup>
              <Checkbox
                label="Созданные мной"
                checked={myQuestions}
                onChange={handleChangeMyQuestions}
              />
            </FormGroup>
          </FormControl>
        )}
      </Popover>
    </Container>
  );
};

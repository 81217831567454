import React, { useState } from 'react';
import { QuestionStatus } from 'interfaces/Question';
import { SearchTextFieldInput } from 'components/shared/inputs/SearchInput';
import { QuestionsFilters as QuestionsFiltersType } from 'interfaces/QuestionsFilters';
import { styled, SxProps, Theme } from '@mui/system';
import { GuideIcon } from '../../../elements/guideIcon';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 8px',
});

const FilterButton = styled('button')({
  marginRight: 40,
  padding: 8,
  border: 'none',
  fontFamily: 'inherit',
  fontWeight: '400',
  fontSize: 14,
  color: '#4b4b4b',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',

  '&:disabled': {
    borderRadius: 4,
    backgroundColor: '#f5f5f5',
  },
});

const SearchContainer = styled('div')({
  marginLeft: 'auto',
  width: 300,
  display: 'flex',
});

type Filters = Pick<QuestionsFiltersType, 'status' | 'text'>;

type Props = {
  initial?: Filters;
  sx?: SxProps<Theme>;
  onChange?: (filters: Filters) => void;
};

export const QuestionsHeader = ({ initial = {}, sx = [], onChange }: Props) => {
  const [query, setQuery] = useState<string>('');

  const [status, setStatus] = useState<QuestionStatus>(
    initial.status ?? QuestionStatus.ACTIVE,
  );

  const handleQueryChange = (value: string) => {
    onChange?.({ status, text: value });
    setQuery(value);
  };

  const handleStatusChange = (value: QuestionStatus) => () => {
    onChange?.({ status: value, text: query });
    setStatus(value);
  };

  return (
    <Container sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <FilterButton
        disabled={status === QuestionStatus.ACTIVE}
        onClick={handleStatusChange(QuestionStatus.ACTIVE)}
      >
        Открытые
      </FilterButton>
      <FilterButton
        disabled={status === QuestionStatus.CLOSED}
        onClick={handleStatusChange(QuestionStatus.CLOSED)}
      >
        Закрытые
      </FilterButton>
      <SearchContainer>
        <SearchTextFieldInput
          defaultValue={initial.text ?? ''}
          onChangeSearch={handleQueryChange}
        />
        <GuideIcon withContainer />
      </SearchContainer>
    </Container>
  );
};

import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Title,
} from 'components/features/business/containers/item/update/components';
import { getBusinessCard } from 'store/features/business/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getBusinessCardItem,
  getBusinessCardLoading,
} from 'store/features/business';
import { TabLayout } from 'components/elements/tabs';
import { Loader } from 'components/shared/loader';
import { CommonDataView } from 'components/features/business/shared/item/view/commonData/BusinessCommonData';
import { ServiceDataView } from 'components/features/business/shared/item/view/serviceData';
import { URLS } from 'defenitions/routes';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useTranslation } from 'react-i18next';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { BusinessReviewsData } from 'components/features/business/shared/item/view/reviewsData/BusinessReviews';

export const ViewBusinessCard = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const { id } = useParams();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
  const { isOpen, onClickOpen, onClickClose } = useModalActions();
  const dispatch = useAppDispatch();
  const businessCard = useAppSelector(getBusinessCardItem);
  const isBusinessCardLoading = useAppSelector(getBusinessCardLoading);

  const onClickCancel = () => navigate(-1);
  const onClickNext = () => setTab((prevState) => prevState + 1);
  const onClickBack = () => setTab((prevState) => prevState - 1);

  const handleClickEdit = useCallback(() => {
    navigate(`${URLS.business}/${id}/edit`);
  }, [id, navigate]);

  useEffect(() => {
    id && dispatch(getBusinessCard(id));
  }, [dispatch, id]);

  return (
    <Container>
      {isBusinessCardLoading && <Loader show global />}
      <Title>{businessCard?.name.ru}</Title>
      <TabLayout
        onMouseLeave={() => setAnchorEl(null)}
        tab={tab}
        disabled={false}
        onChangeTabs={handleChange}
        tabs={[
          {
            label: t('tabs.common data'),
            component: (
              <CommonDataView
                businessCard={businessCard}
                onClickCancel={onClickCancel}
                onClickNext={onClickNext}
              />
            ),
          },
          {
            label: t('tabs.service data'),
            component: (
              <ServiceDataView
                onClickNext={onClickNext}
                onClickBack={onClickBack}
                businessServices={businessCard?.services || []}
                isCreatedByCurrentAccount={
                  !!businessCard?.isCreatedByCurrentAccount
                }
              />
            ),
          },
          {
            label: t('tabs.reviews'),
            component: (
              <BusinessReviewsData
                businessCard={businessCard}
                onClickCancel={onClickCancel}
                onClickBack={onClickBack}
              />
            ),
          },
        ]}
      />
      <MoreIcon
        style={{ top: 120 }}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      />
      {anchorEl && (
        <MoreMenu
          anchorEl={anchorEl}
          onClickEdit={
            businessCard?.isCreatedByCurrentAccount
              ? handleClickEdit
              : undefined
          }
          onClickComplaint={
            !businessCard?.isCreatedByCurrentAccount ? onClickOpen : undefined
          }
        />
      )}
      {id && (
        <ConfirmDialog
          onClickClose={onClickClose}
          open={isOpen}
          title={t('modalTexts.complaint.title', {
            name: getLocaleValue(businessCard?.name),
          })}
        >
          <CreateComplaint
            relationId={id}
            complaintAboutType={ComplaintAboutType.BUSINESS_CARD}
            additionAction={onClickClose}
          />
        </ConfirmDialog>
      )}
    </Container>
  );
};

import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import { ReactComponent as InfoIcon } from 'assets/icons/informationCircle.svg';
import Paper from '@mui/material/Paper';
import { Locale } from 'interfaces/Locale';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';

type Props = {
  errorMessage?: string;
  locale?: Locale;
  onClickChangeLocale?: () => void;
  maxLength?: number;
  value?: string;
};

const LocaleSwitcher = styled('div')({
  background: '#E4E6EB',
  borderRadius: '50%',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 16,
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#A9A9A9',
  width: 36,
  height: 36,
  textTransform: 'uppercase',
  cursor: 'pointer',
  marginLeft: 10,
});

const ErrorMessageContainer = styled(Paper)({
  boxShadow: ' 0px 0px 20px rgba(6, 31, 65, 0.1)',
  borderRadius: 8,
  padding: 16,
  fontSize: 14,
  lineHeight: '20px',
  color: '#9499A6',
});

const MaxLength = styled('div')({
  fontSize: 14,
  color: '#a9a9a9',
});

const CustomTextFieldInput = styled(
  ({
    errorMessage,
    locale,
    onClickChangeLocale,
    maxLength,
    value,
    ...props
  }: TextFieldProps & Props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<SVGElement | null>(null);
    const [showPassword, setShowPassword] = React.useState<boolean>(
      props.type !== 'password',
    );

    const handleClickShowPassword = () => setShowPassword(true);
    const handleMouseDownPassword = () => setShowPassword(false);
    const MessageText = () => {
      return (
        <Popper
          open={!!anchorEl}
          anchorEl={anchorEl}
          placement={'top-start'}
          style={{ zIndex: 9999 }}
        >
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        </Popper>
      );
    };

    const endAdornment = (): React.ReactNode => {
      return (
        <InputAdornment
          position="end"
          style={{ marginRight: props.select ? 20 : 0 }}
        >
          {props.error && (
            <>
              <InfoIcon
                onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
                fill={props.error ? '#E04311' : '#9499A6'}
                style={{ cursor: 'pointer' }}
              />
              <MessageText />
            </>
          )}
          {locale && (
            <LocaleSwitcher onClick={onClickChangeLocale}>
              {locale}
            </LocaleSwitcher>
          )}
          {props.type === 'password' && (
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          )}
        </InputAdornment>
      );
    };

    return (
      <>
        <TextField
          fullWidth
          size={'small'}
          InputProps={{
            disableUnderline: true,
            endAdornment: !props.select && endAdornment(),
          }}
          value={value}
          {...props}
          inputProps={{
            maxLength: maxLength,
            ...props.inputProps,
          }}
          type={showPassword ? 'text' : 'password'}
        />
        {maxLength && (
          <MaxLength>{`${t('symbolLength')}: ${
            value?.length
          } / ${maxLength}`}</MaxLength>
        )}
      </>
    );
  },
)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    overflow: 'hidden',
    width: '100%',
    border: '1px solid #A9A9A9',
    transition: theme.transitions.create([
      'border-width',
      'border-color',
      'background-color',
    ]),
    '&:hover': {
      backgroundColor: '#eaeaea',
    },
    '&.Mui-focused': {
      backgroundColor: '#F8F8F8',
      border: '1px solid #CBCBCB',
    },
  },
  '& .Mui-error': {
    border: '1px solid #E04311',
  },
  '& .MuiInputLabel-root': {
    color: '#A9A9A9',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    paddingRight: 70,
    '&.Mui-focused': {
      color: '#A9A9A9',
    },
  },
  'MuiFilledInput-input': {
    padding: '0 4px',
  },
}));

export const TextFieldInput = ({
  label,
  errorMessage,
  onClickChangeLocale,
  ...props
}: Props & TextFieldProps) => {
  return (
    <CustomTextFieldInput
      label={label}
      error={!!errorMessage}
      errorMessage={errorMessage}
      onClickChangeLocale={onClickChangeLocale}
      variant="filled"
      {...props}
    />
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { styled, SxProps, Theme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'store';
import { getPublishedBusinessCardsList } from 'store/features/business';
import { getPublishedBusinessCards } from 'store/features/business/actions';
import { CreateAnswer, Question } from 'interfaces/Question';
import { Grid } from '@mui/material';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { IBusinessAttachment } from 'interfaces/Attachment';
import {
  createAnswer,
  uploadAnswerAttachments,
} from 'store/features/questions/actions';
import { AxiosResponse } from 'axios';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { answerValidationSchema } from './validationSchemes';
import { selectSoftwareCardAccount } from 'store/features/software';
import { getSoftwareCardAccount } from 'store/features/software/actions';

const Form = styled('form')({
  padding: '16px 24px',
});

type Data = Omit<CreateAnswer, 'questionId' | 'attachments'>;

type Props = {
  sx?: SxProps<Theme>;
  questionId: Question['id'];
};

export const AnswerForm = ({ sx = [], questionId }: Props) => {
  const dispatch = useAppDispatch();
  const businessCards = useAppSelector(getPublishedBusinessCardsList);
  const softwareCards = useAppSelector(selectSoftwareCardAccount);

  const [attachments, setAttachments] = useState<IBusinessAttachment[]>([]);

  const {
    formState: { isValid, errors },
    control,
    reset,
    handleSubmit,
    watch,
    trigger,
  } = useForm<Data>({
    mode: 'onChange',
    resolver: yupResolver(answerValidationSchema),
  });

  const handleSoftwareCardsOptions = useCallback(() => {
    dispatch(getSoftwareCardAccount());
  }, [dispatch]);

  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      const { payload } = await dispatch(uploadAnswerAttachments(formData));
      const { data } = payload as AxiosResponse<IBusinessAttachment[]>;
      data && setAttachments(data);
    },
    [dispatch],
  );

  const handleRemoveAttachments = useCallback((id: string) => {
    setAttachments((prevState) => prevState.filter((el) => el.id !== id));
  }, []);

  const business = businessCards.map((b) => ({
    selectable: false,
    code: b.id,
    display: b.name,
  }));

  const software = softwareCards.map((s) => ({
    code: s.id,
    display: s.name,
    selectable: true,
  }));

  const onSubmit = handleSubmit(async (data: Data) => {
    const answer = {
      ...data,
      businessCardId: business[0],
      questionId,
      attachments,
    };

    const result = await dispatch(createAnswer(answer)).unwrap();

    if (result) {
      setAttachments([]);
      reset();
    }
  });

  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (name === 'content.en' && type === 'change') {
        trigger('content.ru');
      }
      if (name === 'content.ru' && type === 'change') {
        trigger('content.en');
      }
    });

    return () => subscription.unsubscribe();
  }, [trigger, watch]);

  useEffect(() => {
    dispatch(getPublishedBusinessCards());
  }, [dispatch]);

  useEffect(() => {
    trigger();
  }, [trigger]);
  return (
    <Form sx={[...(Array.isArray(sx) ? sx : [sx])]} onSubmit={onSubmit}>
      <Grid container spacing={1.5}>
        {!!business?.length && (
          <Grid item xs={12}>
            <MultiSelectAutocomplete
              value={business[0]}
              noHierarchy
              disabled
              label="Бизнес"
              data={business}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            control={control}
            defaultValue={[]}
            name="softwareCardIds"
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value}
                noHierarchy
                multiple
                inputRef={ref}
                label="ПО"
                data={software}
                onLoadOptions={handleSoftwareCardsOptions}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            control={control}
            errors={errors}
            fieldName="content"
            label="Написать ответ"
            isEditor
          />
        </Grid>
        <Grid item xs={12}>
          <FilesUploader
            maxFiles={3}
            variant="small"
            attachments={attachments}
            hideTitle
            onUploadAttachments={handleUploadAttachments}
            onClickRemoveAttachments={handleRemoveAttachments}
          />
        </Grid>
        <Grid item xs={12}>
          <ActionButton
            fullWidth
            type="submit"
            text="Ответить"
            disabled={!isValid}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { IViewsStats } from 'interfaces/Stats';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';
import { StatsTable } from 'components/elements/table';
import {
  createHeadCell,
  createData,
} from 'components/features/stats/item/business/tables/views/table/utils';

export const ViewStats = ({ views }: { views: IViewsStats }) => {
  const { t } = useTranslation();

  return (
    <StatsCollapseLayout title={t('tables.tableView')}>
      <Grid item container>
        <StatsTable data={createData(views)} columns={createHeadCell()} />
      </Grid>
    </StatsCollapseLayout>
  );
};

import React from 'react';
import { Box, styled, SxProps, Theme } from '@mui/material';
import { SearchMatch } from 'interfaces/Search';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: 80,
  padding: theme.spacing(2, 4),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.1)',
}));

const Type = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '700',
  fontSize: 14,
  color: '#4b4b4b',
});

const Content = styled('p')({
  margin: 0,
  padding: 0,
  overflow: 'hidden',
  fontWeight: '700',
  fontSize: 14,
  color: '#4b4b4b',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

type Props = {
  sx?: SxProps<Theme>;
  match: SearchMatch;
  onFollowObject?: (url: string) => void;
};

export const MatchCard = ({ sx = [], match, onFollowObject }: Props) => {
  const { t } = useTranslation();

  const type = t(`search.type.${match.type}`);
  const content = match.fragments.map(({ content }) => content).join(' ');

  const handleFollowObject = () => {
    match.pathToViewObject && onFollowObject?.(match.pathToViewObject);
  };

  const decode = (str: string) => {
    const txt = new DOMParser().parseFromString(str, 'text/html');
    return txt.documentElement.textContent || '';
  };

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Type
        sx={
          match.pathToViewObject
            ? {
                mb: 1,
                color: '#004b7c',
                textDecoration: 'underline',
                cursor: 'pointer',
              }
            : { mb: 2 }
        }
        onClick={handleFollowObject}
      >
        {type}
      </Type>
      <Content
        dangerouslySetInnerHTML={{
          __html: truncate(decode(content), { length: 100, separator: ' ' }),
        }}
      />
    </Root>
  );
};

import React from 'react';
import Grid from '@mui/material/Grid';
import {
  Container,
  Divider,
} from 'components/features/business/shared/item/components';
import { TextFieldInput } from 'components/shared/inputs';
import {
  Control,
  Controller,
  FormState,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form';
import { IBusiness } from 'interfaces/Business';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { IBusinessAttachment } from 'interfaces/Attachment';
import { LogoUploader } from 'components/elements/uploaders/LogoUploader';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';

type Props = {
  control: Control<IBusiness>;
  formState: FormState<IBusiness>;
  onClickNext: () => void;
  onClickCancel: () => void;
  onClickSaveDraft: SubmitHandler<IBusiness>;
  handleSubmit: UseFormHandleSubmit<IBusiness>;
  cardTypes: IDictionariesContent[];
  onLoadCardTypes: () => void;
  onUploadLogo: (formData: FormData) => void;
  logo?: IBusinessAttachment;
  attachments: IBusinessAttachment[];
  onUploadAttachments: (formData: FormData) => void;
  onDeleteLogo: () => void;
  onRemoveAttachments: (id: string) => void;
  isUpdate: boolean;
};
export const BusinessCommonDataForm = ({
  control,
  formState,
  onClickNext,
  onClickCancel,
  onClickSaveDraft,
  handleSubmit,
  cardTypes,
  onLoadCardTypes,
  onUploadLogo,
  logo,
  onUploadAttachments,
  attachments,
  onDeleteLogo,
  onRemoveAttachments,
  isUpdate,
}: Props) => {
  const { errors, isValid, isSubmitting } = formState;

  return (
    <Container container spacing={4}>
      <Grid item container xs={8} spacing={3}>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="cardType"
              control={control}
              defaultValue={null}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <MultiSelectAutocomplete
                    {...rest}
                    data={cardTypes}
                    onLoadOptions={onLoadCardTypes}
                    inputRef={ref}
                    label={'Тип карточки'}
                    disabled={isUpdate}
                    errorMessage={errors.cardType?.toString()}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'name'}
            label={'Наименование организации'}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'address'}
            label={'Адрес'}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextFieldInput
                {...rest}
                inputRef={ref}
                label={'Электронный адрес'}
                errorMessage={errors.email?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="website"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextFieldInput
                {...rest}
                inputRef={ref}
                label={'Веб сайт'}
                errorMessage={errors.website?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'contactPerson'}
            label={'Контактное лицо'}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="contactPhone"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextFieldInput
                {...rest}
                inputRef={ref}
                label={'Контактный телефон'}
                errorMessage={errors.contactPhone?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'description'}
            label={'Краткое описание'}
            errors={errors}
            control={control}
            multiline
            isEditor
          />
        </Grid>
      </Grid>
      <Grid item container xs={4}>
        <LogoUploader
          logoUrl={logo?.path}
          onClickUploadLogo={onUploadLogo}
          onClickDeleteLogo={onDeleteLogo}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <FilesUploader
            maxFiles={10}
            onUploadAttachments={onUploadAttachments}
            attachments={attachments}
            onClickRemoveAttachments={onRemoveAttachments}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        {!isUpdate && (
          <Grid item style={{ width: 235 }}>
            <CreateButton
              fullWidth
              disabled={!isValid || isSubmitting}
              text={'Сохранить как черновик'}
              onClick={handleSubmit(onClickSaveDraft)}
            />
          </Grid>
        )}
        <Grid item style={{ width: 136 }}>
          <CreateButton fullWidth text={'Назад'} onClick={onClickCancel} />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            disabled={!isValid}
            onClick={onClickNext}
            text={'Далее'}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

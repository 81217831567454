import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { Avatar } from '@mui/material';

export const Container = styled(Grid)({
  padding: '8px 0px 8px 24px',
  borderBottom: '1px solid #E4E4E4',
  position: 'relative',
});

export const NotificationAvatar = styled(Avatar)({
  height: 55,
  width: 55,
});

export const NotificationAvatarContainer = styled('div')({
  height: 55,
  width: 55,
  borderRadius: '50%',
  background: '#00618E',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Title = styled(Grid)({
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '22px',
});
export const Info = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

export const UnreadIcon = styled('div')({
  background: '#00618E',
  width: 20,
  height: 20,
  position: 'absolute',
  top: '50%',
  right: 48,
  borderRadius: '50%',
});

export const ViewTitle = styled('div')({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '34px',
});

export const TitleDivider = styled('div')({
  background: '#DDDDDD',
  borderRadius: 8,
  height: 1,
  width: '100%',
});

import { ICreateSoftware, ISoftware } from 'interfaces/Software';
import { IAttachment } from 'interfaces/Attachment';

export const transformSoftwareDataForm = (
  data: ISoftware,
  logo: IAttachment | undefined,
  attachments: IAttachment[],
): ICreateSoftware => {
  return {
    logoId: logo?.id,
    attachmentIds: attachments.map((el) => el.id),
    name: data.name,
    description: data.description,
    applicationAreas: data.applicationAreas?.map((el) => el.code) || [],
    codeExamples:
      data.codeExamples?.map((el) => {
        return {
          ...el,
          programmingLanguage: el.programmingLanguage?.code || undefined,
        };
      }) || [],
    id: data.id,
    programmingLanguages: data.programmingLanguages?.map((el) => el.code) || [],
    softwareTypes: data.softwareTypes?.map((el) => el.code) || [],
    technologyStack: data.technologyStack?.map((el) => el.code) || [],
  };
};

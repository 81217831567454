import React from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { Loader } from 'components/shared/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IComplaint } from 'interfaces/Complaint';
import { ComplaintsCard } from './ComplaintsCard';
import { useTranslation } from 'react-i18next';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

const Item = styled('div')({
  marginBottom: 8,

  '&:last-of-type': {
    marginBottom: 0,
  },
});

const EmptyListContainer = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

type Props = {
  sx?: SxProps<Theme>;
  complaints: IComplaint[];
  loading?: boolean;
  refetching?: boolean;
  hasMore?: boolean;
  next?: () => void;
  onView?: (id: string) => void;
  onReject?: (id: string) => void;
  onContentDelete?: (id: string) => void;
  onFollowLink?: (url: string) => void;
};

export const ComplaintsList = ({
  sx = [],
  complaints,
  loading = false,
  refetching = false,
  hasMore = false,
  next,
  onView,
  onReject,
  onContentDelete,
  onFollowLink,
}: Props) => {
  const { t } = useTranslation();

  const handleNext = () => next?.();

  const handleView = (id: string) => () => {
    onView?.(id);
  };

  const handleReject = (id: string) => () => {
    onReject?.(id);
  };

  const handleContentDelete = (id: string) => () => {
    onContentDelete?.(id);
  };

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {loading && (
        <EmptyListContainer>
          <Loader show />
        </EmptyListContainer>
      )}

      {!loading && !complaints.length && (
        <EmptyListContainer>
          {t('moderation.complaints.empty')}
        </EmptyListContainer>
      )}

      {!loading && !!complaints.length && (
        <InfiniteScroll
          dataLength={complaints.length}
          loader={<Loader show={refetching} global />}
          hasMore={hasMore}
          next={handleNext}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {complaints.map((item) => (
            <Item key={item.id}>
              <ComplaintsCard
                complaint={item}
                onFollowLink={onFollowLink}
                menu={[
                  ...(onView
                    ? [
                        {
                          label: t('actions.view'),
                          onClick: handleView(item.id),
                        },
                      ]
                    : []),

                  ...(onReject
                    ? [
                        {
                          label: t('actions.rejectComplaint'),
                          onClick: handleReject(item.id),
                        },
                      ]
                    : []),

                  ...(onContentDelete
                    ? [
                        {
                          label: t('actions.deleteContent'),
                          onClick: handleContentDelete(item.id),
                        },
                      ]
                    : []),
                ]}
              />
            </Item>
          ))}
        </InfiniteScroll>
      )}
    </Root>
  );
};

import {
  IAccount,
  IAccountRating,
  IComplaint,
  IInvalidContent,
} from 'interfaces/Complaint';

export enum ActionTypes {
  CREATE_COMPLAINT = 'CREATE_COMPLAINT',
  UPLOAD_COMPLAINT_ATTACHMENT = 'UPLOAD_COMPLAINT_ATTACHMENT',
  GET_COMPLAINTS = 'GET_COMPLAINTS',
  RESOLVE_COMPLAINT = 'RESOLVE_COMPLAINT',
  DECLINE_COMPLAINT = 'DECLINE_COMPLAINT',
  GET_COMPLAINTS_ACCOUNTS = 'GET_COMPLAINTS_ACCOUNTS',
  GET_COMPLAINTS_ACCOUNTS_FILTER = 'GET_COMPLAINTS_ACCOUNTS_FILTER',
  GET_COMPLAINT_USERS = 'GET_COMPLAINT_USERS',
  GET_INVALID_CONTENTS = 'GET_INVALID_CONTENTS',
  ADD_INVALID_CONTENT = 'ADD_INVALID_CONTENT',
  REMOVE_INVALID_CONTENT = 'REMOVE_INVALID_CONTENT',
  EDIT_INVALID_CONTENT = 'EDIT_INVALID_CONTENT',
  GET_COMPLAINT_BY_ID = 'GET_COMPLAINT_BY_ID',
  GET_COMPLAINT_BY_RELATION_ID = 'GET_COMPLAINT_BY_RELATION_ID',
}

export interface IComplaintsState {
  complaints: {
    list: IComplaint[];
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
  accounts: {
    list: IAccountRating[];
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
  accountsFilter: {
    list: IAccount[];
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
  invalidContents: {
    list: IInvalidContent[];
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
  complaint: {
    item: IComplaint | null;
    loading: boolean;
    loaded: boolean;
  };
  sameComplaints: {
    list: IComplaint[];
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
}

export const initialComplaintsState: IComplaintsState = {
  complaints: {
    list: [],
    loading: false,
    loaded: false,
    hasMore: false,
  },
  accounts: {
    list: [],
    loading: false,
    loaded: false,
    hasMore: false,
  },
  accountsFilter: {
    list: [],
    loading: false,
    loaded: false,
    hasMore: false,
  },
  invalidContents: {
    list: [],
    loading: false,
    loaded: false,
    hasMore: false,
  },
  complaint: {
    item: null,
    loading: false,
    loaded: false,
  },
  sameComplaints: {
    list: [],
    loading: false,
    loaded: false,
    hasMore: false,
  },
};

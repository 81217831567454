import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { ReactComponent as MarketPlaceLogo } from 'assets/icons/business.svg';
import { ReactComponent as RequestsLogo } from 'assets/icons/memberBlueIcon.svg';
import { ReactComponent as GetServiceLogo } from 'assets/icons/getServiceIcon.svg';
import { ReactComponent as ProvideServiceLogo } from 'assets/icons/provideServiceIcon.svg';
import { MenuItemLink } from 'components/elements/navigationMenu/menuItem';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { URLS } from 'defenitions/routes';
import { MENU_WIDTH } from 'services/theme';
import { useTranslation } from 'react-i18next';
import { MenuAd } from 'components/elements/ads';

const Container = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: '20px 13px',
  borderRight: '1px solid #dddddd',
  width: MENU_WIDTH,
  background: theme.palette.background.paper,
}));

const Divider = styled('div')({
  borderBottom: '1px solid #dddddd',
});

const Title = styled('div')({
  margin: 0,
  padding: '0 24px',
  fontWeight: '700',
  fontSize: 18,
  color: '#3d3d3d',
});

export const NavigationMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Title>{t('catalogSoftware')}</Title>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <MenuItemLink
        link={URLS.marketplace}
        logo={<MarketPlaceLogo />}
        title={t('marketplace.navigation.compilation')}
      />
      <MenuItemLink
        link={URLS.marketplaceRequests}
        logo={<RequestsLogo />}
        title={t('marketplace.navigation.myRequests')}
      />
      <MenuItemLink
        link={URLS.marketplaceGetService}
        logo={<GetServiceLogo />}
        title={t('marketplace.navigation.receiveServices')}
      />
      <MenuItemLink
        link={URLS.marketplaceProvideService}
        logo={<ProvideServiceLogo />}
        title={t('marketplace.navigation.provideServices')}
      />
      <CreateButton
        fullWidth
        withPlusIcon
        style={{ marginTop: 18 }}
        text={t('marketplace.navigation.createServiceRequest')}
        onClick={() => navigate(URLS.marketplaceCreateRequest)}
      />
      <Divider sx={{ mt: '18px' }} />
      <MenuAd />
    </Container>
  );
};

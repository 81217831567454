import React, { useEffect } from 'react';
import { Container } from 'components/features/business/shared/item/components';
import Grid from '@mui/material/Grid';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormTrigger,
} from 'react-hook-form';
import { ICodeExamples } from 'interfaces/Software';
import { Editor } from 'components/shared/editor';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { MAX_LENGTH } from 'defenitions/errorMessages';

type Props = {
  control: Control<ICodeExamples>;
  programmingLanguages: IDictionariesContent[];
  onLoadProgrammingLanguages: () => void;
  errors: FieldErrors<ICodeExamples>;
  trigger: UseFormTrigger<ICodeExamples>;
};

export const EditCodeExamples = ({
  control,
  programmingLanguages,
  onLoadProgrammingLanguages,
  errors,
  trigger,
}: Props) => {
  useEffect(() => {
    trigger('code');
  }, [trigger]);
  return (
    <Container container>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={12}>
          <Controller
            name={`programmingLanguage`}
            control={control}
            defaultValue={null}
            render={({ field: { ref, ...rest } }) => {
              return (
                <MultiSelectAutocomplete
                  {...rest}
                  data={programmingLanguages}
                  onLoadOptions={onLoadProgrammingLanguages}
                  inputRef={ref}
                  noHierarchy
                  label={'Язык программирования'}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={`code`}
            defaultValue={''}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <Editor
                  value={value || ''}
                  onChange={onChange}
                  errorMessage={errors.code?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={`description`}
            label={'Краткое описание'}
            control={control}
            multiline
            maxLength={MAX_LENGTH}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

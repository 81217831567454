import React, { useEffect } from 'react';
import { ReactionType } from 'interfaces/Post';
import { useAppDispatch, useAppSelector } from 'store';
import {
  changeFilters,
  getNewsFilters,
  getNewsHasMore,
  getNewsList,
  getNewsLoaded,
  getNewsLoading,
  resetList,
} from 'store/features/news';
import {
  changeReaction,
  deleteNews,
  getImportantNews,
  updateImportantNewsList,
} from 'store/features/news/actions';
import { getAccount, getCurrent } from 'store/features/users';
import { PAGE_SIZE } from 'interfaces/NewsFilters';
import { NewsLayout } from 'components/features/news/components/layout';
import { NewsList } from 'components/features/news/components/NewsList';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

const Title = styled('div')({
  fontSize: '14px',
  fontWeight: 500,
  color: '#000000',
});

export const ImportantNews = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const hasMore = useAppSelector(getNewsHasMore);
  const loading = useAppSelector(getNewsLoading);
  const loaded = useAppSelector(getNewsLoaded);
  const news = useAppSelector(getNewsList);
  const currentUser = useAppSelector(getCurrent);
  const account = useAppSelector(getAccount);
  const filters = useAppSelector(getNewsFilters);

  const isSystemAccount = account?.isSystemAccount;

  const reloadPage = async () => {
    await dispatch(updateImportantNewsList());
  };

  const handleNextPage = () => {
    dispatch(
      changeFilters({
        ...filters,
        offset: (filters.offset ?? 0) + PAGE_SIZE,
        limit: PAGE_SIZE,
      }),
    );
  };

  const handleDelete = async (id: string) => {
    await dispatch(deleteNews(id));
    await reloadPage();
  };

  const handleLike = (id: string) => {
    dispatch(changeReaction({ id, type: ReactionType.LIKE }));
  };

  const handleDislike = (id: string) => {
    dispatch(changeReaction({ id, type: ReactionType.DISLIKE }));
  };

  useEffect(() => {
    dispatch(getImportantNews(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    return () => void dispatch(resetList());
  }, [dispatch]);

  return (
    <NewsLayout>
      {!news.length && !loading && loaded && (
        <Title>{t('news empty data')}</Title>
      )}
      <NewsList
        currentUserId={currentUser?.id}
        isSystemAccount={isSystemAccount}
        hasMore={hasMore}
        items={news}
        next={handleNextPage}
        onDelete={handleDelete}
        onLike={handleLike}
        onDislike={handleDislike}
      />
    </NewsLayout>
  );
};

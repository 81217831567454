import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';

import { NewsLayout } from 'components/features/news/components/layout';
import { useAppDispatch, useAppSelector } from 'store';
import { createNew, uploadAttachments } from 'store/features/news/actions';
import { getBusinessPublishedList } from 'store/features/business';
import { getBusinessPublished } from 'store/features/business/actions';
import { IAttachment, AttachmentType } from 'interfaces/Attachment';

import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { SelectTextFields } from 'components/shared/selects';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';

import { serviceDataValidationSchema } from './validationSchema';
import { IMultiLanguage } from 'interfaces/Locale';
import { newsFileTypes } from 'defenitions/fileUploader';
import { URLS } from 'defenitions/routes';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { selectSoftwareCardAccount } from 'store/features/software';
import { getSoftwareCardAccount } from 'store/features/software/actions';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { IDictionariesContent } from 'interfaces/Dictionaries';

const FormWrap = styled('div')({
  border: '1px solid #E4E4E4',
  padding: '23px 31px 110px 31px',
  width: '723px',
  borderRadius: '5px',
});

const Title = styled('div')({
  fontSize: '24px',
  fontWeight: 600,
  marginBottom: '36px',
});

const ButtonsWrap = styled('div')({
  display: 'flex',
  marginTop: 95,
  justifyContent: 'space-between',
  button: {
    width: '181px',
  },
});

const InputWrap = styled('div')({
  marginBottom: '27px',
});

interface CreateNewForm {
  title: IMultiLanguage;
  content: IMultiLanguage;
  businessCardId: string;
  softwareCardIds?: IDictionariesContent[];
}

export const CreateNew = () => {
  const language = 'ru';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const businessCards = useAppSelector(getBusinessPublishedList);
  const softwareCards = useAppSelector(selectSoftwareCardAccount);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid, isSubmitting },
    trigger,
    setValue,
  } = useForm<CreateNewForm>({
    mode: 'onChange',
    resolver: attachments.length
      ? undefined
      : yupResolver(serviceDataValidationSchema),
    defaultValues: {
      content: {
        ru: '',
        en: '',
      },
    },
  });
  const formData = watch();

  const canSendForm = useMemo(
    () =>
      attachments.length > 0 ||
      formData.content?.ru?.length > 0 ||
      formData.content?.en?.length > 0,
    [formData, attachments],
  );

  const businessOptions = useMemo(
    () =>
      businessCards.map((item) => ({
        id: item?.id,
        name: item?.name?.[language],
      })),
    [businessCards],
  );

  const softwareOptions: IDictionariesContent[] = useMemo(
    () =>
      softwareCards.map((item) => ({
        code: item.id,
        display: item.name,
        selectable: true,
      })),
    [softwareCards],
  );

  useEffect(() => {
    dispatch(getBusinessPublished());
    dispatch(getSoftwareCardAccount());
  }, [dispatch]);

  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      if (formData.get('files') === null) return;
      const { payload } = await dispatch(
        uploadAttachments({ formData, type: AttachmentType.NewsPost }),
      );
      const { data } = payload as AxiosResponse<IAttachment[]>;
      data && setAttachments([...attachments, ...data]);
    },
    [dispatch, attachments],
  );

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleRemoveAttachment = useCallback(
    (id) => {
      setAttachments(attachments.filter((attachment) => attachment.id !== id));
    },
    [attachments],
  );

  const onSubmit = useCallback(
    async (data: CreateNewForm) => {
      await dispatch(
        createNew({
          ...data,
          softwareCardIds: data.softwareCardIds?.map((el) => el.code),
          attachments: attachments.map((item) => item.id),
        }),
      );
      handleGoBack();
    },
    [attachments, handleGoBack, dispatch],
  );

  const handleSoftwareCardsOptions = useCallback(() => {
    dispatch(getSoftwareCardAccount());
  }, [dispatch]);

  useEffect(() => {
    businessOptions.length === 1 &&
      setValue('businessCardId', businessOptions[0].id);
    trigger('businessCardId');
  }, [businessOptions, setValue, trigger]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    trigger('content');
  }, [attachments, trigger]);
  return (
    <NewsLayout>
      <FormWrap>
        <Title>Создать публикацию</Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrap>
            <Controller
              name="businessCardId"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <SelectTextFields
                  {...rest}
                  options={businessOptions}
                  inputRef={ref}
                  label={'Бизнес'}
                  errorMessage={errors.businessCardId?.message}
                  createLink={URLS.createBusinessCard}
                  disabled={!!businessOptions.length}
                />
              )}
            />
          </InputWrap>
          <InputWrap>
            <Controller
              control={control}
              name="softwareCardIds"
              defaultValue={[]}
              render={({ field: { ref, value, ...rest } }) => (
                <MultiSelectAutocomplete
                  {...rest}
                  value={value ?? null}
                  noHierarchy
                  inputRef={ref}
                  label="ПО"
                  multiple
                  data={softwareOptions}
                  onLoadOptions={handleSoftwareCardsOptions}
                />
              )}
            />
          </InputWrap>
          <InputWrap>
            <FormMultiLocaleField
              fieldName={'title'}
              label={'Тема публикации'}
              errors={errors}
              control={control}
            />
          </InputWrap>
          <InputWrap>
            <FormMultiLocaleField
              fieldName={'content'}
              label={'Текст публикации'}
              errors={errors}
              control={control}
              minRows={6}
              multiline
              isEditor
            />
          </InputWrap>
        </form>
        <FilesUploader
          onClickRemoveAttachments={handleRemoveAttachment}
          onUploadAttachments={handleUploadAttachments}
          attachments={attachments}
          hideTitle
          accept={newsFileTypes}
          maxFiles={3}
          maxSize={26214400}
        />
        <ButtonsWrap>
          <CreateButton fullWidth text={'Отменить'} onClick={handleGoBack} />
          <ActionButton
            fullWidth
            disabled={!isValid || isSubmitting || !canSendForm}
            text={'Опубликовать'}
            type={'submit'}
            onClick={handleSubmit(onSubmit)}
          />
        </ButtonsWrap>
      </FormWrap>
    </NewsLayout>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from 'store/features/dictionaries/config';
import { actionHandler } from 'store/utils/actionHandler';
import {
  getDictionariesCardTypeEndpoint,
  getDictionariesQuestionCategoriesEndpoint,
  getDictionariesQuestionSubcategoriesEndpoint,
  getDictionariesServiceTypeEndpoint,
  getDictionariesContentCardTypeEndpoint,
  getDictionariesContentServiceTypeEndpoint,
  getDictionariesContentApplicationAreasEndpoint,
  getDictionariesContentProgrammingLanguagesEndpoint,
  getDictionariesContentSoftwareTypesEndpoint,
  getDictionariesContentTechnologyStackEndpoint,
} from 'store/features/dictionaries/api';
import { Types } from 'interfaces/Users';

export const getDictionariesCardType = createAsyncThunk(
  ActionTypes.GET_CARD_TYPE_DICTIONARIES,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getDictionariesCardTypeEndpoint(), rejectWithValue),
);

export const getDictionariesContentCardType = createAsyncThunk(
  ActionTypes.GET_CARD_TYPE_DICTIONARIES_CONTENT,
  async (type: Types | undefined, { rejectWithValue }) =>
    actionHandler(
      () => getDictionariesContentCardTypeEndpoint(type),
      rejectWithValue,
    ),
);

export const getDictionariesServiceType = createAsyncThunk(
  ActionTypes.GET_SERVICE_TYPE_DICTIONARIES,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getDictionariesServiceTypeEndpoint(), rejectWithValue),
);

export const getDictionariesContentServiceType = createAsyncThunk(
  ActionTypes.GET_SERVICE_TYPE_DICTIONARIES_CONTENT,
  async (any, { rejectWithValue }) =>
    actionHandler(
      () => getDictionariesContentServiceTypeEndpoint(),
      rejectWithValue,
    ),
);

export const getDictionariesQuestionCategories = createAsyncThunk(
  ActionTypes.GET_QUESTION_CATEGORIES_DICTIONARIES,
  async (any, { rejectWithValue }) =>
    actionHandler(
      () => getDictionariesQuestionCategoriesEndpoint(),
      rejectWithValue,
    ),
);

export const getDictionariesQuestionSubcategories = createAsyncThunk(
  ActionTypes.GET_QUESTION_SUBCATEGORIES_DICTIONARIES,
  async ({ directory }: { directory: string }, { rejectWithValue }) =>
    actionHandler(
      () => getDictionariesQuestionSubcategoriesEndpoint(directory),
      rejectWithValue,
    ),
);

export const getDictionariesContentSoftwareTypes = createAsyncThunk(
  ActionTypes.GET_SOFTWARE_TYPES_DICTIONARIES,
  async (any, { rejectWithValue }) =>
    actionHandler(
      () => getDictionariesContentSoftwareTypesEndpoint(),
      rejectWithValue,
    ),
);

export const getDictionariesContentProgrammingLanguages = createAsyncThunk(
  ActionTypes.GET_PROGRAMMING_LANGUAGES_DICTIONARIES,
  async (any, { rejectWithValue }) =>
    actionHandler(
      () => getDictionariesContentProgrammingLanguagesEndpoint(),
      rejectWithValue,
    ),
);

export const getDictionariesContentApplicationAreas = createAsyncThunk(
  ActionTypes.GET_APPLICATION_AREAS_DICTIONARIES,
  async (any, { rejectWithValue }) =>
    actionHandler(
      () => getDictionariesContentApplicationAreasEndpoint(),
      rejectWithValue,
    ),
);

export const getDictionariesContentTechnologyStack = createAsyncThunk(
  ActionTypes.GET_TECHNOLOGY_STACK_DICTIONARIES,
  async (any, { rejectWithValue }) =>
    actionHandler(
      () => getDictionariesContentTechnologyStackEndpoint(),
      rejectWithValue,
    ),
);

import React, { useEffect, useRef, useState } from 'react';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@mui/system';
import {
  getServiceRequestsList,
  hasMoreMarketplaceListItems,
  isMarketplaceListLoading,
} from 'store/features/marketplace';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getIncomingServiceRequests,
  likeServiceRequest,
  marketplaceDeclineServiceRequest,
} from 'store/features/marketplace/actions';
import {
  IProvideServicesPageFilters,
  IServiceRequest,
  ServiceRequestFromPages,
  ServiceRequestStatus,
} from 'interfaces/Marketplace';
import { Loader } from 'components/shared/loader';
import { ServiceRequestCard } from 'components/features/marketplace/components/cards/ServiceRequestCard';
import { Modal } from 'components/shared/modal';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { serviceDataValidationSchema } from './validationSchema';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { IMultiLanguage } from 'interfaces/Locale';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { ReactionType } from 'interfaces/Post';
import { ListAdOne, ListAdTwo } from '../../../../elements/ads';

const EmptyText = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
});
const ModalBody = styled('div')({
  padding: 20,
});

type Props = {
  filters?: IProvideServicesPageFilters;
  onFiltersChange?: (filters: IProvideServicesPageFilters) => void;
};

export const IncomingServiceRequestsList = ({
  filters = {},
  onFiltersChange,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    watch,
  } = useForm<{ title: IMultiLanguage }>({
    mode: 'onChange',
    resolver: yupResolver(serviceDataValidationSchema),
    defaultValues: {},
  });

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name === 'title.en' && type === 'change') {
        trigger('title.ru');
      }
      if (name === 'title.ru' && type === 'change') {
        trigger('title.en');
      }
    });
    return () => subscription.unsubscribe();
  }, [trigger, watch]);

  const limit = filters.limit ?? 10;
  const offset = filters.offset ?? 0;

  const [openCancelModalId, setOpenCancelModalId] = useState<
    string | undefined
  >();

  const isLoading = useAppSelector(isMarketplaceListLoading);
  const hasMore = useAppSelector(hasMoreMarketplaceListItems);
  const requests = useAppSelector(getServiceRequestsList);

  const isFetching = offset === 0 && isLoading;
  const isRefetching = offset !== 0 && isLoading;

  const prevFilters = useRef<IProvideServicesPageFilters>({});

  useEffect(() => {
    const currentFilters = { ...filters, limit, offset };

    if (!isEqual(prevFilters.current, currentFilters)) {
      dispatch(getIncomingServiceRequests(currentFilters));
      prevFilters.current = currentFilters;
    }
  }, [dispatch, filters, limit, offset]);

  const changeFilters = () => {
    onFiltersChange?.({ limit, offset: offset + limit });
  };

  const navigateToService = (id: IServiceRequest['id']) => () => {
    navigate({
      pathname: `/marketplace/request/${id}`,
      search: qs.stringify({
        from: ServiceRequestFromPages.ReceivedRequests,
      }),
    });
  };

  const navigateToCreateAnswer = (request: IServiceRequest) => () => {
    navigate({
      pathname: `/marketplace/offer/${request.id}/answer`,
      search: qs.stringify({
        businessCard: request?.receiverBusinessCard?.id,
      }),
    });
  };

  const handleCancel = (id: IServiceRequest['id']) => () => {
    setOpenCancelModalId(id);
  };

  const handleDecline = async (data: any) => {
    await dispatch(
      marketplaceDeclineServiceRequest({
        ...data,
        offerId: openCancelModalId,
      }),
    );
    setOpenCancelModalId(undefined);
    reset();
    dispatch(getIncomingServiceRequests(filters));
  };
  const { isOpen, onClickOpen, onClickClose } = useModalActions();
  const [selectedService, setSelectedService] =
    useState<IServiceRequest | null>(null);
  const handleClickCreateComplaint = (service: IServiceRequest) => {
    onClickOpen();
    setSelectedService(service);
  };
  const handleClickCloseComplaint = () => {
    onClickClose();
    setSelectedService(null);
  };

  const handleLike = (id: IServiceRequest['id']) => {
    dispatch(likeServiceRequest({ id, type: ReactionType.LIKE }));
  };

  const handleDislike = (id: IServiceRequest['id']) => {
    dispatch(likeServiceRequest({ id, type: ReactionType.DISLIKE }));
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <>
      {isFetching && <Loader sx={{ m: 'auto' }} show />}
      {!isFetching && !requests.length && (
        <EmptyText sx={{ m: 'auto' }}>
          {t('marketplace.emptyRequests')}
        </EmptyText>
      )}
      {!isFetching && !!requests.length && (
        <InfiniteScroll
          dataLength={requests.length}
          loader={<Loader show={isRefetching} global />}
          next={changeFilters}
          hasMore={hasMore}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {requests.map((request, index) => (
            <>
              {index % 4 === 0 && index % 8 !== 0 && index !== 0 && (
                <ListAdOne />
              )}
              {index % 4 === 0 && index % 8 === 0 && index !== 0 && (
                <ListAdTwo />
              )}
              <ServiceRequestCard
                sx={[{ mb: 2 }, index === requests.length - 1 && { mb: 0 }]}
                key={request.id}
                request={request}
                onLike={handleLike}
                onDislike={handleDislike}
                menu={[
                  {
                    label: t('actions.view'),
                    onClick: navigateToService(request.id),
                  },
                  ...(request.status === ServiceRequestStatus.PUBLISHED
                    ? [
                        {
                          label: t('actions.answer'),
                          onClick: navigateToCreateAnswer(request),
                        },
                        {
                          label: t('actions.reject'),
                          onClick: handleCancel(request.id),
                        },
                        {
                          label: t('actions.complaint'),
                          onClick: () => {
                            handleClickCreateComplaint(request);
                          },
                        },
                      ]
                    : []),
                ]}
              />
            </>
          ))}
        </InfiniteScroll>
      )}
      {openCancelModalId !== undefined && (
        <Modal
          title={t('modalTexts.revokeServiceRequest.title')}
          open={true}
          saveButtonTitle={t('modalTexts.revokeServiceRequest.actionTitle')}
          handleClose={() => setOpenCancelModalId(undefined)}
          disabled={!isValid}
          onClickAccept={handleSubmit(handleDecline)}
        >
          <ModalBody>
            <FormMultiLocaleField
              fieldName={'title'}
              label={t('modalTexts.revokeServiceRequest.reasonLabel')}
              placeholder={t(
                'modalTexts.revokeServiceRequest.reasonPlaceholder',
              )}
              errors={errors}
              control={control}
            />
          </ModalBody>
        </Modal>
      )}
      {selectedService && (
        <ConfirmDialog
          onClickClose={handleClickCloseComplaint}
          open={isOpen}
          title={t('modalTexts.complaint.title', {
            name: getLocaleValue(selectedService.title),
          })}
        >
          <CreateComplaint
            relationId={selectedService.id}
            complaintAboutType={ComplaintAboutType.BUSINESS_SERVICE}
            additionAction={handleClickCloseComplaint}
          />
        </ConfirmDialog>
      )}
    </>
  );
};

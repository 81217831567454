import React, { useState } from 'react';
import { SearchTextFieldInput } from 'components/shared/inputs/SearchInput';
import { styled, SxProps, Theme } from '@mui/system';
import {
  GetServicePageFilters,
  GetServicePageClosedOptions,
} from 'interfaces/Marketplace';
import { GuideIcon } from 'components/elements/guideIcon';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 8px',
  backgroundColor: theme.palette.background.paper,
}));

const FilterButton = styled('button')({
  marginRight: 40,
  padding: 8,
  border: 'none',
  fontFamily: 'inherit',
  fontWeight: '400',
  fontSize: 14,
  color: '#4b4b4b',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',

  '&:disabled': {
    borderRadius: 4,
    backgroundColor: '#f5f5f5',
  },
});

const SearchContainer = styled('div')({
  marginLeft: 'auto',
  width: 300,
  display: 'flex',
});

type Filters = Pick<GetServicePageFilters, 'closed' | 'text'>;

type Props = {
  initial?: Filters;
  sx?: SxProps<Theme>;
  onChange?: (filters: Filters) => void;
};

export const GetServiceHeader = ({
  initial = {},
  sx = [],
  onChange,
}: Props) => {
  const [query, setQuery] = useState<string>('');

  const [status, setStatus] = useState<GetServicePageFilters['closed']>(
    initial?.closed,
  );

  const handleQueryChange = (value: string) => {
    onChange?.({ closed: status, text: value });
    setQuery(value);
  };

  const handleStatusChange =
    (value?: GetServicePageFilters['closed']) => () => {
      onChange?.({ closed: value, text: query });
      setStatus(value);
    };

  return (
    <Container sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <FilterButton
        disabled={status === GetServicePageClosedOptions.all}
        onClick={handleStatusChange(GetServicePageClosedOptions.all)}
      >
        Все
      </FilterButton>
      <FilterButton
        disabled={status === GetServicePageClosedOptions.open}
        onClick={handleStatusChange(GetServicePageClosedOptions.open)}
      >
        Открытые
      </FilterButton>
      <FilterButton
        disabled={status === GetServicePageClosedOptions.closed}
        onClick={handleStatusChange(GetServicePageClosedOptions.closed)}
      >
        Закрытые
      </FilterButton>
      <SearchContainer>
        <SearchTextFieldInput
          defaultValue={initial.text ?? ''}
          onChangeSearch={handleQueryChange}
        />
        <GuideIcon withContainer />
      </SearchContainer>
    </Container>
  );
};

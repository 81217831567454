// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Highlight from 'highlight.js';
import { Quill } from 'react-quill';
import { debounce } from 'lodash';

const CodeToken = Quill.import('formats/token');
const CodeBlock = Quill.import('formats/code-block');

class SyntaxCodeBlock extends CodeBlock {
  replaceWith(block) {
    this.domNode.textContent = this.domNode.textContent;
    this.attach();
    super.replaceWith(block);
  }

  highlight(highlight, languages) {
    const text = this.domNode.textContent;

    if (this.cachedText === text) return;

    const hasCachedText = this.cachedText != null;
    const hasText = text.trim().length > 0;

    if (hasText || !hasCachedText) {
      this.domNode.innerHTML = highlight(text, languages);
      this.domNode.normalize();
      this.attach();
    }

    this.cachedText = text;
  }
}

SyntaxCodeBlock.className = 'ql-syntax';

class Syntax {
  static register() {
    Quill.register(SyntaxCodeBlock, true);
    Quill.register(CodeToken, true);
  }

  constructor(private quill, private options) {
    const debounced = debounce(() => this.highlight(), options.interval);
    this.quill.on(Quill.events.SCROLL_OPTIMIZE, debounced);
    this.highlight();
  }

  highlight() {
    if (this.quill.selection.composing) return;

    this.quill.update(Quill.sources.USER);
    const range = this.quill.getSelection();

    this.quill.scroll.descendants(SyntaxCodeBlock).forEach((code) => {
      code.highlight(this.options.highlight);
    });

    this.quill.update(Quill.sources.SILENT);

    if (range != null) this.quill.setSelection(range, Quill.sources.SILENT);
  }
}

const defaultHighlight = (code, languages) => {
  return Highlight.highlightAuto(code, languages).value;
};

const defaultInterval = 1000;

const defaultLanguages = [
  '1c',
  'arduino',
  'c',
  'cpp',
  'csharp',
  'dart',
  'erlang',
  'go',
  'java',
  'javascript',
  'kotlin',
  'objectivec',
  'php',
  'r',
  'ruby',
  'scala',
  'swift',
  'typescript',
];

Syntax.DEFAULTS = {
  highlight: defaultHighlight,
  interval: defaultInterval,
  languages: defaultLanguages,
};

export { SyntaxCodeBlock, Syntax };

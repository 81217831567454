import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from 'date-fns';

export const getFormattedDate = (date: number) => {
  const currentDate = new Date();
  const hours = differenceInHours(currentDate, date);
  const minutes = differenceInMinutes(currentDate, date);
  const seconds = differenceInSeconds(currentDate, date);
  if (hours < 24) {
    if (hours < 1) {
      if (minutes < 1) {
        if (seconds < 0) return '1c.';
        return seconds + ' c.';
      }
      return minutes + ' м.';
    }
    return hours + ' ч.';
  }
  return format(date, 'dd.MM.yyyy');
};

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const Container = styled(Grid)({
  background: '#FFFFFF',
  padding: 35,
});

export const Title = styled('div')({
  color: '#353840',
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '28px',
  paddingBottom: 16,
});

export const NotificationSettingTitle = styled('div')({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '20px',
  textAlign: 'center',
});

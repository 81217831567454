import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { ReactComponent as LabelBookIcon } from 'assets/logos/Logo.svg';
import { Info } from 'components/features/auth/shared/info';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 50,
  background: theme.palette.background.default,
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
}));

const Card = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
}));

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: 23,
  lineHeight: '40px',
  color: '#59606A',
});

const Footer = styled('div')({
  textAlign: 'center',
  color: '#666666',
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '15px',
  paddingBottom: 6,
});

type Props = {
  title: string;
  children: React.ReactNode;
};

export const AuthLayout = ({ title, children }: Props) => {
  return (
    <Container>
      <LabelBookIcon style={{ width: '200px' }} />
      <Info />
      <Card>
        <Grid container alignItems={'center'} direction={'column'}>
          <Grid item xs={12}>
            <Title>{title}</Title>
          </Grid>
          <Grid item xs={12} style={{ width: '100%', zIndex: 1 }}>
            {children}
          </Grid>
        </Grid>
      </Card>
      <Footer>
        <div>©EQUIRON LLC.</div>
        <div>
          LabelCoding® является зарегистрированным товарным знаком EQUIRON LLC.
        </div>
      </Footer>
    </Container>
  );
};

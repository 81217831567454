import { IBusinessAttachment } from './Attachment';
import { IMultiLanguage } from './Locale';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { IBusiness, IPublishedBusinessCard } from './Business';
import { ReactionDictionary } from './Post';
import { IPublishedSoftwareCard, ISoftwareCardShort } from './Software';
import { IPerson } from './News';

export enum QuestionStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}
export interface Question {
  id: string;
  attachments?: IBusinessAttachment[];
  content: IMultiLanguage;
  title: IMultiLanguage;
  status: QuestionStatus;
  createdDateTime: number;
  answersCount: number;
  hasUserAnswer: boolean;
  receiverBusinessCard?: IPublishedBusinessCard;
  receiverSoftwareCard?: IPublishedSoftwareCard;
  reactions?: ReactionDictionary;
  businessCard: {
    id: string;
    name: IMultiLanguage;
    logo?: IBusinessAttachment;
  };
  creator: IPerson;
  viewsCounter: {
    uniqueUsers: number;
    viewedByCurrentUser: boolean;
  };
  softwareCards?: ISoftwareCardShort[];
}

export interface Answer {
  id: string;
  accepted: boolean;
  attachments?: IBusinessAttachment[];
  content: IMultiLanguage;
  createdDateTime: number;
  updateDateTime: number;
  canProcessByCurrentUser: boolean;
  reactionCount: number;
  hasCurrentUserReaction: boolean;
  hasUserAnswer: boolean;
  businessCard: IPublishedBusinessCard;
  reactions?: ReactionDictionary;
  creator: IPerson;
  softwareCards?: ISoftwareCardShort[];
}
export interface BaseQuestion {
  topic: IMultiLanguage;
  content: IMultiLanguage;
  isCommentAllowed: boolean;
  attachments: IBusinessAttachment[];
}

export interface CreateQuestion extends BaseQuestion {
  receiverBusinessCardId?: IBusiness;
  receiverSoftwareCardId?: IDictionariesContent;
  categoryId: IDictionariesContent;
  businessCardId: IDictionariesContent;
  subcategoryId: IDictionariesContent;
  softwareCardIds: IDictionariesContent[];
  programmingLanguages: IDictionariesContent[];
  applicationAreas: IDictionariesContent[];
}

export interface CreateQuestionApi extends BaseQuestion {
  receiverBusinessCardId?: string;
  receiverSoftwareCardId?: string;
  categoryId: string;
  businessCardId: string;
  subcategoryId: string;
  softwareCardIds: string[];
  programmingLanguages: string[];
  applicationAreas: string[];
}

export interface BaseAnswer {
  questionId: Question['id'];
  content: IMultiLanguage;
  attachments: IBusinessAttachment[];
}

export interface CreateAnswer extends BaseAnswer {
  businessCardId: IDictionariesContent;
  softwareCardIds: IDictionariesContent[];
}
export interface CreateAnswerApi extends BaseAnswer {
  businessCardId: string;
  softwareCardIds: string[];
}

export interface EditAnswerApi {
  id: string;
  content: IMultiLanguage;
  attachments: IBusinessAttachment[];
}

export interface QuestionSubscription {
  categoryId: string;
  subcategoryId?: string;
  code: string;
  value: IMultiLanguage;
  subscriptionId?: string;
  selectable: boolean;
  parent?: string;
  children?: QuestionSubscription[];
}

export interface CreateQuestionSubscription {
  categoryId: string;
  subcategoryId: string;
}

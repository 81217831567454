import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { ButtonBase, styled, SxProps, Theme } from '@mui/material';

const Button = styled(ButtonBase)({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '8px 12px',
  borderRadius: 14,
  backgroundColor: '#f5f5f5',
  fontWeight: 600,
  fontSize: 14,
  color: '#00618e',

  '&:active': {
    backgroundColor: '#e9f8ff',
  },

  '& svg': {
    marginRight: 10,
  },
});

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
};

export const BackButton = ({ className, sx = [], onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      className={className}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      disableRipple
      onClick={onClick}
    >
      <ArrowBackIcon color="inherit" />
      {t('back')}
    </Button>
  );
};

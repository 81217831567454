import React, { useState } from 'react';
import { styled, SxProps, Theme } from '@mui/system';
import { Comment } from 'components/features/marketplace/components/comments/Comment';
import { IComment } from 'interfaces/Comment';
import { CreateCommentForm } from 'components/features/marketplace/components/comments/CreateCommentForm';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { useModalActions } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { IUser } from 'interfaces/Users';

const Root = styled('div')();

const Header = styled('p')({
  margin: 0,
  padding: '12px 8px',
  fontSize: '14px',
  fontWeight: '500',
  color: '#000',
});

type Props = {
  cardId: IComment['businessCard']['id'];
  comments?: IComment[];
  relationId: IComment['relationId'];
  sx?: SxProps<Theme>;
  onLike?: (id: IComment['id']) => void;
  onDislike?: (id: IComment['id']) => void;
  currentUser: IUser | null;
  disabled?: boolean;
};

export const Comments = ({
  cardId,
  comments = [],
  relationId,
  sx = [],
  onLike,
  onDislike,
  currentUser,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const { isOpen, onClickOpen, onClickClose } = useModalActions();
  const [selectedComment, setSelectedComment] = useState<IComment | null>(null);
  const onClickCreateComplaint = (comment: IComment) => {
    setSelectedComment(comment);
    onClickOpen();
  };
  const onClickCloseComplaint = () => {
    setSelectedComment(null);
    onClickClose();
  };
  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Header sx={{ mb: 1 }}>{`${t('comments')}: ${comments.length}`}</Header>
      {!disabled && (
        <CreateCommentForm
          sx={{ p: 0 }}
          cardId={cardId}
          relationId={relationId}
        />
      )}
      {comments.map((comment, index) => (
        <Comment
          sx={[
            { border: '1px solid #e4e4e4', mb: 2 },
            index === 0 && { mt: 4 },
            index === comments.length - 1 && { mb: 0 },
          ]}
          key={comment.id}
          comment={comment}
          mine={
            comment.canProcessByCurrentUser &&
            currentUser?.id === comment.creator.id
          }
          onLike={onLike}
          onDislike={onDislike}
          onCreateComplaint={
            currentUser?.id !== comment.creator.id
              ? () => onClickCreateComplaint(comment)
              : undefined
          }
        />
      ))}
      {selectedComment && (
        <ConfirmDialog
          onClickClose={onClickCloseComplaint}
          open={isOpen}
          title={t('modalTexts.complaint.title', {
            name: selectedComment.creator.name,
          })}
        >
          <CreateComplaint
            relationId={selectedComment.id}
            complaintAboutType={ComplaintAboutType.COMMENT}
            additionAction={onClickCloseComplaint}
          />
        </ConfirmDialog>
      )}
    </Root>
  );
};

import React from 'react';
import { IService } from 'interfaces/Marketplace';
import { styled, SxProps, Theme } from '@mui/system';
import { BusinessCardHeader } from '../BusinessCardHeader';
import { Grid } from '@mui/material';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { useTranslation } from 'react-i18next';
import { Editor } from 'components/shared/editor';
import { getLocaleValue } from 'utils/form';

const Root = styled('div')({
  padding: '24px 32px',
});

const StatusHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 32,
});

const Type = styled('span')({
  fontWeight: '700',
  fontSize: 14,
  color: '#3d3d3d',
});

const Title = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '700',
  fontSize: 16,
  color: '#00618e',
});

const Label = styled('span')({
  fontWeight: '400',
  fontSize: 16,
  color: '#a9a9a9',
  whiteSpace: 'nowrap',
});

const Info = styled('span')({
  fontWeight: '400',
  fontSize: 16,
  color: '#000',
});

type Props = {
  sx?: SxProps<Theme>;
  service: IService;
  type: string;
};

export const ServiceDetailedCard = ({ sx = [], service, type }: Props) => {
  const { t } = useTranslation();

  const { businessCard, businessService } = service;

  const { attachments, updateDateTime, description, name, serviceType } =
    businessService;

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <StatusHeader sx={{ mb: 2 }}>
        <Type>{type}</Type>
      </StatusHeader>
      <BusinessCardHeader
        sx={{ mb: 3 }}
        src={businessCard?.logo?.path}
        name={businessCard?.name?.ru || businessCard?.name?.en || ''}
        created={updateDateTime}
        id={businessCard?.id}
      />
      <Grid sx={{ mb: 1.5 }} flexDirection="column" spacing={0.5} container>
        <Grid sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }} item>
          <Label>{t('marketplace.serviceType')}:</Label>
          <Info>{getLocaleValue(serviceType.display)}</Info>
        </Grid>
      </Grid>
      <Title sx={{ mb: 1.5 }}>{name.ru || name.en}</Title>
      <Editor value={description.ru || description.en} readOnly />
      {!!attachments?.length && <Thumbs attachments={attachments} />}
    </Root>
  );
};

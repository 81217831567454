import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { IUserActivityStats } from 'interfaces/Stats';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';
import { StatsTable } from 'components/elements/table';
import {
  createData,
  createHeadCell,
} from 'components/features/stats/item/user/tables/activity/table/utils';

export const ActivityStats = ({
  activity,
}: {
  activity: IUserActivityStats[];
}) => {
  const { t } = useTranslation();

  return (
    <StatsCollapseLayout title={t('tables.tableUserActivity')}>
      <Grid item container>
        <StatsTable data={createData(activity)} columns={createHeadCell()} />
      </Grid>
    </StatsCollapseLayout>
  );
};

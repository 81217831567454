import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {
  Container,
  Divider,
  SubTitle,
} from 'components/features/business/shared/item/components';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { Loader } from 'components/shared/loader';
import { PAGE_SIZE } from 'interfaces/BusinessFilters';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Field } from 'components/elements/viewFields';
import { initialSoftwareReviewFilters } from 'store/features/software/config';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import {
  selectSoftwareLoading,
  selectSoftwareCardReviewFilters,
  selectSoftwareCardReview,
  changeReviewsFilters,
} from 'store/features/software';
import { getSoftwareCardReview } from 'store/features/software/actions';
import { ReviewsSoftware } from 'components/features/business/shared/item/view/reviewsData/components/ReviewsSoftware';
import { ISoftware } from 'interfaces/Software';
import { getCurrent } from 'store/features/users';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { CreateSoftwareReview } from 'components/features/business/shared/item/components/Feedback';
import { useQuery } from 'hooks/useQuery';
import { useSearchParams } from 'react-router-dom';

type Props = {
  softwareCard: ISoftware | null;
  onClickCancel: () => void;
  onClickBack: () => void;
};

export const SoftwareReviewsData = ({
  softwareCard,
  onClickCancel,
  onClickBack,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getCurrent);
  const isLoading = useAppSelector(selectSoftwareLoading);
  const filters = useAppSelector(selectSoftwareCardReviewFilters);
  const reviews = useAppSelector(selectSoftwareCardReview);
  const hasMore = reviews.length % PAGE_SIZE === 0;
  const { addReview } = useQuery();
  const loadNext = () => {
    dispatch(
      changeReviewsFilters({ ...filters, offset: filters.offset + PAGE_SIZE }),
    );
  };
  useEffect(() => {
    !!softwareCard?.id &&
      dispatch(
        getSoftwareCardReview({ ...filters, softwareCardId: softwareCard.id }),
      );
  }, [softwareCard?.id, dispatch, filters]);

  useEffect(() => {
    return () => {
      dispatch(changeReviewsFilters(initialSoftwareReviewFilters));
    };
  }, []);

  const viewByAuthor = softwareCard?.creatorId === user?.id;

  const { isOpen, onClickOpen, onClickClose } = useModalActions(_, addReview);
  const [searchParams, setSearchParams] = useSearchParams();
  const onClickCloseReview = () => {
    searchParams.delete('addReview');
    setSearchParams(searchParams);
    onClickClose();
  };

  return (
    <Container container spacing={4}>
      {!viewByAuthor && (
        <Grid
          item
          container
          xs={12}
          justifyContent={'flex-end'}
          marginBottom={2}
        >
          <CreateButton
            text={t('modalTexts.feedback.actionTitle')}
            withPlusIcon
            onClick={onClickOpen}
            style={{ width: 222 }}
          />
        </Grid>
      )}
      <Divider />
      {!!softwareCard?.reviewCount && (
        <>
          <Grid item container xs style={{ paddingTop: 0 }}>
            <Field>{`${t('rating')}: ${softwareCard.rate || ''} (${
              softwareCard.reviewCount
            })`}</Field>
          </Grid>
          <Divider />
        </>
      )}
      {!reviews.length && (
        <Grid
          item
          container
          xs
          style={{ paddingTop: 0 }}
          alignContent={'center'}
        >
          <SubTitle> {t('no reviews')}</SubTitle>
        </Grid>
      )}
      <Grid item container xs style={{ paddingTop: 0 }}>
        <div style={{ height: '100%', overflowY: 'scroll', width: '100%' }}>
          <InfiniteScroll
            dataLength={reviews.length}
            next={loadNext}
            hasMore={hasMore}
            loader={<Loader show={isLoading} global />}
            scrollableTarget={SCROLLABLE_REFERENCE_ID}
          >
            {reviews.map((el) => (
              <ReviewsSoftware key={el.id} review={el} />
            ))}
          </InfiniteScroll>
        </div>
      </Grid>
      <Divider />
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            text={t('actions.back')}
            onClick={onClickBack}
          />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            onClick={onClickCancel}
            text={t('actions.close')}
          />
        </Grid>
      </Grid>
      {softwareCard && (
        <ConfirmDialog
          onClickClose={onClickCloseReview}
          open={isOpen}
          title={t('modalTexts.software.title')}
        >
          <CreateSoftwareReview
            softwareCard={softwareCard}
            additionAction={onClickCloseReview}
          />
        </ConfirmDialog>
      )}
    </Container>
  );
};

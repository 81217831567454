import { IFieldError } from 'interfaces/APIResponseError';
import { IAuthCredentials } from 'interfaces/Auth';

export enum ActionTypes {
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  RESTORE = 'RESTORE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  LOGOUT = 'LOGOUT',
}

export interface IAuthState {
  credentials: IAuthCredentials;
  fieldErrors: IFieldError[];
}

export const initialAuthState: IAuthState = {
  credentials: {
    id: '',
    password: '',
    rememberMe: false,
  },
  fieldErrors: [],
};

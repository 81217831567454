import React, { useState } from 'react';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import {
  styled,
  SxProps,
  Theme,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import {
  IServiceRequest,
  ServiceRequestStatus,
  ServiceRequestStatusMap,
} from 'interfaces/Marketplace';
import { BusinessCardHeader } from '../BusinessCardHeader';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { ReactComponent as AnswerIcon } from 'assets/icons/answer.svg';
import { ReactComponent as ViewIcon } from 'assets/icons/viewIcon.svg';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ReactionType } from 'interfaces/Post';
import { Editor } from 'components/shared/editor';
import { getLocaleValue } from 'utils/form';
import { enumeration } from 'utils/strings';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'defenitions/routes';

const Root = styled('div')({
  position: 'relative',
  border: '1px solid #ddd',
  padding: '16px 12px',
  backgroundColor: '#fff',
});

const ContentContainer = styled('div')({
  paddingLeft: 72,
});

const Title = styled('h6')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 16,
  color: '#00618e',
});

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const Menu = styled(MuiMenu)({
  '& .MuiMenu-list': {
    padding: 0,
  },
});

const MenuItem = styled(MuiMenuItem)({
  minWidth: 200,
  display: 'flex',
  justifyContent: 'center',
  padding: '8px 16px',
  fontFamily: 'inherit',
  fontWeight: '600',
  fontSize: 14,
  color: '#004b7c',
});

const Subheader = styled(Typography)({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '16px',
  color: '#a9a9a9',
});

const SoftwareCardsBox = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  color: theme.palette.text.primary,
}));

const SoftwareCard = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  color: '#00618e',
  textDecoration: 'none',
}) as typeof Typography;

type MenuOptions = Array<{ label: string; onClick?: () => void }>;

const Status = styled('span')<{ published?: boolean }>(
  ({ published = false }) => ({
    fontWeight: '700',
    fontSize: 18,
    color: published ? '#165209' : '#707070',
    marginRight: 50,
  }),
);

type Props = {
  menu?: MenuOptions;
  request: IServiceRequest;
  showStatus?: boolean;
  showAnswers?: boolean;
  sx?: SxProps<Theme>;
  onLike?: (id: IServiceRequest['id']) => void;
  onDislike?: (id: IServiceRequest['id']) => void;
  onComment?: (id: IServiceRequest['id']) => void;
  onAnswer?: () => void;
};

export const ServiceRequestCard = ({
  menu = [],
  request,
  showStatus = true,
  showAnswers = false,
  sx = [],
  onLike,
  onDislike,
  onComment,
  onAnswer,
}: Props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const {
    answersCount = 0,
    businessCard,
    commentsCount,
    content,
    creator,
    createdDateTime,
    hasCurrentUserComment,
    hasUserAnswer = false,
    receiverBusinessCard,
    title,
    status,
    viewsCounter,
    reactions,
    softwareCards,
  } = request;

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const receiverName =
    receiverBusinessCard?.name?.ru || receiverBusinessCard?.name?.en;

  const serviceTypes = enumeration(
    request.serviceTypes.map((serviceType) =>
      getLocaleValue(serviceType.display),
    ),
    t('marketplace.serviceType'),
  );

  const handleLike = () => {
    onLike?.(request.id);
  };

  const handleDislike = () => {
    onDislike?.(request.id);
  };

  const handleComment = () => {
    onComment?.(request.id);
  };

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => setAnchorEl(null);

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <BusinessCardHeader
        sx={{ mb: 2 }}
        avatarSx={{ height: 60, width: 60 }}
        id={businessCard?.id}
        src={businessCard?.logo?.path}
        name={businessCard?.name?.ru || businessCard?.name?.en || ''}
        user={`${creator.name} ${creator.lastName}, #${creator.reputation}`}
        created={createdDateTime}
        receiver={receiverName}
        status={
          showStatus && (
            <Status published={status === ServiceRequestStatus.PUBLISHED}>
              {ServiceRequestStatusMap[status]}
            </Status>
          )
        }
      />
      <ContentContainer>
        {!!softwareCards?.length && (
          <SoftwareCardsBox sx={{ mb: 1 }}>
            {'ПО: '}
            {softwareCards.map((card, index) => (
              <>
                <SoftwareCard
                  key={card.id}
                  component={Link}
                  to={generatePath(URLS.viewSoftwareCard, { id: card.id })}
                >
                  {getLocaleValue(card.name)}
                </SoftwareCard>
                {index === softwareCards.length - 1 ? '' : ', '}
              </>
            ))}
          </SoftwareCardsBox>
        )}
        <Title sx={{ mb: 0.5 }}>
          {truncate(title.ru || title.en, { length: 100, separator: ' ' })}
        </Title>
        <Subheader sx={{ mb: 0.5 }}>{serviceTypes}</Subheader>
        <Box sx={{ mb: 2 }}>
          <Editor
            value={truncate(content.ru || content.en, {
              length: 250,
              separator: ' ',
            })}
            readOnly
          />
        </Box>
        <Actions>
          <Box sx={{ flexBasis: '150px' }}>
            <InteractiveButton
              variant="stroke"
              IconComponent={ViewIcon}
              count={viewsCounter.uniqueUsers}
              label={t('views')}
              active={viewsCounter.viewedByCurrentUser}
            />
          </Box>
          {!!onLike && (
            <Box sx={{ flexBasis: '100px' }}>
              <InteractiveButton
                IconComponent={LikeIcon}
                count={likes}
                active={hasUserLikes}
                onClick={handleLike}
              />
            </Box>
          )}
          {!!onDislike && (
            <Box sx={{ flexBasis: '100px' }}>
              <InteractiveButton
                IconComponent={DislikeIcon}
                count={dislikes}
                active={hasUserDislikes}
                onClick={handleDislike}
              />
            </Box>
          )}
          {!!onComment && (
            <Box sx={{ flexBasis: '150px' }}>
              <InteractiveButton
                IconComponent={AnswerIcon}
                count={commentsCount}
                label={t('comments')}
                active={hasCurrentUserComment}
                onClick={handleComment}
              />
            </Box>
          )}
          {showAnswers && (
            <Box sx={{ flexBasis: '150px' }}>
              <InteractiveButton
                IconComponent={AnswerIcon}
                count={answersCount}
                label={t('marketplace.responds')}
                active={hasUserAnswer}
                onClick={onAnswer}
              />
            </Box>
          )}
        </Actions>
      </ContentContainer>
      <IconButton
        sx={{ position: 'absolute', top: 10, right: 12 }}
        onClick={handleOpenMore}
      >
        <MoreIcon sx={{ color: '#707070' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseMore}
      >
        {menu.map(({ label, onClick }, index) => (
          <MenuItem key={index} divider onClick={onClick}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Root>
  );
};

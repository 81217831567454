import React from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { SearchMatchType } from 'interfaces/Search';

const ItemButton = styled(ListItemButton)({
  '&.Mui-selected': {
    backgroundColor: '#f5f5f5',
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const Text = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  minWidth: 0,
  fontWeight: 600,
  fontSize: 14,
  color: theme.palette.common.black,
}));

type Props = {
  sx?: SxProps<Theme>;
  types?: SearchMatchType[];
  onChange?: (types: SearchMatchType[]) => void;
};

export const SectionsFilters = ({ sx = [], types = [], onChange }: Props) => {
  const isSelected = (value: SearchMatchType[]) => {
    return value.some((v) => types.includes(v));
  };

  const handleChange = (value: SearchMatchType[]) => () => {
    onChange?.(value);
  };

  const handleReset = () => {
    onChange?.([]);
  };

  return (
    <List sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <ListItem disablePadding>
        <ItemButton selected={!types.length} onClick={handleReset}>
          <Text>Все</Text>
        </ItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ItemButton
          selected={isSelected([
            SearchMatchType.BUSINESS_CARD,
            SearchMatchType.BUSINESS_SERVICE,
          ])}
          onClick={handleChange([
            SearchMatchType.BUSINESS_CARD,
            SearchMatchType.BUSINESS_SERVICE,
          ])}
        >
          <Text>Бизнес</Text>
        </ItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ItemButton
          selected={isSelected([
            SearchMatchType.OFFER,
            SearchMatchType.OFFER_ANSWER,
          ])}
          onClick={handleChange([
            SearchMatchType.OFFER,
            SearchMatchType.OFFER_ANSWER,
          ])}
        >
          <Text>Маркетплейс</Text>
        </ItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ItemButton
          selected={isSelected([SearchMatchType.NEWS])}
          onClick={handleChange([SearchMatchType.NEWS])}
        >
          <Text>Новости</Text>
        </ItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ItemButton
          selected={isSelected([
            SearchMatchType.QUESTION,
            SearchMatchType.ANSWER,
          ])}
          onClick={handleChange([
            SearchMatchType.QUESTION,
            SearchMatchType.ANSWER,
          ])}
        >
          <Text>Вопросы-ответы</Text>
        </ItemButton>
      </ListItem>
    </List>
  );
};

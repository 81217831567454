import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getComplaint,
  getComplaintLoading,
  getSameComplaintsList,
  getSameComplaintsLoading,
} from 'store/features/complaints';
import {
  declineComplaint,
  getComplaintById,
  getComplaintsByRelationId,
  resolveComplaint,
} from 'store/features/complaints/actions';
import {
  Complaint,
  ComplaintObject,
  ModerationLayout,
  SameComplaint,
} from '../components';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { ComplaintStatus } from 'interfaces/Complaint';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { BackButton } from 'components/shared/buttons/BackButton';

const Root = styled('div')(({ theme }) => ({
  borderRadius: 4,
  border: '1px solid #dddddd',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}));

const Header = styled('h2')({
  position: 'relative',
  margin: 0,
  padding: '24px 32px',
  fontWeight: '600',
  fontSize: 22,
  textAlign: 'center',
  color: '#565a65',
});

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 48,
});

const SameComplaints = styled('div')({});

const SameComplaintsTitle = styled('p')({
  margin: 0,
  padding: '8px 16px',
  fontWeight: '700',
  fontSize: 14,
  textAlign: 'center',
  color: '#4b4b4b',
});

export const ModerationComplaint = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const complaint = useAppSelector(getComplaint);
  const isLoading = useAppSelector(getComplaintLoading);

  const sameComplaints = useAppSelector(getSameComplaintsList);
  const isSameComplaintsLoading = useAppSelector(getSameComplaintsLoading);

  const same = sameComplaints.filter((item) => item.id !== complaint?.id);

  const handleBack = () => {
    navigate(-1);
  };

  const handleReject = (id: string) => () => {
    dispatch(declineComplaint(id));
  };

  const handleContentDelete = (id: string) => () => {
    dispatch(resolveComplaint(id));
  };

  const handleSameComplaintClick = (id: string) => {
    navigate(`/moderation/complaints/${id}`);
  };

  const handleFollowObjectLink = (url: string) => {
    navigate(url);
  };

  useEffect(() => {
    id && dispatch(getComplaintById(id));
  }, [dispatch, id]);

  useEffect(() => {
    complaint?.relationId &&
      dispatch(getComplaintsByRelationId(complaint.relationId));
  }, [complaint?.relationId, dispatch]);

  return (
    <ModerationLayout
      containerSx={{ bgcolor: theme.palette.background.paper }}
      bottomSideMenu={
        !isSameComplaintsLoading &&
        !!same.length && (
          <SameComplaints sx={{ mt: 4, borderTop: '1px solid #dddddd' }}>
            <SameComplaintsTitle sx={{ mb: 2 }}>
              {`${t('moderation.complaint.sameComplaints')} (${same.length})`}
            </SameComplaintsTitle>
            {same.map((item) => (
              <SameComplaint
                sx={{ borderBottom: '1px solid #dddddd' }}
                key={item.id}
                complaint={item}
                onClick={handleSameComplaintClick}
              />
            ))}
          </SameComplaints>
        )
      }
    >
      <Root>
        {!isLoading && !!complaint && (
          <>
            <Header sx={{ mb: 2, borderBottom: '1px solid #dddddd' }}>
              {t('moderation.complaint.header')}
              <BackButton
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: 24,
                  transform: 'translateY(-50%)',
                }}
                onClick={handleBack}
              />
            </Header>

            <Complaint sx={{ mb: 2 }} complaint={complaint} />

            <ComplaintObject
              sx={{ borderBottom: '1px solid #dddddd' }}
              complaint={complaint}
              onFollowLink={handleFollowObjectLink}
            />

            {complaint.status === ComplaintStatus.ACTIVE && (
              <Actions sx={{ mt: 5 }}>
                <CreateButton
                  sx={{ width: 200 }}
                  text={t('actions.rejectComplaint')}
                  onClick={handleReject(complaint.id)}
                />
                <ActionButton
                  sx={{ width: 200 }}
                  text={t('actions.deleteContent')}
                  onClick={handleContentDelete(complaint.id)}
                />
              </Actions>
            )}
          </>
        )}
      </Root>
    </ModerationLayout>
  );
};

import React, { ReactNode } from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { SideMenu } from './SideMenu';

const Root = styled('div')({
  display: 'flex',
  minHeight: '100%',
  backgroundColor: '#f0f2f5',
});

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  padding: '20px 37px',
});

type Props = {
  sx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  bottomSideMenu?: ReactNode;
  children?: ReactNode;
  defaultSearchValue?: string;
  autoFocusSearch?: boolean;
  onSearch?: (text: string) => void;
};

export const SearchLayout = ({
  sx = [],
  containerSx = [],
  bottomSideMenu,
  children,
  defaultSearchValue,
  autoFocusSearch,
  onSearch,
}: Props) => {
  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <SideMenu
        sx={{ flex: '0 0 auto' }}
        bottomSideMenu={bottomSideMenu}
        defaultSearchValue={defaultSearchValue}
        autoFocusSearch={autoFocusSearch}
        onSearch={onSearch}
      />
      <Container
        sx={[...(Array.isArray(containerSx) ? containerSx : [containerSx])]}
      >
        {children}
      </Container>
    </Root>
  );
};

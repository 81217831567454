import { IMultiLanguage } from 'interfaces/Locale';

export enum Dictionaries {
  cardType = 'cardType',
  serviceType = 'serviceType',
  questionCategories = 'questionCategory',
  softwareType = 'softwareType',
  applicationArea = 'applicationArea',
  programmingLanguage = 'programmingLanguage',
  technologyStack = 'technologyStack',
}

export interface IDictionaries {
  disabled: boolean;
  id: string;
  parent?: string;
  text: { en: string; ru: string };
  children?: IDictionaries[];
  childDirectory?: string;
}

export interface IDictionariesContentChild {
  code: string;
  display: IMultiLanguage;
  parent: string;
  selectable: boolean;
}

export interface IDictionariesContent {
  code: string;
  display: IMultiLanguage;
  parent?: string;
  selectable?: boolean;
  children?: IDictionariesContentChild[];
}

export interface IDictionariesTypesContent {
  concepts: IDictionariesContent[];
  createdDateTime: number;
  id: string;
  modifiedDateTime: number;
  name: string;
  publicationStatus: string;
  title: string;
}

export interface IBusinessServiceType {
  code: string;
  display: IMultiLanguage;
  description: IMultiLanguage;
  parent: string;
  children: string[];
  selectable: boolean;
  id: string;
}

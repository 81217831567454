import React from 'react';
import { styled } from '@mui/system';
import { ReactComponent as BusinessLogo } from 'assets/icons/business.svg';
import { ReactComponent as MyBusinessLogo } from 'assets/icons/myBusinessIcon.svg';
import { MenuItemLink } from 'components/elements/navigationMenu/menuItem';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { URLS } from 'defenitions/routes';
import { MENU_WIDTH } from 'services/theme';
import { CollapseLayout } from 'components/elements/collapse';
import { BusinessCardCategories } from 'components/elements/navigationMenu/businessCardCategories';
import { ServiceCategories } from 'components/elements/navigationMenu/serviceCategories';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { changeFilters } from 'store/features/business';
import { initialBusinessFilters } from 'store/features/business/config';
import { useTranslation } from 'react-i18next';
import { getAccount } from 'store/features/users';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { MenuAd } from 'components/elements/ads';

const Container = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: '20px 13px',
  borderRight: '1px solid #dddddd',
  width: MENU_WIDTH,
  background: theme.palette.background.paper,
}));

const Divider = styled('div')({
  borderBottom: '1px solid #dddddd',
});

const Title = styled('div')({
  margin: 0,
  padding: '0 24px',
  fontWeight: '700',
  fontSize: 18,
  color: '#3d3d3d',
});

export const NavigationMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const account = useAppSelector(getAccount);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const handleCloseCreate = () => {
    setAnchorEl(null);
  };
  const handleClickCreateMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const onClickInitialFilters = () =>
    dispatch(changeFilters(initialBusinessFilters));

  const showFilters = pathname === URLS.business;
  return (
    <Container>
      <Title>Бизнес</Title>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <MenuItemLink
        link={URLS.business}
        logo={<MyBusinessLogo />}
        title={t('business cards')}
        onClick={onClickInitialFilters}
      />
      <MenuItemLink
        link={URLS.software}
        logo={<BusinessLogo />}
        title={t('software cards')}
        onClick={onClickInitialFilters}
      />
      <CreateButton
        fullWidth
        withPlusIcon
        style={{ marginTop: 18 }}
        text={'Создать'}
        onClick={handleClickCreateMenu}
      />
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      {showFilters && (
        <>
          <CollapseLayout
            style={{ paddingLeft: 24 }}
            title={'Категории карточек бизнеса'}
          >
            <BusinessCardCategories />
          </CollapseLayout>
          <CollapseLayout
            style={{ paddingLeft: 24, marginTop: 18 }}
            title={'Категории услуг'}
          >
            <ServiceCategories />
          </CollapseLayout>
        </>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseCreate}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: 234,
            transform: 'translateX(-3px)',
          },
        }}
      >
        {!account?.businessCardPublished && (
          <MenuItem onClick={() => navigate(URLS.createBusinessCard)}>
            {t('create business')}
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate(URLS.createSoftware)}>
          {t('create software')}
        </MenuItem>
      </Menu>
      <MenuAd />
    </Container>
  );
};

import React from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Search } from './Search';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 16,
  padding: '16px 32px',
  backgroundColor: theme.palette.background.paper,
}));

const Header = styled('h2')({
  margin: 0,
  padding: 0,
  fontWeight: '500',
  fontSize: 18,
  color: '#00618e',
});

type Props = {
  sx?: SxProps<Theme>;
  defaultValue?: string | undefined | null;
  onTextChange?: (text: string) => void;
};

export const ContentsHeader = (props: Props) => {
  const { t } = useTranslation();
  const { sx = [], defaultValue, onTextChange } = props;

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Header>{t('moderation.contents.title')}</Header>
      <Search
        sx={{ width: 300 }}
        defaultValue={defaultValue}
        onTextChange={onTextChange}
      />
    </Root>
  );
};

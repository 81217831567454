import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { INotificationsFilters } from 'interfaces/Notifications';

export const getNotificationsEndpoint = (
  filters: INotificationsFilters,
): Promise<AxiosResponse> => {
  return apiClient.get('/notifications', {
    params: {
      ...filters,
    },
  });
};

export const getUnreadNotificationsCountEndpoint =
  (): Promise<AxiosResponse> => {
    return apiClient.get('/notifications/unread/count');
  };

export const getNotificationEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`/notifications/${id}`);
};

import React, { useEffect } from 'react';
import { useForm, SubmitHandler, Controller, useWatch } from 'react-hook-form';
import { ISignUpForm, signupFormFields } from 'interfaces/Auth';
import { TextFieldInput } from 'components/shared/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/system';
import { URLS } from 'defenitions/routes';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { Checkbox } from 'components/shared/checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from 'store';
import { signup } from 'store/features/auth/actions';
import { signupValidationSchema } from 'components/features/auth/containers/signup/form/signupValidationSchema';
import {
  TextFieldContainer,
  ButtonContainer,
  RememberContainer,
  CreateContainer,
  SecondaryText,
  HelpSinginText,
  HelpText,
} from 'components/features/auth/shared';
import { SelectTextFields } from 'components/shared/selects';
import { Countries, UserTypes } from 'defenitions/main';
import { useNavigate } from 'react-router-dom';
import { setFromError } from 'utils/form';
import { getFieldErrors } from 'store/features/auth';
import Grid from '@mui/material/Grid';
import { AxiosResponse } from 'axios';
import { authService } from 'services/AuthService';
import { useTranslation } from 'react-i18next';

const FromContainer = styled(Grid)({
  padding: '36px 50px 0px',
});

export const SignUpForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fieldErrors = useAppSelector(getFieldErrors);

  const {
    trigger,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
    setError,
  } = useForm<ISignUpForm>({
    resolver: yupResolver(signupValidationSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      country: '',
      email: '',
      userProfile: {
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        lastName: '',
      },
      accept: false,
    },
  });

  const onSubmit: SubmitHandler<ISignUpForm> = async (data) => {
    const res = await dispatch(
      signup({ ...data, email: data.userProfile.email }),
    );
    const response = res.payload as AxiosResponse;
    if (response.status === 200) {
      authService.signIn(JSON.parse(response.config.data).email);
      navigate(URLS.successSingup);
    }
  };

  const type = useWatch({ name: 'type', control });

  useEffect(() => {
    setFromError<ISignUpForm>(
      Object.values(signupFormFields),
      fieldErrors,
      setError,
    );
  }, [fieldErrors, setError]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    trigger('name');
  }, [trigger, type]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FromContainer
        container
        xs={12}
        spacing={3}
        direction={'row'}
        justifyContent={'center'}
      >
        <Grid item container xs={6} direction={'column'}>
          <TextFieldContainer>
            <Controller
              name="userProfile.name"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <TextFieldInput
                  {...rest}
                  inputRef={ref}
                  label={'Имя'}
                  errorMessage={errors.userProfile?.name?.message}
                />
              )}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <Controller
              name="userProfile.lastName"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <TextFieldInput
                  {...rest}
                  inputRef={ref}
                  label={'Фамилия'}
                  errorMessage={errors.userProfile?.lastName?.message}
                />
              )}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <Controller
              name="userProfile.email"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <TextFieldInput
                  {...rest}
                  inputRef={ref}
                  label={'Электронная почта'}
                  errorMessage={errors.userProfile?.email?.message}
                />
              )}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <Controller
              name="country"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <SelectTextFields
                  {...rest}
                  options={Countries}
                  inputRef={ref}
                  label={'Страна или регион'}
                  errorMessage={errors.country?.message}
                />
              )}
            />
          </TextFieldContainer>
        </Grid>
        <Grid item container xs={6} direction={'column'}>
          <TextFieldContainer>
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <SelectTextFields
                  {...rest}
                  options={UserTypes()}
                  inputRef={ref}
                  label={t('userType')}
                  errorMessage={errors.type?.message}
                />
              )}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <TextFieldInput
                  {...rest}
                  inputRef={ref}
                  label={'Наименование организации'}
                  errorMessage={errors.name?.message}
                />
              )}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <Controller
              name="userProfile.password"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <TextFieldInput
                  {...rest}
                  inputRef={ref}
                  type={'password'}
                  label={'Пароль'}
                  errorMessage={errors.userProfile?.password?.message}
                />
              )}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <Controller
              name="userProfile.confirmPassword"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <TextFieldInput
                  {...rest}
                  inputRef={ref}
                  type={'password'}
                  label={'Подтвердите пароль'}
                  errorMessage={errors.userProfile?.confirmPassword?.message}
                />
              )}
            />
          </TextFieldContainer>
          <HelpText>
            Пароль должен содеражать не менее 8-ми знаков, включая буквы в
            верхнем и нижнем регистре, цифры и символы
          </HelpText>
        </Grid>
        <Grid item container xs={6} alignItems={'center'} direction={'column'}>
          <RememberContainer style={{ width: 305 }}>
            <Controller
              name="accept"
              control={control}
              defaultValue={false}
              render={({ field: { ref, ...rest } }) => (
                <Checkbox
                  {...rest}
                  error={errors.accept?.message}
                  inputRef={ref}
                  label={
                    <>
                      Я подтверждаю, что принимаю{' '}
                      <HelpSinginText
                        href={URLS.termsOfService}
                        target={'_blank'}
                      >
                        пользовательское соглашение
                      </HelpSinginText>
                    </>
                  }
                />
              )}
            />
          </RememberContainer>
          <ButtonContainer style={{ width: 350 }}>
            <ActionButton
              fullWidth
              disabled={!isDirty || !isValid || isSubmitting}
              text={'Создать'}
              type={'submit'}
            />
          </ButtonContainer>
          <CreateContainer>
            <SecondaryText>Есть аккаунт?</SecondaryText>
            <HelpSinginText href={URLS.signin}>Войти</HelpSinginText>
          </CreateContainer>
        </Grid>
      </FromContainer>
    </form>
  );
};

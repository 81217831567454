import React, { useRef } from 'react';
import { BusinessLayout } from 'components/features/business/shared/layout';
import { TabLayout } from 'components/elements/tabs';
import { useSearchParams } from 'react-router-dom';
import { findKey } from 'lodash';
import {
  SearchRef,
  SearchTextFieldInput,
} from 'components/shared/inputs/SearchInput';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BusinessList } from 'components/features/business/shared/list/BusinessList';
import { useAppSelector, useAppDispatch } from 'store';
import {
  changeFilters,
  getBusinessFilters,
  getBusinessList,
  getBusinessListLoading,
} from 'store/features/business';
import { FooterAd } from 'components/elements/ads';
import { GuideIcon } from 'components/elements/guideIcon';

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const SearchContainer = styled('div')({
  position: 'absolute',
  right: 20,
  top: 15,
  width: 300,
  transform: 'translateY(50%)',
  display: 'flex',
});

enum TabsValue {
  All = 'All',
  My = 'My',
}

const TabsMap = {
  [TabsValue.My]: 0,
  [TabsValue.All]: 1,
} as const;

export const Business = () => {
  const [searchParams, setSearchParams] = useSearchParams<{ tab: TabsValue }>({
    tab: TabsValue.My,
  });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const businessFilters = useAppSelector(getBusinessFilters);
  const businessCards = useAppSelector(getBusinessList);
  const isBusinessCardsLoading = useAppSelector(getBusinessListLoading);

  const searchRef = useRef<SearchRef>(null);

  const currentTab: TabsValue =
    (searchParams.get('tab') as TabsValue) ?? TabsValue.My;

  const handleTabsChange = (_: unknown, value: number) => {
    searchRef.current?.clear();
    dispatch(changeFilters({ ...businessFilters, offset: 0 }));
    const name = findKey(TabsMap, (item) => item === value) as TabsValue;
    setSearchParams({ tab: name });
  };

  const handleChangeSearch = (value: string) => {
    dispatch(
      changeFilters({ ...businessFilters, text: value || null, offset: 0 }),
    );
  };

  return (
    <BusinessLayout
      contentSx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container>
        <TabLayout
          sx={{ pr: '340px' }}
          tab={TabsMap[currentTab]}
          tabs={[
            {
              label: t('my business card'),
              component: (
                <>
                  <BusinessList
                    key={`${TabsMap[currentTab]}`}
                    isAll={currentTab === TabsValue.All}
                    filters={businessFilters}
                    businessCards={businessCards}
                    isBusinessCardsLoading={isBusinessCardsLoading}
                  />
                </>
              ),
            },
            {
              label: t('view all'),
              component: (
                <>
                  <BusinessList
                    key={`${TabsMap[currentTab]}`}
                    isAll={currentTab === TabsValue.All}
                    filters={businessFilters}
                    businessCards={businessCards}
                    isBusinessCardsLoading={isBusinessCardsLoading}
                  />
                </>
              ),
            },
          ]}
          onChangeTabs={handleTabsChange}
        />
        <SearchContainer>
          <SearchTextFieldInput
            ref={searchRef}
            defaultValue={businessFilters.text ?? ''}
            onChangeSearch={handleChangeSearch}
          />
          <GuideIcon withContainer />
        </SearchContainer>
      </Container>
      <FooterAd />
    </BusinessLayout>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { SubscriptionTreeView } from 'components/elements/tree';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getQuestionSubscriptionsList,
  isQuestionSubscriptionsLoading,
} from 'store/features/questions';
import { Loader } from 'components/shared/loader';
import {
  createQuestionSubscriptions,
  getQuestionSubscriptions,
} from 'store/features/questions/actions';
import { CreateQuestionSubscription } from 'interfaces/Question';
import { getFlatArrayFromTree } from 'utils/tree';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { isEqual } from 'lodash';
import Snackbar from 'services/Snackbar';

const Container = styled(Grid)({
  background: '#FFFFFF',
  border: '1px solid #E4E4E4',
  paddingBottom: 16,
});

const Title = styled('div')({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  color: '#4B4B4B',
  padding: 30,
  borderBottom: '1px solid #E4E4E4',
});

export const SubscriptionsForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const subscriptions = useAppSelector(getQuestionSubscriptionsList);
  const isSubscriptionsLoading = useAppSelector(isQuestionSubscriptionsLoading);
  const [subscriptionIds, setSubscriptionIds] = useState<
    CreateQuestionSubscription[]
  >([]);
  const flatSubscriptionIds = useMemo(
    () => getFlatArrayFromTree(subscriptions),
    [subscriptions],
  );

  const onClickSaveSubscriptions = () =>
    dispatch(createQuestionSubscriptions(subscriptionIds)).then(() =>
      Snackbar.show('Подписка на вопросы обновлена', 'success'),
    );
  const onClickCancelSubscriptions = () =>
    setSubscriptionIds(flatSubscriptionIds);

  const onAddSubscriptions = (sub: CreateQuestionSubscription) =>
    setSubscriptionIds((prevState) => [...prevState, sub]);
  const onRemoveSubscription = (sub: CreateQuestionSubscription) =>
    setSubscriptionIds((prevState) =>
      prevState.filter(
        (el) =>
          !(
            el.categoryId == sub.categoryId &&
            el.subcategoryId == sub.subcategoryId
          ),
      ),
    );

  useEffect(() => {
    dispatch(getQuestionSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    setSubscriptionIds(flatSubscriptionIds);
  }, [flatSubscriptionIds, subscriptions]);

  return (
    <Container container>
      <Grid item xs={12}>
        <Title>{t('questions.subscriptionsTitle')}</Title>
      </Grid>
      {isSubscriptionsLoading && <Loader show global />}
      {subscriptions.map((subscription) => (
        <Grid item xs={12} pl={3} key={subscription.categoryId}>
          <SubscriptionTreeView
            data={subscription}
            onAddSubscriptions={onAddSubscriptions}
            onRemoveSubscription={onRemoveSubscription}
            subscriptionIds={subscriptionIds}
          />
        </Grid>
      ))}
      <Grid item container xs={12} justifyContent={'space-around'} pt={3}>
        <Grid item xs={4}>
          <CreateButton
            fullWidth
            text={t('cancel')}
            onClick={onClickCancelSubscriptions}
            disabled={
              isEqual(subscriptionIds, flatSubscriptionIds) ||
              isSubscriptionsLoading
            }
          />
        </Grid>
        <Grid item xs={4}>
          <ActionButton
            fullWidth
            text={t('save')}
            onClick={onClickSaveSubscriptions}
            disabled={
              isEqual(subscriptionIds, flatSubscriptionIds) ||
              isSubscriptionsLoading
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

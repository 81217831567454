import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { styled, SxProps, Theme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'store';
import { Grid } from '@mui/material';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { IAttachment, AttachmentType } from 'interfaces/Attachment';
import { AxiosResponse } from 'axios';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { IMultiLanguage } from 'interfaces/Locale';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCommentValidationSchema } from 'components/features/marketplace/components/comments/validationScheme';
import { getBusinessCard } from 'store/features/business/actions';
import { TextFieldInput } from 'components/shared/inputs';
import { IPublishedBusinessCard } from 'interfaces/Business';
import { ICreateComment } from 'interfaces/Comment';
import {
  createOfferComment,
  uploadMarketplaceAttachment,
} from 'store/features/marketplace/actions';
import { map } from 'lodash';
import { marketplaceCommentFileTypes } from 'defenitions/fileUploader';
import { useTranslation } from 'react-i18next';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { selectSoftwareCardAccount } from '../../../../../store/features/software';
import { getSoftwareCardAccount } from '../../../../../store/features/software/actions';

const Form = styled('form')({
  padding: '16px 24px',
});

type CreateCommentData = {
  businessCard: IPublishedBusinessCard;
  content: IMultiLanguage;
  softwareCardIds: IDictionariesContent[];
};

type Props = {
  sx?: SxProps<Theme>;
  relationId: string;
  cardId: string;
};

export const CreateCommentForm = ({ sx = [], relationId, cardId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const softwareCards = useAppSelector(selectSoftwareCardAccount);

  const {
    formState: { isValid, errors },
    control,
    reset,
    setValue,
    trigger,
    watch,
    handleSubmit,
  } = useForm<CreateCommentData>({
    mode: 'onChange',
    resolver: attachments.length
      ? undefined
      : yupResolver(createCommentValidationSchema),
  });

  const card = watch('businessCard');

  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      const attachment = { data: formData, type: AttachmentType.Comment };

      const { payload } = await dispatch(
        uploadMarketplaceAttachment(attachment),
      );

      const { data } = payload as AxiosResponse<IAttachment[]>;
      data && setAttachments(data);
    },
    [dispatch],
  );

  const handleRemoveAttachments = useCallback((id: string) => {
    setAttachments((prevState) => prevState.filter((el) => el.id !== id));
  }, []);

  const onSubmit = handleSubmit(async (formData: CreateCommentData) => {
    const data: ICreateComment = {
      attachments: map(attachments, 'id'),
      businessCardId: formData.businessCard.id,
      content: formData.content,
      relationId,
      softwareCardIds: formData.softwareCardIds.map((el) => el.code),
    };

    dispatch(createOfferComment(data));
    reset({ businessCard: card });
    setAttachments([]);
  });

  const software = softwareCards.map((s) => ({
    code: s.id,
    display: s.name,
    selectable: true,
  }));

  const handleSoftwareCardsOptions = useCallback(() => {
    dispatch(getSoftwareCardAccount());
  }, [dispatch]);

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name === 'content.en' && type === 'change') {
        trigger('content.ru');
      }
      if (name === 'content.ru' && type === 'change') {
        trigger('content.en');
      }
    });
    return () => subscription.unsubscribe();
  }, [trigger, watch]);

  useEffect(() => {
    (async () => {
      const result = await dispatch(getBusinessCard(cardId)).unwrap();
      const { data } = result as AxiosResponse<IPublishedBusinessCard>;
      setValue('businessCard', data);
    })();
  }, [cardId, dispatch, setValue]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    trigger('content');
  }, [attachments]);
  return (
    <Form sx={[...(Array.isArray(sx) ? sx : [sx])]} onSubmit={onSubmit}>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Controller
            name="businessCard"
            control={control}
            render={({ field: { value } }) => (
              <TextFieldInput
                value={value?.name.ru || value?.name.en}
                disabled
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            defaultValue={[]}
            name="softwareCardIds"
            render={({ field: { ref, value, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                value={value}
                noHierarchy
                multiple
                inputRef={ref}
                label="ПО"
                data={software}
                onLoadOptions={handleSoftwareCardsOptions}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName="content"
            isEditor
            label={t('marketplace.writeAnswer')}
            control={control}
            multiline
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <FilesUploader
            accept={marketplaceCommentFileTypes}
            attachments={attachments}
            variant="small"
            maxFiles={3}
            hideTitle
            onUploadAttachments={handleUploadAttachments}
            onClickRemoveAttachments={handleRemoveAttachments}
          />
        </Grid>
        <Grid item xs={12}>
          <ActionButton
            fullWidth
            text={t('actions.comment')}
            type="submit"
            disabled={!isValid}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

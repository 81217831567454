import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { default as MuiCheckbox, CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 24,
  height: 24,
  border: '2px solid #C0C8D1',
  backgroundColor: theme.palette.background.paper,
  'input:hover ~ &': {
    borderColor: '#2F80ED',
  },
  'input:disabled ~ &': {
    background: '#EDEDED',
    border: 'none',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#2F80ED',
  border: '1px solid #2F80ED',
  '&:before': {
    display: 'block',
    width: 19,
    height: 24,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#2F80ED',
  },
});

const StyledFormControlLabel = styled(FormControlLabel)({
  color: '#59606A',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 16,
  lineHeight: '24px',
});

type Props = {
  label: string | ReactElement;
  error?: string;
};

export const Checkbox = ({ label, error, ...props }: Props & CheckboxProps) => {
  return (
    <FormControl error={!!error}>
      <FormGroup>
        <StyledFormControlLabel
          label={label}
          control={
            <MuiCheckbox
              sx={{
                '&:hover': { bgcolor: 'transparent' },
              }}
              color={error ? 'error' : 'default'}
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              inputProps={{ 'aria-label': 'Checkbox demo' }}
              {...props}
            />
          }
        />
      </FormGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

export const CustomTabs = styled(Tabs)({
  padding: '15px 20px 10px',
  background: '#FFFFFF',
  borderBottom: '1px solid #DDDDDD',
  '& button': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '29px',
    textTransform: 'initial',
  },
});

import React from 'react';
import guideIcon from 'assets/icons/guide.png';
import { Link } from 'react-router-dom';

export const GuideIcon = ({ withContainer }: { withContainer?: boolean }) => {
  return (
    <Link
      style={{
        display: 'flex',
        justifyContent: 'right',
        marginRight: withContainer ? 0 : 16,
        marginTop: withContainer ? 0 : 16,
        height: 35,
        marginLeft: withContainer ? 8 : 0,
      }}
      to="/webapi/guide"
      target="_blank"
    >
      <img src={guideIcon} alt="guide" />
    </Link>
  );
};

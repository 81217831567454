import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import { authService } from 'services/AuthService';

type Props = {
  children: JSX.Element;
};

export const PrivateRoute = ({ children }: Props) => {
  const location = useLocation();
  const { accountId } = authService;

  if (!accountId) {
    return <Navigate to={URLS.signin} state={{ from: location }} replace />;
  }
  return children;
};

import { IUser, IAccount, IUsersFilters, IDetailsUser } from 'interfaces/Users';

export enum ActionTypes {
  GET_USER_CURRENT = 'GET_USER_CURRENT',
  GET_ACCOUNT_CURRENT = 'GET_ACCOUNT_CURRENT',
  GET_USERS = 'GET_USERS',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  LOCK_USER = 'LOCK_USER',
  UNLOCK_USER = 'UNLOCK_USER',
  UPLOAD_LOGO = 'UPLOAD_LOGO',
  INVITE_USER = 'INVITE_USER',
  GET_ALL_USERS = 'GET_ALL_USERS',
  LOCK_USER_BY_SYSTEM = 'LOCK_USER_BY_SYSTEM',
  UNLOCK_USER_BY_SYSTEM = 'UNLOCK_USER_BY_SYSTEM',
  GET_ACTIVE_USERS = 'GET_ACTIVE_USERS',
  GET_USER = 'GET_USER',
}

export interface IUsersState {
  current: IUser | null;
  account: IAccount | null;
  users: {
    users: IUser[];
    loading: boolean;
    loaded: boolean;
  };
  allUsers: {
    filters: IUsersFilters;
    users: IUser[];
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
  user: {
    user: IDetailsUser | null;
    loading: boolean;
  };
}

export const initialUserFilters: IUsersFilters = {
  accountIds: [],
  userRoles: [],
  limit: 10,
  offset: 0,
  text: '',
  userStatuses: [],
};

export const initialUsersState: IUsersState = {
  current: null,
  account: null,
  users: {
    users: [],
    loaded: false,
    loading: false,
  },
  allUsers: {
    users: [],
    loaded: false,
    loading: false,
    filters: initialUserFilters,
    hasMore: false,
  },
  user: {
    user: null,
    loading: false,
  },
};

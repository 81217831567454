import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import {
  getDictionariesCardType,
  getDictionariesQuestionCategories,
  getDictionariesQuestionSubcategories,
  getDictionariesServiceType,
  getDictionariesContentCardType,
  getDictionariesContentServiceType,
  getDictionariesContentApplicationAreas,
  getDictionariesContentProgrammingLanguages,
  getDictionariesContentSoftwareTypes,
  getDictionariesContentTechnologyStack,
} from 'store/features/dictionaries/actions';
import {
  IDictionariesState,
  initialDictionariesState,
} from 'store/features/dictionaries/config';
import { RootState } from 'store/index';
import { AxiosResponse } from 'axios';
import {
  IDictionaries,
  IDictionariesTypesContent,
} from 'interfaces/Dictionaries';

const dictionariesSlice = createSlice({
  name: Slices.dictionaries,
  initialState: initialDictionariesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDictionariesCardType.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionaries[]>;
        return {
          ...state,
          cardTypes: data,
        };
      },
    );
    builder.addCase(
      getDictionariesServiceType.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionaries[]>;
        return {
          ...state,
          serviceTypes: data,
        };
      },
    );
    builder.addCase(
      getDictionariesContentCardType.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionariesTypesContent>;
        return {
          ...state,
          cardTypesContent: data.concepts,
        };
      },
    );
    builder.addCase(
      getDictionariesContentServiceType.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionariesTypesContent>;
        return {
          ...state,
          serviceTypesContent: data.concepts,
        };
      },
    );
    builder.addCase(
      getDictionariesQuestionCategories.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionaries[]>;
        return {
          ...state,
          questionCategories: data,
        };
      },
    );
    builder.addCase(
      getDictionariesQuestionSubcategories.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionaries[]>;
        return {
          ...state,
          questionSubcategories: data,
        };
      },
    );
    builder.addCase(
      getDictionariesContentSoftwareTypes.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionariesTypesContent>;
        state.softwareTypes = data.concepts;
      },
    );
    builder.addCase(
      getDictionariesContentApplicationAreas.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionariesTypesContent>;
        state.applicationAreas = data.concepts;
      },
    );
    builder.addCase(
      getDictionariesContentProgrammingLanguages.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionariesTypesContent>;
        state.programmingLanguages = data.concepts;
      },
    );
    builder.addCase(
      getDictionariesContentTechnologyStack.fulfilled,
      (state: IDictionariesState, { payload }) => {
        const { data } = payload as AxiosResponse<IDictionariesTypesContent>;
        state.technologyStack = data.concepts;
      },
    );
  },
});

export { dictionariesSlice };

export const getCardTypeDictionaries = (state: RootState) =>
  state.dictionaries.cardTypes;
export const getCardTypeDictionariesContent = (state: RootState) =>
  state.dictionaries.cardTypesContent;

export const getServiceTypeDictionaries = (state: RootState) =>
  state.dictionaries.serviceTypes;

export const getServiceTypeDictionariesContent = (state: RootState) =>
  state.dictionaries.serviceTypesContent;

export const getQuestionCategoriesDictionaries = (state: RootState) =>
  state.dictionaries.questionCategories;

export const getQuestionSubcategoriesDictionaries = (state: RootState) =>
  state.dictionaries.questionSubcategories;

export const getSoftwareTypesDictionaries = (state: RootState) =>
  state.dictionaries.softwareTypes;

export const getProgrammingLanguagesDictionaries = (state: RootState) =>
  state.dictionaries.programmingLanguages;

export const getApplicationAreasDictionaries = (state: RootState) =>
  state.dictionaries.applicationAreas;

export const getTechnologyStackDictionaries = (state: RootState) =>
  state.dictionaries.technologyStack;

import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';

const MAX_LENGTH_CONTENT = 4000;
const MAX_LENGTH_TITLE = 250;

export const serviceDataValidationSchema = yup.object({
  title: yup.object().shape(
    {
      ru: yup.string().when('en', {
        is: (en: string) => !en,
        then: yup
          .string()
          .max(MAX_LENGTH_TITLE, ErrorMessages.maxLength(MAX_LENGTH_TITLE))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
      en: yup.string().when('ru', {
        is: (ru: string) => !ru,
        then: yup
          .string()
          .max(MAX_LENGTH_TITLE, ErrorMessages.maxLength(MAX_LENGTH_TITLE)),
      }),
    },
    [
      ['en', 'ru'],
      ['ru', 'en'],
    ],
  ),
  content: yup.object().shape(
    {
      ru: yup.string().when('en', {
        is: (en: string) => !en,
        then: yup
          .string()
          .max(MAX_LENGTH_CONTENT, ErrorMessages.maxLength(MAX_LENGTH_CONTENT))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
      en: yup.string().when('ru', {
        is: (ru: string) => !ru,
        then: yup
          .string()
          .max(MAX_LENGTH_CONTENT, ErrorMessages.maxLength(MAX_LENGTH_CONTENT)),
      }),
    },
    [
      ['en', 'ru'],
      ['ru', 'en'],
    ],
  ),
  businessCardId: yup.string().required(ErrorMessages.required),
});

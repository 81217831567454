import React, { CSSProperties } from 'react';
import { styled } from '@mui/system';
import {
  AnswerStatuses,
  AnswerStatusMap,
  ServiceRequestStatusMap,
  ServiceRequestStatus,
} from 'interfaces/Marketplace';

const Text = styled('span')({
  fontWeight: '700',
  fontSize: 18,
});

type Props = {
  status: ServiceRequestStatus | AnswerStatuses;
  type: 'Request' | 'Answer';
  style?: CSSProperties;
};

const Color = {
  [ServiceRequestStatus.PUBLISHED]: '#165209',
  [ServiceRequestStatus.IN_WORK]: '#00618e',
  [ServiceRequestStatus.DRAFT]: '#707070',
  [ServiceRequestStatus.COMPLETED]: '#000000',
  [ServiceRequestStatus.CANCELLED]: '#413c3c',
  [ServiceRequestStatus.ACCEPTED]: '#00618e',
  [ServiceRequestStatus.DECLINED]: '#be845b',
  [AnswerStatuses.DRAFT]: '#707070',
  [AnswerStatuses.PUBLISHED]: '#165209',
  [AnswerStatuses.ACCEPTED]: '#00618e',
  [AnswerStatuses.DECLINED]: '#be845b',
  [AnswerStatuses.IN_WORK]: '#00618e',
  [AnswerStatuses.COMPLETED]: '#000000',
  [AnswerStatuses.CANCELLED]: '#413c3c',
};

export const Status = ({ status, type, style }: Props) => {
  const text =
    type === 'Answer'
      ? AnswerStatusMap[status]
      : ServiceRequestStatusMap[status];
  return <Text style={{ color: Color[status], ...style }}>{text}</Text>;
};

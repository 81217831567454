import React, { useState } from 'react';
import { styled, SxProps, Theme } from '@mui/material/styles';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel as MuiFormLabel,
  Popover as MuiPopover,
  RadioGroup,
  Switch as MuiSwitch,
  SwitchProps,
} from '@mui/material';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { startOfToday, subMonths, subWeeks } from 'date-fns';
import { RadioButton } from 'components/shared/radio';
import { ICompilationPageFilters } from 'interfaces/Marketplace';
import { useTranslation } from 'react-i18next';
import { SoftwareTypes } from 'components/features/marketplace/components/SoftwareTypes';
import { ApplicationAreas } from 'components/features/marketplace/components/ApplicationAreas';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 56,
  padding: '16px 40px',
  backgroundColor: '#fff',
});

const StyledButton = styled(Button)({
  borderRadius: 4,
  padding: 0,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: 12,
  color: '#000',
  textTransform: 'none',
  boxShadow: 'none',
});

const Switch = styled((props: SwitchProps) => (
  <MuiSwitch disableRipple {...props} />
))({
  height: 20,
  width: 40,
  margin: 0,
  padding: 0,

  '& .MuiSwitch-track': {
    border: '1px solid #a9a9a9',
    borderRadius: 22 / 2,
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    opacity: 1,
  },

  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 12,
    height: 12,
    margin: 4,
    backgroundColor: '#a9a9a9',
  },

  '& .MuiSwitch-switchBase': {
    padding: 0,

    '&.Mui-checked + .MuiSwitch-track': {
      border: '1px solid #00618e',
      backgroundColor: '#fff',
      opacity: 1,
    },

    '&.Mui-checked > .MuiSwitch-thumb': {
      backgroundColor: '#00618e',
    },
  },
});

const SwitchControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: 12,
    fontWeight: '500',
    color: '#393939',
  },
});

const FormLabel = styled(MuiFormLabel)({
  marginBottom: 8,
  fontWeight: '600',
  fontSize: 12,
  color: '#4b4b4b',
});

const RadioControlLabel = styled(FormControlLabel)({
  color: '#4B4B4B',
  '& span': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
  },
});

const Radio = styled(RadioButton)({
  marginRight: 12,
  padding: 0,
});

const Popover = styled(MuiPopover)({
  '& .MuiPopover-paper': {
    maxHeight: 200,
  },
});

type Filters = Pick<
  ICompilationPageFilters,
  'applicationAreas' | 'fromDate' | 'recommended' | 'softwareTypes'
>;

type Props = {
  filters?: Partial<Filters>;
  sx?: SxProps<Theme>;
  onChange?: (filters: Filters) => void;
};

export const SoftwareFilters = ({ sx = [], filters = {}, onChange }: Props) => {
  const { t } = useTranslation();
  const fromDate = filters.fromDate ?? null;
  const recommended = filters.recommended ?? false;
  const softwareTypes = filters.softwareTypes ?? [];
  const applicationAreas = filters.applicationAreas ?? [];

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleRecommendedChange = (_: unknown, checked: boolean) => {
    onChange?.({
      fromDate,
      recommended: checked,
      softwareTypes,
      applicationAreas,
    });
  };

  const handleFromDateChange = (_: unknown, value: string) => {
    onChange?.({
      recommended,
      fromDate: value ? parseInt(value) : null,
      softwareTypes,
      applicationAreas,
    });
  };

  const handleSoftwareTypesChange = (value: string[]) => {
    onChange?.({
      recommended,
      fromDate,
      softwareTypes: value,
      applicationAreas,
    });
  };

  const handleApplicationAreasChange = (value: string[]) => {
    onChange?.({
      recommended,
      fromDate,
      softwareTypes,
      applicationAreas: value,
    });
  };

  const today = startOfToday();

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <StyledButton onClick={handlePopoverOpen}>
        <FilterIcon style={{ marginRight: 4 }} />
        Фильтр
      </StyledButton>

      <SwitchControlLabel
        control={
          <Switch
            sx={{ m: 1 }}
            checked={recommended}
            onChange={handleRecommendedChange}
          />
        }
        label={t('filters.recommended')}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <FormControl sx={{ padding: '16px' }}>
          <FormLabel>{t('filters.period.title')}</FormLabel>
          <FormGroup>
            <RadioGroup
              value={fromDate ? `${fromDate}` : ''}
              onChange={handleFromDateChange}
            >
              <RadioControlLabel
                labelPlacement="end"
                value={''}
                control={<Radio />}
                label={t('filters.period.all')}
              />
              <RadioControlLabel
                labelPlacement="end"
                value={`${today.getTime()}`}
                control={<Radio />}
                label={t('filters.period.today')}
              />
              <RadioControlLabel
                labelPlacement="end"
                value={`${subWeeks(today, 1).getTime()}`}
                control={<Radio />}
                label={t('filters.period.week')}
              />
              <RadioControlLabel
                labelPlacement="end"
                value={`${subMonths(today, 1).getTime()}`}
                control={<Radio />}
                label={t('filters.period.month')}
              />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <FormControl sx={{ padding: '16px' }}>
          <FormLabel>{t('filters.applicationAreas')}</FormLabel>
          <FormGroup>
            <ApplicationAreas
              areas={applicationAreas}
              onChange={handleApplicationAreasChange}
            />
          </FormGroup>
        </FormControl>
        <FormControl sx={{ padding: '16px' }}>
          <FormLabel>{t('filters.softwareTypes')}</FormLabel>
          <FormGroup>
            <SoftwareTypes
              software={softwareTypes}
              onChange={handleSoftwareTypesChange}
            />
          </FormGroup>
        </FormControl>
      </Popover>
    </Root>
  );
};

import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { IOffersStats } from 'interfaces/Stats';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';
import { StatsTable } from 'components/elements/table';
import {
  createHeadCellOffers,
  createDataOffers,
  createDataAnswers,
  createHeadCellAnswers,
} from 'components/features/stats/item/business/tables/offers/table/utils';

export const OffersStats = ({ offers }: { offers: IOffersStats }) => {
  const { t } = useTranslation();

  return (
    <StatsCollapseLayout title={t('tables.tableOffers')}>
      <Grid item container>
        <StatsTable
          title={t('tables.tableOffersOutgoingOffers')}
          data={createDataOffers(offers.outgoingOffers)}
          columns={createHeadCellOffers(true, true)}
        />
      </Grid>
      <Grid item container>
        <StatsTable
          title={t('tables.tableOffersIncomingOffers')}
          data={createDataOffers(offers.incomingOffers)}
          columns={createHeadCellOffers(true)}
        />
        <StatsTable
          title={t('tables.tableOffersOutgoingAnswers')}
          data={createDataAnswers(offers.outgoingAnswers)}
          columns={createHeadCellAnswers()}
        />
        <StatsTable
          title={t('tables.tableOffersIncomingAnswers')}
          data={createDataAnswers(offers.incomingAnswers)}
          columns={createHeadCellAnswers(true, true)}
        />
      </Grid>
    </StatsCollapseLayout>
  );
};

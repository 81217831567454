import React from 'react';
import { TextFieldInput } from 'components/shared/inputs';
import MenuItem from '@mui/material/MenuItem';
import { IBook } from 'interfaces/Books';
import { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  errorMessage?: string;
  options: IBook[];
  createLink?: string;
};

const LinkValue = styled('div')({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '24px',
  color: '#004B7C',
  overflowWrap: 'break-word',
});
export const SelectTextFields = ({
  label,
  options,
  createLink,
  ...rest
}: Props & TextFieldProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <TextFieldInput {...rest} select label={label}>
      {!!options.length &&
        options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      {!options.length && createLink && (
        <MenuItem onClick={() => navigate(createLink)}>
          <LinkValue>{t('create link')}</LinkValue>
        </MenuItem>
      )}
    </TextFieldInput>
  );
};

import { IAttachment } from 'interfaces/Attachment';
import { IUsersStats } from 'interfaces/Stats';

export enum Types {
  PERSON = 'PERSON',
  ORGANIZATION = 'ORGANIZATION',
}

export enum Roles {
  OWNER = 'OWNER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  OPERATOR = 'OPERATOR',
}

export enum Status {
  CONFIRMING = 'CONFIRMING',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
}

export interface IUser {
  agreementSigned: boolean;
  created: number;
  id: string;
  lastName: string;
  name: string;
  role: Roles;
  settings: object;
  updated: number;
  logo?: IAttachment;
  lastLoggedIn: number;
  status?: Status;
  confirmed?: boolean;
  accountId?: string;
  account?: {
    id: string;
    name: string;
  };
  reputation?: number;
}

export interface IAccount {
  id: string;
  name: string;
  country: string;
  email: string;
  created: number;
  updated: number;
  lastLoggedIn: number;
  owner: IUser;
  isSystemAccount: boolean;
  type: Types;
  publishedBusinessCardId?: string;
  businessCardPublished?: boolean;
}

export interface ICreateUser {
  name: string;
  lastName: string;
  email: string;
  role: Roles;
  logoId?: string;
}

export interface IUsersFilters {
  accountIds: string[];
  userStatuses: Status[];
  userRoles: Roles[];
  text: string;
  offset: number;
  limit: number;
  businessCardIds?: string[];
}

export interface IDetailsUser {
  user: IUser;
  statistics: IUsersStats;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionHandler } from 'store/utils/actionHandler';
import { ActionTypes } from 'store/features/stats/config';
import {
  getBusinessStatsEndpoint,
  getUsersStatsEndpoint,
  getUserStatsEndpoint,
  getBusinessDetailedStatsEndpoint,
  getAllBusinessCardsEndpoint,
  getAllUsersEndpoint,
} from 'store/features/stats/api';
import { IStatsFilters, IStatsItemFilters } from 'interfaces/Stats';

export const getBusinessStats = createAsyncThunk(
  ActionTypes.GET_BUSINESS_STATS,
  async (filters: IStatsFilters, { rejectWithValue }) =>
    actionHandler(() => getBusinessStatsEndpoint(filters), rejectWithValue),
);

export const getUsersStats = createAsyncThunk(
  ActionTypes.GET_USERS_STATS,
  async (filters: IStatsFilters, { rejectWithValue }) =>
    actionHandler(() => getUsersStatsEndpoint(filters), rejectWithValue),
);

export const getUserStats = createAsyncThunk(
  ActionTypes.GET_USER_STATS,
  async (
    { id, filters }: { id: string; filters?: IStatsItemFilters },
    { rejectWithValue },
  ) => actionHandler(() => getUserStatsEndpoint(id, filters), rejectWithValue),
);

export const getBusinessDetailedStats = createAsyncThunk(
  ActionTypes.GET_BUSINESS_DETAILED_STATS,
  async (
    { id, filters }: { id: string; filters?: IStatsItemFilters },
    { rejectWithValue },
  ) =>
    actionHandler(
      () => getBusinessDetailedStatsEndpoint(id, filters),
      rejectWithValue,
    ),
);

export const getAllBusinessCards = createAsyncThunk(
  ActionTypes.GET_ALL_BUSINESS_CARDS,
  async (_, { rejectWithValue }) =>
    actionHandler(() => getAllBusinessCardsEndpoint(), rejectWithValue),
);

export const getAllUsers = createAsyncThunk(
  ActionTypes.GET_ALL_USERS,
  async (_, { rejectWithValue }) =>
    actionHandler(() => getAllUsersEndpoint(), rejectWithValue),
);

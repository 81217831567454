import React, { ReactNode } from 'react';
import { Container } from 'components/features/home/components';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'store';
import {
  selectBusinessStats,
  selectBusinessStatsLoading,
} from 'store/features/stats';
import { Loader } from 'components/shared/loader';
import { useTranslation } from 'react-i18next';
import { StatsTable } from 'components/elements/table';
import {
  createData,
  createHeadCell,
} from 'components/features/stats/list/business/table/utils';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';

type Props = {
  filters?: ReactNode;
  query?: string;
};

export const BusinessStats = ({ filters, query }: Props) => {
  const { t } = useTranslation();
  const businessStats = useAppSelector(selectBusinessStats);
  const isBusinessStatsLoading = useAppSelector(selectBusinessStatsLoading);

  return (
    <Container item container mt={3}>
      <StatsCollapseLayout title={t('tables.tableBusinessName')}>
        <Grid item container>
          {filters && (
            <Grid item xs={12}>
              {filters}
            </Grid>
          )}
          {isBusinessStatsLoading && <Loader show global />}
          <StatsTable
            url={'business'}
            query={query}
            data={createData(businessStats)}
            columns={createHeadCell()}
          />
        </Grid>
      </StatsCollapseLayout>
    </Container>
  );
};

import React from 'react';
import Grid from '@mui/material/Grid';
import { Divider } from 'components/features/business/shared/item/components';
import { LinkValue, Value, Title, Field } from 'components/elements/viewFields';
import { SxProps, Theme } from '@mui/system';
import { Editor } from 'components/shared/editor';

type Props = {
  title: string;
  fields: {
    field: string;
    value?: string | null;
    isHref?: boolean;
    isEmail?: boolean;
    isEditor?: boolean;
  }[];
  fieldTitleXs?: number;
  sx?: SxProps<Theme>;
};

export const ViewField = ({ title, fields, sx, fieldTitleXs }: Props) => {
  return (
    <Grid item container height={'min-content'} sx={sx}>
      <Grid item xs={12}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Title>{title}</Title>
          <Divider />
        </div>
      </Grid>
      {fields.map((el) => {
        return (
          el.value && (
            <Grid container key={el.field} marginLeft={2}>
              <Grid
                item
                xs={fieldTitleXs ? fieldTitleXs : 6}
                alignSelf={'center'}
              >
                <Field>{el.field}</Field>
              </Grid>
              <Grid
                item
                xs={fieldTitleXs ? 12 - fieldTitleXs : 6}
                alignSelf={'center'}
              >
                {el.isHref || el.isEmail ? (
                  <LinkValue
                    href={el.isEmail ? `mailto:${el.value}` : el.value}
                  >
                    {el.value}
                  </LinkValue>
                ) : (
                  <Value>
                    {el.isEditor && el.value ? (
                      <Editor value={el.value} readOnly />
                    ) : (
                      el?.value
                    )}
                  </Value>
                )}
              </Grid>
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

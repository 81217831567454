import {
  CreateQuestionSubscription,
  QuestionSubscription,
} from 'interfaces/Question';
import {
  INotificationSettingCreateItem,
  INotificationSettingsItem,
} from 'interfaces/Notifications';

const flatten = (
  destArray: CreateQuestionSubscription[],
  nodeList: QuestionSubscription[],
) => {
  return nodeList.forEach((node) => {
    node.subscriptionId &&
      destArray.push({
        categoryId: node.categoryId,
        subcategoryId: `${node.subcategoryId}`,
      });
    flatten(destArray, node.children || []);
  });
};

export const getFlatArrayFromTree = (subscriptions: QuestionSubscription[]) => {
  const dest: CreateQuestionSubscription[] = [];
  flatten(dest, subscriptions);
  return dest;
};

export const normalizeCreateNotificationSettings = (
  data: INotificationSettingsItem[],
): INotificationSettingCreateItem[] => {
  const items: INotificationSettingCreateItem[] = [];
  data.forEach((el) =>
    el.children?.forEach((child) =>
      items.push({ code: child.code, setting: child.setting }),
    ),
  );
  return items;
};

export const normalizeNotificationSettings = (
  data: INotificationSettingsItem[],
): INotificationSettingsItem[] => {
  return data.map((el) => {
    return {
      ...el,
      children: el.children?.map((child) => {
        return {
          ...child,
          setting: {
            ...child.setting,
            LK: child.setting.LK || false,
            EMAIL: child.setting.EMAIL || false,
          },
        };
      }),
    };
  });
};

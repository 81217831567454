import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, SxProps, Theme } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
  show?: boolean;
  global?: boolean;
};
const StyledCircularProgress = styled(CircularProgress)<{ global?: boolean }>(
  ({ global }) => ({
    position: global ? 'fixed' : 'relative',
    top: global ? '50%' : undefined,
    left: global ? '50%' : undefined,
    zIndex: 1,
  }),
);
export const Loader = ({ sx = [], show, global }: Props) => {
  return show ? (
    <StyledCircularProgress
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      global={global}
    />
  ) : (
    <></>
  );
};

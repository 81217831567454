import React from 'react';
import i18n from 'i18next';
import { HeadCell } from 'components/elements/table/TableHead';
import { IUsersStats } from 'interfaces/Stats';
import { DataItem } from 'components/elements/table';
import { ViewRole } from 'components/elements/viewFields/Role';

export const createHeadCell = (): HeadCell[] => {
  return [
    { id: 'user', label: i18n.t('user'), numeric: false },
    {
      id: 'role',
      label: i18n.t('tables.role'),
      numeric: false,
    },
    {
      id: 'status',
      label: i18n.t('tables.status'),
      numeric: false,
    },
    {
      id: 'rate',
      label: i18n.t('tables.rate'),
      numeric: true,
    },
    {
      id: 'complaints',
      label: i18n.t('tables.complaints'),
      numeric: true,
    },
    {
      id: 'blocks',
      label: i18n.t('tables.blocks'),
      numeric: true,
    },
    {
      id: 'offers',
      label: i18n.t('tables.offers'),
      numeric: true,
    },
    /*    {
      id: 'offerAnswers',
      label: i18n.t('tables.offerAnswers'),
      numeric: true,
    },*/
    {
      id: 'questions',
      label: i18n.t('tables.questions'),
      numeric: true,
    },
    {
      id: 'answers',
      label: i18n.t('tables.answers'),
      numeric: true,
    },
    {
      id: 'news',
      label: i18n.t('tables.news'),
      numeric: true,
    },
    /*{
      id: 'comments',
      label: i18n.t('tables.comments'),
      numeric: true,
    },
    {
      id: 'reviews',
      label: i18n.t('tables.reviews'),
      numeric: true,
    },
    {
      id: 'reactions',
      label: i18n.t('tables.reactions'),
      numeric: true,
    },*/
  ];
};

export const createData = (data: IUsersStats[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.user.id,
      user: `${el.user.name} ${el.user.lastName}`,
      role: <ViewRole role={el.role}>{i18n.t(`roles.${el.role}`)}</ViewRole>,
      status: i18n.t(`statuses.${el.user.status}`),
    };
  });

import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import { signin, signup } from 'store/features/auth/actions';
import { IAuthState, initialAuthState } from 'store/features/auth/config';
import { RootState } from 'store/index';
import { IAPIResponseError } from 'interfaces/APIResponseError';

const authSlice = createSlice({
  name: Slices.signin,
  initialState: initialAuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signin.rejected, (state: IAuthState, action) => {
      const { fieldErrors } = action.payload as IAPIResponseError;
      return {
        ...state,
        fieldErrors,
      };
    });
    builder.addCase(signup.rejected, (state: IAuthState, action) => {
      const { fieldErrors } = action.payload as IAPIResponseError;
      return {
        ...state,
        fieldErrors,
      };
    });
  },
});

export { authSlice };
export const getFieldErrors = (state: RootState) => state.auth.fieldErrors;

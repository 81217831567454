import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import { RootState } from 'store/index';
import {
  IBusinessState,
  initialBusinessState,
} from 'store/features/business/config';
import {
  getAllBusinessCards,
  getBusiness,
  getBusinessCard,
  getBusinessPublished,
  getMyBusiness,
  getPublishedBusinessCards,
  subscribeBusinessCards,
  unsubscribeBusinessCards,
  getBusinessCardReviews,
  getBusinessCardByAccount,
} from 'store/features/business/actions';
import {
  IBusinessFilters,
  IBusinessReviewsFilters,
  PAGE_SIZE,
} from 'interfaces/BusinessFilters';
import { AxiosResponse } from 'axios';
import { IPublishedBusinessCard } from 'interfaces/Business';
import { ISubscription } from 'interfaces/News';
import { IReview } from 'interfaces/Review';

const businessSlice = createSlice({
  name: Slices.business,
  initialState: initialBusinessState,
  reducers: {
    changeFilters: (
      state: IBusinessState,
      { payload }: PayloadAction<IBusinessFilters>,
    ) => {
      state.list.filters = payload;
    },
    changeReviewsFilters: (
      state: IBusinessState,
      { payload }: PayloadAction<IBusinessReviewsFilters>,
    ) => {
      state.item.reviewsFilters = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusiness.pending, (state: IBusinessState) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    });
    builder.addCase(
      getBusiness.fulfilled,
      (state: IBusinessState, { payload }) => {
        const { data } = payload as AxiosResponse;
        const hasMore = data.length % PAGE_SIZE === 0;
        const newBusiness =
          state.list.filters.offset === 0
            ? data
            : [...state.list.business, ...data];
        return {
          ...state,
          list: {
            ...state.list,
            business: newBusiness,
            loading: false,
            loaded: true,
            hasMore,
          },
        };
      },
    );
    builder.addCase(getBusiness.rejected, (state: IBusinessState) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          loaded: false,
          hasMore: false,
        },
      };
    });

    builder.addCase(getMyBusiness.pending, (state: IBusinessState) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    });
    builder.addCase(
      getMyBusiness.fulfilled,
      (state: IBusinessState, { payload }) => {
        const { data } = payload as AxiosResponse;
        const hasMore = data.length % PAGE_SIZE === 0;
        const newBusiness =
          state.list.filters.offset === 0
            ? data
            : [...state.list.business, ...data];
        return {
          ...state,
          list: {
            ...state.list,
            business: newBusiness,
            loading: false,
            loaded: true,
            hasMore,
          },
        };
      },
    );
    builder.addCase(getMyBusiness.rejected, (state: IBusinessState) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          loaded: false,
          hasMore: false,
        },
      };
    });

    builder.addCase(getBusinessCard.pending, (state: IBusinessState) => {
      return {
        ...state,
        item: {
          ...state.item,
          loading: true,
        },
      };
    });
    builder.addCase(
      getBusinessCard.fulfilled,
      (state: IBusinessState, { payload }) => {
        const { data } = payload as AxiosResponse;
        return {
          ...state,
          item: {
            ...state.item,
            businessCard: data,
            loading: false,
            loaded: true,
          },
        };
      },
    );
    builder.addCase(getBusinessCard.rejected, (state: IBusinessState) => {
      return {
        ...state,
        item: {
          ...state.item,
          loading: false,
          loaded: false,
        },
      };
    });
    builder.addCase(
      getBusinessPublished.fulfilled,
      (state: IBusinessState, { payload }) => {
        const { data } = payload as AxiosResponse;
        return {
          ...state,
          publishedBusiness: data,
        };
      },
    );
    builder.addCase(
      getPublishedBusinessCards.fulfilled,
      (state: IBusinessState, { payload }) => {
        const { data } = payload as AxiosResponse<IPublishedBusinessCard[]>;
        state.publishedBusinessCards = data;
      },
    );
    builder.addCase(subscribeBusinessCards.pending, (state: IBusinessState) => {
      state.list.loading = true;
    });
    builder.addCase(
      subscribeBusinessCards.fulfilled,
      (state: IBusinessState, { payload }) => {
        const { data } = payload as AxiosResponse<ISubscription>;
        state.list.loading = false;
        state.list.business = [...state.list.business].map((el) =>
          el.id === data.businessCard.id
            ? { ...el, subscriptionId: data.id }
            : el,
        );
      },
    );
    builder.addCase(
      subscribeBusinessCards.rejected,
      (state: IBusinessState) => {
        state.list.loading = false;
      },
    );
    builder.addCase(
      unsubscribeBusinessCards.pending,
      (state: IBusinessState) => {
        state.list.loading = true;
      },
    );
    builder.addCase(
      unsubscribeBusinessCards.fulfilled,
      (state: IBusinessState, { payload }) => {
        const { data } = payload as AxiosResponse<ISubscription>;
        state.list.loading = false;
        state.list.business = [...state.list.business].map((el) =>
          el.id === data.businessCard.id ? { ...el, subscriptionId: '' } : el,
        );
      },
    );
    builder.addCase(
      unsubscribeBusinessCards.rejected,
      (state: IBusinessState) => {
        state.list.loading = false;
      },
    );
    builder.addCase(
      getAllBusinessCards.fulfilled,
      (state: IBusinessState, { payload }) => {
        const { data } = payload as AxiosResponse<IPublishedBusinessCard[]>;
        state.all = data;
      },
    );

    //reviews
    builder.addCase(getBusinessCardReviews.pending, (state: IBusinessState) => {
      state.item.loading = true;
    });
    builder.addCase(
      getBusinessCardReviews.fulfilled,
      (state: IBusinessState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<IReview[]>;
        state.item.reviews =
          offset === 0 ? data : [...state.item.reviews, ...data];
        state.item.loading = false;
      },
    );
    builder.addCase(
      getBusinessCardReviews.rejected,
      (state: IBusinessState) => {
        state.item.loading = false;
        state.item.reviews = [];
      },
    );

    builder
      .addCase(getBusinessCardByAccount.pending, (state) => {
        state.item.loading = true;
        state.item.loaded = false;
      })
      .addCase(getBusinessCardByAccount.rejected, (state) => {
        state.item.loading = false;
        state.item.loaded = false;
      })
      .addCase(getBusinessCardByAccount.fulfilled, (state, { payload }) => {
        const { data } = payload as AxiosResponse<IPublishedBusinessCard>;
        state.item.loading = false;
        state.item.loaded = true;
        state.item.businessCardByAccount = data;
      });
  },
});

export { businessSlice };
export const { changeFilters, changeReviewsFilters } = businessSlice.actions;
export const getBusinessList = (state: RootState) =>
  state.business.list.business;
export const getBusinessListLoading = (state: RootState) =>
  state.business.list.loading;
export const getBusinessFilters = (state: RootState) =>
  state.business.list.filters;
export const getBusinessHasMore = (state: RootState) =>
  state.business.list.hasMore;
export const getBusinessCardItem = (state: RootState) =>
  state.business.item.businessCard;
export const getBusinessCardLoading = (state: RootState) =>
  state.business.item.loading;
export const getBusinessPublishedList = (state: RootState) =>
  state.business.publishedBusiness;
export const getPublishedBusinessCardsList = (state: RootState) =>
  state.business.publishedBusinessCards;
export const getAllBusinessCardsList = (state: RootState) => state.business.all;
export const selectBusinessCardReviews = (state: RootState) =>
  state.business.item.reviews;
export const selectBusinessCardReviewFilters = (state: RootState) =>
  state.business.item.reviewsFilters;
export const selectAccountBusinessCard = (state: RootState) =>
  state.business.item.businessCardByAccount;

import React, { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { TextFieldInput } from 'components/shared/inputs/TextFieldInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/system';
import { URLS } from 'defenitions/routes';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { useAppDispatch } from 'store';
import { restore } from 'store/features/auth/actions';
import {
  ButtonContainer,
  CreateContainer,
  HelpSinginText,
  HelpText,
  SecondaryText,
  TextFieldContainer,
} from 'components/features/auth/shared';
import { AxiosResponse } from 'axios';
import Grid from '@mui/material/Grid';
import { restoreValidationSchema } from 'components/features/auth/containers/restore/form/restoreValidationSchema';
import Snackbar from 'services/Snackbar';
import { useNavigate } from 'react-router-dom';

const FromContainer = styled('div')({
  padding: '0px 150px',
  display: 'flex',
  flexDirection: 'column',
});

export const RestoreForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    trigger,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<{ email: string }>({
    resolver: yupResolver(restoreValidationSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<{ email: string }> = async (data) => {
    await dispatch(restore(data.email)).then(async (res) => {
      const response = res.payload as AxiosResponse;
      if (response.status === 200) {
        navigate(URLS.signin);
        Snackbar.show(
          'Вам на почту отправлена инструкция по восстановлению пароля',
          'success',
        );
      }
    });
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <HelpText style={{ padding: 30, textAlign: 'center' }}>
          На вашу почту отправлена ссылка , перейдите по ней для для активации
          учётной записи
        </HelpText>
      </Grid>
      <FromContainer>
        <TextFieldContainer>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextFieldInput
                {...rest}
                inputRef={ref}
                label={'Электронная почта'}
                errorMessage={errors.email?.message}
              />
            )}
          />
        </TextFieldContainer>
        <ButtonContainer>
          <ActionButton
            fullWidth
            disabled={!isDirty || !isValid || isSubmitting}
            text={'Отправить'}
            type={'submit'}
          />
        </ButtonContainer>
        <CreateContainer>
          <SecondaryText>Есть аккаунт?</SecondaryText>
          <HelpSinginText href={URLS.signin}>Войти</HelpSinginText>
        </CreateContainer>
      </FromContainer>
    </form>
  );
};

import React, { useEffect } from 'react';
import Rating from '@mui/material/Rating';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { useTranslation } from 'react-i18next';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { useAttachments } from 'utils/hooks';
import { useAppDispatch } from 'store';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { uploadReviewAttachments } from 'store/features/marketplace/actions';
import { styled } from '@mui/material/styles';
import {
  BusinessAvatar,
  Name,
} from 'components/features/marketplace/components/cards/ServiceRequestAnswerCard';
import { getLocaleValue } from 'utils/form';
import { ISoftware } from 'interfaces/Software';
import {
  createSoftwareReview,
  getSoftwareCard,
} from 'store/features/software/actions';
import { IMultiLanguage } from 'interfaces/Locale';
import { attachUrl } from 'services/ApiClient';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { localeValidate } from 'components/elements/fieldBuilder/validator';
import { MAX_LENGTH } from 'defenitions/errorMessages';
const MAX_LENGTH_DESCRIPTION = 2000;

const feedbackValidationSchema = yup.object({
  content: localeValidate(MAX_LENGTH_DESCRIPTION),
});

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 16,
  lineHeight: '20px',
  color: '#4B4B4B',
  paddingRight: 16,
});
const BusinessName = styled(Grid)({
  fontSize: 16,
});

type Props = {
  softwareCard: ISoftware;
  additionAction: () => void;
};

interface SoftwareCreateForm {
  content: IMultiLanguage;
  grade: number;
}
export const CreateSoftwareReview = ({
  softwareCard,
  additionAction,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { attachments, onUploadAttachments, onRemoveAttachments } =
    useAttachments(uploadReviewAttachments);

  const {
    trigger,
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<SoftwareCreateForm>({
    mode: 'onChange',
    defaultValues: {},
    resolver: attachments.length
      ? undefined
      : yupResolver(feedbackValidationSchema),
  });

  const handleSave: SubmitHandler<SoftwareCreateForm> = async (data) => {
    await dispatch(
      createSoftwareReview({
        ...data,
        attachments: attachments.map((el) => el.id),
        softwareCardReviewId: softwareCard.id,
        relationId: softwareCard.id,
      }),
    );
    await dispatch(getSoftwareCard(softwareCard.id));
    additionAction();
  };

  const avatarUrl =
    softwareCard.logo?.path && attachUrl + softwareCard.logo?.path;

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    trigger('content');
  }, [attachments, trigger]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item container>
          <BusinessName>{t('review.softwareTitle')}</BusinessName>
        </Grid>
        <Grid item container pb={2} xs={12} alignItems={'center'}>
          <BusinessAvatar src={avatarUrl} />
          <Name style={{ paddingLeft: 16 }}>
            <div>{getLocaleValue(softwareCard.name)}</div>
          </Name>
        </Grid>

        <Grid item container xs={12} alignItems={'center'}>
          <Title>{t('add review')}</Title>
          <Controller
            name={`grade`}
            control={control}
            rules={{ required: true }}
            defaultValue={1}
            render={({ field: { ref, ...rest } }) => (
              <Rating ref={ref} {...rest} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'content'}
            label={t('review.content')}
            errors={errors}
            control={control}
            multiline
            maxLength={MAX_LENGTH}
          />
        </Grid>
        <Grid item xs={12}>
          <FilesUploader
            maxFiles={10}
            onUploadAttachments={onUploadAttachments}
            attachments={attachments}
            onClickRemoveAttachments={onRemoveAttachments}
          />
        </Grid>
        <Grid item xs={12}>
          <ActionButton
            fullWidth
            disabled={!isValid}
            onClick={handleSubmit(handleSave)}
            autoFocus
            text={t('modalTexts.feedback.actionTitle')}
          />
        </Grid>
      </Grid>
    </form>
  );
};

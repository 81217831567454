import React, { useMemo } from 'react';
import { Grid, SxProps, Theme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import {
  changeBusinessFilters,
  selectAllBusinessCards,
  selectBusinessFilters,
} from 'store/features/stats';
import { DateTimeRangeFilter } from 'components/elements/filters/DateTimeRangeFilter';
import { useTranslation } from 'react-i18next';
import { CreateButton } from './CreateButton';
import {
  getAllBusinessCards,
  getBusinessStats,
} from 'store/features/stats/actions';
import { SelectFilters } from 'components/elements/filters/SelectFilters';
import { getLocaleValue } from 'utils/form';

type Props = {
  sx?: SxProps<Theme>;
};

export const BusinessFilters = ({ sx = [] }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filters = useAppSelector(selectBusinessFilters);
  const { fromDate, toDate, businessCardIds } = filters;

  const startDate = fromDate ? new Date(fromDate) : null;
  const endDate = toDate ? new Date(toDate) : null;

  const handleDateTimesChange = (start: Date | null, end: Date | null) => {
    const startTime = start ? start.getTime() : null;
    const endTime = end ? end.getTime() : null;

    dispatch(
      changeBusinessFilters({
        ...filters,
        fromDate: startTime,
        toDate: endTime,
      }),
    );
  };

  const businessCards = useAppSelector(selectAllBusinessCards);

  const businessOptions = useMemo(
    () =>
      businessCards.map(({ id, name }) => ({ id, name: getLocaleValue(name) })),
    [businessCards],
  );

  const handleBusinessFiltersLoad = () => dispatch(getAllBusinessCards());

  const handleBusinessChange = (value: string[]) => {
    dispatch(changeBusinessFilters({ ...filters, businessCardIds: value }));
  };

  const handleCreate = () => {
    dispatch(getBusinessStats(filters));
  };

  return (
    <Grid
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      container
      alignItems="center"
    >
      <Grid item>
        <DateTimeRangeFilter
          startDate={startDate}
          endDate={endDate}
          onDateTimesChange={handleDateTimesChange}
        />
      </Grid>
      <Grid item>
        <SelectFilters
          title={t('filters.stats.businessTitle')}
          placeholder={t('filters.stats.businessPlaceholder')}
          options={businessOptions}
          value={businessCardIds}
          onLoad={handleBusinessFiltersLoad}
          onChange={handleBusinessChange}
        />
      </Grid>
      <Grid item>
        <CreateButton onClick={handleCreate}>{t('create stats')}</CreateButton>
      </Grid>
    </Grid>
  );
};

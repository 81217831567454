import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { INews } from 'interfaces/News';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { Box, CircularProgress } from '@mui/material';
import { NewsCard } from 'components/features/news/components/NewsCard';
import { useLocation } from 'react-router-dom';
import { URLS } from '../../../../defenitions/routes';
import { ListAdOne, ListAdTwo } from '../../../elements/ads';

type Props = {
  currentUserId?: string;
  isSystemAccount?: boolean;
  hasMore: boolean;
  items: INews[];
  next: () => void;
  onDelete?: (id: string) => void;
  onLike?: (id: string) => void;
  onDislike?: (id: string) => void;
};

export const NewsList = ({
  currentUserId,
  isSystemAccount,
  hasMore,
  items,
  next,
  onDelete,
  onLike,
  onDislike,
}: Props) => {
  const location = useLocation();
  const isSystemNews = location.pathname === URLS.newsImportant;
  const loader = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
      }}
    >
      <CircularProgress size={32} />
    </Box>
  );

  return (
    <InfiniteScroll
      style={{ overflowY: 'hidden' }}
      dataLength={items.length}
      hasMore={hasMore}
      loader={loader}
      next={next}
      scrollableTarget={SCROLLABLE_REFERENCE_ID}
    >
      {items.map((item, index) => (
        <>
          {index % 4 === 0 && index % 8 !== 0 && index !== 0 && <ListAdOne />}
          {index % 4 === 0 && index % 8 === 0 && index !== 0 && <ListAdTwo />}
          <NewsCard
            key={item.id}
            news={item}
            currentUserId={currentUserId}
            isSystemAccount={isSystemAccount}
            onDelete={onDelete}
            onLike={onLike}
            onDislike={onDislike}
            isSystemNews={isSystemNews}
          />
        </>
      ))}
    </InfiniteScroll>
  );
};

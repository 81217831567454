import * as React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import {
  IBusinessFilters,
  SortName,
  SortType,
} from 'interfaces/BusinessFilters';
import { RadioButton } from 'components/shared/radio';

type Props = {
  filters: IBusinessFilters;
  onChangeFilters: (filters: IBusinessFilters) => void;
};

type sorFilter = {
  sort: SortName;
  order: SortType;
};

const Title = styled(FormControlLabel)({
  margin: 0,
  color: '#4B4B4B',
  '& span': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
  },
});

export const BusinessFilters = ({ filters, onChangeFilters }: Props) => {
  const [value, setValue] = React.useState(`${filters.sort}.${filters.order}`);

  const normalizeValue = (value: string): sorFilter => {
    const values = value.split('.');
    const sort = values[0] as SortName;
    const order = values[1] as SortType;
    return {
      sort,
      order,
    };
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    const sortFilters = normalizeValue(
      (event.target as HTMLInputElement).value,
    );
    onChangeFilters({ ...filters, ...sortFilters, offset: 0 });
  };

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <Title
        labelPlacement="end"
        value={`${SortName.publishedDateTime}.${SortType.ASC}`}
        control={<RadioButton />}
        label="Дата добавления: сначала новые"
      />
      <Title
        labelPlacement="end"
        value={`${SortName.publishedDateTime}.${SortType.DESC}`}
        control={<RadioButton />}
        label="Дата добавления: сначала старые"
      />
      <Title
        labelPlacement="end"
        value={`${SortName.name}.${SortType.DESC}`}
        control={<RadioButton />}
        label="Наименование: по убыванию"
      />
      <Title
        labelPlacement="end"
        value={`${SortName.name}.${SortType.ASC}`}
        control={<RadioButton />}
        label="Наименование: по возрастанию"
      />
    </RadioGroup>
  );
};

import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import { ReactComponent as ModerationReportsIcon } from 'assets/icons/moderationReports.svg';
import { ReactComponent as ModerationRatingsIcon } from 'assets/icons/moderationRatings.svg';
import { ReactComponent as ModerationContentIcon } from 'assets/icons/moderationContent.svg';
import { MenuItemLink } from 'components/elements/navigationMenu/menuItem';
import { URLS } from 'defenitions/routes';
import { MENU_WIDTH } from 'services/theme';
import { useTranslation } from 'react-i18next';

const Container = styled('aside')(({ theme }) => ({
  flexShrink: 0,
  padding: '20px 13px',
  borderRight: '1px solid #dddddd',
  width: MENU_WIDTH,
  background: theme.palette.background.paper,
}));

const Divider = styled('div')({
  borderBottom: '1px solid #dddddd',
});

const Header = styled('h3')({
  margin: 0,
  padding: '0 24px',
  fontWeight: '700',
  fontSize: 18,
  color: '#3d3d3d',
});

type Props = {
  bottomSideMenu?: ReactNode;
};

export const SideMenu = ({ bottomSideMenu }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('moderation.header')}</Header>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <MenuItemLink
        link={URLS.moderationComplaints}
        logo={<ModerationReportsIcon />}
        title={t('moderation.navigation.reports')}
      />
      <MenuItemLink
        link={URLS.moderationRatings}
        logo={<ModerationRatingsIcon />}
        title={t('moderation.navigation.ratings')}
      />
      <MenuItemLink
        link={URLS.moderationContents}
        logo={<ModerationContentIcon />}
        title={t('moderation.navigation.content')}
      />
      <Divider sx={{ mt: '18px', mb: bottomSideMenu ? '18px' : 0 }} />
      {bottomSideMenu}
    </Container>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionHandler } from 'store/utils/actionHandler';
import { ActionTypes } from 'store/features/business/config';
import {
  getBusinessCardEndpoint,
  createBusinessCardEndpoint,
  deleteBusinessCardEndpoint,
  getBusinessEndpoint,
  unpublishBusinessCardEndpoint,
  updateBusinessCardEndpoint,
  getMyBusinessEndpoint,
  uploadBusinessCardLogoEndpoint,
  uploadBusinessCardAttachmentEndpoint,
  getBusinessPublishedEndpoint,
  uploadServiceCardAttachmentEndpoint,
  getPublishedBusinessCardsEndpoint,
  subscribeBusinessCardsEndpoint,
  unsubscribeBusinessCardsEndpoint,
  getCountBusinessEndpoint,
  getAllBusinessCardsEndpoint,
  getBusinessCardReviewsEndpoint,
  getBusinessCardByAccountEndpoint,
} from 'store/features/business/api';
import { IBusinessCreate } from 'interfaces/Business';
import {
  IBusinessFilters,
  IBusinessReviewsFilters,
} from 'interfaces/BusinessFilters';

export const getBusiness = createAsyncThunk(
  ActionTypes.GET_BUSINESS,
  async (filters: IBusinessFilters, { rejectWithValue }) =>
    actionHandler(() => getBusinessEndpoint(filters), rejectWithValue),
);

export const getBusinessPublished = createAsyncThunk(
  ActionTypes.GET_BUSINESS_PUBLISHED,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getBusinessPublishedEndpoint(), rejectWithValue),
);

export const getMyBusiness = createAsyncThunk(
  ActionTypes.GET_MY_BUSINESS_CARD,
  async (filters: IBusinessFilters, { rejectWithValue }) =>
    actionHandler(() => getMyBusinessEndpoint(filters), rejectWithValue),
);

export const getBusinessCard = createAsyncThunk(
  ActionTypes.GET_BUSINESS_CARD,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => getBusinessCardEndpoint(id), rejectWithValue),
);

export const createBusinessCard = createAsyncThunk(
  ActionTypes.CREATE_BUSINESS_CARD,
  async (businessCard: IBusinessCreate, { rejectWithValue }) =>
    actionHandler(
      () => createBusinessCardEndpoint(businessCard),
      rejectWithValue,
    ),
);

export const updateBusinessCard = createAsyncThunk(
  ActionTypes.UPDATE_BUSINESS_CARD,
  async (businessCard: IBusinessCreate, { rejectWithValue }) =>
    actionHandler(
      () => updateBusinessCardEndpoint(businessCard.id, businessCard),
      rejectWithValue,
    ),
);

export const deleteBusinessCard = createAsyncThunk(
  ActionTypes.DELETE_BUSINESS_CARD,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => deleteBusinessCardEndpoint(id), rejectWithValue),
);

export const unpublishBusinessCard = createAsyncThunk(
  ActionTypes.UNPUBLISH_BUSINESS_CARD,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => unpublishBusinessCardEndpoint(id), rejectWithValue),
);

export const uploadBusinessCardLogo = createAsyncThunk(
  ActionTypes.UPLOAD_BUSINESS_CARD_LOGO,
  async (formData: FormData, { rejectWithValue }) =>
    actionHandler(
      () => uploadBusinessCardLogoEndpoint(formData),
      rejectWithValue,
    ),
);

export const uploadBusinessCardAttachments = createAsyncThunk(
  ActionTypes.UPLOAD_BUSINESS_CARD_ATTACHMENTS,
  async (formData: FormData, { rejectWithValue }) =>
    actionHandler(
      () => uploadBusinessCardAttachmentEndpoint(formData),
      rejectWithValue,
    ),
);
export const uploadServiceCardAttachments = createAsyncThunk(
  ActionTypes.UPLOAD_SERVICE_CARD_ATTACHMENTS,
  async (formData: FormData, { rejectWithValue }) =>
    actionHandler(
      () => uploadServiceCardAttachmentEndpoint(formData),
      rejectWithValue,
    ),
);

export const getPublishedBusinessCards = createAsyncThunk(
  ActionTypes.GET_PUBLISHED_BUSINESS_CARDS,
  async (_, { rejectWithValue }) =>
    actionHandler(() => getPublishedBusinessCardsEndpoint(), rejectWithValue),
);

export const subscribeBusinessCards = createAsyncThunk(
  ActionTypes.SUBSCRIBE_SERVICE_CARD,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => subscribeBusinessCardsEndpoint(id), rejectWithValue),
);

export const unsubscribeBusinessCards = createAsyncThunk(
  ActionTypes.UNSUBSCRIBE_SERVICE_CARD,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => unsubscribeBusinessCardsEndpoint(id), rejectWithValue),
);

export const getBusinessCount = createAsyncThunk(
  ActionTypes.GET_COUNT_BUSINESS,
  async (
    filters: {
      cardTypes: string[];
      services: string[];
    },
    { rejectWithValue },
  ) => actionHandler(() => getCountBusinessEndpoint(filters), rejectWithValue),
);

export const getAllBusinessCards = createAsyncThunk(
  ActionTypes.GET_ALL_BUSINESS_CARDS,
  async (_, { rejectWithValue }) =>
    actionHandler(() => getAllBusinessCardsEndpoint(), rejectWithValue),
);

export const getBusinessCardReviews = createAsyncThunk(
  ActionTypes.GET_BUSINESS_CARD_REVIEWS,
  async (filters: IBusinessReviewsFilters, { rejectWithValue }) =>
    actionHandler(
      () => getBusinessCardReviewsEndpoint(filters),
      rejectWithValue,
    ),
);

export const getBusinessCardByAccount = createAsyncThunk(
  ActionTypes.GET_BUSINESS_CARD_BY_ACCOUNT,
  async (accountId: string, { rejectWithValue }) =>
    actionHandler(
      () => getBusinessCardByAccountEndpoint(accountId),
      rejectWithValue,
    ),
);

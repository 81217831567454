import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import {
  initialSoftwareState,
  ISoftwareState,
} from 'store/features/software/config';
import {
  ISoftware,
  ISoftwareCardShort,
  ISoftwareFilters,
  ISoftwareReviewsFilters,
} from 'interfaces/Software';
import {
  createSoftwareReview,
  getMySoftware,
  getOthersAccountSoftwareCardByBusinessId as getOthersAccountSoftwareCardsByBusinessId,
  getOthersAccountSoftwareCards,
  getSoftware,
  getSoftwareCard,
  getSoftwareCardAccount,
  getSoftwareCardReview,
  hideSoftware,
  showSoftware,
} from 'store/features/software/actions';
import { AxiosResponse } from 'axios';
import { Slices } from 'store/configuration/slices';
import { IReview } from 'interfaces/Review';
import Snackbar from 'services/Snackbar';
import i18next from 'services/i18n';
import { PAGE_SIZE } from 'interfaces/BusinessFilters';

const softwareSlice = createSlice({
  name: Slices.software,
  initialState: initialSoftwareState,
  reducers: {
    changeFilters: (
      state: ISoftwareState,
      { payload }: PayloadAction<ISoftwareFilters>,
    ) => {
      state.list.filters = payload;
    },
    changeReviewsFilters: (
      state: ISoftwareState,
      { payload }: PayloadAction<ISoftwareReviewsFilters>,
    ) => {
      state.item.reviewsFilters = payload;
    },
  },
  extraReducers: (builder) => {
    //all
    builder.addCase(getSoftware.pending, (state: ISoftwareState) => {
      state.list.loading = true;
    });
    builder.addCase(
      getSoftware.fulfilled,
      (state: ISoftwareState, { payload, meta }) => {
        const { offset } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<ISoftware[]>;
        state.list.software = !offset
          ? data
          : [...state.list.software, ...data];
        state.list.loading = false;
        state.list.hasMore = !!data.length;
      },
    );
    builder.addCase(getSoftware.rejected, (state: ISoftwareState) => {
      state.list.loading = false;
      state.list.hasMore = false;
    });
    //my
    builder.addCase(getMySoftware.pending, (state: ISoftwareState) => {
      state.list.loading = true;
    });
    builder.addCase(
      getMySoftware.fulfilled,
      (state: ISoftwareState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<ISoftware[]>;
        state.list.loading = false;
        state.list.software =
          offset === 0 ? data : [...state.list.software, ...data];
        state.list.hasMore = data.length % PAGE_SIZE === 0;
      },
    );
    builder.addCase(getMySoftware.rejected, (state: ISoftwareState) => {
      state.list.loading = false;
    });
    //show
    builder.addCase(showSoftware.pending, (state: ISoftwareState) => {
      state.list.loading = true;
    });
    builder.addCase(
      showSoftware.fulfilled,
      (state: ISoftwareState, { meta }) => {
        const id = meta.arg ?? {};
        const index = state.list.software.findIndex((el) => el.id === id);
        if (index !== -1) {
          state.list.software[index].hidden = false;
        }
        state.list.loading = false;
      },
    );
    builder.addCase(showSoftware.rejected, (state: ISoftwareState) => {
      state.list.loading = false;
    });
    //hide
    builder.addCase(hideSoftware.pending, (state: ISoftwareState) => {
      state.list.loading = true;
    });
    builder.addCase(
      hideSoftware.fulfilled,
      (state: ISoftwareState, { meta }) => {
        const id = meta.arg ?? {};
        const index = state.list.software.findIndex((el) => el.id === id);
        if (index !== -1) {
          state.list.software[index].hidden = true;
        }
        state.list.loading = false;
      },
    );
    builder.addCase(hideSoftware.rejected, (state: ISoftwareState) => {
      state.list.loading = false;
    });
    //card
    builder.addCase(getSoftwareCard.pending, (state: ISoftwareState) => {
      state.item.loading = true;
    });
    builder.addCase(
      getSoftwareCard.fulfilled,
      (state: ISoftwareState, { payload }) => {
        const { data } = payload as AxiosResponse<ISoftware>;
        state.item.loading = false;
        state.item.card = data;
      },
    );
    builder.addCase(getSoftwareCard.rejected, (state: ISoftwareState) => {
      state.item.loading = false;
    });
    //reviews
    builder.addCase(getSoftwareCardReview.pending, (state: ISoftwareState) => {
      state.item.loading = true;
    });
    builder.addCase(
      getSoftwareCardReview.fulfilled,
      (state: ISoftwareState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<IReview[]>;
        state.item.reviews =
          offset === 0 ? data : [...state.item.reviews, ...data];
        state.item.loading = false;
      },
    );
    builder.addCase(getSoftwareCardReview.rejected, (state: ISoftwareState) => {
      state.item.loading = false;
      state.item.reviews = [];
    });
    //review
    builder.addCase(createSoftwareReview.fulfilled, (state, { payload }) => {
      const { data } = payload as AxiosResponse<IReview>;
      state.item.reviews = [data, ...state.item.reviews];
      Snackbar.show(i18next.t('notification.review created'), 'success');
    });
    //account card
    builder.addCase(
      getSoftwareCardAccount.fulfilled,
      (state: ISoftwareState, { payload }) => {
        const { data } = payload as AxiosResponse<ISoftwareCardShort[]>;
        state.activeSoftware = data;
      },
    );

    builder.addCase(
      getOthersAccountSoftwareCards.fulfilled,
      (state: ISoftwareState, { payload }) => {
        const { data } = payload as AxiosResponse<ISoftwareCardShort[]>;
        state.othersSoftware = data;
      },
    );
    builder.addCase(
      getOthersAccountSoftwareCardsByBusinessId.fulfilled,
      (state: ISoftwareState, { payload }) => {
        const { data } = payload as AxiosResponse<ISoftwareCardShort[]>;
        state.othersSoftwareByBusinessId = data;
      },
    );
  },
});

export { softwareSlice };
export const { changeFilters, changeReviewsFilters } = softwareSlice.actions;
const self = (state: RootState) => state.software;

export const selectSoftware = createSelector(
  self,
  (state) => state.list.software,
);

export const selectSoftwareFilters = createSelector(
  self,
  (state) => state.list.filters,
);

export const selectSoftwareHasMore = createSelector(
  self,
  (state) => state.list.hasMore,
);

export const selectSoftwareLoading = createSelector(
  self,
  (state) => state.list.loading,
);

export const selectSoftwareCardLoading = createSelector(
  self,
  (state) => state.item.loading,
);

export const selectSoftwareCard = createSelector(
  self,
  (state) => state.item.card,
);

export const selectSoftwareCardReview = createSelector(
  self,
  (state) => state.item.reviews,
);

export const selectSoftwareCardReviewFilters = createSelector(
  self,
  (state) => state.item.reviewsFilters,
);

export const selectSoftwareCardAccount = createSelector(
  self,
  (state) => state.activeSoftware,
);

export const selectOthersSoftwareCards = createSelector(
  self,
  (state) => state.othersSoftware,
);

export const selectOthersSoftwareCardsByBusinessId = createSelector(
  self,
  (state) => state.othersSoftwareByBusinessId,
);

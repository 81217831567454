import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionHandler } from 'store/utils/actionHandler';
import { ActionTypes } from 'store/features/software/config';
import {
  ICreateSoftware,
  ISoftwareFilters,
  ISoftwareReviewsFilters,
} from 'interfaces/Software';
import {
  getSoftwareEndpoint,
  getMySoftwareEndpoint,
  showSoftwareEndpoint,
  hideSoftwareEndpoint,
  getSoftwareCardEndpoint,
  getSoftwareCardReviewsEndpoint,
  createSoftwareCardEndpoint,
  updateSoftwareCardEndpoint,
  uploadSoftwareCardAttachmentEndpoint,
  uploadSoftwareCardLogoEndpoint,
  createSoftwareReviewEndpoint,
  getSoftwareCardAccountEndpoint,
  getOthersAccountSoftwareCardsEndpoint,
} from 'store/features/software/api';
import { ICreateSoftwareReview } from 'interfaces/Review';

export const getSoftware = createAsyncThunk(
  ActionTypes.GET_SOFTWARE,
  async (filters: ISoftwareFilters, { rejectWithValue }) =>
    actionHandler(() => getSoftwareEndpoint(filters), rejectWithValue),
);

export const getMySoftware = createAsyncThunk(
  ActionTypes.GET_MY_SOFTWARE,
  async (filters: ISoftwareFilters, { rejectWithValue }) =>
    actionHandler(() => getMySoftwareEndpoint(filters), rejectWithValue),
);

export const showSoftware = createAsyncThunk(
  ActionTypes.SHOW_SOFTWARE,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => showSoftwareEndpoint(id), rejectWithValue),
);

export const hideSoftware = createAsyncThunk(
  ActionTypes.HIDE_SOFTWARE,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => hideSoftwareEndpoint(id), rejectWithValue),
);

export const getSoftwareCard = createAsyncThunk(
  ActionTypes.GET_SOFTWARE_CARD,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => getSoftwareCardEndpoint(id), rejectWithValue),
);

export const getSoftwareCardReview = createAsyncThunk(
  ActionTypes.GET_SOFTWARE_REVIEW,
  async (filters: ISoftwareReviewsFilters, { rejectWithValue }) =>
    actionHandler(
      () => getSoftwareCardReviewsEndpoint(filters),
      rejectWithValue,
    ),
);

export const createSoftwareCard = createAsyncThunk(
  ActionTypes.CREATE_SOFTWARE_CARD,
  async (softwareCard: ICreateSoftware, { rejectWithValue }) =>
    actionHandler(
      () => createSoftwareCardEndpoint(softwareCard),
      rejectWithValue,
    ),
);

export const updateSoftwareCard = createAsyncThunk(
  ActionTypes.UPDATE_SOFTWARE_CARD,
  async (softwareCard: ICreateSoftware, { rejectWithValue }) =>
    actionHandler(
      () => updateSoftwareCardEndpoint(softwareCard.id, softwareCard),
      rejectWithValue,
    ),
);

export const uploadSoftwareCardLogo = createAsyncThunk(
  ActionTypes.UPLOAD_SOFTWARE_CARD_LOGO,
  async (formData: FormData, { rejectWithValue }) =>
    actionHandler(
      () => uploadSoftwareCardLogoEndpoint(formData),
      rejectWithValue,
    ),
);

export const uploadSoftwareCardAttachments = createAsyncThunk(
  ActionTypes.UPLOAD_SOFTWARE_CARD_ATTACHMENTS,
  async (formData: FormData, { rejectWithValue }) =>
    actionHandler(
      () => uploadSoftwareCardAttachmentEndpoint(formData),
      rejectWithValue,
    ),
);

export const createSoftwareReview = createAsyncThunk(
  ActionTypes.CREATE_SOFTWARE_REVIEW,
  async (review: ICreateSoftwareReview, { rejectWithValue }) =>
    actionHandler(() => createSoftwareReviewEndpoint(review), rejectWithValue),
);

export const getSoftwareCardAccount = createAsyncThunk(
  ActionTypes.GET_SOFTWARE_CARD_SHORT,
  async (_, { rejectWithValue }) =>
    actionHandler(() => getSoftwareCardAccountEndpoint(), rejectWithValue),
);

export const getOthersAccountSoftwareCards = createAsyncThunk(
  ActionTypes.GET_OTHERS_ACCOUNTS_SOFTWARE_CARDS,
  async (_, { rejectWithValue }) =>
    actionHandler(
      () => getOthersAccountSoftwareCardsEndpoint({}),
      rejectWithValue,
    ),
);

export const getOthersAccountSoftwareCardByBusinessId = createAsyncThunk(
  ActionTypes.GET_OTHERS_ACCOUNTS_SOFTWARE_CARDS_BY_BUSINESS_ID,
  async (data: { businessCardId: string }, { rejectWithValue }) =>
    actionHandler(
      () => getOthersAccountSoftwareCardsEndpoint(data),
      rejectWithValue,
    ),
);

import React, { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { TextFieldInput } from 'components/shared/inputs/TextFieldInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/system';
import { URLS } from 'defenitions/routes';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { useAppDispatch } from 'store';
import { changePassword } from 'store/features/auth/actions';
import {
  ButtonContainer,
  CreateContainer,
  HelpSinginText,
  HelpText,
  SecondaryText,
  TextFieldContainer,
} from 'components/features/auth/shared';
import { AxiosResponse } from 'axios';
import Grid from '@mui/material/Grid';
import { changePasswordValidationSchema } from 'components/features/auth/containers/changePassword/form/changePasswordValidationSchema';
import { IChangePasswordForm } from 'interfaces/Auth';
import { useNavigate } from 'react-router-dom';

const FromContainer = styled('div')({
  padding: '0px 150px',
  display: 'flex',
  flexDirection: 'column',
});

export const ChangePasswordForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    trigger,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<IChangePasswordForm>({
    resolver: yupResolver(changePasswordValidationSchema),
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit: SubmitHandler<IChangePasswordForm> = async (data) => {
    await dispatch(changePassword(data)).then(async (res) => {
      const response = res.payload as AxiosResponse;
      if (response.status === 200) navigate(URLS.signin);
    });
  };

  useEffect(() => {
    trigger();
  }, [trigger]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <HelpText style={{ padding: 30, textAlign: 'center' }}>
          Пароль должен содеражать не менее 8-ми знаков, включая буквы в верхнем
          и нижнем регистре, цифры и символы
        </HelpText>
      </Grid>
      <FromContainer>
        <TextFieldContainer>
          <Controller
            name="oldPassword"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextFieldInput
                {...rest}
                inputRef={ref}
                type={'password'}
                label={'Старый пароль'}
                errorMessage={errors.oldPassword?.message}
              />
            )}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextFieldInput
                {...rest}
                inputRef={ref}
                type={'password'}
                label={'Введите новый пароль'}
                errorMessage={errors.password?.message}
              />
            )}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...rest } }) => (
              <TextFieldInput
                {...rest}
                inputRef={ref}
                type={'password'}
                label={'Подтвердите пароль'}
                errorMessage={errors.confirmPassword?.message}
              />
            )}
          />
        </TextFieldContainer>
        <ButtonContainer>
          <ActionButton
            fullWidth
            disabled={!isDirty || !isValid || isSubmitting}
            text={'Войти'}
            type={'submit'}
          />
        </ButtonContainer>
        <CreateContainer>
          <SecondaryText>Есть аккаунт?</SecondaryText>
          <HelpSinginText href={URLS.signin}>Войти</HelpSinginText>
        </CreateContainer>
      </FromContainer>
    </form>
  );
};

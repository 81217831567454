import React, { useEffect } from 'react';
import qs from 'qs';
import { HEADER_HEIGHT } from 'services/theme';
import { ReactComponent as Logo } from 'assets/logos/Logo.svg';
import { MenuItemLink } from 'components/elements/header/navigationItem';
import { ReactComponent as HomeIcon } from 'assets/icons/mainIcon.svg';
import { ReactComponent as NavigationBusinessIcon } from 'assets/icons/businessIcon.svg';
import { ReactComponent as MarketplaceIcon } from 'assets/icons/marketplaceIcon.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/newsIcon.svg';
import { ReactComponent as QAIcon } from 'assets/icons/qaIcon.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/notificationIcon.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/settingIcon.svg';
import { ReactComponent as ModerationIcon } from 'assets/icons/moderation.svg';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { URLS } from 'defenitions/routes';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getAccountCurrent,
  getUserCurrent,
} from 'store/features/users/actions';
import { getAccount, getCurrent } from 'store/features/users';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { authService } from 'services/AuthService';
import { attachUrl } from 'services/ApiClient';
import { logout } from 'store/features/auth/actions';
import { useTranslation } from 'react-i18next';
import { getUnreadCountNotifications } from 'store/features/notifications/actions';
import { selectUnreadCountNotifications } from 'store/features/notifications';
import Badge from '@mui/material/Badge';
import { Roles } from 'interfaces/Users';
import { Search } from 'components/shared/search';
import { styled, SxProps, Theme } from '@mui/material';

const REQUEST_TIME = 30000;
const MIN_SEARCH_LENGTH = 3;

const Container = styled('div')(({ theme }) => ({
  backgroundColor: '#e9f8ff',
  height: HEADER_HEIGHT,
  paddingLeft: 37,
  paddingRight: 37,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

const CustomSearch = styled(Search)(({ theme }) => ({
  width: 235,
  backgroundColor: theme.palette.background.paper,
}));

const NavItemContainer = styled('div')({});

const IconsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const UserContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const AvatarText = styled('div')({
  fontWeight: 600,
  fontSize: 12,
  color: '#3d3d3d',
  textTransform: 'uppercase',
});

const NotificationBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    fontSize: 10,
    backgroundColor: 'red',
    top: 1,
    right: 3,
  },
});

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  isAuth?: boolean;
};

export const Header = ({ className, sx = [], isAuth }: Props) => {
  const { t } = useTranslation();
  const user = useAppSelector(getCurrent);
  const account = useAppSelector(getAccount);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const notificationCount = useAppSelector(selectUnreadCountNotifications);
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElSettings);
  const handleClickSettingsMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElSettings(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };

  const handleClickChangePassword = () =>
    navigate(URLS.changePassword, { replace: true });

  const handleClickSignOut = async () => {
    await dispatch(logout());
    authService.signOut();
    navigate(URLS.signin, { replace: true });
  };

  const handleClickSettings = () => {
    navigate(URLS.settings);
    handleCloseSettings();
  };
  const fullName = user
    ? `${user?.name} ${user?.lastName.substring(0, 1)}`
    : '';

  const avatarUrl = user?.logo?.path && attachUrl + user.logo.path;

  const handleClickNotifications = () => navigate(URLS.notifications);

  useEffect(() => {
    !user && authService.accountId && dispatch(getUserCurrent());
    !account && authService.accountId && dispatch(getAccountCurrent());
  }, [user, dispatch, account]);

  useEffect(() => {
    user?.role === Roles.OWNER && dispatch(getUnreadCountNotifications());
    const interval = setInterval(() => {
      user?.role === Roles.OWNER && dispatch(getUnreadCountNotifications());
    }, REQUEST_TIME);

    return () => clearInterval(interval);
  }, [dispatch, user?.role]);

  const showGlobalSearch =
    account?.isSystemAccount && pathname !== URLS.globalSearch;

  const handleSearchChange = (value: string) => {
    if (value.length < MIN_SEARCH_LENGTH) return;

    navigate({
      pathname: URLS.globalSearch,
      search: qs.stringify({ text: value }),
    });
  };

  return (
    <Container className={className} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Logo style={{ flexShrink: 0 }} width={143} />
      {isAuth && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '37px' }}>
            {showGlobalSearch && (
              <CustomSearch
                placeholder={t('filters.search')}
                onEnter={handleSearchChange}
              />
            )}
            <NavItemContainer>
              <MenuItemLink
                link={'/home'}
                title={t('account')}
                logo={<HomeIcon />}
              />
            </NavItemContainer>
            <NavItemContainer>
              <MenuItemLink
                link={[URLS.business, URLS.software]}
                title={'Бизнес'}
                logo={<NavigationBusinessIcon />}
              />
            </NavItemContainer>
            <NavItemContainer>
              <MenuItemLink
                link={URLS.marketplace}
                title={t('catalogSoftware')}
                logo={<MarketplaceIcon />}
              />
            </NavItemContainer>
            <NavItemContainer>
              <MenuItemLink
                link={URLS.newsImportant}
                title={t('feedSoftware')}
                logo={<NewsIcon />}
              />
            </NavItemContainer>
            <NavItemContainer>
              <MenuItemLink
                link={URLS.myQuestions}
                title={t('questionsSoftware')}
                logo={<QAIcon />}
              />
            </NavItemContainer>
            {account?.isSystemAccount && (
              <NavItemContainer>
                <MenuItemLink
                  link={URLS.moderationComplaints}
                  title={t('moderation.header')}
                  logo={<ModerationIcon />}
                />
              </NavItemContainer>
            )}
          </div>
          <IconsContainer>
            {user?.role === Roles.OWNER && (
              <IconButton
                sx={{ mr: '21px' }}
                onClick={handleClickNotifications}
              >
                <NotificationBadge
                  badgeContent={notificationCount}
                  color={'error'}
                >
                  <NotificationsIcon height={24} width={24} />
                </NotificationBadge>
              </IconButton>
            )}
            <IconButton sx={{ mr: '29px' }} onClick={handleClickSettingsMenu}>
              <SettingIcon height={24} width={24} />
            </IconButton>
            <UserContainer>
              <Avatar
                sx={{ width: 42, height: 42, mr: '16px' }}
                alt="Avatar"
                src={avatarUrl}
              />
              <AvatarText>{fullName}</AvatarText>
            </UserContainer>
          </IconsContainer>

          <Menu
            anchorEl={anchorElSettings}
            open={open}
            onClose={handleCloseSettings}
          >
            <MenuItem onClick={handleClickSettings}>{t('settings')}</MenuItem>
            <MenuItem onClick={handleClickChangePassword}>
              Изменить пароль
            </MenuItem>
            <MenuItem onClick={handleClickSignOut}>Выход</MenuItem>
          </Menu>
        </>
      )}
    </Container>
  );
};

import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { Loader } from 'components/shared/loader';
import { useAppDispatch, useAppSelector } from 'store';
import { changeFilters, selectSoftwareHasMore } from 'store/features/software';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getSoftware, getMySoftware } from 'store/features/software/actions';
import { ISoftware, ISoftwareFilters } from 'interfaces/Software';
import { SoftwareCard } from 'components/features/business/shared/list/card/SoftwareCard';
import { ListAdOne, ListAdTwo } from '../../../../elements/ads';

type Props = {
  software: ISoftware[];
  isSoftwareLoading: boolean;
  filters: ISoftwareFilters;
  isAll?: boolean;
};

const Container = styled('div')({
  display: 'flex',
  paddingTop: '20px',
  alignItems: 'flex-start',
  flexDirection: 'column',
  alignSelf: 'flex-start',
  width: '100%',
});

export const SoftwareList = ({
  software,
  isSoftwareLoading,
  filters,
  isAll,
}: Props) => {
  const dispatch = useAppDispatch();
  const hasMore = useAppSelector(selectSoftwareHasMore);

  const loadNext = () => {
    dispatch(changeFilters({ ...filters, offset: (filters.offset ?? 0) + 10 }));
  };

  useEffect(() => {
    isAll ? dispatch(getSoftware(filters)) : dispatch(getMySoftware(filters));
  }, [filters, isAll]);

  return (
    <Container>
      {isSoftwareLoading && <Loader show global />}
      <div style={{ height: '100%', width: '100%' }}>
        <InfiniteScroll
          dataLength={software.length}
          next={loadNext}
          hasMore={hasMore}
          loader={<Loader show={isSoftwareLoading} global />}
          scrollableTarget={SCROLLABLE_REFERENCE_ID}
        >
          {software.map((el, index) => (
            <>
              {index % 4 === 0 && index % 8 !== 0 && index !== 0 && (
                <ListAdOne />
              )}
              {index % 4 === 0 && index % 8 === 0 && index !== 0 && (
                <ListAdTwo />
              )}
              <SoftwareCard key={el.id} software={el} isAll={isAll} />
            </>
          ))}
        </InfiniteScroll>
      </div>
    </Container>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from 'store/features/settings/config';
import { actionHandler } from 'store/utils/actionHandler';
import {
  getSettingsEndpoint,
  changeLocaleEndpoint,
  changeTimeZoneEndpoint,
  getTimeZonesEndpoint,
  getNotificationSettingsEndpoint,
  changeNotificationSettingsEndpoint,
} from 'store/features/settings/api';
import { Locale } from 'interfaces/Locale';
import { INotificationSettingCreateItem } from 'interfaces/Notifications';

export const getSettings = createAsyncThunk(
  ActionTypes.GET_SETTINGS,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getSettingsEndpoint(), rejectWithValue),
);

export const changeLocale = createAsyncThunk(
  ActionTypes.CHANGE_LOCALE,
  async (locale: Locale, { rejectWithValue }) =>
    actionHandler(() => changeLocaleEndpoint(locale), rejectWithValue),
);

export const changeTimeZone = createAsyncThunk(
  ActionTypes.CHANGE_TIMEZONE,
  async (timeZone: string, { rejectWithValue }) =>
    actionHandler(() => changeTimeZoneEndpoint(timeZone), rejectWithValue),
);

export const getTimeZones = createAsyncThunk(
  ActionTypes.GET_TIMEZONES,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getTimeZonesEndpoint(), rejectWithValue),
);

export const getNotificationSettings = createAsyncThunk(
  ActionTypes.GET_NOTIFICATION_SETTINGS,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getNotificationSettingsEndpoint(), rejectWithValue),
);

export const changeNotificationSettings = createAsyncThunk(
  ActionTypes.CHANGE_NOTIFICATION_SETTINGS,
  async (settings: INotificationSettingCreateItem[], { rejectWithValue }) =>
    actionHandler(
      () => changeNotificationSettingsEndpoint(settings),
      rejectWithValue,
    ),
);

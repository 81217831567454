import React, { ComponentType, CSSProperties, SVGProps } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/system';
import { ReactComponent as SortIcon } from 'assets/icons/sortingIcon.svg';

const StyledButton = styled(Button)({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '17px',
  height: 32,
  textTransform: 'capitalize',
  borderRadius: 5,
  boxShadow: 'none',
  color: '#A9A9A9',
});

type Props = {
  text: string;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  IconComponent?: ComponentType<SVGProps<SVGSVGElement>>;
  fill?: string;
};

export const TextButton = ({
  text,
  onClick,
  type,
  disabled,
  style,
  IconComponent,
  fill,
  ...props
}: Props & ButtonProps) => (
  <StyledButton
    {...props}
    style={style}
    disabled={disabled}
    variant="text"
    onClick={onClick}
    type={type}
  >
    {!IconComponent && <SortIcon style={{ marginRight: 6 }} />}
    {text}
    {IconComponent && <IconComponent fill={fill} />}
  </StyledButton>
);

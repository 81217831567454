import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slices } from 'store/configuration/slices';
import {
  deleteUser,
  getAccountCurrent,
  getAllUsers,
  getUserCurrent,
  getUsers,
  lockUser,
  lockUserBySystem,
  unlockUser,
  unlockUserBySystem,
  updateUser,
  getActiveUsers,
  getUser,
} from 'store/features/users/actions';
import { initialUsersState, IUsersState } from 'store/features/users/config';
import { RootState } from 'store/index';
import { AxiosResponse } from 'axios';
import {
  IAccount,
  IDetailsUser,
  IUser,
  IUsersFilters,
  Status,
} from 'interfaces/Users';
import { logout } from 'store/features/auth/actions';

const usersSlice = createSlice({
  name: Slices.users,
  initialState: initialUsersState,
  reducers: {
    changeFilters: (
      state: IUsersState,
      { payload }: PayloadAction<IUsersFilters>,
    ) => {
      state.allUsers.filters = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUserCurrent.fulfilled,
      (state: IUsersState, { payload }) => {
        if (payload) {
          const { data } = payload as AxiosResponse<IUser>;
          return {
            ...state,
            current: data,
          };
        }
      },
    );
    builder.addCase(updateUser.fulfilled, (state: IUsersState, { payload }) => {
      const { data } = payload as AxiosResponse<IUser>;
      return {
        ...state,
        current: data,
      };
    });
    builder.addCase(
      getAccountCurrent.fulfilled,
      (state: IUsersState, { payload }) => {
        const { data } = payload as AxiosResponse<IAccount>;
        return {
          ...state,
          account: data,
        };
      },
    );
    builder.addCase(deleteUser.fulfilled, (state: IUsersState, { meta }) => {
      const newUsers = state.users.users.map((el) => {
        return {
          ...el,
          status: el.id === meta.arg ? Status.DELETED : el.status,
        };
      });
      return {
        ...state,
        users: {
          ...state.users,
          users: newUsers,
        },
      };
    });
    builder.addCase(lockUser.fulfilled, (state: IUsersState, { meta }) => {
      const newUsers = state.users.users.map((el) => {
        return {
          ...el,
          status: el.id === meta.arg ? Status.BLOCKED : el.status,
        };
      });
      return {
        ...state,
        users: {
          ...state.users,
          users: newUsers,
        },
      };
    });
    builder.addCase(unlockUser.fulfilled, (state: IUsersState, { meta }) => {
      const newUsers = state.users.users.map((el) => {
        return {
          ...el,
          status: el.id === meta.arg ? Status.ACTIVE : el.status,
        };
      });
      return {
        ...state,
        users: {
          ...state.users,
          users: newUsers,
        },
      };
    });

    builder.addCase(getUsers.pending, (state: IUsersState) => {
      return {
        ...state,
        users: {
          ...state.users,
          loaded: false,
          loading: true,
        },
      };
    });
    builder.addCase(getUsers.fulfilled, (state: IUsersState, { payload }) => {
      const { data } = payload as AxiosResponse<IUser[]>;
      return {
        ...state,
        users: {
          users: data,
          loading: false,
          loaded: true,
        },
      };
    });
    builder.addCase(getUsers.rejected, (state: IUsersState) => {
      return {
        ...state,
        users: {
          ...state.users,
          loaded: true,
          loading: false,
        },
      };
    });

    builder.addCase(getAllUsers.pending, (state: IUsersState, { meta }) => {
      const { offset = 0 } = meta.arg ?? {};
      state.allUsers.loading = true;
      state.allUsers.loaded = false;
      state.allUsers.hasMore = offset === 0 || state.allUsers.hasMore;
    });
    builder.addCase(
      getAllUsers.fulfilled,
      (state: IUsersState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<Array<IUser>>;
        state.allUsers.users =
          offset === 0 ? data : [...state.allUsers.users, ...data];
        state.allUsers.loading = false;
        state.allUsers.loaded = true;
        state.allUsers.hasMore = data.length !== 0;
      },
    );
    builder.addCase(getAllUsers.rejected, (state: IUsersState) => {
      state.allUsers.loading = false;
      state.allUsers.loaded = false;
      state.allUsers.hasMore = false;
    });

    builder.addCase(
      lockUserBySystem.fulfilled,
      (state: IUsersState, { meta }) => {
        state.allUsers.users = state.allUsers.users.map((el) => {
          return {
            ...el,
            status: el.id === meta.arg.id ? Status.BLOCKED : el.status,
          };
        });
      },
    );
    builder.addCase(
      unlockUserBySystem.fulfilled,
      (state: IUsersState, { meta }) => {
        state.allUsers.users = state.allUsers.users.map((el) => {
          return {
            ...el,
            status: el.id === meta.arg.id ? Status.ACTIVE : el.status,
          };
        });
      },
    );

    //active
    builder.addCase(getActiveUsers.pending, (state: IUsersState, { meta }) => {
      const { offset = 0 } = meta.arg ?? {};
      state.allUsers.loading = true;
      state.allUsers.loaded = false;
      state.allUsers.hasMore = offset === 0 || state.allUsers.hasMore;
    });
    builder.addCase(
      getActiveUsers.fulfilled,
      (state: IUsersState, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<Array<IUser>>;
        state.allUsers.users =
          offset === 0 ? data : [...state.allUsers.users, ...data];
        state.allUsers.loading = false;
        state.allUsers.loaded = true;
        state.allUsers.hasMore = data.length !== 0;
      },
    );
    builder.addCase(getActiveUsers.rejected, (state: IUsersState) => {
      state.allUsers.loading = false;
      state.allUsers.loaded = false;
      state.allUsers.hasMore = false;
    });

    //user
    builder.addCase(getUser.pending, (state: IUsersState) => {
      state.user.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state: IUsersState, { payload }) => {
      const { data } = payload as AxiosResponse<IDetailsUser>;
      state.user.loading = false;
      state.user.user = data;
    });
    builder.addCase(getUser.rejected, (state: IUsersState) => {
      state.user.loading = false;
      state.user.user = null;
    });

    //auth
    builder.addCase(logout.fulfilled, (state: IUsersState) => {
      state.account = null;
      state.current = null;
    });
  },
});

export { usersSlice };
export const { changeFilters } = usersSlice.actions;
export const getCurrent = (state: RootState) => state.users.current;
export const getAccount = (state: RootState) => state.users.account;
export const getUsersLoading = (state: RootState) => state.users.users.loading;
export const getUsersList = (state: RootState) => state.users.users.users;
export const getAllUsersLoading = (state: RootState) =>
  state.users.allUsers.loading;
export const getAllUsersList = (state: RootState) => state.users.allUsers.users;
export const getAllUsersHasMore = (state: RootState) =>
  state.users.allUsers.hasMore;
export const getAllUsersFilters = (state: RootState) =>
  state.users.allUsers.filters;
export const getUserSelector = (state: RootState) => state.users.user.user;
export const getUserLoadingSelector = (state: RootState) =>
  state.users.user.loading;

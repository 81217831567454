import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';

export const editUserValidationSchema = yup.object({
  name: yup.string().required(ErrorMessages.required),
  lastName: yup.string().required(ErrorMessages.required),
  role: yup.string().required(ErrorMessages.required),
  email: yup
    .string()
    .email(ErrorMessages.emailNotCorrect)
    .required(ErrorMessages.required),
});

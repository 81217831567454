import React from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { Loader } from 'components/shared/loader';
import { IInvalidContent } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { ContentCard } from './ContentCard';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

const List = styled('ul')({
  padding: 0,
  margin: 0,
  listStyle: 'none',
});

const Item = styled('div')({
  marginBottom: 8,

  '&:last-of-type': {
    marginBottom: 0,
  },
});

const EmptyListContainer = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

type Props = {
  sx?: SxProps<Theme>;
  contents: IInvalidContent[];
  loading?: boolean;
  onEdit?: (content: IInvalidContent) => void;
  onRemove?: (id: string) => void;
};

export const ContentsList = ({
  sx = [],
  contents,
  loading = false,
  onEdit,
  onRemove,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {loading && (
        <EmptyListContainer>
          <Loader show />
        </EmptyListContainer>
      )}

      {!loading && !contents.length && (
        <EmptyListContainer>
          {t('moderation.contents.empty')}
        </EmptyListContainer>
      )}

      {!loading && !!contents.length && (
        <List>
          {contents.map((item) => (
            <Item key={item.id}>
              <ContentCard content={item} onEdit={onEdit} onDelete={onRemove} />
            </Item>
          ))}
        </List>
      )}
    </Root>
  );
};

import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { IComplaintsValue } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCell = (): HeadCell[] => {
  return [
    {
      id: 'name',
      label: i18n.t('tables.name'),
      numeric: false,
      withOpen: true,
    },
    {
      id: 'all',
      label: i18n.t('tables.all'),
      numeric: true,
    },
    {
      id: 'active',
      label: i18n.t('tables.active'),
      numeric: true,
    },
    {
      id: 'rejected',
      label: i18n.t('tables.rejected'),
      numeric: true,
    },
    {
      id: 'resolved',
      label: i18n.t('tables.resolved'),
      numeric: true,
    },
  ];
};

export const createData = (data: IComplaintsValue[]): DataItem[] =>
  data.map((el) => {
    return {
      id: el.type,
      name: i18n.t(`search.type.${el.type}`),
      all: el.all,
      active: el.active,
      rejected: el.rejected,
      resolved: el.resolved,
      child: el.names?.map((child, index) => {
        return {
          id: index.toString(),
          name: getLocaleValue(child.name),
          all: child.all,
          active: child.active,
          rejected: child.rejected,
          resolved: child.resolved,
        };
      }),
    };
  });

import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ComplaintAboutType, IComplaintCreate } from 'interfaces/Complaint';
import Grid from '@mui/material/Grid';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { useTranslation } from 'react-i18next';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { useAttachments } from 'utils/hooks';
import {
  createComplaint,
  uploadComplaintAttachments,
} from 'store/features/complaints/actions';
import { useAppDispatch } from 'store';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { MAX_LENGTH } from 'defenitions/errorMessages';
import { localeValidate } from 'components/elements/fieldBuilder/validator';

type Props = {
  complaintAboutType: ComplaintAboutType;
  businessCardId?: string;
  relationId: string;
  additionAction: () => void;
};
export const CreateComplaint = ({
  complaintAboutType,
  businessCardId,
  relationId,
  additionAction,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { attachments, onUploadAttachments, onRemoveAttachments } =
    useAttachments(uploadComplaintAttachments);

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm<IComplaintCreate>({
    mode: 'onChange',
    defaultValues: {},
    resolver: attachments.length
      ? undefined
      : yupResolver(
          yup.object({
            content: localeValidate(MAX_LENGTH),
          }),
        ),
  });

  const handleSave: SubmitHandler<IComplaintCreate> = async (data) => {
    await dispatch(
      createComplaint({
        ...data,
        complaintAboutType,
        businessCardId,
        relationId,
      }),
    ).unwrap();
    additionAction();
  };
  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    trigger('content');
  }, [attachments]);
  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'content'}
            label={t('complaints.content')}
            errors={errors}
            control={control}
            multiline
            maxLength={MAX_LENGTH}
          />
        </Grid>
        <Grid item xs={12}>
          <FilesUploader
            maxFiles={10}
            onUploadAttachments={onUploadAttachments}
            attachments={attachments}
            onClickRemoveAttachments={onRemoveAttachments}
          />
        </Grid>
        <Grid item xs={12}>
          <ActionButton
            fullWidth
            onClick={handleSubmit(handleSave)}
            autoFocus
            text={t('modalTexts.complaint.actionTitle')}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const accountId = 'accountId';

class AuthService {
  get accountId(): string {
    return localStorage.getItem(accountId) as string;
  }

  signIn = (accountId: string) => {
    localStorage.setItem('accountId', accountId);
  };

  signOut = () => {
    localStorage.removeItem(accountId);
    document.cookie =
      'MARKINGSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };
}

export const authService = new AuthService();

import React, { useMemo } from 'react';
import { Grid, SxProps, Theme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import {
  changeUsersFilters,
  selectAllUsers,
  selectUsersFilters,
} from 'store/features/stats';
import { DateTimeRangeFilter } from 'components/elements/filters/DateTimeRangeFilter';
import { useTranslation } from 'react-i18next';
import { CreateButton } from './CreateButton';
import { getAllUsers, getUsersStats } from 'store/features/stats/actions';
import { SelectFilters } from 'components/elements/filters/SelectFilters';

type Props = {
  sx?: SxProps<Theme>;
};

export const UsersFilters = ({ sx = [] }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filters = useAppSelector(selectUsersFilters);
  const { fromDate, toDate, userIds } = filters;

  const startDate = fromDate ? new Date(fromDate) : null;
  const endDate = toDate ? new Date(toDate) : null;

  const handleDateTimesChange = (start: Date | null, end: Date | null) => {
    const startTime = start ? start.getTime() : null;
    const endTime = end ? end.getTime() : null;

    dispatch(
      changeUsersFilters({
        ...filters,
        fromDate: startTime,
        toDate: endTime,
      }),
    );
  };

  const handleCreate = () => {
    dispatch(getUsersStats(filters));
  };

  const users = useAppSelector(selectAllUsers);

  const usersOptions = useMemo(
    () =>
      users.map(({ id, name, lastName }) => ({
        id,
        name: `${name} ${lastName}`.trim(),
      })),
    [users],
  );

  const handleUsersFiltersLoad = () => dispatch(getAllUsers());

  const handleUsersChange = (value: string[]) => {
    dispatch(changeUsersFilters({ ...filters, userIds: value }));
  };

  return (
    <Grid
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      container
      alignItems="center"
    >
      <Grid item>
        <DateTimeRangeFilter
          startDate={startDate}
          endDate={endDate}
          onDateTimesChange={handleDateTimesChange}
        />
      </Grid>
      <Grid item>
        <SelectFilters
          title={t('filters.stats.userTitle')}
          placeholder={t('filters.stats.userPlaceholder')}
          options={usersOptions}
          value={userIds}
          onLoad={handleUsersFiltersLoad}
          onChange={handleUsersChange}
        />
      </Grid>
      <Grid item>
        <CreateButton onClick={handleCreate}>{t('create stats')}</CreateButton>
      </Grid>
    </Grid>
  );
};

import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { Dictionaries } from 'interfaces/Dictionaries';
import { Types } from 'interfaces/Users';
import qs from 'qs';

export const getDictionariesCardTypeEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get(`/dictionaries/${Dictionaries.cardType}/select2`);
};

export const getDictionariesServiceTypeEndpoint =
  (): Promise<AxiosResponse> => {
    return apiClient.get(`/dictionaries/${Dictionaries.serviceType}/select2`);
  };

export const getDictionariesContentCardTypeEndpoint = (
  type: Types | undefined,
): Promise<AxiosResponse> => {
  return apiClient.get(
    `/dictionaries/${Dictionaries.cardType}/content?hierarchical=true&dependsOn=${type}`,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
      },
    },
  );
};

export const getDictionariesContentServiceTypeEndpoint =
  (): Promise<AxiosResponse> => {
    return apiClient.get(
      `/dictionaries/${Dictionaries.serviceType}/content?hierarchical=true`,
    );
  };

export const getDictionariesQuestionCategoriesEndpoint =
  (): Promise<AxiosResponse> => {
    return apiClient.get(
      `/dictionaries/${Dictionaries.questionCategories}/select2`,
    );
  };

export const getDictionariesQuestionSubcategoriesEndpoint = (
  directory: string,
): Promise<AxiosResponse> => {
  return apiClient.get(`/dictionaries/${directory}/select2`);
};

export const getDictionariesContentSoftwareTypesEndpoint =
  (): Promise<AxiosResponse> => {
    return apiClient.get(
      `/dictionaries/${Dictionaries.softwareType}/content?hierarchical=true`,
    );
  };

export const getDictionariesContentApplicationAreasEndpoint =
  (): Promise<AxiosResponse> => {
    return apiClient.get(
      `/dictionaries/${Dictionaries.applicationArea}/content?hierarchical=true`,
    );
  };

export const getDictionariesContentProgrammingLanguagesEndpoint =
  (): Promise<AxiosResponse> => {
    return apiClient.get(
      `/dictionaries/${Dictionaries.programmingLanguage}/content?hierarchical=true`,
    );
  };

export const getDictionariesContentTechnologyStackEndpoint =
  (): Promise<AxiosResponse> => {
    return apiClient.get(
      `/dictionaries/${Dictionaries.technologyStack}/content?hierarchical=true`,
    );
  };

import React, { MouseEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getFormattedDate } from 'utils/dates';
import { ReactComponent as ClockIcon } from 'assets/icons/clockIcon.svg';
import { attachUrl } from 'services/ApiClient';
import { INews } from 'interfaces/News';
import { getComments } from 'store/features/news/actions';
import { getNewsComments } from 'store/features/news';
import { CommentForm } from 'components/features/news/containers/commentForm';
import { CommentCard } from 'components/features/news/components/commentCard';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { ReactComponent as AnswerIcon } from 'assets/icons/answer.svg';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import { Editor } from 'components/shared/editor';
import { getNameInitials } from 'utils/user';
import Avatar from '@mui/material/Avatar';
import { ReactionType } from 'interfaces/Post';
import Snackbar from 'services/Snackbar';
import { Locale } from 'interfaces/Locale';
import { Loader } from 'components/shared/loader';
import { Box, styled, Typography } from '@mui/material';
import { ISoftwareCardShort } from 'interfaces/Software';

const Root = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: '5px',
  padding: theme.spacing('20px', '30px'),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.1)',
}));

const NewItemHeader = styled('div')({
  display: 'flex',
  marginBottom: '25px',
  position: 'relative',
});

const NewItemHeaderPhoto = styled('div')({
  marginRight: 5,
  img: {
    borderRadius: '50%',
    overflow: 'hidden',
    width: 53,
    height: 47,
  },
});

const NewItemHeaderInfo = styled('div')({});

const NewItemHeaderInfoTitle = styled(Link)({
  fontSize: '14px',
  fontWeight: 500,
  color: '#000000',
});

const NewItemHeaderInfoSubTitle = styled('div')({
  fontSize: '12px',
  color: '#A9A9A9',
});

const NewItemHeaderInfoTime = styled('div')({
  display: 'flex',
  fontSize: '12px',
  color: '#A9A9A9',
});

const NewItemHeaderInfoTimeIcon = styled('div')({
  marginRight: 5,
});

const NewItemContent = styled('div')({
  marginBottom: 25,
});

const NewItemTitle = styled('div')({
  marginBottom: 10,
  fontSize: 18,
  fontWeight: 'bold',
});

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(0, 2),
}));

const Divider = styled('div')({
  background: '#DDDDDD',
  height: 1,
  margin: '20px 0px 20px -30px',
  width: 'calc(100% + 60px)',
});

const CommentList = styled('div')({
  marginTop: '21px',
});

const Attachments = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const Attachment = styled('div')({
  flex: 1,

  '& > img': {
    width: '100%',
    height: 'auto',
  },

  '& > video': {
    width: '100%',
    height: 'auto',
  },
});

const ShowAll = styled('button')({
  margin: 0,
  padding: 0,
  border: 0,
  backgroundColor: 'transparent',
  fontWeight: 500,
  fontSize: 14,
  color: '#00618e',
  cursor: 'pointer',
});

type Props = {
  currentUserId?: string;
  isSystemAccount?: boolean;
  news: INews;
  onDelete?: (id: string) => void;
  onLike?: (id: string) => void;
  onDislike?: (id: string) => void;
  isSystemNews?: boolean;
};

export const NewsCard = ({
  currentUserId,
  isSystemAccount = false,
  isSystemNews = false,
  news,
  onDelete,
  onLike,
  onDislike,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const language = Locale.ru;

  const {
    attachments = [],
    businessCard,
    commentsCount,
    createdDateTime,
    creator,
    hasCurrentUserComment,
    id,
    reactions,
    softwareCards,
  } = news;

  const avatar = businessCard?.logo && `${attachUrl}${businessCard.logo.path}`;
  const company = businessCard?.name[language] ?? '';

  const content = news.content[language] ?? '';
  const title = news.title[language] ?? '';

  const creatorFullName =
    `${creator.name} ${creator.lastName}, #${creator.reputation}`.trim();

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const complaintDialog = useModalActions();

  const complaintDialogTitle = t('modalTexts.complaint.title', { name: title });

  const [showComments, setShowComments] = useState(false);

  const toggleComments = () => setShowComments((prev) => !prev);

  const commentsState = useAppSelector(getNewsComments(id));

  const { loading: isCommentsLoading = false, list: comments = [] } =
    commentsState;

  const handleDelete = () => onDelete?.(id);

  const handleLike = () => onLike?.(id);

  const handleDislike = () => onDislike?.(id);

  const handleSoftwareClick =
    ({ hidden, cardBelongToCurrentAccount }: ISoftwareCardShort) =>
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (hidden && !cardBelongToCurrentAccount) {
        event.preventDefault();
        Snackbar.show(t('hidden card'), 'warning');
      }
    };

  const [showFullContent, setShowFullContent] = useState(false);

  const visibleContent = showFullContent ? content : content.substring(0, 255);

  const showFullContentButton = !showFullContent && content?.length > 255;

  const handleShowAll = () => setShowFullContent(true);

  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const handleOpenMoreMenu = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const showMoreButton = currentUserId !== news.creator.id || isSystemAccount;

  const canCreateComplaint =
    currentUserId !== news.creator.id && !isSystemAccount;

  const canDelete = isSystemAccount;

  useEffect(() => {
    showComments && dispatch(getComments(id));
  }, [dispatch, id, showComments]);

  return (
    <Root>
      <NewItemHeader onMouseLeave={() => setAnchorEl(null)}>
        <NewItemHeaderPhoto>
          <Avatar src={avatar} sx={{ width: 55, height: 55 }}>
            {getNameInitials(company ?? 'Deleted')}
          </Avatar>
        </NewItemHeaderPhoto>
        <NewItemHeaderInfo>
          {!isSystemNews && (
            <NewItemHeaderInfoTitle
              target={'_blank'}
              to={generatePath(URLS.viewBusinessCard, {
                id: businessCard?.id ?? 'deleted',
              })}
            >
              {company ?? 'Deleted'}
            </NewItemHeaderInfoTitle>
          )}

          {isSystemNews ? (
            <NewItemHeaderInfoSubTitle>
              Новости системы
            </NewItemHeaderInfoSubTitle>
          ) : (
            <NewItemHeaderInfoSubTitle>
              {creatorFullName}
            </NewItemHeaderInfoSubTitle>
          )}

          <NewItemHeaderInfoTime>
            <NewItemHeaderInfoTimeIcon>
              <ClockIcon />
            </NewItemHeaderInfoTimeIcon>
            {getFormattedDate(createdDateTime)}
          </NewItemHeaderInfoTime>
        </NewItemHeaderInfo>

        {showMoreButton && <MoreIcon onClick={handleOpenMoreMenu} />}
      </NewItemHeader>
      <NewItemTitle>{title}</NewItemTitle>
      <NewItemContent>
        {!!softwareCards?.length && (
          <Typography sx={{ mb: 1, fontWeight: 500, fontSize: 14 }}>
            {softwareCards.map((card, index) => (
              <>
                <Typography
                  component={Link}
                  key={card.id}
                  sx={{ color: '#00618e', textDecoration: 'none' }}
                  variant="inherit"
                  to={generatePath(URLS.viewSoftwareCard, { id: card.id })}
                  target={'_blank'}
                  onClick={handleSoftwareClick(card)}
                >
                  {getLocaleValue(card.name)}
                </Typography>
                {index === softwareCards.length - 1 ? '' : ', '}
              </>
            ))}
          </Typography>
        )}

        <Editor value={visibleContent} readOnly />

        {showFullContentButton && (
          <ShowAll onClick={handleShowAll}>Показать всё</ShowAll>
        )}
      </NewItemContent>
      <Attachments>
        {attachments.map((attachment) => {
          const isImage = attachment.contentType.includes('image');
          const url = `${attachUrl}${attachment.path}`;

          return (
            <Attachment key={attachment.id}>
              {isImage ? (
                <img
                  src={url}
                  alt={`attachment-${attachment.id}`}
                  loading={'lazy'}
                />
              ) : (
                <video controls>
                  <source src={url} type="video/mp4" />
                </video>
              )}
            </Attachment>
          );
        })}
      </Attachments>
      <Actions sx={{ display: 'flex', mt: 2, gap: 16 }}>
        <Box sx={{ flexBasis: '100px' }}>
          <InteractiveButton
            IconComponent={LikeIcon}
            count={likes}
            active={hasUserLikes}
            onClick={handleLike}
          />
        </Box>
        <Box sx={{ flexBasis: '100px' }}>
          <InteractiveButton
            IconComponent={DislikeIcon}
            count={dislikes}
            active={hasUserDislikes}
            onClick={handleDislike}
          />
        </Box>
        <Box sx={{ flexBasis: '150px' }}>
          <InteractiveButton
            IconComponent={AnswerIcon}
            count={commentsCount}
            label={t('comments')}
            active={hasCurrentUserComment}
            onClick={toggleComments}
          />
        </Box>
      </Actions>
      {isCommentsLoading && <Loader />}
      {showComments && (
        <>
          <Divider />
          <CommentForm newId={id} />
          <CommentList>
            {comments.map((item) => (
              <CommentCard
                key={item.id}
                comment={item}
                newsId={id}
                currentUserId={currentUserId}
                forSystemCard={isSystemAccount}
              />
            ))}
          </CommentList>
        </>
      )}
      <ConfirmDialog
        open={complaintDialog.isOpen}
        title={complaintDialogTitle}
        onClickClose={complaintDialog.onClickClose}
      >
        <CreateComplaint
          relationId={news.id}
          complaintAboutType={ComplaintAboutType.NEWS}
          additionAction={complaintDialog.onClickClose}
        />
      </ConfirmDialog>
      <MoreMenu
        anchorEl={anchorEl}
        onClickComplaint={
          canCreateComplaint ? complaintDialog.onClickOpen : undefined
        }
        onClickDelete={canDelete ? handleDelete : undefined}
      />
    </Root>
  );
};

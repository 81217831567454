import React, { useEffect } from 'react';
import { HomeLayout } from 'components/features/home/components/layout';
import {
  Container,
  Title,
  TitleContainer,
} from 'components/features/home/components';
import { RouteNames, URLS } from 'defenitions/routes';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getNotification,
  getUnreadCountNotifications,
} from 'store/features/notifications/actions';
import {
  changeFilters,
  selectNotification,
  selectNotificationsFilters,
  selectNotificationsLoading,
} from 'store/features/notifications';
import { Loader } from 'components/shared/loader';
import Grid from '@mui/material/Grid';
import {
  Info,
  NotificationAvatar,
  ViewTitle,
  TitleDivider,
  NotificationAvatarContainer,
} from 'components/features/notification/components';
import { getLocaleValue } from 'utils/form';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { attachUrl } from 'services/ApiClient';
import getDateFnsLocale from 'services/dateFns';
import DOMPurify from 'dompurify';
import { Sections } from 'defenitions/main';
import { ReactComponent as MarketplaceIcon } from 'assets/icons/marketplaceIcon.svg';
import { ReactComponent as NavigationBusinessIcon } from 'assets/icons/businessIcon.svg';
import { ReactComponent as QAIcon } from 'assets/icons/qaIcon.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/newsIcon.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/mainIcon.svg';
import { BackButton } from 'components/shared/buttons/BackButton';

export const ViewNotification = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notification = useAppSelector(selectNotification);
  const isNotificationLoading = useAppSelector(selectNotificationsLoading);
  const notificationFilters = useAppSelector(selectNotificationsFilters);
  const from =
    differenceInDays(
      new Date(),
      new Date(notification?.createdDateTime || new Date()),
    ) === 0
      ? formatDistanceToNowStrict(
          new Date(notification?.createdDateTime || new Date()),
          {
            locale: getDateFnsLocale(),
          },
        )
      : format(
          new Date(notification?.createdDateTime || new Date()),
          'dd MMMM yyyy',
          {
            locale: getDateFnsLocale(),
          },
        );
  const avatarUrl = notification?.creator?.logo?.path
    ? attachUrl + notification?.creator.logo.path
    : '';

  const renderEmptyAvatar = () => {
    return (
      <NotificationAvatarContainer>
        {notification?.category === Sections.HOME && (
          <HomeIcon stroke={'white'} />
        )}
        {notification?.category === Sections.MARKETPLACE && (
          <MarketplaceIcon stroke={'white'} />
        )}
        {notification?.category === Sections.BUSINESS && (
          <NavigationBusinessIcon stroke={'white'} />
        )}
        {notification?.category === Sections.QUESTION && (
          <QAIcon stroke={'white'} />
        )}
        {notification?.category === Sections.NEWS && (
          <NewsIcon stroke={'white'} />
        )}
      </NotificationAvatarContainer>
    );
  };

  const onClickBack = () => {
    dispatch(changeFilters({ ...notificationFilters, offset: 0 }));
    navigate(-1);
  };

  useEffect(() => {
    id &&
      dispatch(getNotification(id)).then(() =>
        dispatch(getUnreadCountNotifications()),
      );
  }, [dispatch, id]);

  return (
    <HomeLayout>
      <Container item container>
        <TitleContainer container>
          <Grid item xs={2}>
            <BackButton onClick={onClickBack} />
          </Grid>
          <Grid item container xs={8} justifyContent={'center'}>
            <Title>{t(`${RouteNames[URLS.notificationView]}`)}</Title>
          </Grid>
          <Grid item xs={2} />
        </TitleContainer>
      </Container>
      {isNotificationLoading && <Loader show global />}
      <Container sx={{ borderTop: 'none', borderRadius: 0 }}>
        <TitleContainer container flexWrap={'nowrap'}>
          <Grid item mr={2}>
            {avatarUrl ? (
              <NotificationAvatar src={avatarUrl} />
            ) : (
              renderEmptyAvatar()
            )}
          </Grid>
          <Grid item container direction={'column'} spacing={1}>
            <Title item>{getLocaleValue(notification?.title)}</Title>
            <Grid sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} item>
              <TimeIcon />
              <Info>{from}</Info>
            </Grid>
          </Grid>
        </TitleContainer>
        <TitleContainer container sx={{ paddingRight: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <ViewTitle>{t('Notification')}</ViewTitle>
            <TitleDivider />
          </div>
          <Grid
            item
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(getLocaleValue(notification?.content)),
            }}
          />
        </TitleContainer>
      </Container>
    </HomeLayout>
  );
};

import React from 'react';
import {
  Container,
  Divider,
  SubTitle,
} from 'components/features/business/shared/item/components';
import Grid from '@mui/material/Grid';

import { IBusinessService } from 'interfaces/Business';
import { ViewBusinessService } from 'components/features/business/shared/item/view/serviceData/components';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { useTranslation } from 'react-i18next';

type Props = {
  businessServices: IBusinessService[];
  onClickNext: () => void;
  onClickBack: () => void;
  isCreatedByCurrentAccount: boolean;
};

export const ServiceDataView = ({
  businessServices,
  onClickNext,
  onClickBack,
  isCreatedByCurrentAccount,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Container container>
      {businessServices.length === 0 && (
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignContent={'center'}
          style={{ height: 'calc(100vh - 394px)' }}
        >
          <SubTitle>Перечень оказываемых услуг пуст</SubTitle>
        </Grid>
      )}
      {businessServices.map((el) => (
        <ViewBusinessService
          key={el.name.ru}
          businessService={el}
          isCreatedByCurrentAccount={isCreatedByCurrentAccount}
        />
      ))}
      <Divider />
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            text={t('actions.back')}
            onClick={onClickBack}
          />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            onClick={onClickNext}
            text={t('actions.next')}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

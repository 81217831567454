import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Popover,
} from '@mui/material';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { Checkbox } from 'components/shared/checkbox';
import { IUsersFilters, Roles, Status } from 'interfaces/Users';
import { useTranslation } from 'react-i18next';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 8px',
});

const StyledButton = styled(Button)({
  borderRadius: 4,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: 12,
  color: '#000',
  textTransform: 'none',
  boxShadow: 'none',
});

const Title = styled(FormLabel)({
  marginBottom: 8,
  fontWeight: '600',
  fontSize: 12,
  color: '#4b4b4b',
});

const ResetButton = styled('button')({
  margin: 0,
  border: 0,
  padding: 0,
  fontWeight: '500',
  fontSize: 10,
  color: '#00618e',
  textDecoration: 'underline',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
  marginTop: 8,
  textAlign: 'left',
});

type Props = {
  filters: IUsersFilters;
  onChange: (filters: IUsersFilters) => void;
};

export const UsersFilters = ({ filters, onChange }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isStatusSelected = (name: Status) => {
    return Boolean(filters.userStatuses.find((b) => b === name));
  };

  const handleChangeStatus =
    (name: Status) => (_: unknown, checked: boolean) => {
      const newStatuses = checked
        ? [...filters.userStatuses, name]
        : filters.userStatuses.filter((b) => b !== name);

      onChange({ ...filters, userStatuses: newStatuses, offset: 0 });
    };

  const isRoleSelected = (name: Roles) => {
    return Boolean(filters.userRoles.find((b) => b === name));
  };

  const handleChangeRole = (name: Roles) => (_: unknown, checked: boolean) => {
    const newRoles = checked
      ? [...filters.userRoles, name]
      : filters.userRoles.filter((b) => b !== name);

    onChange({ ...filters, userRoles: newRoles, offset: 0 });
  };

  const reset = () => onChange({ ...filters, userRoles: [], userStatuses: [] });

  return (
    <Container>
      <StyledButton onClick={handleOpen}>
        <FilterIcon style={{ marginRight: 4 }} />
        Фильтр
      </StyledButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <FormControl sx={{ padding: '16px' }}>
          <Title>{t('status')}</Title>
          <FormGroup>
            {Object.keys(Status).map((item) => (
              <Checkbox
                key={item}
                label={t(`statuses.${item}`)}
                checked={isStatusSelected(item as Status)}
                onChange={handleChangeStatus(item as Status)}
              />
            ))}
          </FormGroup>
          <ResetButton onClick={reset}>
            {t('filters.accounts.reset')}
          </ResetButton>
        </FormControl>
        <FormControl sx={{ padding: '16px' }}>
          <Title>{t('role')}</Title>
          <FormGroup>
            {Object.keys(Roles).map((item) => (
              <Checkbox
                key={item}
                label={t(`roles.${item}`)}
                checked={isRoleSelected(item as Roles)}
                onChange={handleChangeRole(item as Roles)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Popover>
    </Container>
  );
};

import { styled } from '@mui/system';
import React from 'react';
import { MENU_WIDTH } from 'services/theme';
import { matchPath, useLocation } from 'react-router-dom';
import { URLS } from 'defenitions/routes';

const Footer = styled('div')({
  color: '#666666',
  fontWeight: 400,
  background: '#FFFFFF',
  borderRight: '1px solid #dddddd',
  width: MENU_WIDTH,
  padding: 7,
  fontSize: 12,
  lineHeight: '15px',
});

export const CopyRight = () => {
  const { pathname } = useLocation();
  const isFullWidth =
    matchPath(URLS.createBusinessCard, pathname) ||
    matchPath(URLS.editBusinessCard, pathname) ||
    matchPath(URLS.viewBusinessCard, pathname) ||
    matchPath(URLS.createSoftware, pathname) ||
    matchPath(URLS.editSoftwareCard, pathname) ||
    matchPath(URLS.viewSoftwareCard, pathname);
  return (
    <Footer style={{ width: isFullWidth ? '100%' : MENU_WIDTH }}>
      © Авторские права 2022, EQUIRON LLC. LabelCoding® является
      зарегистрированным товарным знаком EQUIRON LLC.
    </Footer>
  );
};

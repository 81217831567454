import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { IQuestions } from 'interfaces/Stats';

export const createHeadCell = (): HeadCell[] => {
  return [
    { id: 'status', label: i18n.t('tables.questionStatus'), numeric: false },
    {
      id: 'all',
      label: i18n.t('tables.allQuestions'),
      numeric: true,
    },
    {
      id: 'publicQuestions',
      label: i18n.t('tables.publicQuestions'),
      numeric: true,
    },
    {
      id: 'privateQuestions',
      label: i18n.t('tables.privateQuestions'),
      numeric: true,
    },
  ];
};

export const createData = (data: IQuestions[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.status,
      status: i18n.t(`QuestionStatusCategory.${el.status}`),
    };
  });

import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';
import { localeValidate } from 'components/elements/fieldBuilder/validator';

const MAX_LENGTH_CONTENT = 4000;

export const serviceDataValidationSchema = yup.object({
  content: localeValidate(MAX_LENGTH_CONTENT),
  businessCardId: yup.string().required(ErrorMessages.required),
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionHandler } from 'store/utils/actionHandler';
import {
  IComplaintCreate,
  IInvalidContent,
  TAddInvalidContent,
  TComplaintsFilters,
  TContentsFilters,
  TRatingsFilters,
} from 'interfaces/Complaint';
import { ActionTypes } from 'store/features/complaints/config';
import {
  addInvalidContentEndpoint,
  createComplaintEndpoint,
  declineComplaintEndpoint,
  editInvalidContentEndpoint,
  getComplaintByIdEndpoint,
  getComplaintsAccountsFilterEndpoint,
  getComplaintsAccountsRatingsEndpoint,
  getComplaintsByRelationIdEndpoint,
  getComplaintsEndpoint,
  getInvalidContentsEndpoint,
  removeInvalidContentEndpoint,
  resolveComplaintEndpoint,
  uploadComplaintAttachmentEndpoint,
} from 'store/features/complaints/api';

export const createComplaint = createAsyncThunk(
  ActionTypes.CREATE_COMPLAINT,
  async (data: IComplaintCreate, { rejectWithValue }) =>
    actionHandler(() => createComplaintEndpoint(data), rejectWithValue),
);

export const uploadComplaintAttachments = createAsyncThunk(
  ActionTypes.UPLOAD_COMPLAINT_ATTACHMENT,
  async (data: FormData, { rejectWithValue }) =>
    actionHandler(
      () => uploadComplaintAttachmentEndpoint(data),
      rejectWithValue,
    ),
);

export const getComplaints = createAsyncThunk(
  ActionTypes.GET_COMPLAINTS,
  async (filters: TComplaintsFilters, { rejectWithValue }) =>
    actionHandler(() => getComplaintsEndpoint(filters), rejectWithValue),
);

export const resolveComplaint = createAsyncThunk(
  ActionTypes.RESOLVE_COMPLAINT,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => resolveComplaintEndpoint(id), rejectWithValue),
);

export const declineComplaint = createAsyncThunk(
  ActionTypes.DECLINE_COMPLAINT,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => declineComplaintEndpoint(id), rejectWithValue),
);

export const getComplaintsAccountsRatings = createAsyncThunk(
  ActionTypes.GET_COMPLAINTS_ACCOUNTS,
  async (filters: TRatingsFilters, { rejectWithValue }) =>
    actionHandler(
      () => getComplaintsAccountsRatingsEndpoint(filters),
      rejectWithValue,
    ),
);

export const getComplaintsAccountsFilter = createAsyncThunk(
  ActionTypes.GET_COMPLAINTS_ACCOUNTS_FILTER,
  async (_: void, { rejectWithValue }) =>
    actionHandler(() => getComplaintsAccountsFilterEndpoint(), rejectWithValue),
);

export const getInvalidContents = createAsyncThunk(
  ActionTypes.GET_INVALID_CONTENTS,
  async (filters: TContentsFilters, { rejectWithValue }) =>
    actionHandler(() => getInvalidContentsEndpoint(filters), rejectWithValue),
);

export const addInvalidContent = createAsyncThunk(
  ActionTypes.ADD_INVALID_CONTENT,
  async (data: TAddInvalidContent, { rejectWithValue }) =>
    actionHandler(() => addInvalidContentEndpoint(data), rejectWithValue),
);

export const removeInvalidContent = createAsyncThunk(
  ActionTypes.REMOVE_INVALID_CONTENT,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => removeInvalidContentEndpoint(id), rejectWithValue),
);

export const editInvalidContent = createAsyncThunk(
  ActionTypes.EDIT_INVALID_CONTENT,
  async (data: IInvalidContent, { rejectWithValue }) =>
    actionHandler(() => editInvalidContentEndpoint(data), rejectWithValue),
);

export const getComplaintById = createAsyncThunk(
  ActionTypes.GET_COMPLAINT_BY_ID,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => getComplaintByIdEndpoint(id), rejectWithValue),
);

export const getComplaintsByRelationId = createAsyncThunk(
  ActionTypes.GET_COMPLAINT_BY_RELATION_ID,
  async (relationId: string, { rejectWithValue }) =>
    actionHandler(
      () => getComplaintsByRelationIdEndpoint(relationId),
      rejectWithValue,
    ),
);

import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Title,
} from 'components/features/business/containers/item/update/components';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { TabLayout } from 'components/elements/tabs';
import { Loader } from 'components/shared/loader';
import { CommonDataView } from 'components/features/business/shared/item/view/commonData/SoftwareCommonData';
import { URLS } from 'defenitions/routes';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useTranslation } from 'react-i18next';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { getLocaleValue } from 'utils/form';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import {
  selectSoftwareCard,
  selectSoftwareCardLoading,
} from 'store/features/software';
import { getSoftwareCard } from 'store/features/software/actions';
import { getCurrent } from 'store/features/users';
import { CodeExamples } from 'components/features/business/shared/item/view/codeExample';
import { SoftwareReviewsData } from 'components/features/business/shared/item/view/reviewsData/SoftwareReviews';
import { useQuery } from 'hooks/useQuery';
import Snackbar from 'services/Snackbar';

export const ViewSoftwareCard = () => {
  const navigate = useNavigate();
  const { addReview } = useQuery();
  const account = useAppSelector(getCurrent);
  const [tab, setTab] = useState(addReview ? 2 : 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const { id } = useParams();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
  const { isOpen, onClickOpen, onClickClose } = useModalActions();
  const dispatch = useAppDispatch();
  const softwareCard = useAppSelector(selectSoftwareCard);
  const isSoftwareCardLoading = useAppSelector(selectSoftwareCardLoading);

  const onClickCancel = () => navigate(-1);
  const onClickNext = () => setTab((prevState) => prevState + 1);
  const onClickBack = () => setTab((prevState) => prevState - 1);
  const isCreatedByCurrentAccount = account?.id === softwareCard?.creatorId;

  const handleClickEdit = useCallback(() => {
    navigate(`${URLS.software}/${id}/edit`);
  }, [id, navigate]);

  useEffect(() => {
    id && dispatch(getSoftwareCard(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (softwareCard?.hidden && !softwareCard?.cardBelongToCurrentAccount) {
      Snackbar.show(t('hidden card'), 'warning');
      navigate({
        pathname: URLS.software,
        search: `?${createSearchParams({
          tab: 'All',
        })}`,
      });
    }
  }, [navigate, softwareCard, t]);

  return (
    <Container>
      {isSoftwareCardLoading && <Loader show global />}
      <Title>{getLocaleValue(softwareCard?.name)}</Title>
      <TabLayout
        onMouseLeave={() => setAnchorEl(null)}
        tab={tab}
        disabled={false}
        onChangeTabs={handleChange}
        tabs={[
          {
            label: t('tabs.common data'),
            component: (
              <CommonDataView
                softwareCard={softwareCard}
                onClickCancel={onClickCancel}
                onClickNext={onClickNext}
              />
            ),
          },
          ...(softwareCard?.hidden && !softwareCard?.cardBelongToCurrentAccount
            ? []
            : [
                {
                  label: t('tabs.code example'),
                  component: (
                    <CodeExamples
                      codeExamples={softwareCard?.codeExamples || []}
                      onClickNext={onClickNext}
                      onClickBack={onClickBack}
                    />
                  ),
                },
              ]),
          ...(softwareCard?.hidden && !softwareCard?.cardBelongToCurrentAccount
            ? []
            : [
                {
                  label: t('tabs.reviews'),
                  component: (
                    <SoftwareReviewsData
                      softwareCard={softwareCard}
                      onClickCancel={onClickCancel}
                      onClickBack={onClickBack}
                    />
                  ),
                },
              ]),
        ]}
      />
      <MoreIcon
        style={{ top: 120 }}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      />
      {anchorEl && (
        <MoreMenu
          anchorEl={anchorEl}
          onClickEdit={isCreatedByCurrentAccount ? handleClickEdit : undefined}
          onClickComplaint={
            !isCreatedByCurrentAccount ? onClickOpen : undefined
          }
        />
      )}
      {id && (
        <ConfirmDialog
          onClickClose={onClickClose}
          open={isOpen}
          title={t('modalTexts.complaint.title', {
            name: getLocaleValue(softwareCard?.name),
          })}
        >
          <CreateComplaint
            relationId={id}
            complaintAboutType={ComplaintAboutType.SOFTWARE_CARD}
            additionAction={onClickClose}
          />
        </ConfirmDialog>
      )}
    </Container>
  );
};

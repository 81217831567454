import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { IOfferServicesStats } from 'interfaces/Stats';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';
import { StatsTable } from 'components/elements/table';
import {
  createHeadCellIncoming,
  createDataIncoming,
  createDataOutgoing,
  createHeadCellOutgoing,
} from 'components/features/stats/item/business/tables/offerServices/table/utils';

export const OfferServicesStats = ({
  offerServices,
}: {
  offerServices: IOfferServicesStats;
}) => {
  const { t } = useTranslation();

  return (
    <StatsCollapseLayout title={t('tables.tableOfferServices')}>
      <Grid item container>
        <StatsTable
          title={t('tables.tableIncomingOffers')}
          data={createDataIncoming(offerServices.incomingOffers)}
          columns={createHeadCellIncoming()}
        />
        <StatsTable
          title={t('tables.tableOutgoingOffers')}
          data={createDataOutgoing(offerServices.outgoingOffers)}
          columns={createHeadCellOutgoing()}
        />
      </Grid>
    </StatsCollapseLayout>
  );
};

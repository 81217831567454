import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const Container = styled(Grid)({
  background: '#FFFFFF',
  border: '1px solid #E4E4E4',
  borderRadius: '5px 5px 0px 0px',
  position: 'relative',
});

export const Divider = styled(Grid)({
  background: '#C9D1D7',
  borderRadius: 8,
  height: 1,
  width: '100%',
  marginBottom: 20,
});
export const TitleContainer = styled(Grid)({
  display: 'flex',
  position: 'relative',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 24px',
});

export const ContentContainer = styled(Grid)({
  padding: '0px 20px 40px 40px',
});

export const Title = styled(Grid)({
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '29px',
  color: '#00618E',
});

export const LastLoginTextContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: 17,
});

export const LastLoginText = styled('div')({
  color: '#A9A9A9',
  fontWeight: 40,
  fontSize: 10,
  lineHeight: '12px',
});

export const FormActions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '30%',
  width: '100%',
  marginTop: '15%',
});

export const Form = styled('form')({
  background: '#FFFFFF',
  padding: '28px 54px',
});

import React, { useCallback, useEffect, useState } from 'react';
import { RouteNames, URLS } from 'defenitions/routes';
import { TabLayout } from 'components/elements/tabs';
import { ServiceDataForm } from 'components/features/business/shared/item/edit/serviceData';
import { BusinessCommonDataForm } from 'components/features/business/shared/item/edit/BusinessCommonData';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BusinessStatus,
  IBusiness,
  IBusinessService,
} from 'interfaces/Business';
import { commonDataValidationSchema } from 'components/features/business/shared/item/edit/BusinessCommonData/validationSchema';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  createBusinessCard,
  getBusinessCard,
  updateBusinessCard,
  uploadBusinessCardAttachments,
  uploadBusinessCardLogo,
} from 'store/features/business/actions';
import {
  Container,
  Title,
} from 'components/features/business/containers/item/update/components';
import {
  getCardTypeDictionariesContent,
  getServiceTypeDictionariesContent,
} from 'store/features/dictionaries';
import {
  getDictionariesContentCardType,
  getDictionariesContentServiceType,
} from 'store/features/dictionaries/actions';
import { IBusinessAttachment } from 'interfaces/Attachment';
import { AxiosResponse } from 'axios';
import {
  getBusinessCardItem,
  getBusinessCardLoading,
} from 'store/features/business';
import { Loader } from 'components/shared/loader';
import Snackbar from 'services/Snackbar';
import { transformBusinessDataForm } from 'utils/business';
import { IAPIResponseError } from 'interfaces/APIResponseError';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { useTranslation } from 'react-i18next';
import { getAccount } from 'store/features/users';
import { Types } from 'interfaces/Users';
import { getAccountCurrent } from '../../../../../../store/features/users/actions';

export const UpdateBusinessCard = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { type, owner } = useAppSelector(getAccount) || {};
  const businessCard = useAppSelector(getBusinessCardItem);
  const isBusinessCardLoading = useAppSelector(getBusinessCardLoading);
  const cardTypes = useAppSelector(getCardTypeDictionariesContent);
  const serviceTypes = useAppSelector(getServiceTypeDictionariesContent);
  const [logo, setLogo] = useState<IBusinessAttachment>();
  const [attachments, setAttachments] = useState<IBusinessAttachment[]>(
    businessCard?.attachments || [],
  );

  const [businessServices, setBusinessServices] = useState<IBusinessService[]>(
    businessCard?.services || [],
  );
  const onAddBusinessService = (data: IBusinessService) =>
    setBusinessServices((prevState) => [...prevState, data]);
  const onRemoveBusinessService = (deleteIndex: number) => {
    setBusinessServices((prevState) =>
      prevState.filter((_, index) => deleteIndex !== index),
    );
  };
  const onEditBusinessService = (data: IBusinessService, index: number) => {
    const editedBusinessService = [...businessServices];
    editedBusinessService[index] = { ...data };
    setBusinessServices(editedBusinessService);
  };

  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const onClickOpenConfirm = () => setIsOpenConfirm(true);
  const onClickCloseConfirm = () => setIsOpenConfirm(false);
  const onClickCancel = () => navigate(URLS.business);
  const onClickNext = () => setTab(1);
  const onClickBack = () => setTab(0);
  const { control, handleSubmit, formState, trigger, reset, setValue } =
    useForm<IBusiness>({
      mode: 'onChange',
      resolver: yupResolver(commonDataValidationSchema),
    });
  const cardType = useWatch({ name: 'cardType', control });

  const handleSave: SubmitHandler<IBusiness> = async (data) => {
    const updateData = transformBusinessDataForm(
      data,
      logo,
      attachments,
      businessServices,
      BusinessStatus.PUBLISHED,
      data.cardType?.code || null,
    );
    const response = id
      ? await dispatch(updateBusinessCard(updateData))
      : await dispatch(createBusinessCard(updateData));
    const payload = response.payload as IAPIResponseError;
    if (payload.success !== false) {
      navigate(URLS.business);
      Snackbar.show('Данные сохранены', 'success');
      dispatch(getAccountCurrent());
    }
  };

  const handleSaveAsDraft: SubmitHandler<IBusiness> = async (data) => {
    const updateData = transformBusinessDataForm(
      data,
      logo,
      attachments,
      businessServices,
      BusinessStatus.DRAFT,
      data.cardType?.code || null,
    );
    const response = id
      ? await dispatch(updateBusinessCard(updateData))
      : await dispatch(createBusinessCard(updateData));
    const payload = response.payload as AxiosResponse;
    if (payload.success !== false) {
      navigate(`${URLS.business}/${payload.data.id}/edit`);
      Snackbar.show('Данные сохранены', 'success');
    }
  };

  const handleCardTypeLoadOptions = useCallback(() => {
    dispatch(getDictionariesContentCardType(type));
  }, [dispatch, type]);

  const handleServiceTypeLoadOptions = useCallback(() => {
    dispatch(getDictionariesContentServiceType());
  }, [dispatch]);

  const handleUploadLogo = useCallback(
    async (formData: FormData) => {
      const { payload } = await dispatch(uploadBusinessCardLogo(formData));
      const { data } = payload as AxiosResponse<IBusinessAttachment>;
      setLogo(data);
    },
    [dispatch],
  );

  const handleDeleteLogo = () => setLogo(undefined);

  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      const { payload } = await dispatch(
        uploadBusinessCardAttachments(formData),
      );
      const { data } = payload as AxiosResponse<IBusinessAttachment[]>;
      data && setAttachments((prevState) => [...prevState, ...data]);
    },
    [dispatch],
  );

  const handleRemoveAttachments = (id: string) =>
    setAttachments((prevState) => prevState.filter((el) => el.id !== id));

  const name = useWatch({ name: 'name', control });
  const address = useWatch({ name: 'address', control });
  useEffect(() => {
    trigger();
  }, [trigger, name, address]);

  useEffect(() => {
    handleCardTypeLoadOptions();
    handleServiceTypeLoadOptions();
    if (id) {
      dispatch(getBusinessCard(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const defaultValues = id ? { ...businessCard } : {};
    reset(defaultValues);
    setAttachments(id ? businessCard?.attachments || [] : []);
    setLogo(id ? businessCard?.logo : undefined);
    setBusinessServices(id ? businessCard?.services || [] : []);
  }, [businessCard, reset, id]);

  useEffect(() => {
    if (!id && owner && type === Types.PERSON) {
      reset({
        contactPerson: {
          ru: `${owner.lastName} ${owner.name}`,
          en: `${owner.lastName} ${owner.name}`,
        },
      });
    }
  }, [id, owner, reset, setValue, trigger, type]);

  useEffect(() => {
    if (!id && type === Types.PERSON && cardType) {
      setValue('name', cardType.display);
    }
  }, [cardType, id, setValue, type]);
  return (
    <Container>
      {isBusinessCardLoading && <Loader show global />}
      <Title>
        {id ? businessCard?.name.ru : RouteNames[URLS.createBusinessCard]}
      </Title>
      <form>
        <TabLayout
          tab={tab}
          disabled={!formState.isValid}
          onChangeTabs={handleChange}
          tabs={[
            {
              label: 'Общая информация',
              component: (
                <BusinessCommonDataForm
                  isUpdate={
                    !!(
                      id &&
                      (businessCard?.status === BusinessStatus.PUBLISHED ||
                        (businessCard && businessCard?.publishedDateTime > 0))
                    )
                  }
                  control={control}
                  formState={formState}
                  onClickNext={onClickNext}
                  onClickCancel={onClickOpenConfirm}
                  onClickSaveDraft={handleSaveAsDraft}
                  handleSubmit={handleSubmit}
                  cardTypes={cardTypes}
                  onLoadCardTypes={handleCardTypeLoadOptions}
                  onUploadLogo={handleUploadLogo}
                  onDeleteLogo={handleDeleteLogo}
                  onRemoveAttachments={handleRemoveAttachments}
                  logo={logo}
                  attachments={attachments}
                  onUploadAttachments={handleUploadAttachments}
                />
              ),
            },
            {
              label: 'Оказываемые услуги',
              component: (
                <ServiceDataForm
                  isUpdate={
                    !!(
                      id &&
                      (businessCard?.status === BusinessStatus.PUBLISHED ||
                        (businessCard && businessCard?.publishedDateTime > 0))
                    )
                  }
                  onClickBack={onClickBack}
                  onClickSaveDraft={handleSaveAsDraft}
                  onClickSave={handleSave}
                  handleSubmit={handleSubmit}
                  formState={formState}
                  serviceTypes={serviceTypes}
                  onLoadServiceTypes={handleServiceTypeLoadOptions}
                  businessServices={businessServices}
                  onAddBusinessService={onAddBusinessService}
                  onRemoveBusinessService={onRemoveBusinessService}
                  onEditBusinessService={onEditBusinessService}
                />
              ),
            },
          ]}
        />
      </form>
      <ConfirmDialog
        onClickAccept={onClickCancel}
        onClickClose={onClickCloseConfirm}
        open={isOpenConfirm}
        actionTitle={t('modalTexts.business.actionTitle')}
        text={t('modalTexts.business.text')}
        title={t('modalTexts.business.title')}
      />
    </Container>
  );
};

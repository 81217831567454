import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionHandler } from 'store/utils/actionHandler';
import { ActionTypes } from 'store/features/news/config';
import {
  changeReactionEndpoint,
  createCommentEndpoint,
  createNewEndpoint,
  deleteNewsCommentEndpoint,
  deleteNewsEndpoint,
  editCommentEndpoint,
  getCommentsEndpoint,
  getImportantNewsEndpoint,
  getNewsEndpoint,
  getSubscriptionsEndpoint,
  unsubscribeEndpoint,
  uploadAttachmentsEndpoint,
} from 'store/features/news/api';
import { NewsFilters, ISubscriptionFilters } from 'interfaces/NewsFilters';
import {
  ICreateComment,
  ICreateNew,
  INews,
  IEditComment,
  ISubscription,
} from 'interfaces/News';
import { AttachmentType } from 'interfaces/Attachment';
import { RootState } from 'store';
import { ReactionType } from 'interfaces/Post';

export const getNews = createAsyncThunk(
  ActionTypes.GET_NEWS,
  async (
    {
      isSystemAccount,
      filters,
    }: { filters: NewsFilters; isSystemAccount?: boolean },
    { rejectWithValue },
  ) =>
    actionHandler(
      () => getNewsEndpoint(filters, isSystemAccount),
      rejectWithValue,
    ),
);

export const updateNewsList = createAsyncThunk(
  ActionTypes.UPDATE_NEWS_LIST,
  async (
    { isSystemAccount }: { isSystemAccount?: boolean },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as RootState;
    const filters = state.news.list.filters;
    const length = state.news.list.news.length;

    return actionHandler(
      () =>
        getNewsEndpoint(
          { ...filters, offset: 0, limit: length },
          isSystemAccount,
        ),
      rejectWithValue,
    );
  },
);

export const getImportantNews = createAsyncThunk(
  ActionTypes.GET_IMPORTANT_NEWS,
  async (filters: NewsFilters, { rejectWithValue }) =>
    actionHandler(() => getImportantNewsEndpoint(filters), rejectWithValue),
);

export const updateImportantNewsList = createAsyncThunk(
  ActionTypes.UPDATE_IMPORTANT_NEWS_LIST,
  async (_: void, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const filters = state.news.list.filters;
    const length = state.news.list.news.length;

    return actionHandler(
      () => getImportantNewsEndpoint({ ...filters, offset: 0, limit: length }),
      rejectWithValue,
    );
  },
);

export const uploadAttachments = createAsyncThunk(
  ActionTypes.UPLOAD_ATTACHMENTS_NEWS,
  async (
    data: { formData: FormData; type: AttachmentType },
    { rejectWithValue },
  ) => actionHandler(() => uploadAttachmentsEndpoint(data), rejectWithValue),
);

export const createNew = createAsyncThunk(
  ActionTypes.CREATE_NEW,
  async (formData: ICreateNew, { rejectWithValue }) =>
    actionHandler(() => createNewEndpoint(formData), rejectWithValue),
);

export const createComment = createAsyncThunk(
  ActionTypes.CREATE_COMMENT,
  async (formData: ICreateComment, { rejectWithValue }) =>
    actionHandler(() => createCommentEndpoint(formData), rejectWithValue),
);

export const editComment = createAsyncThunk(
  ActionTypes.EDIT_COMMENT,
  async (formData: IEditComment, { rejectWithValue }) =>
    actionHandler(() => editCommentEndpoint(formData), rejectWithValue),
);

export const changeReaction = createAsyncThunk(
  ActionTypes.CHANGE_REACTION,
  async (
    { id, type }: { id: string; type: ReactionType },
    { rejectWithValue },
  ) => actionHandler(() => changeReactionEndpoint(id, type), rejectWithValue),
);

export const getComments = createAsyncThunk(
  ActionTypes.GET_COMMENTS,
  async (newId: INews['id'], { rejectWithValue }) =>
    actionHandler(() => getCommentsEndpoint(newId), rejectWithValue),
);

export const getSubscriptions = createAsyncThunk(
  ActionTypes.GET_SUBSCRIPTIONS,
  async (filters: ISubscriptionFilters, { rejectWithValue }) =>
    actionHandler(() => getSubscriptionsEndpoint(filters), rejectWithValue),
);

export const unsubscribe = createAsyncThunk(
  ActionTypes.UNSUBSCRIBE,
  async (id: ISubscription['id'], { rejectWithValue }) =>
    actionHandler(() => unsubscribeEndpoint(id), rejectWithValue),
);

export const deleteNews = createAsyncThunk(
  ActionTypes.DELETE_NEWS,
  async (newsId: string, { rejectWithValue }) =>
    actionHandler(() => deleteNewsEndpoint(newsId), rejectWithValue),
);

export const deleteNewsComment = createAsyncThunk(
  ActionTypes.DELETE_NEWS_COMMENT,
  async (
    { commentId }: { newsId: string; commentId: string },
    { rejectWithValue },
  ) =>
    actionHandler(() => deleteNewsCommentEndpoint(commentId), rejectWithValue),
);

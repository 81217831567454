import React, { useCallback, useState } from 'react';
import qs from 'qs';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { Container, Title, Value, WebSiteLink } from './components';
import { IBusiness } from 'interfaces/Business';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import { replaceURL } from 'utils/urls';
import { attachUrl } from 'services/ApiClient';
import { QuestionsFilters } from 'interfaces/QuestionsFilters';
import { useAppDispatch, useAppSelector } from 'store';
import {
  subscribeBusinessCards,
  unsubscribeBusinessCards,
} from 'store/features/business/actions';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { useModalActions } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { getLocaleValue } from 'utils/form';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { getAccount } from 'store/features/users';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { ReactComponent as ViewIcon } from 'assets/icons/viewIcon.svg';

type Props = {
  business: IBusiness;
  isOwner?: boolean;
  isAll?: boolean;
};

export const BusinessCard = ({ business, isOwner, isAll }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const account = useAppSelector(getAccount);

  const {
    id,
    countQuestions,
    subscriptionId,
    name,
    logo,
    cardType,
    address,
    website,
    viewsCounter,
    status,
    rate,
  } = business;
  const showViewQuestions = Boolean(isAll && countQuestions);

  const showCreateQuestion = isAll;

  const { isOpen, onClickOpen, onClickClose } = useModalActions();

  const handleClickEdit = useCallback(() => {
    navigate(`${URLS.business}/${id}/edit`);
  }, [id, navigate]);

  const handleClickViewQuetions = useCallback(() => {
    const filters: QuestionsFilters = { businessCardIds: [id] };
    navigate(`${URLS.incomingQuestions}?${qs.stringify(filters)}`);
  }, [id, navigate]);

  const handleClickCreateQuetion = useCallback(() => {
    navigate(`${URLS.createQuestion}/${id}`);
  }, [id, navigate]);

  const handleClickStats = useCallback(() => {
    navigate(`${URLS.stats}/business/${id}`);
  }, [id, navigate]);

  const handleClickView = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      navigate(`${URLS.business}/${id}/view`);
    },
    [id, navigate],
  );

  const subscribeBusiness = useCallback(() => {
    dispatch(subscribeBusinessCards(id));
    setAnchorEl(null);
  }, [id, dispatch]);

  const unsubscribeBusiness = useCallback(() => {
    subscriptionId && dispatch(unsubscribeBusinessCards(subscriptionId));
    setAnchorEl(null);
  }, [subscriptionId, dispatch]);

  return (
    <Container container item xs={12} onMouseLeave={() => setAnchorEl(null)}>
      <Grid item container xs={2} alignItems={'center'}>
        <Avatar
          alt="Avatar"
          src={logo?.path ? `${attachUrl}${logo?.path}` : ''}
          sx={{ width: 80, height: 80 }}
        />
      </Grid>
      <Grid item container xs={7}>
        <Grid item xs={4}>
          <Title>Наименование:</Title>
        </Grid>
        <Grid item xs={8}>
          <Value>{getLocaleValue(name)}</Value>
        </Grid>
        <Grid item xs={4}>
          <Title>Тип:</Title>
        </Grid>
        <Grid item xs={8}>
          <Value>{getLocaleValue(cardType?.display)}</Value>
        </Grid>
        <Grid item xs={4}>
          <Title>Адрес:</Title>
        </Grid>
        <Grid item xs={8}>
          <Value>{getLocaleValue(address)}</Value>
        </Grid>
        <Grid item xs={4}>
          <Title>{`${t('rating')}:`}</Title>
        </Grid>
        <Grid item xs={8}>
          <Value>{rate}</Value>
        </Grid>
        {!isAll && (
          <>
            <Grid item xs={4}>
              <Title>Статус:</Title>
            </Grid>
            <Grid item xs={8}>
              <Value>{t(`businessCardStatuses.${status}`)}</Value>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container xs={3} alignContent={'flex-start'}>
        <Grid item xs={12}>
          <Title>Веб сайт:</Title>
        </Grid>
        <Grid item xs={12}>
          <WebSiteLink href={replaceURL(website)} target={'_blank'}>
            {website}
          </WebSiteLink>
        </Grid>
      </Grid>
      <Grid item container xs={12} mt={2}>
        <InteractiveButton
          variant="stroke"
          IconComponent={ViewIcon}
          count={viewsCounter.uniqueUsers}
          label={t('views')}
          active={viewsCounter.viewedByCurrentUser}
        />
      </Grid>
      <MoreIcon
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      />
      {anchorEl && (
        <MoreMenu
          anchorEl={anchorEl}
          onClickView={handleClickView}
          onClickEdit={!isAll ? handleClickEdit : undefined}
          onClickViewQuestions={
            showViewQuestions ? handleClickViewQuetions : undefined
          }
          onClickCreateQuestion={
            showCreateQuestion ? handleClickCreateQuetion : undefined
          }
          onClickSubscribe={
            !account?.isSystemAccount && isAll && !subscriptionId
              ? subscribeBusiness
              : undefined
          }
          onClickUnsubscribe={
            !account?.isSystemAccount && subscriptionId
              ? unsubscribeBusiness
              : undefined
          }
          onClickComplaint={isAll ? onClickOpen : undefined}
          onClickStats={!isAll && isOwner ? handleClickStats : undefined}
        />
      )}
      <ConfirmDialog
        onClickClose={onClickClose}
        open={isOpen}
        title={t('modalTexts.complaint.title', {
          name: getLocaleValue(name),
        })}
      >
        <CreateComplaint
          relationId={id}
          complaintAboutType={ComplaintAboutType.BUSINESS_CARD}
          additionAction={onClickClose}
        />
      </ConfirmDialog>
    </Container>
  );
};

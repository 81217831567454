import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { INews } from 'interfaces/Stats';
import { getLocaleValue } from 'utils/form';

export const createHeadCell = (): HeadCell[] => {
  return [
    { id: 'name', label: i18n.t('tables.name'), numeric: false },
    {
      id: 'comments',
      label: i18n.t('tables.comments'),
      numeric: true,
    },
    {
      id: 'reactions',
      label: i18n.t('tables.reactions'),
      numeric: true,
    },
  ];
};

export const createData = (data: INews[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.newsId,
      name: getLocaleValue(el.name),
    };
  });

import axios from 'axios';
import { authService } from 'services/AuthService';

export const attachUrl = 'https://labelcoding.ru';
export const baseURL = 'https://labelcoding.ru/webapi';

export const apiClient = axios.create({
  baseURL,
  responseType: 'json',
  withCredentials: true,
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response?.status;
    if (status === 401) {
      authService.signOut();
      window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  },
);

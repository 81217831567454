import * as yup from 'yup';
import {
  ErrorMessages,
  MAX_LENGTH,
  MAX_LENGTH_DESCRIPTION,
} from 'defenitions/errorMessages';

export const serviceDataValidationSchema = yup.object({
  serviceType: yup.object().required(ErrorMessages.required),
  name: yup
    .object({
      ru: yup
        .string()
        .required(ErrorMessages.required)
        .max(MAX_LENGTH, ErrorMessages.maxLength(MAX_LENGTH)),
      en: yup.string().max(MAX_LENGTH, ErrorMessages.maxLength(MAX_LENGTH)),
    })
    .required(ErrorMessages.required),
  description: yup
    .object({
      ru: yup
        .string()
        .required(ErrorMessages.required)
        .max(
          MAX_LENGTH_DESCRIPTION,
          ErrorMessages.maxLength(MAX_LENGTH_DESCRIPTION),
        ),
      en: yup
        .string()
        .max(
          MAX_LENGTH_DESCRIPTION,
          ErrorMessages.maxLength(MAX_LENGTH_DESCRIPTION),
        ),
    })
    .required(ErrorMessages.required),
});

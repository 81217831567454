import React, { MouseEvent, useState } from 'react';
import { styled } from '@mui/system';
import { Avatar, Box, Typography } from '@mui/material';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { ReactComponent as ViewIcon } from 'assets/icons/viewIcon.svg';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import { getNameInitials } from 'utils/user';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { Question, QuestionStatus } from 'interfaces/Question';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { attachUrl } from 'services/ApiClient';
import { ReactionType } from 'interfaces/Post';
import { Editor } from 'components/shared/editor';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'defenitions/routes';

const Container = styled('div')({
  padding: '8px 16px',
  position: 'relative',
});

const Inner = styled('div')({
  display: 'flex',
  gap: 16,
  marginBottom: 16,
});

const UserAvatar = styled(Avatar)({
  height: 55,
  width: 55,
});

const User = styled('div')({
  flex: 1,
});

const Creator = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginBottom: 4,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const Created = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginBottom: 4,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const Receiver = styled('div')({
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const Topic = styled('div')({
  marginBottom: 8,
  fontWeight: '500',
  fontSize: 14,
  color: '#00618e',
  textDecoration: 'underline',
});

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  borderTop: '1px solid #ddd',
  paddingTop: 12,
  paddingBottom: 12,
});

const Colors = {
  [QuestionStatus.ACTIVE]: '#165209',
  [QuestionStatus.CLOSED]: '#8e8e8e',
} as const;

type Props = {
  mine?: boolean;
  question: Question;
  onLike?: (id: string) => void;
  onDislike?: (id: string) => void;
};

export const DetailedQuestionCard = ({
  mine = false,
  question,
  onLike,
  onDislike,
}: Props) => {
  const { t } = useTranslation();

  const {
    attachments = [],
    businessCard,
    content,
    createdDateTime,
    creator,
    id,
    reactions,
    receiverBusinessCard,
    receiverSoftwareCard,
    status,
    title,
    viewsCounter,
    softwareCards,
  } = question;

  const avatar = businessCard?.logo && `${attachUrl}${businessCard.logo.path}`;
  const company = businessCard?.name?.ru || businessCard?.name?.en || '';
  const contentText = content.ru || content.en;
  const createdDate = new Date(createdDateTime);
  const creatorName = `${creator.name} ${creator.lastName}, #${creator.reputation}`;
  const topic = title.ru || title.en;

  const receiverBusiness =
    receiverBusinessCard?.name.ru || receiverBusinessCard?.name.ru;

  const receiverSoftware =
    receiverSoftwareCard?.name.ru || receiverSoftwareCard?.name.ru;

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const created =
    differenceInDays(new Date(), createdDate) === 0
      ? formatDistanceToNowStrict(createdDate, { locale: ru })
      : format(createdDate, 'dd MMMM yyyy');

  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);
  const { isOpen, onClickOpen, onClickClose } = useModalActions();

  const handleLike = () => onLike?.(id);

  const handleDislike = () => onDislike?.(id);

  const handleClickMore = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <Container onMouseLeave={() => setAnchorEl(null)}>
      <Box
        sx={{
          position: 'absolute',
          top: (theme) => theme.spacing(1),
          right: (theme) => theme.spacing(2),
          fontWeight: 700,
          fontSize: 18,
          color: Colors[status],
        }}
      >
        {t(`QuestionStatus.${status}`)}
      </Box>
      <Inner>
        <UserAvatar src={avatar}>{getNameInitials(company)}</UserAvatar>
        <User>
          <Typography
            sx={{
              marginBottom: 4,
              fontFamily: 'Montserrat',
              fontWeight: '500',
              fontSize: 14,
              color: '#000',
            }}
            component={businessCard ? Link : 'span'}
            to={generatePath(URLS.viewBusinessCard, {
              id: businessCard?.id ?? 'deleted',
            })}
            target="_blank"
          >
            {company}
          </Typography>
          <Creator>
            <UserIcon />
            {creatorName}
          </Creator>
          <Created>
            <TimeIcon />
            {created}
          </Created>
          {receiverBusiness && (
            <Receiver>{`Получатель вопроса: ${receiverBusiness}`}</Receiver>
          )}
          {receiverSoftware && (
            <Receiver>{`Получатель ПО: ${receiverSoftware}`}</Receiver>
          )}
        </User>
      </Inner>
      {!!softwareCards?.length && (
        <Typography sx={{ mb: 1, fontWeight: 500, fontSize: 14 }}>
          {'ПО: '}
          {softwareCards.map((card, index) => (
            <>
              <Typography
                component={Link}
                key={card.id}
                sx={{ color: '#00618e', textDecoration: 'none' }}
                to={generatePath(URLS.viewSoftwareCard, { id: card.id })}
                variant="inherit"
              >
                {card.name.ru || card.name.en}
              </Typography>
              {index === softwareCards.length - 1 ? '' : ', '}
            </>
          ))}
        </Typography>
      )}
      <Topic>{topic}</Topic>
      <Box sx={{ mb: 3 }}>
        <Editor value={contentText} readOnly />
      </Box>
      {Boolean(attachments.length) && <Thumbs attachments={attachments} />}
      <Actions>
        <Box sx={{ flexBasis: '150px' }}>
          <InteractiveButton
            variant="stroke"
            IconComponent={ViewIcon}
            count={viewsCounter.uniqueUsers}
            label={t('views')}
            active={viewsCounter.viewedByCurrentUser}
          />
        </Box>
        <Box sx={{ flexBasis: '100px' }}>
          <InteractiveButton
            IconComponent={LikeIcon}
            count={likes}
            active={hasUserLikes}
            onClick={handleLike}
          />
        </Box>
        <Box sx={{ flexBasis: '100px' }}>
          <InteractiveButton
            IconComponent={DislikeIcon}
            count={dislikes}
            active={hasUserDislikes}
            onClick={handleDislike}
          />
        </Box>
      </Actions>
      {!mine && <MoreIcon onClick={handleClickMore} />}
      {anchorEl && (
        <MoreMenu anchorEl={anchorEl} onClickComplaint={onClickOpen} />
      )}
      <ConfirmDialog
        onClickClose={onClickClose}
        open={isOpen}
        title={t('modalTexts.complaint.title', {
          name: topic,
        })}
      >
        <CreateComplaint
          relationId={id}
          complaintAboutType={ComplaintAboutType.QUESTION}
          additionAction={onClickClose}
        />
      </ConfirmDialog>
    </Container>
  );
};

import {
  ISoftware,
  ISoftwareCardShort,
  ISoftwareFilters,
  ISoftwareReviewsFilters,
} from 'interfaces/Software';
import { IReview } from 'interfaces/Review';

export enum ActionTypes {
  GET_SOFTWARE = 'GET_SOFTWARE',
  GET_MY_SOFTWARE = 'GET_MY_SOFTWARE',
  SHOW_SOFTWARE = 'SHOW_SOFTWARE',
  HIDE_SOFTWARE = 'HIDE_SOFTWARE',
  GET_SOFTWARE_CARD = 'GET_SOFTWARE_CARD',
  GET_SOFTWARE_REVIEW = 'GET_SOFTWARE_REVIEW',
  CREATE_SOFTWARE_CARD = 'CREATE_SOFTWARE_CARD',
  UPDATE_SOFTWARE_CARD = 'UPDATE_SOFTWARE_CARD',
  UPLOAD_SOFTWARE_CARD_LOGO = 'UPLOAD_SOFTWARE_CARD_LOGO',
  UPLOAD_SOFTWARE_CARD_ATTACHMENTS = 'UPLOAD_SOFTWARE_CARD_ATTACHMENTS',
  CREATE_SOFTWARE_REVIEW = 'CREATE_SOFTWARE_REVIEW',
  GET_SOFTWARE_CARD_SHORT = 'GET_SOFTWARE_CARD_SHORT',
  GET_OTHERS_ACCOUNTS_SOFTWARE_CARDS = 'GET_OTHERS_ACCOUNTS_SOFTWARE_CARDS',
  GET_OTHERS_ACCOUNTS_SOFTWARE_CARDS_BY_BUSINESS_ID = 'GET_OTHERS_ACCOUNTS_SOFTWARE_CARDS_BY_BUSINESS_ID',
}

export const initialSoftwareFilters: ISoftwareFilters = {
  text: null,
  limit: 10,
  offset: 0,
};

export const initialSoftwareReviewFilters: ISoftwareReviewsFilters = {
  softwareCardId: '',
  limit: 10,
  offset: 0,
};

export interface ISoftwareState {
  list: {
    software: ISoftware[];
    filters: ISoftwareFilters;
    loading: boolean;
    hasMore: boolean;
  };
  item: {
    reviewsFilters: ISoftwareReviewsFilters;
    reviews: IReview[];
    card: ISoftware | null;
    loading: boolean;
  };
  activeSoftware: ISoftwareCardShort[];
  othersSoftware: ISoftwareCardShort[];
  othersSoftwareByBusinessId: ISoftwareCardShort[];
}
export const initialSoftwareState: ISoftwareState = {
  list: {
    software: [],
    filters: initialSoftwareFilters,
    loading: false,
    hasMore: false,
  },
  item: {
    reviews: [],
    reviewsFilters: initialSoftwareReviewFilters,
    card: null,
    loading: false,
  },
  activeSoftware: [],
  othersSoftware: [],
  othersSoftwareByBusinessId: [],
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from 'store/features/users/config';
import { actionHandler } from 'store/utils/actionHandler';
import {
  getUserCurrentEndpoint,
  getAccountCurrentEndpoint,
  getUsersEndpoint,
  createUserEndpoint,
  updateUserEndpoint,
  deleteUserEndpoint,
  lockUserEndpoint,
  unlockUserEndpoint,
  uploadUserLogoEndpoint,
  inviteUserEndpoint,
  getAllUsersEndpoint,
  lockUserBySystemEndpoint,
  unlockUserBySystemEndpoint,
  getUsersActiveEndpoint,
  getUserEndpoint,
} from 'store/features/users/api';
import { ICreateUser, IUsersFilters } from 'interfaces/Users';
import { IMultiLanguage } from 'interfaces/Locale';

export const getUserCurrent = createAsyncThunk(
  ActionTypes.GET_USER_CURRENT,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getUserCurrentEndpoint(), rejectWithValue),
);

export const getAccountCurrent = createAsyncThunk(
  ActionTypes.GET_ACCOUNT_CURRENT,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getAccountCurrentEndpoint(), rejectWithValue),
);

export const getUsers = createAsyncThunk(
  ActionTypes.GET_USERS,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getUsersEndpoint(), rejectWithValue),
);

export const createUser = createAsyncThunk(
  ActionTypes.CREATE_USER,
  async (user: ICreateUser, { rejectWithValue }) =>
    actionHandler(() => createUserEndpoint(user), rejectWithValue),
);

export const updateUser = createAsyncThunk(
  ActionTypes.UPDATE_USER,
  async (user: ICreateUser, { rejectWithValue }) =>
    actionHandler(() => updateUserEndpoint(user), rejectWithValue),
);

export const deleteUser = createAsyncThunk(
  ActionTypes.DELETE_USER,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => deleteUserEndpoint(id), rejectWithValue),
);

export const lockUser = createAsyncThunk(
  ActionTypes.LOCK_USER,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => lockUserEndpoint(id), rejectWithValue),
);

export const unlockUser = createAsyncThunk(
  ActionTypes.UNLOCK_USER,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => unlockUserEndpoint(id), rejectWithValue),
);

export const inviteUser = createAsyncThunk(
  ActionTypes.INVITE_USER,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => inviteUserEndpoint(id), rejectWithValue),
);

export const uploadUserLogo = createAsyncThunk(
  ActionTypes.UPLOAD_LOGO,
  async (formData: FormData, { rejectWithValue }) =>
    actionHandler(() => uploadUserLogoEndpoint(formData), rejectWithValue),
);

export const getAllUsers = createAsyncThunk(
  ActionTypes.GET_ALL_USERS,
  async (filters: IUsersFilters, { rejectWithValue }) =>
    actionHandler(() => getAllUsersEndpoint(filters), rejectWithValue),
);

export const lockUserBySystem = createAsyncThunk(
  ActionTypes.LOCK_USER_BY_SYSTEM,
  async (
    {
      accountId,
      id,
      reason,
    }: {
      accountId: string;
      id: string;
      reason: IMultiLanguage;
    },
    { rejectWithValue },
  ) =>
    actionHandler(
      () => lockUserBySystemEndpoint({ accountId, id, reason }),
      rejectWithValue,
    ),
);

export const unlockUserBySystem = createAsyncThunk(
  ActionTypes.UNLOCK_USER_BY_SYSTEM,
  async (
    {
      accountId,
      id,
    }: {
      accountId: string;
      id: string;
    },
    { rejectWithValue },
  ) =>
    actionHandler(
      () => unlockUserBySystemEndpoint({ accountId, id }),
      rejectWithValue,
    ),
);

export const getActiveUsers = createAsyncThunk(
  ActionTypes.GET_ACTIVE_USERS,
  async (filters: IUsersFilters, { rejectWithValue }) =>
    actionHandler(() => getUsersActiveEndpoint(filters), rejectWithValue),
);

export const getUser = createAsyncThunk(
  ActionTypes.GET_USER,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => getUserEndpoint(id), rejectWithValue),
);

import React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactNode } from 'react';
import { CreateButton } from 'components/shared/buttons/CreateButton';

type Props = {
  children: ReactNode;
  title: string;
  onClickAccept: () => void;
  open: boolean;
  handleClose: () => void;
  disabled: boolean;
  saveButtonTitle?: string;
};
export const Modal = ({
  title,
  onClickAccept,
  children,
  handleClose,
  open,
  disabled,
  saveButtonTitle = 'Сохранить',
}: Props) => {
  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      keepMounted={false}
    >
      <DialogTitle
        sx={{
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '29px',
          color: '#3D3D3D',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Box>{children}</Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: 330,
          alignSelf: 'center',
        }}
      >
        <CreateButton fullWidth onClick={handleClose} text={'Отмена'} />
        <CreateButton
          fullWidth
          disabled={disabled}
          type={'submit'}
          onClick={onClickAccept}
          text={saveButtonTitle}
        />
      </DialogActions>
    </Dialog>
  );
};

import React, { useState } from 'react';
import moment from 'moment';
import { ReactComponent as TimeFilterIcon } from 'assets/icons/timeFilterIcon.svg';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, InputBase, Popover, styled } from '@mui/material';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { TimePicker } from './TimePicker';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/min/locales.min';

moment.locale('ru');

const Root = styled('div')({});

const TitleContainer = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 4,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: 12,
  color: '#000',
  textTransform: 'none',
  boxShadow: 'none',
});

const Title = styled('div')({
  paddingLeft: 8,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '15px',
});

const InputContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
  padding: '2px 4px',
  backgroundColor: '#f5f5f5',
});

const PopoverContainer = styled(Box)({
  '& .CalendarDay__selected_span': {
    backgroundColor: '#eaf2fd',
    color: '#484848',
    border: '1px double #eaf2fd',
  },

  '& .CalendarDay__selected_span:hover': {
    backgroundColor: '#eaf2fd',
    color: '#484848',
    border: '1px double #eaf2fd',
  },

  '& .CalendarDay__hovered_span': {
    backgroundColor: '#eaf2fd',
    color: '#484848',
    border: '1px double #eaf2fd',
  },

  '& .CalendarDay__hovered_span:hover': {
    backgroundColor: '#eaf2fd',
    color: '#484848',
    border: '1px double #eaf2fd',
  },

  '& .CalendarDay__selected': {
    backgroundColor: '#2f80ed',
    color: '#fff',
    border: '1px double #2f80ed',
  },

  '& .CalendarDay__selected:active': {
    backgroundColor: '#2f80ed',
    color: '#fff',
    border: '1px double #2f80ed',
  },

  '& .CalendarDay__selected: hover': {
    backgroundColor: '#2f80ed',
    color: '#fff',
    border: '1px double #2f80ed',
  },
});

const ResetButton = styled('button')(({ theme }) => ({
  border: 0,
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
  fontWeight: 500,
  fontSize: 10,
  lineHeight: '12px',
  color: '#00618e',
  textDecoration: 'underline',
}));

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  onChangeStartDate?: (date: Date | null) => void;
  onChangeEndDate?: (date: Date | null) => void;
  onDateTimesChange?: (startDate: Date | null, endDate: Date | null) => void;
};

export const DateTimeRangeFilter = ({
  startDate,
  endDate,
  onDateTimesChange,
}: Props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const [focused, setFocused] = useState<FocusedInputShape>('startDate');

  const handleFocusedChange = (value: FocusedInputShape | null) => {
    setFocused(value ?? 'startDate');
  };

  const handleDatesChange = (args: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    let start = args.startDate ? args.startDate.startOf('day').toDate() : null;

    if (!start && args.endDate) {
      start = args.endDate.startOf('day').toDate();
    }

    let end = args.endDate ? args.endDate.startOf('day').toDate() : null;

    if (!end && args.startDate) {
      end = args.startDate.startOf('day').toDate();
    }

    onDateTimesChange?.(start, end);
  };

  const handleStartDateChange = (value: Date | null) => {
    const safeEndDate =
      value && endDate && value.getTime() > endDate.getTime() ? value : endDate;

    onDateTimesChange?.(value, safeEndDate);
  };

  const handleEndDateChange = (value: Date | null) => {
    onDateTimesChange?.(startDate, value);
  };

  const handleReset = () => onDateTimesChange?.(null, null);

  const start = startDate ? moment(startDate).format('DD.MM.YYYY HH:mm') : '';
  const end = endDate ? moment(endDate).format('DD.MM.YYYY HH:mm') : '';

  const id = Boolean(anchorEl) ? 'datetime-filters-popover' : undefined;

  return (
    <Root>
      <TitleContainer onClick={handleOpen}>
        <TimeFilterIcon />
        <Title>{t('filters.period.title')}</Title>
      </TitleContainer>
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <PopoverContainer sx={{ padding: 2, height: 420 }}>
          <Grid sx={{ mb: 2 }} container spacing={2}>
            <Grid item xs={6}>
              <InputContainer>
                <InputBase
                  sx={{ ml: 0.5, flex: 1 }}
                  value={start}
                  readOnly
                  inputProps={{
                    style: { height: 30, padding: 0 },
                  }}
                />
                {startDate && (
                  <TimePicker
                    date={startDate}
                    onChange={handleStartDateChange}
                  />
                )}
              </InputContainer>
            </Grid>
            <Grid item xs={6}>
              <InputContainer>
                <InputBase
                  sx={{ ml: 0.5, flex: 1 }}
                  value={end}
                  readOnly
                  inputProps={{
                    style: { height: 30, padding: 0 },
                  }}
                />
                {endDate && (
                  <TimePicker
                    minTime={startDate}
                    date={endDate}
                    onChange={handleEndDateChange}
                  />
                )}
              </InputContainer>
            </Grid>
          </Grid>
          <DayPickerRangeController
            focusedInput={focused}
            startDate={startDate ? moment(startDate) : null}
            endDate={endDate ? moment(endDate) : null}
            initialVisibleMonth={null}
            numberOfMonths={2}
            minimumNights={0}
            hideKeyboardShortcutsPanel
            onFocusChange={handleFocusedChange}
            onDatesChange={handleDatesChange}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <ResetButton onClick={handleReset}>
              {t('filters.period.reset')}
            </ResetButton>
          </Box>
        </PopoverContainer>
      </Popover>
    </Root>
  );
};

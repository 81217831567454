import React, { MouseEvent, useState } from 'react';
import { styled } from '@mui/system';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { ReactComponent as AcceptIcon } from 'assets/icons/accept.svg';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar, Box, Typography } from '@mui/material';
import { getNameInitials } from 'utils/user';
import { useModalActions } from 'utils/hooks';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { CreateComplaint } from 'components/features/complaint/containers/create';
import { ComplaintAboutType } from 'interfaces/Complaint';
import { useTranslation } from 'react-i18next';
import { MoreIcon, MoreMenu } from 'components/elements/moreMenu';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { Answer } from 'interfaces/Question';
import { useAppDispatch } from 'store';
import {
  editAnswer,
  uploadAnswerAttachments,
} from 'store/features/questions/actions';
import { AxiosResponse } from 'axios';
import { IBusinessAttachment } from 'interfaces/Attachment';
import { EditCommentForm } from 'components/elements/comment';
import { EditCommentFormData } from 'components/elements/comment/EditCommentForm';
import { editAnswerValidationSchema } from './validationSchemes';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import { attachUrl } from 'services/ApiClient';
import { ReactionType } from 'interfaces/Post';
import { Editor } from 'components/shared/editor';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'defenitions/routes';
import { AcceptDialog } from './AcceptDialog';

const Container = styled('div')({
  padding: '8px 16px',
  position: 'relative',
});

const Inner = styled('div')({
  display: 'flex',
  gap: 16,
  marginBottom: 16,
});

const UserAvatar = styled(Avatar)({
  height: 55,
  width: 55,
});

const User = styled('div')({
  flex: 1,
});

const Company = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  marginBottom: 4,
});

const Creator = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginBottom: 4,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const Created = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

const TextButton = styled('button')({
  padding: 0,
  border: 0,
  fontFamily: 'inherit',
  fontWeight: '400',
  fontSize: 14,
  color: '#00618e',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
});

type Props = {
  answer: Answer;
  editable?: boolean;
  mine?: boolean;
  showAcceptCheckbox?: boolean;
  onLike?: (id: string) => void;
  onDislike?: (id: string) => void;
  onAccept?: (id: string) => void;
};

export const AnswerCard = ({
  answer,
  editable = false,
  showAcceptCheckbox = false,
  mine = false,
  onAccept,
  onLike,
  onDislike,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const dispatch = useAppDispatch();

  const [editing, setEditing] = useState(false);

  const handleStartEditing = () => setEditing(true);

  const handleStopEditing = () => setEditing(false);

  const handleUploadAttachments = async (formData: FormData) => {
    const { payload } = await dispatch(uploadAnswerAttachments(formData));
    return (payload as AxiosResponse<IBusinessAttachment[]>).data;
  };

  const handleEditAnswer = (data: EditCommentFormData) => {
    const { attachments } = data;
    dispatch(editAnswer({ ...data, id, attachments }));
    handleStopEditing();
  };

  const handleClickMore = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const {
    accepted,
    attachments = [],
    businessCard,
    createdDateTime,
    id,
    reactions,
    updateDateTime,
    softwareCards,
  } = answer;

  const company =
    (answer.businessCard?.name.ru || answer.businessCard?.name.en) ?? 'Deleted';

  const creator = `${answer.creator.name} ${answer.creator.lastName}, #${answer.creator.reputation}`;
  const content = answer.content.ru || answer.content.en;
  const avatar = businessCard?.logo && `${attachUrl}${businessCard.logo.path}`;
  const createdDate = new Date(createdDateTime);
  const edited = createdDateTime !== updateDateTime;

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const created =
    differenceInDays(new Date(), createdDate) === 0
      ? formatDistanceToNowStrict(createdDate, { locale: ru })
      : format(createdDate, 'dd MMMM yyyy');

  const { isOpen, onClickOpen, onClickClose } = useModalActions();

  const acceptDialog = useModalActions(() => onAccept?.(id));

  const handleLike = () => onLike?.(id);

  const handleDislike = () => onDislike?.(id);

  if (editing) {
    return (
      <EditCommentForm
        comment={answer}
        validationSchema={editAnswerValidationSchema}
        uploadAttachments={handleUploadAttachments}
        onCancel={handleStopEditing}
        onSubmit={handleEditAnswer}
      />
    );
  }

  return (
    <Container onMouseLeave={() => setAnchorEl(null)}>
      <Inner>
        <UserAvatar src={avatar}>{getNameInitials(company)}</UserAvatar>
        <User>
          <Company>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontWeight: '500',
                fontSize: 14,
                color: '#000',
              }}
              component={businessCard ? Link : 'span'}
              to={generatePath(URLS.viewBusinessCard, {
                id: businessCard?.id ?? 'deleted',
              })}
              target="_blank"
            >
              {company}
            </Typography>
            {edited && <EditOutlinedIcon color="inherit" fontSize="inherit" />}
          </Company>
          <Creator>
            <UserIcon />
            {creator}
          </Creator>
          <Created>
            <TimeIcon />
            {created}
          </Created>
        </User>
      </Inner>
      {!!softwareCards?.length && (
        <Typography sx={{ mb: 1, fontWeight: 500, fontSize: 14 }}>
          {'ПО: '}
          {softwareCards.map((card, index) => (
            <>
              <Typography
                component={Link}
                key={card.id}
                sx={{ color: '#00618e', textDecoration: 'none' }}
                to={generatePath(URLS.viewSoftwareCard, { id: card.id })}
                variant="inherit"
              >
                {card.name.ru || card.name.en}
              </Typography>
              {index === softwareCards.length - 1 ? '' : ', '}
            </>
          ))}
        </Typography>
      )}
      <Editor sx={{ mb: 2 }} value={content} readOnly />
      {Boolean(attachments.length) && <Thumbs attachments={attachments} />}
      <Actions>
        <Box sx={{ flexBasis: '100px' }}>
          <InteractiveButton
            IconComponent={LikeIcon}
            count={likes}
            active={hasUserLikes}
            onClick={handleLike}
          />
        </Box>
        <Box sx={{ flexBasis: '100px' }}>
          <InteractiveButton
            IconComponent={DislikeIcon}
            count={dislikes}
            active={hasUserDislikes}
            onClick={handleDislike}
          />
        </Box>

        {editable && (
          <TextButton onClick={handleStartEditing}>{'Изменить'}</TextButton>
        )}

        {showAcceptCheckbox && !accepted && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              marginLeft: 'auto',
              color: '#4b4b4b',
              cursor: 'pointer',
            }}
            onClick={acceptDialog.onClickOpen}
          >
            <Typography
              sx={{
                marginRight: (theme) => theme.spacing(2),
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '16px',
                color: 'inherit',
                textDecoration: 'underline',
              }}
            >
              {'Принять'}
            </Typography>
            <AcceptIcon fill={'currentColor'} />
          </Box>
        )}

        {accepted && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              marginLeft: 'auto',
              color: '#00618e',
            }}
          >
            <Typography
              sx={{
                marginRight: (theme) => theme.spacing(2),
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '16px',
                color: 'inherit',
                textDecoration: 'underline',
              }}
            >
              {'Принят автором'}
            </Typography>
            <AcceptIcon fill={'currentColor'} />
          </Box>
        )}
      </Actions>
      {!mine && <MoreIcon onClick={handleClickMore} />}
      {anchorEl && (
        <MoreMenu anchorEl={anchorEl} onClickComplaint={onClickOpen} />
      )}
      <ConfirmDialog
        onClickClose={onClickClose}
        open={isOpen}
        title={t('modalTexts.complaint.title', {
          name: company,
        })}
      >
        <CreateComplaint
          relationId={id}
          complaintAboutType={ComplaintAboutType.COMMENT}
          additionAction={onClickClose}
        />
      </ConfirmDialog>
      <AcceptDialog
        open={acceptDialog.isOpen}
        onClickClose={acceptDialog.onClickClose}
        onClickAccept={acceptDialog.onClickSubmit}
      />
    </Container>
  );
};

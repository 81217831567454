import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { authSlice } from 'store/features/auth';
import { businessSlice } from 'store/features/business';
import { usersSlice } from 'store/features/users';
import { dictionariesSlice } from 'store/features/dictionaries';
import { questionsSlice } from 'store/features/questions';
import { newsSlice } from 'store/features/news';
import { marketplaceSlice } from 'store/features/marketplace';
import { complaintsSlice } from 'store/features/complaints';
import { settingsSlice } from 'store/features/settings';
import { notificationsSlice } from 'store/features/notifications';
import { statsSlice } from 'store/features/stats';
import { searchSlice } from 'store/features/search';
import { softwareSlice } from 'store/features/software';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  business: businessSlice.reducer,
  users: usersSlice.reducer,
  dictionaries: dictionariesSlice.reducer,
  news: newsSlice.reducer,
  questions: questionsSlice.reducer,
  marketplace: marketplaceSlice.reducer,
  complaints: complaintsSlice.reducer,
  settings: settingsSlice.reducer,
  notifications: notificationsSlice.reducer,
  stats: statsSlice.reducer,
  search: searchSlice.reducer,
  software: softwareSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store };

import React, { useEffect, useState } from 'react';
import { SettingLayout } from 'components/features/settings/components/layout';
import {
  Container,
  NotificationSettingTitle,
  Title,
} from 'components/features/settings/components';
import { useAppDispatch, useAppSelector } from 'store';
import { getNotifications } from 'store/features/settings';
import {
  changeNotificationSettings,
  getNotificationSettings,
} from 'store/features/settings/actions';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/shared/loader';
import { NotificationSettingItem } from 'components/features/settings/components/notificationSettingItem';
import { FieldType, INotificationSettingsItem } from 'interfaces/Notifications';
import cloneDeep from 'lodash/cloneDeep';
import Grid from '@mui/material/Grid';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { isEqual } from 'lodash';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import Snackbar from 'services/Snackbar';
import { normalizeCreateNotificationSettings } from 'utils/tree';

export const NotificationSettings = () => {
  const { t } = useTranslation();
  const notificationSettings = useAppSelector(getNotifications);
  const dispatch = useAppDispatch();
  const [changedNotificationSettings, setChangedNotificationSettings] =
    useState<INotificationSettingsItem[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  useEffect(() => {
    dispatch(getNotificationSettings());
  }, []);

  useEffect(() => {
    setChangedNotificationSettings(notificationSettings);
  }, [notificationSettings]);

  const handleChangeItem =
    (index: number) => (childIndex: number, field: FieldType) => {
      const changedSettings = cloneDeep(changedNotificationSettings);
      changedSettings[index].children[childIndex].setting[`${field}`] =
        !changedSettings[index].children[childIndex].setting[`${field}`];

      setChangedNotificationSettings(changedSettings);
    };

  const onClickCancel = () => {
    setChangedNotificationSettings(notificationSettings);
  };

  const onClickSave = async () => {
    setIsUpdate(true);
    const newSettings = normalizeCreateNotificationSettings(
      changedNotificationSettings,
    );
    await dispatch(changeNotificationSettings(newSettings)).then(() =>
      Snackbar.show(t('notification.notification settings'), 'success'),
    );
    setIsUpdate(false);
  };

  return (
    <SettingLayout>
      <Container item container style={{ padding: 16 }} mb={3}>
        <Title style={{ padding: 0 }}>{t('notification settings')}</Title>
      </Container>
      {!changedNotificationSettings.length && <Loader show />}
      {!!changedNotificationSettings.length && (
        <>
          <Grid item container>
            <Grid
              item
              container
              xs={2}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <NotificationSettingTitle>{t('lk')}</NotificationSettingTitle>
            </Grid>
            <Grid
              item
              container
              xs={2}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <NotificationSettingTitle>E-mail</NotificationSettingTitle>
            </Grid>
          </Grid>
          {changedNotificationSettings.map((el, index) => (
            <NotificationSettingItem
              key={el.code}
              item={el}
              onChangeItem={handleChangeItem(index)}
            />
          ))}
          <Grid item container xs={12} justifyContent={'space-around'} pt={3}>
            <Grid item xs={4}>
              <CreateButton
                fullWidth
                text={t('cancel')}
                onClick={onClickCancel}
                disabled={
                  isEqual(changedNotificationSettings, notificationSettings) ||
                  isUpdate
                }
              />
            </Grid>
            <Grid item xs={4}>
              <ActionButton
                fullWidth
                text={t('save')}
                onClick={onClickSave}
                disabled={
                  isEqual(changedNotificationSettings, notificationSettings) ||
                  isUpdate
                }
              />
            </Grid>
          </Grid>
        </>
      )}
    </SettingLayout>
  );
};

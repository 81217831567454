import React, { useCallback } from 'react';
import Rating from '@mui/material/Rating';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { useTranslation } from 'react-i18next';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { useAttachments } from 'utils/hooks';
import { useAppDispatch } from 'store';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { IAnswer, Review } from 'interfaces/Marketplace';
import {
  getAnswerRequestById,
  marketplaceReviewAnswer,
  uploadReviewAttachments,
} from 'store/features/marketplace/actions';
import { styled } from '@mui/material/styles';
import {
  BusinessAvatar,
  Name,
} from 'components/features/marketplace/components/cards/ServiceRequestAnswerCard';
import { getLocaleValue } from 'utils/form';
import { attachUrl } from 'services/ApiClient';
import { MAX_LENGTH } from 'defenitions/errorMessages';

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 16,
  lineHeight: '20px',
  color: '#4B4B4B',
  paddingRight: 16,
});
const BusinessCardContainer = styled(Grid)({
  border: '1px solid #A9A9A9',
  borderRadius: 5,
  padding: '5px 12px',
});
const BusinessTitle = styled(Grid)({
  fontSize: 14,
  color: '#A9A9A9',
});
const BusinessName = styled(Grid)({
  fontSize: 16,
});

type Props = {
  answer: IAnswer;
  additionAction: () => void;
  isCurrentUserOfferAnswer: boolean;
};
export const CreateReview = ({
  answer,
  additionAction,
  isCurrentUserOfferAnswer,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { attachments, onUploadAttachments, onRemoveAttachments } =
    useAttachments(uploadReviewAttachments);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<Review>({
    mode: 'onChange',
    defaultValues: {},
  });

  const handleSave: SubmitHandler<Review> = async (data) => {
    await dispatch(
      marketplaceReviewAnswer({
        offerId: answer.offer.id,
        answerId: answer.id,
        data: {
          ...data,
          attachments: attachments.map((el) => el.id),
        },
      }),
    ).unwrap();
    additionAction();
    getAnswer();
  };

  const getAnswer = useCallback(() => {
    dispatch(getAnswerRequestById(answer.id));
  }, [answer.id]);

  const avatarUrl =
    answer?.businessCard?.logo?.path &&
    attachUrl + answer.businessCard.logo.path;

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item container>
          <BusinessName>{t('review.title')}</BusinessName>
        </Grid>
        <Grid item container pb={2} xs={12} alignItems={'center'}>
          <BusinessAvatar src={avatarUrl} />
          <Name style={{ paddingLeft: 16 }}>
            <div>
              {isCurrentUserOfferAnswer
                ? getLocaleValue(answer.offer.businessCard.name)
                : getLocaleValue(answer.businessCard.name)}
            </div>
          </Name>
        </Grid>

        <Grid item container xs={12} alignItems={'center'}>
          <Title>{t('add review')}</Title>
          <Controller
            name={`grade`}
            control={control}
            rules={{ required: true }}
            defaultValue={1}
            render={({ field: { ref, ...rest } }) => (
              <Rating ref={ref} {...rest} />
            )}
          />
        </Grid>
        <Grid item container xs={12}>
          <BusinessCardContainer item xs={6}>
            <BusinessTitle>{t('business card')}</BusinessTitle>
            <BusinessName>
              {isCurrentUserOfferAnswer
                ? getLocaleValue(answer.businessCard.name)
                : getLocaleValue(answer.offer.businessCard.name)}
            </BusinessName>
          </BusinessCardContainer>
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            fieldName={'content'}
            label={t('review.content')}
            errors={errors}
            control={control}
            rules={{ required: true }}
            multiline
            maxLength={MAX_LENGTH}
          />
        </Grid>
        <Grid item xs={12}>
          <FilesUploader
            maxFiles={10}
            onUploadAttachments={onUploadAttachments}
            attachments={attachments}
            onClickRemoveAttachments={onRemoveAttachments}
          />
        </Grid>
        <Grid item xs={12}>
          <ActionButton
            fullWidth
            disabled={!isValid}
            onClick={handleSubmit(handleSave)}
            autoFocus
            text={t('modalTexts.feedback.actionTitle')}
          />
        </Grid>
      </Grid>
    </form>
  );
};

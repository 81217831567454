import React from 'react';
import { styled } from '@mui/system';
import { ReactComponent as AccountIcon } from 'assets/icons/accountIcon.svg';
import { ReactComponent as ParticipantsIcon } from 'assets/icons/participantsIcon.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/notificationMenuIcon.svg';
import { ReactComponent as StatsIcon } from 'assets/icons/statsIcon.svg';
import { MenuItemLink } from 'components/elements/navigationMenu/menuItem';
import { RouteNames, URLS } from 'defenitions/routes';
import { MENU_WIDTH } from 'services/theme';
import { useAppDispatch, useAppSelector } from 'store';
import { getAccount, getCurrent } from 'store/features/users';
import { Roles, Types } from 'interfaces/Users';
import { CollapseLayout } from 'components/elements/collapse';
import { Sections } from 'defenitions/main';
import {
  changeFilters,
  selectNotificationsFilters,
} from 'store/features/notifications';
import { useTranslation } from 'react-i18next';

const List = styled('ul')({
  width: '100%',
  margin: 0,
  padding: 0,
  listStyleType: 'none',
});

type ListItemProps = {
  selected?: boolean;
};

const ListItem = styled('li')<ListItemProps>(({ selected = false }) => ({
  width: '100%',
  margin: 0,
  borderRadius: '4px',
  padding: '8px 16px',
  fontWeight: '600',
  fontSize: '12px',
  color: selected ? '#000' : '#4b4b4b',
  backgroundColor: selected ? '#f5f5f5' : 'transparent',
  cursor: 'pointer',
}));

const Container = styled('div')(({ theme }) => ({
  flexShrink: 0,
  padding: '20px 13px',
  borderRight: '1px solid #dddddd',
  width: MENU_WIDTH,
  background: theme.palette.background.paper,
}));

const Divider = styled('div')({
  borderBottom: '1px solid #dddddd',
});

const Title = styled('div')({
  margin: 0,
  padding: '0 24px',
  fontWeight: '700',
  fontSize: 18,
  color: '#3d3d3d',
});

export const NavigationMenu = ({ showFilters }: { showFilters?: boolean }) => {
  const { t } = useTranslation();
  const notificationFilters = useAppSelector(selectNotificationsFilters);
  const user = useAppSelector(getCurrent);
  const account = useAppSelector(getAccount);
  const dispatch = useAppDispatch();
  const handleChangeCategory = (category: string) => () => {
    const selectedCategory =
      category === notificationFilters.category ? undefined : category;
    dispatch(
      changeFilters({
        ...notificationFilters,
        offset: 0,
        category: selectedCategory,
      }),
    );
  };
  return (
    <Container>
      <Title>{RouteNames[URLS.account]}</Title>
      <Divider sx={{ mt: '18px', mb: '18px' }} />
      <MenuItemLink
        link={URLS.account}
        logo={<AccountIcon />}
        title={t('account')}
      />
      <MenuItemLink
        link={URLS.profile}
        logo={<AccountIcon />}
        title={t('profile')}
      />
      {account?.type !== Types.PERSON && (
        <MenuItemLink
          link={URLS.users}
          logo={<ParticipantsIcon />}
          title={t('users')}
        />
      )}
      {user?.role === Roles.OWNER && (
        <MenuItemLink
          link={URLS.notifications}
          logo={<NotificationIcon />}
          title={t('Notifications')}
        />
      )}
      {user?.role === Roles.OWNER && (
        <MenuItemLink
          link={URLS.stats}
          logo={<StatsIcon />}
          title={'Статистика'}
        />
      )}
      <Divider sx={{ mt: '18px', mb: showFilters ? '18px' : 0 }} />
      {showFilters && (
        <>
          <CollapseLayout style={{ paddingLeft: 24 }} title={t('section')}>
            <List>
              {Object.keys(Sections).map((item) => (
                <ListItem
                  key={item}
                  selected={item === notificationFilters.category}
                  onClick={handleChangeCategory(item)}
                >
                  {t(`Sections.${item}`)}
                </ListItem>
              ))}
            </List>
          </CollapseLayout>
        </>
      )}
    </Container>
  );
};

import React, { useState } from 'react';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import {
  styled,
  SxProps,
  Theme,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  IconButton,
  Typography,
} from '@mui/material';
import { IService } from 'interfaces/Marketplace';
import { BusinessCardHeader } from '../BusinessCardHeader';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Editor } from 'components/shared/editor';
import { enumeration } from 'utils/strings';
import { getLocaleValue } from 'utils/form';

const Root = styled('div')({
  position: 'relative',
  border: '1px solid #ddd',
  padding: '16px 12px',
  backgroundColor: '#fff',
});

const ContentContainer = styled('div')({
  paddingLeft: 72,
});

const Title = styled('h6')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 16,
  color: '#00618e',
});

const Subheader = styled(Typography)({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '16px',
  color: '#a9a9a9',
});

const Menu = styled(MuiMenu)({
  '& .MuiMenu-list': {
    padding: 0,
  },
});

const MenuItem = styled(MuiMenuItem)({
  minWidth: 200,
  display: 'flex',
  justifyContent: 'center',
  padding: '8px 16px',
  fontFamily: 'inherit',
  fontWeight: '600',
  fontSize: 14,
  color: '#004b7c',
});

type MenuOptions = Array<{ label: string; onClick?: () => void }>;

type Props = {
  menu?: MenuOptions;
  service: IService;
  sx?: SxProps<Theme>;
  onLike?: (count: number) => void;
  onComment?: () => void;
};

export const ServiceCard = ({ menu = [], service, sx = [] }: Props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { businessCard, businessService } = service;

  const { updateDateTime, description, name } = businessService;

  const serviceType = enumeration(
    [getLocaleValue(businessService.serviceType.display)],
    t('marketplace.serviceType'),
  );

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => setAnchorEl(null);

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <BusinessCardHeader
        sx={{ mb: 2 }}
        avatarSx={{ height: 60, width: 60 }}
        src={businessCard?.logo?.path}
        name={businessCard?.name?.ru || businessCard?.name?.en || ''}
        created={updateDateTime}
        id={businessCard?.id}
      />
      <ContentContainer>
        <Title sx={{ mb: 0.5 }}>
          {truncate(name.ru || name.en, { length: 100, separator: ' ' })}
        </Title>
        <Subheader sx={{ mb: 0.5 }}>{serviceType}</Subheader>
        <Editor
          value={truncate(description.ru || description.en, {
            length: 250,
            separator: ' ',
          })}
          readOnly
        />
      </ContentContainer>
      <IconButton
        sx={{ position: 'absolute', top: 16, right: 12 }}
        onClick={handleOpenMore}
      >
        <MoreIcon sx={{ color: '#707070' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseMore}
      >
        {menu.map(({ label, onClick }, index) => (
          <MenuItem key={index} divider onClick={onClick}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Root>
  );
};

import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  InputBase,
  Popover,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { useAppDispatch, useAppSelector } from 'store';
import { getComplaintsAccountsFilter } from 'store/features/complaints/actions';
import { getComplaintsAccountsFilterList } from 'store/features/complaints';
import { Checkbox } from 'components/shared/checkbox';

const FilterButton = styled(Button)({
  borderRadius: 4,
  padding: 4,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
  textTransform: 'none',
  boxShadow: 'none',
});

const Checkboxes = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 8,
  maxHeight: 150,
  overflowY: 'auto',
});

const SearchContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #e4e4e4',
  borderRadius: 4,
  padding: '8px 12px',
  backgroundColor: '#f5f5f5',
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 8,

  '& button': {
    margin: 0,
    border: 0,
    padding: 0,
    fontWeight: '500',
    fontSize: 10,
    color: '#00618e',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    outline: 'none',
  },
});

const isSubstring = (str: string, substr: string) =>
  str.toLowerCase().includes(substr.toLowerCase());

type Props = {
  sx?: SxProps<Theme>;
  value?: string[] | null | undefined;
  onChange?: (value: string[]) => void;
};

export const AccountsFilter = (props: Props) => {
  const { sx = [], onChange } = props;
  const value = props.value ?? [];

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(getComplaintsAccountsFilterList);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [search, setSearch] = useState('');
  const [showChecked, setShowChecked] = useState(false);

  const current = useMemo(() => {
    return search
      ? accounts.filter(({ name }) => isSubstring(name, search))
      : accounts;
  }, [accounts, search]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChange = (id: string) => (_: unknown, checked: boolean) => {
    onChange?.(checked ? [...value, id] : value.filter((item) => item !== id));
  };

  const isChecked = (id: string) => {
    return !!value.find((item) => item === id);
  };

  const reset = () => onChange?.([]);

  const showOnlyChecked = () => setShowChecked(true);

  const showAll = () => setShowChecked(false);

  useEffect(() => {
    dispatch(getComplaintsAccountsFilter());
  }, [dispatch]);

  return (
    <>
      <FilterButton
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        onClick={handlePopoverOpen}
      >
        <FilterIcon style={{ marginRight: 4 }} />
        {t('filters.accounts.title')}
      </FilterButton>
      <Popover
        PaperProps={{ sx: { p: 2 } }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disablePortal
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <SearchContainer
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 400,
            mb: 1,
            p: '2px 4px',
          }}
        >
          <SearchIcon sx={{ m: 0.5, color: '#a9a9a9' }} />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={t('filters.accounts.placeholder')}
            value={search}
            inputProps={{ 'aria-label': 'account search' }}
            onChange={handleSearchChange}
          />
        </SearchContainer>
        <Checkboxes>
          {current.map(({ id, name }) => {
            const checked = isChecked(id);

            const component = (
              <Checkbox
                key={id}
                label={name}
                checked={checked}
                onChange={handleChange(id)}
              />
            );

            return showChecked ? checked && component : component;
          })}
        </Checkboxes>
        <Actions sx={{ mt: 1.5 }}>
          {showChecked ? (
            <button onClick={showAll}>{t('filters.accounts.showAll')}</button>
          ) : (
            <button onClick={showOnlyChecked}>
              {t('filters.accounts.showChecked')}
            </button>
          )}
          <button onClick={reset}>{t('filters.accounts.reset')}</button>
        </Actions>
      </Popover>
    </>
  );
};

import React, { ComponentType, SVGProps } from 'react';
import { styled, SxProps, Theme } from '@mui/system';

const Root = styled('button')({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 13,
  border: 0,
  padding: 0,
  backgroundColor: 'transparent',
});

const Label = styled('span')<{ color: string }>(({ color }) => ({
  fontWeight: '500',
  fontSize: 14,
  color,
}));

type Props = {
  IconComponent?: ComponentType<SVGProps<SVGSVGElement>>;
  active?: boolean;
  count?: number;
  label?: string;
  sx?: SxProps<Theme>;
  variant?: 'fill' | 'stroke' | 'all';
  onClick?: () => void;
};

export const InteractiveButton = ({
  IconComponent,
  active = false,
  count = 0,
  label,
  sx = [],
  variant = 'fill',
  onClick,
}: Props) => {
  let color = '#a9a9a9';

  if (count) {
    color = '#4b4b4b';
  }

  if (active) {
    color = '#00618e';
  }

  const optional = {
    ...(variant === 'fill' || variant === 'all' ? { fill: color } : {}),
    ...(variant === 'stroke' || variant === 'all' ? { stroke: color } : {}),
  };

  return (
    <Root
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        { cursor: onClick ? 'pointer' : 'default' },
      ]}
      onClick={onClick}
    >
      {IconComponent && <IconComponent {...optional} />}
      <Label color={color}>{count ? count : label}</Label>
    </Root>
  );
};

import { IMultiLanguage } from 'interfaces/Locale';
import { IAttachment, IBusinessAttachment } from 'interfaces/Attachment';
import {
  IBusinessServiceType,
  IDictionariesContent,
} from 'interfaces/Dictionaries';

export enum BusinessStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

interface IBaseBusinessService {
  name: IMultiLanguage;
  description: IMultiLanguage;
}

export interface IBusinessService extends IBaseBusinessService {
  id: string;
  serviceType: IDictionariesContent | null;
  attachments: IBusinessAttachment[];
}

export interface IBusinessServiceCreate extends IBaseBusinessService {
  attachments: string[];
  serviceType: string;
}

export interface IBusinessServiceView extends IBaseBusinessService {
  id: string;
  serviceType: IBusinessServiceType;
  createdDateTime: number;
  updateDateTime: number;
}

interface IBaseBusiness {
  id: string;
  name: IMultiLanguage;
  address: IMultiLanguage;
  contactPerson: IMultiLanguage;
  contactPhone: string;
  description: IMultiLanguage;
  email: string;
  website: string;
  status: BusinessStatus;
}

export interface IBusinessCreate extends IBaseBusiness {
  logo?: string;
  cardType: string | null;
  services: IBusinessServiceCreate[];
  attachments: string[];
}

export interface IBusiness extends IBaseBusiness {
  id: string;
  accountId: string;
  creatorId: string;
  cardType: IDictionariesContent | null;
  logo: IBusinessAttachment;
  rate: number;
  createdDateTime: number;
  updateDateTime: number;
  publishedDateTime: number;
  services: IBusinessService[];
  attachments: IBusinessAttachment[];
  countQuestions: number;
  subscriptionId?: string;
  isCreatedByCurrentAccount: boolean;
  viewsCounter: { uniqueUsers: number; viewedByCurrentUser: boolean };
  reviewCount: number;
  canCurrentUserWatchContacts?: boolean;
}

export interface IBusinessPublished {
  id: string;
  name: IMultiLanguage;
}

export interface IPublishedBusinessCard {
  id: string;
  name: IMultiLanguage;
  logo?: IAttachment;
  rate: number;
}

export type IBusinessMultiLanguageFormFields =
  | 'name'
  | 'address'
  | 'contactPerson'
  | 'description';

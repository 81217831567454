import { AxiosError, AxiosResponse } from 'axios';
import { validateError } from 'store/utils/validateError';

export const actionHandler = async (
  actionRequest: (...arg: any) => Promise<AxiosResponse>,
  rejectWithValue: (errorValidator: any) => void,
) => {
  try {
    return await actionRequest();
  } catch (err) {
    return rejectWithValue(validateError(err as AxiosError));
  }
};

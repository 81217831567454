import React from 'react';
import { styled, SxProps, Theme } from '@mui/system';
import { BackButton } from 'components/shared/buttons/BackButton';

const Header = styled('h2')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  padding: '16px 32px',
  fontWeight: '600',
  fontSize: 22,
  color: '#565a65',
});

const Back = styled(BackButton)({
  position: 'absolute',
  left: 32,
  width: 120,
  height: 40,
});

type Props = {
  sx?: SxProps<Theme>;
  children: string;
  onBack?: () => void;
  onMouseLeave?: () => void;
};

export const ServiceHeader = ({
  sx = [],
  children,
  onBack,
  onMouseLeave,
}: Props) => {
  return (
    <Header
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      onMouseLeave={onMouseLeave}
    >
      {children}
      <Back onClick={onBack} />
    </Header>
  );
};

import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '19px 0px',
  background: '#ffffff',
});

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 28,
  lineHeight: '36px',
  textAlign: 'center',
});

type Props = {
  title: string;
  children: React.ReactNode;
};

export const SignupLayout = ({ title, children }: Props) => {
  return (
    <Container>
      <Grid container alignItems={'center'} direction={'row'}>
        <Grid item xs={12} style={{ padding: 50 }}>
          <Title>{title}</Title>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

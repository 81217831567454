import React, { useEffect, useMemo, useState } from 'react';
import { SettingLayout } from 'components/features/settings/components/layout';
import { Container, Title } from 'components/features/settings/components';
import { useTranslation } from 'react-i18next';
import { SelectTextFields } from 'components/shared/selects';
import { Languages } from 'defenitions/main';
import { useAppDispatch, useAppSelector } from 'store';
import {
  changeLocale,
  changeTimeZone,
  getSettings,
  getTimeZones,
} from 'store/features/settings/actions';
import {
  getLocale,
  getTimeZonesAvailable,
  getTimeZone,
} from 'store/features/settings';
import { Locale } from 'interfaces/Locale';
import i18n from 'services/i18n';
import Grid from '@mui/material/Grid';
import { normalizeTimeZonesBook } from 'utils/locale';
import { Loader } from 'components/shared/loader';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';
import { IDictionariesContent } from 'interfaces/Dictionaries';

export const MainSettings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locale = useAppSelector(getLocale);
  const timeZone = useAppSelector(getTimeZone);
  const timeZones = useAppSelector(getTimeZonesAvailable);
  const timeZonesBook = useMemo(
    () => normalizeTimeZonesBook(timeZones),
    [timeZones],
  );
  const [selectedLocale, setSelectedLocate] = useState<Locale>(Locale.ru);
  const [selectedTimeZone, setSelectedTimeZone] =
    useState<IDictionariesContent | null>(null);

  const handleChangeLocale = (event: React.ChangeEvent<HTMLInputElement>) => {
    const locale = event.target.value as Locale;
    setSelectedLocate(locale);
    dispatch(changeLocale(locale));
    i18n.changeLanguage(locale);
  };

  const handleChangeTimeZone = (data: IDictionariesContent) => {
    setSelectedTimeZone(data);
    dispatch(changeTimeZone(data.code));
  };

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getTimeZones());
  }, []);

  useEffect(() => {
    setSelectedLocate(
      (Languages.find((el) => el.id === locale)?.id as Locale) || Locale.ru,
    );
  }, [locale]);

  useEffect(() => {
    setSelectedTimeZone(
      timeZonesBook.find((el) => el.code === timeZone) || null,
    );
  }, [timeZone, timeZonesBook]);

  return (
    <SettingLayout>
      <Container item container>
        <Grid item xs={12} mb={3}>
          <Title>{t('language')}</Title>
          <SelectTextFields
            onChange={handleChangeLocale}
            options={Languages}
            value={selectedLocale}
            label={t('languageTitle')}
          />
        </Grid>
        <Grid item xs={12}>
          <Title>{t('timeZone')}</Title>
          {!timeZonesBook.length && <Loader show />}
          {!!timeZonesBook.length && (
            <MultiSelectAutocomplete
              disableClearable
              onChange={handleChangeTimeZone}
              value={selectedTimeZone}
              noHierarchy
              label={t('timeZoneTitle')}
              data={timeZonesBook}
            />
          )}
        </Grid>
      </Container>
    </SettingLayout>
  );
};

import React from 'react';
import { styled, SxProps, Theme } from '@mui/system';
import { NavigationMenu } from 'components/elements/navigationMenu';

const Container = styled('div')({
  minHeight: '100%',
  display: 'flex',
});

const ContainerContent = styled('div')({
  padding: '20px 37px',
  flex: 1,
  borderLeft: '1px solid #DDDDDD',
});

export const BusinessLayout = ({
  children,
  contentSx = [],
}: {
  children: React.ReactNode;
  contentSx?: SxProps<Theme>;
}) => {
  return (
    <Container>
      <NavigationMenu />
      <ContainerContent
        sx={[...(Array.isArray(contentSx) ? contentSx : [contentSx])]}
      >
        {children}
      </ContainerContent>
    </Container>
  );
};

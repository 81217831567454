import React from 'react';
import { styled } from '@mui/system';
import { ReactComponent as SettingIcon } from 'assets/icons/settings.svg';
import { ReactComponent as NotificationSettingsIcon } from 'assets/icons/notificationSettingsIcon.svg';
import { MenuItemLink } from 'components/elements/navigationMenu/menuItem';
import { RouteNames, URLS } from 'defenitions/routes';
import { MENU_WIDTH } from 'services/theme';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { getCurrent } from 'store/features/users';
import { Roles } from 'interfaces/Users';

const Container = styled('div')(({ theme }) => ({
  width: MENU_WIDTH,
  background: theme.palette.background.paper,
  padding: '30px 10px',
}));

const Divider = styled('div')({
  marginTop: 10,
  marginBottom: 10,
  borderBottom: '1px solid #DDDDDD',
});

const Title = styled('div')({
  color: '#4B4B4B',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 24,
  lineHeight: '17px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '5px',
});

export const NavigationMenu = () => {
  const { t } = useTranslation();
  const current = useAppSelector(getCurrent);
  return (
    <Container>
      <Title>{RouteNames[URLS.settings]}</Title>
      <Divider />
      <MenuItemLink
        link={URLS.settings}
        logo={<SettingIcon />}
        title={t('settings')}
      />
      {current?.role === Roles.OWNER && (
        <MenuItemLink
          link={URLS.notificationSettings}
          logo={<NotificationSettingsIcon />}
          title={t('notification settings')}
        />
      )}
      <Divider />
    </Container>
  );
};

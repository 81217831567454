import {
  IAnswer,
  IService,
  IServiceRequest,
  Reviews,
} from 'interfaces/Marketplace';

export enum ActionTypes {
  SAVE_MARKET_PLACE_OFFER_ANSWER = 'SAVE_MARKET_PLACE_OFFER_ANSWER',
  GET_SERVICE_REQUEST_BY_ID = 'GET_SERVICE_REQUEST_BY_ID',
  GET_SERVICE_BY_ID = 'GET_SERVICE_BY_ID',
  GET_PUBLISHED_SERVICE_REQUESTS = 'GET_PUBLISHED_SERVICE_REQUESTS',
  GET_INCOMING_SERVICE_REQUESTS = 'GET_INCOMING_SERVICE_REQUESTS',
  GET_SERVICES = 'GET_SERVICES',
  GET_MY_SERVICE_REQUESTS = 'GET_MY_SERVICE_REQUESTS',
  CREATE_SERVICE_REQUEST = 'CREATE_SERVICE_REQUEST',
  PUBLISH_SERVICE_REQUEST = 'PUBLISH_SERVICE_REQUEST',
  CLOSE_SERVICE_REQUEST = 'CLOSE_SERVICE_REQUEST',
  GET_ALL_ANSWERS_FOR_SERVICE_REQUEST = 'GET_ALL_ANSWERS_FOR_SERVICE_REQUEST',
  DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST',
  UPLOAD_MARKETPLACE_ATTACHMENT = 'UPLOAD_MARKETPLACE_ATTACHMENT',
  MARKETPLACE_ACCEPT_ANSWER = 'MARKETPLACE_ACCEPT_ANSWER',
  MARKETPLACE_DECLINE_ANSWER = 'MARKETPLACE_DECLINE_ANSWER',
  MARKETPLACE_COMPLETE_ANSWER = 'MARKETPLACE_COMPLETE_ANSWER',
  DECLINE_SERVICE_REQUEST = 'DECLINE_SERVICE_REQUEST',
  MARKETPLACE_SHARE_CONTACTS = 'MARKETPLACE_SHARE_CONTACTS',
  MARKETPLACE_DELETE_ANSWER = 'MARKETPLACE_DELETE_ANSWER',
  MARKETPLACE_PUBLISH_ANSWER = 'MARKETPLACE_PUBLISH_ANSWER',
  MARKETPLACE_CLOSE_ANSWER = 'MARKETPLACE_CLOSE_ANSWER',
  MARKETPLACE_WORK_ANSWER = 'MARKETPLACE_WORK_ANSWER',
  MARKETPLACE_GET_ANSWER_BY_ID = 'MARKETPLACE_GET_ANSWER_BY_ID',
  MARKETPLACE_GET_SERVICE_PAGE_LIST = 'MARKETPLACE_GET_SERVICE_PAGE_LIST',
  GET_MY_OFFERS = 'GET_MY_OFFERS',
  LIKE_MARKETPLACE_COMMENT = 'LIKE_MARKETPLACE_COMMENT',
  LIKE_SERVICE = 'LIKE_SERVICE',
  LIKE_SERVICE_REQUEST = 'LIKE_SERVICE_REQUEST',
  LIKE_SERVICE_OFFER = 'LIKE_SERVICE_OFFER',
  CREATE_MARKETPLACE_COMMENT = 'CREATE_MARKETPLACE_COMMENT',
  EDIT_MARKETPLACE_COMMENT = 'EDIT_MARKETPLACE_COMMENT',
  CREATE_MARKETPLACE_REVIEW = 'CREATE_MARKETPLACE_REVIEW',
  UPLOAD_REVIEW_ATTACHMENTS = 'UPLOAD_REVIEW_ATTACHMENTS',
  LOAD_MARKETPLACE_REVIEW = 'LOAD_MARKETPLACE_REVIEW',
  GET_MY_OFFER_COUNT = 'GET_MY_OFFER_COUNT',
  SHOW_SERVICE_REQUEST = 'SHOW_SERVICE_REQUEST',
  HIDE_SERVICE_REQUEST = 'HIDE_SERVICE_REQUEST',
}

export interface IMarketplaceState {
  list: {
    requests: Array<IServiceRequest>;
    services: Array<IService>;
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
  item: {
    request: IServiceRequest | null;
    service: IService | null;
    loading: boolean;
    loaded: boolean;
  };
  answerItem: {
    answer: IAnswer | null;
    loading: boolean;
    loaded: boolean;
  };
  getServiceItems: {
    hasMore: boolean;
    items: Array<IAnswer>;
    loading: boolean;
    loaded: boolean;
  };
  answersList: {
    hasMore: boolean;
    items: Array<IAnswer>;
    loading: boolean;
    loaded: boolean;
  };
  reviews: Record<string, never> | Reviews;
}

export const initialMarketplaceState: IMarketplaceState = {
  list: {
    requests: [],
    services: [],
    loading: false,
    loaded: false,
    hasMore: false,
  },
  item: {
    request: null,
    service: null,
    loading: false,
    loaded: false,
  },
  answerItem: {
    answer: null,
    loading: false,
    loaded: false,
  },
  getServiceItems: {
    hasMore: false,
    items: [],
    loading: false,
    loaded: false,
  },
  answersList: {
    hasMore: false,
    items: [],
    loading: false,
    loaded: false,
  },
  reviews: {},
};

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDebounce } from 'utils/hooks';

export type SearchRef = {
  clear: () => void;
};

type Props = Omit<TextFieldProps, 'variant'> & {
  delay?: number;
  defaultValue?: string;
  onChangeSearch: (value: string) => void;
};

const DebouncedTextField = forwardRef<SearchRef, Props>(
  ({ defaultValue = '', delay = 500, onChangeSearch, ...props }, ref) => {
    const [searchValue, setSearchValue] = useState(defaultValue);
    const debouncedSearchValue = useDebounce<string>(searchValue, delay);

    const isMountRef = useRef(false);

    const onClickClear = useCallback(() => {
      setSearchValue('');
    }, []);

    const handleChange = useCallback(
      (e: React.ChangeEvent<{ value: string }>) => {
        const { value } = e.target;
        setSearchValue(value);
        if (value === '') onClickClear();
      },
      [setSearchValue, onClickClear],
    );

    useEffect(() => {
      if (isMountRef.current && (searchValue.length > 2 || !searchValue))
        onChangeSearch(searchValue);
      isMountRef.current = true;
    }, [debouncedSearchValue]);

    useImperativeHandle(ref, () => ({
      clear: onClickClear,
    }));

    return (
      <TextField
        fullWidth
        variant="filled"
        value={searchValue}
        onChange={handleChange}
        InputProps={{
          disableUnderline: true,
          startAdornment: !searchValue && (
            <>
              <SearchIcon />
            </>
          ),
          endAdornment: searchValue && (
            <IconButton onClick={onClickClear} size="small">
              <CloseIcon />
            </IconButton>
          ),
        }}
        {...props}
      />
    );
  },
);

DebouncedTextField.displayName = 'DebouncedTextField';

export const SearchTextFieldInput = styled(DebouncedTextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    overflow: 'hidden',
    width: '100%',
    height: 29,
    outline: '1px solid #D9D9D9',
    transition: theme.transitions.create([
      'outline-width',
      'outline-color',
      'background-color',
    ]),
    '&:hover': {
      backgroundColor: '#eaeaea',
    },
    '&.Mui-focused': {
      backgroundColor: '#F8F8F8',
      outline: '1px solid #CBCBCB',
    },
    '& input': {
      fontSize: 14,
      padding: '0px 12px',
    },
  },
  '& .Mui-error': {
    outline: '1px solid #E04311',
  },
  '& .MuiInputLabel-root': {
    color: '#969696',
    outline: 'none',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    '&.Mui-focused': {
      color: '#969696',
    },
  },
}));

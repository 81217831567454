import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { SearchMatch } from 'interfaces/Search';
import { RootState } from 'store';
import { Slices } from 'store/configuration/slices';
import { search } from './actions';
import { initialState } from './config';

export const searchSlice = createSlice({
  name: Slices.search,
  initialState,
  reducers: {
    clear: (state) => {
      state.matches.list = [];
      state.matches.loading = false;
      state.matches.hasMore = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state, { meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        state.matches.loading = true;
        state.matches.hasMore = offset === 0 || state.matches.hasMore;
      })
      .addCase(search.fulfilled, (state, { payload, meta }) => {
        const { offset = 0 } = meta.arg ?? {};
        const { data } = payload as AxiosResponse<SearchMatch[]>;

        state.matches.list =
          offset === 0 ? data : [...state.matches.list, ...data];

        state.matches.loading = false;
        state.matches.hasMore = data.length !== 0;
      })
      .addCase(search.rejected, (state) => {
        state.matches.loading = false;
        state.matches.hasMore = false;
      });
  },
});

const self = (state: RootState) => state.search;

export const getSearchMatchesList = createSelector(
  self,
  (state) => state.matches.list,
);

export const getSearchMatchesLoading = createSelector(
  self,
  (state) => state.matches.loading,
);

export const getSearchMatchesHasMore = createSelector(
  self,
  (state) => state.matches.hasMore,
);

export const { clear } = searchSlice.actions;

import * as yup from 'yup';
import {
  ErrorMessages,
  MAX_LENGTH,
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_SMALL,
} from 'defenitions/errorMessages';

const localeValidate = yup.object().shape(
  {
    ru: yup.string().when('en', {
      is: (en: string) => !en,
      then: yup
        .string()
        .max(MAX_LENGTH, ErrorMessages.maxLength(MAX_LENGTH))
        .notOneOf(['', undefined, null], ErrorMessages.required),
    }),
    en: yup.string().when('ru', {
      is: (ru: string) => !ru,
      then: yup
        .string()
        .max(MAX_LENGTH, ErrorMessages.maxLength(MAX_LENGTH))
        .notOneOf(['', undefined, null], ErrorMessages.required),
    }),
  },
  [
    ['en', 'ru'],
    ['ru', 'en'],
  ],
);

export const commonDataValidationSchema = yup.object({
  cardType: yup.object().required(ErrorMessages.required),
  name: localeValidate,
  address: localeValidate,
  email: yup
    .string()
    .email(ErrorMessages.emailNotCorrect)
    .required(ErrorMessages.required),
  website: yup
    .string()
    .url(ErrorMessages.url)
    .max(MAX_LENGTH_SMALL, ErrorMessages.maxLength(MAX_LENGTH_SMALL)),
  description: yup
    .object({
      ru: yup
        .string()
        .max(
          MAX_LENGTH_DESCRIPTION,
          ErrorMessages.maxLength(MAX_LENGTH_DESCRIPTION),
        ),
      en: yup
        .string()
        .max(
          MAX_LENGTH_DESCRIPTION,
          ErrorMessages.maxLength(MAX_LENGTH_DESCRIPTION),
        ),
    })
    .required(ErrorMessages.required),

  contactPerson: yup
    .object({
      ru: yup.string().max(MAX_LENGTH, ErrorMessages.maxLength(MAX_LENGTH)),
      en: yup.string().max(MAX_LENGTH, ErrorMessages.maxLength(MAX_LENGTH)),
    })
    .required(ErrorMessages.required),
});

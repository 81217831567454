import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import {
  ICreateSoftware,
  ISoftwareFilters,
  ISoftwareReviewsFilters,
} from 'interfaces/Software';
import { AttachmentType } from 'interfaces/Attachment';
import { ICreateSoftwareReview } from 'interfaces/Review';

export const getSoftwareEndpoint = (
  filters: ISoftwareFilters,
): Promise<AxiosResponse> => {
  return apiClient.get(`/software-cards`, {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });
};

export const getMySoftwareEndpoint = (
  filters: ISoftwareFilters,
): Promise<AxiosResponse> => {
  return apiClient.get(`/software-cards/account`, {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });
};

export const hideSoftwareEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.patch(`/software-cards/${id}/hide`);
};

export const showSoftwareEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.patch(`/software-cards/${id}/show`);
};

export const getSoftwareCardEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`/software-cards/${id}`, {
    params: { withCodes: true },
  });
};

export const getSoftwareCardReviewsEndpoint = (
  filters: ISoftwareReviewsFilters,
): Promise<AxiosResponse> => {
  return apiClient.get(`/reviews/software-cards/${filters.softwareCardId}`, {
    params: {
      ...filters,
    },
  });
};

export const createSoftwareCardEndpoint = (
  softwareCard: ICreateSoftware,
): Promise<AxiosResponse> => {
  return apiClient.post(`/software-cards`, softwareCard);
};

export const updateSoftwareCardEndpoint = (
  id: string,
  softwareCard: ICreateSoftware,
): Promise<AxiosResponse> => {
  return apiClient.put(`/software-cards/${id}`, softwareCard);
};

export const uploadSoftwareCardLogoEndpoint = (
  formData: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments?type=${AttachmentType.SoftwareCardLogo}`,
    formData,
  );
};

export const uploadSoftwareCardAttachmentEndpoint = (
  formData: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments/files?type=${AttachmentType.SoftwareCard}`,
    formData,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  );
};

export const createSoftwareReviewEndpoint = (
  review: ICreateSoftwareReview,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/software-cards/${review.softwareCardReviewId}/reviews`,
    review,
  );
};

export const getSoftwareCardAccountEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get(`/software-cards/account/active`);
};

export const getOthersAccountSoftwareCardsEndpoint = (data: {
  businessCardId?: string;
}): Promise<AxiosResponse> => {
  return apiClient.get(`/software-cards/list`, { params: data });
};

import React from 'react';
import Grid from '@mui/material/Grid';
import {
  Container,
  Divider,
} from 'components/features/business/shared/item/components';
import { BusinessStatus, IBusiness } from 'interfaces/Business';
import { attachUrl } from 'services/ApiClient';
import {
  Field,
  Image,
  StatusValue,
  Value,
} from 'components/elements/viewFields';
import { Thumbs } from 'components/elements/uploaders/thumbs';
import { ViewField } from 'components/elements/viewFields/ViewField';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { format } from 'date-fns';
import { getLocaleValue } from 'utils/form';
import { useTranslation } from 'react-i18next';

type Props = {
  businessCard: IBusiness | null;
  onClickCancel: () => void;
  onClickNext: () => void;
};

export const CommonDataView = ({
  businessCard,
  onClickCancel,
  onClickNext,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Container container spacing={4}>
      <Grid item container xs={4}>
        <Grid item xs={12}>
          {businessCard?.logo?.path && (
            <Image src={`${attachUrl}${businessCard?.logo?.path}`} />
          )}
          <Grid container>
            <Grid item xs={6} alignSelf={'center'}>
              <Field>Статус:</Field>
            </Grid>
            <Grid item xs={6} alignSelf={'center'}>
              <StatusValue
                isPublish={businessCard?.status === BusinessStatus.PUBLISHED}
              >
                {t(`businessCardStatuses.${businessCard?.status}`)}
              </StatusValue>
            </Grid>
            {businessCard?.createdDateTime && (
              <>
                <Grid item xs={6} alignSelf={'center'}>
                  <Field>Дата создания:</Field>
                </Grid>
                <Grid item xs={6} alignSelf={'center'}>
                  <Value>
                    {format(
                      new Date(businessCard?.createdDateTime),
                      'dd.MM.yyyy',
                    )}
                  </Value>
                </Grid>
              </>
            )}
            {businessCard?.publishedDateTime && (
              <>
                <Grid item xs={6} alignSelf={'center'}>
                  <Field>Дата публикации:</Field>
                </Grid>
                <Grid item xs={6} alignSelf={'center'}>
                  <Value>
                    {format(
                      new Date(businessCard?.publishedDateTime),
                      'dd.MM.yyyy',
                    )}
                  </Value>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={8} spacing={3}>
        <ViewField
          title={'О компании'}
          fields={[
            {
              field: 'Тип услуги:',
              value: getLocaleValue(businessCard?.cardType?.display),
            },
            {
              field: 'Наименование:',
              value: getLocaleValue(businessCard?.name),
            },
            { field: 'Адрес:', value: getLocaleValue(businessCard?.address) },
            { field: 'Веб сайт:', value: businessCard?.website, isHref: true },
            {
              field: 'Электронная почта компании:',
              value: businessCard?.email,
              isEmail: true,
            },
          ]}
        />
        {(businessCard?.contactPhone || businessCard?.contactPerson) &&
          businessCard?.canCurrentUserWatchContacts && (
            <ViewField
              title={'Контактное лицо'}
              fields={[
                {
                  field: 'Контактное лицо:',
                  value: getLocaleValue(businessCard?.contactPerson),
                },
                {
                  field: 'Контактный телефон:',
                  value: businessCard?.contactPhone,
                },
              ]}
            />
          )}
        <ViewField
          title={'О Карточке'}
          fields={[
            {
              field: 'Краткое описание:',
              value: getLocaleValue(businessCard?.description),
              isEditor: true,
            },
            { field: 'Рейтинг:', value: businessCard?.rate?.toString() },
          ]}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          {businessCard?.attachments && (
            <Thumbs attachments={businessCard.attachments} />
          )}
        </Grid>
      </Grid>
      <Divider />
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            text={t('actions.back')}
            onClick={onClickCancel}
          />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            onClick={onClickNext}
            text={t('actions.next')}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

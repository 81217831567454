import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from 'store/features/notifications/config';
import { actionHandler } from 'store/utils/actionHandler';
import {
  getNotificationsEndpoint,
  getUnreadNotificationsCountEndpoint,
  getNotificationEndpoint,
} from 'store/features/notifications/api';
import { INotificationsFilters } from 'interfaces/Notifications';

export const getNotifications = createAsyncThunk(
  ActionTypes.GET_NOTIFICATIONS,
  async (filters: INotificationsFilters, { rejectWithValue }) =>
    actionHandler(() => getNotificationsEndpoint(filters), rejectWithValue),
);

export const getNotification = createAsyncThunk(
  ActionTypes.GET_NOTIFICATION,
  async (id: string, { rejectWithValue }) =>
    actionHandler(() => getNotificationEndpoint(id), rejectWithValue),
);

export const getUnreadCountNotifications = createAsyncThunk(
  ActionTypes.GET_NOTIFICATIONS_UNREAD_COUNT,
  async (any, { rejectWithValue }) =>
    actionHandler(() => getUnreadNotificationsCountEndpoint(), rejectWithValue),
);

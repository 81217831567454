export enum AttachmentType {
  BusinessCard = 'BusinessCard',
  BusinessCardLogo = 'BusinessCardLogo',
  ServiceCard = 'ServiceCard',
  NewsPost = 'NewsPost',
  Comment = 'Comment',
  Question = 'Question',
  Answer = 'Answer',
  Offer = 'Offer',
  OfferAnswer = 'OfferAnswer',
  UserLogo = 'UserLogo',
  Complaint = 'Complaint',
  Review = 'Review',
  SoftwareCard = 'SoftwareCard',
  SoftwareCardLogo = 'SoftwareCardLogo',
}

export enum iconType {
  pdf = 'pdf',
  word = 'word',
  excel = 'excel',
  audio = 'audio',
  video = 'video',
  archive = 'archive',
  image = 'image',
  unidentified = 'unidentified',
}
// todo заиспользовать глобальный атачмант, если возможно IAttachment
export interface IBusinessAttachment {
  id: string;
  contentSize: number;
  contentType: string;
  name: string;
  digest: string;
  path: string;
  type?: AttachmentType;
  iconType: iconType;
}

export interface IAttachment {
  id: string;
  contentSize: number;
  contentType: string;
  name: string;
  digest: string;
  path: string;
  type?: AttachmentType;
  iconType: iconType;
}

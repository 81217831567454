import { Answer, Question, QuestionSubscription } from 'interfaces/Question';

export enum ActionTypes {
  GET_QUESTION_BY_ID = 'GET_QUESTION_BY_ID',
  GET_QUESTIONS = 'GET_QUESTIONS',
  CREATE_QUESTION = 'CREATE_QUESTION',
  UPLOAD_QUESTION_ATTACHMENTS = 'UPLOAD_QUESTION_ATTACHMENTS',
  REACT_TO_QUESTION_POST = 'REACT_TO_QUESTION_POST',
  CLOSE_QUESTION = 'CLOSE_QUESTION',
  GET_ANSWERS = 'GET_ANSWERS',
  CREATE_ANSWER = 'CREATE_ANSWER',
  EDIT_ANSWER = 'EDIT_ANSWER',
  UPLOAD_ANSWER_ATTACHMENTS = 'UPLOAD_ANSWER_ATTACHMENTS',
  GET_QUESTION_SUBSCRIPTIONS = 'GET_QUESTION_SUBSCRIPTIONS',
  CREATE_QUESTION_SUBSCRIPTIONS = 'CREATE_QUESTION_SUBSCRIPTIONS',
  ACCEPT_ANSWER = 'ACCEPT_ANSWER',
}

export interface State {
  list: {
    questions: Question[];
    loading: boolean;
    loaded: boolean;
    hasMore: boolean;
  };
  current: {
    question: Question | null;
    answers: Answer[];
    loading: boolean;
    loaded: boolean;
  };
  subscriptions: {
    subscriptions: QuestionSubscription[];
    loading: boolean;
    loaded: boolean;
  };
}

export const initialState: State = {
  list: {
    questions: [],
    loading: false,
    loaded: false,
    hasMore: false,
  },
  current: {
    question: null,
    answers: [],
    loading: false,
    loaded: false,
  },
  subscriptions: {
    subscriptions: [],
    loading: false,
    loaded: false,
  },
};

import React from 'react';
import { ReactComponent as Plus } from 'assets/icons/plusIcon.svg';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled, SxProps, Theme } from '@mui/material/styles';

const StyledButton = styled(Button)({
  minHeight: 48,
  borderRadius: 14,
  padding: '5px 15px',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '16px',
  textTransform: 'none',
  boxShadow: 'none',
  backgroundColor: '#fff',
  color: '#00618e',

  '&:hover': {
    backgroundColor: '#e9f8ff',
    color: '#00618e',
  },

  '&:active': {
    backgroundColor: '#e9f8ff',
    color: '#00618e',
  },

  '&.Mui-disabled': {
    backgroundColor: '#e4e4e4',
    color: '#a9a9a9',
  },
});

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  text: React.ReactNode;
  withPlusIcon?: boolean;
} & ButtonProps;

export const CreateButton = ({
  className,
  sx = [],
  text,
  withPlusIcon = false,
  disabled = false,
  ...other
}: Props) => {
  return (
    <StyledButton
      className={className}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      variant="outlined"
      disabled={disabled}
      disableRipple
      {...other}
    >
      {withPlusIcon && (
        <Plus style={{ marginRight: 10 }} stroke="currentColor" />
      )}
      {text}
    </StyledButton>
  );
};

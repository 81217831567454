import { AxiosResponse } from 'axios';
import { AttachmentType } from 'interfaces/Attachment';
import {
  CreateReview,
  GetServicePageFilters,
  IAcceptAnswer,
  ICompilationPageFilters,
  ICreateServiceRequest,
  IDeclineAnswer,
  IDeclineServiceRequest,
  IProvideServicesPageFilters,
  IRequestsPageFilters,
  IService,
  IServiceRequest,
  IShareContacts,
} from 'interfaces/Marketplace';
import qs from 'qs';
import { apiClient } from 'services/ApiClient';
import { IAnswer, ICreateOfferAnswer } from 'interfaces/Marketplace';
import { ICreateComment, IEditComment } from 'interfaces/Comment';
import { ReactionType } from 'interfaces/Post';

export const saveMarketplaceItemsOfferAnswerEndpoint = ({
  offerId,
  ...data
}: ICreateOfferAnswer): Promise<AxiosResponse> =>
  apiClient.post(`/offers/${offerId}/answers`, data, {
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

export const publishMarketplaceItemsOfferAnswerEndpoint = (
  id: IAnswer['id'],
): Promise<AxiosResponse> =>
  apiClient.patch(`/offers/answers/${id}/publish`, {});

export const marketplaceAcceptAnswerEndpoint = ({
  offerId,
  answerId,
}: IAcceptAnswer): Promise<AxiosResponse> =>
  apiClient.patch(`/offers/${offerId}/answers/${answerId}/accept`, {});

export const marketplaceCompleteAnswerEndpoint = ({
  offerId,
  answerId,
}: IAcceptAnswer): Promise<AxiosResponse> =>
  apiClient.patch(`/offers/${offerId}/answers/${answerId}/complete`, {});

export const marketplaceReviewEndpoint = ({
  offerId,
  answerId,
  data,
}: CreateReview): Promise<AxiosResponse> =>
  apiClient.post(`/offers/${offerId}/answers/${answerId}/reviews`, data);

export const marketplaceGetReviewEndpoint = ({
  offerId,
  answerId,
}: IAcceptAnswer): Promise<AxiosResponse> =>
  apiClient.get(`/offers/${offerId}/answers/${answerId}/reviews`);

export const marketplaceShareContactsEndpoint = ({
  offerId,
  answerId,
}: IShareContacts): Promise<AxiosResponse> =>
  apiClient.post(`/offers/${offerId}/answers/${answerId}/contacts`, {});

export const marketplaceDeclineAnswerEndpoint = ({
  offerId,
  answerId,
}: IDeclineAnswer): Promise<AxiosResponse> =>
  apiClient.patch(`/offers/${offerId}/answers/${answerId}/decline`, {});

export const marketplaceDeclineServiceRequestEndpoint = ({
  offerId,
  title,
}: IDeclineServiceRequest): Promise<AxiosResponse> =>
  apiClient.post(`/offers/${offerId}/answers/decline`, { ...title });

export const marketplaceDeleteAnswerEndpoint = (
  id: IAnswer['id'],
): Promise<AxiosResponse> => apiClient.delete(`/offers/answers/${id}`);

export const marketplacePublishAnswerEndpoint = (
  id: IAnswer['id'],
): Promise<AxiosResponse> => apiClient.patch(`/offers/answers/${id}/publish`);

export const marketplaceCloseAnswerEndpoint = (
  id: IAnswer['id'],
): Promise<AxiosResponse> => apiClient.patch(`/offers/answers/${id}/close`, {});

export const marketplaceWorkAnswerEndpoint = (
  id: IAnswer['id'],
): Promise<AxiosResponse> => apiClient.patch(`/offers/answers/${id}/work`, {});

export const getServiceRequestByIdEndpoint = (
  id: IServiceRequest['id'],
): Promise<AxiosResponse> => apiClient.get(`/offers/${id}`);

export const getServiceByIdEndpoint = (
  id: IService['businessService']['id'],
): Promise<AxiosResponse> => apiClient.get(`/business-cards/services/${id}`);

export const getAnswerRequestByIdEndpoint = (
  id: IServiceRequest['id'],
): Promise<AxiosResponse> => apiClient.get(`/offers/answers/${id}`);

export const getPublishedServiceRequestsEndpoint = (
  filters = {} as ICompilationPageFilters,
): Promise<AxiosResponse> =>
  apiClient.get('/offers', {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
        skipNulls: true,
      }),
  });

export const getIncomingServiceRequestsEndpoint = (
  filters = {} as IProvideServicesPageFilters,
): Promise<AxiosResponse> =>
  apiClient.get('/offers/incoming', {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
        skipNulls: true,
      }),
  });

export const getMyOffersEndpoint = (
  filters = {} as IProvideServicesPageFilters,
): Promise<AxiosResponse> =>
  apiClient.get('/offers/answers/account', {
    params: filters,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
        skipNulls: true,
      }),
  });

export const getServicesEndpoint = (
  { serviceTypes, ...filters } = {} as ICompilationPageFilters,
): Promise<AxiosResponse> =>
  apiClient.get('/business-cards/services', {
    params: {
      ...filters,
      services: serviceTypes,
    },
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
        skipNulls: true,
      }),
  });

export const getMyServiceRequestsEndpoint = (
  filters = {} as IRequestsPageFilters,
): Promise<AxiosResponse> =>
  apiClient.get('/offers/account', {
    params: {
      ...filters,
      withClosed: true,
    },
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
        skipNulls: true,
      }),
  });

export const createServiceRequestEndpoint = (
  request: ICreateServiceRequest,
): Promise<AxiosResponse<IServiceRequest>> =>
  apiClient.post('/offers', request);

export const publishServiceRequestEndpoint = (
  id: IServiceRequest['id'],
): Promise<AxiosResponse<IServiceRequest>> =>
  apiClient.patch(`/offers/${id}/publish`);

export const closeServiceRequestEndpoint = (
  id: IServiceRequest['id'],
): Promise<AxiosResponse<IServiceRequest>> =>
  apiClient.patch(`/offers/${id}/close`);

export const deleteServiceRequestEndpoint = (
  id: IServiceRequest['id'],
): Promise<AxiosResponse<IServiceRequest>> => apiClient.delete(`/offers/${id}`);

export const showServiceRequestEndpoint = (
  id: IServiceRequest['id'],
): Promise<AxiosResponse<IServiceRequest>> =>
  apiClient.patch(`/offers/${id}/show`);

export const hideServiceRequestEndpoint = (
  id: IServiceRequest['id'],
): Promise<AxiosResponse<IServiceRequest>> =>
  apiClient.patch(`/offers/${id}/hide`);

export const getAllAnswersForServiceRequestEndpoint = (
  id: IServiceRequest['id'],
): Promise<AxiosResponse<IServiceRequest>> =>
  apiClient.get(`/offers/${id}/answers`);

export const marketplaceGetServicePageGetListEndpoint = (
  filter: GetServicePageFilters,
): Promise<AxiosResponse> =>
  apiClient.get(`/offers/answers`, {
    params: filter,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
        skipNulls: true,
      }),
  });

export const uploadMarketplaceAttachmentEndpoint = (attachment: {
  data: FormData;
  type: AttachmentType;
}): Promise<AxiosResponse> => {
  const { data, type } = attachment;
  return apiClient.post(`/attachments/files?type=${type}`, data);
};

export const likeMarketplacePostEndpoint = (
  id: string,
  type: ReactionType,
): Promise<AxiosResponse> => {
  return apiClient.post(`/posts/reactions/${id}`, {}, { params: { type } });
};

export const createMarketplaceCommentEndpoint = (
  data: ICreateComment,
): Promise<AxiosResponse> => {
  return apiClient.post('/posts/comments', data);
};

export const editMarketplaceCommentEndpoint = ({
  id,
  ...data
}: IEditComment): Promise<AxiosResponse> => {
  return apiClient.put(`/posts/comments/${id}`, data);
};

export const uploadReviewAttachmentEndpoint = (
  formData: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments/files?type=${AttachmentType.Review}`,
    formData,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  );
};

export const marketplaceGetOfferCountEndpoint = (
  offerId: string,
): Promise<AxiosResponse> => apiClient.get(`/offers/${offerId}/answers/count`);

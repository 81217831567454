import React from 'react';
import { styled } from '@mui/system';
import { NavigationMenu } from 'components/features/settings/components/navigationMenu/navigationMenu';

const Container = styled('div')({
  minHeight: '100%',
  display: 'flex',
});

const ContainerContent = styled('div')({
  padding: '20px 37px',
  flex: 1,
});

export const SettingLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      <NavigationMenu />
      <ContainerContent>{children}</ContainerContent>
    </Container>
  );
};

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getServiceTypeDictionaries } from 'store/features/dictionaries';
import { getDictionariesServiceType } from 'store/features/dictionaries/actions';
import { Loader } from 'components/shared/loader';
import Grid from '@mui/material/Grid';
import { ReactComponent as ManufactureIcon } from 'assets/icons/manufactureIcon.svg';
import { ReactComponent as SaleIcon } from 'assets/icons/saleIcon.svg';
import { Checkbox } from 'components/shared/checkbox';
import { styled } from '@mui/material/styles';
import { changeFilters, getBusinessFilters } from 'store/features/business';
import { IDictionaries } from 'interfaces/Dictionaries';

const Title = styled('div')({
  color: '#4B4B4B',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
  display: 'flex',
  alignItems: 'center',
});

export const ServiceCategories = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(getBusinessFilters);
  const serviceType = useAppSelector(getServiceTypeDictionaries);
  const [serviceTypesFilter, setServiceTypesFilter] = useState<string[]>(
    filters.services,
  );
  const isLoading = !serviceType.length;
  useEffect(() => {
    isLoading && dispatch(getDictionariesServiceType());
  }, [dispatch, isLoading]);

  const handleChangeFilter = (id: string) => {
    const newServiceTypeFilter = serviceTypesFilter.includes(id)
      ? serviceTypesFilter.filter((el) => el !== id)
      : [...serviceTypesFilter, id];
    setServiceTypesFilter(newServiceTypeFilter);
    dispatch(
      changeFilters({ ...filters, services: newServiceTypeFilter, offset: 0 }),
    );
  };

  const getAllChecked = (array: IDictionaries[] | undefined) => {
    let checked = 0;
    array?.forEach((el) => {
      if (serviceTypesFilter.includes(el.id)) checked += 1;
    });
    return array?.length === checked;
  };

  useEffect(() => {
    setServiceTypesFilter(filters.services);
  }, [filters.services]);

  return (
    <div style={{ paddingLeft: 5 }}>
      {isLoading && <Loader show />}
      {serviceType.map((el) => (
        <Grid container key={el.id} direction={'column'}>
          <Title>
            {el.id === '1' ? (
              <ManufactureIcon style={{ marginRight: 10, minWidth: 24 }} />
            ) : (
              <SaleIcon style={{ marginRight: 10, minWidth: 24 }} />
            )}
            <Checkbox
              label={el.text.ru}
              checked={
                serviceTypesFilter.includes(el.id) || getAllChecked(el.children)
              }
              onChange={() => handleChangeFilter(el.id)}
            />
          </Title>
          {el.children?.map((subEl) => (
            <Grid item xs={12} key={subEl.id} style={{ paddingLeft: 55 }}>
              <Checkbox
                label={subEl.text.ru}
                checked={
                  serviceTypesFilter.includes(subEl.id) ||
                  serviceTypesFilter.includes(subEl.id.slice(0, 1))
                }
                onChange={() => handleChangeFilter(subEl.id)}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </div>
  );
};

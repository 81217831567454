import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import qs from 'qs';
import { useQuery } from 'hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getComplaintsHasMore,
  getComplaintsList,
  getComplaintsLoading,
} from 'store/features/complaints';
import {
  declineComplaint,
  getComplaints,
  resolveComplaint,
} from 'store/features/complaints/actions';
import {
  ComplaintsList,
  ComplaintsFilters,
  ModerationLayout,
  Search,
  SearchRef,
} from '../components';
import { isEqual, findKey, pick } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ListFilters } from 'interfaces/Filters';
import { NonNullableRecord } from 'utils/types';
import { TComplaintsFilters } from 'interfaces/Complaint';
import { Box, styled, Tab, Tabs as MuiTabs } from '@mui/material';

export const TabsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 16,
  padding: '15px 24px 8px',
  backgroundColor: theme.palette.background.paper,
}));

export const Tabs = styled(MuiTabs)({
  '& button': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    textTransform: 'initial',
  },
});

export enum TabsValue {
  Compilation = 'compilation',
  Processed = 'processed',
}

const TabsMap = {
  [TabsValue.Compilation]: 0,
  [TabsValue.Processed]: 1,
} as const;

const formatFilters = (value: TComplaintsFilters) => {
  const { processed, ...other } = value;
  const tab = processed ? TabsValue.Processed : TabsValue.Compilation;
  return { tab, ...other };
};

export const ModerationComplaints = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const search = useQuery();

  const searchRef = useRef<SearchRef>(null);

  const filters = useMemo(
    () => ({
      processed: search.tab === TabsValue.Processed,
      fromDate: parseInt(search.fromDate as string) || null,
      accountIds: search.accountIds,
      userIds: search.userIds,
      text: search.text,
    }),
    [
      search.tab,
      search.fromDate,
      search.accountIds,
      search.userIds,
      search.text,
    ],
  ) as TComplaintsFilters;

  const query = formatFilters(filters);

  const [listFilters, setListFilters] = useState<
    NonNullableRecord<ListFilters>
  >({
    offset: 0,
    limit: 10,
  });

  const complaints = useAppSelector(getComplaintsList);
  const isLoading = useAppSelector(getComplaintsLoading);
  const hasMore = useAppSelector(getComplaintsHasMore);

  const isFetching = listFilters.offset === 0 && isLoading;
  const isRefetching = listFilters.offset !== 0 && isLoading;

  const handleTabsChange = (_: unknown, value: number) => {
    setListFilters({ offset: 0, limit: 10 });
    const name = findKey(TabsMap, (item) => item === value);
    navigate({ search: qs.stringify({ tab: name }, { skipNulls: true }) });
    searchRef.current?.clear();
  };

  const handleListFiltersChange = () => {
    setListFilters((prev) => ({
      offset: prev.offset + prev.limit,
      limit: prev.limit,
    }));
  };

  const handleFiltersChange = ({
    fromDate,
    accountIds,
  }: Pick<TComplaintsFilters, 'fromDate' | 'accountIds'>) => {
    setListFilters({ offset: 0, limit: 10 });
    navigate({
      search: qs.stringify(
        {
          ...query,
          fromDate,
          accountIds,
        },
        { skipNulls: true },
      ),
    });
  };

  const handleTextChange = (text: string) => {
    setListFilters({ offset: 0, limit: 10 });
    navigate({
      search: qs.stringify(
        {
          ...query,
          text: text || null,
        },
        { skipNulls: true },
      ),
    });
  };

  const handleView = (id: string) => {
    navigate(`/moderation/complaints/${id}`);
  };

  const handleReject = (id: string) => {
    dispatch(declineComplaint(id));
  };

  const handleContentDelete = (id: string) => {
    dispatch(resolveComplaint(id));
  };

  const handleFollowObjectLink = (url: string) => {
    navigate(url);
  };

  const prevRef = useRef<TComplaintsFilters>({});

  useEffect(() => {
    const currentFilters: TComplaintsFilters = {
      ...filters,
      ...listFilters,
    };

    if (!isEqual(prevRef.current, currentFilters)) {
      dispatch(getComplaints(currentFilters));
      prevRef.current = currentFilters;
    }
  }, [dispatch, filters, listFilters]);

  const tab = TabsMap[query.tab || TabsValue.Compilation];

  return (
    <ModerationLayout>
      <TabsContainer sx={{ borderBottom: '1px solid #dddddd' }}>
        <Tabs
          TabIndicatorProps={{ style: { display: 'none' } }}
          value={tab}
          onChange={handleTabsChange}
        >
          <Tab label={t('moderation.complaints.tabs.compilation')} />
          <Tab label={t('moderation.complaints.tabs.processed')} />
        </Tabs>
        <Search
          sx={{ width: 300 }}
          ref={searchRef}
          defaultValue={query.text}
          onTextChange={handleTextChange}
        />
      </TabsContainer>
      {tab === TabsMap[TabsValue.Compilation] && (
        <>
          <ComplaintsFilters
            sx={{ mb: 3 }}
            filters={pick(query, ['fromDate', 'accountIds'])}
            onChange={handleFiltersChange}
          />
          <ComplaintsList
            complaints={complaints}
            loading={isFetching}
            refetching={isRefetching}
            hasMore={hasMore}
            next={handleListFiltersChange}
            onView={handleView}
            onReject={handleReject}
            onContentDelete={handleContentDelete}
            onFollowLink={handleFollowObjectLink}
          />
        </>
      )}
      {tab === TabsMap[TabsValue.Processed] && (
        <>
          <ComplaintsFilters
            sx={{ mb: 3 }}
            filters={pick(filters, ['fromDate', 'accountIds'])}
            onChange={handleFiltersChange}
          />
          <ComplaintsList
            complaints={complaints}
            loading={isFetching}
            refetching={isRefetching}
            hasMore={hasMore}
            next={handleListFiltersChange}
            onView={handleView}
            onFollowLink={handleFollowObjectLink}
          />
        </>
      )}
    </ModerationLayout>
  );
};

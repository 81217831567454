import React from 'react';
import { SignInForm } from 'components/features/auth/containers/signin/form';
import { AuthLayout } from 'components/features/auth/layout/authLayout';

export const SignIn = () => {
  return (
    <AuthLayout title={'Вход'}>
      <SignInForm />
    </AuthLayout>
  );
};

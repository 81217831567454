import React, { memo, useMemo } from 'react';
import { styled, SxProps, Theme, Typography } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'highlight.js/styles/monokai-sublime.css';

import { SizeClass, SizeStyle, SUPPORTED_FONT_SIZES } from './ExtendedSize';
import { Syntax } from './ExtendedSyntax';

Quill.register('attributors/class/size', SizeClass, true);
Quill.register('attributors/style/size', SizeStyle, true);
Quill.register('modules/syntax', Syntax, true);

const sizeStyles = SUPPORTED_FONT_SIZES.reduce(
  (acc, size) => ({
    ...acc,
    [`& .quill .ql-size-${size}`]: {
      fontSize: size,
    },
  }),
  {},
);

const Root = styled('div')(({ theme }) => ({
  '& .quill': {
    border: '1px solid #ccc',
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
  },
  '& .quill *': {
    fontFamily: 'Montserrat, sans-serif',
  },
  '& .quill > .ql-container': {
    border: 0,
    fontWeight: 400,
    fontSize: 14,
    color: '#000',
  },
  '& .ql-toolbar': {
    border: 0,
    borderBottom: '1px solid #ccc',
  },
  '& .ql-toolbar .ql-header .ql-picker-label[data-value]::before': {
    content: '"Header " attr(data-value)',
  },
  '& .ql-toolbar .ql-header .ql-picker-item[data-value]::before': {
    content: '"Header " attr(data-value)',
  },
  '& .ql-toolbar .ql-header .ql-picker-item[data-value="1"]::before': {
    fontWeight: 600,
    fontSize: 26,
  },
  '& .ql-toolbar .ql-header .ql-picker-item[data-value="2"]::before': {
    fontWeight: 600,
    fontSize: 24,
  },
  '& .ql-toolbar .ql-header .ql-picker-item[data-value="3"]::before': {
    fontWeight: 600,
    fontSize: 18,
  },
  '& .quill h1': {
    fontWeight: 600,
    fontSize: 26,
  },
  '& .quill h2': {
    fontWeight: 600,
    fontSize: 24,
  },
  '& .quill h3': {
    fontWeight: 600,
    fontSize: 18,
  },
  '& .ql-toolbar .ql-size .ql-picker-label[data-value]::before': {
    content: 'attr(data-value)',
  },
  '& .ql-toolbar .ql-size .ql-picker-item[data-value]::before': {
    content: 'attr(data-value)',
  },
  ...sizeStyles,
}));

const toolbar = [
  [{ header: [false, 1, 2, 3] }, { size: [false, ...SUPPORTED_FONT_SIZES] }],
  ['bold', 'italic', 'underline'],
  [{ align: [] }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['blockquote', 'code-block'],
  [{ color: [] }, { background: [] }],
  ['clean'],
];

type Props = {
  sx?: SxProps<Theme>;
  label?: string;
  languages?: string[];
  placeholder?: string;
  readOnly?: boolean;
  value?: string | undefined | null;
  onChange?: (value: string) => void;
  errorMessage?: string;
};

export const Editor = memo(
  ({
    sx = [],
    label,
    languages,
    readOnly = false,
    placeholder,
    value,
    onChange,
    errorMessage,
  }: Props) => {
    const modules = useMemo(
      () => ({ syntax: { languages }, toolbar }),
      [languages],
    );

    return (
      <Root
        sx={[
          ...(Array.isArray(sx) ? sx : [sx]),
          !!errorMessage && {
            '& .quill': {
              borderColor: '#E04311',
            },
          },
          readOnly && {
            '& .quill': {
              border: 0,
            },
            '& .ql-editor': {
              padding: 0,
            },
            '& .ql-toolbar': {
              display: 'none',
            },
          },
        ]}
      >
        {label && !readOnly && (
          <Typography
            sx={{ display: 'block', mb: 0.5, color: '#a9a9a9' }}
            variant="caption"
            component="label"
          >
            {label}
          </Typography>
        )}
        <ReactQuill
          modules={modules}
          placeholder={placeholder}
          readOnly={readOnly}
          theme="snow"
          value={value ?? ''}
          onChange={onChange}
        />
        {errorMessage && (
          <Typography
            sx={{ display: 'block', mb: 0.5, color: '#E04311' }}
            variant="caption"
            component="label"
          >
            {errorMessage}
          </Typography>
        )}
      </Root>
    );
  },
);

Editor.displayName = 'Editor';

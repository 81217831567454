import React, { useEffect, useState } from 'react';
import { HomeLayout } from 'components/features/home/components/layout';
import { Container } from 'components/features/home/components';
import { RouteNames, URLS } from 'defenitions/routes';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getAccount,
  getAllUsersFilters,
  getAllUsersHasMore,
  getAllUsersList,
  getAllUsersLoading,
  getCurrent,
  getUsersList,
  getUsersLoading,
} from 'store/features/users';
import { IUser, IUsersFilters, Roles } from 'interfaces/Users';
import { UserItem } from 'components/features/home/components/userItem';
import {
  deleteUser,
  getUsers,
  lockUser,
  unlockUser,
  inviteUser,
  getAllUsers,
  getAccountCurrent,
  lockUserBySystem,
  unlockUserBySystem,
  getActiveUsers,
} from 'store/features/users/actions';
import { Loader } from 'components/shared/loader';
import { useNavigate } from 'react-router-dom';
import Snackbar from 'services/Snackbar';
import { AxiosResponse } from 'axios';
import { TabLayout } from 'components/elements/tabs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { changeFilters } from 'store/features/users';
import { PAGE_SIZE } from 'interfaces/BusinessFilters';
import { SearchTextFieldInput } from 'components/shared/inputs/SearchInput';
import { UsersFilters } from 'components/features/home/components/usersFilters';
import { SelectFilters } from 'components/elements/filters/SelectFilters';
import { ConfirmDialog } from 'components/elements/modals/confirmDialog';
import { useModalActions } from 'utils/hooks';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import Grid from '@mui/material/Grid';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IMultiLanguage } from 'interfaces/Locale';
import { getComplaintsAccountsFilterList } from 'store/features/complaints';
import { getComplaintsAccountsFilter } from 'store/features/complaints/actions';
import { SCROLLABLE_REFERENCE_ID } from 'services/theme';
import { MAX_LENGTH } from 'defenitions/errorMessages';

enum TabsValue {
  My = 'my',
  All = 'all',
}

const TabsMap = {
  [TabsValue.My]: 0,
  [TabsValue.All]: 1,
} as const;

export const Users = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const current = useAppSelector(getCurrent);
  const account = useAppSelector(getAccount);
  const users = useAppSelector(getUsersList);
  const isLoading = useAppSelector(getUsersLoading);
  const allUsers = useAppSelector(getAllUsersList);
  const isAllUsersLoading = useAppSelector(getAllUsersLoading);
  const isAllUsersHasMore = useAppSelector(getAllUsersHasMore);
  const allUsersFilters = useAppSelector(getAllUsersFilters);
  const accounts = useAppSelector(getComplaintsAccountsFilterList);
  const navigate = useNavigate();
  const { isOpen, onClickOpen, onClickClose } = useModalActions();
  const [tab, setTab] = useState<number>(TabsMap.my);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

  const onClickCreate = () => {
    navigate(`${URLS.createUser}`);
  };

  const onClickEdit = () => {
    navigate(`${URLS.users}/${current?.id}`);
  };

  const onClickLock = (id: string) => {
    dispatch(lockUser(id));
  };
  const onClickUnlock = (id: string) => {
    dispatch(unlockUser(id));
  };
  const onClickLockBySystem = (user: IUser) => {
    setSelectedUser(user);
    onClickOpen();
  };
  const onClickUnlockBySystem = (accountId: string, id: string) => {
    dispatch(unlockUserBySystem({ accountId, id }));
  };
  const onClickDelete = (id: string) => {
    dispatch(deleteUser(id));
  };
  const onClickStats = (id: string) => {
    navigate(`${URLS.stats}/user/${id}`);
  };
  const onClickInvite = (id: string) => {
    dispatch(inviteUser(id)).then((res) => {
      const { status } = res.payload as AxiosResponse;
      status === 200 && Snackbar.show(t('notification.email send'), 'success');
    });
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getAccountCurrent());
  }, [dispatch]);

  const isAdminUser = current?.role === Roles.ADMINISTRATOR;
  const isOwnerUser = current?.role === Roles.OWNER;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const loadNext = () => {
    dispatch(
      changeFilters({
        ...allUsersFilters,
        offset: allUsersFilters.offset + PAGE_SIZE,
      }),
    );
  };

  const handleChangeSearch = (value: string) => {
    dispatch(changeFilters({ ...allUsersFilters, text: value, offset: 0 }));
  };

  const handleChangeFilters = (filters: IUsersFilters) =>
    dispatch(changeFilters(filters));

  const handleAccountsChange = (value: string[]) =>
    handleChangeFilters({ ...allUsersFilters, accountIds: value, offset: 0 });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ reason: IMultiLanguage }>({
    mode: 'onChange',
    defaultValues: {},
  });

  const handleSave: SubmitHandler<{ reason: IMultiLanguage }> = async (
    data,
  ) => {
    selectedUser?.accountId &&
      dispatch(
        lockUserBySystem({
          accountId: selectedUser.accountId,
          id: selectedUser.id,
          reason: data.reason,
        }),
      );
    reset();
    setSelectedUser(null);
  };

  const handleLoadAccountFilter = () => dispatch(getComplaintsAccountsFilter());

  useEffect(() => {
    account?.isSystemAccount
      ? dispatch(getAllUsers(allUsersFilters))
      : dispatch(getActiveUsers(allUsersFilters));
  }, [account?.isSystemAccount, allUsersFilters, dispatch]);

  return (
    <HomeLayout>
      {isLoading && <Loader show global />}
      <Container item container direction={'column'}>
        <TabLayout
          sx={{ border: 'none' }}
          tab={tab}
          tabs={[
            {
              label: t(`${RouteNames[URLS.users]}`),
              component: (
                <>
                  {users.map((user) => (
                    <UserItem
                      key={user.accountId + user.name}
                      user={user}
                      onClickEdit={
                        current?.id === user.id ? onClickEdit : undefined
                      }
                      onClickLock={() => onClickLock(user.id)}
                      onClickUnlock={() => onClickUnlock(user.id)}
                      onClickDelete={() => onClickDelete(user.id)}
                      onClickInvite={() => onClickInvite(user.id)}
                      onClickStats={() => onClickStats(user.id)}
                      viewByOwner={isOwnerUser}
                      viewByAdmin={isAdminUser}
                      isCurrentUser={current?.id === user.id}
                    />
                  ))}
                </>
              ),
            },
            {
              label: t(`${RouteNames[URLS.allUsers]}`),
              component: (
                <>
                  {account?.isSystemAccount && (
                    <div
                      style={{
                        display: 'flex',
                        paddingLeft: 20,
                        borderTop: '1px solid #DDDDDD',
                      }}
                    >
                      <div style={{ padding: '16px 8px' }}>
                        <SelectFilters
                          value={allUsersFilters.accountIds}
                          onChange={handleAccountsChange}
                          options={accounts}
                          onLoad={handleLoadAccountFilter}
                          title={t('filters.accounts.title')}
                          placeholder={t('filters.accounts.placeholder')}
                        />
                      </div>
                      <UsersFilters
                        filters={allUsersFilters}
                        onChange={handleChangeFilters}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      height: '100%',
                      overflowY: 'scroll',
                      width: '100%',
                    }}
                  >
                    <InfiniteScroll
                      dataLength={allUsers.length}
                      next={loadNext}
                      hasMore={isAllUsersHasMore}
                      loader={<Loader show={isAllUsersLoading} global />}
                      scrollableTarget={SCROLLABLE_REFERENCE_ID}
                    >
                      {allUsers.map((user) => (
                        <UserItem
                          key={user.accountId + user.id + user.name}
                          user={user}
                          onClickLock={() => onClickLockBySystem(user)}
                          onClickUnlock={() =>
                            user.accountId &&
                            onClickUnlockBySystem(user.accountId, user.id)
                          }
                          viewBySystem={account?.isSystemAccount}
                        />
                      ))}
                    </InfiniteScroll>
                  </div>
                </>
              ),
            },
          ]}
          onChangeTabs={handleChange}
        />
        {(isOwnerUser || isAdminUser) && tab === TabsMap.my && (
          <CreateButton
            sx={{
              maxWidth: 300,
              position: 'absolute',
              top: 15,
              right: 10,
            }}
            fullWidth
            text={t('addUser')}
            withPlusIcon
            onClick={onClickCreate}
          />
        )}
        {tab === TabsMap.all && (
          <SearchTextFieldInput
            sx={{ maxWidth: 300, position: 'absolute', top: 28, right: 20 }}
            defaultValue={allUsersFilters.text}
            onChangeSearch={handleChangeSearch}
          />
        )}
        {selectedUser && (
          <ConfirmDialog
            onClickClose={onClickClose}
            open={isOpen}
            title={t('modalTexts.users.confirmBlock')}
          >
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    {t('modalTexts.users.blockTitle', {
                      name: `${selectedUser.name} ${selectedUser.lastName}`,
                    })}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <FormMultiLocaleField
                    fieldName={'reason'}
                    label={t('modalTexts.users.reason')}
                    errors={errors}
                    control={control}
                    multiline
                    maxLength={MAX_LENGTH}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ActionButton
                    fullWidth
                    onClick={handleSubmit(handleSave)}
                    autoFocus
                    text={t('modalTexts.users.confirmBlockAction')}
                  />
                </Grid>
              </Grid>
            </form>
          </ConfirmDialog>
        )}
      </Container>
    </HomeLayout>
  );
};

import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { ICreateUser, IUsersFilters } from 'interfaces/Users';
import { AttachmentType } from 'interfaces/Attachment';
import qs from 'qs';
import { IMultiLanguage } from 'interfaces/Locale';

export const getUserCurrentEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get('/users/current');
};

export const getAccountCurrentEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get('/account/current');
};

export const getUsersEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get('/users/list');
};

export const createUserEndpoint = (
  user: ICreateUser,
): Promise<AxiosResponse> => {
  return apiClient.post('/users/create', user);
};

export const updateUserEndpoint = (
  user: ICreateUser,
): Promise<AxiosResponse> => {
  return apiClient.post('/users/change', user);
};

export const deleteUserEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.post(`/users/delete/${id}`);
};

export const unlockUserEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.post(`/users/unlock/${id}`);
};

export const lockUserEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.post(`/users/block/${id}`);
};
export const inviteUserEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.post(`/users/create/invite?id=${id}`);
};
export const uploadUserLogoEndpoint = (
  formData: FormData,
): Promise<AxiosResponse> => {
  return apiClient.post(
    `/attachments?type=${AttachmentType.UserLogo}`,
    formData,
  );
};

export const getAllUsersEndpoint = (
  filters: IUsersFilters,
): Promise<AxiosResponse> => {
  return apiClient.get('/users', {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });
};

export const unlockUserBySystemEndpoint = ({
  accountId,
  id,
}: {
  accountId: string;
  id: string;
}): Promise<AxiosResponse> => {
  return apiClient.post(`/users/unlock/${accountId}/${id}`);
};

export const lockUserBySystemEndpoint = ({
  accountId,
  id,
  reason,
}: {
  accountId: string;
  id: string;
  reason: IMultiLanguage;
}): Promise<AxiosResponse> => {
  return apiClient.post(`/users/block/${accountId}/${id}`, reason);
};

export const getUsersActiveEndpoint = (
  filters: IUsersFilters,
): Promise<AxiosResponse> => {
  return apiClient.get('/users/active', {
    params: {
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  });
};

export const getUserEndpoint = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`/users/${id}`);
};

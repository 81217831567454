import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { INewsStats } from 'interfaces/Stats';
import { StatsCollapseLayout } from 'components/features/stats/components/CollapseLayout';
import { StatsTable } from 'components/elements/table';
import {
  createHeadCell,
  createData,
} from 'components/features/stats/item/business/tables/news/table/utils';

export const NewsStats = ({ news }: { news: INewsStats }) => {
  const { t } = useTranslation();

  return (
    <StatsCollapseLayout title={t('tables.tableNews')}>
      <Grid item container>
        <StatsTable
          title={t('tables.tableQuestionsOutgoing')}
          data={createData(news.news)}
          columns={createHeadCell()}
        />
      </Grid>
    </StatsCollapseLayout>
  );
};

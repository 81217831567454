import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const Container = styled(Grid)({
  padding: '14px 36px 20px',
  background: '#FFFFFF',
});

export const Divider = styled(Grid)({
  background: '#C9D1D7',
  borderRadius: 8,
  height: 1,
  width: '100%',
  margin: '20px 0px',
});

export const SubTitle = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: '17px',
  color: '#666666',
});

export const ServiceDataTitle = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 14,
  color: '#3D3D3D',
});

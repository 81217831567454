import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { GuideIcon } from 'components/elements/guideIcon';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 101,
});

const Card = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
  borderRadius: 2,
  width: 786,
  padding: 30,
}));

const Title = styled('div')({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 28,
  lineHeight: '36px',
});

type Props = {
  title: string;
  children: React.ReactNode;
};

export const CardLayout = ({ title, children }: Props) => {
  return (
    <>
      <GuideIcon />
      <Container>
        <Card>
          <Grid container alignItems={'center'} direction={'column'}>
            <Grid item xs={12}>
              <Title>{title}</Title>
            </Grid>
            <Grid item xs={12} style={{ width: '100%' }}>
              {children}
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
};

import { apiClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { Locale } from 'interfaces/Locale';
import { INotificationSettingCreateItem } from 'interfaces/Notifications';

export const getSettingsEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get('/settings');
};

export const changeLocaleEndpoint = (
  locale: Locale,
): Promise<AxiosResponse> => {
  return apiClient.post(`/settings/locale/${locale}`);
};

export const changeTimeZoneEndpoint = (
  timeZone: string,
): Promise<AxiosResponse> => {
  return apiClient.post(`/settings/timezone/?timeZone=${timeZone}`);
};

export const getTimeZonesEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get(`/settings/timezone/available`);
};

export const getNotificationSettingsEndpoint = (): Promise<AxiosResponse> => {
  return apiClient.get(`/settings/notifications`);
};

export const changeNotificationSettingsEndpoint = (
  settings: INotificationSettingCreateItem[],
): Promise<AxiosResponse> => {
  return apiClient.post(`/settings/notifications`, settings);
};

import * as yup from 'yup';
import { ErrorMessages } from 'defenitions/errorMessages';

export const createServiceRequestValidationSchema = yup.object({
  serviceTypes: yup
    .array()
    .min(1, ErrorMessages.required)
    .required(ErrorMessages.required),
  title: yup.object().shape(
    {
      ru: yup.string().when('en', {
        is: (en: string) => !en,
        then: yup
          .string()
          .max(250, ErrorMessages.maxLength(250))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
      en: yup.string().when('ru', {
        is: (ru: string) => !ru,
        then: yup
          .string()
          .max(250, ErrorMessages.maxLength(250))
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
    },
    [
      ['ru', 'en'],
      ['en', 'ru'],
    ],
  ),
  content: yup.object().shape(
    {
      ru: yup.string().when('en', {
        is: (en: string) => !en,
        then: yup
          .string()
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
      en: yup.string().when('ru', {
        is: (ru: string) => !ru,
        then: yup
          .string()
          .notOneOf(['', undefined, null], ErrorMessages.required),
      }),
    },
    [
      ['ru', 'en'],
      ['en', 'ru'],
    ],
  ),
});

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { App } from 'root/App';
import { Loader } from 'components/shared/loader';

import 'services/i18n';
import 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';

ReactDOM.render(
  <Suspense fallback={<Loader show global />}>
    <App />
  </Suspense>,
  document.getElementById('root'),
);
export { FieldType } from 'interfaces/Notifications';

import React, { useState } from 'react';
import { truncate } from 'lodash';
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  IconButton,
  Box,
} from '@mui/material';
import { IAnswer } from 'interfaces/Marketplace';
import {
  styled,
  SxProps,
  Theme,
  Avatar,
  Grid,
  Typography,
} from '@mui/material';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/dislike.svg';
import { ReactComponent as AnswerIcon } from 'assets/icons/answer.svg';
import { ReactComponent as ViewIcon } from 'assets/icons/viewIcon.svg';
import { getFormattedDate } from 'utils/dates';
import { InteractiveButton } from 'components/shared/buttons/InteractiveButton';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import { getLocaleValue } from 'utils/form';
import { useTranslation } from 'react-i18next';
import { Status } from 'components/features/marketplace/components/status';
import { attachUrl } from '../../../../../services/ApiClient';
import { ReactionType } from 'interfaces/Post';
import { Editor } from 'components/shared/editor';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from '../../../../../defenitions/routes';

const Root = styled('div')({
  position: 'relative',
  border: '1px solid #ddd',
  padding: '16px 12px',
  backgroundColor: '#fff',
  borderRadius: 3,
});

const InfoHeader = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: 12,
  color: '#a9a9a9',
});

export const BusinessAvatar = styled(Avatar)({
  height: 55,
  width: 55,
});

export const Name = styled('h3')({
  display: 'flex',
  alignItems: 'flex-start',
  margin: 0,
  padding: 0,
  fontWeight: '700',
  fontSize: 18,
  color: '#00618e',
  justifyContent: 'space-between',
});
const Wrap = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 12,
});

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginTop: 30,

  '&:empty': {
    display: 'none',
  },
}));

const Header = styled(Typography)({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '22px',
  color: '#00618e',
}) as typeof Typography;

const Menu = styled(MuiMenu)({
  '& .MuiMenu-list': {
    padding: 0,
  },
});

const MenuItem = styled(MuiMenuItem)({
  minWidth: 200,
  display: 'flex',
  justifyContent: 'center',
  padding: '8px 16px',
  fontFamily: 'inherit',
  fontWeight: '600',
  fontSize: 14,
  color: '#004b7c',
});

const SoftwareCardsBox = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  color: theme.palette.text.primary,
}));

const SoftwareCard = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  color: '#00618e',
  textDecoration: 'none',
}) as typeof Typography;

type MenuOptions = Array<{ label: string; onClick?: () => void }>;

type Props = {
  item: IAnswer;
  sx?: SxProps<Theme>;
  onLike?: (id: IAnswer['id']) => void;
  onDislike?: (id: IAnswer['id']) => void;
  onComment?: (id: IAnswer['id']) => void;
  menu?: MenuOptions;
  showStatus?: boolean;
};

export const ServiceRequestAnswerCard = ({
  item,
  sx = [],
  onLike,
  onDislike,
  onComment,
  menu = [],
  showStatus = true,
}: Props) => {
  const { t } = useTranslation();
  const {
    businessCard,
    creator,
    offer,
    status,
    viewsCounter,
    reactions,
    softwareCards,
  } = item;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const likes = reactions?.[ReactionType.LIKE]?.count ?? 0;
  const hasUserLikes = reactions?.[ReactionType.LIKE]?.hasByUser ?? false;

  const dislikes = reactions?.[ReactionType.DISLIKE]?.count ?? 0;
  const hasUserDislikes = reactions?.[ReactionType.DISLIKE]?.hasByUser ?? false;

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => setAnchorEl(null);

  const handleLike = () => {
    onLike?.(item.id);
  };

  const handleDislike = () => {
    onDislike?.(item.id);
  };

  const handleComment = () => {
    onComment?.(item.id);
  };

  const avatarUrl =
    businessCard?.logo?.path && attachUrl + businessCard.logo?.path;

  return (
    <Root sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Wrap>
        <BusinessAvatar src={avatarUrl} />
        <Grid sx={{ flex: 1 }} spacing={0.5} direction="column" container>
          <Grid item>
            <Name>
              <Header
                component={businessCard?.id ? Link : 'div'}
                to={generatePath(URLS.viewBusinessCard, {
                  id: businessCard?.id ?? '',
                })}
              >
                {getLocaleValue(businessCard?.name) || 'Deleted'}
              </Header>
              {showStatus && (
                <Status
                  style={{ marginRight: 50 }}
                  status={status}
                  type={'Answer'}
                />
              )}
            </Name>
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} item>
            <UserIcon />
            <InfoHeader>
              {`${creator?.name} ${creator?.lastName}, #${creator.reputation}`}
            </InfoHeader>
            <TimeIcon style={{ marginLeft: 20 }} />
            <InfoHeader>{getFormattedDate(item.createdDateTime)}</InfoHeader>
          </Grid>
          {businessCard?.rate && (
            <Grid sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} item>
              <InfoHeader>{t('rating')}</InfoHeader>
              {businessCard?.rate}
            </Grid>
          )}
          <Grid sx={{ margin: '10px 0px 0px' }} item>
            {!!softwareCards?.length && (
              <SoftwareCardsBox>
                {'ПО: '}
                {softwareCards.map((card, index) => (
                  <>
                    <SoftwareCard
                      key={card.id}
                      component={Link}
                      to={generatePath(URLS.viewSoftwareCard, { id: card.id })}
                    >
                      {getLocaleValue(card.name)}
                    </SoftwareCard>
                    {index === softwareCards.length - 1 ? '' : ', '}
                  </>
                ))}
              </SoftwareCardsBox>
            )}
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              margin: '0px 0px 10px',
            }}
            item
          >
            <InfoHeader>
              {t('marketplace.offerReceiver')}
              <span style={{ marginLeft: 20, color: '#00618E' }}>
                {offer.businessCard?.name?.ru || offer.businessCard?.name?.en}
              </span>
            </InfoHeader>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              color: '#00618E',
              fontSize: 16,
            }}
            item
          >
            {t('marketplace.requestTitle')}:{' '}
            {truncate(offer.title.ru || offer.title.en, {
              length: 100,
              separator: ' ',
            })}
          </Grid>
          {getLocaleValue(item.title) && (
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                fontSize: 14,
              }}
              item
            >
              {t('marketplace.offerTitle')}:{' '}
              {truncate(getLocaleValue(item.title), {
                length: 100,
                separator: ' ',
              })}
            </Grid>
          )}
          {getLocaleValue(item.content) && (
            <Grid sx={{ mt: 0.5 }} item>
              <Editor
                value={truncate(getLocaleValue(item.content), {
                  length: 250,
                  separator: ' ',
                })}
                readOnly
              />
            </Grid>
          )}
          <Actions>
            <Box sx={{ flexBasis: '150px' }}>
              <InteractiveButton
                variant="stroke"
                IconComponent={ViewIcon}
                count={viewsCounter.uniqueUsers}
                label={t('views')}
                active={viewsCounter.viewedByCurrentUser}
              />
            </Box>
            {!!onLike && (
              <Box sx={{ flexBasis: '100px' }}>
                <InteractiveButton
                  IconComponent={LikeIcon}
                  count={likes}
                  active={hasUserLikes}
                  onClick={handleLike}
                />
              </Box>
            )}
            {!!onDislike && (
              <Box sx={{ flexBasis: '100px' }}>
                <InteractiveButton
                  IconComponent={DislikeIcon}
                  count={dislikes}
                  active={hasUserDislikes}
                  onClick={handleDislike}
                />
              </Box>
            )}
            {!!onComment && (
              <Box sx={{ flexBasis: '150px' }}>
                <InteractiveButton
                  IconComponent={AnswerIcon}
                  count={item.commentsCount}
                  label={t('comments')}
                  active={item.hasCurrentUserComment}
                  onClick={handleComment}
                />
              </Box>
            )}
          </Actions>
        </Grid>
      </Wrap>
      <IconButton
        sx={{ position: 'absolute', top: 10, right: 12 }}
        onClick={handleOpenMore}
      >
        <MoreIcon sx={{ color: '#707070' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseMore}
      >
        {menu.map(({ label, onClick }, index) => (
          <MenuItem key={index} divider onClick={onClick}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Root>
  );
};

import { HeadCell } from 'components/elements/table/TableHead';
import i18n from 'i18next';
import { DataItem } from 'components/elements/table';
import { IUserComplaintStats } from 'interfaces/Stats';
import { ComplaintAboutTypeName } from 'interfaces/Complaint';

export const createHeadCell = (): HeadCell[] => {
  return [
    {
      id: 'complaintType',
      label: i18n.t('tables.complaintType'),
      numeric: false,
    },
    {
      id: 'all',
      label: i18n.t('tables.all'),
      numeric: true,
    },
    {
      id: 'active',
      label: i18n.t('tables.active'),
      numeric: true,
    },
    {
      id: 'rejected',
      label: i18n.t('tables.rejected'),
      numeric: true,
    },
    {
      id: 'resolved',
      label: i18n.t('tables.resolved'),
      numeric: true,
    },
  ];
};

export const createData = (data: IUserComplaintStats[]): DataItem[] =>
  data.map((el) => {
    return {
      ...el,
      id: el.complaintType,
      complaintType: i18n.t(
        `moderation.complaints.aboutType.${
          ComplaintAboutTypeName[el.complaintType]
        }`,
      ),
    };
  });

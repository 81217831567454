import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, styled, SxProps, Theme } from '@mui/material';
import { SelectTextFields } from 'components/shared/selects';
import { FormMultiLocaleField } from 'components/elements/fieldBuilder';
import { FilesUploader } from 'components/elements/uploaders/FilesUploader';
import { useAppDispatch, useAppSelector } from 'store';
import { getPublishedBusinessCardsList } from 'store/features/business';
import { getPublishedBusinessCards } from 'store/features/business/actions';
import { useTranslation } from 'react-i18next';
import { IMultiLanguage, Locale } from 'interfaces/Locale';
import { IAttachment } from 'interfaces/Attachment';
import { ActionButton } from 'components/shared/buttons/ActionButton';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import { IComment } from 'interfaces/Comment';
import { AnyObjectSchema } from 'yup';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { selectSoftwareCardAccount } from 'store/features/software';
import { getSoftwareCardAccount } from 'store/features/software/actions';
import { MultiSelectAutocomplete } from 'components/shared/autocomplete';

export const FILE_TYPES = 'image/jpeg, image/png, image/jpg, video/mp4';
export const MAX_FILES = 3;

const Form = styled('form')({
  padding: '16px 24px',
});

export type EditCommentFormData = {
  businessCardId: string;
  content: IMultiLanguage;
  attachments: Array<IAttachment>;
  softwareCards?: IDictionariesContent[];
};

type Props = {
  sx?: SxProps<Theme>;
  comment: Partial<
    Pick<IComment, 'attachments' | 'businessCard' | 'content' | 'softwareCards'>
  >;
  label?: string;
  validationSchema: AnyObjectSchema;
  uploadAttachments: (data: FormData) => Promise<IAttachment[]>;
  onSubmit: (data: EditCommentFormData) => void;
  onCancel: () => void;
};

export const EditCommentForm = ({
  sx = [],
  comment,
  label,
  validationSchema,
  uploadAttachments,
  onSubmit,
  onCancel,
}: Props) => {
  const { i18n } = useTranslation();
  const lang = i18n.language as Locale;

  const dispatch = useAppDispatch();
  const businessCards = useAppSelector(getPublishedBusinessCardsList);
  const softwareCards = useAppSelector(selectSoftwareCardAccount);

  const { control, formState, handleSubmit, setValue, trigger, watch } =
    useForm<EditCommentFormData>({
      defaultValues: {
        businessCardId: comment.businessCard?.id || '',
        attachments: comment.attachments ?? [],
        content: {
          ru: comment.content?.ru ?? '',
          en: comment.content?.en ?? '',
        },
        softwareCards: comment.softwareCards?.map((el) => ({
          code: el.id,
          display: el.name,
        })),
      },
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
    });

  const { isValid, errors } = formState;

  const attachments = watch('attachments');

  const businessOptions = useMemo(
    () => businessCards.map(({ id, name }) => ({ id, name: name[lang] })),
    [businessCards, lang],
  );
  const softwareOptions: IDictionariesContent[] = useMemo(
    () =>
      softwareCards.map((item) => ({
        code: item.id,
        display: item.name,
        selectable: true,
      })),
    [softwareCards],
  );

  const handleUploadAttachments = async (data: FormData) => {
    const uploaded = await uploadAttachments(data);
    const changed = [...attachments, ...uploaded];
    setValue('attachments', changed, { shouldValidate: true });
  };

  const handleRemoveAttachments = (id: string) => {
    const changed = attachments.filter((attachment) => attachment.id !== id);
    setValue('attachments', changed, { shouldValidate: true });
  };
  const handleSoftwareCardsOptions = useCallback(() => {
    dispatch(getSoftwareCardAccount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPublishedBusinessCards());
    dispatch(getSoftwareCardAccount());
  }, [dispatch]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'content.en') {
        trigger('attachments');
        trigger('content.ru');
      }
      if (name === 'content.ru') {
        trigger('attachments');
        trigger('content.en');
      }
      if (name === 'attachments') {
        trigger('content');
      }
    });

    return () => subscription.unsubscribe();
  }, [trigger, watch]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <Form
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="businessCardId"
            render={({ field: { value, onChange } }) => (
              <SelectTextFields
                value={value}
                options={businessOptions}
                errorMessage={errors?.businessCardId?.message}
                disabled
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            defaultValue={[]}
            name="softwareCards"
            render={({ field: { ref, ...rest } }) => (
              <MultiSelectAutocomplete
                {...rest}
                noHierarchy
                inputRef={ref}
                label="ПО"
                multiple
                data={softwareOptions}
                onLoadOptions={handleSoftwareCardsOptions}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormMultiLocaleField
            isEditor
            control={control}
            fieldName={'content'}
            label={label || 'Комментарий'}
            errors={errors}
            multiline
            minRows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <FilesUploader
            attachments={attachments}
            accept={FILE_TYPES}
            maxFiles={MAX_FILES}
            hideTitle
            onUploadAttachments={handleUploadAttachments}
            onClickRemoveAttachments={handleRemoveAttachments}
          />
        </Grid>
        <Grid
          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
          item
          xs={12}
        >
          <ActionButton
            fullWidth
            type="submit"
            text={'Изменить'}
            disabled={!isValid}
          />
          <CreateButton
            fullWidth
            type="button"
            text={'Отменить'}
            onClick={onCancel}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Divider,
  SubTitle,
} from 'components/features/business/shared/item/components';
import Grid from '@mui/material/Grid';
import { CreateButton } from 'components/shared/buttons/CreateButton';
import {
  FormState,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
} from 'react-hook-form';
import { IBusiness, IBusinessService } from 'interfaces/Business';
import { Modal } from 'components/shared/modal';
import { IDictionariesContent } from 'interfaces/Dictionaries';
import { IBusinessAttachment } from 'interfaces/Attachment';
import { uploadServiceCardAttachments } from 'store/features/business/actions';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'store';
import { yupResolver } from '@hookform/resolvers/yup';
import { serviceDataValidationSchema } from 'components/features/business/shared/item/edit/serviceData/update/validationSchema';
import { ServiceDataAddForm } from 'components/features/business/shared/item/edit/serviceData/update/serviceForm';
import { ViewBusinessService } from 'components/features/business/shared/item/view/serviceData/components';
import { ConfirmDialog } from '../../../../../../elements/modals/confirmDialog';
import { useModalActions } from '../../../../../../../utils/hooks';
import { useTranslation } from 'react-i18next';

type Props = {
  onClickBack: () => void;
  onClickSaveDraft: SubmitHandler<IBusiness>;
  onClickSave: SubmitHandler<IBusiness>;
  handleSubmit: UseFormHandleSubmit<IBusiness>;
  formState: FormState<IBusiness>;
  serviceTypes: IDictionariesContent[];
  onLoadServiceTypes: () => void;
  onAddBusinessService: (service: IBusinessService) => void;
  businessServices: IBusinessService[];
  onRemoveBusinessService: (index: number) => void;
  onEditBusinessService: (service: IBusinessService, index: number) => void;
  isUpdate: boolean;
};

export const ServiceDataForm = ({
  onClickBack,
  onClickSaveDraft,
  onClickSave,
  handleSubmit,
  formState,
  serviceTypes,
  onLoadServiceTypes,
  businessServices,
  onAddBusinessService,
  onRemoveBusinessService,
  onEditBusinessService,
  isUpdate,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState<null | number>(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setIsEdit(null);
    reset({});
  };
  const handleOpen = () => {
    setOpen(true);
    reset();
  };
  const {
    isOpen: isOpenConfirmModal,
    onClickOpen: onClickOpenConfirmModal,
    onClickClose: onClickCloseConfirmModal,
  } = useModalActions();
  const { isValid, isSubmitting } = formState;
  const {
    control: controlService,
    handleSubmit: handleSubmitService,
    reset,
    formState: {
      errors,
      isValid: isValidService,
      isSubmitting: isSubmittingService,
    },
    trigger,
  } = useForm<IBusinessService>({
    mode: 'onChange',
    resolver: yupResolver(serviceDataValidationSchema),
    defaultValues: {},
  });

  const [attachments, setAttachments] = useState<IBusinessAttachment[]>([]);
  const handleUploadAttachments = useCallback(
    async (formData: FormData) => {
      const { payload } = await dispatch(
        uploadServiceCardAttachments(formData),
      );
      const { data } = payload as AxiosResponse<IBusinessAttachment[]>;
      data &&
        setAttachments((prevState) => {
          return [...prevState, ...data];
        });
    },
    [dispatch],
  );

  const handleRemoveAttachments = (id: string) =>
    setAttachments((prevState) => prevState.filter((el) => el.id !== id));

  const onClickHandleSubmit = () => {
    handleSubmitService((data) => {
      isEdit === null
        ? onAddBusinessService({ ...data, attachments })
        : onEditBusinessService({ ...data, attachments }, isEdit);
      setAttachments([]);
      setIsEdit(null);
      handleClose();
      reset({});
    })();
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onClickEdit = (index: number) => {
    setIsEdit(index);
    reset(businessServices[index]);
    setAttachments(businessServices[index].attachments || []);
    handleOpen();
  };

  const onClickDelete = (index: number) => {
    setIsEdit(index);
    onClickOpenConfirmModal();
  };

  const handleRemove = () => {
    console.log(isEdit);
    isEdit !== null && onRemoveBusinessService(isEdit);
    onClickCloseConfirmModal();
    setIsEdit(null);
  };
  return (
    <Container container>
      <Grid item container xs={12} justifyContent={'flex-end'} marginBottom={2}>
        <CreateButton
          text={'Добавить услугу '}
          withPlusIcon
          onClick={handleOpen}
          style={{ width: 222 }}
        />
      </Grid>
      {businessServices.length === 0 && (
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignContent={'center'}
          style={{ height: 'calc(100vh - 394px)' }}
        >
          <SubTitle>Перечень оказываемых услуг пуст</SubTitle>
        </Grid>
      )}
      {businessServices.map((el, index) => (
        <ViewBusinessService
          key={el.name.ru}
          businessService={el}
          onClickRemoveBusinessService={() => onClickDelete(index)}
          onClickEdit={() => onClickEdit(index)}
        />
      ))}
      <Modal
        title={'Новая услуга'}
        onClickAccept={onClickHandleSubmit}
        open={open}
        handleClose={handleClose}
        disabled={!isValidService || isSubmittingService}
      >
        <ServiceDataAddForm
          serviceTypes={serviceTypes}
          onLoadServiceTypes={onLoadServiceTypes}
          controlService={controlService}
          errors={errors}
          attachments={attachments}
          handleUploadAttachments={handleUploadAttachments}
          trigger={trigger}
          onRemoveAttachments={handleRemoveAttachments}
        />
      </Modal>
      <Divider />
      <Grid item container xs={12} justifyContent={'space-evenly'}>
        {!isUpdate && (
          <Grid item style={{ width: 235 }}>
            <CreateButton
              fullWidth
              disabled={!isValid || isSubmitting}
              text={'Сохранить как черновик'}
              onClick={handleSubmit(onClickSaveDraft)}
            />
          </Grid>
        )}
        <Grid item style={{ width: 136 }}>
          <CreateButton fullWidth text={'Назад'} onClick={onClickBack} />
        </Grid>
        <Grid item style={{ width: 136 }}>
          <CreateButton
            fullWidth
            disabled={!isValid}
            text={'Сохранить'}
            onClick={handleSubmit(onClickSave)}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        onClickAccept={handleRemove}
        onClickClose={onClickCloseConfirmModal}
        open={isOpenConfirmModal}
        actionTitle={t('modalTexts.software.delete')}
        text={t('modalTexts.software.text')}
        title={t('modalTexts.software.remove title')}
      />
    </Container>
  );
};

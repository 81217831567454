import React from 'react';
import { styled, SxProps, Theme } from '@mui/system';
import { NavigationMenu } from 'components/features/marketplace/components/navigationMenu';
import { AnswersNavigationMenu } from 'components/features/marketplace/components/answersNavigationMenu/AnswersNavigationMenu';
import { FooterAd } from 'components/elements/ads';

const Container = styled('div')({
  minHeight: '100%',
  display: 'flex',
});

const ContainerContent = styled('div')({
  padding: '20px 37px',
  flex: 1,
  borderLeft: '1px solid #DDDDDD',
});

export const MarketplaceLayout = ({
  children,
  contentSx = [],
  answersLayout = false,
  menuComponent,
}: {
  children: React.ReactNode;
  contentSx?: SxProps<Theme>;
  answersLayout?: boolean;
  menuComponent?: React.ReactNode;
}) => {
  return (
    <Container>
      {menuComponent ? (
        menuComponent
      ) : answersLayout ? (
        <AnswersNavigationMenu />
      ) : (
        <NavigationMenu />
      )}
      <ContainerContent
        sx={[...(Array.isArray(contentSx) ? contentSx : [contentSx])]}
      >
        {children}
        <FooterAd />
      </ContainerContent>
    </Container>
  );
};
